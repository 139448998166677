
import { ORDER_ENQUIRY } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { orderEnquiryService } from "../../services/orderEnquiry.service";

export const orderEnquiryActions = {
  getEnquiriesList,
  setOrderEnquiryDetails,
  getlimitedEnquiriesList,
  getLimitedFSEEnquiriesList,
};

export function getEnquiriesList(customerTierType: any, filterParams: any, isMyEnquiryChecked?: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING));
    const response = await orderEnquiryService.getEnquiriesList(customerTierType, filterParams,isMyEnquiryChecked, page);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS, response));
      return response || [];
    }
  };
}

export function getArchivedData(data: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ARCHIVED_DATA_FETCHING));
    const response = await orderEnquiryService.getArchivedData(data, page);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ARCHIVED_DATA_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ARCHIVED_DATA_SUCCESS, response));
      return response || [];
    }
  };
}

export function getArchivedDataDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_FETCHING));
    const response = await orderEnquiryService.getArchivedDataDetails(data);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}

export function getFSEEnquiriesList(customerTypeAccess: any, filterParams?: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING));
    const response = await orderEnquiryService.getFSEEnquiriesList(customerTypeAccess, filterParams, page);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS, response));
      return response || [];
    }
  };
}

export function getLimitedFSEEnquiriesList(customerTypeAccess: any, filterParams?: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING));
    const response = await orderEnquiryService.getLimitedFSEEnquiriesList(customerTypeAccess, filterParams, page);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS, response));
      return response || [];
    }
  };
}

export function getlimitedEnquiriesList(customerTierType: any, filterParams: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING));
    const response = await orderEnquiryService.getLimitedEnquiriesList(customerTierType, filterParams, page);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS, response));
      return response || [];
    }
  };
}

export function setOrderEnquiryDetails(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.IS_ORDER_ENQUIRY_DETAILS, data));
  };
}

export function getOrderEnquiryStatus(customerTierType: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_FETCHING));
    const response = await orderEnquiryService.getEnquiryStatus(customerTierType);
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_ENQUIRY_STATUS_SUCCESS, response));
      return response || [];
    }
  }
}

export function getFSEList() {
  return async (dispatch: any) => {
    dispatch(apiStart(ORDER_ENQUIRY.GET_FSE_LIST_FETCHING));
    const response = await orderEnquiryService.getFSEList();
    if (response.error) {
      dispatch(apiError(ORDER_ENQUIRY.GET_FSE_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(ORDER_ENQUIRY.GET_FSE_LIST_SUCCESS, response));
      return response || [];
    }
  }
}


