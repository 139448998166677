/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector } from 'react-redux';
import _, { get } from "lodash";
import { getNewBrand, getNewlyAddedBrand } from '../../common/components/util';
import PlacedMakePaymentDialog from './PlacedMakePaymentDialog';
import { useEffect } from 'react';
import { createUpdateEnquiryService } from '../../services/createUpdateEnquiry.service ';
import { useDispatch } from 'react-redux';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getCreditLimit } from '../../store/actions/creditLimit.actions';
import { getOrderSummary } from '../../store/actions/orderSummary.actions';
import { placeOrder } from '../../store/actions/createUpdateEnquiry.actions';
import { getProfilePlacedSummary } from '../../store/actions/profile.action';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import DeleteDialog from '../../common/components/deleteDialog';
import { Prompt } from 'react-router';

const useStyles = makeStyles(() => ({
    header: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.625rem',
        fontFamily: 'Nunito',
        minWidth: 80
    },
    brand: {
        color: '#444444',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    body: {
        color: '#666666',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    price: {
        color: '#E88B00',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    makePayment: {
        minWidth: "120px !important",
        minHeight: "20px !important",
        border: "1px solid",
        background: '#1A9FE0',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: '#1A9FE0',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        },
        "&:disabled": {
            background: '#8ED1FC',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    orderCreated: {
        minWidth: "120px !important",
        border: "1px solid #4ec766",
        background: '#4ec766',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: '#4ec766',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    bodyColor: {
        color: '#0089ff',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor1: {
        color: '#ff0000',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor2: {
        color: '#008000',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor3: {
        color: '#00ff00',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor4: {
        color: '#800080',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    orderFailed: {
        minWidth: "120px !important",
        border: "1px solid red",
        background: 'red',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: 'red',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    rowCustomSummary: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));

const columns = [
    {
        id: 'brand',
        label: 'Brand',
        align: 'left',
    },
    {
        id: 'orderno',
        label: 'Order No.',
        align: 'left',
    },
    {
        id: 'referenceno',
        label: 'Reference No.',
        align: 'left'
    },
    {
        id: 'lines',
        label: 'Lines',
        align: 'left'
    },
    {
        id: 'price',
        label: 'Price',
        align: 'left'
    },
    {
        id: 'orderStatus',
        label: 'Order Status',
        align: 'left'
    },
];

function getParameterByName(name: any, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export default function PlaceOrderSummary(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const [orderData, setOrderData] = React.useState<any>(null);
    const [orderDataDialog, setOrderDataDiaglog] = React.useState<any>(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [errorSnackBar, setErrorSnackBar] = React.useState(false);
    const { profileDetails, profilePlacedSummary }: any = useSelector((state: any) => state.profileDetails);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const { isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
    const customerTierType = get(profilePlacedSummary, 'data.data.customerInfo.customerTierType', '');
    const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
    const companyCurrencyType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const { dealerDetails }: any = useSelector((state: any) => state.dealer);
    const { orderSummary }: any = useSelector((state: any) => state.orderSummary)
    const companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data) ? dealerDetails.data.data.organizationIds : null;
    const brandIds = companyBrands && companyBrands.map((id: any) => id.organizationId);
    const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
    const sapCustomerId = _.get(profilePlacedSummary, "data.data.basicInfo.sapCustomerId", "");
    let customerAccountType = get(profileDetails, 'data.data.customerInfo.customerAccountType', '');
    const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
    const [deliveryType, setDeliveryType] = React.useState<any>("None");
    const [enquiryData, setenquiryData] = React.useState<any>(null);
    const [loading, setLoading] = React.useState<any>(false);
    const [goToOrder, setGoOrder] = React.useState(false);
    const [offlineData, setOfflineData] = React.useState<any>([]);
    const [address, setAddress] = React.useState<any>('');
    const [dataUpdated, setDataUpdated] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const { offlinePaymentUpdate }: any = useSelector((state: any) => state.orderSummary)
    const collectionPoints = profilePlacedSummary && profilePlacedSummary.data && profilePlacedSummary.data.data && profilePlacedSummary.data.data.customerInfo && profilePlacedSummary.data.data.customerInfo.collectionPointsOfOrder && profilePlacedSummary.data.data.customerInfo.collectionPointsOfOrder.collectionPoints;

    const { enquiryInfo, enquirySessionId }: any = useSelector(
        (state: any) => state.createUpdateEnquiry
    );

    const firstName = get(
        profileDetails,
        "data.data.basicInfo.firstName",
        ""
    );

    const b2cUserId = get(
        profileDetails,
        "data.data.basicInfo.b2cUserId",
        ""
    );

    let addresses = get(
        profilePlacedSummary,
        "data.data.customerInfo.addresses",
        ""
    );


    useEffect(() => {
        setOfflineData(offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"])
        let paymentStatus: any = []
        let gotoOrder = orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((ele: any, i: any) => {
            let obj = offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"][i]
            if (obj && obj.orderNumber === ele.orderNumber && obj.paymentStatus === "Payment Due") {
                paymentStatus.push("Payment due")
            }
        })
        if (paymentStatus.length !== 0) {
            setGoOrder(true)
        } else if (paymentStatus.length === 0) {
            setGoOrder(false)
        }
    }, [offlinePaymentUpdate])

    useEffect(() => {
        let order = orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((ele: any) => {
            if (ele.paymentStatus === "Payment Due") {
                setGoOrder(true)
            }
        })
        let newAddress: any = '';
        if (orderSummary && orderSummary.data && Array.isArray(orderSummary.data) && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList && orderSummary.data[0].orderSummeryList.length > 0) {
            let defaultAddress = addresses && addresses.length > 0 && addresses.find((ele: any) => ele.shiftToPartyCustomerNumber === orderSummary.data[0].orderSummeryList[0].shiftToPartyCustomerNumber);
            if (typeof defaultAddress != 'string') {
                if (typeof defaultAddress == 'object') {
                    let data = addresses.find((item: any) => item.firstAddress)
                    if (defaultAddress) {
                        newAddress = `${defaultAddress.streetName}, ${defaultAddress.region}, ${defaultAddress.city}, ${defaultAddress.country} ,${defaultAddress.postalCode}, ${defaultAddress.shiftToPartyCustomerNumber}`
                    } else {
                        newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
                    }
                } else {
                    let data = addresses.find((item: any) => item.firstAddress)
                    newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
                }
            }
            setAddress(newAddress);
        }
    }, [orderSummary && orderSummary.data && orderSummary.data, addresses])

    useEffect(() => {
        if (profilePlacedSummary && profilePlacedSummary.data && profilePlacedSummary.data.data && profilePlacedSummary.data.data.basicInfo && profilePlacedSummary.data.data.basicInfo.sapCustomerId) {
            dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
            // dispatch(placeOrder(customerTierType, deliveryType, enquiryData[0].B2bEnquiryId, firstName, b2cUserId, enquirySessionId));
        }
    }, [profilePlacedSummary]) // eslint-disable-line

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };


    const handleopenClose = (open?: any) => {
        setConfirmDialog(open ? true : false);
        setSuccessMessage(null);
    };


    useEffect(() => {
        const b2cUid = sessionStorage.getItem('userId');
        const currentURL = window.location.href.split("=")
        const currentB2cId = window.location.href.split("&")[2].split("=")[1]
        dispatch(getProfilePlacedSummary(currentB2cId, "dealerUser"))
    }, []);

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleClickOpen = (item: any) => {
        setOrderDataDiaglog(item);
        setOpenDialog(true);
    };

    // const loadOrderData = async () => {
    //     let orderNumber = getParameterByName('orderNo')
    //     let masterId = getParameterByName('masterId')

    //     if (orderNumber) {
    //         let data: any = await dispatch(getOrderSummary(customerTierType, masterId, orderNumber));
    //         //let data = await createUpdateEnquiryService.getOrderSummaryData(customerTierType, orderNumber)
    //         if (data && data.data && Array.isArray(data.data) && data.data.length > 0 && data && data.message.status === "S") {
    //             setOrderData(data.data[0].orderSummeryList)
    //             setSuccessMessage("Your payment is authorized. Order & payment status will get updated soon");
    //             setOpenSnackbar(true);
    //             setErrorSnackBar(false)
    //         } else if (data && data.message && data.message.status && data.message.status !== "S") {
    //             setErrorSnackBar(true)
    //             setSuccessMessage(data && data.message && data.message.message ? data && data.message && data.message.message : data && data.error && data.error);
    //         }
    //     }
    // }

    const loadOrderData = async () => {
        let orderNumber = getParameterByName('orderNo')
        let masterId = getParameterByName('masterId')

        if (orderNumber) {
            let data: any = await dispatch(getOrderSummary(customerTierType, masterId, orderNumber));
            if (data) {
                let order = data && data.data && data.data[0] && data.data[0].orderSummeryList.map((ele: any) => {
                    if (ele.paymentStatus === "Payment in Progress"
                    ) {
                        setOrderData(data.data[0].orderSummeryList)
                        const showStatus = data && data.data && data && data.data[0] && data && data.data && data &&
                            data.data[0].paymentStatusDetails && data.data[0].paymentStatusDetails.paymentInProgress.map((ele: any) => {
                                if (ele && ele.showPopup
                                ) {
                                    setSuccessMessage(ele.showMsg)
                                    setOpenSnackbar(true);
                                    setErrorSnackBar(false)
                                }
                            })

                    } else if (ele.paymentStatus === "Payment Failed" || ele.paymentStatus === "Declined"
                    ) {
                        setOrderData(data.data[0].orderSummeryList)
                        const showStatus = data && data.data && data && data.data[0] && data && data.data && data &&
                            data.data[0].paymentStatusDetails && data.data[0].paymentStatusDetails.paymentFailed.map((ele: any) => {
                                if (ele && ele.showPopup
                                ) {
                                    setSuccessMessage(ele.showMsg)
                                    setOpenSnackbar(true);
                                    setErrorSnackBar(true)
                                }
                            })
                        setSuccessMessage(data && data.data && data && data.data[0] && data && data.data && data && data.data[0].paymentStatusDetails && data.data[0].paymentStatusDetails.paymentFailed[0] && data.data[0].paymentStatusDetails.paymentFailed[0].showMsg)

                    } else if (
                        ele.paymentStatus === "Payment Received"
                    ) {
                        setOrderData(data.data[0].orderSummeryList)
                        // setSuccessMessage("Your payment is authorized. Order & payment status will get updated soon");
                        // setOpenSnackbar(true);
                        // setErrorSnackBar(false)
                        const showStatus = data && data.data && data && data.data[0] && data && data.data && data &&
                            data.data[0].paymentStatusDetails && data.data[0].paymentStatusDetails.paymentRecived.map((ele: any) => {
                                if (ele && ele.showPopup
                                ) {
                                    setSuccessMessage("Your payment is authorized. Order & payment status will get updated soon");
                                    setOpenSnackbar(true);
                                    setErrorSnackBar(false)

                                }
                            })
                    }
                })
            }
            else if (data && data.message && data.message.status && data.message.status === "E") {
                setSuccessMessage(data && data.message && data.message.message);
                setOpenSnackbar(true);
                setErrorSnackBar(true)
            }

        }

    }

    const currentURL = window.location.href.split("=")
    const currentB2cId = window.location.href.split("&")[1].split("=")[1]




    // if (typeof address != 'string') {
    //     if (typeof address == 'object') {
    //         let data = address.find((item: any) => item.firstAddress)
    //         if (data) {
    //             address = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
    //         } else {
    //             address = ``
    //         }
    //     } else {
    //         address = ""
    //     }
    // }

    useEffect(() => {
        loadOrderData()

        const b2cUid = sessionStorage.getItem('userId');
        const userType = sessionStorage.getItem('userType');
    }, [])

    const calculatePrice = (orderData: any) => {
        let cal: any = 0;
        let data = orderSummary
        orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.forEach((ele: any) => {
            cal = parseFloat(cal) + parseFloat(ele.price)
        })
        return parseFloat(cal).toFixed(2)
    }


    return (
        <Fragment>
            {loading && (<FullscreenLoader />)}
            {orderSummary && orderSummary.loading && <FullscreenLoader />}
            {orderData && orderData.loading && <FullscreenLoader />}
            <Snackbar
                open={openSnackbar}
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} message={successMessage} />
            <Snackbar
                open={errorSnackBar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} message={successMessage} />
            <div className="row p-4">
                {/* <div className="px-4 row">
                    <div className="row justifyContentHorizontalSpacebetween align-items-center ml-0">
                        <h3 className="page-title px-2">Order Summary</h3>
                    </div>
                </div> */}
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justifyContentHorizontalSpacebetween align-items-center col-12 my-2">
                    <div className="px-2 row col-12 d-flex justify-content-between">
                        <div className="">
                            <h3 className="page-title px-3">Order Summary</h3>
                        </div>
                        <div>
                            <Button variant="contained" color="primary" className="rounded-button-dealer pt-2 pb-2" onClick={() => {
                                history.push('/orderenquiry')
                            }} >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div className="d-flex justifyContentHorizontalSpacebetween align-items-center col-12 my-2">
                        {/* {errorSnackBar !== true && <div className="mr-1"> */}
                        {/* {currentURL && currentURL[4] === "true" ?
                                <div>
                                    <CheckCircleIcon className="order-placed-tick" fontSize="large" />
                                    <span className="order-placed ml-3 mt-2">Order Placed. Please proceed for payment.</span>
                                </div> :
                                <>
                                    <CloseIcon className="order-errorplaced-tick" fontSize="large" />
                                    <span className="order-placed-error ml-3 mt-2">There is some error to update the payment status.</span>
                                </>}  </div>} */}
                        <span className="order-placed-message ml-1 mt-4">Payment details:</span>

                        <h6 className="ml-auto font-15 font-weight-600">
                            {/* <span className="mr-2">
                                <IconButton className="searchbarOptions" size="small">
                                    <RefreshIcon className="searchbarOptions mb-1 cursor-pointer"
                                        fontSize="medium"
                                        onClick={() => {
                                            let orderNumber = getParameterByName('orderNo')
                                            let masterId = getParameterByName('masterId')
                                            dispatch(getOrderSummary(customerTierType, masterId, orderNumber))
                                        }}
                                    ></RefreshIcon>
                                    <span className="font-size-13 load-from-fav ml-1" onClick={() => {
                                        let orderNumber = getParameterByName('orderNo')
                                        let masterId = getParameterByName('masterId')
                                        dispatch(getOrderSummary(customerTierType, masterId, orderNumber))
                                    }}>Refresh</span>
                                </IconButton>
                            </span> */}
                            <span className="ml-auto font-15 font-weight-600"> Date -  {orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0] && orderData[0].B2bEnquiryId ? moment(new Date(orderData[0].createdOn)).utc().format('DD MMM YYYY') : '-'}</span>
                        </h6>
                        {/* <h6 className="ml-auto font-15 font-weight-600">Date -
                            { }
                        </h6>
                        {orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0] && orderData[0].B2bEnquiryId ? moment(new Date(orderData[0].createdOn)).utc().format('DD MMM YYYY') : '-'} */}
                        {/* {orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0] && orderData[0].B2bEnquiryId ? orderData.length> 0 && props.orderData[0] && props.orderData[0].createdOn ? moment(new Date(props.orderData[0].createdOn)).utc().format('DD MMM YYYY') : ''} */}
                    </div>
                    {/* <div className="mr-1">
                        <CheckCircleIcon className="order-placed-tick" fontSize="large" />
                        <span className="order-placed ml-3">Your payment was successfull your order status will update in some time.</span>
                    </div> */}
                    {/* <div className='row col-12 d-flex justify-content-end'>
                        <h6 className="ml-auto font-10 font-weight-600"> {orderData && moment(new Date(orderData[0].createdOn)).utc().format('DD MMM YYYY')}</h6>
                        {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].createdOn ? moment(new Date(props.orderData[0].createdOn)).utc().format('DD MMM YYYY') : ''}
                    </div> */}
                </div>
                <div className="px-4 col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="col-12 border-top w-100 p-0">
                        <div className={`enquiry-number ${classes.rowCustomSummary} justifyContentHorizontalSpacebetween align-items-center`}>
                            <div className='font-15'>Enquiry Number : {orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0] && orderData[0].B2bEnquiryId ? orderData[0].B2bEnquiryId : '-'}</div>
                            <div className='font-15'>Reference Number : {orderData && Array.isArray(orderData) && orderData.length > 0 && orderData[0] && orderData[0].masterReferenceNumber ? orderData[0].masterReferenceNumber : '-'}</div>
                        </div>
                    </div>
                    <TableContainer className="mb-5">
                        <Table>
                            <TableHead className="w-100">
                                <TableRow>
                                    {columns.map((column: any) => (
                                        <TableCell
                                            key={column.id}
                                            className={classes.header}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                    <TableCell className={classes.header}>Payment Status</TableCell>
                                    <TableCell className={classes.header}>Transaction ID </TableCell>
                                    <TableCell className={classes.header}>Request ID </TableCell>
                                    <TableCell className={classes.header}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((item: any, i: any) => {
                                    let priceFinal: any = item && item.price && typeof item.price == 'string' ? parseFloat(item.price) : item.price;
                                    priceFinal = priceFinal.toFixed(2)
                                    if (item) {
                                        return <TableRow>
                                            <TableCell className={!item.orderNumber ? 'text-red' : `${classes.brand}`}>
                                                {/* {getNewBrand(item.make) === 'Tradepoint' && (customerTierType && customerTierType.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? 'ALAC' : getNewBrand(item.make)} */}
                                                {customerTierType && customerTierType.toLowerCase() === "workshop" ? getNewBrand(item.make) : getNewlyAddedBrand(item.make)}
                                                {/* {getNewBrand(item.make)} */}
                                                {!item.orderNumber && item.status && !(item.status.toLowerCase().includes('failed')) && (
                                                    <small className="text-red position-absolute font-weight-normal d-block mt-1">{item.sapOrderMessage} </small>
                                                )}
                                                {item.sapOrderMessage && item.sapOrderMessage.includes("not allow external procurement") && (
                                                    <small className="text-red position-absolute font-weight-normal d-block mt-1">Some parts are not allowed for external procurement</small>
                                                )}
                                            </TableCell>
                                            <TableCell className={classes.body}>{item.orderNumber}</TableCell>
                                            <TableCell className={classes.body}>{item.enquiryReferenceNumber}</TableCell>
                                            <TableCell className={classes.body}>{item.lines}</TableCell>
                                            <TableCell className={!item.orderNumber ? 'text-red' : `${classes.body}`}>
                                                {customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {priceFinal}</TableCell>
                                            <TableCell className={item.status && typeof item.status == 'string' && item.status === "Back Order Created" || item.status === "Back order created" ? classes.bodyColor : classes.bodyColor2}>
                                                {item.status && typeof item.status == 'string' && item.status}
                                            </TableCell>
                                            {/* <TableCell className={item.paymentStatus === "Payment Due" ? classes.bodyColor1 : item.paymentStatus === "Expired" ? classes.bodyColor1 : item.paymentStatus === "Declined" ? classes.bodyColor1 : item.paymentStatus === "Offline Payment Pending" ? classes.bodyColor2 : item.paymentStatus === "Payment Received" ? classes.bodyColor2 : classes.bodyColor4}>
                                                {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                                            </TableCell> */}
                                            {item.paymentStatus === "Credit Utilized" || item.paymentStatus === "Payment Received" && (item.status && typeof item.status == 'string') ?
                                                <TableCell className='row'>
                                                    <span className='row'>
                                                        <p className="row font-colorchanges pl-4 mt-1 pr-3">
                                                            {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                                                        </p>
                                                        {isSellingPriceData &&
                                                            <p className='row'>
                                                                {item.paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? "" :
                                                                    <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                                                        <p className='pl-3'> <ErrorIcon className='icon-color' /> </p>
                                                                    </Tooltip>
                                                                }
                                                            </p>}
                                                    </span>
                                                </TableCell>
                                                :
                                                <TableCell className={item.paymentStatus === "Payment Due" ? classes.bodyColor1 : item.paymentStatus === "Expired" ? classes.bodyColor1 : item.paymentStatus === "Declined" ? classes.bodyColor1 : item.paymentStatus === "Offline Payment Pending" ? classes.bodyColor2 : item.paymentStatus === "Payment Received" ? classes.bodyColor2 : classes.bodyColor4}>
                                                    {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                                                </TableCell>
                                            }

                                            <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.TransactionID : '-'}</TableCell>
                                            <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.requestId : '-'}</TableCell>
                                            {(customerTierType.toLowerCase() === "tpcdealer" || customerTierType.toLowerCase() === "workshop") && customerAccountType === 'Credit' ? '' :
                                                <>
                                                    {(item.paymentStatus === "Credit Utilized" || item.paymentStatus === "Payment Received" && (item.status && typeof item.status == 'string')) ?
                                                        <TableCell>
                                                            {isSellingPriceData && <>
                                                                {item.paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? "" :
                                                                    <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                                                        <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                                                    </Tooltip>}
                                                                { } <Button
                                                                    className={classes.makePayment}
                                                                    size="small"
                                                                    disabled={item.paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment in Progress" || item && item.paymentStatus === "Payment in Progress" ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                                                    variant="contained"
                                                                    onClick={() => { handleClickOpen(item); setOpenDialog(true); setOrderDataDiaglog(item); sessionStorage.setItem("placed_order_index", i) }}
                                                                > Make Payment
                                                                </Button></>}
                                                        </TableCell>
                                                        : <TableCell>
                                                            {isSellingPriceData && <Button
                                                                className={classes.makePayment}
                                                                size="small"
                                                                variant="contained"
                                                                disabled={priceFinal === "0.00" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment in Progress" || item && item.paymentStatus === "Payment in Progress" ||
                                                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                                                onClick={() => { handleClickOpen(item); setOpenDialog(true); sessionStorage.setItem("placed_order_index", i) }}
                                                            > Make Payment
                                                            </Button>}
                                                        </TableCell>
                                                    }
                                                </>
                                            }
                                        </TableRow>
                                    }
                                    return null
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                <div className="px-4 col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="row no-gutters table-bottom p-0">
                        <div className="col-lg-3 col-md-3 col-sm-6 p-3 border">
                            <p className="bottom-field-name-table">
                                Total Price
                            </p>
                            <p className="bottom-field-value-table ">
                                {customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {orderData && orderData ? calculatePrice(orderData) : "0"}
                                <span className="vat ml-1">(Incl. of VAT)</span>
                            </p>
                        </div>
                        {/* <div className="col-lg-6 col-md-6 col-sm-12 p-3 border">
                            <p className="bottom-field-name-table">
                                Office Address
                            </p>
                            <p className="bottom-field-value-table">
                                {address}
                            </p>
                        </div> */}
                        {customerTierType.toLowerCase() === "workshop" || customerTierType === "Workshop" ?
                            <>
                                {orderData && Array.isArray(orderData) && orderData.map((item: any, i: any) => {
                                    return <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                                        <p className="bottom-field-name-table">
                                            {item.deliveryType === "Onsite Delivery" ? "Shipping Address" : item.deliveryType === "Self Pickup" ? "Collection Point" : ""}
                                        </p>
                                        {item.deliveryType === "Onsite Delivery" ?
                                            <p className="bottom-field-value-table">
                                                {address}
                                            </p>
                                            :
                                            item.deliveryType === "Self Pickup" ?
                                                <p className="bottom-field-value-table">
                                                    {collectionPoints && collectionPoints.length > 0 && collectionPoints.map((ele: any) => {
                                                        return <p> {ele.defineTPCounter ? ele.defineTPCounter : ""}, {" "} {ele.area ? ele.area : ""}, {" "} {ele.landlinePhone ? "Landline No :" : ""} {" "} {ele.landlinePhone ? ele.landlinePhone : ""}</p>
                                                    })}
                                                </p>
                                                :
                                                ""
                                        }
                                    </div>
                                })}
                            </>
                            : ''}
                        {customerTierType.toLowerCase() !== "workshop" || customerTierType !== "Workshop" ?
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                                <p className="bottom-field-name-table">
                                    Office Address
                                </p>
                                <p className="bottom-field-value-table">
                                    {address}
                                </p>
                            </div>
                            : ''
                        }
                        <div className="col-lg-3 col-md-3 col-sm-6 p-2 border d-flex justify-content-center">
                            <Button variant="contained" color="primary" className="mt-2 gotoOrderHistory" onClick={() => {
                                if (goToOrder) {
                                    setDataUpdated(true); handleopenClose(true);
                                } else {
                                    history.push("/orderhistory")
                                }
                            }}>
                                Go to Order History
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <>
                <DeleteDialog
                    open={confirmDialog && dataUpdated}
                    text={<div>
                        <h6 className='font-text mt-0'>Would you like to make payment later?</h6>
                        <p className='mt-1'>If not paid within 24 hours, your order will get automatically cancelled. Delivery will not be scheduled until payment is made.</p>
                    </div>}
                    handleopenClose={(data: any) => {
                        if (data === 'delete') { history.push('/orderhistory'); setDataUpdated(false); handleopenClose(false); }
                        if (!data) { handleopenClose(false); }
                    }} />
                <Dialog className="pb-0"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <PlacedMakePaymentDialog
                        orderData={orderDataDialog} setSuccessMessage={setSuccessMessage}
                        setOpenErrorSnackbar={setOpenErrorSnackbar}
                        openErrorSnackbar={openErrorSnackbar}
                        handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog}

                    />
                    {/* <MakePaymentDialog
                    orderData={orderData} setSuccessMessage={setSuccessMessage}
                    setOpenErrorSnackbar={setOpenErrorSnackbar}
                    openErrorSnackbar={openErrorSnackbar}
                    handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog}
                /> */}
                </Dialog>
                {goToOrder && <Prompt when={window.location.pathname == '/orderhistory'} message={"Would you like to make payment later?"}></Prompt>}
            </>
        </Fragment >
    )
}
