/* eslint-disable */
import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment-timezone";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import _, { get } from "lodash";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import XLSX from "xlsx";
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../common/components/dateRangePicker';
import TablePagination from "@material-ui/core/TablePagination";
import { getDocumentReport, setDocumentReportData } from "../../store/actions/tasks.actions";
import { isEmpty } from 'lodash';

const useStyles = makeStyles((theme) => ({
  tableHeadSmallBrand: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 19,
    lineHeight: 0,
    width: 200,
  },
  tableHeading: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 200,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 20,
    lineHeight: 0,
    width: 140,
  },
  body: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 180,
  },
  bodyFile: {
    color: '#1A9FE0',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 200,
    cursor: "pointer",
    textDecoration: "underline"
  }
}));

const DocumentReportDialog = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles()
  const { documentReports }: any = useSelector((state: any) => state.tasks)
  const [searchText, setsearchText] = React.useState<any>('');

  const [startDate, setstartDate] = React.useState<any>(null);
  const [endDate, setendDate] = React.useState<any>(null);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
  const dispatch: Dispatch<any> = useDispatch();

  const exportData = () => {
    if (documentReports && documentReports.data && Array.isArray(documentReports.data.documentList) && documentReports.data.documentList.length > 0) {
      let filename = "documentReports.xlsx";
      let data: any = [];
      documentReports.data.documentList.map((item: any) => {
        data.push({
          'Customer Name': item.companyName,
          'Document Name': item.docType,
          'Expiry Date': moment(item.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD')
        });
      });
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  }

  const documentReport = async (newPage?: number) => {
    let data: any = {
      "sapCustomerId": searchText ? searchText : '',
      "customerName": searchText ? searchText : '',
      "webApplicationLoginId": webApplicationLoginId ? webApplicationLoginId : sessionStorage.getItem('webApplicationLoginId'),
      "fromDate": startDate ? startDate : '',
      "toDate": endDate ? endDate : '',
    }
    await dispatch(getDocumentReport(data));
  };

  const loadDocumentReport = async () => {
    if (searchText === '' && !startDate && !endDate) {
      await dispatch(setDocumentReportData(null));
    } else {
      let data: any = {
        "sapCustomerId": searchText ? searchText : '',
        "customerName": searchText ? searchText : '',
        "webApplicationLoginId": webApplicationLoginId ? webApplicationLoginId : sessionStorage.getItem('webApplicationLoginId'),
        "fromDate": startDate ? startDate : '',
        "toDate": endDate ? endDate : '',
      }
      setLoading(true);
      await dispatch(getDocumentReport(data));
      setLoading(false);
    }
  }


  return (
    <div>
      <React.Fragment>
        <Snackbar
          open={false}
          type="error"
          handleopenClose={() => {
            // handleopenCloseErrorSnackbar(false)
          }} message={''} />
        {loading && (<FullscreenLoader />)}
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog open={props.open ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleOpenCloseReportPopup(false);
            }
          }}
          fullWidth={true}
          maxWidth="md">
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">Document Report
              <IconButton aria-label="close" className="float-right mb-1" onClick={() => props.handleOpenCloseReportPopup(false)}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="row bg-secondary p-3">
              <TextField
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value);
                }}
                size="small"
                className="col-4 col-sm-4 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs1 searchField" label="Search by Customer Name / ID" variant="outlined" />
              <div className="col-5 col-sm-5 col-md-5 col-lg-5 d-inline-flex date-picker-50 w-100">
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setstartDate={setstartDate}
                  setendDate={setendDate}
                  // isEnableOnlyThirtyDays={true}
                  isDisableBeforeOneYear={false}
                  onChange={(start: any, end: any) => {
                    setstartDate(start);
                    setendDate(end);
                  }}
                />
              </div>
              <Button
                className="font-size-11 addOrderEnqButton"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  loadDocumentReport();
                }}
              >Search
              </Button>
            </div>
            <h6 className="font-weight-700 ml-2 mt-2">List of Reports</h6>
            <div className="cardCommon h-300 mt-2 over-flow-auto">
              <TableContainer>
                <Table aria-label="table" >
                  <TableHead className="w-100">
                    <TableRow>
                      <TableCell className={classes.tableHead}>Customer Name</TableCell>
                      <TableCell className={`px-2 ${classes.tableHeadSmallBrand}`}>Expiry Date</TableCell>
                      <TableCell className={classes.tableHeading}>Document Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {documentReports && documentReports.data && Array.isArray(documentReports.data.documentList) && documentReports.data.documentList.map((data: any, index: any) => {
                      return (
                        <TableRow>
                          <TableCell className={classes.body}>{data.companyName}</TableCell>
                          <TableCell className={data.expiryDate ? `mt-3 ${classes.body}` : `px-5 mt-3 ${classes.body}`}>{data.expiryDate ? moment(data.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD') : "-"}</TableCell>
                          <TableCell className={classes.bodyFile}>
                            <span className="cursor-pointer" onClick={() => {
                              window.open(data.url)
                            }}><img src="./fileArrowDown.svg" className="mr-2 w-12px" />{data.docType ? data.docType : 'Document'}</span>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {isEmpty(documentReports && documentReports.data && Array.isArray(documentReports.data.documentList) && documentReports.data.documentList) && (
                  <div className="mt-5 justify-content-center mx-auto text-center d-flex mb-5">No Document Reports found. To check the reports please use the filters.</div>
                )}
              </TableContainer>
            </div>
            {/* <div className="mt-2 mr-3 mb-0">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={documentReports && documentReports.data && documentReports.data.count ? documentReports && documentReports.data && documentReports.data.count : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div> */}
          </div>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained"
                color="primary"
                disabled={isEmpty(documentReports && documentReports.data && Array.isArray(documentReports.data.documentList) && documentReports.data.documentList)}
                className="submit-button-chooseCustomer font-size-11 mr-3" onClick={() => { exportData() }}>
                Export to Excel
              </Button>
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            <Button variant="contained" className="submit-button font-size-11 mr-3 mb-3" onClick={() => { props.handleOpenCloseReportPopup(false) }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div >
  )
}
export default DocumentReportDialog;
