
/* eslint-disable */
import React, { forwardRef, Fragment, Dispatch, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { useEffect } from 'react';
import { FormControl, InputAdornment, MenuItem, Tooltip, Checkbox, Button } from '@material-ui/core';
import { Messages, ValidationErrorMessage } from '../../constants/messages';
import ErrorIcon from '@material-ui/icons/Error';
import moment from "moment-timezone";
import { getActualBrand, getNewBrand, getUniqueTimestamp } from '../../common/components/util';
import { editPartnerDetails, getDealerDetails, getPartnerDetails, saveDealerDetails, savePartnerDetails, deletePartnerDetails, setCustomerType, resetValues, getNationality, getAllPartners, getIBCountryAndSalesRegion, getRegion } from '../../store/actions/onboard.actions';
import { get, stubFalse, truncate } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import Snackbar from '../../common/components/Snackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import ActionButtons from './ActionButtons';
import AddNewOwnerPartnerDialog from './addNewOwnerPartnerDialog'
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubmitDialogKAM from '../Dealers/AddDealer/submitYourRequestDialogKam';
import ListItemText from '@material-ui/core/ListItemText';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getAllBrands, getRefreshDataFromSAPCustomerDetails } from "../../store/actions/dealer.action";
import { FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  footer: {
    paddingLeft: 220
  }
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADER COLOUMN CODE
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'nationality', numeric: true, disablePadding: false, label: 'Nationality' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

const DealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [error, setError] = React.useState<any>(null);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const [status, setStatus] = React.useState<any>();
  const [editData, setEditData] = React.useState();
  const [selected, setSelected] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [customerLastName, setCustomerLastName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [companyLastNameError, setCompanyLastNameError] = React.useState<boolean>(false);
  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [emailId, setEmailId] = React.useState<string>("");
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficErrorMessage, setTelephoneOfficeErrorMessage] = React.useState<string>("");
  const [telephoneOfficError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [vatRegistrationNumber, setVatRegistrationNumber] = React.useState<string>("");
  const [vatRegistrationNumberError, setVatRegistrationNumberError] = React.useState<boolean>(false);
  const [invalidVatRegistrationNumber, setInvalidVatRegistrationNumber] = React.useState<boolean>(false);
  const [dealerEmail, setDealerEmail] = React.useState<string>("");
  const [dealerEmailError, setDealerEmailError] = React.useState<boolean>(false);
  const [invalidDealerEmail, setInvalidDealerEmail] = React.useState<boolean>(false);
  const [headOfficeRegion, setHeadOfficeRegion] = React.useState<string>("");
  const [isNewCustomer, setIsNewCustomer] = React.useState<any>();
  const [headOfficeRegionCode, setHeadOfficeRegionCode] = React.useState<string>("");
  const [headOfficeRegionError, setHeadOfficeRegionError] = React.useState<boolean>(false);
  const [invalidHeadOfficeRegion, setInvalidHeadOfficeRegion] = React.useState<boolean>(false);
  const [typeOfTrade, setTypeOfTrade] = React.useState<string>("");
  const [typeOfTradeError, setTypeOfTradeError] = React.useState<boolean>(false);
  const [invalidTypeOfTrade, setInvalidTypeOfTrade] = React.useState<boolean>(false);
  const [dealingIn, setDealingIn] = React.useState<string>("");
  const [dealingInError, setDealingInError] = React.useState<boolean>(false);
  const [invalidDealingIn, setInvalidDealingIn] = React.useState<boolean>(false);
  const [annualTurnover, setAnnualTurnover] = React.useState<string>("");
  const [annualTurnoverError, setAnnualTurnoverError] = React.useState<boolean>(false);
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [headOfficeAddress, setHeadOfficeAddress] = React.useState<any>({});
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [invalidFirstName, setInvalidFirstName] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [invalidLastName, setInvalidLastName] = React.useState<boolean>(false);
  const [ownerDesignation, setOwnerDesignation] = React.useState<string>("");
  const [ownerDesignationError, setOwnerDesignationError] = React.useState<boolean>(false);
  const [invalidOwnerDesignation, setInvalidOwnerDesignation] = React.useState<boolean>(false);
  const [ownerNationality, setOwnerNationality] = React.useState<string>("");
  const [ownerNationalityError, setOwnerNationalityError] = React.useState<boolean>(false);
  const [invalidOwnerNationality, setInvalidOwnerNationality] = React.useState<boolean>(false);
  const [ownerEmailId, setOwnerEmailId] = React.useState<string>("");
  const [ownerEmailIdError, setOwnerEmailIdError] = React.useState<boolean>(false);
  const [invalidOwnerEmailId, setInvalidOwnerEmailId] = React.useState<boolean>(false);
  const [ownerEmiratesIdNo, setOwnerEmiratesIdNo] = React.useState<string>("");
  const [ownerEmiratesIdNoError, setOwnerEmiratesIdNoError] = React.useState<boolean>(false);
  const [invalidOwnerEmiratesIdNo, setInvalidOwnerEmiratesIdNo] = React.useState<boolean>(false);
  const [bankName, setBankName] = React.useState<string>("");
  const [selectedPartnerData, setSelectedPartnerData] = React.useState<any>("");
  const [bankNameError, setBankNameError] = React.useState<boolean>(false);
  const [invalidBankName, setInvalidBankName] = React.useState<boolean>(false);
  const [accountNo, setAccountNo] = React.useState<string>("");
  const [accountNoError, setAccountNoError] = React.useState<boolean>(false);
  const [invalidAccountNo, setInvalidAccountNo] = React.useState<boolean>(false);
  const [contactName, setContactName] = React.useState<string>("");
  const [contactNameError, setContactNameError] = React.useState<boolean>(false);
  const [invalidContactName, setInvalidContactName] = React.useState<boolean>(false);
  const [telephone, setTelephone] = React.useState<string>("");
  const [telephoneError, setTelephoneError] = React.useState<boolean>(false);
  const [invalidTelephone, setInvalidTelephone] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<string>("");
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [invalidAddress, setInvalidAddress] = React.useState<boolean>(false);
  const [selectBrand, setSelectBrand] = React.useState<any>([]);
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [invalidSelectBrand, setInvalidSelectBrand] = React.useState<boolean>(false);
  const [creditLimit, setCreditLimit] = React.useState<string>("");
  const [creditLimitError, setCreditLimitError] = React.useState<boolean>(false);
  const [invalidCreditLimit, setInvalidCreditLimit] = React.useState<boolean>(false);
  const [initalStockOrder, setInitalStockOrder] = React.useState<string>("");
  const [initalStockOrderError, setInitalStockOrderError] = React.useState<boolean>(false);
  const [invalidInitalStockOrder, setInvalidInitalStockOrder] = React.useState<boolean>(false);
  const [totalInvestment, setTotalInvestment] = React.useState<string>("");
  const [totalInvestmentError, setTotalInvestmentError] = React.useState<boolean>(false);
  const [invalidTotalInvestment, setInvalidTotalInvestment] = React.useState<boolean>(false);
  const [otherBusinessEstablishments, setOtherBusinessEstablishments] = React.useState<string>("");
  const [otherBusinessEstablishmentsError, setOtherBusinessEstablishmentsError] = React.useState<boolean>(false);
  const [invalidOtherBusinessEstablishments, setInvalidOtherBusinessEstablishments] = React.useState<boolean>(false);
  const [otherBrandsProductsSold, setOtherBrandsProductsSold] = React.useState<string>("");
  const [otherBrandsProductsSoldError, setOtherBrandsProductsSoldError] = React.useState<boolean>(false);
  const [invalidOtherBrandsProductsSold, setInvalidOtherBrandsProductsSold] = React.useState<boolean>(false);
  const [creditLimitSelector, setCreditLimitSelector] = React.useState<string>("AED");
  const [creditLimitSelectorError, setCreditLimitSelectorError] = React.useState<boolean>(false);
  const [invalidCreditLimitSelector, setInvalidCreditLimitSelector] = React.useState<boolean>(false);
  const [initalStockOrderSelector, setInitalStockOrderSelector] = React.useState<string>("");
  const [initalStockOrderSelectorError, setInitalStockOrderSelectorError] = React.useState<boolean>(false);
  const [invalidInitalStockOrderSelector, setInvalidInitalStockOrderSelector] = React.useState<boolean>(false);
  const [totalInvestmentSelector, setTotalInvestmentSelector] = React.useState<string>("AED");
  const [totalInvestmentSelectorError, setTotalInvestmentSelectorError] = React.useState<boolean>(false);
  const [invalidTotalInvestmentSelector, setInvalidTotalInvestmentSelector] = React.useState<boolean>(false);
  const [annualTurnoverSelector, setAnnualTurnoverSelector] = React.useState<string>("AED");
  const [annualTurnoverSelectorError, setAnnualTurnoverSelectorError] = React.useState<boolean>(false);
  const [invalidAnnualTurnoverSelector, setInvalidAnnualTurnoverSelector] = React.useState<boolean>(false);
  const [streetName, setstreetName] = React.useState<string>("");
  const [OtherBrandsProductsSoldErrorMessage, setOtherBrandsProductsSoldErrorMessage] = React.useState<string>("");
  const [streetNameError, setstreetNameError] = React.useState<boolean>(false);
  const [invalidstreetName, setInvalidstreetName] = React.useState<boolean>(false);
  const [customerAccountTypeError, setcustomerAccountTypeError] = React.useState<boolean>(false);
  const [invalidcustomerAccountType, setInvalidcustomerAccountType] = React.useState<boolean>(false);
  const [city, setcity] = React.useState<string>("");
  const [cityError, setcityError] = React.useState<boolean>(false);
  const [invalidcity, setInvalidcity] = React.useState<boolean>(false)
  const [country, setCountry] = React.useState<string>("");
  const [countryError, setCountryError] = React.useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = React.useState<boolean>(false);
  const [countryErrorMessage, setCountryErrorMessage] = React.useState<string>("");
  const [invalidcountry, setInvalidcountry] = React.useState<boolean>(false)
  const [postalCode, setpostalCode] = React.useState<string>("");
  const [postalCodeError, setpostalCodeError] = React.useState<boolean>(false);
  const [invalidpostalCode, setInvalidpostalCode] = React.useState<boolean>(false)
  const [brandLists, setBrandList] = React.useState<any>([]);
  const [brandListError, setBrandListError] = React.useState<boolean>(false);
  const [invalidBrandList, setInvalidBrandList] = React.useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = React.useState<any>();
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [transportationZone, setTransportationZone] = React.useState<any>("");
  const [transportationZoneCode, setTransportationZoneCode] = React.useState<any>("");
  const [isExisting, setIsExisting] = React.useState<any>("");
  const [shiftToPartyCustomerNumber, setShiftToPartyCustomerNumber] = React.useState<any>("");
  const [loading, setLoading] = React.useState<any>(false);
  const currencies = ["AED"];
  const dealingInOptions = ['Corporate', 'Patnership', 'Sole Patnership'];
  const [shippingLabels, setShippingLabels] = React.useState<any>([{ name: "SORT1", value: '' }, { name: "SORT2", value: '' }]);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const { customerDetails, regionData, dealingInData, typeofTradeData,
    savePartnerData, editPartnerData, deletePartnerData, dealerDetails, getAllPartnersData, getCustomerDetailsFromSAPOnClickRefresh
  }: any = useSelector((state: any) => state.onboard);
  const { brandsList, selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
  const [tradeReference, setTradeReference] = React.useState<any>([]);
  const [selectedDealerInfo, setselectedDealerInfo] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const classes = useStyles();
  const [tradeRowRefDeleteIndex, setTradeRowRefDeleteIndex] = React.useState<any>();
  const [typeOfDelete, setTypeOfDelete] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [OtherBusinessEstablishmentsErrorMessage, setOtherBusinessEstablishmentsErrorMessage] = React.useState<string>("");
  const [tradeReferenceErrors, setTradeReferenceErrors] = React.useState<any>([]);
  const [customer, setCustomer] = React.useState<any>("");
  const customerAccountTypes = ["Credit", "Cash"];
  const [customerAccountType, setCustomerAccountType] = React.useState<string>("Cash");
  const [customerTierType, setCustomerTierType] = React.useState("")
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };
  const { getNationalityData, getIBCountryAndRegionData, customerTypeData }: any = useSelector((state: any) => state.onboard);
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  let userType = sessionStorage.getItem('userType');
  let userTypeByProfileAPI = sessionStorage.getItem('userType-Profile-API');

  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const [mobileNumberError, setMobileNumberError] = React.useState<boolean>(false);
  const [regionDataNew, setRegionData] = React.useState<any>([]);
  const [salesRegion, setSalesRegion] = React.useState<any>("");
  const groupCode = sessionStorage.getItem('groupCode');
  const customerStatus = sessionStorage.getItem('status');
  const [isChangeCountry, setIsChangeCountry] = React.useState(false);
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const displayName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.displayName;
  const [selectedIBCurrency, setSelectedIBCurrency] = React.useState<any>("");
  const [ibCurrency, setIbCurrency] = React.useState<any>("");
  const [ibCurrencyError, setIbCurrencyError] = React.useState<boolean>(false);
  let onboardingType: any = sessionStorage.getItem('customerOnboardingType');
  const [customerLastNameError, setCustomerLastNameError] = React.useState<boolean>(false);
  const [companyLastNameErrorMessage, setCompanyLastNameErrorMessage] = React.useState<string>("");

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      if (customerTypeAccess.includes("intbusiness")) {
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess])

  useEffect(() => {
    if (!dataFetched) loadPartners();
  }, []);

  const loadPartners = async () => {
    setLoading(true);
    props.setDataUpdated(false)
    handleopenCloseSnackbar(false);
    dispatch(getAllPartners(b2bUserId));
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    await dispatch(getRegion(sessionStorage.getItem('customerOnboardingType')));
    await dispatch(getAllBrands(sessionStorage.getItem('customerOnboardingType')));
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails'));
    if (data && data.data && Object.keys(data.data.companyDetails).length > 0 && !dataFetched) {
      sessionStorage.setItem("customerOnboardingType", data.data.customerTierType)
      let ibCountryData: any;
      if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" || data.data.customerTierType === "Int. Business") {
        ibCountryData = await dispatch(getIBCountryAndSalesRegion());
      }
      setIsNewCustomer(data.data.isNewCustomer);
      setStatus(data.data.status);
      setSalesRegion(data.data.companyDetails.salesRegion);
      if (ibCountryData && ibCountryData.data && ibCountryData.data.countryAndSalesRegion && ibCountryData.data.countryAndSalesRegion.length > 0) {
        const regionData1 = getRegionsByCountryCode(ibCountryData.data.countryAndSalesRegion, data.data.companyDetails.country)
        setRegionData(regionData1);
      }
      let customerAccType = data.data.customerAccountType !== "" ? data.data.customerAccountType : data.data.companyDetails.customerAccountType;
      setCustomerAccountType(customerAccType);
      sessionStorage.setItem('customerTypeDropdown', data && data.data && data.data.customerAccountType)
      sessionStorage.setItem('vatRegistrationNumber', data.data.companyDetails.vatRegistrationNumber)
      setHeadOfficeRegion(data.data.companyDetails.regionCode);
      setHeadOfficeRegionCode(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setEstablishedDate(data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate === "Invalid date" && data && data.data && data.data.customerTierType === "Government" ? null : data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate);
      setTradeReference(data.data.treadReference);
      setselectedDealerInfo(data.data)
      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber)
      setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setDealerEmail(data.data.companyDetails.emails[0].emailId)
      setCountry(data.data.addresses[0].country);
      setPoBox(data.data.companyDetails.poBox);
      setSalesRegion(data.data.companyDetails.salesRegion);
      setIbCurrency(data.data.companyDetails.companyCurrencyType);
      setSelectedIBCurrency(data.data.companyDetails.companyCurrencyType);
      sessionStorage.setItem("ibCurrency", data.data.companyDetails.companyCurrencyType)
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setCustomerType(data.data.isNewCustomer)
      let selectedBrandsTemp: any = []
      if (data.data.customerTierType === 'Workshop' || data.data.customerTierType === 'tpcdealer') {
        if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
          let orgdata: any = [];
          data.data.selectedBrand.map((org: any) => {
            selectedBrandsTemp.push(getNewBrand(org.make));
          })
          setSelectBrand(orgdata);
        }
      } else {
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          let orgdata: any = [];
          data.data.organizationIds.map((org: any) => {
            if (org.organizationId !== '2003')
              selectedBrandsTemp.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make))
          });
          setSelectBrand(orgdata);
        }
      }

      if (data.data.isNewCustomer) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
          tradereferenceItems.push({
            name: element.name,
            addresses: element.addresses,
            companyName: element.companyName,
            telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        });
        if (data.data.treadReference.length == 0) {
          tradereferenceItems.push({
            companyName: "",
            addresses: "",
            name: "",
            telephone: ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        }
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (data && data.data && !data.data.isNewCustomer && tradeReference.length == 0) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradereferenceItems.push({
          companyName: "",
          addresses: "",
          name: "",
          telephone: ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }

      if (data.data.companyDetails.emails && data.data.companyDetails.emails[0])
        setEmailId(data.data.companyDetails.emails[0].emailId)
      if (data.data.companyDetails.telephones && data.data.companyDetails.telephones[0])
        setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setSelectBrand(selectedBrandsTemp)
      setCreditLimit(data.data.initialStockOrder)
      setInitalStockOrderSelector(data.data.stockOrderCurrencyType)
      setTotalInvestment(data.data.totalInvesment)
      setTotalInvestmentSelector(data.data.totalInvesmentCurrencyType)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      if (data.data.addresses && data.data.addresses[0]) {
        setHeadOfficeAddress(data.data.addresses[0])
        setstreetName(data.data.addresses[0].streetName)
        setcity(data.data.addresses[0].city)
        setCountry(data.data.addresses[0].country)
        setpostalCode(data.data.addresses[0].postalCode)
        setShiftToPartyCustomerNumber(data.data.addresses[0].shiftToPartyCustomerNumber)
        setIsExisting(data.data.addresses[0].isExisting)
        setTransportationZoneCode(data.data.addresses[0].transportationZoneCode)
        setTransportationZone(data.data.addresses[0].transportationZone);
        if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
      }
    }
    if (!dataFetched) setDataFetched(true);
    if (true) dispatch(setCustomerType('existing'));
    setLoading(false);
    if (data && data.data && data.data.customerTierType) setCustomer(data && data.data && data.data.customerTierType)
    setCustomerTierType(data && data.data && data.data.isNewCustomer)
  }

  const loadPartnersOnClickOfRefresh = async () => {
    setLoading(true);
    props.setDataUpdated(false)
    handleopenCloseSnackbar(false);
    dispatch(getAllPartners(b2bUserId));
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    dispatch(getRegion(sessionStorage.getItem('customerOnboardingType')));
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails'));
    if (data && data.data && Object.keys(data.data.companyDetails).length > 0 && dataFetched) {
      sessionStorage.setItem("customerOnboardingType", data.data.customerTierType)
      let ibCountryData: any;
      if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" || data.data.customerTierType === "Int. Business") {
        ibCountryData = await dispatch(getIBCountryAndSalesRegion());
      }
      setIsNewCustomer(data.data.isNewCustomer);
      setStatus(data.data.status);
      setSalesRegion(data.data.companyDetails.salesRegion);
      if (ibCountryData && ibCountryData.data && ibCountryData.data.countryAndSalesRegion && ibCountryData.data.countryAndSalesRegion.length > 0) {
        const regionData1 = getRegionsByCountryCode(ibCountryData.data.countryAndSalesRegion, data.data.companyDetails.country)
        setRegionData(regionData1);
      }
      setHeadOfficeRegion(data.data.companyDetails.regionCode);
      setHeadOfficeRegionCode(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setCustomerAccountType(data.data.companyDetails.customerAccountType);
      setEstablishedDate(data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate === "Invalid date" && data && data.data && data.data.customerTierType === "Government" ? null : data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate);
      setTradeReference(data.data.treadReference);
      setselectedDealerInfo(data.data)
      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber)
      setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setDealerEmail(data.data.companyDetails.emails[0].emailId)
      setCountry(data.data.addresses[0].country);
      setPoBox(data.data.companyDetails.poBox);
      setSalesRegion(data.data.companyDetails.salesRegion);
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setCustomerType(data.data.isNewCustomer)
      let selectedBrandsTemp: any = []
      if (data.data.customerTierType === 'Workshop' || data.data.customerTierType === 'tpcdealer') {
        if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
          let orgdata: any = [];
          data.data.selectedBrand.map((org: any) => {
            selectedBrandsTemp.push(getNewBrand(org.make));
          })
          setSelectBrand(orgdata);
        }
      } else {
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          let orgdata: any = [];
          data.data.organizationIds.map((org: any) => {
            if (org.organizationId !== '2003')
              selectedBrandsTemp.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make))
          })
          setSelectBrand(orgdata);
        }
      }

      if (data.data.isNewCustomer) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
          tradereferenceItems.push({
            name: element.name,
            addresses: element.addresses,
            companyName: element.companyName,
            telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        });
        if (data.data.treadReference.length == 0) {
          tradereferenceItems.push({
            companyName: "",
            addresses: "",
            name: "",
            telephone: ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        }
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (data && data.data && !data.data.isNewCustomer && tradeReference.length == 0) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradereferenceItems.push({
          companyName: "",
          addresses: "",
          name: "",
          telephone: ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }

      if (data.data.companyDetails.emails && data.data.companyDetails.emails[0])
        setEmailId(data.data.companyDetails.emails[0].emailId)
      if (data.data.companyDetails.telephones && data.data.companyDetails.telephones[0])
        setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setSelectBrand(selectedBrandsTemp)
      setCreditLimit(data.data.initialStockOrder)
      setInitalStockOrderSelector(data.data.stockOrderCurrencyType)
      setTotalInvestment(data.data.totalInvesment)
      setTotalInvestmentSelector(data.data.totalInvesmentCurrencyType)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      if (data.data.addresses && data.data.addresses[0]) {
        setHeadOfficeAddress(data.data.addresses[0])
        setstreetName(data.data.addresses[0].streetName)
        setcity(data.data.addresses[0].city)
        setCountry(data.data.addresses[0].country)
        setpostalCode(data.data.addresses[0].postalCode)
        setShiftToPartyCustomerNumber(data.data.addresses[0].shiftToPartyCustomerNumber)
        setIsExisting(data.data.addresses[0].isExisting)
        setTransportationZoneCode(data.data.addresses[0].transportationZoneCode)
        setTransportationZone(data.data.addresses[0].transportationZone);
        if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
      }
    }
    setLoading(false);
    if (data && data.data && data.data.customerTierType) setCustomer(data && data.data && data.data.customerTierType)
    setCustomerTierType(data && data.data && data.data.isNewCustomer)
  }

  function resetValue() {
    setIsEdit(false);
    handleClose();
    dispatch(resetValues());
  }

  function isDisabled() {
    if (status === 'Incomplete from KAM' || status === 'Incomplete from FSE' || sessionStorage.getItem('customerOnboardingType') === "Int. Business" && status === 'Incomplete from FSE' || userType === 'FSE' && status === "Awaiting feedback from FSE") {
      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (savePartnerData && savePartnerData.data && savePartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(savePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [savePartnerData]);

  useEffect(() => {
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
      getNationalityData.data[0].countries.sort((a: any, b: any) => {
        let country1 = a.Country.trim();
        let country2 = b.Country.trim();
        return (country1 < country2) ? -1 : (country1 > country2) ? 1 : 0;
      })
    }

    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Code === 'AE') {
          setCountry(r.Code);
        }
      })
    }
    if (country) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === country) {
          setCountry(r);
        }
      })
    }
  }, [country, getNationalityData])

  useEffect(() => {
    if (deletePartnerData && deletePartnerData.data && deletePartnerData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deletePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllPartners(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deletePartnerData]);

  useEffect(() => {
    if (editPartnerData && editPartnerData.data && editPartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(editPartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [editPartnerData]);

  useEffect(() => {
    if (headOfficeRegion !== '' && sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
          setHeadOfficeRegion(r.Region);
          setHeadOfficeRegionCode(r.Code);
          setSelectedRegion(r);
        }
      })
    }
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" && !isChangeCountry) {
      regionDataNew && regionDataNew.length > 0 && regionDataNew.map((r: any) => {
        if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
          setHeadOfficeRegion(r.Region);
          setHeadOfficeRegionCode(r.Code);
          setSelectedRegion(r);
        }
      })
    }
  }, [headOfficeRegion, regionData, regionDataNew]);

  useEffect(() => {
    if (userType !== 'FSE') {
      if (customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.addresses && customerDetails.data.customerInformation.addresses.length > 0 && customerDetails.data.customerInformation.addresses[0].region && customerDetails.data.customerInformation.addresses[0].region !== '') {
        regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
          if ((r.Code === customerDetails.data.customerInformation.addresses[0].region) || (r.Region === customerDetails.data.customerInformation.addresses[0].region)) {
            setHeadOfficeRegion(r.Region);
            setHeadOfficeRegionCode(r.Code);
          }
        })
      }
    }
    const typeOfTrades: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
      return customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.customerGroupCode === ele.groupCode;
    })
    if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
        if (ele.title === "TBL Dealer" && ele.groupCode === "02") {
          return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
        }
      })
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
    } else if (userType !== "FSE" && sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
        return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
      })

      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
    } else {
      // customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.map((ele: any) => {
      //   ele && ele.subType && ele.subType.map((check: any) => {
      //     setTypeOfTrade(check && check.name)
      //   })
      // })
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data.tradeType && typeofTradeData.data.tradeType.filter((ele: any) => {
        return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
      })
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
    }

  }, [customerDetails, regionData, typeofTradeData, typeOfTrade]);

  function getRegionsByCountryCode(arr: any, countryCode: any) {
    return arr.filter((obj: any) => obj.countryCode === countryCode).map((obj: any) => ({ Code: obj.regionCode, Region: obj.regionDesc }));
  }

  const brands = [
    // {
    //   "make": "Famco",
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "organizationId": "2195",
    }
  ]

  const getSalesRegion = (arr: any, country: any) => {
    let filtered = arr.filter((obj: any) => obj.countryCode === country);
    if (filtered.length > 0) {
      setSalesRegion(filtered[0].salesRegion)
    }
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, errorErrorMessage?: any, type?: any) => {
    props.setDataUpdated(true);
    setterName(e.target.value);
    if (type == 'country') {
      setSalesRegion('');
      setIsChangeCountry(true);
      if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
        getSalesRegion(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country);
        const regionArray = getRegionsByCountryCode(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country)
        setRegionData(regionArray);
      }
      setHeadOfficeRegion('');
      setHeadOfficeRegionCode('');
      setSelectedRegion(null);
    }
    if (type == 'region') {
      setIsChangeCountry(false);
    }
    if (type === 'brand') {
      // setterName(e.target.value);
      errorSetterName(false);
    }
    else if (type === 'intialStock') {
      props.setDataUpdated(true);
      setCreditLimit(e.target.value)
      errorSetterName(false);
    }
    else if (type === "brandproductsold") {
      props.setDataUpdated(true);
    }
    else if (type === "vatReg") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (!validateForm(e.target.value, "vatReg")) {
          errorErrorMessage(ValidationErrorMessage.TAX_REGISTRATION_TEXT)
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "telephone") {
      props.setDataUpdated(true);
      if (e.target.value != "" && !customerAcceessForIB || e.target.value != "" && sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
        if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
          errorErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
          errorSetterName(true);
        }
        //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
        else if (e.target.value.charAt(0) != "0") {
          // errorErrorMessage("It should be start with 5 or 0.")
          errorErrorMessage("It should be start with 0.")
          errorSetterName(true);
        }
        else if (e.target.value.length > 9) {
          errorErrorMessage("It should not exceed 9 digits.")
          setTelephoneOfficeError(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      } else if (e.target.value != "" && customerAcceessForIB || e.target.value != "" && sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
        if (e.target.value.length > 30) {
          errorErrorMessage("It should not exceed 30 digits.")
          setTelephoneOfficeError(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "companyName") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          errorErrorMessage(ValidationErrorMessage.LENGTH)
          errorSetterName(true);
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.LENGTH);
        //   errorSetterName(true);

        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
        //   errorSetterName(true);
        // }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    else if (type === "pobox") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (e.target.value.length > 7) {
          errorErrorMessage(ValidationErrorMessage.POBOX_TEXT);
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else {
      if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
        if (sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
          if (errorSetterName) {
            props.setDataUpdated(true);
            if (errorSetterName && type !== 'brand' && typeof e.target.value === "string") {
              if (e.target.value === "" || (e.target.value && e.target.value.trim() === "")) {
                errorSetterName(true);
              }
              else {
                errorErrorMessage("");
                errorSetterName(false);
              }
            }
          }
        }
      }
    }
  }

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => data.push(brand.displayName));
      setSelectBrand(data);
    }
  }

  const handleSelectAllClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setBrandList([]);
    }
  };

  const handleInputChangetradeRef = (e: any, fieldname: any, index: any) => {
    let trade: any = [...tradeReference];
    trade[index][fieldname] = e.target.value
    setTradeReference(trade)
  }

  function validateMobile(number: string) {
    const re = (customerAcceessForIB || sessionStorage.getItem('customerOnboardingType') === "Int. Business") ? /^\d{5,30}$/ : /^\d{10}$/;
    return re.test(number);
  }

  function validateLandline(number: string) {
    const re = /^(?:[1-9]\d*|0)$/;
    return number.length !== 8 || !re.test(number) ? false : true;
  }

  const tradeReferenceItem = (e: any, index: any, type: any) => {
    props.setDataUpdated(true);
    let trade = [...tradeReference];
    let tradeErrors = [...tradeReferenceErrors];

    if (type === "companyName") {
      // setDataUpdated(true);
      trade[index].companyName = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].companyNameError = true;
          tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
        // }
        else {
          tradeErrors[index].companyNameError = false;
          tradeErrors[index].companyNameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].companyNameError = false;
        tradeErrors[index].companyNameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    } else if (type === "name") {
      // setDataUpdated(true);
      trade[index].name = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].nameError = true;
          tradeErrors[index].nameErrorMessage = ValidationErrorMessage.COMPANY_NAME_TEXT;
        }
        else {
          tradeErrors[index].nameError = false;
          tradeErrors[index].nameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].nameError = false;
        tradeErrors[index].nameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (type === "addresses") {
      trade[index].addresses = e.target.value;
      tradeErrors[index].addressError = false;
      tradeErrors[index].addressErrorMessage = "";
    }
    else if (type == "telephone") {
      // setDataUpdated(true);
      trade[index].telephone = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 20) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.ANNUAL_TURNOVER_TEXT;
        }
        else {
          tradeErrors[index].telephoneError = false;
          tradeErrors[index].telephoneErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].telephoneError = false;
        tradeErrors[index].telephoneErrorMessage = "";
      }
    }
    setTradeReferenceErrors(tradeErrors);
    setTradeReference(trade)
  }

  function validateForm(value: any, type: string) {
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      switch (type) {
        case "country":
        // case "bussinessEst":
        case "brandproductsold":
          {
            let re = /^[a-z\d\-_\s]+$/i;
            return !re.test(value) ? false : true;
          }
        case "companyName":
          {
            // let re = /^[a-z\d\-_\s]+$/i;
            return value.length > 250 ? false : true;
          }
        case "vatReg": {
          const re = /^[0-9]{15}$/;
          return !re.test(value) ? false : true;
        }
        case "mobileno": {
          const re = sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? /^\d{5,30}$/ : /^\d{10}$/;;
          return !re.test(value) ? false : true;
        }
        case "telephone":
          {
            const re = (customerAcceessForIB || sessionStorage.getItem('customerOnboardingType') === "Int. Business") ? /^[0-9]{5,30}$/ : /^[0-9]{9}$/;
            return !re.test(value) ? false : true;
          }
      }
    }
  }


  function validateExistingTab() {
    let isValid: boolean = true;
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if ((poBox == "" || poBoxError) && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer") {
          setPoBoxError(true)
          isValid = false;
        }
      }
    }
    // else{
    //   setPoBoxError(false)
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!dealingIn && customer !== "Government") || (dealingIn && dealingIn.trim() === '' && customer !== "Government")) {
        setDealingInError(true);
        isValid = false;
      }
    }
    if (dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "" && userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
      if (telephoneOffice && telephoneOffice.length > 30) {
        setTelephoneOfficeError(true);
        isValid = false;
      }
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (telephoneOffice) {
          //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
          if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
            setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
            setTelephoneOfficeError(true);
            isValid = false;
          }
          //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
          else if (telephoneOffice.charAt(0) != "0") {
            //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
            setTelephoneOfficeErrorMessage("It should be start with 0.")
            setTelephoneOfficeError(true);
            isValid = false;
          }
          else if (telephoneOffice.length > 9) {
            setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
            setTelephoneOfficeError(true);
            isValid = false;
          }
          else {
            setTelephoneOfficeErrorMessage("")
            setTelephoneOfficeError(false);
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!typeOfTrade) || (typeOfTrade && typeOfTrade.trim() === '')) {
        setTypeOfTradeError(true);
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (!establishedDate) {
          setEstablishedDateError(true);
          isValid = false;
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "") {
      if (customerLastName === "") {
        setCustomerLastNameError(true);
        isValid = false;
      }
      else if (customerLastName.length > 250) {
        setCustomerLastNameError(true);
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (error) {
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (establishedDate) {
          var varDate = new Date(establishedDate);
          var today = new Date();
          if (varDate > today) {
            setInvalidDate(true);
            setError('Date should be less than today date.')
            setEstablishedDateError(true);
            isValid = false;
          }
          else {
            setEstablishedDateError(false);
            setInvalidDate(false);
            setError(null)
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      if (ibCurrency === "") {
        isValid = false;
        setIbCurrencyError(true);
        setOpenErrorSnackbar(true);
        handleopenCloseSnackbar(true);
        setSuccessMessage('Please Select Currency Type');
      }
    }
    if (getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length === 0) {
      isValid = false;
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please add atleast one Owner/Partner');
    }
    return isValid;
  }

  function validateNewDealerDetailsTab() {
    let isValid: boolean = true;
    if (!validateExistingTab()) {
      isValid = false;
    }
    if (companyName === "") {
      setCompanyNameError(true);
      isValid = false;
    }
    else if (companyName.length > 250) {
      setCompanyNameError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "") {
      if (customerLastName === "") {
        setCustomerLastNameError(true);
        isValid = false;
      }
      else if (customerLastName.length > 250) {
        setCustomerLastNameError(true);
        isValid = false;
      }
    }
    if (selectBrand.length === 0) {
      setSelectBrandError(true);
      isValid = false;
    }
    else {
      setSelectBrandError(false);
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (vatRegistrationNumber.trim() == "" && customer !== "Workshop" && customer !== "tpcdealer") {
          setVatRegistrationNumberError(true);
          isValid = false;
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (vatRegistrationNumber != "") {
          if (!validateForm(vatRegistrationNumber, 'vatReg')) {
            setVatRegistrationNumberError(true);
            isValid = false;
          }
        }
      }
    }
    if (dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "" && userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (telephoneOffice.trim() == "") {
          setTelephoneOfficeError(true);
          isValid = false;
        }
      }
      if (telephoneOffice && telephoneOffice.length > 30) {
        setTelephoneOfficeError(true);
        isValid = false;
      }
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (telephoneOffice) {
          //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
          if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
            setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
            setTelephoneOfficeError(true);
            isValid = false;
          }
          //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
          else if (telephoneOffice.charAt(0) != "0") {
            //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
            setTelephoneOfficeErrorMessage("It should be start with 0.")
            setTelephoneOfficeError(true);
            isValid = false;
          }
          else if (telephoneOffice.length > 9) {
            setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
            setTelephoneOfficeError(true);
            isValid = false;
          }
          else {
            setTelephoneOfficeErrorMessage("")
            setTelephoneOfficeError(false);
          }
        }
      }
    }
    if ((!country) || (country && country.trim() === undefined)) {
      setCountryError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (userType !== 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Fleet") {
          if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
            if ((!totalInvestment) || (totalInvestment && totalInvestment.trim() === '')) {
              setTotalInvestmentError(true);
              isValid = false;
            }
            else {
              setTotalInvestmentError(false);
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
      if (telephoneOffice && telephoneOffice === "") {
        setTelephoneOfficeError(false);
        setTelephoneOfficeErrorMessage("");
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "Workshop" || sessionStorage.getItem('customerOnboardingType') === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
      if ((!customerAccountType) || (customerAccountType && customerAccountType.trim() === '')) {
        setcustomerAccountTypeError(true);
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
        if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE") {
          if ((!mobileNumber) || (mobileNumber && mobileNumber.trim() === '') || validateMobile(mobileNumber) === false) {
            setMobileNumberError(true);
            isValid = false;
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (userType !== 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Fleet") {
          if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
            if ((!creditLimit) || (creditLimit && creditLimit.trim() === '')) {
              setCreditLimitError(true);
              isValid = false;
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (userType !== 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Fleet") {
          if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
            if (creditLimit.trim() !== "") {
              setInvalidCreditLimit(false);
              setCreditLimitError(false);
            }
          }
        }
      }
    }
    // if (userType !== 'FSE' && customerAcceessForIB === false) {
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!streetName) || (streetName && streetName.trim() === '')) {
        setstreetNameError(true);
        isValid = false;
      }
      if (streetName.trim() !== "") {
        if (streetName.split('').length > 250) {
          setInvalidstreetName(true);
          isValid = false;
        }
        else {
          setInvalidstreetName(false);
        }
      }
    }
    if ((!city) || (city && city.trim() === '')) {
      setcityError(true);
      isValid = false;
    }
    if (customer === "tpcdealer" && customerLastName) {
      setCustomerLastNameError(false);
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if ((!poBox) && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" || (poBox && poBox.trim() === '') && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer") {
          setPoBoxError(true);
          isValid = false;
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') === "tpcdealer") {
        if (poBox.trim() !== "") {
          if (poBox.split('').length > 7 || !(/^[a-z\d\-_\s]+$/i.test(poBox.trim()))) {
            setInvalidPoBox(true);
            isValid = false;
          }
          else {
            setInvalidPoBox(false);
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
          if (poBox.trim() !== "") {
            if (poBox.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(poBox.trim()))) {
              setInvalidPoBox(true);
              isValid = false;
            }
            else {
              setInvalidPoBox(false);
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
          if (userType !== 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Fleet") {
            if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
              if ((!postalCode && customer !== "Government" && customer.toLowerCase() !== "workshop") || (postalCode && postalCode.trim() === '' && customer !== "Government" && customer.toLowerCase() !== "workshop")) {
                setpostalCodeError(true);
                isValid = false;
              }
              else {
                setpostalCodeError(false);
              }
              if (dealerEmail.trim() == "") {
                setDealerEmailError(true);
                isValid = false;
              }
            }
          }
        }
      }
    }
    // if (customer && customer === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "tpcdealer") {
    //   if ((!postalCode) || (postalCode && postalCode.trim() === '')) {
    //     setpostalCodeError(true);
    //     isValid = false;
    //   }
    //   else {
    //     setpostalCodeError(false);
    //   }
    // }

    // if (sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && postalCode) {
    //   setpostalCodeError(false);
    // }else {
    //   setpostalCodeError(true);
    // }
    if (dealerEmail.trim() != "") {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dealerEmail.trim()))) {
        setInvalidDealerEmail(true);
        isValid = false;
      }
      else {
        setInvalidDealerEmail(false);
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
      if (dealerEmail.trim() == "") {
        setDealerEmailError(true);
        isValid = false;
      } else {
        setDealerEmailError(false);
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Government") {
          if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
            if (creditLimit.trim() == "") {
              setCreditLimitError(true);
              isValid = false;
            }
            else {
              setCreditLimitError(false);
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
      if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Government") {
        if (userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
          let trade1 = [...tradeReference];
          let tradeError = [...tradeReferenceErrors]
          trade1 && trade1.length > 0 && trade1.forEach((element: any, index: any) => {
            if (element.companyName == "" || element.companyName.length > 250) {
              isValid = false;
              tradeError[index].companyNameError = true;
            }
            else {
              tradeError[index].companyNameError = false;
            }
            if (element.name == "") {
              isValid = false;
              tradeError[index].nameError = true;
            }
            else {
              tradeError[index].nameError = false;
            }
            if (element.addresses == "") {
              tradeReferenceErrors[index].addressError = true;
              isValid = false;
            }
            else {
              tradeReferenceErrors[index].addressError = false;
            }
            if (element.telephone == "" || element.telephone.length > 20) {
              tradeReferenceErrors[index].telephoneError = true;
              isValid = false;
            }
            else {
              tradeReferenceErrors[index].telephoneError = false;
            }
          })
        }
      }
    }
    return isValid;
  }

  function datePickerValid(e: any, state: any) {
    if (customer !== "Government") {
      if (e === "Invalid Date Format") {
        setError("Invalid Date Format");
        state(true);
      }
    } else {
      var varDate = new Date(establishedDate);
      var today = new Date();
      if (varDate > today) {
        setInvalidDate(true);
        setError('Date should be less than today date.')
        setEstablishedDateError(true);
      }
      else {
        setEstablishedDateError(false);
        state(false);
        setError(null)
      }
    }
  }

  async function saveDetails(isSubmitted?: any) {
    setOpenErrorSnackbar(false);
    // setdataUpdated(false)
    if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === "tpcdealer") {
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === false) {
        if (validateExistingTab()) {
          let selectBrandTemp = selectBrand;
          let selectedBrands: any[] = [];
          let dataOld: any = selectedDealerInfo
          selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
            brands.map((item: any) => {
              if (getActualBrand(element)) {
                if (item && item.make === getActualBrand(element)) {
                  selectedBrands.push(item);
                }
              }
            })
          });
          const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = streetName;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.customerAccountType = customerAccountType;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.emailId = dealerEmail
            headOfficeAddress.region = headOfficeRegionCode;
            headOfficeAddress.dafault = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                name: element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            customerAccountType: customerAccountType,
            "companyDetails": {
              "title": dataOld && dataOld.title,
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              customerAccountType: customerAccountType,
              "vatRegistrationNumber": vatRegistrationNumber,
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": headOfficeRegion,
              "regionCode": headOfficeRegionCode,
              "mobileNumber": mobileNumber,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [{ emailId: dealerEmail ? dealerEmail : emailId, emailType: "PRIMARY" }],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "telephones": [{ country: country, phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
              "poBox": poBox,
              "country": country,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            // "organizationIds": selectedBrands.filter((brand: any) => brand),
            "organizationIds": [
              {
                "make": "OES",
                "organizationId": "2195"
              }
            ],
            "selectedBrand": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": tradeReference,
            "isSubmitted": false,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));

          if (savedData && savedData.message && savedData.message.status === 'S') {
            props.setDataUpdated(false);
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) {
              props.setTabValue(1);
            }
            return true;
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
            return false;
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
      else if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === true) {
        if (validateNewDealerDetailsTab()) {
          let selectBrandTemp = selectBrand;
          let selectedBrands: any[] = [];
          let dataOld: any = selectedDealerInfo
          selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
            brands.map((item: any) => {
              if (getActualBrand(element)) {
                if (item && item.make === getActualBrand(element)) {
                  selectedBrands.push(item);
                }
              }
            })
          });
          // selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.forEach((element: any) => {
          //   selectedBrands.push(brands.filter((item: any) => item && item.make === getActualBrand(element))[0]);
          // });
          const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = streetName;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.customerAccountType = customerAccountType;
            headOfficeAddress.emailId = dealerEmail;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.region = headOfficeRegionCode;
            headOfficeAddress.dafault = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                name: element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
            "isNewCustomer": true,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            customerAccountType: customerAccountType,
            "companyDetails": {
              "title": dataOld && dataOld.title,
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              customerAccountType: customerAccountType,
              "vatRegistrationNumber": vatRegistrationNumber,
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": headOfficeRegion,
              "regionCode": headOfficeRegionCode,
              "mobileNumber": mobileNumber,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [{ emailId: dealerEmail ? dealerEmail : emailId, emailType: "PRIMARY" }],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "telephones": [{ country: country, phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
              "poBox": poBox,
              "country": country,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            // "organizationIds": selectedBrands.filter((brand: any) => brand),
            "organizationIds": [
              {
                "make": "OES",
                "organizationId": "2195"
              }
            ],
            "selectedBrand": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": tradeReference,
            "isSubmitted": false,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));

          if (savedData && savedData.message && savedData.message.status === 'S') {
            props.setDataUpdated(false);
            setOpenErrorSnackbar(false);
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) {
              props.setTabValue(1);
            }
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    } else {
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === false) {
        if (validateExistingTab()) {
          let selectBrandTemp = selectBrand;
          let selectedBrands: any[] = [];
          let dataOld: any = selectedDealerInfo
          selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
            brands.map((item: any) => {
              if (getActualBrand(element)) {
                if (item && item.make === getActualBrand(element)) {
                  selectedBrands.push(item);
                }
              }
            })
          });
          const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = streetName;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.emailId = dealerEmail
            headOfficeAddress.region = headOfficeRegionCode;
            headOfficeAddress.dafault = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
            headOfficeAddress.customerAccountType = customerAccountType;
          }

          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                name: element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "customerAccountType": customerAccountType,
            "companyDetails": {
              "title": dataOld && dataOld.title,
              "companyName": companyName + ' ' + customerLastName,
              "customerAccountType": customerAccountType,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": headOfficeRegion,
              "regionCode": headOfficeRegionCode,
              "mobileNumber": mobileNumber,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [{ emailId: dealerEmail ? dealerEmail : emailId, emailType: "PRIMARY" }],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "telephones": [{ country: country, phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
              "poBox": poBox,
              "country": country,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": tradeReference,
            "isSubmitted": false,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));

          if (savedData && savedData.message && savedData.message.status === 'S') {
            props.setDataUpdated(false);
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) {
              props.setTabValue(1);
            }
            return true;
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
            return false;
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
      else if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer === true) {
        if (validateNewDealerDetailsTab()) {
          let selectBrandTemp = selectBrand;
          let selectedBrands: any[] = [];
          let dataOld: any = selectedDealerInfo
          selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.map((element: any) => {
            brands.map((item: any) => {
              if (getActualBrand(element)) {
                if (item && item.make === getActualBrand(element)) {
                  selectedBrands.push(item);
                }
              }
            })
          });
          // selectBrandTemp && Array.isArray(selectBrandTemp) && selectBrandTemp.forEach((element: any) => {
          //   selectedBrands.push(brands.filter((item: any) => item && item.make === getActualBrand(element))[0]);
          // });
          const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = streetName;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.emailId = dealerEmail;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.region = headOfficeRegionCode;
            headOfficeAddress.dafault = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
            headOfficeAddress.customerAccountType = customerAccountType;
          }

          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                name: element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
            "isNewCustomer": true,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "customerAccountType": customerAccountType,
            "companyDetails": {
              "title": dataOld && dataOld.title,
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "customerAccountType": customerAccountType,
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": headOfficeRegion,
              "regionCode": headOfficeRegionCode,
              "mobileNumber": mobileNumber,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [{ emailId: dealerEmail ? dealerEmail : emailId, emailType: "PRIMARY" }],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "telephones": [{ country: country, phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
              "poBox": poBox,
              "country": country,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": tradeReference,
            "isSubmitted": false,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));

          if (savedData && savedData.message && savedData.message.status === 'S') {
            props.setDataUpdated(false);
            setOpenErrorSnackbar(false);
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) {
              props.setTabValue(1);
            }
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    }
  }

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      if (customer !== 'Int. Business') {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if ((customer && customer.toLowerCase() !== 'workshop' && onboardingType && onboardingType.toLowerCase !== 'workshop' && customer && customer.toLowerCase() !== 'tpcdealer' && onboardingType && onboardingType.toLowerCase !== 'tpcdealer') && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
        // else {
        //   data = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
        // }

        // data = any = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
        setBrandList(data);
      } else {
        // let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco" && brand.brandId !== '2195');
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if ((customer && customer.toLowerCase() !== 'workshop' && onboardingType && onboardingType.toLowerCase !== 'workshop' || customer && customer.toLowerCase() !== 'tpcdealer' && onboardingType && onboardingType.toLowerCase !== 'tpcdealer') && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        setBrandList(data);
      }
    }
  }, [brandsList, customer])

  const handleClose = () => {
    setOpen(false);
  };

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const onClickDeleteAddress = (partnerData: any) => {
    setTypeOfDelete('address')
    setDeleteMessage("Are you sure you want to delete this Owner/Partner ?");
    handleopenClose(true);
    setSelectedPartnerData(partnerData);
  }
  const onClickDeleteTradeRef = () => {
    setTypeOfDelete('trade')
    setDeleteMessage("Are you sure you want to delete this Trade Reference ?");
    handleopenClose(true);
  }

  const deletePartner = () => {
    dispatch(deletePartnerDetails(selectedPartnerData.id, b2bUserId));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    if (!open) setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsView(false);
  };

  const addTradeRow = () => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors];
    if (!isDisabledTradeRow()) {
      if (trade[trade.length - 1].companyName || trade[trade.length - 1].name || trade[trade.length - 1].addresses || trade[trade.length - 1].telephone) {
        const data = {
          companyName: "",
          name: "",
          addresses: "",
          telephone: ""
        }
        const errors =
        {
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        }
        // validateDealerDetailsTradeReferance();
        trade.push(data);
        setTradeReference(trade);
        tradeError.push(errors)
        setTradeReferenceErrors(tradeError);
      }
    }
  }

  const deleteTradeRow = (index: any) => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors]
    trade = trade.filter((data, i) => {
      if (index != i) {
        return data;
      }
    })
    tradeError = trade.filter((data, i) => {
      if (i <= trade.length) {
        return data;
      }
    })
    setTradeReferenceErrors(tradeError);
    setTradeReference(trade);
    handleopenClose(false);
  }

  const isDisabledTradeRow = () => {
    let length = tradeReference.length - 1
    if (tradeReference.length && ((tradeReference[length].companyName == "" || tradeReference[length].name == "" || tradeReference[length].addresses == "" || tradeReference[length].telephone == ""))) {
      // if (tradeReference.length == 1) {
      let tradeError = [...tradeReferenceErrors]
      if (tradeReference[length].companyName == "") {
        tradeError[length].companyNameError = true;
      }
      else {
        tradeError[length].companyNameError = false;
      }
      if (tradeReference[length].name == "") {
        tradeError[length].nameError = true;
      }
      else {
        tradeError[length].nameError = false;
      }
      if (tradeReference[length].addresses == "") {
        tradeReferenceErrors[length].addressError = true;
      }
      else {
        tradeReferenceErrors[length].addressError = false;
      }
      if (tradeReference[length].telephone == "") {
        tradeReferenceErrors[length].telephoneError = true;
      }
      else {
        tradeReferenceErrors[length].telephoneError = false;
      }
      setTradeReferenceErrors(tradeError)
      // }
      return true;
    }
    return false;
  }

  //TABLE HEADER CODE
  function EnhancedTableHead(props: any) {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ?
                headCell.label === "Nationality" ? "" : headCell.label : headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    if (validateExistingTab()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  const handleNextClick = async () => {
    // if (saveDetails(true)) {
    //   props.setTabValue(1);
    // }
    saveDetails(true);
  }

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(b2bUserId, partner.id));
    handleClickOpen();
    setIsView(isView);
    setIsEdit(true);
    setEditData(partner);
  }

  const onchangePobox = (e: any) => {
    props.setDataUpdated(true);
    setPoBox(e.target.value);
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (e.target.value.length > 7) {
        setPoBoxError(true);
      }
      else {
        setPoBoxError(false);
      }
    }
  }

  const onChangeTelephone = (e: any) => {
    props.setDataUpdated(true);
    setTelephoneOffice(e.target.value)
    if (dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "" && userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer") {
      if ((customerAcceessForIB || sessionStorage.getItem('customerOnboardingType') === "Int. Business") === true) {
        if (e.target.value.length > 30) {
          setTelephoneOfficeErrorMessage("It should not exceed 30 digits.")
          setTelephoneOfficeError(true);
        }
        else {
          setTelephoneOfficeErrorMessage("")
          setTelephoneOfficeError(false);
        }
      } else if (userType !== 'FSE' && (customerAcceessForIB === false || sessionStorage.getItem('customerOnboardingType') !== "Int. Business")) {
        if (e.target.value != "") {
          //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
          if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
            setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
            setTelephoneOfficeError(true);
          }
          //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
          else if (e.target.value.charAt(0) != "0") {
            //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
            setTelephoneOfficeErrorMessage("It should be start with 0.")
            setTelephoneOfficeError(true);
          }
          else if (e.target.value.length > 9) {
            setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
            setTelephoneOfficeError(true);
          }
          else {
            setTelephoneOfficeErrorMessage("")
            setTelephoneOfficeError(false);
          }
        }
      } else if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
        if (e.target.value != "") {
          if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
            setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
            setTelephoneOfficeError(true);
          }
          else if (e.target.value.charAt(0) != "0") {
            setTelephoneOfficeErrorMessage("It should be start with 0.")
            setTelephoneOfficeError(true);
          }
          else if (e.target.value.length > 9) {
            setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
            setTelephoneOfficeError(true);
          }
          else {
            setTelephoneOfficeErrorMessage("")
            setTelephoneOfficeError(false);
          }
        }
      }
      else {
        setTelephoneOfficeErrorMessage("")
        setTelephoneOfficeError(false);
      }
    }
  }

  const onChangeOtherBrandProducts = (e: any) => {
    props.setDataUpdated(true);
    setOtherBrandsProductsSold(e.target.value)
  }

  const onChangeBussinessEst = (e: any) => {
    props.setDataUpdated(true);
    setOtherBusinessEstablishments(e.target.value)
  }

  const onChangeCompanyName = (e: any) => {
    props.setDataUpdated(true);
    setCompanyName(e.target.value)
    if (e.target.value != "") {
      // if (!validateForm(e.target.value, 'companyName')) {
      //   setCompanyNameError(true);
      // }
      // else {
      setCompanyNameError(false);
      // }
    }
    else {
      setCompanyNameError(false);
    }
  }

  const onChangeCompanyLastName = (e: any) => {
    props.setDataUpdated(true);
    setCustomerLastName(e.target.value)
    if (sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "") {
      if (e.target.value === "") {
        setCustomerLastNameError(true);
      }
      else {
        setCustomerLastNameError(false);
      }
    }
  }


  const onChangeVatReg = (e: any) => {
    props.setDataUpdated(true);
    setVatRegistrationNumber(e.target.value)
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (e.target.value != "") {
        if (!validateForm(e.target.value, 'vatReg')) {
          setVatRegistrationNumberError(true);
        }
        else {
          setVatRegistrationNumberError(false);
        }
      }
      else {
        setVatRegistrationNumberError(false);
      }
    }
  }

  const onChangeMobileNumber = (e: any) => {
    props.setDataUpdated(true);
    setMobileNumber(e.target.value)
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (e.target.value != "") {
        if (!validateForm(e.target.value, 'mobileno')) {
          setMobileNumberError(true);
        }
        else {
          setMobileNumberError(false);
        }
      }
      else {
        setMobileNumberError(false);
      }
    } if (customerAcceessForIB === true || sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      if (e.target.value != "") {
        if (validateMobile(e.target.value) === false) {
          setMobileNumberError(true);
        }
        else {
          setMobileNumberError(false);
        }
      }
    }
  }

  const handleChange = (event: any) => {
    setSelectedIBCurrency(event.target.value);
    setIbCurrency(event.target.value);
  };

  return (
    <Fragment>
      {/* <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} text={successMessage} /> */}
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
        <div className="d-flex flex-row border-bottom mb-2 pl-3">
          <div><h6 className="page-title pl-4 mt-3" style={{ fontSize: "15px" }}>Currency Type :</h6></div>
          <div className="row">
            <FormControl component="fieldset" name="customer-type" className="pl-4">
              <RadioGroup className="mt-2 ml-4" row onChange={handleChange} value={selectedIBCurrency}>
                <FormControlLabel
                  value={"AED"}
                  control={<Radio color="primary" />}
                  label="AED" disabled={isDisabled()} />
                <FormControlLabel
                  value={"USD"}
                  control={<Radio color="primary" />}
                  label="USD"
                  disabled={isDisabled()}
                />
              </RadioGroup>
              {ibCurrencyError && <span className="px-3" style={{ color: 'red' }}>{"Please select currency type"}</span>}
            </FormControl>
          </div>
          <div></div>
        </div>
        : ""}
      {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ?
        <div className="card col-12 m-0 p-0">
          <div className="row mb-0 px-2">
            <div className="col-12 mt-1">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title px-2 mt-1"></h5>
                <div className="row-space-start ml-auto">
                  <Tooltip title="Pull real-time information from SAP." placement="top">
                    <Button
                      className="mr-2 text-info"
                      startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                      onClick={async () => {
                        setLoading(true);
                        let data: any = await dispatch(getRefreshDataFromSAPCustomerDetails("refreshCustomerDetails", b2bUserId, webApplicationLoginId, displayName.replace(/\s+/g, ' ')));
                        if (data && data.status === 400 || data && data.status === "E" || data && data.message && data.message.status === "E") {
                          setOpenErrorSnackbar(true);
                          handleopenCloseSnackbar(true);
                          setSuccessMessage(data.error);
                          setLoading(false);
                        } else {
                          loadPartnersOnClickOfRefresh();
                          setSuccessMessage(data && data.message && data.message.message)
                          handleopenCloseSnackbar(true);
                          setSelectedIBCurrency(data && data.data && data.data.companyDetails && data.data.companyDetails.companyCurrencyType);
                          setIbCurrency(data && data.data && data.data.companyDetails && data.data.companyDetails.companyCurrencyType);
                          setLoading(false);
                        }
                      }}
                    ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Refresh
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        : ""}
      {sessionStorage.setItem('customerIdGenrated', dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId)}
      <div className="row m-0 p-4">
        {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ? "" :
          <div className="col-12">
            <h6 className="dealer-table-content mb-2">Company Details</h6>
          </div>}
        {loading && (<FullscreenLoader />)}
        <>
          <div className="col-md-4 col-lg-4">
            <TextField required id="companyName"
              value={companyName}
              error={companyNameError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              onChange={(e: any) => onChangeCompanyName(e)}
              label="Company Name Line 1" className="commonRoundedInputs w-100"
              inputProps={{ maxLength: 40 }}
              helperText={companyNameError && companyName.length > 0 && <span style={{ color: 'red' }}>{ValidationErrorMessage.LENGTH}</span>}
              InputProps={{
                startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
              }} margin="dense" variant="outlined" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="companyNameLine2"
              value={customerLastName}
              error={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && !customerLastName && dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId === "" ? customerLastNameError : false}
              helperText={customerLastNameError && <span style={{ color: 'red' }}>{companyLastNameErrorMessage}</span>}
              required={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? true : false}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              onChange={(e: any) => onChangeCompanyLastName(e)}
              inputProps={{ maxLength: 40 }}
              label="Company Name Line 2" className="commonRoundedInputs w-100"
              InputProps={{
                // startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
              }} margin="dense" variant="outlined" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="vatRegistrationNumber"
              type="number"
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              value={vatRegistrationNumber}
              error={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : vatRegistrationNumberError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : vatRegistrationNumberError && <span style={{ color: 'red' }}>{ValidationErrorMessage.VAT_TEXT}</span>}
              onChange={(e: any) => onChangeVatReg(e)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="VAT Registration Number" variant="outlined" margin="dense" required={sessionStorage.getItem('customerOnboardingType') === "Fleet" ? true : sessionStorage.getItem('customerOnboardingType') === "Government" ? true : sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" && customerAccountType === "Cash" ? false : sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" && customerAccountType === "Credit" ? true : customer === "Workshop" || customer === 'tpcdealer' && customer !== "TBL Dealer" ? false : userType === 'FSE' && customer !== "TBL Dealer" || sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customerAcceessForIB || customer !== "TBL Dealer" || sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customerAcceessForIB && customerStatus === "Submitted by FSE" || sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" || sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : true} />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="telephoneOffice"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : telephoneOfficError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              helperText={(telephoneOffice.length < 30 && (userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE")) ? false : telephoneOfficError && telephoneOfficErrorMessage.length > 0 && <span style={{ color: 'red' }}>{telephoneOfficErrorMessage}</span>}
              onChange={(e: any) => onChangeTelephone(e)}
              value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Contact Telephone Number" : "Telephone Office(Landline No.)"}
              required={sessionStorage.getItem('customerOnboardingType') !== "Int. Business"} variant="outlined" margin="dense" />
          </div>
          {(sessionStorage.getItem('customerOnboardingType') === "Int. Business" && customerAcceessForIB) && (customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE") || sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ?
            <div className="col-md-4 col-lg-4">
              <TextField id="mobileno"
                type="number"
                required={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : true}
                error={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : mobileNumberError}
                helperText={mobileNumberError && <span style={{ color: 'red' }}>It can be upto 30 digits.</span>}
                disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
                onChange={(e: any) => onChangeMobileNumber(e)}
                value={mobileNumber} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Mobile Number" variant="outlined" margin="dense" />
            </div>
            : ""}
          <div className="col-md-4 col-lg-4">
            <KeyboardDatePicker required={customer === "Workshop" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" || customer === 'tpcdealer' ? true : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : customer !== "Government" ? true : false}
              disableFuture
              views={["year", "month", "date"]}
              openTo="year"
              autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
              onError={(e, v) => datePickerValid(e, setInvalidDate)}
              disabled={isDisabled()}
              format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
              onChange={(date: Date | null) => {
                props.setDataUpdated(true);
                setEstablishedDate(date);
                // if (date) {

                //   setEstablishedDateError(false)
                // } else {
                //   setEstablishedDateError(true)
                // }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              className="rounded-input-dealer w-100 mt-2 cal-icon"
              error={(userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Fleet" && !establishedDate) ? true : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : establishedDateError || invalidDate}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : invalidDate && <span style={{ color: 'red' }}>{error}</span>}
            />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="country"
              error={countryError}
              value={country}
              disabled={(sessionStorage.getItem('userType') === "kam" && sessionStorage.getItem('status') === 'Incomplete from KAM') || (status === 'Incomplete from FSE' && userType === 'FSE' && (sessionStorage.getItem('customerOnboardingType') === "Workshop" || sessionStorage.getItem('customerOnboardingType') === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerOnboardingType') === "Dealer" || sessionStorage.getItem('customerOnboardingType') === "Fleet")) || isDisabled() || (dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId)}
              onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage, 'country')}
              // disabled={isDisabled()}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Country" required variant="outlined" margin="dense">
              {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
            </TextField>
          </div>
          {(sessionStorage.getItem('customerOnboardingType') === "Int. Business" && customerAcceessForIB) && (customerAcceessForIB && (customerStatus === "Awaiting feedback from FSE" || customerStatus === 'Incomplete from FSE' || customerStatus === "Submitted by FSE")) ?
            <div className="col-md-4 col-lg-4">
              <TextField id="ibSalesRegion"
                value={salesRegion}
                disabled
                onChange={(e: any) => handleInputChange(e, setSalesRegion)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="IB Sales Region" required variant="outlined" margin="dense" >
                {getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion.map((item: any) => (
                  <MenuItem key={item.salesRegion} value={item.salesRegion}>{item.salesRegion} </MenuItem>))}
              </TextField>
            </div>
            : ""}
          {(sessionStorage.getItem('customerOnboardingType') === "Int. Business" && customerAcceessForIB) && (customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE") ?
            <div className="col-md-4 col-lg-4">
              <TextField select id="headOfficeRegion"
                error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : headOfficeRegionError}
                helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : invalidHeadOfficeRegion}
                value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegion}
                // disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ? true : customerAcceessForIB && status === "Submitted by FSE" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" && status === "Submitted by FSE" ? true : country ? false : true}
                disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
                onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion, 'region')}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={customerTierType === "Workshop" ? "Trade Point Counter Location" : "Head Office Region"} required variant="outlined" margin="dense" >
                {regionDataNew && regionDataNew.map((item: any) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
              </TextField>
            </div>
            :
            <div className="col-md-4 col-lg-4">
              <TextField select id="headOfficeRegion"
                error={headOfficeRegionError}
                helperText={invalidHeadOfficeRegion}
                value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegion}
                disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
                onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion, 'region')}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Trade Point Counter Location" : "Head Office Region"} required variant="outlined" margin="dense" >
                {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
              </TextField>
            </div>
          }
          <div className="col-md-4 col-lg-4">
            <TextField id="dealerEmail"
              error={dealerEmailError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              value={dealerEmail}
              helperText={invalidDealerEmail && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
              onChange={(e: any) => handleInputChange(e, setDealerEmail, setDealerEmailError, setInvalidDealerEmail)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Customer Email" required variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField select id="dealingIn"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : dealingInError}
              disabled={isDisabled()}
              helperText={userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : invalidDealingIn}
              value={dealingIn}
              onChange={(e: any) => { handleInputChange(e, setDealingIn, setDealingInError, setInvalidDealingIn) }}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label={sessionStorage.getItem('customerOnboardingType') === "Workshop" || sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? "Dealing In" : "Type Of Ownership"} required={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? true : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : customer !== "Government" ? true : false} variant="outlined" margin="dense" >
              {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                dealingInData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
            </TextField>
          </div>
          {customer !== "Government" && <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 salestoCustomerKam">
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <TextField disabled defaultValue={ibCurrency} value={ibCurrency} onChange={(e: any) => handleInputChange(e, setSelectedIBCurrency)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined">
                <MenuItem key={ibCurrency} value={ibCurrency}>{ibCurrency}</MenuItem>
              </TextField>
              :
              <TextField disabled={isDisabled()} defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined" style={{ width: '74px' }}>
                {currencies.map(item => (
                  <MenuItem key={item} value={item}>{item}</MenuItem>))}
              </TextField>}
            <TextField id="annualTurnover" type="number"
              value={annualTurnover}
              disabled={isDisabled()}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              // error={annualTurnoverSelectorError}
              // helperText={invalidAnnualTurnover}
              onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
              size="small" className="col-sm-9 commonRoundedInputs-textfield aed1-width"
              label="Annual Turnover" variant="outlined" margin="dense" />
          </div>}
          <div className="col-md-4 col-lg-4">
            <TextField id="typeOfTrade"
              // error={typeOfTradeError}
              // helperText={invalidTypeOfTrade}
              select
              value={typeOfTrade}
              disabled
              onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
              // setTypeOfTradeError, setInvalidTypeOfTrade)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Type of Trade" required variant="outlined" margin="dense">
              {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
            </TextField>
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="poBox"
              error={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : customer.toLowerCase() === "workshop" || customer.toLowerCase() === "tpcdealer" ? false : poBoxError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && poBoxError && poBox.length > 7 ? poBoxError && poBox.length > 7 && <span style={{ color: 'red' }}>{ValidationErrorMessage.POBOX_TEXT}</span> : poBoxError && poBox.length > 7 && <span style={{ color: 'red' }}>{ValidationErrorMessage.POBOX_TEXT}</span>}
              value={poBox}
              onChange={(e: any) => onchangePobox(e)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="PO Box" required={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : customer.toLowerCase() === "workshop" || customer.toLowerCase() === "tpcdealer" ? false : true} variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="Street Name"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : streetNameError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : invalidstreetName}
              value={streetName}
              onChange={(e: any) => handleInputChange(e, setstreetName, setstreetNameError, setInvalidstreetName)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Street Name" required={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? true : sessionStorage.getItem('customerOnboardingType') === "Int. Business" || userType === 'FSE' && customerAcceessForIB || customerAcceessForIB && customerStatus === "Submitted by FSE" || customerAcceessForIB && customerStatus === "Awaiting feedback from FSE" ? false : true} variant="outlined" margin="dense" />
          </div>
          <div className="col-md-4 col-lg-4">
            <TextField id="City"
              error={cityError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              helperText={invalidcity}
              value={city}
              onChange={(e: any) => handleInputChange(e, setcity, setcityError, setInvalidcity)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="City" required variant="outlined" margin="dense" />
          </div>
          {customer !== "Government" && customer !== "Fleet" && userType === 'KAM' && customer !== 'Int. Business' && <div className="col-md-4 col-lg-4">
            <TextField id="Postal Code"
              error={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : customer.toLowerCase() === "workshop" ? false : postalCodeError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              type="number"
              helperText={invalidpostalCode}
              value={postalCode}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e: any) => handleInputChange(e, setpostalCode, setpostalCodeError, setInvalidpostalCode)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Postal Code" required={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? true : customer.toLowerCase() === "workshop" ? false : true} variant="outlined" margin="dense" />
          </div>}
          {userType === 'FSE' && (customer === 'Workshop' || customer === 'tpcdealer' || customer === "TBL Dealer") && <div className="col-md-4 col-lg-4">
            <TextField id="Postal Code"
              error={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : customer.toLowerCase() === "workshop" || customer.toLowerCase() === "tpcdealer" ? false : postalCodeError}
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              type="number"
              helperText={invalidpostalCode}
              value={postalCode}
              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
              onChange={(e: any) => handleInputChange(e, setpostalCode, setpostalCodeError, setInvalidpostalCode)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs"
              label="Postal Code" required={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : customer.toLowerCase() === "workshop" ? false : true} variant="outlined" margin="dense" />
          </div>}
          {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ?
            <div className="col-md-4 col-lg-4">
              <TextField id="Al-Futtaim Customer Number"
                disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ? true : false}
                value={sapCustomerId}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Al-Futtaim Customer Number" required variant="outlined" margin="dense" />
            </div>
            : ""}
          <div className="col-md-4 col-lg-4">
            <TextField select id="c"
              error={customerAccountTypeError}
              required
              disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
              value={customerAccountType}
              onChange={(e: any) => handleInputChange(e, setCustomerAccountType, setcustomerAccountTypeError, setInvalidcustomerAccountType)}
              size="small" className="col-sm-12 my-2 commonRoundedInputs" label="Customer Account Type" variant="outlined" margin="dense">
              {customerAccountTypes.map(item => (
                <MenuItem key={item} disabled={item == "Credit" ? true : false} value={item}>{item}</MenuItem>))}
            </TextField>
          </div>
        </>
        <div className="row col-12  mt-2 mb-2 pl-3 border-top">
          <div className="col-lg-4 mt-3">
            <h6 className="dealer-table-content mb-2">Owner/Partners Details</h6>
          </div>
          <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3">
            <Button variant="contained" color="primary"
              className="rounded-button-dealer ml-3 w-200p float-right"
              // disabled={isDisabled()}
              disabled={isDisabled()}
              onClick={() => {
                handleClickOpen();
                //setIsEdit(false);
                setTypeOfDailog('add')
              }}>Add Owner/Partner
            </Button>
          </div>
          {getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length > 0 && (
            <div className="row col-12 mt-2 mb-2 pl-3 border-top">
              <div className="col-lg-12 mt-3">
                <TableContainer>
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead numSelected={selected.length} />
                    <TableBody>
                      <>
                        {getAllPartnersData.data.map((newPartnerData: any, index: any) => (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell component="th"
                              scope="row" padding="none" className="document-tablecell-data">
                              {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.email}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.mobile}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.nationality}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.designation}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.emiratesIdNumber}
                            </TableCell>
                            <TableCell align="left">
                              <div className="row">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  {isAdmin && (
                                    <Button
                                      onClick={() => {
                                        setTypeOfDailog('view')
                                        handleEditPartnerDetails(newPartnerData, true);
                                      }}
                                      className='text-info'
                                      startIcon={<Visibility />}
                                    >
                                      View
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                      disabled={isDisabled()}
                                      startIcon={<EditOutlinedIcon />}
                                      onClick={() => {
                                        setTypeOfDailog('edit')
                                        handleEditPartnerDetails(newPartnerData, false);
                                      }}>
                                      Edit
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                      onClick={() => { onClickDeleteAddress(newPartnerData); }}
                                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                      disabled={isDisabled()}
                                      startIcon={<DeleteOutlineOutlinedIcon />}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          <Dialog className="pb-0"
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown>
            <AddNewOwnerPartnerDialog
              setOpenDialog={handleClose} setOwnerData={setOwnerData}
              updateNewPartnerData={updateNewPartnerData}
              isView={isView} typeofDailog={typeofDailog}
            />
          </Dialog>
        </div>
        <div className="row col-12  mt-2 mb-3 pl-3 border-top">
          <div className="col-12 mt-3">
            <h6 className="dealer-table-content mb-2 ml-1">Brand & Others</h6>
          </div>
          {isNewCustomer ? (
            <>
              <div className="col-md-8 col-lg-8 pr-5">
                <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                  <InputLabel error={selectBrandError} id="companyBrandsLabel">Select Brand *</InputLabel>
                  <Select
                    multiple
                    disabled={status === "Incomplete from KAM" ? false : status === "Confirmed from Dealer" ? false : status === "Submitted" ? false : userTypeByProfileAPI === 'KAM' && status === "Submitted by FSE" ? false : userTypeByProfileAPI === 'FSE' && status === "Submitted by FSE" ? true : true}
                    // disabled={isDisabled()}
                    required
                    variant="outlined"
                    value={selectBrand}
                    error={selectBrandError}
                    onChange={(e: any) => {
                      props.setDataUpdated(true);
                      handleInputChange(e, setSelectBrand, setSelectBrandError, setInvalidSelectBrand)
                    }}
                    input={<OutlinedInput label="Select Brand" />}
                    renderValue={(selected: any) => selected.join(', ')}>
                    {brandLists && brandLists.length > 0 && brandLists.map((brand: any, i: any) => (
                      <MenuItem key={i}
                        disabled={brand.brandType === "OEM" && sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? true : false}
                        value={brand.brandType === "OES" && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" ? 'ALAC' : brand.displayName}>
                        <Checkbox onChange={(e: any) =>
                          handleSelectAllClick(e, brand.brandType === "OES" && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" ? 'ALAC' : brand.displayName)
                          // handleSelectAllClick(e, brand.displayName)
                        }
                          disabled={brand.brandType === "OEM" && sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? true : false}
                          checked={selectBrand && selectBrand.includes(brand.displayName) ? true : false}
                        />
                        {/* {getNewBrand(brand.make)} */}
                        <p className="pr-2"> {brand.brandId}</p><p className="pr-2">{"-"}</p>
                        <span className={selectBrand && selectBrand.includes(brand.displayName) ? "brandsButton-content" : ""}>{brand.displayName}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {customer === 'Int. Business' ?
                <div className={"col-sm-4 col-md-2 col-lg-3 mb-2 pr-0 pt-0 px-0"}>
                </div>
                : ''}
                 {customer === 'Int. Business' ?
                <div className={"col-md-4 col-lg-4 pr-5 pt-0"}>
                  {customer !== "Government" && customerTierType &&
                    <TextField id="otherBusinessEstablishments"
                      // error={otherBusinessEstablishmentsError}
                      // helperText={otherBusinessEstablishmentsError && <span style={{ color: 'red' }}>{OtherBusinessEstablishmentsErrorMessage}</span>}
                      value={otherBusinessEstablishments}
                      onChange={(e: any) => onChangeBussinessEst(e)}
                      disabled={isDisabled()}
                      size="small" className={customer === 'Int. Business' ? "col-sm-12 my-2 mt-4 commonRoundedInputs" : userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "col-sm-12 my-2 mt-2 commonRoundedInputs" : "col-sm-12 my-2 mt-4 commonRoundedInputs"}
                      label="Other Business Establishments" variant="outlined" margin="dense" />}
                </div>
                : ''}
              {customer === 'Int. Business' ?
                <div className={customer === "Fleet" ? "col-md-4 col-lg-4 px-1 pr-5" : sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? "col-md-4 col-lg-4 px-1 pr-5"
                  : sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? "col-md-4 col-lg-4 px-4 pr-3"
                    : userType === 'FSE' || userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Workshop"
                      || customerStatus === "Submitted by FSE" || customerStatus === "Awaiting feedback from FSE" &&
                      userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" ?
                      "col-md-4 col-lg-4 px-4 pr-5" : "col-md-4 col-lg-4 mt-0 px-0 pr-5"}>
                  {customer !== "Government" && customerTierType && <TextField id="otherBrandsProductsSold"
                    // error={otherBrandsProductsSoldError}
                    // helperText={otherBrandsProductsSoldError && <span style={{ color: 'red' }}>{OtherBrandsProductsSoldErrorMessage}</span>}
                    value={otherBrandsProductsSold}
                    onChange={(e: any) => onChangeOtherBrandProducts(e)}
                    disabled={isDisabled()}
                    size="small" className="col-sm-12 my-2 mt-4 commonRoundedInputs"
                    label="Other Brands/Products Sold" variant="outlined" margin="dense" />}
                </div>
                : ""}
              {customer !== "Government" && userType !== 'FSE' && customer !== 'Int. Business' && sessionStorage.getItem('customerOnboardingType') !== "tpcdealer" &&
                <div className="col-4 row">
                  {customer.toLowerCase() !== "workshop" && isNewCustomer && <div className="col-sm-4 col-md-2 col-lg-3 mb-2 pr-0 px-0">
                    <TextField select id="creditLimitSelector"
                      error={creditLimitSelectorError}
                      value={creditLimitSelector} defaultValue={currencies[0]}
                      onChange={(e: any) => handleInputChange(e, setCreditLimitSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                      disabled={isDisabled()}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>
                  </div>}
                  {customer.toLowerCase() !== "workshop" && isNewCustomer && userType !== 'FSE' && customer !== 'Int. Business' &&
                    <div className="col-sm-4 col-md-4 col-lg-9 mb-2 pl-0 pr-4">
                      <TextField id="creditLimit"
                        type="number"
                        error={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : creditLimitError}
                        helperText={invalidCreditLimit}
                        value={creditLimit}
                        disabled={isDisabled()}
                        onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onChange={(e: any) => handleInputChange(e, setCreditLimit, setCreditLimitError, setInvalidCreditLimit)}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                        label={customerTierType && customer.toLowerCase() === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? "Initial Stock Order" : "Initial Stock Order*"} variant="outlined" margin="dense" />
                    </div>}
                </div>}
              {userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || customer === "tpcdealer" || customer === "TBL Dealer" && userType === "FSE" ?
                <div className="col-4 row">
                  <div className="col-sm-4 col-md-2 col-lg-3 mb-2 pr-0 px-0">
                    <TextField select id="creditLimitSelector"
                      error={creditLimitSelectorError}
                      value={creditLimitSelector} defaultValue={currencies[0]}
                      onChange={(e: any) => handleInputChange(e, setCreditLimitSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                      disabled={isDisabled()}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-9 mb-2 pl-0 pr-4">
                    <TextField id="creditLimit"
                      type="number"
                      error={sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? false : sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? false : creditLimitError}
                      helperText={invalidCreditLimit}
                      value={creditLimit}
                      disabled={isDisabled()}
                      onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      onChange={(e: any) => handleInputChange(e, setCreditLimit, setCreditLimitError, setInvalidCreditLimit)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                      label={sessionStorage.getItem('customerOnboardingType') === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? "Initial Stock Order" : "Initial Stock Order*"} variant="outlined" margin="dense" />
                  </div>
                </div> : ""}
              {customer !== "Government" && customerTierType && userType !== 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customer !== 'Int. Business' && customer.toLowerCase() !== "workshop" && isNewCustomer &&
                <div className={customer === "Fleet" ? "col-sm-4 col-md-2 col-lg-1 mb-2 pr-0" : "col-sm-4 col-md-2 col-lg-1 mb-2 pr-0"}>
                  <TextField select id="totalInvestmentSelector"
                    disabled={isDisabled()}
                    error={totalInvestmentSelectorError}
                    value={totalInvestmentSelector}
                    onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentSelectorError, setInvalidTotalInvestmentSelector)}
                    size="small" className={customer !== 'Int. Business' ? "col-sm-12 mt-4 my-2 commonRoundedInputs-dropdown" : "col-sm-12 my-2 commonRoundedInputs-dropdown"} variant="outlined" margin="dense">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                </div>}
              {customer !== "Government" && userType !== 'FSE' && customer !== 'Int. Business' && sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customerTierType && customer.toLowerCase() !== "workshop" && isNewCustomer &&
                <div className={customer === "Fleet" ? "col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0" : "col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0"}>
                  {customer !== "Government" && userType !== 'FSE' && customer !== 'Int. Business' && sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customerTierType && customer.toLowerCase() !== "workshop" && isNewCustomer && <TextField id="totalInvestment"
                    type="number"
                    error={totalInvestmentError}
                    helperText={invalidTotalInvestment}
                    value={totalInvestment}
                    disabled={isDisabled()}
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                    size="small" className={customer !== 'Int. Business' ? "col-sm-12 mt-4 my-2 commonRoundedInputs-textfield" : "col-sm-12 my-2 commonRoundedInputs-textfield"}
                    label={customerTierType && customer.toLowerCase() === "tpcdealer" ? "Total Investment" : "Total Investment*"} variant="outlined" margin="dense" />}
                </div>}
              {userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" || sessionStorage.getItem('customerOnboardingType') === "tpcdealer" && userType === "FSE" ?
                <div className="col-4 mt-0 row">
                  <div className="col-sm-4 col-md-2 col-lg-3 mb-2 mt-0 pr-0">
                    <TextField select id="totalInvestmentSelector"
                      disabled={isDisabled()}
                      // error={totalInvestmentSelectorError}
                      value={totalInvestmentSelector}
                      onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentSelectorError, setInvalidTotalInvestmentSelector)}
                      size="small" className={customer !== 'Int. Business' ? "col-sm-12 mt-4 my-2 commonRoundedInputs-dropdown" : "col-sm-12 my-2 commonRoundedInputs-dropdown"} variant="outlined" margin="dense">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>
                  </div>
                  <div className="col-sm-4 col-md-4 col-lg-9 px-0 mb-2 mt-0 pl-0">
                    <TextField id="totalInvestment"
                      type="number"
                      // error={totalInvestmentError}
                      // helperText={invalidTotalInvestment}
                      value={totalInvestment}
                      disabled={isDisabled()}
                      onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                      size="small" className={customer !== 'Int. Business' ? "col-sm-12 mt-4 my-2 commonRoundedInputs-textfield" : "col-sm-12 my-2 commonRoundedInputs-textfield"}
                      label="Total Investment" variant="outlined" margin="dense" />
                  </div>
                </div>
                : ""}
              {customer !== 'Int. Business' ?
                <div className={ sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? "col-md-4 col-lg-4 pr-4 pt-0 " : userType === 'FSE' || userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Workshop"
                  || customerStatus === "Submitted by FSE" || customerStatus === "Awaiting feedback from FSE" ?
                  "col-md-4 col-lg-4 pr-5 pt-0 " : "col-md-4 col-lg-4 mt-0 pr-5"}>
                  {customer !== "Government" && customerTierType &&
                    <TextField id="otherBusinessEstablishments"
                      // error={otherBusinessEstablishmentsError}
                      // helperText={otherBusinessEstablishmentsError && <span style={{ color: 'red' }}>{OtherBusinessEstablishmentsErrorMessage}</span>}
                      value={otherBusinessEstablishments}
                      onChange={(e: any) => onChangeBussinessEst(e)}
                      disabled={isDisabled()}
                      size="small" className={customer === 'Int. Business' ? "col-sm-12 my-2 mt-4 commonRoundedInputs" : userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "col-sm-12 my-2 mt-2 commonRoundedInputs" : "col-sm-12 my-2 mt-4 commonRoundedInputs"}
                      label="Other Business Establishments" variant="outlined" margin="dense" />}
                </div>
                : ''}
              {customer !== 'Int. Business' ?
                <div className={customer === "Fleet" ? "col-md-4 col-lg-4 px-1 pr-5" : sessionStorage.getItem('customerOnboardingType') === "tpcdealer" ? "col-md-4 col-lg-4 px-1 pr-5"
                  : sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ? "col-md-4 col-lg-4 px-4 pr-3"
                    : userType === 'FSE' || userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Workshop"
                      || customerStatus === "Submitted by FSE" || customerStatus === "Awaiting feedback from FSE" &&
                      userType === 'FSE' && sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" ?
                      "col-md-4 col-lg-4 px-4 pr-5" : "col-md-4 col-lg-4 mt-0 px-0 pr-5"}>
                  {customer !== "Government" && customerTierType && <TextField id="otherBrandsProductsSold"
                    // error={otherBrandsProductsSoldError}
                    // helperText={otherBrandsProductsSoldError && <span style={{ color: 'red' }}>{OtherBrandsProductsSoldErrorMessage}</span>}
                    value={otherBrandsProductsSold}
                    onChange={(e: any) => onChangeOtherBrandProducts(e)}
                    disabled={isDisabled()}
                    size="small" className="col-sm-12 my-2 mt-4 commonRoundedInputs"
                    label="Other Brands/Products Sold" variant="outlined" margin="dense" />}
                </div>
                : ""}
            </>
          ) :
            (
              <>
                <div className='px-3'>
                  {selectBrand.map((org: any, index: any) => (
                    <Button key={index} onClick={() => {
                    }}
                      className="brandsButton mr-2 cursor-default"
                      variant="contained">
                      {getNewBrand(org)}
                    </Button>
                  ))}
                </div>
                <>
                  {sessionStorage.getItem('customerOnboardingType') === "tpcdealer" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer" ?
                    <>
                      {dealerDetails && dealerDetails.data && (
                        <div className="row m-0 col-12 mt-3 px-1 mt-2">
                          <div className="col-3 col-md-3 col-sm-3 mb-4">
                            <div className="info-sub-title-value font-size-12">Initial Stock Order</div>
                            <div className="subTitle mt-2">{dealerDetails && dealerDetails.data && dealerDetails.data.initalStockOrder ? dealerDetails && dealerDetails.data && dealerDetails.data.initalStockOrder : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mb-4">
                            <div className="info-sub-title-value font-size-12">Total Investment</div>
                            <div className="subTitle mt-2">{dealerDetails && dealerDetails.data && dealerDetails.data.totalInvestment ? dealerDetails && dealerDetails.data && dealerDetails.data.totalInvestment : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mb-4">
                            <div className="info-sub-title-value font-size-12">Other Business Establishments</div>
                            <div className="subTitle mt-2">{dealerDetails && dealerDetails.data && dealerDetails.data.otherBusinessEstablishments ? dealerDetails && dealerDetails.data && dealerDetails.data.otherBusinessEstablishments : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mb-4">
                            <div className="info-sub-title-value font-size-12">Other Brands/Products Sold</div>
                            <div className="subTitle mt-2">{dealerDetails && dealerDetails.data && dealerDetails.data.otherBrandsProductsSold ? dealerDetails && dealerDetails.data && dealerDetails.data.otherBrandsProductsSold : '-'}</div>
                          </div>
                        </div>
                      )}
                    </>
                    : ""}
                </>
              </>
            )}
        </div>
        {isNewCustomer === false ? '' : (
          <>
            {sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customer.toLowerCase() !== "TBL Dealer" && customer !== "Government" && customerTierType && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" && isNewCustomer && <div className="row col-md-12 m-0 border-top pl-3" >
              <h6 className="dealer-table-content mb-2 mt-4">Trade Reference</h6>
            </div>}
            {tradeReference.map((tradeItem: any, index: any) => {
              if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customer.toLowerCase() !== "TBL Dealer" && customer !== "Government" && customerTierType && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" && isNewCustomer) {
                return (
                  <div className="col-md-12 m-0" key={index}>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.name}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "name")}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index] && tradeReferenceErrors[index].nameErrorMessage}</span>}
                          name={tradeItem.name + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        {/* <TextField
                      value={tradeItem.companyName}
                      disabled={isDisabled()}
                      name={tradeItem.companyName + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Company Name" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'companyName', index)
                      }}
                    /> */}
                        <TextField
                          required
                          value={tradeItem.companyName}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "companyName")}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                          }}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameErrorMessage}</span>}
                          name={tradeItem.companyName + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Company Name" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        {/* <TextField
                      value={tradeItem.addresses}
                      disabled={isDisabled()}
                      // name={tradeItem.address + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Address" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'addresses', index)
                      }}
                    /> */}
                        <TextField
                          required
                          value={tradeItem.addresses}
                          type="text"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "addresses")}
                          disabled={isDisabled()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].addressErrorMessage}</span>}
                          inputProps={{ maxLenght: 30 }}
                          name={tradeItem.addresses + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Address" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        {/* <TextField
                      value={tradeItem.telephone}
                      disabled={isDisabled()}
                      // name={tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[0].telephone + index}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Telephone" variant="outlined" margin="dense"
                      onChange={(e: any) => {
                        handleInputChangetradeRef(e, 'telephone', index)
                      }}
                    /> */}
                        <TextField
                          required
                          value={tradeItem.telephone}
                          type="number"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError}
                          onChange={(e: any) => tradeReferenceItem(e, index, 'telephone')}
                          disabled={isDisabled()}
                          onKeyDown={(e: any) => ["e", "E", "-"].includes(e.key) && e.preventDefault()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].telephoneErrorMessage}</span>}
                          inputProps={{ maxLenght: sessionStorage.getItem('customerOnboardingType') === "Int. Business" || customerAcceessForIB ? 30 : 20 }}
                          name={tradeItem.telephone + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Telephone" variant="outlined" margin="dense" />
                      </div>
                      {
                        tradeReference.length !== 1 ?
                          <div className="col-md-4 col-lg-2">
                            <IconButton className="mr-1 mt-n2" size="small"
                              disabled={isDisabled()}
                              onClick={() => {
                                onClickDeleteTradeRef();
                                setTradeRowRefDeleteIndex(index)
                                // deleteTradeRow(index)
                              }}
                            >
                              <DeleteIcon fontSize="inherit" />
                              <span className="font-size-13 load-from-fav ml-1 mt-0">
                                Delete
                              </span>
                            </IconButton>
                          </div>
                          : ""}
                      {/* <div className="col-md-4 col-lg-2">
                    <IconButton disabled={isDisabled()} className="mr-1 mt-3" size="small" onClick={() => deleteTradeRow(index)}>
                      <DeleteIcon fontSize="inherit" />
                      <span className="font-size-13 load-from-fav ml-1 mt-0">
                        Delete
                      </span>
                    </IconButton>
                  </div> */}
                    </div>
                  </div>
                )
              }
            })}

            <div className="row col-md-12 m-0">
              {sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer" && customer.toLowerCase() !== "TBL Dealer" && customer !== "Government" && customerTierType && customer !== "Government" && customerTierType && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer" && isNewCustomer &&
                < div className="row-space-start">
                  <Button
                    disabled={isDisabled()}
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    onClick={() => addTradeRow()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                </div>
              }
            </div>
          </>
        )}
      </div>
      {/* <SubmitDialogKAM
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveDetails();
          }
          if (!data) handleopenCloseSubmit(false);
        }} /> */}
      <DeleteDialog
        open={openDeleteDialog}
        type={typeOfDelete}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deletePartner()
          }
          else if (data === 'tradeRef') {
            deleteTradeRow(tradeRowRefDeleteIndex)
          }
          if (!data) handleopenClose(false);
        }} />
      {
        (status === "Incomplete from KAM") || (userType === "FSE" && status === "Incomplete from FSE") || (userType === "FSE" && customerStatus === "Awaiting feedback from FSE") || (userType === "FSE" && customerStatus === "Submitted by FSE") || (loading) ? (
          <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
            <div className="col-12 display-flex p-0">
              <div className="col-8 p-0">
                <div className={`row m-0`}>
                  {/* <div className="col-5 p-0">
            <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
            {status === 'Submitted' ? (
              <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
            ) : (
              <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                  Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
              </p>
            )}
          </div> */}
                  <div className="col-4 mt-3 p-0">
                    <Button variant="contained"
                      disabled={status === "Submitted by FSE" ? true : false}
                      className="border-left rounded-pill ml-5 w-150p mb-2"
                      onClick={() => saveDetails(false)}
                    >Save Form</Button>
                  </div>
                </div>
              </div>
              <div className="col-4 onboard-footer float-right">
                {/* <div className="p-3 mr-3">
          <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
            onClick={() => 
              handleBackClick()
            }
          >Back</Button>
        </div> */}
                <div className="p-3 mr-3">
                  <Button variant="contained"
                    color="primary"
                    // disabled={userType === "FSE" && customerAcceessForIB && customerStatus === "Submitted by FSE" ? true : false}
                    onClick={() => {
                      // setDeleteMessage("Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost.");
                      // handleopenCloseSubmit(true);
                      // saveDetails(true);
                      sessionStorage.setItem('customerTypeDropdown', customerAccountType)
                      sessionStorage.setItem('vatRegistrationNumber', vatRegistrationNumber)
                      handleNextClick();
                    }}
                    className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ActionButtons
            isSaveDisabled={!props.dataUpdated}
            iscorrectionDisabled={customerStatus === "Incomplete from KAM" || userType === "FSE" && customerStatus === "Submitted by FSE" ? true : false}
            isapproveDisabled={true}
            isrejectDisabled={customerStatus === "Submitted by FSE" ? false : true}
            saveCallback={() => {
              saveDetails()
            }}
            correctionCallback={() => { }}
            approveCallback={() => { }}
            rejectCallback={() => { }}
            setOpenErrorSnackbar={setOpenErrorSnackbar}
            openErrorSnackbar={openErrorSnackbar}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            setSuccessMessage={setSuccessMessage}
            setDataUpdated={props.setDataUpdated}
          />
        )
      }
    </Fragment >
  )
}

export default DealerDetails