/* eslint-disable */
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment-timezone";
import {
  Checkbox,
} from '@material-ui/core';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from '@material-ui/icons/Search';
import { getAgreementDocs, sendCategoryCertificateMail, sendCustomerAgreementMail, setAgreementDetails } from '../../store/actions/dealer.action';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import { isEmpty, isString } from 'lodash';

export interface AgreementDialogProps {
  open: boolean;
  selectedTabValue: number;
  onClose: (value: boolean) => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dealerdetail-tabpanel-${index}`}
      aria-labelledby={`dealerdetail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `dealerdetail-tab-${index}`,
    'aria-controls': `dealerdetail-tabpanel-${index}`,
  };
}

function Row(props: any) {
  const [open, setOpen] = React.useState(props.filterByCustomer === 'Dealer' && props.tierName === 'Silver' ? true : props.filterByCustomer !== 'Dealer' ? true : false);
  const [customerChecked, setCustomerChecked] = React.useState(props.values.map((item: any) => item.status !== "Deactive" && item.statusPlus.length === 0 && (item.customerAgreementUpdateDate !== '' || props.tabValue == 1))); // All customers checked by default
  const [isTierChecked, setIsTierChecked] = React.useState(true);
  // Handle customer checkbox change
  const handleCustomerCheckboxChange = (index: any, sapCustomerId: any) => {
    const updatedChecked = [...customerChecked];
    updatedChecked[index] = !updatedChecked[index];
    setCustomerChecked(updatedChecked);
    props.setSelectedCheckboxRecord(sapCustomerId)
    // Check if all are selected or none are selected
    const allChecked = updatedChecked.every(Boolean);
    const allUnchecked = updatedChecked.every((checked) => !checked);

    setIsTierChecked(allChecked); // Update tier checkbox based on customer selections
    if (allUnchecked) {
      setIsTierChecked(false); // If all customers are unchecked, uncheck tier
    }
    // const updatedSelectedCustomers = getSelectedCustomers(updatedChecked);
    // props.onSelectionChange(updatedSelectedCustomers, props.tierName);
  };

  // Handle tier checkbox change
  const handleTierCheckboxChange = () => {
    const newTierChecked = !isTierChecked;
    setIsTierChecked(newTierChecked);

    // Update all customer checkboxes based on the tier checkbox
    const updatedCustomerChecked = props.values.map((item: any) => newTierChecked && item.status !== "Deactive" && item.statusPlus.length === 0 && (item.customerAgreementUpdateDate !== '' || props.tabValue == 1));
    setCustomerChecked(updatedCustomerChecked);
    const selectedCustomers = props.values.map((item: any) => {
      return { sapCustomerId: item.sapCustomerId, status: newTierChecked && item.status !== "Deactive" && item.statusPlus.length === 0 && (item.customerAgreementUpdateDate !== '' || props.tabValue == 1) }
    })
    props.setSelectedTierCheckbox(selectedCustomers)
    // const updatedSelectedCustomers = getSelectedCustomers(updatedCustomerChecked);
    // props.onSelectionChange(updatedSelectedCustomers, props.tierName);
  };

  // Get selected customers based on their checkbox state
  const getSelectedCustomers = (checkedStates: any) => {
    return props.values
      .map((customer: any, index: any) => {
        if (checkedStates[index] && customer.status.includes('Active')) {
          return {
            sapCustomerId: customer.sapCustomerId,
            companyName: customer.companyName,
            customerTierType: customer.customerTierType,
            customerEmail: customer.contactDetails?.email || '',
            assignKamEmail: customer.assignedKamEmails,
            assignKamName: customer.assignedKam,
          };
        }
        return null;
      })
      .filter(Boolean);
  };

  return (
    <>
      {props.tierName !== 'otherDealers' && props?.values?.length > 0 && (
        <React.Fragment>
          <TableRow className="tableRowPanelsCreateEnquiry">
            <TableCell className="pr-0">
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
              </IconButton>
            </TableCell>
            <TableCell className="px-0 mw-50p">
              <Checkbox checked={isTierChecked} onChange={handleTierCheckboxChange} />
            </TableCell>
            <TableCell colSpan={10} className="blueLabelHeaderTable">
              {props.tierName}
            </TableCell>
          </TableRow>
          {open &&
            props.tierName &&
            props.values.map((customer: any, index: any) => {
              return (
                customer.customerTierType !== '' && (
                  <TableRow key={customer.sapCustomerId}>
                    <TableCell className="pr-0"></TableCell>
                    <TableCell className="px-0 mw-50p">
                      <Checkbox
                        // disabled={
                        //   props.tabValue === 0 &&
                        //   (!customer.customerAgreementUpdateDate || customer.customerAgreementUpdateDate === '') ||
                        //   !customer.status.includes('Active')
                        // }
                        disabled={customer.status == "Deactive" || customer.statusPlus.length !== 0 || (customer.customerAgreementUpdateDate === '' && props.tabValue == 0)}
                        checked={customerChecked[index]}
                        onChange={() => handleCustomerCheckboxChange(index, customer.sapCustomerId)}
                      />
                    </TableCell>
                    <TableCell className="max-w-200p pr-0">
                      <div className="dealer-table-content">{customer.companyName}</div>
                      <div className="dealer-table-subcontent">{customer.sapCustomerId}</div>
                    </TableCell>
                    <TableCell className="pr-0">
                      <div className="dealer-table-content">
                        {customer.contactDetails?.telephoneNumber || '-'}
                      </div>
                      <div className="dealer-table-subcontent">
                        {customer.contactDetails?.email || '-'}
                      </div>
                    </TableCell>
                    <TableCell className="pr-0">
                      <div className="dealer-table-content">{customer.assignedKam || '-'}</div>
                      <div className="dealer-table-subcontent">{customer.assignedFSEs || '-'}</div>
                    </TableCell>
                    {props.tabValue === 0 ?
                      <TableCell className='mw-100p pr-0'>
                        <div className="dealer-table-content">{customer.agreementSentOn ? moment(customer.agreementSentOn).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                        <div className="dealer-table-subcontent">{customer.agreementSentOn ? moment(customer.agreementSentOn).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                      </TableCell>
                      :
                      <TableCell className='mw-100p pr-0'>
                        <div className="dealer-table-content">{customer.categoryCertificateSentOn ? moment(customer.categoryCertificateSentOn).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                        <div className="dealer-table-subcontent">{customer.categoryCertificateSentOn ? moment(customer.categoryCertificateSentOn).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                      </TableCell>
                    }
                    {props.tabValue === 0 && (
                      <TableCell className='mw-100p pr-0'>
                        <div className="dealer-table-content">{customer.customerAgreementUpdateDate ? moment(customer.customerAgreementUpdateDate).tz('Asia/Dubai').format("DD/MM/YYYY") : "-"}</div>
                        <div className="dealer-table-subcontent">{customer.customerAgreementUpdateDate ? moment(customer.customerAgreementUpdateDate).tz('Asia/Dubai').format("HH:mm:ss") : ""}</div>
                      </TableCell>
                    )}
                    {customer.status && customer.status !== "Active" && customer && customer.statusPlus && customer.statusPlus.length > 0 ?
                      <TableCell className="mw-150p">
                        <div style={{ display: "flex" }} className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}
                        </div>
                        <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                        <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                      </TableCell>
                      :
                      customer.status && customer.status === "Active" && customer && customer.statusPlus && customer.statusPlus.length > 0 ?
                        <TableCell className="mw-150p">
                          <div style={{ display: "flex" }} className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}
                          </div>
                          <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                          <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                        </TableCell>
                        :
                        <TableCell className="mw-150p">
                          <div className={!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "dealer-table-content text-red" : customer.status && customer.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(customer && customer.statusPlus && customer.statusPlus) && customer.status && customer.status === "Active" ? "Inactive" : customer.status ? customer.status === "Document expiry issue" ? "Document Expiry Issue" : customer.status === "Deactive" ? "Inactive" : customer.status : "-"}</div>
                          <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(customer && customer.statusPlus && customer.statusPlus) ? `(${customer && customer.statusPlus && customer.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                          {isEmpty(customer && customer.statusPlus && customer.statusPlus) &&
                            <div className="dealer-table-subcontent dealer-table-content text-red">{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{customer.status !== "Deactive" && customer.docExpiryAutoDeactivateUntil && customer.docExpiryAutoDeactivate ? customer.docExpiryAutoDeactivateUntil : ""}</div>
                          }
                        </TableCell>
                    }
                  </TableRow>
                )
              );
            })}
        </React.Fragment>
      )}
    </>
  );
}

const AgreementDialog: React.FC<AgreementDialogProps> = (props: AgreementDialogProps) => {
  const [tabValue, setTabValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState<any>('');
  const [selectedRecords, setSelectedRecords] = React.useState<any>([]);
  const [filterByCustomer, setFilterByCustomer] = React.useState("Dealer")
  const [agreementDocs, setAgreementDocs] = React.useState<any>({})
  const [error, setError] = React.useState<any>('');
  const [loading, setLoading] = React.useState<any>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [sendMailSelectedRecords, setSendMailSelectedRecords] = React.useState<any>({});

  const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    setError('');
    if (filterByCustomer === 'Dealer') {
      let ag: any = { data: { data: agreementDetails } }
      await setAgreementDocs(ag);
    } else {
      await getPublishAgreementDocs();
    }
    // dispatch(getAgreementDocs('Dealer'));
    setSearchValue('');
    setFilterByCustomer('Dealer');
    setOpenErrorSnackbar(false);
    setSuccessMessage(null);
  };

  // let agreementDocs: any = { data: { data: {} } }

  const { agreementDetails }: any = useSelector((state: any) => state.dealer);

  useEffect(() => {
    setTabValue(tabValue ? tabValue : props.selectedTabValue);
    let ag: any = { data: { data: agreementDetails } }
    setAgreementDocs(ag);
    if (agreementDetails) {
      setError('');
      let initialSelectedRecords: any;
      if (tabValue === 0) {
        initialSelectedRecords = Object.entries(agreementDetails).flatMap(([tierName, customers]: [any, any]) =>
          customers && Array.isArray(customers) && customers?.filter((customer: any) =>
            (customer.status === "Active" || customer.status.includes("Active"))
          )
            .map((customer: any) => ({
              sapCustomerId: customer.sapCustomerId,
              companyName: customer.companyName,
              customerTierType: customer.customerTierType,
              customerEmail: customer.contactDetails.email || '',
              assignKamEmail: customer.assignedKamEmails,
              assignKamName: customer.assignedKam,
              tierType: tierName === "otherDealers" ? 'Others' : tierName
            }))
        );
      } else {
        initialSelectedRecords = Object.entries(agreementDetails).flatMap(([tierName, customers]: [any, any]) =>
          customers && Array.isArray(customers) && customers
            ?.filter((customer: any) =>
              (customer.status === "Active" || customer.status.includes("Active"))
            )
            .map((customer: any) => ({
              sapCustomerId: customer.sapCustomerId,
              companyName: customer.companyName,
              customerTierType: customer.customerTierType,
              customerEmail: customer.contactDetails.email || '',
              assignKamEmail: customer.assignedKamEmails,
              assignKamName: customer.assignedKam,
              tierType: tierName === "otherDealers" ? 'Others' : tierName
            }))
        );
      }
      let obj: any = {}
      Object.entries(agreementDetails).flatMap(([tierName, customers]: [any, any]) => {
        customers && Array.isArray(customers) && customers
          ?.forEach((item: any) => {
            obj[item.sapCustomerId] = item.status !== "Deactive" && item.statusPlus.length === 0 && (item.customerAgreementUpdateDate !== '' || tabValue == 1);
          })
      })
      setSendMailSelectedRecords(obj);
      setSelectedRecords(initialSelectedRecords);
    }
    // agreementDocs.data.data = agreementDetails;
  }, [props.selectedTabValue, agreementDetails, tabValue])


  const getPublishAgreementDocs = async () => {
    setLoading(true);
    const limit = 20; // Define limit
    let skip = 0; // Initialize skip
    let totalCount = 0; // Initialize totalCount

    let tempData: any = {
      Silver: [],
      Gold: [],
      Platinum: [],
      Elite: [],
      otherDealers: [],
    }; // Initialize a temporary object to accumulate all data before updating the state

    do {
      const response: any = await dispatch(getAgreementDocs('Dealer', limit, skip));

      if (response && response.data) {
        const res = response.data;
        // Check if any category has data
        const isDataFetched =
          res.Silver?.length > 0 ||
          res.Gold?.length > 0 ||
          res.Platinum?.length > 0 ||
          res.Elite?.length > 0 ||
          res.otherDealers?.length > 0;

        if (isDataFetched) {
          // Append the fetched data to the corresponding categories in tempData
          Object.keys(res).forEach((key) => {
            if (Array.isArray(res[key])) {
              tempData[key] = [
                ...tempData[key],      // Append existing data
                ...res[key],           // Append new fetched data
              ];
            }
          });
        }
      }

      // Update totalCount from the first response (for pagination)
      if (response && response.data && response.data.customersTotaCount && skip === 0) {
        totalCount = response.data.customersTotaCount;
      }

      // Increment skip by the limit to fetch the next set of data
      skip += limit;

    } while (skip < totalCount); // Continue looping until all data is fetched

    // Once all data is fetched, update the state with the accumulated data
    dispatch(setAgreementDetails(tempData));
    setLoading(false);
  };

  const setSelectedCheckboxRecord = (sapCustomerId: any) => {
    let obj = { ...sendMailSelectedRecords, [sapCustomerId]: !sendMailSelectedRecords[sapCustomerId] };
    setSendMailSelectedRecords(obj)
  }

  const setSelectedTierCheckbox = (data: any) => {
    let obj: { [key: string]: boolean } = {};
    data.forEach((item: any) => {
      const { sapCustomerId, status } = item;
      obj[sapCustomerId] = status;
    })
    setSendMailSelectedRecords({ ...sendMailSelectedRecords, ...obj });
  }

  const handleSelectionChange = (updatedTierRecords: any[], tierName: any) => {
    if (tierName === 'Workshop' || tierName === 'Government' || tierName === 'Fleet' || tierName === 'TBL Dealer' || tierName === 'TPC Dealer') {
      setSelectedRecords(updatedTierRecords)
    } else {
      setSelectedRecords((prevRecords: any) => {
        // Filter out records that match the tierName
        const filteredRecords = prevRecords.filter((record: any) => record.tierType !== tierName);
        const uniqueUpdatedRecords = updatedTierRecords.filter((newRecord: any) =>
          !prevRecords.some((existingRecord: any) => existingRecord.sapCustomerId === newRecord.sapCustomerId)
        );
        // Return combined records
        return [...filteredRecords, ...uniqueUpdatedRecords];
      });
    }
  };

  const renderRows = (data: any, onSelectionChange: (selectedCustomers: any, tierName: any) => void) => {
    let mappedData: any;
    if (filterByCustomer === 'Dealer') {
      mappedData = {
        Silver: data.Silver ? data.Silver : [],
        Gold: data.Gold ? data.Gold : [],
        Platinum: data.Platinum ? data.Platinum : [],
        Elite: data.Elite ? data.Elite : [],
        Others: data.otherDealers ? data.otherDealers : [],
      };
    } else {
      if (filterByCustomer === 'Workshop') {
        mappedData = { Workshop: data.IWS ? data.IWS : [] }
      }
      if (filterByCustomer === 'Government') {
        mappedData = { Government: data.Government ? data.Government : [] }
      }
      if (filterByCustomer === 'TPC Dealer') {
        mappedData = { 'TPC Dealer': data["TPC Dealer"] ? data["TPC Dealer"] : [] }
      }
      if (filterByCustomer === 'TBL Dealer') {
        mappedData = { 'TBL Dealer': data["TBL Dealer"] ? data["TBL Dealer"] : [] }
      }
      if (filterByCustomer === 'Fleet') {
        mappedData = { Fleet: data.Fleet ? data.Fleet : [] }
      }
    }

    let finalRows: any = [];
    Object.keys(mappedData).map((key) => {
      if (key) {
        finalRows.push(
          <Row
            tierName={key}
            filterByCustomer={filterByCustomer}
            selectedRecords={selectedRecords}
            values={mappedData[key]}
            onSelectionChange={onSelectionChange}
            tabValue={tabValue}
            setSelectedCheckboxRecord={setSelectedCheckboxRecord}
            setSelectedTierCheckbox={setSelectedTierCheckbox}
          />
        );
      }
    });
    return finalRows;
  };


  const sendEmail = async () => {
    let fileterData = selectedRecords.filter((item: any) => {
      if (sendMailSelectedRecords[item.sapCustomerId]) {
        return item;
      }
    })
    if (fileterData && fileterData.length > 0) {
      let payload: any = { customers: fileterData }
      if (tabValue === 0) {
        let data: any = await dispatch(sendCustomerAgreementMail(payload));
        if (data && data.data) {
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setSuccessMessage('Email sent successfully')
        }
      } else {
        let data: any = await dispatch(sendCategoryCertificateMail(payload));
        if (data && data.data) {
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setSuccessMessage('Email sent successfully')
        }
      }
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage('Please select atleast one customer')
    }
  }
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const filterDataByCustomerIdOrName = async (customerType: any, searchValue: any) => {
    const filteredData: any = {
      Silver: [],
      Gold: [],
      Platinum: [],
      Elite: [],
      otherDealers: [],
    };
    if (searchValue === '') {
      let ag: any = { data: { data: agreementDetails } }
      setAgreementDocs(ag);
    } else {
      setLoading(true);
      Object.keys(agreementDetails).forEach((key) => {
        filteredData[key] = agreementDetails[key].filter(
          (item: any) =>
            item.sapCustomerId?.toString().includes(searchValue) || // Check customer ID
            item.companyName?.toLowerCase().includes(searchValue.toLowerCase()) // Check company name
        );
      });
      let ag: any = { data: { data: filteredData } }
      await setAgreementDocs(ag)
      setLoading(false);
    }
  }

  const filterByCustomerTpe = async (customerType: any) => {
    setLoading(true);
    const limit = 20; // Define limit
    let skip = 0; // Initialize skip
    let totalCount = 0; // Initialize totalCount
    let tempData: any;
    if (customerType === 'Dealer') {
      tempData = {
        Silver: [],
        Gold: [],
        Platinum: [],
        Elite: [],
        otherDealers: [],
      };
    } else if (customerType === 'IWS') {
      tempData = {
        IWS: [],
        otherDealers: []
      };
    } else if (customerType === 'Government') {
      tempData = {
        Government: [],
        otherDealers: []
      };
    } else if (customerType === 'TPC Dealer') {
      tempData = {
        'TPC Dealer': [],
        otherDealers: []
      };
    } else if (customerType === 'TBL Dealer') {
      tempData = {
        'TBL Dealer': [],
        otherDealers: []
      };
    } else if (customerType === 'Fleet') {
      tempData = {
        Fleet: [],
        otherDealers: []
      };
    }
    // Initialize a temporary object to accumulate all data before updating the state
    do {
      const response: any = await dispatch(getAgreementDocs(customerType, limit, skip));

      if (response && response.data) {
        const res = response.data;
        // Log the response data to ensure it's being fetched correctly
        // Check if any category has data
        const isDataFetched =
          res.Silver?.length > 0 ||
          res.Gold?.length > 0 ||
          res.Platinum?.length > 0 ||
          res.Elite?.length > 0 ||
          res.otherDealers?.length > 0 ||
          res.IWS?.length > 0 ||
          res.Government?.length > 0 ||
          res.Fleet?.length > 0 ||
          res['TBL Dealer']?.length > 0 ||
          res['TPC Dealer']?.length > 0
        if (isDataFetched) {
          // Append the fetched data to the corresponding categories in tempData
          Object.keys(res).forEach((key) => {
            if (Array.isArray(res[key])) {
              tempData[key] = [
                ...tempData[key],      // Append existing data
                ...res[key],           // Append new fetched data
              ];
            }
          });
        }
      }

      // Update totalCount from the first response (for pagination)
      if (response && response.data && response.data.customersTotaCount && skip === 0) {
        totalCount = response.data.customersTotaCount;
      }

      // Increment skip by the limit to fetch the next set of data
      skip += limit;

    } while (skip < totalCount); // Continue looping until all data is fetched

    dispatch(setAgreementDetails(tempData));
    setLoading(false);

    // return tempData; // Return the accumulated data (not the state, since it's updated asynchronously)
  };

  return (
    <>
      {(agreementDocs && agreementDocs.loading || loading) && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
        }}
      />
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.onClose(false) }}
        fullWidth={true}
        maxWidth={'md'}
        disableEscapeKeyDown>
        <DialogTitle className="pb-0" id="form-dialog-title">
          <div className="page-title">
            Email Agreement Docs
            <IconButton aria-label="close" className="float-right" onClick={() => {
              setTabValue(0);
              props.onClose(false);
            }}>
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <div className="p-0 col-12 h-240">
          <div className='flex-end mt-3 float-right'>
            <TextField
              id="kamList"
              select
              label="Filter By CustomerType"
              variant="outlined"
              className="rounded-input-dealer mw-200p mr-2 mb-2"
              size="small"
              onChange={async (e: any) => {
                setError('');
                setSearchValue('');
                setFilterByCustomer(e.target.value)
                filterByCustomerTpe(e.target.value === 'Workshop' ? 'IWS' : e.target.value)
                // let data: any = await dispatch(getAgreementDocs(e.target.value === 'Workshop' ? 'IWS' : e.target.value, searchValue ? searchValue : ''));
                // if (data && data.message && data.message.status === 'S') {
                // } else {
                //   setError(data.error ? data.error : data.message)
                // }
              }}
              value={filterByCustomer}
            >
              <MenuItem value={"Dealer"}>
                Dealer
              </MenuItem>\ <MenuItem value={"Workshop"}>
                Workshop
              </MenuItem>\ <MenuItem value={"Government"}>
                Government
              </MenuItem>\ <MenuItem value={"Fleet"}>
                Fleet
              </MenuItem>
              <MenuItem value={"TPC Dealer"}>
                TPC Dealer
              </MenuItem>\
              <MenuItem value={"TBL Dealer"}>
                TBL Dealer
              </MenuItem>\
            </TextField>
            <FormControl variant="outlined">
              <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search by Customer or SAP ID</InputLabel>
              <OutlinedInput
                id="dealer-search"
                value={searchValue}
                onKeyPress={async (ev) => {
                  if (ev.key === 'Enter') {
                    filterDataByCustomerIdOrName(filterByCustomer, searchValue ? searchValue : '');
                    // let data: any = await dispatch(getAgreementDocs(filterByCustomer === 'Workshop' ? 'IWS' : filterByCustomer, searchValue ? searchValue : ''));
                    // if (data && data.message && data.message.status === 'S') {
                    // } else {
                    //   setError(data.error ? data.error : data.message)
                    // }
                  }
                }}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search by Customer or SAP ID"
                      edge="end"
                      onClick={async (e: any) => {
                        filterDataByCustomerIdOrName(filterByCustomer, searchValue ? searchValue : '');
                        // let data: any = await dispatch(getAgreementDocs(filterByCustomer === 'Workshop' ? 'IWS' : filterByCustomer, searchValue ? searchValue : ''));
                        // if (data && data.message && data.message.status === 'S') {
                        // } else {
                        //   setError(data.error ? data.error : data.message)
                        // }
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={280} margin="dense" className="rounded-input-dealer bd-highlight w-280p mr-2 mb-2" />
            </FormControl>
          </div>
        </div>
        <DialogContent className="p-0">
          <div className="p-0">
            <Paper square>
              <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Agreement Tab">
                <Tab label="Customer Agreement Renewal" {...allTabProps(0)} />
                <Tab label="Category Certificate" {...allTabProps(1)} />
              </Tabs>
            </Paper>
            <TabPanel value={tabValue} index={0}>
              {<Grid item xs={12} className="mt-2 cardCommon">
                <TableContainer component={Paper} className="pb-5 mb-5">
                  <Table aria-label="dealertable">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Customer</div>
                          <div className="dealer-table-title-desc">Name &amp; SAP ID</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Contact</div>
                          <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Assigned</div>
                          <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Sent</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Uploaded Doc</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Status</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <React.Fragment>
                        {agreementDocs && agreementDocs?.data && agreementDocs?.data?.data && (
                          renderRows(agreementDocs.data.data, handleSelectionChange)
                        )}
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </TableContainer>
                <span className='text-center ml-25 flex-center justify-content-center mt-n-5'>{error}</span>
              </Grid>}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              {<Grid item xs={12} className="mt-2 cardCommon">
                <TableContainer component={Paper} className="pb-5 mb-5">
                  <Table aria-label="dealertable">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Customer</div>
                          <div className="dealer-table-title-desc">Name &amp; SAP ID</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Contact</div>
                          <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title">Assigned</div>
                          <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Last Sent</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-title mb-3">Status</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <React.Fragment>
                        {agreementDocs && agreementDocs?.data && agreementDocs?.data?.data && (
                          renderRows(agreementDocs.data.data, handleSelectionChange)
                        )}
                      </React.Fragment>
                    </TableBody>
                  </Table>
                </TableContainer>
                <span className='text-center ml-25 flex-center justify-content-center mt-n-5'>{error}</span>
              </Grid>}
            </TabPanel>
          </div>
        </DialogContent>
        <DialogActions className="my-2 mr-4">
          <Button
            variant="contained"
            color="secondary"
            className="rounded-button-dealer pt-2 pb-2 mr-2"
            onClick={() => {
              sendEmail();
            }}
          >
            Send Email
          </Button>
          <Button variant="contained" className="rounded-button-dealer-cancel pl-4 pr-4" onClick={() => { setTabValue(0); props.onClose(false); }} >Close</Button>
        </DialogActions>
      </Dialog >
    </>
  )
}

export default AgreementDialog
