import { ORDER_ENQUIRY } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  enquiryList: null,
  enquiryAction: null,
  enquiryStatus: null,
  fseList: null,
  archivedOrderDetails: null,
};

function orderEnquiryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_FETCHING:
      return {
        ...state,
        enquiryList: { loading: true, data: null, err: null }
      };
    case ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_SUCCESS:
      return {
        ...state,
        enquiryList: {
          totalCount: action.payload.data && action.payload.data.data && action.payload.data.data && action.payload.data.data.totalCount ? action.payload.data.data.totalCount : 0,
          loading: false,
          data: {
            data: action.payload.data && action.payload.data.data && action.payload.data.data.enquiryList && Array.isArray(action.payload.data.data.enquiryList) && action.payload.data.data.enquiryList,
          },
          err: null
        }
      };
    case ORDER_ENQUIRY.GET_ORDER_ENQYUIRY_ERROR:
      return {
        ...state,
        enquiryList: { loading: false, data: null, err: action.payload.error }
      };

    case ORDER_ENQUIRY.GET_ARCHIVED_DATA_FETCHING:
      return {
        ...state,
        archivedData: { loading: true, data: null, err: null }
      };
    case ORDER_ENQUIRY.GET_ARCHIVED_DATA_SUCCESS:
      return {
        ...state,
        archivedData: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ORDER_ENQUIRY.GET_ARCHIVED_DATA_ERROR:
      return {
        ...state,
        archivedData: { loading: false, data: null, err: action.payload.error }
      };

      case ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_FETCHING:
      return {
        ...state,
        archivedOrderDetails: { loading: true, data: null, err: null }
      };
    case ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_SUCCESS:
      return {
        ...state,
        archivedOrderDetails: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ORDER_ENQUIRY.GET_ARCHIVED_DETAILS_ERROR:
      return {
        ...state,
        archivedOrderDetails: { loading: false, data: null, err: action.payload.error }
      };
    case ORDER_ENQUIRY.GET_ENQUIRY_STATUS_FETCHING:
      return {
        ...state,
        enquiryStatus: { loading: true, data: null, err: null }
      };
    case ORDER_ENQUIRY.GET_ENQUIRY_STATUS_SUCCESS:
      return {
        ...state,
        enquiryStatus: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ORDER_ENQUIRY.GET_ENQUIRY_STATUS_ERROR:
      return {
        ...state,
        enquiryStatus: { loading: false, data: null, err: action.payload }
      };
    case ORDER_ENQUIRY.GET_FSE_LIST_FETCHING:
      return {
        ...state,
        fseList: { loading: true, data: null, err: null }
      };
    case ORDER_ENQUIRY.GET_FSE_LIST_SUCCESS:
      return {
        ...state,
        fseList: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case ORDER_ENQUIRY.GET_FSE_LIST_ERROR:
      return {
        ...state,
        fseList: { loading: false, data: null, err: action.payload }
      };
    case ORDER_ENQUIRY.IS_ORDER_ENQUIRY_DETAILS:
      return {
        ...state,
        enquiryAction: action.payload,
      }
    default:
      return state;
  }
};

export default orderEnquiryReducer;
