/* eslint-disable  */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { useHistory } from "react-router-dom";
import { getCustomerDetailsByCustomerID, setValuesForChooseCustomer } from "../../store/actions/dealer.action";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import TextField from "@material-ui/core/TextField";
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { userAccessControl } from '../LeftMenu/UserAccessControl';
import { getProfile } from '../../store/actions/profile.action';

const useStyles = makeStyles(() => (
    {
        root: {
            width: '100%',
        },
        title: {
            flex: '1 1 100%',
        },
        closeButton: {
            backgroundColor: '#EAEAEA',
            width: 120,
            fontWeight: 800,
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
            fontSize: '0.688rem',
            color: '#666666',
            borderRadius: '2.5rem',
            height: 35,
            textAlign: 'center',
            marginRight: 24
        },
        icon: {
            float: "left",
            margin: "0 20px 20px 0",
            height: "100%",
            fill: "white",
        },
        cancel: {
            fontSize: '0.813rem',
            fontFamily: 'Nunito',
            fontWeight: 700,
            color: '#919191',
            textTransform: 'capitalize'
        },
        rejectText: {
            color: '#616161',
            fontSize: '5rem',
            fontFamily: 'Nunito',
            fontWeight: 900,
            textTransform: 'inherit'
        }
    }));

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const ChooseCustomerDialog = (props: any) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch: Dispatch<any> = useDispatch();
    const [alfuttaimCustomeNumberOrName, setAlfuttaimCustomeNumberOrName] = useState<any>('');
    const [selectCreateOrder, setSelectCreateOrder] = React.useState<any>("");
    const [createdOrderType, setCreatedOrderType] = React.useState<any>("");
    const [orderCreatedError, setOrderCreatedError] = React.useState<boolean>(false);
    const { getCustomerDetailsBySapId, getFSECustomerListData }: any = useSelector((state: any) => state.dealer);
    const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
    const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
    const [searchText, setSearchText] = React.useState<any>('');
    const [customerId, setCustomerId] = React.useState<any>([]);
    const [customerInfo, setCustomerInfo] = React.useState<any>([]);
    const [count, setCount] = React.useState<any>();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedCustomerID, setSelectedCustomerID] = useState<any>('');
    const userType = sessionStorage.getItem('userType');
    const customerTypeAccess = sessionStorage.getItem('customerTypeAccess');
    const [loading, setLoading] = React.useState<any>(false);
    const [orderType, setOrderType] = React.useState<any>("");
    const [orderTypeError, setOrderTypeError] = React.useState<boolean>(false);
    const [selectOffice, setSelectOffice] = useState<any>('');
    const [selectedOfficeTitle, setSelectedOfficeTitle] = useState<any>('');
    const [selectedOfficeTypeError, setSelectedOfficeTypeError] = React.useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>([]);
    const [isDisabled, setIsDisabled] = React.useState<boolean>(false);
    const [isCustomerIDSelected, setIsCustomerIDSelected] = React.useState<boolean>(false);
    const getSelectedOrderType: any = sessionStorage.getItem("orderType");
    const getSelectedOffice = sessionStorage.getItem('selectOffice');
    const [selectedCustomerData, setSelectedCustomerData] = React.useState<any>("");
    const getFinalOrderType: any = sessionStorage.getItem('finalOrderType');
    const getFinalSelectOffice: any = sessionStorage.getItem('finalSelectOffice');
    const [onCancelResetValues, setOnCancelResetValues] = React.useState<boolean>(false);
    const getOrderCreatedBy: any = sessionStorage.getItem('orderCreatedBy');
    const getFinalOrderCreatedBy: any = sessionStorage.getItem('finalOrderCreated');
    const selectedSAPIDCustomerType = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType;


    const chooseCustomer: any = async () => {
        if (props.onClickOfChangeButton === true) {
            if (getCustomerDetailsBySapId && getCustomerDetailsBySapId.data) {
                await userAccessControl(getCustomerDetailsBySapId?.data?.accessParameter, 'FSE')
                const kamEmail = sessionStorage.getItem('email');
                await dispatch(getProfile(kamEmail, 'fse'));
                setSelectedCustomerId(getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId);
                setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
                if (getSelectedOffice !== "" && getSelectedOrderType !== "") {
                    setSelectOffice(getSelectedOffice);
                    setOrderType(getSelectedOrderType);
                }
                if (getCustomerDetailsBySapId.data.customerTierType === 'tpcdealer' || getCustomerDetailsBySapId.data.customerTierType === 'Workshop') {
                    await userAccessControl(getCustomerDetailsBySapId?.data?.accessParameter, 'FSE')
                    setOrderType(getCustomerDetailsBySapId.data.customerAccountType === 'Cash' ? 'CASH' : getCustomerDetailsBySapId.data.customerAccountType === 'Credit' ? 'CRD' : '');
                }
                if (getOrderCreatedBy !== " ") {
                    setSelectCreateOrder(getOrderCreatedBy);
                }
            }
        } else {
            setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
            if (getCustomerDetailsBySapId && getCustomerDetailsBySapId.data) {
                const kamEmail = sessionStorage.getItem('email');
                await dispatch(getProfile(kamEmail, 'fse'));
                await userAccessControl(getCustomerDetailsBySapId?.data?.accessParameter, 'FSE')
                if (getCustomerDetailsBySapId.data.customerTierType === 'tpcdealer' || getCustomerDetailsBySapId.data.customerTierType === 'Workshop') {
                    userAccessControl(getCustomerDetailsBySapId?.data?.accessParameter, 'FSE')
                    setOrderType(getCustomerDetailsBySapId.data.customerAccountType === 'Cash' ? 'CASH' : getCustomerDetailsBySapId.data.customerAccountType === 'Credit' ? 'CRD' : '');
                }
            }
        }
    }
    useEffect(() => {
        chooseCustomer();
    }, [getFSECustomerListData, selectedCustomerId, getCustomerDetailsBySapId]);

    const handleChange = (event: any) => {
        setSelectCreateOrder(event.target.value);
        setCreatedOrderType(event.target.value);
    };

    const handleChangeOrderType = (event: any) => {
        setOrderType(event.target.value);
    };


    const handleClose = () => {
        setAnchorEl(null);
        setSearchText("");
    };

    const resetData = () => {
        setAlfuttaimCustomeNumberOrName('');
        setSelectCreateOrder("");
        setCreatedOrderType("");
        setOrderTypeError(false);
        setOrderCreatedError(false);
        setSelectOffice("");
        setCustomerId([]);
        setSelectedCustomerId([]);
        setOrderType("");
        setOrderTypeError(false);
        setSelectOffice("");
        setSelectedOfficeTypeError(false);
    };

    const onCustomerChangeReset = () => {
        setSelectCreateOrder("");
        setCreatedOrderType("");
        setOrderTypeError(false);
        setOrderCreatedError(false);
        setSelectOffice("");
        setOrderType("");
        setOrderTypeError(false);
        setSelectOffice("");
        setSelectedOfficeTypeError(false);
    }

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const onSearchCustomerId = (searchText: any) => {
        setSearchText(searchText);
        setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
        if (searchText.length > 0) {
            const filteredIds = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()) || sapId.name.toLowerCase().includes(searchText.toLowerCase()));
            setCustomerId(filteredIds);
        } else {
            setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
        }
    };

    const handleClickSapId = (event: any, sapId: any) => {
        setSelectedCustomerId(sapId);
        dispatch(getCustomerDetailsByCustomerID(sapId));
        setIsDisabled(true);
        setIsCustomerIDSelected(true);
        onCustomerChangeReset();
        sessionStorage.setItem('orderType', "");
        sessionStorage.setItem('selectOffice', "");
        sessionStorage.setItem('orderCreatedBy', "");
    };

    return (
        <div>
            {loading && (<FullscreenLoader />)}
            <Dialog
                open={props.openChooseCustomerDialog}
                disableEscapeKeyDown
                aria-abelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div className='px-3'>
                    <div className="d-flex flex-row col-12 px-3 mt-3">
                        <h4 className="col-12 shift-to-party-page-title mb-2">Choose Customer</h4>
                    </div>
                    <div className="px-4 col-12 col-sm-12 mt-2 col-md-12 mb-2 col-lg-12 d-inline-flex pl-0">
                        <TextField
                            required
                            id="customerId"
                            variant="outlined"
                            size="small"
                            onClick={handleClick}
                            value={selectedCustomerId}
                            label="Al-Futtaim Customer Number or Name"
                            InputLabelProps={{
                                style: { color: '#000000' },
                            }}
                            aria-controls="customized-menu"
                            className="smallText mt-2 commonRoundedInputs w-100"
                            aria-haspopup="true"
                            placeholder="Al-Futtaim Customer Number or Name"
                        >
                        </TextField>
                        <StyledMenu
                            id="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            className="dealer-custom-dropdown"
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <div>
                                <div className="mt-2">
                                    <div className="searchDealer">
                                        <IconButton
                                            edge="start"
                                            className="ml-2"
                                            disabled
                                        >
                                            <SearchIcon fontSize="inherit" />
                                        </IconButton>
                                        <TextField value={searchText} onChange={(e) => { onSearchCustomerId(e.target.value) }}
                                            size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                                    </div>
                                </div>
                                {customerId && customerId.length > 0 && customerId.map((sapId: any, i: any) => (
                                    <Grid key={i} container className="border-bottom">
                                        <MenuItem
                                            disabled={sapId.isVisible === false}
                                            key={i} value={sapId.sapCustomerId}>
                                            <ListItemText
                                                primary={sapId.sapCustomerId} onClick={(event) => { handleClickSapId(event, sapId.sapCustomerId); handleClose() }} /><p className="px-5" onClick={(event) => { handleClickSapId(event, sapId.sapCustomerId); handleClose() }}> {" "} {" "} {sapId.name}</p>
                                        </MenuItem>
                                    </Grid>
                                ))}
                                {customerId && customerId.length === 0 && (
                                    <Grid container>
                                        <div className="d-flex justify-content-center text-center mx-auto">No Customers found.</div>
                                    </Grid>
                                )}
                            </div>
                        </StyledMenu>
                    </div>
                </div>
                <>
                    {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && selectedCustomerId && selectedCustomerId.length > 0 && (
                        <div className='collapseBgChooseCustomer mt-2 px-2'>
                            <div className="row-space-start col-12 mb-2 mt-2">
                                <div className="col-12 px-4">
                                    <div className="mb-0">
                                        <div className='row'>
                                            <span className="headingColourChooseCustomer mt-2 mb-2 px-3">{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.title}{" "}{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName}{" "} </span>
                                        </div>
                                        <div className='row'>
                                            <span className="fontColourChooseCustomer px-3">{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.streetName ? getCustomerDetailsBySapId.data.streetName + "," : ""}{" "}{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.city ? getCustomerDetailsBySapId.data.city + "," : " "}{" "} {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.country}</span>
                                        </div>
                                        <div className='row'>
                                            <span className="subheadingChooseCustomer mt-2 mb-1 px-3">{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.telephones ? getCustomerDetailsBySapId.data.telephones + " | " : " "}{" "}{" "}{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.city}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.orderCreatedFor && (isCustomerIDSelected === true || props.onClickOfChangeButton === true) ?
                        <div className='px-0'>
                            <div className="d-flex flex-row px-5">
                                <div className=''><h6 className="page-title mt-3" style={{ fontSize: "15px" }}>Order Created* </h6></div>
                                <div></div>
                            </div>
                            <div className="d-flex flex-row border-bottom mb-2 px-5">
                                <div className="row">
                                    <FormControl component="fieldset" name="customer-type" className="pl-2">
                                        <RadioGroup className="mt-2 ml-1" row onChange={handleChange} value={selectCreateOrder}>
                                            {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.orderCreatedFor && getCustomerDetailsBySapId.data.orderCreatedFor.length > 0 && getCustomerDetailsBySapId.data.orderCreatedFor.map((type: any) => (
                                                <>
                                                    <>
                                                        {userType === "FSE" ?
                                                            <FormControlLabel
                                                                value={type.value}
                                                                disabled={props.onClickOfChangeButton === true && isDisabled === false}
                                                                control={<Radio color="primary" />}
                                                                label={type.title}
                                                            ></FormControlLabel>
                                                            : ""}
                                                    </>
                                                </>
                                            ))}
                                        </RadioGroup>
                                        {orderCreatedError && <span className="px-2" style={{ color: 'red' }}>{"Please select order created for."}</span>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {(getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && (getCustomerDetailsBySapId.data.customerTierType === "Workshop" || getCustomerDetailsBySapId.data.customerTierType === "tpcdealer")) && (isCustomerIDSelected === true || props.onClickOfChangeButton === true) ?
                        <div className='px-1'>
                            <div className="d-flex flex-row px-5">
                                <div className=''><h6 className="page-title mt-3" style={{ fontSize: "15px" }}>Order Type* :</h6></div>
                                <div></div>
                            </div>
                            <div className="d-flex flex-row border-bottom mb-2 px-5 mt-3">
                                <div className="row">
                                    <FormControl component="fieldset" name="customer-type" className="pl-2">
                                        <RadioGroup className="mt-2 ml-1" row onChange={handleChangeOrderType} value={orderType}>
                                            {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.orderType && getCustomerDetailsBySapId.data.orderType.length > 0 && getCustomerDetailsBySapId.data.orderType.map((type: any) => (
                                                <>
                                                    <>
                                                        <FormControlLabel
                                                            value={type.value}
                                                            disabled
                                                            // disabled={props.onClickOfChangeButton === true && isDisabled === false}
                                                            control={<Radio color="primary" />}
                                                            label={type.title}
                                                        ></FormControlLabel>
                                                    </>
                                                </>
                                            ))}
                                        </RadioGroup>
                                        {orderTypeError && <span className="px-3" style={{ color: 'red' }}>{"Please select Order type"}</span>}
                                    </FormControl>
                                </div>
                            </div>
                        </div>
                        : ""}
                    {(getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && (getCustomerDetailsBySapId.data.customerTierType === "Workshop" || getCustomerDetailsBySapId.data.customerTierType === "tpcdealer")) && (isCustomerIDSelected === true || props.onClickOfChangeButton === true) ?
                        <div className="px-5 col-12 col-sm-7 mt-2 col-md-7 mb-2 col-lg-7 d-inline-flex pl-0">
                            <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                                <InputLabel id="demo-simple-select-outlined-label">Select SO*</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={selectOffice}
                                    disabled={props.onClickOfChangeButton === true && isDisabled === false}
                                    error={selectedOfficeTypeError}
                                    onChange={(e) => {
                                        setSelectOffice(e.target.value);
                                    }}
                                    label="selectOffice"
                                >
                                    {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.salesOffice && getCustomerDetailsBySapId.data.salesOffice.length > 0 && getCustomerDetailsBySapId.data.salesOffice.map((ele: any, i: any) => (
                                        <MenuItem key={i} value={ele.value} onClick={(e) => {
                                            setSelectedOfficeTitle(ele.title);
                                        }} > {ele.title} </MenuItem>
                                    ))}
                                </Select>
                                {selectedOfficeTypeError && <span className="mt-2 px-2" style={{ color: 'red' }}>Please select the SO.</span>}
                            </FormControl>
                        </div>
                        : ""}
                </>
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => {
                                                    setOnCancelResetValues(true);
                                                    props.openCloseChooseCustomer();
                                                    setOrderType(getFinalOrderType);
                                                    setSelectOffice(getFinalSelectOffice);
                                                    setSelectCreateOrder(getFinalOrderCreatedBy);
                                                    sessionStorage.setItem('orderType', getFinalOrderType);
                                                    sessionStorage.setItem('selectOffice', getFinalSelectOffice);
                                                    sessionStorage.setItem('orderCreatedBy', getFinalOrderCreatedBy);
                                                    if (props.onClickOfChangeButton === undefined || props.onClickOfChangeButton === false) {
                                                        resetData();
                                                        setOrderType(getFinalOrderType);
                                                        setSelectOffice(getFinalSelectOffice);
                                                        setSelectCreateOrder(getFinalOrderCreatedBy);
                                                    }
                                                }}>Cancel
                                            </Button>
                                            <Button variant="contained"
                                                disabled={props.onClickOfChangeButton === true && isDisabled === false && selectedCustomerId && selectedCustomerId.length > 0 ? true : selectedCustomerId && selectedCustomerId.length > 0 ? false : true}
                                                color="primary" className="submit-button-chooseCustomer"
                                                onClick={() => {
                                                    setLoading(true);
                                                    sessionStorage.setItem('isImportFromOES', 'false');
                                                    sessionStorage.setItem("partsData", "")
                                                    if (selectedSAPIDCustomerType === "Int. Business") {
                                                        if (selectCreateOrder === "" && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.orderCreatedFor) {
                                                            setOrderCreatedError(true);
                                                        } else {
                                                            sessionStorage.setItem('isImportFromOES', 'false');
                                                            setOrderCreatedError(false);
                                                            // dispatch(setValuesForChooseCustomer());
                                                            setOrderCreatedError(false);
                                                            props.openCloseChooseCustomer();
                                                            // if (window.location.pathname.includes('createenquiry')) {
                                                            // } else {
                                                            if (props.isEnquirySaved === false) {
                                                                window.location.pathname.includes('createenquiry')
                                                            } else if (props.isEnquirySaved === undefined) {
                                                                history.push('/createenquiry');
                                                                sessionStorage.setItem("enquiry-current-status", "");
                                                                sessionStorage.setItem('subEnquiryId', "");
                                                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                                                sessionStorage.setItem('Order-log-ReadCount', "")
                                                                sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                                sessionStorage.setItem('isCopyandCreate', "");
                                                            } else {
                                                                history.push('/createenquiry');
                                                                sessionStorage.setItem('subEnquiryId', "");
                                                                sessionStorage.setItem("enquiry-current-status", "");
                                                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                                                sessionStorage.setItem('Order-log-ReadCount', "")
                                                                sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                                sessionStorage.setItem('isCopyandCreate', "");
                                                            }
                                                            sessionStorage.setItem('orderCreatedBy', selectCreateOrder);
                                                            sessionStorage.setItem('finalOrderCreated', selectCreateOrder);
                                                            sessionStorage.setItem('CompanyName', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName);
                                                            sessionStorage.setItem('companyCurrencyType', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyCurrencyType);
                                                            sessionStorage.setItem('selectedSapCustomerId', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId);
                                                        }
                                                    } else if (getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && (getCustomerDetailsBySapId.data.customerTierType === "Workshop" || getCustomerDetailsBySapId.data.customerTierType === "tpcdealer")) {
                                                        if (selectOffice === "") {
                                                            setSelectedOfficeTypeError(true);
                                                            setLoading(false);
                                                        } else if (orderType === "") {
                                                            setOrderTypeError(true);
                                                            setLoading(false);
                                                        } else {
                                                            setOrderCreatedError(false);
                                                            props.openCloseChooseCustomer();
                                                            // if (window.location.pathname.includes('createenquiry')) {
                                                            // } else {
                                                            //     history.push('/createenquiry');
                                                            // }
                                                            if (props.isEnquirySaved === false) {
                                                                window.location.pathname.includes('createenquiry')
                                                            } else if (props.isEnquirySaved === undefined) {
                                                                history.push('/createenquiry');
                                                                sessionStorage.setItem("enquiry-current-status", "");
                                                                sessionStorage.setItem('subEnquiryId', "");
                                                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                                                sessionStorage.setItem('Order-log-ReadCount', "")
                                                                sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                                sessionStorage.setItem('isCopyandCreate', "");
                                                            } else {
                                                                history.push('/createenquiry');
                                                                sessionStorage.setItem("enquiry-current-status", "");
                                                                sessionStorage.setItem('subEnquiryId', "");
                                                                sessionStorage.setItem('Order-log-UnReadCount', "")
                                                                sessionStorage.setItem('Order-log-ReadCount', "")
                                                                sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                                sessionStorage.setItem('isCopyandCreate', "");
                                                            }
                                                            // resetData();
                                                            setLoading(false);
                                                            sessionStorage.setItem('orderType', orderType);
                                                            sessionStorage.setItem('selectOffice', selectOffice);
                                                            sessionStorage.setItem('CompanyName', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName);
                                                            sessionStorage.setItem('companyCurrencyType', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyCurrencyType);
                                                            sessionStorage.setItem('selectedSapCustomerId', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId);
                                                            sessionStorage.setItem('selectedOfficeTitle', selectedOfficeTitle);
                                                            sessionStorage.setItem('finalOrderType', orderType);
                                                            sessionStorage.setItem('finalSelectOffice', selectOffice);
                                                            // dispatch(setValuesForChooseCustomer());
                                                            // dispatch(getDataFromChooseCustomer(data));
                                                        }
                                                    } else {
                                                        // dispatch(setValuesForChooseCustomer());
                                                        setOrderCreatedError(false);
                                                        props.openCloseChooseCustomer();
                                                        // if (window.location.pathname.includes('createenquiry')) {
                                                        // } else {
                                                        //     history.push('/createenquiry');
                                                        // }
                                                        if (props.isEnquirySaved === false) {
                                                            window.location.pathname.includes('createenquiry')
                                                        } else if (props.isEnquirySaved === undefined) {
                                                            history.push('/createenquiry');
                                                            sessionStorage.setItem("enquiry-current-status", "");
                                                            sessionStorage.setItem('subEnquiryId', "");
                                                            sessionStorage.setItem('Order-log-UnReadCount', "")
                                                            sessionStorage.setItem('Order-log-ReadCount', "")
                                                            sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                            sessionStorage.setItem('isCopyandCreate', "");
                                                        } else {
                                                            history.push('/createenquiry');
                                                            sessionStorage.setItem("enquiry-current-status", "");
                                                            sessionStorage.setItem('subEnquiryId', "");
                                                            sessionStorage.setItem('Order-log-UnReadCount', "")
                                                            sessionStorage.setItem('Order-log-ReadCount', "")
                                                            sessionStorage.setItem('reset-the-orderlog-count', "true")
                                                            sessionStorage.setItem('isCopyandCreate', "");
                                                        }
                                                        sessionStorage.setItem('CompanyName', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName);
                                                        sessionStorage.setItem('companyCurrencyType', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyCurrencyType);
                                                        sessionStorage.setItem('selectedSapCustomerId', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId);
                                                    }
                                                    setLoading(false);
                                                }}>Submit
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog >
        </div >
    )
}
export default ChooseCustomerDialog