/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDealers, getAllDealersRequests, getAllUsers, getDealerDetails, getDealerCreditRequest, getkamUserList,
  isEdit, selectedNewDealer, viewCreditDealer, getCustomerFilterStatus, getFSECustomerTypes, getAllFSEDealers, getInactiveStatusDetails, getFSEKAMUserList, getFSESalesRegions, getKAMUserListForKAM
} from "../../store/actions/dealer.action";
import "./Dealers.css";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import GroupIcon from '@material-ui/icons/Group';
import Snackbar from './../../common/components/Snackbar';
import AddDealerDialog from './AddDealerDialog';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import DealerDetailsDialog from "./DealerDetailsDialog";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import PostAddIcon from '@material-ui/icons/PostAdd';
import { ButtonGroup, Tab, Tabs } from "@material-ui/core";
import defaultLogo from '../../assets/default-user-logo.png';
import { getNewBrand } from "../../common/components/util";
import { get } from "lodash";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Visibility from "@material-ui/icons/Visibility";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import Dialog from '@material-ui/core/Dialog';
import RetrieveBrandsDialog from "./RetrieveBrandsDialog";
import { AddNewDealer } from "./newAddDealerDialog";
import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getCustomerDealerDetails } from "../../store/actions/superAdmin.actions";
import { search } from "superagent";
import { EmojiEventsOutlined } from "@material-ui/icons";
import moment from "moment-timezone";
import { getCustomerType } from "../../store/actions/onboard.actions";
import PublishRevisedTandCDialog from "./PublishRevisedT&CDialog";
import { getIsSevenDaysDate } from "../../common/components/util";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
// import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import HelpIcon from "@material-ui/icons/Help";
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '@material-ui/icons/Error';
import { isEmpty, isString } from 'lodash';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
}))

const Dealers: React.FC = () => {
  const [dealerDetailTab, setDealerDetailTab] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers, kamUserList, kamFseUserList, fseSalesRegionList, kamUserListForKam, dealerRequests, getInactiveStatusaApiResponse }: any = useSelector((state: any) => state.dealer);
  const [openDealerDetailDialog, setOpenDealerDetailDialog] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openAddDealer, setOpenAddDealer] = React.useState(false);
  const [openNewAddDealerPopup, setOpenNewAddDealerPopup] = React.useState(false);
  const [isDialogForEdit, setIsDialogForEdit] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const reportingKamIds = get(profileDetails, 'data.data.basicInfo.reportingKamIds', []);
  const [kamUserId, setKamUserId] = React.useState<any>(sessionStorage.getItem('userType') === 'FSE' ? reportingKamIds && reportingKamIds.length > 0 && reportingKamIds[0] : sessionStorage.getItem('userType') === 'kam' ? sessionStorage.getItem('webApplicationLoginId') : "0");
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [filterValue, setFilterValue] = React.useState("");
  const [filterByFseSalesRegion, setFilterByFseSalesRegion] = React.useState("");
  const [filterByKamSalesRegion, setFilterByKamSalesRegion] = React.useState("");
  const [filterValueForNewDealerReq, setFilterValueForNewDealerReq] = React.useState(sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe');
  const [FSECustomerList, setFSECustomerTypes] = React.useState([])
  const [selectedStatusByKAM, setSelectedStatusByKAM] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { getCustomerFilterStatusData }: any = useSelector((state: any) => state.dealer);
  const [filterByStatusNewDealer, setFilterByStatusNewDealer] = React.useState("ALL")
  const [selectedStatusFilterByKAM, setSelectedStatusFilterByKAM] = React.useState<any>([
    {
      name: "All",
      code: "all"
    },
    {
      name: "Assigned to me",
      code: "assignedToMe"
    },
    {
      name: "Added by me",
      code: "addedByMe"
    },
  ]);
  const { tabValue }: any = useSelector((state: any) => state.dealer);
  const [selectedTab, setselectedTab] = React.useState(tabValue ? tabValue : 0);
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  // let adminDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails) ? dealerDetails.data.data.userDetails : null;
  const { changeCustomerTypeData }: any = useSelector((state: any) => state.onboard);
  //const webApplicationLoginId = adminDetails && adminDetails.webApplicationLoginId;
  let userType = sessionStorage.getItem('userType');
  // const userType = get(profileDetails, 'data.data.basicInfo.userType', '');
  const [open, setOpen] = React.useState(false);

  const [openPublishRevisedTandCDialog, setOpenPublishRevisedTandCDialog] = React.useState(false);
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
  const [showOnboard, showOnboarding] = React.useState(false);
  const [item, setItem] = React.useState<any>();
  const [customerTier, setCustomerTier] = React.useState<any>("")
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  const [customerAcceessForFleetGovt, setCustomerAcceessForFleetGovt] = React.useState(false);
  const [customerAcceessForWorkshop, setCustomerAcceessForWorkshop] = React.useState(false);
  const [customerAcceessForTBLDealer, setCustomerAcceessForTBLDealer] = React.useState(false);
  const [customerAcceess, setCustomerTypeAccess] = React.useState([]);
  const [filterByCustomer, setFilterByCustomer] = React.useState(sessionStorage.getItem('userType') !== 'kam' ? "All" : customerAcceess && customerAcceess.length > 1 ? "All" : customerAcceess && customerAcceess.length > 0 ? customerAcceess[0] : '')
  const [filterByCustomerNewDealer, setFilterByCustomerNewDealer] = React.useState(sessionStorage.getItem('userType') !== 'kam' ? "All" : customerAcceess && customerAcceess.length > 1 ? "All" : customerAcceess && customerAcceess.length > 0 ? customerAcceess[0] : '')
  const [openInfo, setOpenInfo] = React.useState(-1);
  const [loading, setLoading] = React.useState<any>(false);

  const [publishedDate, setPublishedDate] = React.useState(new Date("2024-05-06T12:06:40Z")); // Published date of T&C
  const [subUserBlocked, setSubUserBlocked] = React.useState(false); // Whether the sub user is blocked
  const tandCDate = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.tandCDate && profileDetails.data.data.customerInfo.tandCDate;

  const verifySuccess = () => {
    showOnboarding(true);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleClose = () => {
  //     // setselectedOrder(null)
  // };

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      let formattedTypes = customerTypeAccess.map((type: any) => {
        if (type.toLowerCase() === "intbusiness") {
          return "Int. Business";
        } if (type.toLowerCase() === "tpcdealer") {
          return "TPC Dealer";
        }
        return type.replace(/\b\w/g, (char: string) => char.toUpperCase());
      });
      if (formattedTypes && formattedTypes.length > 0) {
        setFilterByCustomer(sessionStorage.getItem('userType') === 'kam' ? formattedTypes[0] : formattedTypes.length > 1 ? "All" : formattedTypes[0])
        setFilterByCustomerNewDealer(sessionStorage.getItem('userType') === 'kam' ? formattedTypes[0] : formattedTypes.length > 1 ? "All" : formattedTypes[0]);
        setCustomerTypeAccess(formattedTypes);
      }
      if ((customerTypeAccess.includes("government") || customerTypeAccess.includes("fleet"))) {
        if (customerTypeAccess.includes("government")) {
          sessionStorage.setItem('customerTypeAccess', 'government');
          setCustomerAcceessForFleetGovt(true);
        } else {
          sessionStorage.setItem('customerTypeAccess', 'fleet');
          setCustomerAcceessForFleetGovt(true);
        }
      } else {
        setCustomerAcceessForFleetGovt(false);
      }
      if (customerTypeAccess.includes("workshop")) {
        sessionStorage.setItem('customerTypeAccess', 'workshop');
        setCustomerAcceessForWorkshop(true);
      } else {
        setCustomerAcceessForWorkshop(false);
      }
      if (customerTypeAccess.includes("tpcdealer")) {
        sessionStorage.setItem('customerTypeAccess', 'tpcdealer');
        setCustomerAcceessForWorkshop(true);
      } else {
        setCustomerAcceessForWorkshop(false);
      }
      if (customerTypeAccess.includes("TBL Dealer")) {
        sessionStorage.setItem('customerTypeAccess', 'TBL Dealer');
        setCustomerAcceessForTBLDealer(true);
      } else {
        setCustomerAcceessForTBLDealer(false);
      }
      if (customerTypeAccess.includes("intbusiness")) {
        sessionStorage.setItem('customerTypeAccess', 'intbusiness');
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess])

  useEffect(() => {
    if (sessionStorage.getItem('userType') === 'kam') {
      if (customerAcceess && customerAcceess.length > 0) {
        setFilterByCustomer(customerAcceess[0]);
        setFilterByCustomerNewDealer(customerAcceess[0]);
        handlePageReloadOnRerouting()
      }
    } else {
      handlePageReloadOnRerouting()
    }
  }, [location.pathname, customerAcceess])

  const handlePageReloadOnRerouting = async () => {
    if (sessionStorage.getItem('userType') !== 'FSE') {
      if (selectedTab === 0) {
        if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
          dispatch(getAllDealers(kamUserId, '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer ? filterByCustomer : 'All'));
        else
          dispatch(getAllDealers("", '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer ? filterByCustomer : 'All'));
      } else {
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer ? filterByCustomerNewDealer : 'All', '', sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe', filterByStatusNewDealer, page));
        // dispatch(getCustomerFilterStatus());
      }
    }
  }

  const loadCustomerData = async () => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      let data: any = await dispatch(getFSECustomerTypes());
      if (data && data.data && data.data.customerType && data.data.customerType.length > 0) {
        setFSECustomerTypes(data.data.customerType);
        setFilterByCustomer(data.data.customerType.length > 1 ? "All" : data.data.customerType[0]);
        setFilterByCustomerNewDealer(data.data.customerType.length > 1 ? "All" : data.data.customerType[0]);
        let fseKamsData: any = {}
        if (kamFseUserList && kamFseUserList.data && kamFseUserList.data.data && kamFseUserList.data.data.length > 0) {
        } else {
          fseKamsData = await dispatch(getFSEKAMUserList());
        }
        if (data.data.customerType && data.data.customerType.includes('Int. Business') && selectedTab === 0) {
          if (fseSalesRegionList && fseSalesRegionList.data && fseSalesRegionList.data.data && fseSalesRegionList.data.data.length > 0) {
          } else {
            await dispatch(getFSESalesRegions());
          }
        }
        if ((fseKamsData && fseKamsData.data) || (kamFseUserList && kamFseUserList.data)) {
          if (selectedTab === 0) {
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', data.data.customerType.includes('tpcdealer') && data.data.customerType.length > 1 ? "All" : data.data.customerType[0]));
          }
          if (selectedTab === 1) {
            dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, '', sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe', filterByStatusNewDealer, page));
          }
        }
      }
    } else if (sessionStorage.getItem('userType') === 'kam') {
      if ((customerTypeAccess.includes("intbusiness") || sessionStorage.getItem('customerTypeAccess') === "intbusiness") && selectedTab === 0) {
        if (fseSalesRegionList && fseSalesRegionList.data && fseSalesRegionList.data.data && fseSalesRegionList.data.data.length > 0) {
        } else {
          await dispatch(getFSESalesRegions());
        }
      }
      if (selectedTab === 0) {
        if (kamUserListForKam && kamUserListForKam.data && kamUserListForKam.data.data && kamUserListForKam.data.data.length > 0) {
        } else {
          await dispatch(getKAMUserListForKAM());
        }
      }
      // if (selectedTab === 1) {
      //   dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, '', sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe', filterByStatusNewDealer, page));
      // }
    } else {
      // dispatch(getAllDealers(kamUserId, filterByCustomer));
      // dispatch(getAllDealersRequests(webApplicationLoginId, "All",
      //   searchValue, 'all', filterByStatusNewDealer));
      if (kamUserList && kamUserList.data && kamUserList.data.data && kamUserList.data.data.length > 0) {
      } else {
        dispatch(getkamUserList());
      }
      if (selectedTab === 1) {
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, '', sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe', filterByStatusNewDealer, page));
      }
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('userType') === 'kam') {
      if (customerAcceess && customerAcceess.length > 0) {
        loadCustomerData();
      }
    } else {
      if (customerAcceess && customerAcceess.length > 0) {
        loadCustomerData();
      }
    }
  }, [customerAcceess]);

  // useEffect(() => {
  //   if (changeCustomerTypeData && changeCustomerTypeData.data && changeCustomerTypeData.data.message && changeCustomerTypeData.data.message.status === 'S') {
  //     addDealerOpen(true);
  //   }
  // }, [changeCustomerTypeData])

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseAdddealer = (open?: any) => {
    setOpenAddDealer(open ? true : false);
  };

  const addDealerOpen = (open: any) => {
    setOpenCustomerDialog(true)
    setOpenNewAddDealerPopup(true)
  }

  const ITEM_HEIGHT = 48;

  const filterDealersByKAM = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (e.target.value === "0") {
      setKamUserId(e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllDealers(e.target.value.trim(), filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), 0));
      else
        dispatch(getAllDealers(e.target.value.trim(), filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer));

    }
    else {
      setKamUserId(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllDealers(searchFilter.trim(), filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, "", 0));
        else
          dispatch(getAllDealers(searchFilter.trim(), filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), 0));
      }
    }
  }

  const filterDealersByFseKAM = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (e.target.value === "0") {
      setKamUserId(e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllFSEDealers(e.target.value.trim(), filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), 0));
      else
        dispatch(getAllFSEDealers(e.target.value.trim(), filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer));
    }
    else {
      setKamUserId(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllFSEDealers(searchFilter.trim(), filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, "", 0));
        else
          dispatch(getAllFSEDealers(searchFilter.trim(), filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), 0));
      }
    }
  }

  const filterDealersByFseSalesRegion = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (e.target.value === "0") {
      setFilterByFseSalesRegion('');
      if (searchValue.trim() !== "")
        dispatch(getAllFSEDealers(kamUserId, '', filterByCustomer, searchValue.trim(), 0));
      else
        dispatch(getAllFSEDealers(kamUserId, '', filterByCustomer));
    }
    else {
      setFilterByFseSalesRegion(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllFSEDealers(kamUserId, searchFilter.trim(), filterByCustomer, "", 0));
        else
          dispatch(getAllFSEDealers(kamUserId, searchFilter.trim(), filterByCustomer, searchValue.trim(), 0));
      }
    }
  }

  const filterDealersByKamSalesRegion = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (e.target.value === "0") {
      setFilterByKamSalesRegion('');
      if (searchValue.trim() !== "")
        dispatch(getAllDealers(kamUserId, '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), 0));
      else
        dispatch(getAllDealers(kamUserId, '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer));
    }
    else {
      setFilterByKamSalesRegion(e.target.value);
      let searchFilter = e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "")
          dispatch(getAllDealers(kamUserId, searchFilter.trim(), filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, "", 0));
        else
          dispatch(getAllDealers(kamUserId, searchFilter.trim(), filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), 0));
      }
    }
  }

  const handleFilterByCustomer = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (kamUserId === "0") {
      setFilterByCustomer(e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value);
      let searchFilter = e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value ? e.target.value : "";
      if (searchValue.trim() !== "")
        dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', searchFilter.trim(), searchValue.trim(), 0));
      else
        dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', searchFilter.trim()));
    }
    else {
      setFilterByCustomer(e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value);
      let searchFilter = e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "") {
          dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', searchFilter.trim(), "", 0));
        } else {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0") {
            dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', searchFilter.trim(), searchValue.trim(), 0));
          } else
            dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', searchFilter.trim(), searchValue.trim(), 0));
        }
      }
    }
  }

  const handleFilterByCustomerFse = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    if (kamUserId === "0") {
      setFilterByCustomer(e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value);
      if (searchValue.trim() !== "")
        dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', e.target.value.trim(), searchValue.trim(), 0));
      else
        dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', e.target.value.trim()));
    }
    else {
      setFilterByCustomer(e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value);
      let searchFilter = e.target.value === "TPC Dealer" ? 'tpcdealer' : e.target.value ? e.target.value : "";
      if (e.target.value && e.target.value.trim() !== "") {
        if (searchValue.trim() === "") {
          dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', searchFilter.trim(), "", 0));
        } else {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0") {
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', searchFilter.trim(), searchValue.trim(), 0));
          } else
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', searchFilter.trim(), searchValue.trim(), 0));
        }
      }
    }
  }

  const handleFilterByNewCustomer = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    setFilterByCustomerNewDealer(e.target.value);
    let searchFilter = e.target.value ? e.target.value : "";
    if (e.target.value && e.target.value.trim() !== "") {
      if (searchValue.trim() === "")
        dispatch(getAllDealersRequests(webApplicationLoginId, searchFilter, "", filterValueForNewDealerReq, filterByStatusNewDealer, 0));
      else
        dispatch(getAllDealersRequests(webApplicationLoginId, searchFilter, searchValue, filterValueForNewDealerReq, filterByStatusNewDealer, 0));
    }

  }

  // FILTER FOR NEW DEALER REQUEST - FOR KAM
  const filterNewDealerReq = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    setFilterValueForNewDealerReq(e.target.value);
    let searchFilter = e.target.value ? e.target.value : "";
    if (e.target.value && e.target.value.trim() !== "") {
      if (searchValue.trim() === "")
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, "", searchFilter, filterByStatusNewDealer, 0));
      else
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, searchValue, searchFilter, filterByStatusNewDealer, 0));
    }
  }

  //  FILTER FOR NEW DEALER REQUEST - FOR CUSTOMER STATUS
  const filterForCustomerStatus = (e: any) => {
    setPage(0);
    setOpenInfo(-1);
    setFilterByStatusNewDealer(e.target.value);

    let searchFilter = e.target.value ? e.target.value : "";
    if (e.target.value && e.target.value.trim() !== "") {
      if (searchValue.trim() === "") {
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, "", sessionStorage.getItem('userType') !== 'FSE' ? "ALL" : 'addedByMe', e.target.value, 0));
        // dispatch(getCustomerFilterStatus());
      } else {
        // dispatch(getCustomerFilterStatus());
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, searchValue, searchFilter, e.target.value, 0));
      }
    }
  }

  const searchDealerList = (e: any, isSearch: any) => {
    if (selectedTab === 0) {
      if (sessionStorage.getItem('userType') === 'FSE') {
        if (searchValue.trim() === "") {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
            dispatch(getAllFSEDealers(kamUserId.trim(), filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, "", isSearch ? 0 : page));
          else
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer));
        } else {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
          else
            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
        }
      } else {
        if (searchValue.trim() === "") {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
            dispatch(getAllDealers(kamUserId.trim(), filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, "", isSearch ? 0 : page));
          else
            dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer));
        } else {
          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
            dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
          else
            dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), isSearch ? 0 : page));
        }
      }
    } else {
      dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, searchValue.trim(), filterValueForNewDealerReq, filterByStatusNewDealer, 0));
    }
  }

  const handleChangePage = (newPage: number) => {
    setOpenInfo(-1);
    if (userType === 'FSE') {
      if (selectedTab == 0 && kamUserId.trim() !== "0") {
        if (searchValue.trim() === "" && filterValue.trim() === "") {
          dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, "", newPage));
        }
        else if (searchValue.trim() === "" && filterValue.trim() !== "") {
          dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, "", newPage));
        }
        else {
          dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), newPage));
        }
      } else if (selectedTab == 0 && kamUserId.trim() === "0") {
        dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer, searchValue.trim(), newPage));
      } else {
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, searchValue.trim(), filterValueForNewDealerReq, filterByStatusNewDealer, newPage));
      }
    } else {
      if (selectedTab == 0 && kamUserId.trim() !== "0") {
        if (searchValue.trim() === "" && filterValue.trim() === "") {
          dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, "", newPage));
        }
        else if (searchValue.trim() === "" && filterValue.trim() !== "") {
          dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, "", newPage));
        }
        else {
          dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), newPage));
        }
      } else if (selectedTab == 0 && kamUserId.trim() === "0") {
        dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer, searchValue.trim(), newPage));
      } else {
        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, searchValue.trim(), filterValueForNewDealerReq, filterByStatusNewDealer, newPage));
      }
    }

    setPage(newPage);
  };

  const handleClickOpenPublishRevisedTandC = () => {
    setOpenPublishRevisedTandCDialog(true);
  };

  const handleClosePublishRevisedTandC = () => {
    setOpenPublishRevisedTandCDialog(false);
  };

  const id = open ? "simple-popover" : undefined

  return (
    <Fragment>
      {loading && (<FullscreenLoader />)}
      {allDealers && allDealers.loading && <FullscreenLoader />}
      {dealerRequests && dealerRequests.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Customers</h2>
        <div className="ml-auto">
          {sessionStorage.getItem('userType') === 'kam' ?
            <Button className="text-info mr-0"
              startIcon={<PostAddIcon fontSize="inherit" />}
              onClick={() => {
                handleClickOpenPublishRevisedTandC();
              }}><h6 className="info-sub-header-tandc mt-1"> Publish T&C </h6></Button>
            : ""}
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <Grid container className="">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text="Dealer added successfully!" />
        <AddDealerDialog
          open={openAddDealer}
          isEdit={isDialogForEdit}
          handleopenClose={(data: any) => {
            if (data) {
              handleopenCloseSnackbar(true)
            }
            handleopenCloseAdddealer(false)
          }}
        />
        {openDealerDetailDialog &&
          <DealerDetailsDialog selectedTabValue={dealerDetailTab} open={openDealerDetailDialog} onClose={(value: boolean) => setOpenDealerDetailDialog(value)} />
        }
        {/* <Grid item xs={12} sm={4}> */}
        <div className="card col-12">
          <div className="row col-sm-4 col-lg-4">
            <ThemeProvider theme={theme}>
              <Paper square className="bg-transparent">
                <Tabs
                  className="tabSelect"
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={async (event, newValue) => {
                    setPage(0);
                    setOpenInfo(-1);
                    setselectedTab(newValue);
                    setSearchValue('');
                    if (sessionStorage.getItem('userType') === 'FSE') {
                      let data: any = await dispatch(getFSECustomerTypes());
                      if (data && data.data && data.data.customerType && data.data.customerType.length > 0) {
                        setFSECustomerTypes(data.data.customerType);
                        setFilterByCustomer(data.data.customerType.length > 1 ? "All" : data.data.customerType[0]);
                        setFilterByCustomerNewDealer(data.data.customerType.length > 1 ? "All" : data.data.customerType[0]);
                        // dispatch(getAllDealers(kamUserId, data.data.customerType[0]));
                      }
                    } else {
                      setFilterByCustomer(sessionStorage.getItem('userType') === 'kam' ? customerAcceess && customerAcceess.length > 0 ? customerAcceess[0] : customerTypeAccess && customerTypeAccess.length > 0 ? customerTypeAccess[0] : 'All' : 'All')
                      setFilterByCustomerNewDealer(sessionStorage.getItem('userType') === 'kam' ? customerAcceess && customerAcceess.length > 0 ? customerAcceess[0] : customerTypeAccess && customerTypeAccess.length > 0 ? customerTypeAccess[0] : 'All' : 'All')
                      // setFilterByCustomerNewDealer("All")
                      setFilterByStatusNewDealer("ALL")
                    }
                    if (newValue === 0) {
                      if (sessionStorage.getItem('userType') === 'FSE') {
                        if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                          dispatch(getAllFSEDealers(kamUserId, '', filterByCustomer ? filterByCustomer : 'All'));
                        else
                          dispatch(getAllFSEDealers(kamUserId, '', filterByCustomer ? filterByCustomer : 'All'));
                      } else {
                        if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                          dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer ? filterByCustomer : 'All'));
                        else
                          dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer ? filterByCustomer : 'All'));
                      }
                    } else {
                      dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer ? filterByCustomerNewDealer : 'All', '', sessionStorage.getItem('userType') !== 'FSE' ? "all" : 'addedByMe', filterByStatusNewDealer, page));
                      if (getCustomerFilterStatusData && getCustomerFilterStatusData.data &&
                        getCustomerFilterStatusData.data.data && getCustomerFilterStatusData.data.data.data &&
                        getCustomerFilterStatusData.data.data.data.length > 0) {
                      } else {
                        dispatch(getCustomerFilterStatus());
                      }
                    }
                  }}
                  aria-label="disabled tabs example">
                  <Tab label="Customer List" value={0} {...a11yProps('DealerList')} />
                  {/* {userType === "FSE" && (customerAcceessForIB || customerAcceessForFleetGovt) ? */}
                  {/* {userType === "FSE" && (customerAcceessForIB) ?
                    <Tab label="New Customer Req." value={1} {...a11yProps('NewDealerReq.')} />
                    : ""} */}
                  {userType === "FSE" && (customerAcceessForIB === false && customerAcceessForFleetGovt === false && customerAcceessForWorkshop == false && customerAcceessForTBLDealer === false) ? "" :
                    <Tab label="New Customer Req." value={1} {...a11yProps('NewDealerReq.')} />
                  }
                </Tabs>
              </Paper>
            </ThemeProvider>
          </div>
        </div>
        <div className="card col-12">
          <div className="card d-flex flex-row-reverse bd-highlight card-height-kam-filters mt-4 col-sm-12 col-lg-12">
            <div className="row">
              {selectedTab === 0 && sessionStorage.getItem('userType') !== 'FSE' &&
                <>
                  {sessionStorage.getItem('userType') !== 'kam' && (
                    <TextField
                      id="kamList"
                      select
                      label="Filter By CustomerType"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { handleFilterByCustomer(e) }}
                      value={filterByCustomer}
                    >
                      <MenuItem value={"All"}>
                        All
                      </MenuItem>
                      <MenuItem value={"Dealer"}>
                        Dealer
                      </MenuItem>\ <MenuItem value={"Workshop"}>
                        Workshop
                      </MenuItem>\<MenuItem value={"tpcdealer"}>
                        TPC Dealer
                      </MenuItem>\ <MenuItem value={"Government"}>
                        Government
                      </MenuItem>\ <MenuItem value={"Fleet"}>
                        Fleet
                      </MenuItem>\ <MenuItem value={"Int. Business"}>
                        Int. Business
                      </MenuItem>
                    </TextField>
                  )}
                  {sessionStorage.getItem('userType') === 'kam' && (
                    <>
                      <TextField
                        id="kamList"
                        select
                        label="Filter By CustomerType"
                        variant="outlined"
                        className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                        size="small"
                        onChange={(e: any) => { handleFilterByCustomer(e) }}
                        value={filterByCustomer}
                      >
                        {customerAcceess && customerAcceess.length > 0 && customerAcceess.map((type: any) => (
                          <MenuItem value={type === 'TPC Dealer' ? 'tpcdealer' : type}>
                            {type}
                          </MenuItem>
                        ))}
                        {/* <MenuItem value={"Dealer"}>
                          Dealer
                        </MenuItem>\ <MenuItem value={"Workshop"}>
                          Workshop
                        </MenuItem>\ <MenuItem value={"Government"}>
                          Government
                        </MenuItem>\ <MenuItem value={"Fleet"}>
                          Fleet
                        </MenuItem>\ <MenuItem value={"Int. Business"}>
                          Int. Business
                        </MenuItem> */}
                      </TextField>
                      <TextField
                        id="kamList"
                        select
                        label="Filter By KAM"
                        variant="outlined"
                        className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                        size="small"
                        onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterDealersByKAM(e); setFilterValue('') }}
                        value={kamUserId}
                      >
                        {kamUserListForKam && kamUserListForKam.data && kamUserListForKam.data.data && kamUserListForKam.data.data.length > 0 && kamUserListForKam.data.data.map((item: any, index: any) => (
                          <MenuItem key={index} value={item.webApplicationLoginId}>
                            {item.name}
                          </MenuItem>
                        ))}
                        {kamUserListForKam && kamUserListForKam.data && kamUserListForKam.data.data && kamUserListForKam.data.data.length >= 1 && (
                          <MenuItem value={"0"}>
                            Show All
                          </MenuItem>
                        )}
                      </TextField>
                    </>
                  )}
                  {userType !== 'FSE' && sessionStorage.getItem('userType') !== 'kam' && (
                    <TextField
                      id="kamList"
                      select
                      label="Filter By KAM"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterDealersByKAM(e); setFilterValue('') }}
                      value={kamUserId}
                    >
                      {kamUserList && kamUserList.data && kamUserList.data.data && kamUserList.data.data.length > 0 && kamUserList.data.data.map((item: any, index: any) => (
                        <MenuItem key={index} value={item.webApplicationLoginId}>
                          {item.displayName}
                        </MenuItem>
                      ))}
                      {kamUserList && kamUserList.data && kamUserList.data.data && kamUserList.data.data.length >= 1 && (
                        <MenuItem value={"0"}>
                          Show All
                        </MenuItem>
                      )}
                    </TextField>
                  )}
                  {sessionStorage.getItem('userType') === 'kam' && customerAcceessForIB && (
                    <TextField
                      id="kamList"
                      select
                      label="Filter By IB Sales Region"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-150p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => {
                        if (e.target.value === '0') {
                          setFilterByKamSalesRegion('')
                        }
                        setPage(0);
                        setOpenInfo(-1);
                        setFilterValue('')
                        filterDealersByKamSalesRegion(e);
                      }}
                      value={filterByKamSalesRegion}
                    >
                      {fseSalesRegionList && fseSalesRegionList.data && fseSalesRegionList.data.data && fseSalesRegionList.data.data.length > 0 && fseSalesRegionList.data.data.filter((ele: any) => ele !== "NOT APPLICABLE").map((item: any, index: any) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                      <MenuItem value={"0"}>
                        Clear Filter
                      </MenuItem>
                    </TextField>
                  )}
                </>}
              {selectedTab === 0 && sessionStorage.getItem('userType') === 'FSE' &&
                <>
                  <TextField
                    id="kamList"
                    select
                    label="Filter By CustomerType"
                    variant="outlined"
                    className="rounded-input-dealer bd-highlight mw-150p mr-2 mb-2"
                    size="small"
                    onChange={(e: any) => { handleFilterByCustomerFse(e) }}
                    value={filterByCustomer}
                  >
                    {FSECustomerList && FSECustomerList.length > 0 && FSECustomerList.map((value: any) => (
                      <MenuItem value={value === 'TPC Dealer' ? "tpcdealer" : value}>
                        {value === "tpcdealer" ? 'TPC Dealer' : value}
                      </MenuItem>
                    ))}
                    {FSECustomerList && FSECustomerList.length > 1 ?
                      <MenuItem value={"All"}> All </MenuItem> : null}
                  </TextField>
                  <TextField
                    id="kamList"
                    select
                    label="Filter By KAM"
                    variant="outlined"
                    className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                    size="small"
                    onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterDealersByFseKAM(e); setFilterValue('') }}
                    value={kamUserId}
                  >
                    {kamFseUserList && kamFseUserList.data && kamFseUserList.data.data && kamFseUserList.data.data.length > 0 && kamFseUserList.data.data.map((item: any, index: any) => (
                      <MenuItem key={index} value={item.webApplicationLoginId}>
                        {item.name}
                      </MenuItem>
                    ))}
                    <MenuItem value={"0"}>
                      Show All
                    </MenuItem>
                  </TextField>
                  {customerAcceessForIB && (
                    <TextField
                      id="kamList"
                      select
                      label="Filter By IB Sales Region"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-150p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => {
                        if (e.target.value === '0') {
                          setFilterByFseSalesRegion('')
                        }
                        setPage(0);
                        setOpenInfo(-1);
                        setFilterValue('')
                        filterDealersByFseSalesRegion(e);
                      }}
                      value={filterByFseSalesRegion}
                    >
                      {fseSalesRegionList && fseSalesRegionList.data && fseSalesRegionList.data.data && fseSalesRegionList.data.data.length > 0 && fseSalesRegionList.data.data.filter((ele: any) => ele !== "NOT APPLICABLE").map((item: any, index: any) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                      <MenuItem value={"0"}>
                        Clear Filter
                      </MenuItem>
                    </TextField>
                  )}
                </>}
              {selectedTab === 1 &&
                <div className="">
                  {sessionStorage.getItem('userType') === 'FSE' && (
                    <TextField
                      id="filterCustomer"
                      select
                      label="Filter By CustomerType"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setFilterByCustomerNewDealer(e.target.value); handleFilterByNewCustomer(e) }}
                      value={filterByCustomerNewDealer}
                    >
                      {FSECustomerList && FSECustomerList.length > 0 && FSECustomerList.map((value: any) => (
                        <MenuItem value={value === 'TPC Dealer' ? "tpcdealer" : value}>
                          {value === "tpcdealer" ? 'TPC Dealer' : value}
                        </MenuItem>
                      ))}
                      {FSECustomerList && FSECustomerList.length > 1 ?
                        <MenuItem value={"All"}> All </MenuItem> : null}
                    </TextField>
                  )}
                  {sessionStorage.getItem('userType') !== 'FSE' && sessionStorage.getItem('userType') !== 'kam' && (
                    <TextField
                      id="filterCustomer"
                      select
                      label="Filter By CustomerType"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setFilterByCustomerNewDealer(e.target.value); handleFilterByNewCustomer(e) }}
                      value={filterByCustomerNewDealer}
                    >
                      <MenuItem value={"All"}>
                        All
                      </MenuItem>
                      <MenuItem value={"Dealer"}>
                        Dealer
                      </MenuItem>\ <MenuItem value={"Workshop"}>
                        Workshop
                      </MenuItem>\ <MenuItem value={"Government"}>
                        Government
                      </MenuItem>\ <MenuItem value={"Fleet"}>
                        Fleet
                      </MenuItem>\ <MenuItem value={"Int. Business"}>
                        Int. Business
                      </MenuItem>
                    </TextField>
                  )}
                  {sessionStorage.getItem('userType') === 'kam' && (
                    <TextField
                      id="kamList"
                      select
                      label="Filter By CustomerType"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setFilterByCustomerNewDealer(e.target.value); handleFilterByNewCustomer(e) }}
                      value={filterByCustomerNewDealer}
                    >
                      {/* {customerAcceess && customerAcceess.length > 0 && customerAcceess.map((type: any) => (
                        <MenuItem value={type}>
                          {type}
                        </MenuItem> */}
                      {customerAcceess && customerAcceess.length > 0 && customerAcceess.map((type: any) => (
                        <MenuItem value={type}>
                          {type}
                        </MenuItem>
                      ))}
                      {/* <MenuItem value={"Dealer"}>
                          Dealer
                        </MenuItem>\ <MenuItem value={"Workshop"}>
                          Workshop
                        </MenuItem>\ <MenuItem value={"Government"}>
                          Government
                        </MenuItem>\ <MenuItem value={"Fleet"}>
                          Fleet
                        </MenuItem>\ <MenuItem value={"Int. Business"}>
                          Int. Business
                        </MenuItem> */}
                    </TextField>
                  )}
                  {userType !== 'FSE' && (
                    <TextField
                      id="filterValueForNewDealerReq"
                      select
                      disabled={sessionStorage.getItem('userType') === 'FSE'}
                      label="Filter By KAM"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterNewDealerReq(e); }}
                      value={filterValueForNewDealerReq}
                    >
                      {selectedStatusFilterByKAM && selectedStatusFilterByKAM && selectedStatusFilterByKAM.length > 0 && selectedStatusFilterByKAM.map((name: any) => (
                        <MenuItem key={name.code} value={name.code}>{name.name} </MenuItem>))}
                    </TextField>
                  )}
                  {userType === 'FSE' && (
                    <TextField
                      id="filterValueForNewDealerReq"
                      select
                      // disabled={sessionStorage.getItem('userType') === 'FSE'}
                      label="Requested By"
                      variant="outlined"
                      className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                      size="small"
                      onChange={(e: any) => { setPage(0); setOpenInfo(-1); filterNewDealerReq(e); }}
                      value={filterValueForNewDealerReq}
                    >
                      <MenuItem value={"all"}>
                        All
                      </MenuItem>
                      <MenuItem value={"addedByMe"}>
                        Added By Me
                      </MenuItem>
                    </TextField>
                  )}
                  <TextField
                    id="filterByStatus"
                    select
                    label="Filter By Status"
                    variant="outlined"
                    className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                    size="small"
                    onChange={(e: any) => {
                      // setFilterByStatusNewDealer(e.target.value);
                      filterForCustomerStatus(e);
                    }}
                    value={filterByStatusNewDealer}
                  >
                    {getCustomerFilterStatusData && getCustomerFilterStatusData.data &&
                      getCustomerFilterStatusData.data.data && getCustomerFilterStatusData.data.data.data &&
                      getCustomerFilterStatusData.data.data.data.length > 0 &&
                      getCustomerFilterStatusData.data.data.data.map((item: any) => (
                        <MenuItem key={item.code} value={item.code}>{item.title} </MenuItem>))}
                  </TextField>
                </div>}
              <FormControl variant="outlined">
                <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search</InputLabel>
                <OutlinedInput
                  id="dealer-search"
                  value={searchValue}
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      searchDealerList(searchValue, true);
                      setPage(0);
                      setOpenInfo(-1);
                    }
                  }}
                  onChange={(e: any) => {
                    setSearchValue(e.target.value);
                    setOpenInfo(-1);
                    if (e.target.value.trim() === "") {
                      if (selectedTab === 0) {
                        if (userType === "FSE") {
                          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer));
                          else
                            dispatch(getAllFSEDealers(kamUserId, filterByFseSalesRegion ? filterByFseSalesRegion : '', filterByCustomer));
                        } else {
                          if (kamUserId.trim() !== "" && kamUserId.trim() !== "0")
                            dispatch(getAllDealers(kamUserId, filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer));
                          else
                            dispatch(getAllDealers("", filterByKamSalesRegion ? filterByKamSalesRegion : '', filterByCustomer.toLowerCase() === 'tpcdealer' ? 'tpcdealer' : filterByCustomer));
                        }
                      } else {
                        dispatch(getAllDealersRequests(webApplicationLoginId, filterByCustomerNewDealer, e.target.value, filterValueForNewDealerReq, filterByStatusNewDealer, page));
                      }
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Search"
                        edge="end"
                        disabled={searchValue === "" || searchValue === null || !searchValue}
                        onClick={(e: any) => {
                          searchDealerList(e, true);
                          setPage(0);
                          setOpenInfo(-1);
                        }}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={50} margin="dense" className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2" />
              </FormControl>
              {userType === "FSE" && (customerAcceessForIB || customerAcceessForFleetGovt || customerAcceessForWorkshop || customerAcceessForTBLDealer) ?
                <div className="">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="rounded-button-dealer bd-highlight"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      addDealerOpen(true);
                      dispatch(getCustomerType());
                    }}
                  >
                    Add Customer
                  </Button>
                </div>
                : ""
              }
              {userType !== "FSE" ?
                <div className="">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="rounded-button-dealer bd-highlight"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      addDealerOpen(true);
                      dispatch(getCustomerType());
                    }}
                  >
                    Add Customer
                  </Button>
                </div> : ""}
            </div>
          </div>
        </div>
        {selectedTab == 0 ? <Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500 mb-5 pb-5">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Customer</div>
                    <div className="dealer-table-title-desc">Logo, Name &amp; Trade License No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Assigned</div>
                    <div className="dealer-table-title-desc">KAM &amp; FSE</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">CustomerType</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">SAPCustomerId</div>
                  </TableCell>
                  {/* <TableCell>
                    <div className="dealer-table-title mb-3">Type</div>
                  </TableCell> */}
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length > 0 &&
                  allDealers.data.data.data.customerList.map((item: any, index: any) =>
                    <React.Fragment>
                      <TableRow key={item.sapCustomerId}>
                        <TableCell component="th" scope="row" className="mw-200p">
                          <Grid container>
                            <div className="d-flex">
                              {/* <Grid item xs={3}> */}
                              <div style={{ display: "inline" }}>
                                <img
                                  src={item.logo ? item.logo : defaultLogo}
                                  alt="Logo Client"
                                  className="dealer-grid-logo"
                                /></div>
                              <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                                <div className="dealer-table-content dealer-company-name cursor-pointer"
                                  onClick={async () => {
                                    sessionStorage.setItem('userCurrentStatus', item.status);
                                    dispatch(selectedNewDealer(item));
                                    sessionStorage.setItem('assignFse', item.assignFse);
                                    sessionStorage.setItem('isAddedByFse', item.isAddedByFse ? 'FSE' : 'KAM');
                                    dispatch(isEdit(false));
                                    history.push('/vieweditdealerdetails');
                                    sessionStorage.setItem('isStatusPlusComing', !isEmpty(item && item.statusPlus && item.statusPlus) ? 'true' : 'false');
                                  }}>{item.companyTitle} {item.companyName}</div>
                                <div className="dealer-table-subcontent">Trade License No. {item.tradeLicenseNo}</div>
                              </div>
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.addresses ? item.addresses : "-"}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                          <div className="dealer-table-subcontent">{item.contactDetails.email ? item.contactDetails.email : "-"}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          <div className="dealer-table-content">{item.assignKam ? item.assignKam : "-"}</div>
                          <div className="dealer-table-subcontent">{item.assignFse}{item.assignFse ? " (FSE)" : '-'}</div>
                        </TableCell>
                        <TableCell className="mw-150p">
                          {/* <div className="dealer-table-content">{item.creditCurrencyType + " " + item.overallCreditLimit}</div> */}
                          <div className="dealer-table-subcontent">
                            {item.customerTierType === 'Workshop' || item.customerTierType === 'tpcdealer' ? (
                              <>
                                {item.selectedBrand &&
                                  item.selectedBrand.map((value: any) => getNewBrand(value && value.make)).join(", ")
                                }
                              </>) : (<>
                                {
                                  item.organizationIds &&
                                  item.organizationIds
                                    .filter((value: any) => value.isActive !== false && value.organizationId !== '2003')
                                    .map((value: any) => (value.make === 'OES' ? 'ALAC' : getNewBrand(value.make)))
                                    .join(", ")
                                }
                              </>)}
                            {/* {item.organizationIds &&
                            item.organizationIds.map((value: any) => value.isActive === false ? '' : getNewBrand(value && value.make)).join(", ")
                          } */}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-content">{item.customerTierType === 'tpcdealer' ? 'TPC Dealer' : item.customerTierType ? item.customerTierType : "-"}</div>
                        </TableCell>
                        <TableCell>
                          <div className="dealer-table-content">{item.sapCustomerId ? item.sapCustomerId : "-"}</div>
                        </TableCell>
                        {/* <TableCell>
                        <div className={item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{item.status ? item.status === "Deactive" ? "Inactive" :  item.status : "-"}</div>
                      </TableCell> */}
                        {item.status && item.status !== "Active" && item && item.statusPlus && item.statusPlus.length > 0 ?
                          <TableCell className="mw-150p">
                            <div style={{ display: "flex" }} className={getIsSevenDaysDate(item && item.tandCDate) === true ? "dealer-table-content text-red" : !isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : getIsSevenDaysDate(item && item.tandCDate) === true ? "T&C issue" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}
                              <ErrorIcon className="cursor-pointer bottomFieldNameTable ml-2 mb-2 mt-0 float-right" fontSize="small"
                                onClick={async () => {
                                  setLoading(true);
                                  await dispatch(getInactiveStatusDetails(item.sapCustomerId));
                                  setOpenInfo(open === index + 1 ? -1 : index + 1)
                                  setLoading(false);
                                }} /></div>
                            {/* {item && item.statusPlus && item.statusPlus.map((ele: any) => {
                              return <>
                                <div className="dealer-table-subcontent dealer-table-content text-red">({ele ? ele : ""})</div>
                              </>
                            })} */}
                            <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                            <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                          </TableCell>
                          :
                          item.status && item.status === "Active" && item && item.statusPlus && item.statusPlus.length > 0 ?
                            <TableCell className="mw-150p">
                              <div style={{ display: "flex" }} className={getIsSevenDaysDate(item && item.tandCDate) === true ? "dealer-table-content text-red" : !isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : getIsSevenDaysDate(item && item.tandCDate) === true ? "T&C issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}
                                <ErrorIcon className="cursor-pointer bottomFieldNameTable ml-2 mb-2 mt-0 float-right" fontSize="small"
                                  onClick={async () => {
                                    setLoading(true);
                                    await dispatch(getInactiveStatusDetails(item.sapCustomerId));
                                    setOpenInfo(open === index + 1 ? -1 : index + 1)
                                    setLoading(false);
                                  }} /></div>

                              {/* <div className={getIsSevenDaysDate(item && item.tandCDate) === true ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{getIsSevenDaysDate(item && item.tandCDate) === true ? "T&C issue" : item.status ? item.status : "-"}</div> */}
                              {/* T&C status display */}


                              {/* {item && item.statusPlus && item.statusPlus.map((ele: any) => {
                            return <>
                              <div className="dealer-table-subcontent dealer-table-content text-red">({ele ? ele : ""})</div>
                            </>
                          })} */}
                              <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                              <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                            </TableCell>
                            :
                            <TableCell className="mw-150p">
                              <div className={!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "dealer-table-content text-red" : item.status && item.status === "Active" ? "dealer-table-content status-color" : "dealer-table-content text-red"}>{!isEmpty(item && item.statusPlus && item.statusPlus) && item.status && item.status === "Active" ? "Inactive" : item.status ? item.status === "Document expiry issue" ? "Document Expiry Issue" : item.status === "Deactive" ? "Inactive" : item.status : "-"}</div>
                              {/* {item && item.statusPlus && item.statusPlus.map((ele: any) => {
                              return <>
                                <div className="dealer-table-subcontent dealer-table-content text-red">({ele ? ele : ""})</div>
                              </>
                            })} */}
                              <div className="dealer-table-subcontent dealer-table-content text-red">{!isEmpty(item && item.statusPlus && item.statusPlus) ? `(${item && item.statusPlus && item.statusPlus.toString().split(",").join(', ')})` : ""}</div>
                              {isEmpty(item && item.statusPlus && item.statusPlus) &&
                                <div className="dealer-table-subcontent dealer-table-content text-red">{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? "Till :" : ""}{" "}{item.status !== "Deactive" && item.docExpiryAutoDeactivateUntil && item.docExpiryAutoDeactivate ? item.docExpiryAutoDeactivateUntil : ""}</div>
                              }
                            </TableCell>}
                        <TableCell className="mw-230p">
                          <div style={{ background: "white", }}>
                            {<IconButton aria-describedby={id} onClick={(event: any) => {
                              handleClick(event);
                              setItem(item);
                              sessionStorage.setItem('userCurrentStatus', item.status);
                              sessionStorage.setItem('statusPlus', item && item.statusPlus);
                              sessionStorage.setItem('assignFse', item.assignFse);
                              sessionStorage.setItem('isAddedByFse', item.isAddedByFse ? 'FSE' : 'KAM');
                              sessionStorage.setItem('itemCustomerTierType', item.customerTierType);
                              sessionStorage.setItem('isStatusPlusComing', !isEmpty(item && item.statusPlus && item.statusPlus) ? 'true' : 'false');
                              setCustomerTier(item.customerTierType && item.customerTierType)
                            }} className={classes.actionButton} size="small">
                              <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                            </IconButton>}
                          </div>
                        </TableCell>
                      </TableRow>
                      {!isEmpty(getInactiveStatusaApiResponse) ?
                        <TableRow className={classes.collapseBg}>
                          <TableCell className="p-0 w-100" colSpan={12}>
                            <Collapse in={openInfo > 0 && openInfo === index + 1 ? true : false}>
                              <Box>
                                <Table aria-label="table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={`${classes.subTableHead} px-0 ml-0`}>
                                        <span className="mx-2 ml-3"></span>
                                        Date </TableCell>
                                      <TableCell className={classes.subTableHead}>Time</TableCell>
                                      <TableCell className={classes.subTableHead}>Inactive Reason</TableCell>
                                      <TableCell className={classes.subTableHead}></TableCell>
                                      <TableCell className={classes.subTableHead}>Status</TableCell>
                                      <TableCell className={classes.subTableHead}></TableCell>
                                    </TableRow>
                                  </TableHead>
                                  {!isEmpty(getInactiveStatusaApiResponse && getInactiveStatusaApiResponse.data) && getInactiveStatusaApiResponse && getInactiveStatusaApiResponse.data.map((subEnquiry: any) => {
                                    return <>
                                      <TableRow>
                                        {/* <TableCell className={`${classes.subTableBodyContent} px-4`}>{moment(subEnquiry && subEnquiry.date).tz('Asia/Dubai').format('YYYY-MM-DD')}</TableCell> */}
                                        <TableCell className={`${classes.subTableBodyContent} px-4`}>{subEnquiry && subEnquiry.date}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>
                                          {moment.utc(subEnquiry && subEnquiry.time, 'HH:mm:ss').tz('Asia/Dubai').format('HH:mm:ss')}
                                        </TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{subEnquiry && subEnquiry.reason}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{ }</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{subEnquiry && subEnquiry.status}</TableCell>
                                        <TableCell className={classes.subTableBodyContent}>{ }</TableCell>
                                      </TableRow>
                                    </>
                                  })}
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell >
                        </TableRow>
                        :
                        <>
                          {isEmpty(getInactiveStatusaApiResponse) &&
                            <TableRow className={classes.collapseBg}>
                              <TableCell className="p-0 w-100" colSpan={12}>
                                <Collapse in={openInfo > 0 && openInfo === index + 1 ? true : false}>
                                  <Box>
                                    <Table aria-label="table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.subTableHead}>
                                            <div className="justify-content-center mx-auto text-center d-flex">No Record Found.</div>
                                          </TableCell>
                                        </TableRow>
                                      </TableHead>
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          }</>}
                    </React.Fragment >
                  )}
              </TableBody>
            </Table>
            {allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.customerList && allDealers.data.data.data.customerList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={allDealers && allDealers.data && allDealers.data.data && allDealers.data.data.data && allDealers.data.data.data.totaCount ? allDealers.data.data.data.totaCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
          </TableContainer>

          {
            openNewAddDealerPopup &&
            <AddNewDealer
              setOpenCustomerDialog={setOpenCustomerDialog}
              openCustomerDialog={openCustomerDialog}
              verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md" />
          }
        </Grid> : <Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Customer</div>
                    <div className="dealer-table-title-desc">Name &amp; Trade License No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Owner</div>
                    <div className="dealer-table-title-desc">Name &amp; Mobile No.</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                    <div className="dealer-table-title-desc"></div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">CustomerType</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">SAPCustomerId</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">RequestedBy</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">CreatedDate</div>
                  </TableCell>
                  {/* <TableCell>
                    <div className="dealer-table-title mb-3">Type</div>
                  </TableCell> */}
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.customerList && dealerRequests.data.data.data.customerList.length > 0 &&
                  dealerRequests.data.data.data.customerList.map((item: any) =>
                    <TableRow key={item.sapCustomerId}>
                      <TableCell component="th" scope="row" className="mw-200p">
                        <Grid container>
                          <div className="d-flex">
                            <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                              <div className="dealer-table-content dealer-company-name"
                                onClick={async () => {
                                  // await dispatch(getDealerDetails(item.sapCustomerId))
                                  // // if(webApplicationLoginId){
                                  // //   await dispatch(getCreditLimit(item.sapCustomerId, webApplicationLoginId));
                                  // // }
                                  // setDealerDetailTab(0);
                                  // setOpenDealerDetailDialog(true);
                                }}>{item.companyTitle} {item.companyName}</div>
                              <div className="dealer-table-subcontent">Trade License No. {item.treadLicenseNumber
                              }</div>
                            </div>
                          </div>
                        </Grid>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.addresses.streetName}{item.addresses.streetName ? ", " : ""}{item.addresses.poBox}{item.addresses.poBox ? ", " : ""}{item.addresses.city}{item.addresses.city ? ", " : ""}{item.addresses.region}{item.addresses.region ? ", " : ""}{item.addresses.country} </div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item && item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                        <div className="dealer-table-subcontent">{item && item.contactDetails ? item.contactDetails.email : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-content">{item.ownerDetails.name ? item.ownerDetails.name : "-"}</div>
                        <div className="dealer-table-subcontent">{item.ownerDetails.mobileNumber ? item.ownerDetails.mobileNumber : "-"}</div>
                      </TableCell>
                      <TableCell className="mw-150p">
                        <div className="dealer-table-subcontent">
                          {
                            (item.customerTierType === 'Workshop' || item.customerTierType === 'tpcdealer') ?
                              item.selectedBrand &&
                              item.selectedBrand.map((value: any) => getNewBrand(value && value.make)).join(", ") : item.organizationIds &&
                              item.organizationIds.map((value: any) => (value.isActive === false || value.organizationId === '2003') ? '' : value && value.make === 'OES' ? 'ALAC' : getNewBrand(value && value.make)).join(", ")
                          }
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.customerTierType === 'tpcdealer' ? 'TPC Dealer' : item.customerTierType ? item.customerTierType : "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.sapCustomerId} </div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.requestedByRole ? item.requestedByRole : item.requestedBy ? item.requestedBy : "-"}</div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{moment(item.requestSubmitted ? item.requestSubmitted : "").format('DD MMM YYYY')}</div>
                      </TableCell>
                      <TableCell>
                        <div className="dealer-table-content">{item.status}</div>
                      </TableCell>
                      <TableCell className="mw-230p">
                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                          <Button
                            className="text-info btn-link" startIcon={<Visibility />}
                            onClick={() => {
                              dispatch(selectedNewDealer(item));
                              sessionStorage.setItem('status', item.status);
                              history.push('/dealerrequestdetails');
                              sessionStorage.setItem('RequestedBy', item.requestedByRole ? item.requestedByRole : item.requestedBy ? item.requestedBy : "-")
                            }}>View Customer</Button>
                        </ButtonGroup>
                      </TableCell>
                    </TableRow>)}
              </TableBody>
            </Table>
            {dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.customerList && dealerRequests.data.data.data.customerList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={dealerRequests && dealerRequests.data && dealerRequests.data.data && dealerRequests.data.data.data && dealerRequests.data.data.data.totalCount ? dealerRequests.data.data.data.totalCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
            {
              openNewAddDealerPopup &&
              <AddNewDealer
                setOpenCustomerDialog={setOpenCustomerDialog}
                openCustomerDialog={openCustomerDialog}
                verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth="md" />
            }
          </TableContainer>
        </Grid>}
      </Grid>

      {/* <AddNewDealer
        setOpenCustomerDialog={setOpenCustomerDialog}
        openCustomerDialog={openCustomerDialog}
        verifySuccess={verifySuccess} aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md" /> */}
      <Dialog className="pb-0"
        open={openPublishRevisedTandCDialog}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClosePublishRevisedTandC()
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
        disableEscapeKeyDown>
        <PublishRevisedTandCDialog
          setOpenDialog={handleClosePublishRevisedTandC}
        />
      </Dialog>
      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <RetrieveBrandsDialog setOpenDialog={handleClose} />
      </Dialog>
      <Menu
        id={id}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        className="ml-3 mt-5 "
      >
        {userType !== 'FSE' && (
          <MenuItem className=""><Button className="text-info  mr-4" startIcon={<EditIcon />}
            onClick={() => {
              dispatch(selectedNewDealer(item));
              dispatch(isEdit(true));
              history.push('/vieweditdealerdetails');
              sessionStorage.setItem('assignFse', item.assignFse);
              sessionStorage.setItem('statusPlus', item && item.statusPlus);
              sessionStorage.setItem('isAddedByFse', item && item.isAddedByFse ? 'FSE' : 'KAM');
              sessionStorage.setItem('isStatusPlusComing', !isEmpty(item && item.statusPlus && item.statusPlus) ? 'true' : 'false');
            }}><div className="font-dropdown ">Edit</div></Button></MenuItem>
        )}

        <MenuItem> <Button className="text-info"
          startIcon={<GroupIcon />}
          onClick={() => {
            history.push('/dealerusers');
            // dispatch(getAllUsers(item.sapCustomerId));
            dispatch(getDealerDetails(item.sapCustomerId, 'dealerDetails'))
          }}>Users</Button></MenuItem>
        {customerTier === "Dealer" && userType !== 'FSE' && <> <MenuItem>  <Button className="text-info  mr-4"
          startIcon={<AttachMoneyIcon />}
          onClick={() => {
            dispatch(getDealerCreditRequest(webApplicationLoginId, item.sapCustomerId, 'kam'));
            history.push('/viewcreditkam');
          }}><span className="ml-n2">ViewCredit</span></Button></MenuItem></>}
        {customerTier === "Dealer" && <><MenuItem> <Button className="text-info  mr-3"
          startIcon={<img src="./settargetinfo.svg" />}
          onClick={() => {
            history.push('/viewtarget');
            dispatch(viewCreditDealer(item.sapCustomerId, new Date().getFullYear()));
            dispatch(selectedNewDealer(item));
            dispatch(getCustomerDealerDetails(item.sapCustomerId))
          }}><span>View Target</span></Button>
        </MenuItem></>}
        {customerTier === "Dealer" && <MenuItem className="mt-0"> <Button className="text-info  mr-3"
          startIcon={<EmojiEventsOutlined fontSize="inherit" />}
          onClick={() => {
            history.push("/achievementskam")
            // dispatch(getPurChaseHistoryByYearKam("",webApplicationLoginId,item.sapCustomerId,"2022")) 
            dispatch(selectedNewDealer(item));
          }}><span>Achievements</span></Button>
        </MenuItem>}
        {userType !== 'FSE' && (
          <MenuItem style={{ pointerEvents: 'none' }} className="mb-0"> <Button className="cursor-remove text-info mr-4" disabled
            startIcon={<AutorenewIcon />}
            onClick={() => { handleClickOpen(); }}><span className="">RetrieveBrands</span></Button>
          </MenuItem>
        )}
      </Menu>
    </Fragment >
  );
};

export default Dealers;
