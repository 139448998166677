import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const achievementService = {
    getPurChaseHistoryData,
    isAccesstoAchievement,
    getPurChaseHistoryYearData,

}



async function getPurChaseHistoryData(b2b: string, webid: string, sapid: string, year: string) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/purchase?year=${year}&b2bUserId=${b2b}&loginId=${webid}&sapCustomerId=${sapid}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function isAccesstoAchievement(b2b: string, sapid: string) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/achievementModule/${b2b}/sapCustomerId/${sapid}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getPurChaseHistoryYearData(b2b: string, webid: string, sapid: string, year: string, dealer: any) {
    let apiPath;
    if (dealer === "dealer" || dealer === "tpcdealer") {
        apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/purchase?year=${year}&b2bUserId=${b2b}&loginId=${webid}&sapCustomerId=${sapid}&source=${dealer}`;
    } else if (dealer === "kam") {
        apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/purchase?year=${year}&loginId=${webid}&sapCustomerId=${sapid}&source=kam`
    } else if (dealer === "superadmin") {
        apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/dealer/purchase?year=${year}&loginId=${webid}&sapCustomerId=${sapid}&source=superadmin`
    }
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

