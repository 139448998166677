import React, { Dispatch, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core';
import ViewFavoriteInfo from './viewFavoriteInfo';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';
import { getUniqueEnquirySessionId } from '../../store/actions/createUpdateEnquiry.actions';
import { getUniqueTimestamp } from '../../common/components/util';
import { useDispatch } from 'react-redux';

export default function ViewFavorite(props: any) {
  const dispatch: Dispatch<any> = useDispatch();

  const useStyles = makeStyles((theme) => ({
    addOrderEnqButton: {
      backgroundColor: '#1A9FE0',
      width: 190,
      fontWeight: 800,
      textTransform: 'inherit',
      fontFamily: 'Nunito',
      fontSize: '0.688rem',
      color: '#FFFFFF',
      borderRadius: 1000,
      height: 35,
      textAlign: 'center',
      marginRight: 8
    },
    closeButton: {
      backgroundColor: '#EAEAEA',
      width: 140,
      fontWeight: 800,
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
      fontSize: '0.688rem',
      color: '#666666',
      borderRadius: 1000,
      height: 35,
      textAlign: 'center',
      marginRight: 24
    },
  }));

  const classes = useStyles();
  const history = useHistory();
  const [products, setSelectedProducts] = useState([]);
  const [error, setErrorMessage] = useState<any>(null);

  const selectedProducts = (product: any) => {
    setSelectedProducts(product);
  }

  useEffect(() => {
    if (products && products.length > 0) setErrorMessage(null);
  }, [products])

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (
    <Dialog
      open={props.open}
      onClose={() => { props.handleopenClose(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="sm"
    >
      <ViewFavoriteInfo selectedProducts={selectedProducts} />
      <div className="mx-auto text-center text-danger mt-2">{error}</div>
      <DialogActions className="mt-3 mb-2 justify-content-end">
        {showCreateChangeEnquiry &&
        <Button variant="contained"
          color="secondary"
          className="createFavourite mr-2" onClick={() => {
            if (products && products.length > 0) {
              dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
              sessionStorage.setItem('partsData', JSON.stringify([...products]));
              sessionStorage.setItem('isImportFromOES', 'false');
              history.push('/createenquiry');
            } else {
              setErrorMessage('Please select atleast one part');
            }
          }}>
          Add to Order Enquiry
        </Button>
        }
        <Button variant="contained" className={classes.closeButton} onClick={() => { props.handleopenClose(false); setErrorMessage(null); }}>Close</Button> 
        </DialogActions>
    </Dialog>
  );
}
