import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(() => ({
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    yes: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#1A9FE0',
        textTransform: 'capitalize'
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    deleteText: {
        color: '#133F8A',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    },
    dialogCustomWidth: {
        width: '600px !important',
        maxWidth: '600px !important',
        margin: '0 auto !important',
    }
}));

export default function DeleteDialogProductTeamPortal(props: any) {
    const classes = useStyles();

    const dialogClass = props.open ? classes.dialogCustomWidth : '';

    return (
        <div className="float-left">
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenCloseDeleteDialog(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                className={dialogClass}  // Apply the custom width here
            >
                <DialogContent>
                    <div className={classes.icon}>
                        <WarningIcon className="delete-warning" fontSize="small" />
                    </div>
                    <h4 className="page-title"> Confirm Delete </h4>
                    <div>
                        <DialogContentText id="alert-dialog-description">
                            <div className={`mt-3 ${classes.deleteText}`}>
                                {props.text}
                            </div>
                        </DialogContentText>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            props.handleopenCloseDeleteDialog('delete');
                        }}
                    >
                        Confirm
                    </Button>
                    <Button
                        className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn"
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            props.handleopenCloseDeleteDialog(false);
                        }}
                        autoFocus
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
