/* eslint-disable  */
import React, { Fragment, Dispatch, useEffect } from "react";
import "./OrderDetails.css";
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Popover from '@material-ui/core/Popover';
import { getOrderHistoryDetails, getPaymentTransactionDetails } from "../../store/actions/orderHistory.actions";
import moment from "moment-timezone";
import XLSX from 'xlsx'
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { getNewBrand, getNewlyAddedBrand } from "../../common/components/util";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import _, { add, get } from "lodash";
import { getSellingPrice } from "../../helpers/commonmethod";
import MakePaymentDialog from "../CreateEnquiry/MakePaymentDialog";
import MakePaymentViewOrder from "./MakePaymentViewOrder";
import Dialog from '@material-ui/core/Dialog';
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import { Tooltip, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import UploadLpoViewOrder from "./UploadLpoViewOrder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import MenuItem from '@material-ui/core/MenuItem';
import ViewQuotation from "../Quotation/ViewQuotation";
import OrderHistoryViewLPO from "../Quotation/OrderHistoryViewLPODialog";
// import ViewLPO from "../Quotation/ViewLpo"
import OrderHistoryViewQuotation from "../Quotation/OrderHistoryViewQuotation";
import { downloadQuotation, viewLPO } from "../../store/actions/quotation.action";
import { uploadLpo } from "../../services/quotation.service";
import ReportOrderDialog from "./ReportOrder";


const useStyles1 = makeStyles(() => ({
  header: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    minWidth: 100
  },
  brand: {
    color: '#444444',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  body: {
    color: '#666666',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  price: {
    color: '#E88B00',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  paymentStatus: {
    color: '#E88B00',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  credit: {
    backgroundColor: '#E88B00',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  backOrder: {
    backgroundColor: '#1A9FE0',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  orderCreated: {
    minWidth: "120px !important",
    border: "1px solid #4ec766",
    background: '#4ec766',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#4ec766',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  makePayment: {
    minWidth: "120px !important",
    border: "1px solid",
    background: '#1A9FE0',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    marginTop: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#1A9FE0',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    },
    "&:disabled": {
      background: '#8ED1FC',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  bodyColor: {
    color: '#0089ff',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  bodyColor1: {
    color: '#ff0000',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  bodyColor2: {
    color: '#008000',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  makePaymentCreditUtilized: {
    minWidth: "120px !important",
    border: "1px solid",
    background: '#696969',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#696969',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  orderFailed: {
    minWidth: "120px !important",
    border: "1px solid red",
    background: 'red',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: 'red',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  rowCustomSummary: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`order-tabpanel-${index}`}
      aria-labelledby={`order-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `order-tab-${index}`,
    'aria-controls': `order-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  container: {
    maxHeight: 500,
  },
  typography: {
    padding: theme.spacing(2),
    width: '300px'
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito'
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 12,
    minWidth: 70
  },
  tableHeadColor: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 5,
    minWidth: 30
  },
  quantity: {
    minWidth: 70
  },
  actionButton: {
    color: '#1A9FE0',
  },
  vat: {
    color: '#666666',
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
  },
}));
const columnsBack = [
  {
    id: 'productIdDescription',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'materialGroup',
    label: 'Brand',
    align: 'left',
  },
  {
    id: 'nowAvailability',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'backOrderPrice',
    label: 'Net Price',
    align: 'left'
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    id: 'deliveredQuantity',
    label: 'Delivered Qty.',
    align: 'left'
  },
  {
    id: 'deliveryStatus',
    label: 'Delivery Status',
    align: 'left'
  }
];

const columns = [
  {
    id: 'productIdDescription',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'materialGroup',
    label: 'Brand',
    align: 'left',
  },
  {
    id: 'nowAvailability',
    label: 'Now Qty',
    align: 'left'
  },
  {
    id: 'hrs24Availability',
    label: '24 Hrs Qty',
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'netValueOrderDocumentCurrency',
    label: 'Net Price',
    align: 'left'
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    id: 'deliveredQuantity',
    label: 'Delivered Qty.',
    align: 'left'
  },
  {
    id: 'deliveryStatus',
    label: 'Delivery Status',
    align: 'left'
  }
];

const columnsDealerUser = [
  {
    id: 'productIdDescription',
    label: 'Description',
    align: 'left',
  },
  {
    id: 'materialGroup',
    label: 'Brand',
    align: 'left',
  },
  {
    id: 'nowAvailability',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'quantity',
    label: 'Qty',
    align: 'left'
  },
  {
    id: 'confirmQuantity',
    label: 'Confirm Qty',
    align: 'left'
  },
  {
    id: 'netValueOrderDocumentCurrency',
    label: 'Net Price',
    align: 'left'
  },
  {
    id: 'currency',
    label: 'Currency',
    align: 'left'
  },
  {
    id: 'deliveredQuantity',
    label: 'Delivered Qty.',
    align: 'left'
  },
  {
    id: 'deliveryStatus',
    label: 'Delivery Status',
    align: 'left'
  }
];

const OrderDetails: React.FC = () => {
  const history = useHistory()
  const classes = useStyles();
  const classes1 = useStyles1()
  const dispatch: Dispatch<any> = useDispatch();
  const [orderData, setOrderData] = React.useState<any>(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [Order, setOrder] = React.useState<any>("");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const { orderId, orderDetails, transactionDetails }: any = useSelector((state: any) => state.orderHistory);
  const [tabValue, setTabValue] = React.useState(0);
  const [tabName, setTabName] = React.useState("");
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const { quotationDetails }: any = useSelector((state: any) => state.quotation);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  let customerAccountType = get(profileDetails, 'data.data.customerInfo.customerAccountType', '');
  const companyCurrencyType: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups);
  const [offlineData, setOfflineData] = React.useState<any>([])

  const [nowStatus, setNowStatus] = React.useState("");
  const [isHyperLink, setHyperLink] = React.useState(true);
  const [backStatus, setBackStatus] = React.useState("")
  const [isBackOrder, setIsBackOrder] = React.useState("false");
  const { getFSECustomerListData }: any = useSelector((state: any) => state.dealer);
  const { offlinePaymentUpdate }: any = useSelector((state: any) => state.orderSummary);
  let materialGroupsData = get(materialGroups, 'data.data', null);
  const sapCustomerId = get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const shipToPartyFeatureAccess = get(profileDetails, 'data.data.customerInfo.shipToPartyFeatureAccess', '');
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [nowOrderHide, setNowOrderHide] = React.useState<any>();
  const [boOrderHide, setBoOrderHide] = React.useState<any>();
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false)
  const [hidePaymentTStatus, setHidePaymentTStatus] = React.useState(true);
  const [quotationNumber, setQuotationNumber] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const currentTier = sessionStorage.getItem('currentTier');
  const [openViewQuotation, setOpenViewQuotation] = React.useState(false);
  const [viewQuotationUrl, setViewQuotationUrl] = React.useState("")
  const [openViewLPO, setOpenViewLPO] = React.useState(false);
  const [enquiryNumber, setEnquiryNumber] = React.useState("");
  const [brandId, setBrandId] = React.useState("");
  const [address, setAddress] = React.useState([]);
  const [billingAddress, setBillingAddress] = React.useState([])
  const [overallDeliveryStatus, setOverallDeliveryStatus] = React.useState<any>();
  const [lpoAttachments, setLpoAttachments] = React.useState<any>([])
  const [fileExtention, setFileExtension] = React.useState('pdf');
  // const [url, setUrl] = React.useState<any>("");
  const [openCloseSupportDialog, setOpenCloseSupportDialog] = React.useState(false);
  const [assignedKAMs, setAssignedKAMs] = React.useState<any>([]);
  const userType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType;

  let uploadedFromViewOrderLPO: any = sessionStorage.getItem("upload-lpoAttachments-view-order");
  let urlLink: any = sessionStorage.getItem("upload-lpoAttachments-url-view-order");

  let enquiryDataExisting: any = sessionStorage.getItem("lpoAttachments");
  if (enquiryDataExisting && JSON.parse(enquiryDataExisting)) {
    enquiryDataExisting = JSON.parse(enquiryDataExisting);
    if (uploadedFromViewOrderLPO === "true") {
      sessionStorage.setItem("lpoAttachments-url-view-order", urlLink);
    } else {
      sessionStorage.setItem("lpoAttachments-url-view-order", enquiryDataExisting && enquiryDataExisting.url);
      // setUrl(enquiryDataExisting && enquiryDataExisting.url);
    }
    // sessionStorage.setItem("lpoAttachments-url-view-order", enquiryDataExisting && enquiryDataExisting.url);
  }
  const customerID = sessionStorage.getItem('selectedCustomerID');

  const getCustomerTierType = (customerId: any) => {
    const customer = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.find((customer: any) => customer.sapCustomerId === customerId);
    return customer && customer.customerTierType ? customer.customerTierType : sessionStorage.getItem('customerTierType');
  };

  const [customerTierType, setCustomerTierType] = React.useState(sessionStorage.getItem('userType') === 'FSE' ? getCustomerTierType(customerID)
    : get(profileDetails, 'data.data.customerInfo.customerTierType', ''));

  const addresses: any = get(
    profileDetails,
    "data.data.customerInfo.addresses",
    []
  );
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');

  useEffect(() => {
    setAssignedKAMs(get(profileDetails, 'data.data.basicInfo.assignedKamAndFse', []));
  }, [profileDetails]);

  useEffect(() => {
    let shipToPartyNo: any;
    if (customerID && getFSECustomerListData && getFSECustomerListData.data && sessionStorage.getItem('userType') === 'FSE') {
      const tierType: any = getCustomerTierType(customerID);
      setCustomerTierType(tierType);
    }
    if (orderDetails && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails.data.backOrder[0].shiftToPartyCustomerNumber) {
      shipToPartyNo = orderDetails.data.backOrder[0].shiftToPartyCustomerNumber
    } else if (orderDetails && orderDetails.data && orderDetails.data.shiftToPartyCustomerNumber) {
      shipToPartyNo = orderDetails.data.shiftToPartyCustomerNumber
    }
    const billingAddress = addresses && addresses.length >= 1 && addresses.filter((ele: any) => {
      return (ele.dafault)
    })
    setBillingAddress(billingAddress);
    if (shipToPartyNo) {
      const shippingAddress = addresses && addresses.length >= 1 && addresses.filter((ele: any) => {
        return (shipToPartyNo == ele.shiftToPartyCustomerNumber)
      })
      if (shippingAddress && shippingAddress.length > 0) {
        setAddress(shippingAddress)
      } else {
        setAddress(billingAddress)
      }
    } else {
      setAddress(billingAddress)
    }
  }, [addresses, orderDetails, customerID, getFSECustomerListData])

  useEffect(() => {
    setEnquiryNumber(quotationDetails && quotationDetails.data && quotationDetails.data.b2bEnquiryId)
    setBrandId(quotationDetails && quotationDetails.data && quotationDetails.data.brandId)
  }, [quotationDetails]);

  const isShowSPR = () => {
    if ((currentTier && currentTier.toLowerCase() === 'gold') || (currentTier && currentTier.toLowerCase() === 'platinum') || (currentTier && currentTier.toLowerCase() === 'elite')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    // let checkDate = orderDetails && orderDetails.data && orderDetails.data.orderCreatedOn
    let nowOrderHideButton = orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatusCode
    let boOrderHideButton = orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatusCode

    setNowOrderHide(nowOrderHideButton)
    setBoOrderHide(boOrderHideButton);
    setOverallDeliveryStatus(orderDetails && orderDetails.data && orderDetails.data.overallDeliveryStatus);

    if ("OLDORDER" == nowOrderHideButton || "OLDORDER" == boOrderHideButton) {
      setHidePaymentTStatus(true)
    } else {
      setHidePaymentTStatus(false)
    }
  }, [orderDetails])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isHyperLink) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
    // if (orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length === 0) {
    //   if (orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus !== "Payment Due") {
    //     setAnchorEl(event.currentTarget);
    //   }
    // } else {
    //   if (orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus !== "Payment Due") {
    //     setAnchorEl(event.currentTarget);
    //   }
    // }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< EXISTING CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // const materialGroupsData = get(materialGroups, 'data.data', []);

  // let enquiryMapping: any = {};

  // materialGroupsData.forEach((group: any) => {
  //   return group.materialGroup.forEach((item: any) => {
  //     return enquiryMapping[item] = group.make;
  //   })
  // })

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NEW CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  useEffect(() => {
    const orderNumber = orderDetails && orderDetails.data && orderDetails.data.orderNumber && orderDetails.data.orderNumber;
    if (orderDetails && orderDetails.data && orderDetails.data.masterId) {
      setOfflineData(offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"])
      let backOrder: any = false;
      if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0 && tabValue == 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length == 0) {
        backOrder = false;
      } else if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length == 0 && tabValue == 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0) {
        backOrder = true;
      } else if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0) {
        if (tabValue == 0) {
          backOrder = false;
        } else {
          backOrder = true;
        }
      }

      const getPayment = async () => {
        let orderno: any;
        let data: any = await dispatch(getPaymentTransactionDetails(backOrder ? sessionStorage.getItem('orderBackNumber') ? sessionStorage.getItem('orderBackNumber') : orderDetails.data.backOrderNumber : orderDetails.data.orderNumber, orderDetails.data.masterId));
        orderno = backOrder ? orderDetails.data.backOrderNumber : orderDetails.data.orderNumber;
        setOrderNumber(orderno);
        setQuotationNumber(data && data.data && data.data.quotationNumber);
      }


      getPayment()

      let resNow = offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"].filter((ele: any) => {
        return ele.orderNumber === orderNumber
      })
      if (!backOrder) {
        if (resNow && resNow.length !== 0) {
          setNowStatus(resNow[0].paymentStatus);
          if (resNow[0].paymentStatus === 'Payment Due' || orderDetails.data.nowOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
            if (!resNow[0].paymentStatus) {
              if (orderDetails.data.nowOrderPaymentStatus === 'Payment Due' || orderDetails.data.nowOrderStatus === 'Fully Cancelled') {
                setHyperLink(false);
              } else {
                setHyperLink(true);
              }
            }
          }
        } else {
          if (orderDetails.data.nowOrderPaymentStatus === 'Payment Due' || orderDetails.data.nowOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        }
      }

      let resBack = offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"].filter((ele: any) => {
        return ele.orderNumber === sessionStorage.getItem('orderBackNumber')
      })

      if (backOrder) {
        if (resBack && resBack.length !== 0) {
          setBackStatus(resBack[0].paymentStatus)
          if (resBack[0].paymentStatus === 'Payment Due' || orderDetails.data.backOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
            if (!resBack[0].paymentStatus) {
              if (orderDetails.data.backOrderPaymentStatus === 'Payment Due' || orderDetails.data.backOrderStatus === 'Fully Cancelled') {
                setHyperLink(false);
              } else {
                setHyperLink(true);
              }
            }
          }
        } else {
          if (orderDetails.data.backOrderPaymentStatus === 'Payment Due' || orderDetails.data.backOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        }
      }
    }
  }, [offlinePaymentUpdate, openDialog, orderDetails])

  useEffect(() => {
    if (orderId) {
      let backOrder: any = sessionStorage.getItem('isBackOrder');
      setIsBackOrder(backOrder)
      const currentTabName = backOrder === "true" ? "Back Order" : "Now Order"
      setTabName(currentTabName)
      dispatch(getOrderHistoryDetails(orderId, backOrder));
      sessionStorage.removeItem('isBackOrder')
    }
  }, [orderId, dispatch]);

  let enquiryMappingReverse: any = {};
  let temp: any = []
  if (customerTierType && customerTierType?.toLowerCase() !== "workshop") {
    if (materialGroupsData !== null) {
      for (let item of materialGroupsData && materialGroupsData) {
        // if (item.make != "OES") {
        temp.push(item)
        // }
      }
    }
    // let removeOES = materialGroupsData && materialGroupsData.filter((ele: any) => {
    //   console.log("ELELELELELs", ele)
    //   return ele.make != "OES"
    // })
  } else if (customerTierType === "Workshop" && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.selectedBrand) {
    let selectedBrand = profileDetails?.data?.data?.customerInfo?.selectedBrand
    if (selectedBrand.length == 1 && selectedBrand[0].organizationId === "2195") {
      let brand = _.filter(materialGroupsData, (i) => {
        return i.orgId == "2195"
      });
      if (!_.isEmpty(brand)) {
        temp.push(brand[0])
      }
    } else if (selectedBrand.length > 1) {
      for (let item of selectedBrand) {
        let brand = _.filter(materialGroupsData, (i) => {
          return i.orgId == item.organizationId
        });
        if (!_.isEmpty(brand)) {
          temp.push(brand[0])
        }
      }
      let mergeMaterialGroup: any = [];
      for (let i of temp) {
        mergeMaterialGroup = mergeMaterialGroup.concat(i.materialGroup)
      }
      let finalMaterialGroup = [{
        displayName: "Tradepoint",
        make: "OES",
        materialGroup: mergeMaterialGroup,
        orgId: "2195"
      }]
      temp = finalMaterialGroup
    }
  }
  materialGroupsData = temp;
  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    enquiryMappingReverse[group.make] = group.materialGroup.toString();
  })

  let enquiryMapping: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group.make;
    })
  })
  const handleTabChange = async (event: any, newValue: number) => {
    setTabValue(newValue);
    setTabName(event.target.textContent)
    setOrderNumber(event.target.textContent === "Back Order" ? orderDetails && orderDetails.data && orderDetails.data.backOrderNumber : orderDetails && orderDetails.data && orderDetails.data.orderNumber)

    if (orderDetails && orderDetails.data) {
      await dispatch(getPaymentTransactionDetails(newValue === 1 ? sessionStorage.getItem('orderBackNumber') : orderDetails.data.orderNumber, orderDetails.data.masterId));
    }
    if (orderDetails && orderDetails.data && orderDetails.data.masterId) {
      let backOrder: any = false;
      if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0 && newValue == 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length == 0) {
        backOrder = false;
      } else if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length == 0 && newValue == 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0) {
        backOrder = true;
      } else if (orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0) {
        if (newValue == 0) {
          backOrder = false;
        } else {
          backOrder = true;
        }
      }
      if (!backOrder) {
        if (nowStatus) {
          if (nowStatus && nowStatus === 'Payment Due' || orderDetails.data.nowOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        } else if (newValue === 0 && !nowStatus) {
          if (orderDetails.data.nowOrderPaymentStatus === 'Payment Due' || orderDetails.data.nowOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        }
      }

      if (backOrder) {
        if (backStatus) {
          if (backStatus && backStatus === 'Payment Due' || orderDetails.data.backOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        } else if (!backStatus) {
          if (backOrder && orderDetails.data.backOrderPaymentStatus === 'Payment Due' || orderDetails.data.backOrderStatus === 'Fully Cancelled') {
            setHyperLink(false);
          } else {
            setHyperLink(true);
          }
        }
      }
      // if (newValue === 1) {
      //   if (backOrder && orderDetails.data.backOrderPaymentStatus === 'Payment Due') {
      //     setHyperLink(false);
      //   } else {
      //     setHyperLink(true);
      //   }
      // } else if (newValue === 0) {
      //   if (!backOrder && orderDetails.data.nowOrderPaymentStatus === 'Payment Due') {
      //     setHyperLink(false);
      //   } else {
      //     setHyperLink(true);
      //   }
      // }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleCloseViewQuotation = () => {
    setOpenViewQuotation(false);
    setViewQuotationUrl('');
  }

  const updateLPO = () => {
    setOpenUploadLpo(true);
  }

  const handleCloseViewLPO = () => {
    setOpenViewLPO(false);
    setViewQuotationUrl('');
  }

  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  const exportData = () => {
    if (tabValue === 0 && orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0) {
      let filename = 'orderexport.xlsx';
      let data: any = []
      orderDetails.data.products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && (customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer"))) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': item.nowAvailabilityChecked ? item.nowAvailability : 0,
          'Confirmed Quantity': item.confirmedQuantity,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.netValueOrderDocumentCurrency), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': Number(parseFloat(item.deliveredQuantity).toFixed(2)),
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.netPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "",
          'Orignal Part': item.supercessionMaterial
        })
        return undefined;
      })
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
    if ((tabValue === 1 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)
      || (tabValue === 0 && orderDetails && orderDetails.data && orderDetails.data.products && orderDetails.data.products.length === 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)) {
      let filename = 'backorderexport.xlsx';
      let data: any = []
      orderDetails.data.backOrder.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': Number(parseInt(item.quantity)),
          'Confirmed Quantity': item.confirmedQuantity,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.backOrderPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': item.deliveredQuantity ? Number(parseFloat(item.deliveredQuantity).toFixed(2)) : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nspev), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(3)) : ""
        })
        return undefined;
      })
      var ws1 = XLSX.utils.json_to_sheet(data);
      var wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, ws1, "sheet1");
      XLSX.writeFile(wb1, filename);
    }
  }

  const exportDataWorkshopUser = () => {
    if (tabValue === 0 && orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0) {
      let filename = 'orderexport.xlsx';
      let data: any = []
      orderDetails.data.products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Now Quantity': item.nowAvailabilityChecked ? item.nowAvailability : 0,
          '24 Hrs Quantity': item.hrs24Availability,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.netValueOrderDocumentCurrency), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': Number(parseFloat(item.deliveredQuantity).toFixed(2)),
          'STO ID': item.stoId ? item.stoId : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.netPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          // Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          // Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          // Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "",
          'Orignal Part': item.supercessionMaterial
        })
        return undefined;
      })
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
    if ((tabValue === 1 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)
      || (tabValue === 0 && orderDetails && orderDetails.data && orderDetails.data.products && orderDetails.data.products.length === 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)) {
      let filename = 'backorderexport.xlsx';
      let data: any = []
      orderDetails.data.backOrder.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': Number(parseInt(item.quantity)),
          'Confirmed Quantity': item.confirmedQuantity,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.backOrderPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': item.deliveredQuantity ? Number(parseFloat(item.deliveredQuantity).toFixed(2)) : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nspev), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(3)) : ""
        })
        return undefined;
      })
      var ws1 = XLSX.utils.json_to_sheet(data);
      var wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, ws1, "sheet1");
      XLSX.writeFile(wb1, filename);
    }
  }

  const exportDataFSETPCDealerUser = () => {
    if (tabValue === 0 && orderDetails.data.products && Array.isArray(orderDetails.data.products) && orderDetails.data.products.length > 0) {
      let filename = 'orderexport.xlsx';
      let data: any = []
      orderDetails.data.products.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Now Quantity': item.nowAvailabilityChecked ? item.nowAvailability : 0,
          '24 Hrs Quantity': item.hrs24Availability,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.netValueOrderDocumentCurrency), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': Number(parseFloat(item.deliveredQuantity).toFixed(2)),
          'STO ID': userType === "FSE" && customerTypeAccess.includes("tpcdealer") ? item.stoId : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.netPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          // Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          // Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          // Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "",
          'Orignal Part': item.supercessionMaterial
        })
        return undefined;
      })
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
    if ((tabValue === 1 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)
      || (tabValue === 0 && orderDetails && orderDetails.data && orderDetails.data.products && orderDetails.data.products.length === 0 && orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0)) {
      let filename = 'backorderexport.xlsx';
      let data: any = []
      orderDetails.data.backOrder.map((item: any) => {
        data.push({
          'Part No': item.productId,
          'Part Description': item.productIdDescription,
          Brand: (customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand')) : item && item.displayName === "Tradepoint" ? item.displayName : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] : '',
          'Quantity': Number(parseInt(item.quantity)),
          'Confirmed Quantity': item.confirmedQuantity,
          'Net Price': Number(parseFloat(getSellingPrice(Number(item.backOrderPrice), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Currency': sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? item.currency : customerTierType === "Int. Business" ? `${companyCurrencyType}` : item.currency,
          'Delivered Quantity': item.deliveredQuantity ? Number(parseFloat(item.deliveredQuantity).toFixed(2)) : "",
          'Delivery Status': item.deliveryStatus,
          'Net Value (Excl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nspev), isSellingPriceData, markupPercentage)).toFixed(2)),
          'Net Value (Incl. Vat)': Number(parseFloat(getSellingPrice(Number(item.nsp), isSellingPriceData, markupPercentage)).toFixed(2)),
          Width: item.dimensions.width ? Number(parseFloat(item.dimensions.width).toFixed(2)) : "",
          Height: item.dimensions.height ? Number(parseFloat(item.dimensions.height).toFixed(2)) : "",
          Length: item.dimensions.length ? Number(parseFloat(item.dimensions.length).toFixed(2)) : "",
          Weight: item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : "",
          Volume: item.dimensions.volume ? Number(parseFloat(item.dimensions.volume).toFixed(3)) : ""
        })
        return undefined;
      })
      var ws1 = XLSX.utils.json_to_sheet(data);
      var wb1 = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb1, ws1, "sheet1");
      XLSX.writeFile(wb1, filename);
    }
  }

  const showDownloadOrders = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ORDER);
  const showBrandName = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.IAM_MAPPING);
  const showListOrders = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_ORDERS);

  const handleUploadLpo = () => {
    setOpenUploadLpo(false);
    if (orderId) {
      dispatch(getOrderHistoryDetails(orderId, isBackOrder));
    }
    setOpenViewLPO(false);
  }

  const handleViewQuotation = async () => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId))
    if (data && data.error) {
      setSuccessMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
      setOpenViewQuotation(false)
    } else {
      // window.open(data && data.data)
      if (data) {
        setOpenViewQuotation(true)
        setViewQuotationUrl(data)
      }
    }
  }

  let url: any = sessionStorage.getItem("lpoAttachments-url-view-order")

  const handleViewLPO = async (orderNumber: any) => {
    if (tabName === "Now Order") {
      setOrderNumber(orderDetails && orderDetails.data && orderDetails.data.orderNumber)
    } else if (tabName === "Back Order") {
      setOrderNumber(orderDetails && orderDetails.data && orderDetails.data.backOrderNumber)
    }
    if (quotationNumber) {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sessionStorage.getItem('userType') === 'FSE' ? customerID : sapCustomerId, quotationNumber, ""))
      if (data && data.error) {
        setSuccessMessage("Unable to view LPO")
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
        // } else {
        //   // window.open(data && data.data)
        //   if (data) {
        //     setOpenViewLPO(true)
        //     setViewQuotationUrl(data)
        //   }
        // }
        sessionStorage.getItem("lpoAttachments")
      } else {
        if (url) {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
          setFileExtension(fileExtension.toLowerCase())
          if (fileExtension === 'pdf') {
            setViewQuotationUrl(data && typeof data === 'string' ? data : url);
            setOpenViewLPO(true);
            // fetch(url ? url : data).then(resp => resp.arrayBuffer()).then(resp => {
            //   const file = new Blob([resp], { type: 'application/pdf' });
            //   const fileURL = URL.createObjectURL(file);
            //   setViewQuotationUrl(fileURL)
            //   if (fileURL) {
            //     setOpenViewLPO(true)
            //   }
            // });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setViewQuotationUrl(url);
            if (url) {
              setOpenViewLPO(true)
            }
          }
        }
      }
    } else {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sessionStorage.getItem('userType') === 'FSE' ? customerID : sapCustomerId, "", orderNumber))
      if (data && data.error) {
        setSuccessMessage("Unable to view LPO")
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
        // } else {
        //   // window.open(data && data.data)
        //   if (data) {
        //     setOpenViewLPO(true)
        //     setViewQuotationUrl(data)
        //   }
        // }
      } else {
        // window.open(data && data.data)
        // if (data) {
        //   setOpenViewLPO(true)
        //   setViewQuotationUrl(data)
        // }
        if (url) {
          const fileName = url.substring(url.lastIndexOf('/') + 1);
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
          setFileExtension(fileExtension.toLowerCase())
          if (fileExtension === 'pdf') {
            setViewQuotationUrl(data && typeof data === 'string' ? data : url);
            setOpenViewLPO(true);
            // fetch(url ? url : data).then(resp => resp.arrayBuffer()).then(resp => {
            //   const file = new Blob([resp], { type: 'application/pdf' });
            //   const fileURL = URL.createObjectURL(file);
            //   setViewQuotationUrl(fileURL)
            //   if (fileURL) {
            //     setOpenViewLPO(true)
            //   }
            // });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setViewQuotationUrl(url || data);
            if (url) {
              setOpenViewLPO(true)
            }
          }
        }
      }
    }
  }

  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Order History
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      {orderDetails && orderDetails.loading && <FullscreenLoader />}
      {orderDetails && orderDetails.data && (
        <>
          <div className="row">
            <div className="col-12">
              <div className="d-flex align-items-center mb-2">
                <h5 className="col-8 page-sub-title mt-2">Order Details</h5>
                <div className="ml-auto legendItemCreateEnquiry m-2 mt-3">
                  {customerTierType && customerTierType !== "Workshop" && customerTierType && customerTierType !== "Int. Business" && customerTierType !== "Government" && customerTierType !== "Fleet" && isShowSPR() && currentTier !== "" && (
                    <>
                      <span className="specialprice"></span>
                      <span className="legendItemLabel mr-2 ml-2">Special Price</span>
                    </>
                  )}
                </div>
                {customerTierType && customerTierType !== "Workshop" && (
                  <span className="row mt-2">
                    <div className="ml-auto d-flex flex-row">
                      <div className="legendItemCreateEnquiry ml-3 green-legend-margin green-legend-margin">
                        <span className="dot mt-1"></span>
                      </div>
                    </div>
                    <span className="legendItemLabel mt-1 mr-2">Discounted Price</span>
                  </span>
                )}
                <div className="mt-2">
                  <IconButton className="ml-auto" onClick={() => {
                    history.push('/orderhistory')
                  }}>
                    <ChevronLeftIcon fontSize="small" />
                    <span className="backLinkLabel">Back to List</span>
                  </IconButton>
                </div>
              </div>
              <div className="cardCommon font-11">
                <div className="row col-12 m-0">
                  <div className="col-lg-4 col-md-4 col-sm-12 p-3 borderRight borderBottom">
                    <h6 className="title mb-3">Enquiry Details</h6>
                    <div className="row">
                      <div className="col-6 col-md-6 col-sm-6">
                        <div className="subTitle mb-2">Reference No:
                          <span className="subTitleValue">{orderDetails.data.masterReferenceNumber}</span>
                        </div>
                        <div className="subTitle mb-2">Enquiry No:
                          <span className="subTitleValue">{orderDetails.data.masterId}</span>
                        </div>
                        <div className="subTitle mb-2">Created On:
                          <span className="subTitleValue">{orderDetails.data.enquiryCreatedOn &&
                            //  moment(new Date(orderDetails.data.enquiryCreatedOn)).utc().format("DD MMM YYYY HH:mm")}
                            moment(orderDetails.data.enquiryCreatedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                          </span>
                        </div>
                        <div className="subTitle mb-2">Internal Number:
                          <span className="subTitleValue">{orderDetails.data.enquiryNumber}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 col-sm-6">
                        <div className="subTitle mb-2">Status:
                          <span className="subTitleValue">{orderDetails.data.enquiryStatus}</span>
                        </div>
                        <div className="subTitle mb-2">Created By:
                          <span className="subTitleValue">{orderDetails.data.createdBy} {" "} ({orderDetails.data.userRole})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-8 col-sm-12 p-3 borderBottom">
                    <h6 className="title mb-3">Order Details</h6>
                    <div className="row">
                      <div className="col-4 col-md-4 col-sm-4">
                        <div className="subTitle mb-2">Now Order No:
                          <span className="subTitleValue">{orderDetails.data.orderNumber}</span>
                        </div>
                        {orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0 && <div className="subTitle mb-2">Back Order No:
                          <span className="subTitleValue">{sessionStorage.getItem('orderBackNumber')}</span>
                        </div>}
                        <div className="subTitle mb-2">Created By:
                          <span className="subTitleValue">{orderDetails.data.createdBy}{" "} ({orderDetails.data.userRole})</span>
                        </div>
                        <div className="subTitle mb-2">Created On:
                          <span className="subTitleValue">{orderDetails.data.orderCreatedOn &&
                            //  moment(new Date(orderDetails.data.orderCreatedOn)).utc().format("DD MMM YYYY HH:mm")}
                            moment(orderDetails.data.orderCreatedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                          </span>
                        </div>
                        {orderDetails.data.nowOrderQuotationNumber ?
                          <div className="subTitle mb-2">Now Order Quotation No:
                            <span className="subTitleValue">{orderDetails.data.nowOrderQuotationNumber}</span>
                          </div>
                          : ""}
                        {/* {orderDetails.data.nowOrderLPONumber ?
                                <div className="subTitle mb-2">Now Order LPO No:
                           <span className="subTitleValue">{orderDetails.data.nowOrderLPONumber}</span>
                           </div>
                          : ""} */}
                      </div>
                      <div className="col-4 col-md-4 col-sm-4">
                        {/* <div className="subTitle mb-2">Order Status:
                          <span className="subTitleValue">{orderDetails.data.orderStatus}</span>
                      </div> */}
                        <div className="subTitle mb-2">Now Order Status:
                          <span className="subTitleValue">{orderDetails.data.nowOrderStatus}</span>
                        </div>
                        <div className="subTitle mb-2">Back Order Status:
                          <span className="subTitleValue">{orderDetails.data.backOrderStatus}</span>
                        </div>
                        <div className="subTitle mb-2">Back Order:
                          {orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && <span className="subTitleValue">
                            <Button variant="contained" className="subTitleButton" size="small">{orderDetails.data.backOrder.length > 0 ? 'YES' : 'NO'}</Button>
                          </span>}
                        </div>
                        <div className="subTitle mb-2">Brand:
                          <span className="subTitleValue">
                            {(customerTierType && customerTierType?.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop") || sessionStorage.getItem('customerTierType') === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? "Tradepoint" : getNewlyAddedBrand(sessionStorage.getItem('orderStoredBrand'))}
                          </span>
                        </div>
                      </div>
                      <div className="col-4 col-md-4 col-sm-4">
                        <div className="subTitle mb-2">Overall Delivery Status:
                          <span className="subTitleValue">{orderDetails.data.overallDeliveryStatus}</span>
                        </div>
                        <div className="subTitle mb-2">Overall Blocked Status:
                          <span className="subTitleValue">{orderDetails.data.overallBlockedStatus}</span>
                        </div>
                        {customerTierType !== "Workshop" && <div className="subTitle mb-2">Credit Status:
                          <span className="subTitleValue">{orderDetails.data.creditStatus}</span>
                        </div>}
                        {orderDetails.data.backOrderQuotationNumber ?
                          <div className="subTitle mb-2">Back Order Quotation No:
                            <span className="subTitleValue">{orderDetails.data.backOrderQuotationNumber}</span>
                          </div>
                          : ""}
                        {/* {orderDetails.data.backOrderLPONumber ?
                    <div className="subTitle mb-2">Back Order LPO No:
                        <span className="subTitleValue">{orderDetails.data.backOrderLPONumber}</span>
                       </div>
                        : ""} */}
                      </div>
                      {/* <div className="col-4 col-md-4 col-sm-4">
                  {orderDetails.data.backOrder && Array.isArray(orderDetails.data.backOrder) && orderDetails.data.backOrder.length > 0 && <div className="subTitle mb-2">Back Order No:
                    <span className="subTitleValue">{sessionStorage.getItem('orderBackNumber')}</span>
                  </div>}
                </div> */}
                    </div>
                    <div className="row">
                      <div className={(orderDetails.data.nowOrderLPONumber).length > 16 ? "col-6 col-md-6 col-sm-6" : "col-4 col-md-4 col-sm-4"}>
                        {orderDetails.data.nowOrderLPONumber ?
                          <div className="subTitle mb-2">Now Order LPO No:
                            <span className="subTitleValue">{orderDetails.data.nowOrderLPONumber}</span>
                          </div>
                          : ""}
                      </div>
                      <div className={(orderDetails.data.backOrderLPONumber).length > 16 ? "col-6 col-md-6 col-sm-6" : "col-4 col-md-4 col-sm-4"}>
                        {orderDetails.data.backOrderLPONumber ?
                          <div className="subTitle mb-2">Back Order LPO No:
                            <span className="subTitleValue">{orderDetails.data.backOrderLPONumber}</span>
                          </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="margin-top-horizontal" />
                <div className="col-12 m-0">
                  {address && address.length >= 1 && (
                    <>
                      <div className="row border-bottom border-top-0">
                        <h6 className="title mb-3 margin-shipping-address px-3">Shipping Address</h6>
                        {address && address.length >= 1 && address.map((ele: any) => {
                          return (
                            <div className="col-12 col-md-12 col-sm-12 border-bottom border-top-0 row m-0 pr-0 px-2">
                              <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column mb-3 px-4">
                                <div className="subTitle mb-2">{ele.companyName ? 'Company Name' : 'Location Name'}
                                </div>
                                <p className="subTitleValue">
                                  {ele.companyName ? ele.companyName : ele.location ? ele.location : '-'}
                                </p>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                                <div className="subTitle mb-2">Street, City & Country:
                                </div>
                                <span className="subTitleValue d-flex flex-column">
                                  {ele.streetName}{" "}{ele.city
                                  }{" "}{ele.country ? ele.country : "-"}

                                </span>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                                <div className="subTitle mb-2">Telephone Number:
                                </div>
                                <span className="subTitleValue">{ele.telephoneNumber ? ele.telephoneNumber : "-"
                                }</span>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                                <div className="subTitle mb-2">Po Box:
                                </div>
                                <span className="subTitleValue">{ele.poBox ? ele.poBox : "-"
                                }</span>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                                <div className="subTitle mb-2">Emirates/Region:
                                </div>
                                <span className="subTitleValue">{ele.region ? ele.region : "-"
                                }{" "}
                                  {ele.city ? ele.city : "-"
                                  }</span>
                              </div>
                              <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                                <div className="subTitle mb-2">Transportation Zone:
                                </div>
                                <span className="subTitleValue">{ele.transportationZone ? ele.transportationZone : "-"
                                }{" "}
                                </span>
                              </div>
                              <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column p-0">
                                <div className="subTitle mb-2">Shiptoparty no:
                                </div>
                                <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.userRole === 'FSE' && orderDetails.data.shiftToPartyCustomerNumber ? orderDetails.data.shiftToPartyCustomerNumber : ele.shiftToPartyCustomerNumber ? ele.shiftToPartyCustomerNumber : "-"
                                }{" "}
                                </span>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  )}
                  {sessionStorage.getItem('userType') === 'FSE' ?
                    <div>
                      <h6 className="title mb-3 margin-shipping-address mt-3 px-1">Billing Address</h6>
                      <div className="col-12 col-md-12 col-sm-12 row m-0 pr-0">
                        <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column mb-3 px-2">
                          <div className="subTitle mb-2">Company Name:
                          </div>
                          <p className="subTitleValue">
                            {orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.companyName ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.companyName : "-"}
                          </p>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                          <div className="subTitle mb-2">City & Country:
                          </div>
                          <span className="subTitleValue d-flex flex-column">
                            {orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.city
                            }{" "}{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.country ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.country : "-"}
                          </span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                          <div className="subTitle mb-2">Telephone Number:
                          </div>
                          <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.telephoneNumber ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.telephoneNumber : "-"
                          }</span>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                          <div className="subTitle mb-2">Po Box:
                          </div>
                          <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.poBox ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.poBox : "-"
                          }</span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                          <div className="subTitle mb-2">Emirates/Region:
                          </div>
                          <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.region ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.region : "-"
                          }{" "}
                            {orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.city ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.city : "-"
                            }</span>
                        </div>
                        <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                          <div className="subTitle mb-2">Transportation Zone:
                          </div>
                          <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.transportationZone ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.transportationZone : "-"
                          }{" "}
                          </span>
                        </div>
                        <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column p-0">
                          <div className="subTitle mb-2">Customer ID:
                          </div>
                          <span className="subTitleValue">{orderDetails && orderDetails.data && orderDetails.data.shiftToPartyAddressDetails && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress && orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.sapCustomerId ? orderDetails.data.shiftToPartyAddressDetails.headOfficeAddress.sapCustomerId : "-"
                          }{" "}
                          </span>
                        </div>
                      </div>
                    </div> :
                    <div>
                      <h6 className="title mb-3 margin-shipping-address mt-3 px-1">Billing Address</h6>
                      {billingAddress && billingAddress.length >= 1 && billingAddress.map((ele: any) => {
                        return (
                          <div className="col-12 col-md-12 col-sm-12 row m-0 pr-0">
                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column mb-3 px-2">
                              <div className="subTitle mb-2">Company Name:
                              </div>
                              <p className="subTitleValue">
                                {ele.companyName ? ele.companyName : "-"}
                              </p>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                              <div className="subTitle mb-2">City & Country:
                              </div>
                              <span className="subTitleValue d-flex flex-column">
                                {ele.city
                                }{" "}{ele.country ? ele.country : "-"}

                              </span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                              <div className="subTitle mb-2">Telephone Number:
                              </div>
                              <span className="subTitleValue">{ele.telephoneNumber ? ele.telephoneNumber : "-"
                              }</span>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column">
                              <div className="subTitle mb-2">Po Box:
                              </div>
                              <span className="subTitleValue">{ele.poBox ? ele.poBox : "-"
                              }</span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                              <div className="subTitle mb-2">Emirates/Region:
                              </div>
                              <span className="subTitleValue">{ele.region ? ele.region : "-"
                              }{" "}
                                {ele.city ? ele.city : "-"
                                }</span>
                            </div>
                            <div className="col-lg-2 col-md-2 col-sm-2 d-flex flex-column">
                              <div className="subTitle mb-2">Transportation Zone:
                              </div>
                              <span className="subTitleValue">{ele.transportationZone ? ele.transportationZone : "-"
                              }{" "}
                              </span>
                            </div>
                            <div className="col-lg-1 col-md-1 col-sm-1 d-flex flex-column p-0">
                              <div className="subTitle mb-2">Customer ID:
                              </div>
                              <span className="subTitleValue">{ele.shiftToPartyCustomerNumber ? ele.shiftToPartyCustomerNumber : "-"
                              }{" "}
                              </span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  }
                </div>
              </div>
            </div >
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12 ml-auto justifyContentHorizontalSpacebetween align-items-center">
              {/* <h5 className="page-sub-title">Order Items</h5> */}
              <div className="ml-auto row">
                {/* <div className="ml-auto legendItemCreateEnquiry m-2 mt-3">
                <span className="specialprice"></span>
                <span className="superseededdotLabel mt-2 mr-3 ml-2">Special Price part</span>
                </div> */}
                {((tabName === "Back Order" && orderDetails.data.backOrderLPONumber == "") || (tabName === "Now Order" && orderDetails.data.nowOrderLPONumber == ""))
                  // && (customerTierType?.toLowerCase() === "government" || customerTierType?.toLowerCase() === "fleet") 
                  && <Button variant="contained"
                    className={`${classes1.makePayment} upload-lpo mt-2`}
                    onClick={() => {
                      setOpenUploadLpo(true)
                      if (tabName === "Now Order") {
                        setOrderNumber(orderDetails && orderDetails.data && orderDetails.data.orderNumber)
                      } else if (tabName === "Back Order") {
                        setOrderNumber(orderDetails && orderDetails.data && orderDetails.data.backOrderNumber)
                      }
                    }
                    }
                    disabled={(orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails && orderDetails.data && orderDetails.data.backOrderStatus === "Fully delivered") || (orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails && orderDetails.data && orderDetails.data.backOrderStatus === "Partially Invoiced") || (orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails && orderDetails.data && orderDetails.data.backOrderStatus === "Fully Invoiced") || (orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails && orderDetails.data && orderDetails.data.backOrderStatus === "Partially delivered") || (orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && orderDetails && orderDetails.data && orderDetails.data.backOrderStatus === "Delivery Processing") ||
                      (orderDetails && orderDetails.data && orderDetails.data.nowOrderStatus === "Partially Invoiced") || (orderDetails && orderDetails.data && orderDetails.data.nowOrderStatus === "Fully delivered") || (orderDetails && orderDetails.data && orderDetails.data.nowOrderStatus === "Fully Invoiced") || (orderDetails && orderDetails.data && orderDetails.data.nowOrderStatus === "Partially delivered") || (orderDetails && orderDetails.data && orderDetails.data.nowOrderStatus === "Delivery Processing") ? true : false
                    }
                  >{"Upload LPO"}
                  </Button>}
                <span className="superseededdot mt-3 mr-2"></span>
                <span className="superseededdotLabel mt-3 mr-3">Alternate Part</span>
                {/* {quotationNumber && (customerTierType?.toLowerCase() === "government" || customerTierType?.toLowerCase() === "fleet") && <div className="exportExcel mt-3 mr-3" onClick={handleViewQuotation} style={{ cursor: 'pointer' }}> */}
                {quotationNumber
                  //  (customerTierType?.toLowerCase() === "government" || customerTierType?.toLowerCase() === "fleet") 
                  && <div className="exportExcel mt-3 mr-3" onClick={handleViewQuotation} style={{ cursor: 'pointer' }}>
                    <IconButton className="exportExcel" size="small">
                      <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                    View Quotation
                  </div>}
                {((tabName === "Back Order" && orderDetails.data.backOrderLPONumber != "") || (tabName === "Now Order" && orderDetails.data.nowOrderLPONumber != ""))
                  // && ((customerTierType?.toLowerCase() === "government" || customerTierType?.toLowerCase() === "fleet")) 
                  ?
                  <div className="exportExcel mt-3 mr-3" onClick={() => { handleViewLPO(orderNumber); }} style={{ cursor: 'pointer' }}>
                    <IconButton className="exportExcel" size="small">
                      <VisibilityIcon fontSize="inherit" />
                    </IconButton>
                    View LPO
                  </div>
                  : ""}
                {showDownloadOrders && (
                  <>
                    {customerTierType && (customerTierType === "Workshop") || userType === "FSE" && !customerTypeAccess.includes("tpcdealer") ?
                      <div className="exportExcel mt-3 mr-3" onClick={() => exportDataWorkshopUser()} style={{ cursor: 'pointer' }}>
                        <IconButton className="exportExcel" size="small">
                          <ArrowDownwardIcon fontSize="inherit" />
                        </IconButton>
                        Export to Excel
                      </div>
                      : userType === "FSE" && customerTypeAccess.includes("tpcdealer") ?
                        <div className="exportExcel mt-3 mr-3" onClick={() => exportDataFSETPCDealerUser()} style={{ cursor: 'pointer' }}>
                          <IconButton className="exportExcel" size="small">
                            <ArrowDownwardIcon fontSize="inherit" />
                          </IconButton>
                          Export to Excel
                        </div>
                        :
                        <div className="exportExcel mt-3 mr-3" onClick={() => exportData()} style={{ cursor: 'pointer' }}>
                          <IconButton className="exportExcel" size="small">
                            <ArrowDownwardIcon fontSize="inherit" />
                          </IconButton>
                          Export to Excel
                        </div>
                    }
                  </>
                )}

                <div className="font-weight-bold totalAed mt-3 mr-3">Total {customerTierType === "Int. Business" ? `${sessionStorage.getItem("companyCurrencyTypeForIB")}` : 'AED'} {tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-')}</div>
                {(sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('orderStoredBrand') !== "OES")
                  ? "" :
                  <>
                    {customerTierType && customerTierType?.toLowerCase() === "fleet" || customerTierType === "Fleet" || customerTierType && customerTierType?.toLowerCase() === "government" || customerTierType === "Government" ? "" :
                      <>
                        {orderDetails.data.paymentStatus !== "APPROVED" && <>
                          <div className="row font-weight-bold totalAed payment-status-padding mt-3 pr-5">
                            Payment Status - {"   "} {"   "}
                            <span className="row">
                              <p className={isHyperLink ? 'row paymentstatusfont pl-4 pr-4 cursor-pointer text-underline' : 'row paymentstatusfont pl-4 pr-4'} aria-describedby={id} onClick={(e: any) => handleClick(e)}>{orderDetails.data.paymentStatus}
                                {tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.nowOrderStatus == 'Fully Cancelled' ? '-' : nowStatus ? nowStatus : orderDetails.data.nowOrderPaymentStatus ? orderDetails.data.nowOrderPaymentStatus : '-')
                                  : (orderDetails.data.backOrderStatus == 'Fully Cancelled' ? '-' : backStatus ? backStatus : orderDetails.data.backOrderPaymentStatus ? orderDetails.data.backOrderPaymentStatus : '-')}
                              </p>
                              <p className="row">
                                <>
                                  {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length === 0 ?
                                    <>
                                      {
                                        <div className="d-flex flex-column">
                                          {nowStatus === "Offline Payment Pending" || nowStatus === "Payment Received" || nowStatus === "Offline Payment Pending (Payment at Counter)" || nowStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                            orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                            orderDetails && orderDetails.data.nowOrderStatus == 'Fully Cancelled' ||
                                            orderDetails && orderDetails.data.backOrderStatus == 'Fully Cancelled' ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <></> : orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                              <p className="row pl-3 pr-3"><ErrorIcon className='icon-color' /> </p>
                                            </Tooltip> : ""}
                                        </div>
                                      }
                                    </>
                                    :
                                    orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length == 0 && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 ?
                                      <>
                                        {
                                          <div className="d-flex flex-column">
                                            {
                                              backStatus === "Offline Payment Pending" || backStatus && backStatus === "Payment Received" ||
                                                backStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                backStatus === "Offline Payment Pending (Credit card at delivery )"
                                                || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" ||
                                                orderDetails && orderDetails.data.nowOrderStatus == 'Fully Cancelled' ||
                                                orderDetails && orderDetails.data.backOrderStatus == 'Fully Cancelled' ||
                                                orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ? <></> : orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                                  <p className="row pl-3 pr-3"><ErrorIcon className='icon-color' /> </p>
                                                </Tooltip> : ""}

                                          </div>
                                        }
                                      </>
                                      :
                                      orderDetails.data && tabValue == 0 && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ?
                                        <div className="d-flex flex-column">
                                          {nowStatus === "Offline Payment Pending" || nowStatus === "Payment Received" || nowStatus === "Offline Payment Pending (Payment at Counter)" ||
                                            nowStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                            orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending"
                                            ||
                                            orderDetails && orderDetails.data.nowOrderStatus == 'Fully Cancelled' ||
                                            orderDetails && orderDetails.data.backOrderStatus == 'Fully Cancelled' ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <></> : orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                              <p className="row pl-3 pr-3"><ErrorIcon className='icon-color' /> </p>
                                            </Tooltip> : ""}
                                        </div>
                                        :
                                        <>
                                          {
                                            <div className="d-flex flex-column">
                                              {backStatus === "Offline Payment Pending" || backStatus && backStatus === "Payment Received" || backStatus === "Offline Payment Pending (Payment at Counter)" || backStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                                orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                                orderDetails && orderDetails.data.nowOrderStatus == 'Fully Cancelled' ||
                                                orderDetails && orderDetails.data.backOrderStatus == 'Fully Cancelled' ||
                                                orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" ? <></> : orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                                  <p className="row pl-3 pr-3"><ErrorIcon className='icon-color' /> </p>
                                                </Tooltip> : ""}
                                            </div>
                                          }
                                        </>
                                  }

                                </>

                              </p>
                            </span>
                            {hidePaymentTStatus ? "" :
                              <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'center',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'center',
                                }}
                              >
                                <Typography className={classes.typography}>
                                  {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && tabValue === 0 ? (
                                    <>
                                      {orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Payment Received" || (nowStatus && nowStatus === 'Payment Received') || (nowStatus && nowStatus.includes('Declined')) || orderDetails.data.nowOrderPaymentStatus.includes('Declined') ? (
                                        <div className="col-12">
                                          <div className="subTitle mb-2">TransactionID:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.TransactionID}</span>
                                          </div>
                                          <div className="subTitle mb-2">RequestID:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.requestId}</span>
                                          </div>
                                          <div className="subTitle mb-2">Card Number:
                                            <span className="subTitleValue">xxxxxxxxxxxx{transactionDetails && transactionDetails.data && transactionDetails.data.CardNumber}</span>
                                          </div>
                                          <div className="subTitle mb-2">Payment Mode:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment && transactionDetails.data.typeOfPayment === 'CARD' ? 'CREDIT CARD' : transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment}</span>
                                          </div>
                                          <div className="subTitle mb-2">Date & Time:
                                            <span className="subTitleValue">
                                              {moment(transactionDetails && transactionDetails.data && transactionDetails.data.paymentStartedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                                            </span>
                                          </div>
                                        </div>
                                      ) : (
                                        <>
                                          {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && tabValue === 0 ?
                                            orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending" || (nowStatus && nowStatus.includes('Offline Payment Pending')) || orderDetails.data.nowOrderPaymentStatus.includes("Offline Payment Pending") || orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" || (nowStatus && nowStatus === 'Credit Utilized') ? (
                                              <div className="col-12">
                                                <div className="subTitle mb-2">Payment Mode:
                                                  <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment && transactionDetails.data.typeOfPayment === 'CARD' ? 'CREDIT CARD' : orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" ? 'Credit Utilized' : transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment}</span>
                                                </div>
                                                <div className="subTitle mb-2">Date & Time:
                                                  <span className="subTitleValue">
                                                    {moment(transactionDetails && transactionDetails.data && transactionDetails.data.paymentStartedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                                                  </span>
                                                </div>
                                              </div>
                                            ) : '' : ''
                                          }
                                        </>
                                      )}
                                    </>
                                  ) : orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && (
                                    <>
                                      {orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Payment Received" || (backStatus && backStatus === 'Payment Received') || (backStatus && backStatus.includes('Declined')) || orderDetails.data.backOrderPaymentStatus === "Payment Declined" || orderDetails.data.backOrderPaymentStatus === "Declined" ? (
                                        <div className="col-12">
                                          <div className="subTitle mb-2">TransactionID:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.TransactionID}</span>
                                          </div>
                                          <div className="subTitle mb-2">RequestID:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.requestId}</span>
                                          </div>
                                          <div className="subTitle mb-2">Card Number:
                                            <span className="subTitleValue">xxxxxxxxxxxx{transactionDetails && transactionDetails.data && transactionDetails.data.CardNumber}</span>
                                          </div>
                                          <div className="subTitle mb-2">Payment Mode:
                                            <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment && transactionDetails.data.typeOfPayment === 'CARD' ? 'CREDIT CARD' : transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment}</span>
                                          </div>
                                          <div className="subTitle mb-2">Date & Time:
                                            <span className="subTitleValue">
                                              {moment(transactionDetails && transactionDetails.data && transactionDetails.data.paymentStartedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                                            </span>
                                          </div>
                                        </div>
                                      ) :
                                        orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" || (backStatus && backStatus.includes('Offline Payment Pending')) || (backStatus && backStatus === 'Credit Utilized') || orderDetails.data.backOrderPaymentStatus.includes("Offline Payment Pending") || orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? (
                                          <div className="col-12">
                                            <div className="subTitle mb-2">Payment Mode:
                                              <span className="subTitleValue">{transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment && transactionDetails.data.typeOfPayment === 'CARD' ? 'CREDIT CARD' : orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? 'Credit Utilized' : transactionDetails && transactionDetails.data && transactionDetails.data.typeOfPayment}</span>
                                            </div>
                                            <div className="subTitle mb-2">Date & Time:
                                              <span className="subTitleValue">
                                                {moment(transactionDetails && transactionDetails.data && transactionDetails.data.paymentStartedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                                              </span>
                                            </div>
                                          </div>
                                        ) : ''}
                                    </>
                                  )}
                                </Typography>
                              </Popover>
                            }
                          </div>
                        </>}
                      </>}
                  </>}
                {(sessionStorage.getItem('userType') === 'FSE' && (orderDetails?.data?.salesData?.orderType === 'CRD' || orderDetails?.data?.customerAccountType === 'Credit')) || ((customerTierType?.toLowerCase() === "tpcdealer" || customerTierType?.toLowerCase() === "workshop") && customerAccountType === 'Credit') ? '' :
                  <>
                    {(sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('orderStoredBrand') !== "OES")
                      ? "" :
                      <>
                        {customerTierType && customerTierType?.toLowerCase() === "fleet" || customerTierType === "Fleet" || customerTierType && customerTierType?.toLowerCase() === "government" || customerTierType === "Government" ? "" :
                          <>
                            {hidePaymentTStatus ? "" :
                              <>
                                {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length === 0 ?
                                  <>
                                    {
                                      <div className="d-flex flex-column">
                                        {nowStatus === "Offline Payment Pending" || nowStatus === "Payment Received" || nowStatus === "Offline Payment Pending (Payment at Counter)" || nowStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <></> : orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                          <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                        </Tooltip> : ""}
                                        <Button variant="contained" className={classes1.makePayment}
                                          disabled={orderDetails.data.nowOrderStatus == 'Fully Cancelled' || !isSellingPriceData || nowStatus && nowStatus === "Payment Received" ||
                                            nowStatus && nowStatus === "Offline Payment Pending (Payment at Counter)" ||
                                            nowStatus && nowStatus === "Pending Approval" ||
                                            nowStatus && nowStatus === "Payment in Progress" ||
                                            nowStatus && nowStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                            nowStatus && nowStatus === "Offline Payment Pending" ||
                                            parseInt(orderDetails.data.netPrice) == 0 ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Payment Received" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Payment in Progress" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Pending Approval" ||
                                            orderDetails.data.nowOrderGoodsMovementStatus == 'Completed' ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false} size="large" onClick={() => {
                                              setOrderData({
                                                B2bEnquiryId: orderDetails.data.masterId,
                                                enquiryNumber: orderDetails.data.enquiryNumber,
                                                orderNumber: orderDetails.data.orderNumber && orderDetails.data.orderNumber,
                                                orderTotal: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                isBackOrder: false,
                                                make: sessionStorage.getItem('orderStoredBrand'),
                                                price: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                deliveryType: orderDetails.data.deliveryType
                                              })
                                              setOpenDialog(true)
                                              dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
                                            }}
                                        >{"Make Payment"}
                                        </Button>
                                      </div>
                                    }
                                  </>
                                  :
                                  orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length == 0 && orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 ?
                                    <>
                                      {
                                        <div className="d-flex flex-column">
                                          {
                                            backStatus === "Offline Payment Pending" || backStatus && backStatus === "Payment Received" ||
                                              backStatus === "Offline Payment Pending (Payment at Counter)" ||
                                              backStatus === "Offline Payment Pending (Credit card at delivery )"
                                              || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" ||
                                              orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ? <></> : orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                                <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                              </Tooltip> : ""}
                                          <Button variant="contained" disabled={!isSellingPriceData || backStatus && backStatus === "Payment Received" || backStatus && backStatus === "Offline Payment Pending" || orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" ||
                                            backStatus && backStatus === "Payment in Progress" || orderDetails.data.backOrderPaymentStatus === "Payment in Progress" || orderDetails.data.backOrderStatus == 'Fully Cancelled' || orderDetails.data.backOrderGoodsMovementStatus == 'Completed' ||
                                            orderDetails.data.backOrderPaymentStatus === "Payment Received" || backStatus === "Offline Payment Pending (Payment at Counter)" || backStatus === "Offline Payment Pending (Credit card at delivery )" ||
                                            parseInt(orderDetails.data.backOrderPrice) == 0 ||
                                            orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ? true : false} className={classes1.makePayment} size="large" onClick={() => {
                                              setOrderData({
                                                B2bEnquiryId: orderDetails.data.masterId,
                                                enquiryNumber: orderDetails.data.enquiryNumber,
                                                orderNumber: sessionStorage.getItem('orderBackNumber'),
                                                orderTotal: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                isBackOrder: true,
                                                make: sessionStorage.getItem('orderStoredBrand'),
                                                price: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                deliveryType: orderDetails.data.deliveryType
                                              })
                                              setOpenDialog(true)
                                              dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
                                            }}
                                          >{"Make Payment "}
                                          </Button>
                                        </div>
                                      }
                                    </>
                                    :
                                    orderDetails.data && tabValue == 0 && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ?
                                      <div className="d-flex flex-column">
                                        {nowStatus === "Offline Payment Pending" || nowStatus === "Payment Received" || nowStatus === "Offline Payment Pending (Payment at Counter)" || nowStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending"
                                          || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <></> : orderDetails.data.nowOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                            <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                          </Tooltip> : ""}
                                        {
                                          <Button variant="contained" disabled={!isSellingPriceData || orderDetails.data.nowOrderStatus == 'Fully Cancelled' ||
                                            orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending" || orderDetails.data.nowOrderGoodsMovementStatus == 'Completed' ||
                                            orderDetails.data.nowOrderPaymentStatus === "Payment Received" || nowStatus && nowStatus === "Payment Received" ||
                                            orderDetails.data.nowOrderPaymentStatus === "Payment in Progress" || nowStatus && nowStatus === "Payment in Progress" ||
                                            parseInt(orderDetails.data.netPrice) == 0 ||
                                            nowStatus && nowStatus === "Offline Payment Pending" || nowStatus === "Offline Payment Pending (Payment at Counter)" || nowStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails.data.nowOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}

                                            className={classes1.makePayment} size="large" onClick={() => {
                                              setOrderData({
                                                B2bEnquiryId: orderDetails.data.masterId,
                                                enquiryNumber: orderDetails.data.enquiryNumber,
                                                orderNumber: tabValue === 0 && orderDetails.data.orderNumber,
                                                orderTotal: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                isBackOrder: false,
                                                make: sessionStorage.getItem('orderStoredBrand'),
                                                price: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                deliveryType: orderDetails.data.deliveryType
                                              })
                                              setOpenDialog(true)
                                              dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
                                            }}
                                          >{"Make Payment"}
                                          </Button>}
                                      </div>
                                      :
                                      <>
                                        {
                                          <div className="d-flex flex-column">
                                            {backStatus === "Offline Payment Pending" || backStatus && backStatus === "Payment Received" || backStatus === "Offline Payment Pending (Payment at Counter)" || backStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" ? <></> : orderDetails.data.backOrderPaymentStatus === "Credit Utilized" ? <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                              <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                            </Tooltip> : ""}
                                            <Button variant="contained" disabled={backStatus === "Offline Payment Pending (Payment at Counter)" || backStatus === "Offline Payment Pending (Credit card at delivery )" || !isSellingPriceData || backStatus && backStatus === "Payment Received" || orderDetails.data.backOrderStatus == 'Fully Cancelled' ||
                                              backStatus && backStatus === "Offline Payment Pending" || orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending" || orderDetails.data.backOrderGoodsMovementStatus == 'Completed' ||
                                              backStatus && backStatus === "Payment in Progress" || orderDetails.data.backOrderPaymentStatus === "Payment in Progress" ||
                                              parseInt(orderDetails.data.backOrderPrice) == 0 ||
                                              orderDetails.data.backOrderPaymentStatus === "Payment Received" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Credit card at delivery )" || orderDetails && orderDetails.data && orderDetails.data.backOrderPaymentStatus === "Offline Payment Pending (Payment at Counter)" ? true : false} className={classes1.makePayment} size="large" onClick={() => {
                                                setOrderData({
                                                  B2bEnquiryId: orderDetails.data.masterId,
                                                  enquiryNumber: orderDetails.data.enquiryNumber,
                                                  orderNumber: sessionStorage.getItem('orderBackNumber'),
                                                  orderTotal: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                  isBackOrder: true,
                                                  make: sessionStorage.getItem('orderStoredBrand'),
                                                  price: tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-'),
                                                  deliveryType: orderDetails.data.deliveryType
                                                })
                                                setOpenDialog(true)
                                                dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
                                              }}
                                            >{"Make Payment"}
                                            </Button>
                                          </div>
                                        }
                                      </>
                                }
                              </>
                            }
                          </>}
                      </>}
                  </>
                }
              </div>
            </div>
          </div>

          <div className="row mt-2 mb-2 ">
            <div className="" style={{ marginRight: "0px" }}>
              <div className=" " >

              </div>
              <h5 className="page-sub-title ml-3">Order Items</h5>
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2">
              <div className="cardCommon orderItems">
                <Paper square>
                  <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Dealer Tab">
                    {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && <Tab label={(customerTierType && customerTierType === "Workshop" || sessionStorage.getItem('customerTierType') === 'tpcdealer') ? "Now / 24 hours Order" : "Now Order"} {...allTabProps(0)} />}
                    {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && <Tab label="Back Order" {...allTabProps(orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? 1 : 0)} />}
                  </Tabs>
                </Paper>
                {(customerTierType === "Workshop" || sessionStorage.getItem('customerTierType') === 'tpcdealer') ?
                  <>
                    {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && <TabPanel value={tabValue} index={0}>
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead className="w-100">
                            <TableRow>
                              <TableCell className={`pt-0 pb-0 ${classes.tableHead}`}>Part No.</TableCell>
                              <TableCell className={classes.tableHead}>Description</TableCell>
                              <TableCell className={classes.tableHead}>Brand</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Now Qty</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>24 Hrs Qty</TableCell>
                              <TableCell className={classes.tableHead}>
                                <h6 className="netHead mt-3 pt-1 pb-0">Net Price</h6>
                                <div className={classes.vat}>(Incl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Currency</TableCell>
                              <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivered Qty.</TableCell>
                              <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivery Status</TableCell>
                              {(userType === "FSE" && customerTypeAccess.includes("tpcdealer")) || (customerTierType === "Workshop") || (userType === "FSE" && customerTypeAccess.includes("workshop")) ?
                                <TableCell className={`${classes.tableHead} ${classes.quantity}`}>STO ID</TableCell> : ""}
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                                <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                                <div className={classes.vat}>(Excl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                                <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                                <div className={classes.vat}>(Incl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>AOP</TableCell>
                              {/* <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Width</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Height</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Length</TableCell> */}
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Weight</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Volume</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails.data && orderDetails.data.products && orderDetails.data.products.map((row: any) => {
                              return (
                                <TableRow className={`${row.isSuperseeded ? "superseededRow" : ""}`}>
                                  <TableCell className={classes.bodyContent}>
                                    {!row.supercessionMaterial ? row.productId : <>
                                      {row.productId}<br />
                                      (<small>{row.supercessionMaterial}</small>)
                                    </>}
                                  </TableCell>
                                  {columns.map((column: any) => {
                                    if (column.id !== 'quantity' && column.id !== 'deliveredQuantity' && column.id !== 'materialGroup') {
                                      if (column.label === 'Now Qty') {
                                        const Nowvalue: any = column.id === 'nowAvailability' && row.nowAvailabilityChecked ? parseInt(row["nowAvailability"]) : 0
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {Nowvalue}
                                          </TableCell>
                                        );
                                      } else if (column.label === '24 Hrs Qty') {
                                        const Hrsvalue: any = column.id === 'hrs24Availability' && row.hrs24AvailabilityChecked ? parseInt(row['hrs24Availability']) : parseInt(row[column.id])
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {Hrsvalue}
                                          </TableCell>
                                        );
                                      } else if (column.id) {
                                        const value: any = row[column.id];
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {column.id === 'netValueOrderDocumentCurrency' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                          </TableCell>
                                        );
                                      }
                                      const value: any = column.id === 'nowAvailability' ? row.nowAvailabilityChecked ? parseInt(row[column.id]) : 0 : row[column.id];
                                      return (
                                        <TableCell key={column.id} className={classes.bodyContent}>
                                          {column.id === 'netValueOrderDocumentCurrency' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                        </TableCell>
                                      );
                                    }
                                    if (column.id === 'materialGroup') {
                                      const value: any = row[column.id];
                                      return (
                                        customerTierType && (customerTierType?.toLowerCase() === 'workshop' || sessionStorage.getItem('customerTierType') === 'tpcdealer') ?
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {!showBrandName || row.brandName == undefined ? (
                                              <>
                                                {row.displayName === 'ALAC' ? 'Tradepoint' : row.displayName}
                                              </>
                                            ) : (
                                              <>
                                                {row.displayName === 'ALAC'
                                                  ? (row.brandName ? row.brandName : 'Tradepoint')
                                                  : row.displayName}

                                                {/* Check if brandName is 'Tradepoint' before showing the row.type */}
                                                {row.brandName !== 'Tradepoint' && (
                                                  <div className="font-10">
                                                    {row.type === 'afterMarket'
                                                      ? '(Aftermarket)'
                                                      : (row.type === 'OEM' ? '' : '(Aftermarket)')}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </TableCell>


                                          :
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`]) === 'Tradepoint' && (
                                              (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) && (sessionStorage.getItem('customerTierType') !== 'tpcdealer' || !customerTypeAccess.includes("tpcdealer"))) ? 'ALAC' : getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`])}
                                            {/* {getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`])} */}
                                          </TableCell>
                                      );
                                    }
                                    if (column.id === 'deliveredQuantity') {
                                      const value: any = row[column.id];
                                      return (
                                        <TableCell key={column.id} className={classes.bodyContent}>
                                          {parseFloat(value).toFixed(2)}
                                        </TableCell>
                                      );
                                    }
                                    return undefined;
                                  })}
                                  {(userType === "FSE" && customerTypeAccess.includes("tpcdealer")) || (customerTierType === "Workshop") || (userType === "FSE" && customerTypeAccess.includes("workshop")) ?
                                    <TableCell className={classes.bodyContent}>
                                      {row.stoId ? row.stoId : ""}
                                    </TableCell>
                                    : " "}
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.netPrice, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.nsp, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.aop, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  {/* <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.width).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.height).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.length).toFixed(2)}
                                  </TableCell> */}
                                  <TableCell className={classes.bodyContent}>
                                    {row.dimensions.grossWeight ? parseFloat(row.dimensions.grossWeight).toFixed(2) : '-'}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {row.dimensions.volume}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>}
                  </>
                  :
                  <>
                    {orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 && <TabPanel value={tabValue} index={0}>
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table" >
                          <TableHead className="w-100">
                            <TableRow>
                              <TableCell className={`pt-0 pb-0 ${classes.tableHeadColor}`}></TableCell>
                              <TableCell className={`pt-0 pb-0 ${classes.tableHead}`}>Part No.</TableCell>
                              <TableCell className={classes.tableHead}>Description</TableCell>
                              <TableCell className={classes.tableHead}>Brand</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Qty</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Conf Qty</TableCell>
                              <TableCell className={classes.tableHead}>
                                <h6 className="netHead mt-3 pt-1 pb-0">Net Price</h6>
                                <div className={classes.vat}>(Incl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Currency</TableCell>
                              <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivered Qty.</TableCell>
                              <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivery Status</TableCell>
                              {(userType === "FSE" && customerTypeAccess.includes("tpcdealer")) || (customerTierType === "Workshop") || (userType === "FSE" && customerTypeAccess.includes("workshop")) ?
                                <TableCell className={`${classes.tableHead} ${classes.quantity}`}>STO ID</TableCell> : ""}
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                                <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                                <div className={classes.vat}>(Excl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                                <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                                <div className={classes.vat}>(Incl.Vat)</div>
                              </TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>AOP</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Width</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Height</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Length</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Weight</TableCell>
                              <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Volume</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {orderDetails.data && orderDetails.data.products && orderDetails.data.products.map((row: any) => {
                              return (
                                <TableRow className={`${row.isSuperseeded ? "superseededRow" : ""}`}>
                                  {row.isSpecialPriceRequestPart === true ?
                                    <TableCell>
                                      {
                                        row.isSpecialPriceRequestPart === true ? <div className="legendItemCreateEnquiry">
                                          <span className="specialprice"></span>
                                        </div> : null
                                      }
                                    </TableCell>
                                    :
                                    <TableCell>
                                      {
                                        row.isSpecialPriced === true ? <div className="legendItemCreateEnquiry green-legend-margin green-legend-margin">
                                          <span className="dot"></span>
                                        </div> : null
                                      }
                                    </TableCell>
                                  }
                                  {sessionStorage.setItem("companyCurrencyTypeForIB", row.currency)}
                                  <TableCell className={classes.bodyContent}>
                                    {!row.supercessionMaterial ? row.productId : <>
                                      {row.productId}<br />
                                      (<small>{row.supercessionMaterial}</small>)
                                    </>}
                                  </TableCell>
                                  {columnsDealerUser.map((column: any) => {
                                    if (column.id !== 'quantity' && column.id !== 'deliveredQuantity' && column.id !== 'materialGroup') {
                                      if (column.label === 'Qty') {
                                        const value: any = column.id === 'nowAvailability' ? row.nowAvailabilityChecked ? parseInt(row[column.id]) : 0
                                          : row.hrs24AvailabilityChecked ? parseInt(row['hrs24Availability']) : parseInt(row[column.id])
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {value}
                                          </TableCell>
                                        );
                                      } else if (column.label === 'Confirm Qty') {
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {row.confirmedQuantity}
                                          </TableCell>
                                        );
                                      } else {
                                        // const value: any = row[column.id];
                                        const value: any = column.id === 'currency' && sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? row && row.currency : column.id === 'currency' && customerTierType === "Int. Business" ? `${companyCurrencyType}` : column.id === 'quantity' ? parseInt(row[column.id]) : row[column.id];
                                        return (
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {column.id === 'netValueOrderDocumentCurrency' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                          </TableCell>
                                        );
                                      }
                                      const value: any = column.id === 'nowAvailability' ? row.nowAvailabilityChecked ? parseInt(row[column.id]) : 0 : row[column.id];
                                      return (
                                        <TableCell key={column.id} className={classes.bodyContent}>
                                          {column.id === 'netValueOrderDocumentCurrency' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                        </TableCell>
                                      );
                                    }
                                    if (column.id === 'materialGroup') {
                                      const value: any = row[column.id];
                                      return (
                                        customerTierType && customerTierType?.toLowerCase() === 'workshop' || sessionStorage.getItem('customerTierType') === 'tpcdealer' ?
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {row.displayName === 'ALAC' ? 'Tradepoint' : row.displayName}
                                          </TableCell> :
                                          <TableCell key={column.id} className={classes.bodyContent}>
                                            {getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`]) === 'Tradepoint' && (
                                              (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) && (sessionStorage.getItem('customerTierType') !== 'tpcdealer' || !customerTypeAccess.includes("tpcdealer"))) ? 'ALAC' : getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`])}
                                            {/* {getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`])} */}
                                          </TableCell>
                                      );
                                    }
                                    if (column.id === 'deliveredQuantity') {
                                      const value: any = row[column.id];
                                      return (
                                        <TableCell key={column.id} className={classes.bodyContent}>
                                          {parseFloat(value).toFixed(2)}
                                        </TableCell>
                                      );
                                    }
                                    return undefined;
                                  })}
                                  {(userType === "FSE" && customerTypeAccess.includes("tpcdealer")) || (customerTierType === "Workshop") || (userType === "FSE" && customerTypeAccess.includes("workshop")) ?
                                    <TableCell className={classes.bodyContent}>
                                      {row.stoId ? row.stoId : ""}
                                    </TableCell>
                                    : " "}
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.netPrice, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.nsp, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(getSellingPrice(row.aop, isSellingPriceData, markupPercentage)).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.width).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.height).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {parseFloat(row.dimensions.length).toFixed(2)}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {row.dimensions.grossWeight ? parseFloat(row.dimensions.grossWeight).toFixed(2) : '-'}
                                  </TableCell>
                                  <TableCell className={classes.bodyContent}>
                                    {row.dimensions.volume}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>}
                  </>
                }
                {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder && orderDetails.data.backOrder.length > 0 && <TabPanel value={tabValue} index={orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? 1 : 0}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" >
                      <TableHead className="w-100">
                        <TableRow>
                          <TableCell className={`pt-0 pb-0 ${classes.tableHeadColor}`}></TableCell>
                          <TableCell className={`pt-0 pb-0 ${classes.tableHead}`}>Part No.</TableCell>
                          <TableCell className={classes.tableHead}>Description</TableCell>
                          <TableCell className={classes.tableHead}>Brand</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Qty</TableCell>
                          <TableCell className={classes.tableHead}>
                            <h6 className="netHead mt-3 pt-1 pb-0">Net Price</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Currency</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivered Qty.</TableCell>
                          <TableCell className={`mt-5 pt-0 pb-0 ${classes.tableHead}`}>Delivery Status</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Excl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>
                            <h6 className="netHead mt-3 pt-0 pb-0">Net Value</h6>
                            <div className={classes.vat}>(Incl.Vat)</div>
                          </TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>AOP</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Width</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Height</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Length</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Weight</TableCell>
                          <TableCell className={`${classes.tableHead} ${classes.quantity}`}>Volume</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {orderDetails.data && orderDetails.data.backOrder && orderDetails.data.backOrder.map((row: any) => {
                          return (
                            <TableRow className={`${row.isSuperseeded ? "superseededRow" : ""}`}>
                              {row.isSpecialPriceRequestPart === true ?
                                <TableCell>
                                  {
                                    row.isSpecialPriceRequestPart === true ? <div className="legendItemCreateEnquiry">
                                      <span className="specialprice"></span>
                                    </div> : null
                                  }
                                </TableCell>
                                :
                                <TableCell>
                                  {
                                    row.isSpecialPriced === true ? <div className="legendItemCreateEnquiry green-legend-margin green-legend-margin">
                                      <span className="dot"></span>
                                    </div> : null
                                  }
                                </TableCell>
                              }
                              <TableCell className={classes.bodyContent}>
                                {!row.supercessionMaterial ? row.productId : <>
                                  {row.productId}<br />
                                  (<small>{row.supercessionMaterial}</small>)
                                </>}
                              </TableCell>
                              {columnsBack.map((column: any) => {
                                if (column.id !== 'nowAvailability' && column.id !== 'materialGroup') {
                                  const value: any = column.id === 'currency' && sessionStorage.getItem('userType') === 'FSE' && customerTierType === "Int. Business" ? row && row.currency : column.id === 'currency' && customerTierType === "Int. Business" ? `${companyCurrencyType}` : column.id === 'quantity' ? parseInt(row[column.id]) : row[column.id];
                                  return (
                                    <TableCell key={column.id} className={classes.bodyContent}>
                                      {column.id === 'backOrderPrice' ? parseFloat(getSellingPrice(value, isSellingPriceData, markupPercentage)).toFixed(2) : value}
                                    </TableCell>
                                  );
                                }
                                if (column.id === 'materialGroup') {
                                  const value: any = row[column.id];
                                  return (
                                    // sessionStorage.getItem('userType') === 'FSE' ? 
                                    <TableCell key={column.id} className={classes.bodyContent}>
                                      {sessionStorage.getItem('customerTierType') === 'tpcdealer' ? 'Tradepoint' : getNewBrand(sessionStorage.getItem('orderStoredBrand')) === 'Tradepoint' && (customerTierType && customerTierType?.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? 'ALAC' : getNewBrand(sessionStorage.getItem('orderStoredBrand'))}
                                      {/* {getNewBrand(sessionStorage.getItem('orderStoredBrand'))} */}
                                    </TableCell>
                                    // : 
                                    // <TableCell key={column.id} className={classes.bodyContent}>
                                    //   {getNewBrand(enquiryMapping[`${formatMaterialGroup(value)}`])}
                                    // </TableCell>
                                  );
                                }
                                return undefined;
                              })}
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.nspev, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.nsp, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(getSellingPrice(row.aop, isSellingPriceData, markupPercentage)).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.width).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.height).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {parseFloat(row.dimensions.length).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.grossWeight ? parseFloat(row.dimensions.grossWeight).toFixed(2) : '-'}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>
                                {row.dimensions.volume}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
                }<div className="bottomBorder w-100 col-12 d-flex justify-content-end">
                  <div className="totalAED mt-3 mb-3 mr-3">Total {customerTierType === "Int. Business" ? `${sessionStorage.getItem("companyCurrencyTypeForIB")}` : 'AED'} {tabValue === 0 && orderDetails.data && orderDetails.data.products && orderDetails.data.products && orderDetails.data.products.length > 0 ? (orderDetails.data.netPrice ? parseFloat(getSellingPrice(orderDetails.data.netPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-') : (orderDetails.data.backOrderPrice ? parseFloat(getSellingPrice(orderDetails.data.backOrderPrice, isSellingPriceData, markupPercentage)).toFixed(2) : '-')}</div>
                </div>
                {/* <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    rowsPerPageOptions={[5]}
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div> */}
              </div>
            </div>
          </div>
          {orderDetails && orderDetails.data && orderDetails.data.userRole === 'FSE' && (
            <div className="tandcText m-2">
              {profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo ?
                <>
                  <div className="row px-4"> <span className="tandcHeading-orderhistory">Note :</span> <span className="row tandcBody-orderhistory pr-2 px-3">This Sales Order was created by Al-Futtaim Sales Team. If this Sales Order was not requested by you, then please click on </span>
                    <span className="row px-3 title-orderhistory cursor-pointer" onClick={(e: any) => {
                      setOpenCloseSupportDialog(true);
                    }}>"Report Order".</span></div>
                </>
                : ""}
            </div>
          )}
        </>
      )
      }
      <ReportOrderDialog
        openCloseSupportDialog={openCloseSupportDialog}
        orderNumber={orderNumber}
        setOpenCloseSupportDialog={setOpenCloseSupportDialog}
      ></ReportOrderDialog>
      <ViewQuotation openViewQuotation={openViewQuotation} handleCloseViewQuotation={handleCloseViewQuotation} enquiryNumber={enquiryNumber} overallDeliveryStatus={overallDeliveryStatus} brandId={brandId} viewQuotationUrl={viewQuotationUrl} quotationNumber={quotationNumber} />
      <OrderHistoryViewQuotation openViewQuotation={openViewQuotation} handleCloseViewQuotation={handleCloseViewQuotation} overallDeliveryStatus={overallDeliveryStatus} enquiryNumber={enquiryNumber} brandId={brandId} viewQuotationUrl={viewQuotationUrl} quotationNumber={quotationNumber} />
      <OrderHistoryViewLPO openViewLPO={openViewLPO} handleCloseViewLPO={handleCloseViewLPO} overallDeliveryStatus={overallDeliveryStatus} quotationNumber={quotationNumber} enquiryNumber={enquiryNumber} orderNumber={orderNumber} viewQuotationUrl={viewQuotationUrl} updateLPO={updateLPO} fileExtension={fileExtention} />
      <Dialog className="pb-0"
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        {/* <MakePaymentDialog setOpenDialog={handleCloseDialog} orderData={orderData}/> */}
        <MakePaymentViewOrder orderData={orderData} setSuccessMessage={setSuccessMessage}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          openErrorSnackbar={openErrorSnackbar}
          handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog} />
      </Dialog >
      <UploadLpoViewOrder openUploadLpo={openUploadLpo} overallDeliveryStatus={overallDeliveryStatus} handleUploadLpo={handleUploadLpo} orderNumber={orderNumber} />
    </Fragment >
  );
};
export default OrderDetails;