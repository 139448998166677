/* eslint-disable */
import React, { useEffect, Dispatch, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import { loginRequest, b2cPolicies } from "../../authConfig";
import { login, logout } from "../../store/actions/account.actions";
import { useHistory } from 'react-router-dom';
import { profileservice } from './../../services/profile.service'
import jwt_decode from "jwt-decode";
import { onBoardingService } from './../../services/onBoarding.service'
import { getProfile, updateProfile } from "../../store/actions/profile.action";
import { getJwtToken, logoutUser } from './../../store/actions/logon.actions';
import { authService } from './../../services/auth.service';
import Loading from '../../common/components/loading';
import { markAdminUser } from "../../store/actions/global.actions";
import { OnboardingErrorPage } from '../../common/AccountRestricted/OnboardingErrorPage';
import FullscreenLoader from "./../../common/components/FullscreenLoader";
import { addNotifications } from '../../store/actions/notifications.action';
import { DASHBOARD_REDIRECT_EXCLUSION } from '../../constants/dashboardRedirectExclusion.js';
import { getMaterialGroups } from '../../store/actions/materialGroups.actions';
import { NewCustomerDialog } from '../newUserOnboard/newCustomerDialog';
import DeactivateDialog from '../ViewEditDealerDetailsKam/DeactivateDailog';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, makeStyles } from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import { isAccesstoAchievement } from '../../store/actions/achievement.actions';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  }
}));


const LoginAzure: React.FC = () => {
  const classes = useStyles()
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(false);
  const [showstatus, showStatus] = React.useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const userData = useSelector((state: any) => { return state.account && state.account.userData })
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();
  const history = useHistory();
  // const [hasFailed, setHasFailed] = React.useState('')
  const userDispatch = useUserDispatch();
  const [authFailed, setauthFailed] = useState(false);
  const [deactiveMsg, setDeactiveMessage] = useState('');
  const [showDeactivateDialog, setshowDeactivateDialog] = React.useState<any>('');
  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const { tokenInfo }: any = useSelector((state: any) => state.logon);

  const verifySuccess = () => {
    showStatus(true);
  }



  useEffect(() => {
    if (tokenInfo && tokenInfo.data) {
      authService.setToken(tokenInfo.data)
    }
  }, [tokenInfo])

  useEffect(() => {
    if (window.location.href.includes('error')) {
      history.push('/');
    }
    else {
      loginUser()
      const sessionExpiry = Date.now() + 3600000;
      sessionStorage.setItem("sessionExpiry", String(sessionExpiry))
    }
  }, []);

  const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
    setOpenSnackbar(openRequestSnackbar ? true : false);
  };

  const loginUser = async () => {
    if (window.location.protocol == "http:" && !window.location.href.includes('localhost') && !window.location.href.toLowerCase().includes('autob2bqa.corp.al-futtaim.com')) {
      window.location.href = window.location.href.replace(/^http:/, 'http:');
    } else {
      await instance.handleRedirectPromise();
      const accounts = instance.getAllAccounts();
      if (accounts && Array.isArray(accounts) && accounts[0]) {
        const aud = get(accounts[0], 'idTokenClaims.aud', "");
        const tid = get(accounts[0], 'idTokenClaims.tid', "");
        const msalKey = accounts[0].homeAccountId + "-" + accounts[0].environment + "-idtoken-" + aud + "-" + tid + "-";
        const msalData: any = sessionStorage.getItem(msalKey);
        const msalDataParsed = JSON.parse(msalData);
        sessionStorage.setItem('idToken', msalDataParsed && msalDataParsed.secret);
      }
      if (accounts.length === 0) {
        document.getElementById('btnLogin')?.click();
      } else {
        if (accounts && Array.isArray(accounts) && accounts[0]) {
          let data: any = accounts[0];
          let userData: any = await onBoardingService.getUserB2CIdNew(data.idTokenClaims.oid, data.username);
          sessionStorage.setItem('userOid', data.idTokenClaims.oid);
          if (userData && userData.data) {
            // let b2cUid = userData.data[0].issuerAssignedId;
            // let tokenData: any = await dispatch(getJwtToken(b2cUid, accounts[0].username || data.idTokenClaims.emails && Array.isArray(data.idTokenClaims.emails) && data.idTokenClaims.emails[0]));
            await authService.setToken(userData && userData.data && userData.data);
            let jwt_token: any = jwt_decode(userData.data.accessToken);
            sessionStorage.setItem('sapCustomerId', '');
            sessionStorage.setItem('customerType', '');
            let profileData: any = await dispatch(getProfile(jwt_token.userId, "dealerUser")); 
            // profileservice.getProfile(jwt_token.userId, "dealerUser");
            // if (profileData && profileData.data && profileData.data.basicInfo) {
            //   dispatch(isAccesstoAchievement(profileData.data.basicInfo.b2bUserId, profileData.data.basicInfo.sapCustomerId))
            // }
            // if(profileData){
            //   setLoading(profileData.profileDetails.loading);
            // }
            if (profileData && profileData.message && profileData.message.status == "S") {
              if (profileData.data && profileData.data.customerInfo && profileData.data.customerInfo.status && profileData.data.customerInfo.status.toLowerCase() === "inactive") {
                setshowDeactivateDialog(profileData.data.customerInfo.deactivationReason);
                setOpenSnackbar(true);
              } else {
                // profileData = await dispatch(getProfile(jwt_token.userId, "dealerUser"));
                await dispatch(markAdminUser(false))
                await dispatch(login(accounts[0]));
                await dispatch(getMaterialGroups());

                if (profileData.data && profileData.data.basicInfo) {
                  sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                  sessionStorage.setItem('userType', 'dealerUser');
                  sessionStorage.setItem('email', profileData.data.basicInfo.email);
                  sessionStorage.setItem('customerId', profileData.data.basicInfo.sapCustomerId)
                  sessionStorage.setItem('customerName', profileData.data.customerInfo.companyName)
                  sessionStorage.setItem('isTpcDealer', profileData.data.customerInfo.isTpcDealer)
                  sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                }
                const data = {
                  userType: "DEALER",
                  sapCustomerId: sessionStorage.getItem('customerId'),
                  webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId')
                }
                // if (profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === 'Admin') {
                //   await dispatch(addNotifications(data));
                // }
                if (profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "guestAdmin") {
                  history.push('/onboarding')
                } else {
                  //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                  if(profileData.data && profileData.data.customerInfo && profileData.data.customerInfo.customerTierType && (profileData.data.customerInfo.customerTierType.toLowerCase() === 'dealer' || profileData.data.customerInfo.customerTierType.toLowerCase() === 'tpcdealer')) {
                    await dispatch(isAccesstoAchievement(profileData.data.basicInfo.b2bUserId, profileData.data.basicInfo.sapCustomerId))
                  }
                  if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                    history.push('/');
                  }
                }
              }
            } else {
              if (profileData && profileData.status === 403) {
                setauthFailed(true);
                setDeactiveMessage(profileData.message)
              }
              setauthFailed(true);
              // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
              // setTimeout(async () => {
              //   await dispatch(logout());
              //   signOut(userDispatch);
              //   instance.logoutRedirect({ postLogoutRedirectUri: "/" });
              // }, 3000)
            }
          }
        }
      }
    }
  }

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  return (
    <div>
      <Dialog
        open={openSnackbar ? true : false}
        onClose={() => {
          callLogout()
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}>
        <DialogContent>
          <div className={classes.icon}>
            <WarningIcon className="delete-warning" fontSize="small" />
          </div>
          <div>
            <DialogContentText id="alert-dialog-description">
              <div className="mt-2">
                <h5 className="submit-dialog-content">Your account has been deactivated</h5>
                <p >Reason: {showDeactivateDialog}</p>
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions className="mt-3 mb-3 justify-content-end">
          <Button variant="contained"
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {

            }}>Logout
          </Button>
        </DialogActions>
      </Dialog>
      {/* {hasFailed && <h1>{hasFailed}</h1>} */}
      {!openSnackbar && <> {authFailed ? <OnboardingErrorPage status={deactiveMsg ? deactiveMsg : "noAccess"} /> : <FullscreenLoader />} </>}
      <button style={{ display: 'none' }} id="btnLogin" disabled={loading} onClick={() => instance.loginRedirect(loginRequest)}>{loading && <Loading />}Login to Azure</button>
    </div>
  )
}

export default LoginAzure
