
/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NotificationIcon from '../../common/components/NotificationIcon';
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import { FormControl, FormControlLabel, Radio, RadioGroup, Toolbar, Tooltip } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import AddIcon from '@material-ui/icons/Add';
import { useRef } from 'react';
import { DataUsageTwoTone } from '@material-ui/icons';
import { getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getDealerDetails, changeCustomerType, submitAddressTab, getDeliveryType, updateDeliveryType, getTransportationZone } from '../../store/actions/onboard.actions';
import { get } from 'lodash';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';
import { Prompt, useHistory } from 'react-router-dom';
import SubmitDialog from './submitYourRequestDialog';
import UserConfirmationDialog from './confirmDialog';
import { confirmDataFromDealer } from '../../store/actions/dealer.action';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';


export const ShipToPartyCustomer = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [successMessageForUser, setSuccessMessageForUser] = React.useState<any>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarForConfirm, setOpenSnackbarForConfirm] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const [selected, setSelected] = React.useState<string>("");
  const [deliveryType, setDeliveryType] = React.useState<string>('DLV');
  const [sapId, setSapId] = React.useState<string>();
  const [loading, setLoading] = React.useState<any>(false);
  const [page, setPage] = React.useState(0);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const addressDialogRef: any = useRef();
  const [apiResponseText, setApiResponseText] = React.useState<any>("");
  const [userConfirmation, setUserConfirmation] = React.useState<any>(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const { shipToPartyAddressData, deliveryTypes, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData }: any = useSelector((state: any) => state.onboard);
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);
  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const { customerDetails, tradeRegionData }: any = useSelector((state: any) => state.onboard);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [shippingId, setShippingId] = React.useState("")
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);

  const customer: any = sessionStorage.getItem('customerOnboardingType')
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbarForConfirm = (open?: any) => {
    setOpenSnackbarForConfirm(open ? true : false);
  };

  const handleClose = () => {
    setSelected("");
    setOpen(false);
  };

  // const isValidShipToPartyTab = () => {
  //   if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0 && customer !== "Government") {
  //     if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && customer !== "Government") {
  //       let selected = tradeRegionData.data.filter((data: any) => data.id === addressList[0].tradeLicenseNoId);
  //       if ((selected && selected.length > 0 && selected[0].tradeLicenseNo)) {
  //         return true;
  //       } else {
  //         if (deliveryType === "DLV") {
  //           setOpenErrorSnackbar(true);
  //           setSuccessMessage('Please add Trade License Number');
  //           return false;
  //         } else {
  //           return true
  //         }

  //       }
  //     }
  //   } else {
  //     if ((addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0) && deliveryType === "DLV") {
  //       return true;
  //     } else {
  //       if (deliveryType === "DLV") {
  //         setOpenErrorSnackbar(true);
  //         setSuccessMessage('Please add Authorized Person to Accept the Deliveries');
  //         return false;
  //       } else {
  //         return true
  //       }
  //     }
  //   }
  // }

  const isValidShipToPartyTab = () => {
    if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0 && customer !== "Government") {
      if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && customer !== "Government") {
        let selected = tradeRegionData.data.filter((data: any) => data.id === addressList[0].tradeLicenseNoId);
        if (selected && selected.length > 0 && selected[0].tradeLicenseNo) {
          return true;
        } else {
          if (customer && customer.toLowerCase() === 'workshop' && deliveryType !== "DLV") {
            return true;
          } else {
            setOpenErrorSnackbar(true);
            setSuccessMessage('Please add Trade License Number');
            return false;
          }
        }
      }
    } else {
      if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0) {
        return true;
      } else {
        if (customer && customer.toLowerCase() === 'workshop' && deliveryType !== "DLV") {
          return true;
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage('Please add Authorized Person to Accept the Deliveries');
          return false;
        }
      }
    }
  }

  const handleopenCloseSubmit = (open?: any) => {
    if (isValidShipToPartyTab()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  const handleChangeDeliveryType = async (e: any) => {
    setDeliveryType(e.target.value);
    let data: any = {
      "id": b2bUserId,
      "deliveryType": e.target.value,
      "updatedBy": b2bUserId,
      "updatedByRole": "user"
    }
    let updatedData: any = await dispatch(updateDeliveryType(data));
    if (updatedData && updatedData.message && updatedData.message.status === 'S') {
      dispatch(getShipToPartyAddress(b2bUserId));
    } else {

    }
  }

  const submitShipToPartyTab = async () => {
    const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
    let data = {
      "b2bUserId": b2bUserId,
    }
    setLoading(true);
    const isSaveAddress: any = await dispatch(submitAddressTab(data));
    if (isSaveAddress && isSaveAddress.message) {
      setLoading(false);

      await setTimeout(() => {
        setSuccessMessage(isSaveAddress.message.message ? isSaveAddress.message.message : 'Customer details saved successfully');
        handleopenCloseSnackbar(true);
      }, 1000);
      handleopenCloseSubmit(false);
      props.setDataUpdated(false);
      if (isSaveAddress && isSaveAddress.data && isSaveAddress.data.status === 'Submitted') {
        loadAddress();
        props.loadDealerDetails(true);
      }
    }
  }

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
      // setSelected("");
    }
  }

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/onboarding');
      window.location.reload();
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // const onClickDeleteAddress = async (shippingAddress: any) => {
  //   handleopenClose(true);
  //   setIsEdited(false);
  //   setSelected("");
  //   var userType = {
  //     "userType": "guestUser"
  //   }
  //   setShippingAddress(shippingAddress);
  // }

  const deleteShippingAddress = async (action: any) => {
    var userType = {
      "userType": "guestUser"
    }

    const result: any = await dispatch(deleteShipToPartyAddress(b2bUserId, shippingId, userType));

    const apiResponse = get(result, 'message', {});
    // console.log("response2", result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status)

    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      handleopenClose(false);
      setOpenSnackbar(true);
      setSuccessMessage(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
    else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setSuccessMessage(apiResponse);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }

    // onClickDeleteAddress(shippingAddress);
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(b2bUserId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
    }
  }, [saveNewAdress]);

  useEffect(() => {
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
    }
  }, [updateAddressData]);

  useEffect(() => {
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadAddress();
  }, [customer])

  const loadAddress = async () => {
    if (customer && customer.toLowerCase() === 'workshop') {
      await dispatch(getDeliveryType());
    }
    await dispatch(getShipToPartyAddress(b2bUserId));
    if (zoneData && zoneData.data && zoneData.data.length > 0) {
    } else {
      await dispatch(getTransportationZone(b2bUserId));
    }
    dispatch(getTradeRegion(b2bUserId));
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
    // dispatch(getDealerDetails(b2bUserId));
    // setdealerStatus(data)
    loadStatusMessage();
  }

  // TO DISPATCH THE USER DETAILS
  const loadStatusMessage = async () => {
    setLoading(true);
    let data: any = await dispatch(getDealerDetails(b2bUserId, 'shiptoparty'));
    if (data && data.data) {
      props.setdealerStatus(data.data.status)
      setDealerDetails(data);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setMasterCompanyBrands(shipToPartyAddressData.data.masterCompanyBrands);
      setAddressList(shipToPartyAddressData.data.addresses);
      setDeliveryType(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : "DLV")
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {

    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      // setSelected("");
    }

  }, [shipToPartyAddressData, getSelectedAddress])

  const handleBackClick = () => {
    if (dataUpdated) {
      // setDeleteMessage('Do you wish to proceed without saving the data?')
      handleopenClose(true);
    } else {
      props.setTabValue(1)
    }
  }


  return (
    <Fragment>
      {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {loading && (<FullscreenLoader />)}
      <Prompt
        when={dataUpdated}
        message='Do you wish to proceed without saving the data?'
      />
      <div className="row">
        {(shipToPartyAddressData && shipToPartyAddressData.err) && (
          <div className="col-12 mt-4">
            <Alert severity="info">Please submit Complete Registration details prior to Ship to Party!</Alert>
          </div>
        )}
        {customer && customer.toLowerCase() === 'workshop' && (
          <div className='col-12 row float-right'>
            <Toolbar>
              <div className="">
                <h6 className="document-content float-left font-bold mr-0 mb-4">Delivery Type{' '}:</h6>
              </div>
              <div className="document-content">
                <FormControl component="fieldset" name="customer-type" className='document-content'>
                  <RadioGroup className="mt-2 ml-1" row value={deliveryType} onChange={handleChangeDeliveryType}>
                    {deliveryTypes && deliveryTypes.data && deliveryTypes.data.length > 0 && deliveryTypes.data[0].deliveryType && deliveryTypes.data[0].deliveryType.map(
                      (delivery: any) => (
                        <FormControlLabel
                          disabled={isDisabled() ? true : false}
                          value={delivery.value}
                          control={<Radio color="primary" />}
                          label={delivery.title}
                          className="ml-2"
                        />
                      )
                    )}

                    {/* <FormControlLabel
            value="Self"
            control={<Radio color="primary" />}
            label="Self-pickup"
            className="ml-2"
          /> */}
                  </RadioGroup>
                </FormControl>
                {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                  if (ele.title === "Self-pickup" && deliveryType === "SELF-PIC") {
                    return (<>
                      <h6 className="dealer-table-content mb-0 px-4 mb-2">{ele.title === "Self-pickup" && deliveryType === "SELF-PIC" ? ele.description : ""}</h6>
                    </>)
                  } else if (ele.value === "DLV") {
                    return (<>
                      <h6 className="dealer-table-content mb-0 px-4 mb-2">{ele.title === "Delivery" && deliveryType !== "SELF-PIC" ? ele.description : ""}</h6>
                    </>)
                  }
                })}
              </div>
            </Toolbar>
          </div>
        )}
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (

          <div className="col-12 row m-0">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 border-bottom py-1 bg-white" key={index}>
              <div className="w-50 d-inline-block mt-2">
                <div className="shipAddTitle">{shipppingAddress && shipppingAddress.dafault ? shipppingAddress && shipppingAddress.companyName : shipppingAddress && shipppingAddress.location} &nbsp;
                  {shipppingAddress && shipppingAddress.dafault && (
                    <Button
                      className="defaultAddressButton cursor-default"
                      size="small"
                      variant="contained"
                      color="secondary"
                    >Head Office</Button>
                  )}
                </div>
                <div className="shipAdd mt-2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                {sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="shipAdd">Contact Telephone Number: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div> : <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>}
                {/* <div className="shipAdd">PO Box: {shipppingAddress.poBox}</div> */}
                <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
              </div>
              {(
                <div className="markDefaultIcon w-50 d-inline-block text-right">
                  <Button
                    className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                    // disabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer'}
                    disabled={isDisabled()}
                    startIcon={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? <EditOutlinedIcon /> : <AddIcon />}
                    onClick={() => {
                      setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                      onClickEditAddress(shipppingAddress.id)
                      setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                    }}><span className="onshiftoparty-font">
                      {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                    </span>
                  </Button>
                  {!shipppingAddress.dafault && !shipppingAddress.transportationZone && !shipppingAddress.shiftToPartyCustomerNumber && <span>
                    <Button
                      onClick={() => { setShippingId(shipppingAddress.id); setOpenDeleteDialog(true) }}
                      disabled={shipppingAddress.shiftToPartyCustomerNumber || shipppingAddress.transportationZone || isDisabled() ? true : false}
                      className={shipppingAddress.shiftToPartyCustomerNumber || shipppingAddress.transportationZone || isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                      startIcon={<DeleteIcon />}
                    > <span className="onshiftoparty-font">Delete</span>
                    </Button>
                  </span>}
                </div>
              )}
            </div>
          </div>
        ))}
        <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
          <div className="col-12 display-flex p-0">
            <div className={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? "col-6 p-0" : "col-6 p-0"}>
              <div className="row m-0">
                <div className='px-3'></div>
                <div className={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? 'row col-6 p-0 customer-type-popup' : 'row col-6 p-0 customer-type-popup'}>
                  <div className="p-0">
                    <p className={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? "mt-2 px-5 customer-type-font" : "mt-3 px-5 customer-type-font"}>Customer Type</p>
                    {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? (
                      <p className="customer-header-font ml-5 mt-2 mb-2 text-capitalize">{sessionStorage.getItem('customerType')}{' '} {sessionStorage.getItem('customerOnboardingType')} User</p>
                    ) : (
                      <p className="cursor-pointer px-5 mt-2" onClick={() => { changeDealerType() }}>
                        <span className="customer-header-font text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                          {sessionStorage.getItem('customerOnboardingType') == 'Fleet' || sessionStorage.getItem('customerOnboardingType') == 'fleet' ? 'Corporate (Fleet)' : sessionStorage.getItem('customerOnboardingType')}</span><KeyboardArrowDownIcon className='customer-font' />{'  '}<span className="customer-sub-header-font">Change Type</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6 onboard-footer float-right">
              <div className="p-3 mr-3">
                <Button variant="contained" className="rounded-pill pl-4 pr-4 w-150p"
                  onClick={() => handleBackClick()}
                >Back</Button>
                <Button variant="contained" color="primary" className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2"
                  disabled={isDisabled()}
                  onClick={() => {
                    handleopenCloseSubmit(true);
                  }}>Submit
                </Button>
              </div>
            </div>
          </div>
        </div>

      </div>

      <SubmitDialog
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            submitShipToPartyTab();
          }
          if (!data) handleopenCloseSubmit(false);
        }} />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          // if (reason !== 'backdropClick') {
          handleClose()
          // }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} firstAddressCheck={firstAddressCheck} deliveryType={deliveryType} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} ref={addressDialogRef} addDealer={false} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            loadAddress();
            handleopenCloseConfirmPopup(false);
          }
        }} />
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openSnackbarForConfirm}
        handleopenClose={() => {
          handleopenCloseSnackbarForConfirm(false)
        }} message={successMessageForUser} />
    </Fragment>
  )
}

export default ShipToPartyCustomer;