/* eslint-disable */
import React, { Dispatch } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import tempKamLogo from "./afg.jpeg"
import { Markup } from 'interweave';
import { get } from 'lodash';
import { DialogTitle, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { createTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import { useDispatch, useSelector } from "react-redux";
import { acceptTermsAndConditions, getTermsAndConditions } from '../../store/actions/termsAndConditions.action';
// import Docxtemplater from 'docxtemplater';
// import htmlToPdf from 'html-pdf';
import moment from "moment-timezone";
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import { htmlToText } from 'html-to-text';
import { getProfile } from '../../store/actions/profile.action';
import { logoutUser } from '../../store/actions/logon.actions';
import { logout } from '../../store/actions/account.actions';
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import { useMsal } from '@azure/msal-react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
// const { htmlToText } = require('html-to-text');

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);



const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgb(108 197 229 / 14%);',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);



const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  bodyText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  pdfContainer: {
    color: "black"
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export default function TermsAndConditionsDialog(props: any) {

  const classes = useStyles();
  const { termsAndConditions }: any = useSelector((state: any) => state.termsAndConditions);
  const termsAndConditionsData = get(termsAndConditions, 'data', []);
  const [expanded, setExpanded] = React.useState<any>();
  const [checked, setCheckbox] = React.useState<any>(false);
  const [isSelected, setSelected] = React.useState<any>([])
  const [pdfUrl, setPdfUrl] = React.useState(null);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const isReadTandC = get(profileDetails, "data.data.customerInfo.isReadTandC", "");
  let sapCustomerId: any = get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const userDispatch = useUserDispatch();
  const { instance } = useMsal();
  const userType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType;

  const dispatch: Dispatch<any> = useDispatch();

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const acceptTandC = async () => {
    let data: any = {
      "isReadTandC": true,
      "tandCDate": moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      },

      "sapCustomerId": sapCustomerId
    }
    let res: any = await dispatch(acceptTermsAndConditions(data));
    if (res && res.message && res.message?.status === 'S') {
      setSuccessMessage(res?.message?.message);
      const b2cUid: any = sessionStorage.getItem('userId');
      const userType: any = sessionStorage.getItem('userType');
      dispatch(getProfile(b2cUid, userType));
      setOpenSnackbar(true);
      props.handleopenClose(false);
    } else {
      // setSuccessMessage(res?.message?.message);
      // setOpenSnackbar(true);
    }
  }

  React.useEffect(() => {
    let data: any = []
    termsAndConditionsData && termsAndConditionsData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }, [termsAndConditionsData])

  React.useEffect(() => {
    setExpanded(false);
  }, []);

  const handleClose = () => {
    let data: any = []
    termsAndConditionsData && termsAndConditionsData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }

  const addTandCToPDF = async (pdfBytes: any) => {
    const pdfDoc = await PDFDocument.load(pdfBytes);

    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);

    const margin = { top: 50, bottom: 50, left: 50, right: 50 };

    // Convert HTML to plain text
    const removeEmptyLines: any = termsAndConditionsData[0]?.Article_Body.replace(/[\r\n]{2,}/g, '\n');
    const plainText = htmlToText(removeEmptyLines, {
      wordwrap: false,
    });

    const replacedText = plainText.replace(/ﬀ/g, 'ff');
    const regex = /(\d+\..*?)(?= \d+\.|$)/gs;
    const parts = replacedText.split(regex);
    // First part (points 1-5)
    const text1 = parts.slice(1, 10).join('');
    // Second part (points 6-9 and remaining text)
    const text2 = parts.slice(11, 16).join('');
    // Second part (points 6-9 and remaining text)
    const text3 = parts.slice(17, 18).join('');
    const text4 = parts.slice(19).join('');

    // Iterate over all pages of the PDF document
    for (let i = 0; i < pdfDoc.getPageCount(); i++) {
      const page = pdfDoc.getPages()[i];
      const { width, height } = page.getSize();

      if (i === 0) {
        // Add text1 to the first page
        page.drawText(text1, {
          x: margin.left,
          y: height - margin.top,
          size: 12,
          font: font,
          color: rgb(0, 0, 0),
          maxWidth: width - margin.left - margin.right,
          lineHeight: 15, // Adjust line height as needed
        });
      } else if (i === 1) {
        // Add text2 to the second page
        page.drawText(text2, {
          x: margin.left,
          y: height - margin.top,
          size: 12,
          font: font,
          color: rgb(0, 0, 0),
          maxWidth: width - margin.left - margin.right,
          lineHeight: 15, // Adjust line height as needed
        });
      } else if (i === 2) {
        // Add text3 to the third page
        page.drawText(text3, {
          x: margin.left,
          y: height - margin.top,
          size: 12,
          font: font,
          color: rgb(0, 0, 0),
          maxWidth: width - margin.left - margin.right,
          lineHeight: 15, // Adjust line height as needed
        });
      } else if (i === 3) {
        // Add text4 to the fourth page
        page.drawText(text4, {
          x: margin.left,
          y: height - margin.top,
          size: 12,
          font: font,
          color: rgb(0, 0, 0),
          maxWidth: width - margin.left - margin.right,
          lineHeight: 15, // Adjust line height as needed
        });
      }

      // // Add content to each page
      // page.drawText(replacedText, {
      //   x: margin.left,
      //   y: height - margin.top,
      //   size: 12,
      //   font: font,
      //   color: rgb(0, 0, 0),
      //   maxWidth: width - margin.left - margin.right,
      //   lineHeight: 15, // Adjust line height as needed
      // });
    }

    // Serialize the modified PDF
    return await pdfDoc.save();
  };


  const modifyAndSavePDF = async (pdfBytes: any) => {
    try {
      const modifiedPDFBytes = await addTandCToPDF(pdfBytes);
      // Save the modified PDF
      const blob = new Blob([modifiedPDFBytes], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'modifiedPDF.pdf';
      link.click();
    } catch (error) {
      console.error('Error modifying PDF:', error);
    }
  };


  const fetchAndModifyPDF = async () => {
    try {
      // Fetch the PDF file
      const response = await fetch('/portalTermsOfUse.pdf');
      const pdfBytes = await response.arrayBuffer();

      // Modify and save the PDF
      await modifyAndSavePDF(pdfBytes);
    } catch (error) {
      console.error('Error fetching or modifying PDF:', error);
    }
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  const downloadPDF = ()=>{
    props.handleLoader(true);
    const container = document.createElement('div');
    container.classList.add("pdfContainer");
    container.style.width = '1100px';
    container.style.padding = "20px";
    // Append the HTML content from data into the container
    if (customerTierType === "Workshop" || customerTierType === "tpcdealer") {
      termsAndConditionsData.forEach((item: any) => {
        const div = document.createElement('div');
        div.innerHTML = JSON.parse(JSON.stringify(item.Article_Body));
        container.appendChild(div);
      });
    } else {
      const div = document.createElement('div');
      div.innerHTML = JSON.parse(JSON.stringify(termsAndConditionsData[0].Article_Body));
      container.appendChild(div);
    }
    

    // Append the container to the document body
    document.body.appendChild(container);
   
    html2canvas(container, { useCORS: true, allowTaint: true, scrollY: 0 , scale:3}).then((canvas) => {
      const image = { type: 'jpeg', quality: 1 };
      const margin = [0.5, 0.5];
      let imgWidth = 8.5;
      let pageHeight = 10;

      let innerPageWidth = imgWidth - margin[0] * 2;
      let innerPageHeight = pageHeight - margin[1] * 2;

      // Calculate the number of pages.
      let pxFullHeight = canvas.height;
      let pxPageHeight = Math.floor(canvas.width * (pageHeight / imgWidth));
      let nPages = Math.ceil(pxFullHeight / pxPageHeight);

      // Define pageHeight separately so it can be trimmed on the final page.
      pageHeight = innerPageHeight;

      // Create a one-page canvas to split up the full image.
      let pageCanvas = document.createElement('canvas');
      let pageCtx:any = pageCanvas.getContext('2d');
      pageCanvas.width = canvas.width;
      pageCanvas.height = pxPageHeight;

      // Initialize the PDF.
      let pdf = new jsPDF('p', 'in', [8.5, 11]);

      for (let page = 0; page < nPages; page++) {
        // Trim the final page to reduce file size.
        if (page === nPages - 1 && pxFullHeight % pxPageHeight !== 0) {
          pageCanvas.height = pxFullHeight % pxPageHeight;
          pageHeight = (pageCanvas.height * innerPageWidth) / pageCanvas.width;
        }

        // Display the page.
        let w = pageCanvas.width;
        let h = pageCanvas.height;
        pageCtx.fillStyle = 'white';
        pageCtx.fillRect(0, 0, w, h);
        pageCtx.drawImage(canvas, 0, page * pxPageHeight, w, h, 0, 0, w, h);

        // Add the page to the PDF.
        if (page > 0) pdf.addPage();
        let imgData = pageCanvas.toDataURL('image/' + image.type, image.quality);
        pdf.addImage(imgData, image.type, margin[1], margin[0], innerPageWidth, pageHeight);
      }

      pdf.save('Terms & Conditions.pdf');
      // Remove the container from the DOM after rendering
      document.body.removeChild(container);
      props.handleLoader(false);
    })
  }

  return (
    <div className="float-left">
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
          setOpenSnackbarType("");
        }}
      />
      {termsAndConditionsData && termsAndConditionsData.length > 0 &&
        <Dialog
          open={props.open ? true : false}
          disableBackdropClick={true}
          onClose={() => { props.handleopenClose(false); setExpanded(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="md"
          disableEscapeKeyDown >
          {isReadTandC === false && (
            <DialogTitle className="pb-0" id="form-dialog-title">
              <div className="page-title">Terms & Conditions
              </div>
            </DialogTitle>
          )}

          <DialogContent className='tandc'>
            <Grid container>
              <Grid item xs={1}>
                <img src={tempKamLogo} alt="logo" width="70px" height="70px" />
              </Grid>
              <Grid item xs={11}>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    {(customerTierType === 'Workshop' || customerTierType === 'tpcdealer') ? (<>
                      {termsAndConditionsData && termsAndConditionsData.map((data: any, i: any) => (
                        <Accordion square expanded={expanded === data.name && isSelected[i] === false} onChange={handleChange(data.name)}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                            // expandIcon={<ExpandMoreIcon />}
                            onClick={() => {
                              let data: any = [...isSelected]
                              let dataActual: any = []
                              data.forEach((ele: any, i2: any) => {
                                if (i === i2) {
                                  data[i2] = !data[i]
                                } else if (i !== i2) {
                                  data[i2] = true
                                }
                              })
                              setSelected(data)
                            }}
                          >
                            <span className="" style={{ fontSize: "18px" }}>{isSelected[i] ? "+" : "-"}</span>  <h6 className="ml-2 font-weight-600"> {data.title}</h6>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className={`mt-3 ${classes.bodyText} tandc`}>
                              <Markup content={data.Article_Body} />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>) : (
                      <>
                        {termsAndConditionsData && termsAndConditionsData.map((data: any, i: any) => (
                          <>
                            { data.title !== 'Credit Card Payments'  &&  (
                              <Accordion square expanded={expanded === data.name && isSelected[i] === false} onChange={handleChange(data.name)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                  // expandIcon={<ExpandMoreIcon />}
                                  onClick={() => {
                                    let data: any = [...isSelected]
                                    let dataActual: any = []
                                    data.forEach((ele: any, i2: any) => {
                                      if (i === i2) {
                                        data[i2] = !data[i]
                                      } else if (i !== i2) {
                                        data[i2] = true
                                      }
                                    })
                                    setSelected(data)
                                  }}
                                >
                                  <span className="" style={{ fontSize: "18px" }}>{isSelected[i] ? "+" : "-"}</span>  <h6 className="ml-2 font-weight-600"> {data.title}</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className={`mt-3 ${classes.bodyText} tandc`}>
                                    <Markup content={data.Article_Body} />
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                            )
                           }
                          </>
                        ))}
                      </>
                    )}
                  </DialogContentText>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            {isReadTandC === false && userType !== "User" ? (
              <div className='row col-12 m-0'>
                <div className='col-4 row-space-start'>
                  <Button variant="contained" color="primary" className="submit-button-chooseCustomer" onClick={downloadPDF}>Export to PDF</Button>
                </div>
                <div className='col-8 row-space-end'>
                  <span className="pr-2 mb-2 pl-0 pt-3 pb-2 flex-end">
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        checked={checked}
                        onChange={(e) => { setCheckbox(e.target.checked) }}
                        className="checkbox" />
                    </ThemeProvider>
                    <span className="dealer-table-content">By submitting you are accepting this Terms & Conditions
                    </span>
                  </span>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => acceptTandC()}
                    disabled={checked === false}
                    className="submit-button-chooseCustomer"
                  >Submit</Button>
                  <Button variant="contained" className="goToLogin ml-2"
                    onClick={() => { callLogout(); }}
                  >Logout</Button>
                </div>
              </div>
            ) : (
              <Button className={`mr-4 ${classes.cancel}`} onClick={() => { props.handleopenClose(false); setExpanded(false); handleClose() }}>
                Close
              </Button>
            )
            }
          </DialogActions>
        </Dialog>
      }
    </div >
  );
}
