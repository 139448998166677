import React, { Fragment, Dispatch, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import ImportDialog from "../OesOemMapping/ImportProductTeamExcel";
import { makeStyles } from '@material-ui/core/styles';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { FULLY_CANCELLEED } from '../../constants/orderEnquiryConstants';
import _, { get } from "lodash";
import { getEnquiriesList, getFSEEnquiriesList, getFSEList, getOrderEnquiryStatus } from '../../store/actions/orderEnquiry.action';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { styled } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import "./productTeam.css";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import moment from "moment-timezone";
import { getNewBrand, getNewlyAddedBrand } from "../../common/components/util";
import { ALL_STATUS } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";
import { requestSpecialPriceService } from "../../services/requestSpecialPrice.service";
import ChooseCustomerDialog from "../CreateEnquiry/ChooseCustomerDialog";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import { useCallback } from "react";
import { productTeamService } from "../../services/oemOesproductTeamSearch.service";
import { getPartList } from "../../store/actions/orderHistory.actions";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from '@material-ui/icons/Close';
import AddAlternatePart from "./AddAlternatePart";
import { verifyEnquiryQueueCheck } from "../../store/actions/createUpdateEnquiry.actions";
import { oesOemMapActions } from "../../store/actions/oemOesProductTeam.action";
import { MenuItem } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteDialogProductTeamPortal from "../../common/components/DeleteDialogProductTeamPortal";
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from "@material-ui/icons/GetApp";
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupId from "./GroupId";
import { Autocomplete } from "@material-ui/lab";
import { createUpdateEnquiryService } from "../../services/createUpdateEnquiry.service ";
import CancelDialog from "../../common/components/cancelDialog";
const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 84
  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70
  },
  actiontTbleHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70,
    borderBottom: 0,
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10
  },
  createOrderEnqButton: {
    backgroundColor: '#1A9FE0',
    width: 190,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
  },
  testDialog: {
    maxWidth: "850px !important",
    width: "850px ",
    margin: "0 auto !important"
  }
}));

const CUSTOMTAB_INACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#999999",
    fontWeight: 800,
    paddingLeft: 0,
    fontFamily: "Nunito",

    paddingBottom: 4,
    height: "55px",
  },
})(Tab);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const ProductTeamDashboard: React.FC = () => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();

  interface Part {
    Secondnary_Referece_Id: string;
    OES_Aftermarket_Brand: string;
    Cross_Ref_Car_Make_Brand_OEM: string;
    Product_Group_Description: string;
    type: string;
    Part_Number: string;
    updated_by: string;
    emp_id: string;
    updated_date: string;
    afterMarket_number: string;
    id: string;
    _ts: number
  }

  const [filteredParts, setFilteredParts] = useState<Part[]>([]);
  const [createdByME, setcreatedByME] = React.useState<any>(false);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [searchText, setsearchText] = useState<any>('');
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [alternates, setAlternates] = useState<AlternatePart[]>([]);
  const [products, setproducts] = React.useState<any>([]);
  const [fseName, setFseName] = useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const [open, setOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const currentTier = sessionStorage.getItem('currentTier');
  const [openChooseCustomerDialog, setOpenChooseCustomerDialog] = React.useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>('');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [isAddAltClicked, setAddAltClicked] = useState(false);
  const [isAddMoreClicked, setAddMoreClicked] = useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [searchGroupIdText, setSearchGroupIdText] = useState<string>('');
  const [groupIdValue, setGroupIdValue] = useState<any[]>([]);
  const [filteredGroupIds, setFilteredGroupIds] = useState<any[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const { getGroupIdListData, updateSearchPart } = useSelector((state: any) => state.oesOemMapProduct);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isGroupIdClicked, setIsGroupIdClicked] = useState(false);
  const [groupIdValueForAddMore, setGroupIdValueForAddMore] = useState("")
  const [partNoForAddMore, setPartNoForAddMore] = useState("")
  const [searchDataState, setSearchDataState] = useState<searchDataState | null>(null);
  const [relatedRecords, setRelatedRecords] = useState<any[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentValueOfRow, setCurrentValueOfRow] = useState([]);
  const [suggestions, setSuggestions] = useState<any>([]);
  const [replaceGrpIdSelected, setReplaceGrpIdSelected] = useState(false);
  const [selectedType2, setSelectedType2] = useState("");
  const [searchGrpValue, setSearchGrpValue] = useState<any>('');
  const [newGrpIdToReplace, setNewGrpIdToReplace] = useState<any>([]);
  const [openTypeDropdown, setOpenTypeDropdown] = useState(false);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('md');
  const [altData, setAltData] = useState([{
    Part_Number: '',
    OES_Aftermarket_Brand: '',
    Cross_Ref_Car_Make_Brand_OEM: "",
    Secondnary_Referece_Id: searchGrpValue,
    Product_Group_Description: '',
    afterMarket_number: "",
    type: selectedType2
  }])

  const [formData, setFormData] = useState([{
    Part_Number: '',
    OES_Aftermarket_Brand: '',
    Cross_Ref_Car_Make_Brand_OEM: 'AfterMarket',
    Secondnary_Referece_Id: searchGrpValue,
    Product_Group_Description: '',
    type: "AfterMarket",
  }]);

  const createDefaultPart = (): Part => ({
    updated_date: "",
    Part_Number: "",
    OES_Aftermarket_Brand: "",
    type: "",
    Product_Group_Description: "",
    Secondnary_Referece_Id: "",
    updated_by: "",
    emp_id: "",
    Cross_Ref_Car_Make_Brand_OEM: "",
    afterMarket_number: "",
    id: "",
    _ts: 0
  });

  const fetchFilteredParts = () => {
    const dummyData: Part[] = [
      createDefaultPart(),
      createDefaultPart(),
    ];
    setFilteredParts(dummyData);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };
  const isShowSPR = () => {
    if ((currentTier && currentTier.toLowerCase() === 'gold') || (currentTier && currentTier.toLowerCase() === 'platinum') || (currentTier && currentTier.toLowerCase() === 'elite')) {
      return true;
    } else {
      return false;
    }
  }



  const menuItemStyle = {
    width: "550px"
  };


  const showCancel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CANCEL_ENQUIRY) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const SubEnquiries = (props: { subEnq: any, enquiry: any }) => {
    const { subEnq } = props;

    let estimatedTotal;

    if (!!subEnq.estimatedTotal) {
      if (typeof subEnq.estimatedTotal === "string" && subEnq.estimatedTotal.toLowerCase() !== "nan") {
        estimatedTotal = subEnq.estimatedTotal;
      }
      else {
        estimatedTotal = 0;
      }

      if (typeof subEnq.estimatedTotal === "number") {
        estimatedTotal = subEnq.estimatedTotal;
      }
    }
  }

  const showCreateChangeEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  const showImportExcel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);

  const showGroupIdForm = sessionStorage.getItem('userType') === 'FSE' ? true : ""

  const handleClickOpen = () => {
    setOpen(true);
    setAddAltClicked(false);
  };

  const handleAddMoreOpen = () => {
    setOpen(false);
    setAddMoreClicked(true);
    setIsEditing(false);
  };

  const [isSaving, setIsSaving] = useState(false);
  const showConfermation = () => {
    setOpenCancelDialog(true);
  }

  const handleClosePopUp = () => {
    setAlternates([]);
    setAddAltClicked(false);
    setAddMoreClicked(false);
    resetFields();
    setSelectedGroupId("");
    setReplaceGrpIdSelected(false);
    setSuggestions([]);
    setReplaceGrpIdSelected(false);
    setSearchGrpValue('');
    setSelectedType2("");
  };



  const handleopenCloseCancelDialog = (open?: boolean) => {
    setOpenCancelDialog(false);
  };

  const handleCancelConfirmation = (confirmed: boolean) => {
    if (confirmed) {
      setOpen(false);  // Close both the first popup and the cancel dialog
      handleClosePopUp();
    }
    setOpenCancelDialog(false);  // Close cancel dialog regardless
  };


  const handleCloseAlternate = () => {
    setAddAltClicked(false);
    // setAlternates([]);
  }


  const onSearchButtonClick = async () => {
    if (!searchText || searchText.trim() === '') {
      return;
    }

    try {
      const page = 1;
      dispatch(getPartList(createdByME, searchText));
      const result = await productTeamService.getSearchList(createdByME, searchText, page);

      const data = result.data;

      const matchedRecordIndex = data.findIndex((item: any) =>
        item.Part_Number && typeof item.Part_Number === 'string' && item.Part_Number.toLowerCase() === searchText.toLowerCase()
      );

      if (matchedRecordIndex !== -1) {
        const matchedRecord = data[matchedRecordIndex];
        console.log(`Matched Part Number: ${matchedRecord.Part_Number}`);

        data.splice(matchedRecordIndex, 1);

        setSearchDataState(matchedRecord);
        setGroupIdValueForAddMore(matchedRecord.Secondnary_Referece_Id);
        setPartNoForAddMore(searchText);

        setFilteredParts([matchedRecord, ...data]);
      } else {
        setFilteredParts([]);
        setRelatedRecords([]);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      showErrorMessage(error);
      setFilteredParts([]);
      setRelatedRecords([]);
    }
  };

  const resetFields = () => {
    setFormData([{
      Part_Number: '',
      OES_Aftermarket_Brand: '',
      Cross_Ref_Car_Make_Brand_OEM: 'AfterMarket',
      Secondnary_Referece_Id: "",
      Product_Group_Description: '',
      type: "AfterMarket"
    }]);

    setAltData([{
      Part_Number: '',
      OES_Aftermarket_Brand: '',
      Cross_Ref_Car_Make_Brand_OEM: '',
      Secondnary_Referece_Id: "",
      Product_Group_Description: '',
      afterMarket_number: "",
      type: ""
    }]);
  };

  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    let data = [...formData]
    switch (type) {
      case "Part_Number":
        data[index].Part_Number = e.target.value
        break;
      case "OES_Aftermarket_Brand":
        data[index].OES_Aftermarket_Brand = e.target.value;
        break;
      case "Cross_Ref_Car_Make_Brand_OEM":
        data[index].Cross_Ref_Car_Make_Brand_OEM = "AfterMarket";
        break;
      case "Secondnary_Referece_Id":
        data[index].Secondnary_Referece_Id = selectedCustomerId;
        break;
      case "Product_Group_Description":
        data[index].Product_Group_Description = e.target.value;
        break;
      default: ;
    }
    setFormData(data);

    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      }
      else {
        errorSetterName(false);
      }
    }
  };

  const handleAltInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    let data = [...altData];
    // Update the appropriate field based on input type
    switch (type) {
      case "Part_Number":
        data[index].Part_Number = e.target.value;
        break;
      case "OES_Aftermarket_Brand":
        data[index].OES_Aftermarket_Brand = e.target.value;
        break;
      case "type":
        data[index].type = selectedType2;
        break;
      case "Secondnary_Referece_Id":
        data[index].Secondnary_Referece_Id = selectedCustomerId;
        break;
      case "Product_Group_Description":
        data[index].Product_Group_Description = e.target.value;
        break;
      default:
        break;
    }
    setAltData(data);;

    // Update the state with the modified data
    // setAltData(data);

    // Handle error state based on validation
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      } else {
        errorSetterName(false);
      }
    }
  };


  function areAllKeysEmpty(array: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (
        (item.altItemMap.Part_Number !== "" && item.altItemMap.Part_Number !== null && item.altItemMap.Part_Number !== undefined) ||
        (item.altItemMap.OES_Aftermarket_Brand !== "" && item.altItemMap.OES_Aftermarket_Brand !== null && item.altItemMap.OES_Aftermarket_Brand !== undefined) ||
        (item.altItemMap.Cross_Ref_Car_Make_Brand_OEM !== "" && item.altItemMap.Cross_Ref_Car_Make_Brand_OEM !== null && item.altItemMap.Cross_Ref_Car_Make_Brand_OEM !== undefined) ||
        (item.altItemMap.Secondnary_Referece_Id !== "" && item.altItemMap.Secondnary_Referece_Id !== null && item.altItemMap.Secondnary_Referece_Id !== undefined) ||
        (item.altItemMap.Product_Group_Description !== "" && item.altItemMap.Product_Group_Description !== null && item.altItemMap.Product_Group_Description !== undefined)
      ) {
        return false;
      }
    }
    return true;
  }

  function areAllKeysFilled(array: any) {
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (
        item.altItemMap.Part_Number === "" ||
        item.altItemMap.OES_Aftermarket_Brand === "" ||
        item.altItemMap.Cross_Ref_Car_Make_Brand_OEM === "" ||
        item.altItemMap.Secondnary_Referece_Id === "" ||
        item.altItemMap.Product_Group_Description
      ) {
        return false;
      }
    }
    return true;
  }

  const isValidFields = () => {
    let isValid = true;
    let altFormData = [...formData]
    let allow = true;
    altFormData.map((altItemMap: any) => {
      altItemMap.error = null;
      if (!altItemMap.Part_Number || !altItemMap.OES_Aftermarket_Brand || !altItemMap.Cross_Ref_Car_Make_Brand_OEM || !altItemMap.Product_Group_Description) {
        isValid = false;
      }
      // else if (altItemMap.Part_Number || altItemMap.OES_Aftermarket_Brand || altItemMap.Cross_Ref_Car_Make_Brand_OEM || altItemMap.Secondnary_Referece_Id || altItemMap.Product_Group_Description) {
      //   if (!altItemMap.Part_Number || !altItemMap.OES_Aftermarket_Brand || !altItemMap.Cross_Ref_Car_Make_Brand_OEM || !altItemMap.Secondnary_Referece_Id || !altItemMap.Product_Group_Description) {
      //     isValid = false;
      //   }
      // } else {
      //   const allKeysEmpty = areAllKeysEmpty(altFormData);
      //   if (!allKeysEmpty) {
      //     const allKeysFilled = areAllKeysFilled(altFormData);
      //     if (!allKeysFilled) {
      //       isValid = false;
      //     }
      //   }
      // }
    });
    return isValid;
  }

  const handleAddAlternateClick = () => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    if (isValidFields()) {
      setInCompleteFileds(false);

      return true;
    } else {
      setInCompleteFileds(true);
      showErrorMessage("Please fill all Mandatory fields.");
      handleopenCloseErrorSnackbar(true);
      return false;
    }
  }

  const handleAddAddAlternatePartClick = () => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    if (isValidFieldsAlternatePart()) {
      setInCompleteFileds(false);
      return true;
    } else {
      setInCompleteFileds(true);
      showErrorMessage("Please fill all Mandatory fields.");
      handleopenCloseErrorSnackbar(true);
      return false;
    }
  }

  const getGroupIdPartialList = async (groupdId?: any) => {
    setReplaceGrpIdSelected(false);
    const results = await productTeamService.getGroupIdPaginationList('createdByME', '', 0, 1000, groupdId);
    if (results?.data?.allRecords) {
      let suggestions = [];
      if (results?.data?.allRecords?.length > 0) {//Secondnary_Referece_Id
        suggestions = results?.data?.allRecords.map((e: any) => {
          return e.Secondnary_Referece_Id;
        })
      }
      setSuggestions(suggestions);
    }
  };
  useEffect(() => {
    const suggestions: any = [];
    setSuggestions(suggestions);
  }, []);

  // ADD NEW ROW ONCLICK OF ADD MORE
  const addNewPartRow = () => {
    setAddAltClicked(true)
    setIsEditing(false)
    let altFormData = [...formData]
    let allow = true;
    if (isValidFields() && altFormData.length <= 1) {
      altFormData.map((altItemMap: any) => {
        altItemMap.error = null;
        if (!altItemMap.Part_Number || !altItemMap.OES_Aftermarket_Brand || !altItemMap.Cross_Ref_Car_Make_Brand_OEM || !altItemMap.Product_Group_Description) {
          allow = false;
        }
      });
      if (allow) {
        setAddAltClicked(true)
        setFormData(altFormData)
        onSearchButtonClick()
        setInCompleteFileds(false);
      } else {
        setInCompleteFileds(true);
      }
    } else {
      setInCompleteFileds(true);
      handleopenCloseErrorSnackbar(true);
      setAddAltClicked(false)
      showErrorMessage("Please fill all mandatory fields in the first section.");
    }
    return allow;
  }

  const isValidFieldsAlternatePart = () => {
    let isValid = true;
    let altFormData = [...altData]
    let allow = true;
    altFormData.map((altItemMap: any) => {
      // altItemMap.error = null;
      if (!altItemMap.Part_Number || !altItemMap.OES_Aftermarket_Brand || !altItemMap.Cross_Ref_Car_Make_Brand_OEM || !altItemMap.Product_Group_Description) {
        isValid = false;
      }
    });
    return isValid;
  }

  function isValidDocumentTab() {
    let isValid = true;
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);

    // Check if all fields in the first section are filled
    const isFirstSectionValid = formData.every(partItem =>
      partItem.Part_Number && partItem.OES_Aftermarket_Brand && partItem.Cross_Ref_Car_Make_Brand_OEM &&
      partItem.Secondnary_Referece_Id && partItem.Product_Group_Description
    );


    if (!isFirstSectionValid) {
      setInCompleteFileds(true);
      showErrorMessage("Please fill all mandatory fields in the first section.");
      handleopenCloseErrorSnackbar(true);
      return false;
    }

    // If first section is valid, validate alternate part data if applicable
    if (isAddAltClicked) {
      const isAltSectionValid = altData.every(altItem =>
        altItem.Part_Number && altItem.OES_Aftermarket_Brand && altItem.Cross_Ref_Car_Make_Brand_OEM &&
        altItem.Secondnary_Referece_Id && altItem.Product_Group_Description
      );

      if (!isAltSectionValid) {
        setInCompleteFileds(true);
        showErrorMessage("Please fill all mandatory fields in the alternate parts section.");
        handleopenCloseErrorSnackbar(true);
        return false;
      }
    }

    // If both sections are valid
    setInCompleteFileds(false);
    return true;
  }

  //////// Allows new alternate
  const allowNewRow = () => {
    let allow = true;
    return allow;
  }

  // FOR DELETE API CALL FOR : INDIVISUAL PART 
  const deleteAPICall = async () => {
    let altFormData: any = currentValueOfRow
    let responseData: any = await dispatch(oesOemMapActions.deletePart(altFormData))
    if (responseData && responseData.message && responseData.message.message && responseData.message.status === "E") {
      showErrorMessage(responseData && responseData.error);
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
      handleopenCloseDeleteDialog(false);
    } else if (responseData && responseData.message && responseData.message.message && responseData.message.status === "S") {
      setSuccessMessage(responseData && responseData.message && responseData.message.message);
      onSearchButtonClick()
      handleopenCloseSnackbar(true);
      setLoading(false);
      handleopenCloseDeleteDialog(false);
    }
  }

  interface AlternatePart {
    partNo: any;
    brand: any;
    type: any;
    groupId: any;
    description: any;
  }

  interface searchDataState {
    index: any,
    OES_Aftermarket_Brand: any
  }

  ///////////////// Searchable Dropdown

  useEffect(() => {

    const groupIdList = async () => {
      const groupId = await productTeamService.getGroupIdPaginationList('createdByME', '', 1, 10);
      // setGroupIdValue(groupId.data);
      // setFilteredGroupIds(groupId.data);
      setGroupIdValue(groupId?.data?.allRecords);
      setFilteredGroupIds(groupId?.data?.allRecords);
    };
    groupIdList();

    if (getGroupIdListData && getGroupIdListData.data) {
      setGroupIdValue(getGroupIdListData.data);
      setFilteredGroupIds(getGroupIdListData.data);
    }
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSearchGroupId = (searchText: string) => {
    setSearchGroupIdText(searchText);
    if (searchText) {
      const filtered = groupIdValue.filter((groupId) =>
        groupId.Secondnary_Referece_Id.toLowerCase().includes(searchText.toLowerCase())
      );
      setFilteredGroupIds(filtered);

    } else {
      setFilteredGroupIds(groupIdValue);
    }
  };

  const handleGroupIdSelection = (groupId: string) => {
    setSelectedGroupId(groupId);
    setAnchorEl(null);
  };

  const handleopenCloseDeleteDialog = async (open?: any, rowDataToDelete?: any) => {
    setCurrentValueOfRow(rowDataToDelete)
    setOpenDeleteDialog(open ? true : false);
    if (openDeleteDialog === true && rowDataToDelete) {
      let altFormData = {
        "Secondnary_Referece_Id": rowDataToDelete.Secondnary_Referece_Id,
        "OES_Aftermarket_Brand": rowDataToDelete.OES_Aftermarket_Brand,
        // "Cross_Ref_Car_Make_Brand_OEM": rowDataToDelete.Cross_Ref_Car_Make_Brand_OEM,
        "Product_Group_Description": rowDataToDelete.Product_Group_Description,
        "Part_Number": rowDataToDelete.Part_Number,
        "type": rowDataToDelete.type,
        "id": rowDataToDelete.id
      };
    }
  };


  ////////////// Edit Flow

  const handleEditPart = (rowData: Part) => {
    setAddMoreClicked(true);
    setIsEditing(true);
    // Prepare the form data, ensuring to set the Cross_Ref_Car_Make_Brand_OEM correctly
    let altFormData: any = [{
      Part_Number: rowData.Part_Number,
      OES_Aftermarket_Brand: rowData.OES_Aftermarket_Brand,
      type: rowData.type === "afterMarket" || rowData.type === "AfterMarket" ? "AfterMarket" : "OEM",
      // Cross_Ref_Car_Make_Brand_OEM: rowData.Cross_Ref_Car_Make_Brand_OEM, // This will be set in the dropdown
      Secondnary_Referece_Id: rowData.Secondnary_Referece_Id,
      Product_Group_Description: rowData.Product_Group_Description,
      id: rowData.id,
      afterMarket_number: rowData.Part_Number,
    }];

    setAltData(altFormData);
    setInCompleteFileds(false);
    setSelectedType2(rowData.type);
  }


  interface searchDataState {
    Cross_Ref_Car_Make_Brand_OEM: any,
    type: any
  }

  const handleSave = async () => {
    setIsSaving(true); // Indicate that save is in progress
    if (handleAddAlternateClick()) {
      setLoading(true);

      // Update form data with the selected type and secondary reference ID
      let updatedFormData = formData.map(item => ({
        ...item,
        Part_Number: item.Part_Number.trim(),
        OES_Aftermarket_Brand: item.OES_Aftermarket_Brand.trim(),
        Product_Group_Description: item.Product_Group_Description.trim(),
        type: "afterMarket",
        Secondnary_Referece_Id: searchGrpValue,
      }));

      // Update alt data and conditionally add or omit afterMarket_number based on type
      let updatedAltData = altData.map((item, index) => {
        let updatedItem = {
          ...item,
          Part_Number: item.Part_Number.trim(),
          OES_Aftermarket_Brand: item.OES_Aftermarket_Brand.trim(),
          Product_Group_Description: item.Product_Group_Description.trim(),
          type: selectedType2,
          Secondnary_Referece_Id: searchGrpValue,
        };

        // Only add afterMarket_number if type is "OEM"
        if (selectedType2 === "OEM") {
          updatedItem.afterMarket_number = updatedFormData[index]?.Part_Number;
        }

        return updatedItem;
      });

      // Validate form data and alternate data
      const isFormDataValid = updatedFormData.every(item =>
        item.Part_Number.trim() && item.OES_Aftermarket_Brand.trim() && item.type && item.Product_Group_Description.trim()
      );

      const isAltDataValid = updatedAltData.every(item =>
        item.Part_Number && item.OES_Aftermarket_Brand && item.type && item.Product_Group_Description
      );

      // Handle form data and alternate data validation
      if (isFormDataValid && isAddAltClicked && !isAltDataValid) {
        handleAddAddAlternatePartClick();
        setInCompleteFileds(true);
        showErrorMessage("Please fill all Mandatory fields.");
        setLoading(false);
        setIsSaving(false); // End save operation
        return;
      }

      if (
        (updatedFormData && updatedFormData[0]?.Part_Number?.toLowerCase()) ===
        (updatedAltData && updatedAltData[0]?.Part_Number?.toLowerCase())
      ) {
        if (!isFormDataValid || (isAddAltClicked && !isAltDataValid)) {
          setLoading(false);
          handleAddAddAlternatePartClick();
          setInCompleteFileds(true);
          showErrorMessage("Please remove blank spaces.");
          setIsSaving(false); // End save operation
          return;
        }
        handleAddAddAlternatePartClick();
        setInCompleteFileds(true);
        showErrorMessage("Part number cannot be same.");
        setLoading(false);
        setIsSaving(false); // End save operation
      } else {
        if (!isFormDataValid || (isAddAltClicked && !isAltDataValid)) {
          setLoading(false);
          handleAddAddAlternatePartClick();
          setInCompleteFileds(true);
          showErrorMessage("Please remove blank spaces.");
          setIsSaving(false); // End save operation
          return;
        }

        // Prepare the payload
        let payload = updatedFormData;
        if (isAddAltClicked && isAltDataValid) {
          payload = [...updatedFormData, ...updatedAltData];
        }
        const cleanPayload = (payload: any) => {
          return payload.map((item: any) =>
            Object.fromEntries(
              Object.entries(item).filter(([key, value]) => value !== null && value !== undefined)
            )
          );
        };

        // Clean the updated payload
        const cleanedPayload = cleanPayload(payload);

        let responseData: any = await dispatch(oesOemMapActions.addNewParts(cleanedPayload));
        if (responseData && responseData.message && responseData.message.message && responseData.message.message.status === "E") {
          showErrorMessage(responseData && responseData.error);
          handleopenCloseErrorSnackbar(true);
          setLoading(false);
          setIsSaving(false); // End save operation
        } else {
          setSuccessMessage(responseData && responseData.message && responseData.message.message);
          onSearchButtonClick();

          // Close both popups after save is successful
          setOpen(false);  // Close main popup
          setOpenCancelDialog(false);  // Close confirmation popup
          setLoading(false);
          setIsSaving(false);  // End save operation
          setFormData([]);
          setAltData([]);
          handleopenCloseSnackbar(true);
          resetFields();
          handleClosePopUp();
          setSelectedType2("");
        }
      }
    } else {
      setLoading(false);
      setIsSaving(false); // End save operation
    }
  };



  const handleAddMoreSave = async (id: any) => {
    setAddAltClicked(true);
    let updatedAltData = altData.map((item, index) => {
      let updatedItem: any = {
        ...item,
        Part_Number: item.Part_Number.trim(),
        OES_Aftermarket_Brand: item.OES_Aftermarket_Brand.trim(),
        Product_Group_Description: item.Product_Group_Description.trim(),
        type: selectedType2,
        Secondnary_Referece_Id: item.Secondnary_Referece_Id ? item.Secondnary_Referece_Id : groupIdValueForAddMore,
      };

      if (!isEditing && selectedType2 === "OEM") {
        updatedItem.afterMarket_number = partNoForAddMore;
      }

      return updatedItem;
    });

    const isFormDataValid = updatedAltData.every(item =>
      item.Part_Number && item.OES_Aftermarket_Brand && item.type && item.Product_Group_Description
    );
    if (isFormDataValid == false) {
      handleAddAddAlternatePartClick()
      setInCompleteFileds(true);
      showErrorMessage("Please fill all Mandatory fields.");
    }

    let responseData: any;

    if (isFormDataValid) {
      if (!isEditing) {
        responseData = await dispatch(oesOemMapActions.addNewParts(updatedAltData[0]));
      } else {
        let idArray = altData.map((item: any) => item.id);
        const updatePayload = updatedAltData.map((item) => {
          const { afterMarket_number, ...rest } = item;
          return rest;
        });

        responseData = await dispatch(oesOemMapActions.updateSearchPart(updatePayload[0], idArray));
      }

      if (responseData && responseData.message && responseData.message.status === "E") {
        showErrorMessage(responseData.error);
        handleopenCloseErrorSnackbar(true);
        setLoading(false);
      } else {
        setSuccessMessage(responseData?.message?.message);
        setOpenCancelDialog(false);
        onSearchButtonClick();
        setLoading(false);
        setFormData([]);
        setAltData([]);
        handleopenCloseSnackbar(true);
        resetFields();
        handleClosePopUp();
      }
    }
  };

  const openGroupIdPage = () => {
    setIsGroupIdClicked(true)
  }

  const goBack = () => {
    setIsGroupIdClicked(false);
  };


  /////////////////////////////////////// FSE Login \\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\

  const [userType, setUserType] = React.useState<any>("");
  const isFSEDisabled = userType === "FSE" || userType === "kam";

  useEffect(() => {
    // loadInitialData();
    // setDataUpdated(false);
    const userType = sessionStorage.getItem('userType');
    setUserType(userType);
    console.log("User type ===> ", userType)
  }, [])

  return (
    <>
      {!isGroupIdClicked && <Fragment>
        {loading && (<FullscreenLoader />)}
        <div className="d-flex align-items-center">
          <h2 className="page-title">
            OES OEM Mapping
          </h2>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <div className="cardCommon p-3">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title mb-2">Search Parts</h5>
              </div>
              <TextField
                value={searchText}
                onChange={(e: any) => {
                  setsearchText(e.target.value);
                  if (e.target.value.trim() === "") {
                    setFilteredParts([])
                  }
                }}
                onKeyDown={(e: React.KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    onSearchButtonClick();
                  }
                }}
                size="small"
                className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField search-input"
                label="Search by Part No"
                variant="outlined"
              />

              <Button
                className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton prodTeamSearchBtn"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => onSearchButtonClick()}
              >
                Search
              </Button>

            </div>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <div className="row mt-4">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex align-items-center mb-2">
              <h5 className="page-sub-title mt-2">List of Parts</h5>
              <div className="ml-auto legendItemCreateEnquiry m-2 mt-2">

              </div>
              <div>
                <Dialog maxWidth={maxWidth} className="popup-dialog" open={open} onClose={showConfermation}>
                  <div className="popup-dialog">
                    <DialogTitle className="page-title">
                      <h2 className="page-title">
                        Add New Part
                      </h2>
                      <div className="close-btn">
                        <a>
                          <CloseIcon onClick={showConfermation} />
                        </a>
                      </div>
                    </DialogTitle>

                    <DialogContent className="main-input-container">
                      {/* Loop through formData for primary part inputs */}
                      {formData && formData.length > 0 && formData.map((partItem: any, index: any) => (
                        <div className="input-container" key={index}>
                          {/* Part Number */}
                          <div className="mt-2 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`Part_Number-${index}`}
                              label="Part No."
                              variant="outlined"
                              margin="dense"
                              error={!partItem.Part_Number && inCompleteFileds}
                              helperText={partItem.errormessage && <span style={{ color: 'red' }}>{partItem.errormessage}</span>}
                              value={partItem.Part_Number}
                              onChange={(e) => handleInputChange(e, 'Part_Number', index)}
                              onKeyDown={(e: any) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>

                          {/* Brand */}
                          <div className="mt-2 ml-3 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-brand-${index}`}
                              label="Brand"
                              variant="outlined"
                              margin="dense"
                              error={!partItem.OES_Aftermarket_Brand && inCompleteFileds}
                              value={partItem.OES_Aftermarket_Brand}
                              onChange={(e) => handleInputChange(e, 'OES_Aftermarket_Brand', index)}
                              onKeyDown={(e: any) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                  e.preventDefault();
                                }
                              }}

                            />
                          </div>

                          {/* Select Type */}
                          <div className="mt-2 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-type-${index}`}
                              label="Select Type"
                              variant="outlined"
                              margin="dense"
                              // error={!partItem.Cross_Ref_Car_Make_Brand_OEM && inCompleteFileds}
                              value={partItem.type}
                              onChange={(e) => handleAltInputChange(e, 'type', index)}
                              onKeyDown={(e) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                  e.preventDefault();
                                }
                              }}
                              disabled
                            />
                          </div>

                          <div className="mt-2 ml-3 popup-input">
                            <Autocomplete
                              popupIcon={<SearchIcon />}
                              disableClearable
                              noOptionsText={''}
                              inputValue={searchGrpValue}
                              className="group-id-input"
                              onInputChange={(event, value) => {
                                setSearchGrpValue(value);
                                if (value && value.length > 3) {
                                  getGroupIdPartialList(value);
                                }
                              }}

                              autoFocus
                              onChange={(event: any, newValue: string | null) => {
                                setSearchGrpValue(newValue);
                                setReplaceGrpIdSelected(true);
                                setNewGrpIdToReplace(newValue)
                              }}
                              id="group-id-search"
                              options={replaceGrpIdSelected ? [] : suggestions}
                              getOptionLabel={(option: string) => option}
                              renderInput={(params) => (
                                <TextField
                                  className="smallText mt-2 commonRoundedInputs w-100"
                                  {...params}
                                  label="Group ID"
                                  variant="outlined"
                                  value={searchGrpValue}
                                  onChange={(e) => onSearchGroupId(e.target.value)}
                                />
                              )}
                              open={!replaceGrpIdSelected && searchGrpValue.length > 3}
                            />
                          </div>

                          {/* Description */}
                          <div className="mt-2 popup-input-desc">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-description-${index}`}
                              label="Description"
                              variant="outlined"
                              margin="dense"
                              error={!partItem.Product_Group_Description && inCompleteFileds}
                              value={partItem.Product_Group_Description}
                              onChange={(e) => handleInputChange(e, 'Product_Group_Description', index)}
                            // onKeyDown={(e: any) => {
                            //   if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                          </div>

                          {/* Add Alternate Part Button */}
                          {index === 0 &&
                            <div onClick={addNewPartRow} className="add-alter-text mt-5">
                              <div className="add-alternate">
                                <Add fontSize="inherit" />
                              </div>
                              <div>
                                <a>
                                  <h6>Add Alternate Part</h6>
                                </a>
                              </div>
                            </div>}
                        </div>
                      ))}



                      {isAddAltClicked && altData && altData.length > 0 && altData.map((alternateItem, index) => (
                        <div key={index}>
                          {altData.length === 1 && (
                            <div className="close-alt">
                              <a onClick={() => handleCloseAlternate()}>
                                <CloseIcon className="close-icon mr-1" />
                                <span>Cancel</span>
                              </a>
                            </div>
                          )}

                          {/* Dialog Content for Alternate Item */}
                          <DialogContent>
                            <div className="input-container-alt">
                              {/* Alternate Part Number */}
                              <div className="mt-2 popup-input">
                                <TextField
                                  required
                                  className="full-width rounded-input"
                                  size="small"
                                  id={`Part_Number-${index}`}
                                  label="Part No."
                                  variant="outlined"
                                  margin="dense"
                                  error={!alternateItem.Part_Number && inCompleteFileds}
                                  value={alternateItem.Part_Number}
                                  onChange={(e) => handleAltInputChange(e, 'Part_Number', index)}
                                  onKeyDown={(e) => {
                                    if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>

                              {/* Alternate Brand */}
                              <div className="mt-2 ml-3 popup-input">
                                <TextField
                                  required
                                  className="full-width rounded-input"
                                  size="small"
                                  id={`alt-brand-${index}`}
                                  label="Brand"
                                  variant="outlined"
                                  margin="dense"
                                  error={!alternateItem.OES_Aftermarket_Brand && inCompleteFileds}
                                  value={alternateItem.OES_Aftermarket_Brand}
                                  onChange={(e) => handleAltInputChange(e, 'OES_Aftermarket_Brand', index)}
                                  onKeyDown={(e) => {
                                    if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </div>

                              {/* Alternate Type */}
                              <div className="mt-2 popup-input">
                                <Autocomplete
                                  disableClearable
                                  value={selectedType2}
                                  className="group-id-input"
                                  onChange={(event, newValue) => {
                                    setSelectedType2(newValue);
                                    setOpenTypeDropdown(false);
                                  }}
                                  options={["AfterMarket", "OEM"]}
                                  getOptionLabel={(option) => option}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Select Type"
                                      variant="outlined"
                                      className="smallText mt-2 commonRoundedInputs w-100"
                                      onChange={(e) => handleAltInputChange(e, 'type', index)}
                                      // error={!selectedType2}
                                      onClick={() => setOpenTypeDropdown(true)}
                                    />
                                  )}
                                  open={openTypeDropdown}
                                  onClose={() => setOpenTypeDropdown(false)}
                                />
                              </div>

                              {/* Group ID (Alternate) */}
                              <div className="mt-2 ml-3 popup-input">
                                <TextField
                                  required
                                  className="full-width rounded-input"
                                  size="small"
                                  id={`alt-groupId-${index}`}
                                  label="Group ID"
                                  variant="outlined"
                                  margin="dense"
                                  value={searchGrpValue}
                                  onChange={(e) => handleAltInputChange(e, 'Secondnary_Referece_Id', index)}
                                  onKeyDown={(e) => {
                                    if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                      e.preventDefault();
                                    }
                                  }}
                                  disabled
                                />
                              </div>

                              {/* Description (Alternate) */}
                              <div className="mt-2 popup-input-desc">
                                <TextField
                                  required
                                  className="full-width rounded-input"
                                  size="small"
                                  id={`alt-description-${index}`}
                                  label="Description"
                                  variant="outlined"
                                  margin="dense"
                                  error={!alternateItem.Product_Group_Description && inCompleteFileds}
                                  value={alternateItem.Product_Group_Description}
                                  onChange={(e) => handleAltInputChange(e, 'Product_Group_Description', index)}
                                // onKeyDown={(e) => {
                                //   if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                //     e.preventDefault();
                                //   }
                                // }}
                                />
                              </div>
                            </div>
                          </DialogContent>
                        </div>
                      ))}
                    </DialogContent>

                    <DialogActions>
                      <Button
                        className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      <Button
                        className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn"
                        size="small"
                        variant="contained"
                        color="secondary"
                        onClick={showConfermation}
                        autoFocus
                      >
                        Close
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>


                {isAddMoreClicked && altData && altData.length > 0 && altData.map((addMoreItem, index) => (
                  <Dialog maxWidth={maxWidth} open={isAddMoreClicked} onClose={showConfermation}>
                    <div className="popup-dialog">
                      <DialogTitle className="page-title">
                        <h2 className="page-title">
                          {isEditing ? 'Edit Part' : 'Add Alternate Part'}
                        </h2>
                      </DialogTitle>

                      {/* Show Parent Part Info only if editing and index >= 1 */}
                      {isEditing && relatedRecords && index >= 1 && (
                        <div className="parent-part-info">
                          <p className="ml-4">Parent Part No. :</p>
                          <p>{searchText}</p>

                          <p className="ml-5">Brand :</p>
                          <p>{searchDataState?.Cross_Ref_Car_Make_Brand_OEM}</p>

                          <p className="ml-5">Type :</p>
                          <p>{searchDataState?.type}</p>
                        </div>
                      )}

                      <DialogContent key={index}>
                        <div className="input-container-alt">
                          {/* Part Number Input */}
                          <div className="mt-2 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`Part_Number-${index}`}
                              label="Part No."
                              variant="outlined"
                              margin="dense"
                              error={!addMoreItem.Part_Number && inCompleteFileds}
                              value={addMoreItem.Part_Number}
                              onChange={(e) => handleAltInputChange(e, 'Part_Number', index)}
                              onKeyDown={(e) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>

                          {/* Brand Input */}
                          <div className="mt-2 ml-3 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-brand-${index}`}
                              label="Brand"
                              variant="outlined"
                              margin="dense"
                              error={!addMoreItem.OES_Aftermarket_Brand && inCompleteFileds}
                              value={addMoreItem.OES_Aftermarket_Brand}
                              onChange={(e) => handleAltInputChange(e, 'OES_Aftermarket_Brand', index)}
                              onKeyDown={(e) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                                  e.preventDefault();
                                }
                              }}
                            />
                          </div>

                          {/* Cross_Ref_Car_Make_Brand_OEM (Type) Dropdown Input */}
                          <div className="mt-2 popup-input">
                            {isEditing ? (
                              <TextField
                                required
                                className="full-width rounded-input"
                                size="small"
                                id={`alt-type-${index}`}
                                label="Select Type"
                                variant="outlined"
                                margin="dense"
                                value={addMoreItem.type}
                                onChange={(e) => handleAltInputChange(e, 'type', index)}
                                onKeyDown={(e) => {
                                  if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                    e.preventDefault();
                                  }
                                }}
                                disabled
                              />
                            ) : (
                              <Autocomplete
                                disableClearable
                                value={selectedType2}
                                className="group-id-input"
                                onChange={(event, newValue) => {
                                  setSelectedType2(newValue);
                                  setOpenTypeDropdown(false);
                                }}
                                options={["AfterMarket", "OEM"]}
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Select Type"
                                    variant="outlined"
                                    className="smallText mt-2 commonRoundedInputs w-100"
                                    onClick={() => setOpenTypeDropdown(true)}
                                    onChange={(e) => handleAltInputChange(e, 'type', index)}
                                  />
                                )}
                                open={openTypeDropdown}
                                onClose={() => setOpenTypeDropdown(false)}
                              />
                            )}
                          </div>

                          {/* Other Inputs */}
                          <div className="mt-2 ml-3 popup-input">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-groupId-${index}`}
                              label="Group ID"
                              variant="outlined"
                              margin="dense"
                              value={isEditing ? addMoreItem.Secondnary_Referece_Id : groupIdValueForAddMore}
                              onChange={(e) => handleAltInputChange(e, 'Secondnary_Referece_Id', index)}
                              onKeyDown={(e) => {
                                if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab') {
                                  e.preventDefault();
                                }
                              }}
                              disabled
                            />
                          </div>

                          {/* Description Input */}
                          <div className="mt-2 popup-input-desc">
                            <TextField
                              required
                              className="full-width rounded-input"
                              size="small"
                              id={`alt-description-${index}`}
                              label="Description"
                              variant="outlined"
                              margin="dense"
                              error={!addMoreItem.Product_Group_Description && inCompleteFileds}
                              value={addMoreItem.Product_Group_Description}
                              onChange={(e) => handleAltInputChange(e, 'Product_Group_Description', index)}
                            // onKeyDown={(e) => {
                            //   if (!/^[a-zA-Z0-9-]$/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Tab' && e.key !== ' ') {
                            //     e.preventDefault();
                            //   }
                            // }}
                            />
                          </div>
                        </div>
                      </DialogContent>

                      {/* Action Buttons */}
                      <DialogActions>
                        <Button
                          className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleAddMoreSave}
                        >
                          Save
                        </Button>
                        <Button
                          className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn"
                          size="small"
                          variant="contained"
                          color="secondary"
                          onClick={showConfermation}
                          autoFocus
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>
                ))}

                <Button
                  variant="contained"
                  color="primary"
                  className="rounded-pill"
                  startIcon={<AddIcon />}
                  disabled={isFSEDisabled}
                  style={{
                    display: isFSEDisabled ? 'none' : 'auto',
                    opacity: isFSEDisabled ? 0.5 : 1
                  }}
                  onClick={() => {
                    handleClickOpen();
                  }}>
                  Add New
                </Button>
              </div>
              <div className="groupid-btn"
                style={{
                  display: isFSEDisabled ? 'none' : 'auto',
                  opacity: isFSEDisabled ? 0.5 : 1
                }}
                onClick={openGroupIdPage}>
                <ListAltIcon className="groupid-icon" />
                <a className="groupid-tag">Group IDs</a>
              </div>
              {showImportExcel &&
                <div style={{
                  display: isFSEDisabled ? 'none' : 'auto',
                  opacity: isFSEDisabled ? 0.5 : 1
                }}>
                  <IconButton>
                    <ImportDialog
                      style={{
                        display: isFSEDisabled ? 'none' : 'auto',
                        opacity: isFSEDisabled ? 0.5 : 1
                      }}
                      isOpened={() => {
                        sessionStorage.setItem(
                          "tempProducts",
                          JSON.stringify(products ? products : [])
                        );
                      }}
                      commonInsertionLogic={(jsonData: any) => {
                        // commonInsertionLogic(jsonData, true);
                      }}
                    />
                  </IconButton>
                </div>
              }
            </div>


            <div className="cardCommon mh-300p">
              <TableContainer>
                <Table aria-label="table">
                  <TableHead className="w-100 table-head">
                    <TableRow>
                      <TableCell className={classes.tableHead}>Part No.</TableCell>
                      <TableCell className={classes.tableHead}>Brand</TableCell>
                      <TableCell className={classes.tableHead}>Type</TableCell>
                      <TableCell><h6 className="tableHead">Part Description</h6></TableCell>
                      <TableCell className={classes.tableHead}>Group ID</TableCell>
                      <TableCell><h6 className="tableHead">Updated By</h6></TableCell>
                      <TableCell className={classes.tableHead}>Updated On</TableCell>
                      <div
                        className="action-title"
                        style={{
                          display: isFSEDisabled ? 'none' : 'auto',
                          opacity: isFSEDisabled ? 0.5 : 1
                        }}>
                        <TableCell className={classes.actiontTbleHead}>Action</TableCell>
                      </div>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {filteredParts.length > 0 ? (
                      filteredParts.map((item: Part, index: number) => {
                        // Format date and time for each row
                        const formattedDate = item.updated_date ? moment(item.updated_date).
                          tz("Asia/Dubai").format("DD-MM-YYYY") : (new Date(item._ts * 1000).toLocaleDateString('en-GB', { timeZone: 'Asia/Kolkata' }));
                        if (item.Part_Number === '1643202031') {
                          console.log('==> item.updated_date', item.updated_date);
                        }

                        const formattedTime = item.updated_date ? moment(item.updated_date).tz("Asia/Dubai").format("HH:mm:ss") : (new Date(item._ts * 1000).toLocaleTimeString('en-GB', { timeZone: 'Asia/Kolkata' }));

                        return (
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor: index !== 0 ? "#fef9f2" : "transparent",
                              padding: "10px",
                              margin: "5px 0",
                              border: "1px solid #ddd",
                            }}
                          >
                            <TableCell>{item.Part_Number}</TableCell>
                            <TableCell>{item.OES_Aftermarket_Brand}</TableCell>
                            <TableCell>{item.type === "afterMarket" || item.type === "AfterMarket" ? "AfterMarket" : "OEM"}</TableCell>
                            <TableCell>
                              <Tooltip title={item.Product_Group_Description} placement="bottom">
                                <span>
                                  {item.Product_Group_Description?.length > 20
                                    ? item.Product_Group_Description.slice(0, 20) + "..."
                                    : item.Product_Group_Description}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell>{item.Secondnary_Referece_Id}</TableCell>
                            <TableCell>
                              <h6 className="tableHead">{item.updated_by}</h6>
                              <p>Emp ID : {item.emp_id}</p>
                            </TableCell>
                            <TableCell>
                              <h6 className="tableHead">{formattedDate}</h6>
                              <p>{formattedTime}</p>
                            </TableCell>
                            <TableCell>
                              {index === 0 && (
                                <div
                                  className="act-container add-more"
                                  style={{
                                    display: isFSEDisabled ? 'none' : 'auto',
                                    opacity: isFSEDisabled ? 0.5 : 1
                                  }}
                                >
                                  <Add fontSize="inherit" className="mr-1 act-btn" />
                                  <a
                                    onClick={handleAddMoreOpen}
                                    style={{
                                      display: isFSEDisabled ? 'none' : 'auto',
                                      opacity: isFSEDisabled ? 0.5 : 1
                                    }}
                                    className="act-item">
                                    Add More
                                  </a>
                                </div>
                              )}
                              <div className="act-btn-space">
                                <div
                                  className="act-container"
                                  style={{
                                    display: isFSEDisabled ? 'none' : 'auto',
                                    opacity: isFSEDisabled ? 0.5 : 1
                                  }} >
                                  <EditOutlinedIcon fontSize="inherit" className="mr-1 act-btn" />
                                  <a
                                    onClick={() => handleEditPart(item)}
                                    className="mr-3 act-item">
                                    Edit
                                  </a>
                                </div>
                                <div
                                  className="act-container"
                                  style={{
                                    display: isFSEDisabled ? 'none' : 'auto',
                                    opacity: isFSEDisabled ? 0.5 : 1
                                  }} >
                                  <DeleteIcon fontSize="inherit" className="mr-1 act-btn" />
                                  <a
                                    className="mr-3 act-item"
                                    onClick={() => {
                                      handleopenCloseDeleteDialog(true, item);
                                    }}
                                  >
                                    Delete
                                  </a>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell colSpan={8} className="no-data">
                          <b className="no-data">No results found</b>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              <CancelDialog
                // className="cancel-dialog"
                open={openCancelDialog}
                text="Are you sure you want to close this window? All unsaved data will be lost."
                handleopenClose={handleopenCloseCancelDialog}
                handleCancelConfirmation={handleCancelConfirmation}
                maxWidth="xs"
              />


              <DeleteDialogProductTeamPortal
                open={openDeleteDialog}
                text="Are you sure you want to delete this Part ?"
                handleopenCloseDeleteDialog={(data: any) => {
                  if (data === 'delete') {
                    deleteAPICall();
                  } else if (data === false) {
                    handleopenCloseDeleteDialog(false);
                  }
                }} />
              {openChooseCustomerDialog && <ChooseCustomerDialog openChooseCustomerDialog={openChooseCustomerDialog} />}
              <div className="col-12 d-flex justify-content-end">
              </div>
            </div>
          </div>
        </div>
      </Fragment >}
      {isGroupIdClicked && <GroupId goBack={goBack} />}
    </>
  );
}

export default ProductTeamDashboard;
