import { productTeamService } from "../../services/oemOesproductTeamSearch.service";
import { OES_OEM_MAP_PRODUCT_SEARCH, TECH_DOC_PARTS } from "./actionsTypes";
import { apiError, apiStart, apiSuccess } from "./apiActions";

export const oesOemMapActions = {
    getSearchList,
    getGroupIdList,
    getGroupIdPaginationList,
    addNewParts,
    deletePart,
    getPartIdInfo,
    updateSearchPart,
    importPartsExcelSheet,
    updateGroupID,
    getTechDocPartList

    };
  
  export function getSearchList(createdByME: any, searchText: any, page?: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_FETCHING));
      const response = await productTeamService.getSearchList(createdByME, searchText, page);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getGroupIdList(createdByME: any, searchText: any, page?: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_FETCHING));
      const response = await productTeamService.getGroupIdList(createdByME, searchText, page);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getGroupIdPaginationList(createdByME: any, searchText: any, page?: any, ps? : any, groupId?: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_FETCHING));
      const response = await productTeamService.getGroupIdPaginationList(createdByME, searchText, page, ps, groupId);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function resetGroupIDState(actionType:string){
    return async (dispatch: any) => {
      dispatch({type: actionType, payload: ''})
    };   
  }

  export function updateGroupID(oldGrpID: any, newGrpID: any){
    
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_FETCHING));
      const response = await productTeamService.updateGroupID(oldGrpID, newGrpID);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function addNewParts(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_FETCHING));
      const response = await productTeamService.addNewParts(data);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function deletePart(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.DELETE_PART_LIST_FETCHING));
      const response = await productTeamService.deletePart(data);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.DELETE_PART_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.DELETE_PART_LIST_SUCCESS, response));
        return response || [];
      }
    }
  }

  export function getPartIdInfo(id: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_WITH_ID_FETCHING));
      const response = await productTeamService.getPartIdInfo(id);
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_WITH_ID_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_WITH_ID_SUCCESS, response));
        return response || [];
      }
    };
  }


  export function getTechDocPartList(item?: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(TECH_DOC_PARTS.GET_TECH_DOC_PART_FETCHING));
      const response = await productTeamService.getTechDocPartList(item);
      if (response.error) {
        dispatch(apiError(TECH_DOC_PARTS.GET_TECH_DOC_PART_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(TECH_DOC_PARTS.GET_TECH_DOC_PART_SUCCESS, response));
        return response || [];
      }
    };
  }

  // export function getTechDocPartList(item?: any, partList?: any, enquiryDataDetails?: any) {
  //   return async (dispatch: any) => {
  //     let techDocPart = {
  //       techDocParts: item, partsList: partList, enquiryData: enquiryDataDetails
  //     }
  //     dispatch(apiStart(TECH_DOC_PARTS.GET_TECH_DOC_PART_SUCCESS, techDocPart))
  //   }
  // }
  

  export function updateSearchPart(data: any, id?:any) {
    return async (dispatch: any) => {
      dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_FETCHING));
      
      const response = await productTeamService.updateSearchPart(id, data);
      
      console.log("API Response in Action:", response); // Log response here
      
      if (response.error) {
        dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_ERROR, response));
        return response || [];
      } else {
        dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_SUCCESS, response));
        return response || [];
      }
    };
  }

  export function importPartsExcelSheet(data?: any, customHeaders?: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_FETCHING));
        try {
            const response = await productTeamService.importPartsExcelSheet(data, customHeaders);
            dispatch(apiSuccess(OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_ERROR, error));
            return error;
        }
    };
}