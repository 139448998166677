import { API_ENDPOINTS, OES_OEM_MAP_SEARCH } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const productTeamService = {
    getSearchList,
    addNewParts,
    getGroupIdList,
    getGroupIdPaginationList,
    editSearchPart,
    deletePart,
    getPartIdInfo,
    updateSearchPart,
    importPartsExcelSheet,
    updateGroupID,
    getTechDocPartList
};

async function getTechDocPartList(data: any) {
    const apiPath = API_ENDPOINTS.CMS_ENDPOINT + `/enquiries/techAllianceSearch`;
    // const apiPath = `https://autob2bdev.corp.al-futtaim.com/automotive/b2b/v1/cms/enquiries/techAllianceSearch`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getSearchList(createdByME: any, searchText: any, page?: any) {
    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.LIST + `?partNumber=${searchText}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getPartIdInfo(partNo?: any) {
    if (!partNo || partNo.length === 0) return;
    const queryString = partNo.map((p: any) => `searchPartNumber=${encodeURIComponent(p)}`).join('&');

    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + `/part/lists?${queryString}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    }
    return await makeRequest(options)

}

async function updateSearchPart(id: any, data: any) {
    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.UPDATE;
    const changeData = data
    const options = {
        // path: `${apiPath}/${id}`,
        path: `${apiPath}`,
        method: "PUT",
        bodyObj: changeData,
        customHeaders: {},
        formdata: true,
    };

    console.log("API Request Path:", options.path);
    console.log("Request Body:", changeData);

    const response = await makeRequest(options);

    console.log("API Response:", response);
    return response;
}


async function editSearchPart(id: any, data: any) {
    const apiPath = API_ENDPOINTS.PRODUCTS_ENDPOINT + OES_OEM_MAP_SEARCH.UPDATE;
    const options = {
        path: `${apiPath}/${id}`,
        method: "PUT",
        bodyObj: data,
        customHeaders: {
            // "Lob-Id": "AUTO",
            // "Channel-Id": "B2B",
        },
        formdata: true,
    };

    console.log("API Request Path:", options.path);
    console.log("Request Body:", data);  // Log the data you are sending

    const response = await makeRequest(options);

    console.log("API Response:", response); // Log the response from the API
    return response;
}


// async function getGroupIdList(createdByME: any, searchText: any, page?: any) {
//     const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.GROUPID_LIST;
//     const options = {
//         path: `${apiPath}`,
//         method: "GET",
//         bodyObj: {},
//         customHeaders: {
//             "userId": sessionStorage.getItem('webApplicationLoginId'),
//             "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
//         },
//         formdata: true
//     };
//     return await makeRequest(options)
// }

async function getGroupIdList(createdByME: any, searchText: any, page?: any) {
    let apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.GROUPID_LIST;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getGroupIdPaginationList(createdByME: any, searchText: any, page?: any, ps?: any, groupId?: any) {
    const pageSize: number = ps ? ps : 10;
    let apiPath = `${API_ENDPOINTS.OES_OEM_ENDPOINT}${OES_OEM_MAP_SEARCH.GROUPID_LIST_NEW}?page=${page + 1}&pageSize=${pageSize}`;
    if (groupId) {
        apiPath = `${apiPath}&groupId=${groupId}`
    }
    const options = {
        path: `${apiPath}`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateGroupID(oldGrpID: any, newGrpID: any) {
    const apiPath = `${API_ENDPOINTS.OES_OEM_ENDPOINT}${OES_OEM_MAP_SEARCH.UPDATE_GROUPID}`;
    console.log('oldGrpID in api', oldGrpID);
    console.log('newGrpID in api', newGrpID);
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: {
            "oldGroupId": oldGrpID,
            "newGroupId": newGrpID
        },
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addNewParts(data: any) {
    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.POST;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true,
    };
    return await makeRequest(options)
}

async function deletePart(data: any) {
    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.DELETE;
    const options = {
        path: `${apiPath}`,
        method: "DELETE",
        bodyObj: data,
        customHeaders: {},
        formdata: true,
    };
    return await makeRequest(options)
}

async function importPartsExcelSheet(data: any, customHeaders?: any) {
    const apiPath = API_ENDPOINTS.OES_OEM_ENDPOINT + OES_OEM_MAP_SEARCH.IMPORT_EXCEL;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };
    return await makeRequest(options)
}