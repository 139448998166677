/* eslint-disable  */
import React, { Fragment, Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import Checkbox from "@material-ui/core/Checkbox";
import RemoveIcon from '@material-ui/icons/Remove';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import TablePagination from '@material-ui/core/TablePagination';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from '@material-ui/core/Tooltip';
import CancelDialog from '../../common/components/cancelDialog';
import Snackbar from './../../common/components/Snackbar';
import DatePicker from '../../common/components/dateRangePicker';
import { FULLY_CANCELLEED } from '../../constants/orderEnquiryConstants';
import _, { get } from "lodash";
import { getEnquiriesList, getFSEEnquiriesList, getFSEList, getOrderEnquiryStatus } from '../../store/actions/orderEnquiry.action';
import { cancelEnquiry, getUniqueEnquirySessionId, getRefreshOrderDetailsAPI, verifyEnquiryQueueCheck, } from "../../store/actions/createUpdateEnquiry.actions";
import FullscreenLoader from './../../common/components/FullscreenLoader';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import "./orderEnquiry.css";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import moment from "moment-timezone";
import { getNewBrand, getNewlyAddedBrand, getUniqueTimestamp } from "../../common/components/util";
import { ALL_STATUS } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";
import { requestSpecialPriceService } from "../../services/requestSpecialPrice.service";
import { getViewRequestStatusAPI } from "../../store/actions/requestSpecialPrice.actions";
import ExpiredDialog24Hrs from "../CreateEnquiry/ExpiredDialog24Hrs";
import ChooseCustomerDialog from "../CreateEnquiry/ChooseCustomerDialog";
import { getCustomerDetailsByCustomerID, getFSECustomerList, getFSEEnquiryStatus } from "../../store/actions/dealer.action";
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 84
  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  // subTableHead: {
  //   color: '#444444',
  //   fontWeight: 800,
  //   fontSize: '0.75rem',
  //   fontFamily: 'Nunito',
  //   minWidth: 100,
  //   padding: 10
  // },
  actionButton: {
    color: '#1A9FE0',
    // marginLeft: 0,
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10
  },
  createOrderEnqButton: {
    backgroundColor: '#1A9FE0',
    width: 190,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    color: '#FFFFFF',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
  },
}));

const CUSTOMTAB_INACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#999999",
    fontWeight: 800,
    paddingLeft: 0,
    fontFamily: "Nunito",

    paddingBottom: 4,
    height: "55px",
  },
})(Tab);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const OrderEnquiry: React.FC = (props: any) => {
  const history = useHistory();
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { enquiryList, enquiryStatus, fseList } = useSelector((state: any) => state.orderEnquiry);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [selectedMasterId, setSelectedMasterId] = useState<any>(null);
  const [enqCusId, setEnquiryCusId] = useState<any>(null);
  const dataOrderRedux: any = useSelector((state: any) => state.orderEnquiry);
  const [searchText, setsearchText] = useState<any>('');

  const today = moment(new Date());
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  // Convert Date object to moment object
  const momentDate = moment(thirtyDaysAgo);

  // Format the date with day, month, year, hours, minutes, seconds, and timezone
  let formattedDate: any = momentDate.format('ddd MMM DD YYYY HH:mm:ss');
  formattedDate = moment(new Date(formattedDate))


  const [startDate, setstartDate] = useState<any>(formattedDate);
  const [endDate, setendDate] = useState<any>(today);

  const [status, setstatus] = useState<any>('');
  const [fseName, setFseName] = useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const [open, setOpen] = useState(-1);
  const [isMyEnquiryChecked, setIsMyEnquiryChecked] = useState(true)
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const [viewRequestB2bEnquiryId, setViewRequestB2bEnquiryId] = React.useState<any>();
  const [masterID, setMasterID] = React.useState<any>();
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [enquiryMaterdata, setEnquiryMaterdata] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const { requestAccessData, viewSpecialPriceDetails }: any = useSelector((state: any) => state.requestSpecialPrice);
  const currentTier = sessionStorage.getItem('currentTier');
  const [openChooseCustomerDialog, setOpenChooseCustomerDialog] = React.useState(false);
  const [isOrderEnquiryCreated, setIsOrderEnquiryCreated] = React.useState<boolean>(false);
  const { getCustomerDetailsBySapId, getFSECustomerListData, getFSEEnquiryStatusData }: any = useSelector((state: any) => state.dealer);
  const [searchTextByID, setSearchTextByID] = React.useState<any>('');
  const [customerId, setCustomerId] = React.useState<any>([]);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>('');
  const [selectedCustomerName, setSelectedCustomerName] = React.useState<any>('');
  const [count, setCount] = React.useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [isProblemParts, setIsProblemParts] = React.useState<any>(false);
  const [searchTextOnChange, setSearchTextOnChange] = React.useState<any>('');
  const [isEstTotalZero, setIsEstTotalZero] = React.useState<any>(false);
  const [isQueuedEnquirieChecked, setIsQueuedEnquirieChecked] = useState(false)
  const { enquiryInfo, enquiryDetailsQueueCheck }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );

  // const loadEnquiryList = useCallback((pageNumber?: any) => {
  //   setPage(pageNumber ? pageNumber : 0);
  //   let searchData: any = {}
  //   if (searchText && searchText.trim()) {
  //     searchData["enquiryNumber"] = searchText.trim();
  //     searchData["referenceNumber"] = searchText.trim();
  //   }
  //   if (startDate && endDate) {
  //     searchData["fromDate"] = startDate;
  //     searchData["toDate"] = endDate;
  //   }
  //   if (status) {
  //     searchData["status"] = status;
  //   }
  //   dispatch(getEnquiriesList(searchData, pageNumber));
  // }, [startDate, endDate, searchText, status, dispatch])

  const isShowSPR = () => {
    if ((currentTier && currentTier.toLowerCase() === 'gold') || (currentTier && currentTier.toLowerCase() === 'platinum') || (currentTier && currentTier.toLowerCase() === 'elite')) {
      return true;
    } else {
      return false;
    }
  }

  const openCloseChooseCustomer = () => {
    setOpenChooseCustomerDialog(false);
  }

  const handleChooseCustomer = () => {
    setOpenChooseCustomerDialog(true);
  }

  React.useEffect(() => {
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
  }, [getFSECustomerListData]);

  React.useEffect(() => {
    let id: any
    enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
      <>
        {enquiryList.data.data.map((enquiry: any, index: any) => {
          return (
            id = enquiry.masterId
          );
        })}
      </>
    )
    // setLoading(false);
    setMasterID(id)
    if (sessionStorage.getItem('userType') === 'FSE') {
      enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
        <>
          {enquiryList.data.data.map((enquiry: any, index: any) => {
            if (enquiry && enquiry.status === 'Order Created') {
              let data: any = dispatch(verifyEnquiryQueueCheck(enquiry.customerType, enquiry.masterId, enquiry.customerId));
              if (data && data.error && Object.keys(data.error).length > 1) {
                setIsProblemParts(true);
              } else if (data && data.message && ((data.message.message && data.message.message === 'verification completed') || (data.message.message && data.message.message === 'verification in progress') || (data.message.message && data.message.message === 'order created'))) {
                dispatch(getFSEEnquiriesList(customerTypeAccess, {
                  "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')], fromDate: startDate ? startDate : formattedDate,
                  toDate: endDate ? endDate : today
                }))
              }
              setLoading(false);
            }
          })}
        </>
      )
    }
    // setLoading(false);
  }, [enquiryList]);

  const getAllCustomerIds = () => {
    let customer_ids: string[] = [];
    if (getFSECustomerListData && getFSECustomerListData.data) {
      customer_ids = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => item.sapCustomerId);
    }
    return customer_ids;
  }

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') !== 'FSE') {
      dispatch(getEnquiriesList(customerTierType, {
        fromDate: startDate ? startDate : formattedDate,
        toDate: endDate ? endDate : today
      }, isMyEnquiryChecked));
      setstatus('');
    }
    if (searchText === '' && status === '' && selectedCustomerName === '' && sessionStorage.getItem('userType') === 'FSE' && getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
      let searchData: any = {
        fromDate: startDate ? startDate : formattedDate,
        toDate: endDate ? endDate : today
      };
      if (fseName && fseName !== '') {
        if (fseName === 'All') {
          const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
          searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["fseWebApplicationLoginId"] = [];
        } else {
          searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
        }
      } else {
        searchData["fseWebApplicationLoginId"] = [];
      }
      if (selectedCustomerName && selectedCustomerName !== '') {
        searchData["customerId"] = [selectedCustomerName];
      } else if (fseName === '' || fseName === 'Cust Only') {
        searchData["customerId"] = getAllCustomerIds();
      } else {
        searchData["customerId"] = []
      }
      if (isQueuedEnquirieChecked === true) {
        searchData["isQueued"] = isQueuedEnquirieChecked
      }
      dispatch(getFSEEnquiriesList(customerTypeAccess, searchData));
      setstatus('');
    }
  }, [isMyEnquiryChecked, isQueuedEnquirieChecked, getFSECustomerListData]);
  // }, [searchText, startDate, endDate, status, selectedCustomerName, isMyEnquiryChecked, isQueuedEnquirieChecked, getFSECustomerListData]);
  // }, [isMyEnquiryChecked, isQueuedEnquirieChecked, getFSECustomerListData]);

  React.useEffect(() => {
    sessionStorage.setItem("isProblemParts", "");
    const today = moment(new Date());
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    // Convert Date object to moment object
    const momentDate = moment(thirtyDaysAgo);

    // Format the date with day, month, year, hours, minutes, seconds, and timezone
    let formattedDate: any = momentDate.format('ddd MMM DD YYYY HH:mm:ss');
    formattedDate = moment(new Date(formattedDate))

    setstartDate(formattedDate);
    setendDate(today);
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0) {
      } else {
        dispatch(getFSEList());
      }
      if (getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
      } else {
        dispatch(getFSECustomerList());
      }
      if (getFSEEnquiryStatusData && getFSEEnquiryStatusData.data && getFSEEnquiryStatusData.data.length > 0) {
      } else {
        dispatch(getFSEEnquiryStatus());
      }
      // dispatch(getFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": sessionStorage.getItem('webApplicationLoginId') }))
    } else {
      if (enquiryStatus && enquiryStatus.data && enquiryStatus.data.data && enquiryStatus.data.data.length > 0) {
      } else {
        dispatch(getOrderEnquiryStatus(customerTierType));
      }
    }
  }, [])

  // React.useEffect(() => {
  //   loadEnquiryList();
  // }, []);
  // [searchText, startDate, endDate, status, isQueuedEnquirieChecked, fseName, selectedCustomerId, selectedCustomerName])

  const loadEnquiryList = useCallback((pageNumber?: any) => {
    setPage(pageNumber ? pageNumber : 0);
    let searchData: any = {}
    if (searchText && searchText.trim()) {
      searchData["enquiryNumber"] = searchText.trim();
      searchData["referenceNumber"] = searchText.trim();
    }

    if (startDate && endDate) { } else if (!searchText) {
      setstartDate(formattedDate);
      setendDate(today)
    }

    if (searchText && searchText !== '') {
      if ((startDate && endDate)) {
        searchData["fromDate"] = startDate ? startDate : formattedDate;
        searchData["toDate"] = endDate ? endDate : today;
      }
    } else {
      if ((startDate && endDate) || (formattedDate && today)) {
        searchData["fromDate"] = startDate ? startDate : formattedDate;
        searchData["toDate"] = endDate ? endDate : today;
      }
    }

    // if()
    if (status && status !== ALL_STATUS) {
      searchData["status"] = status;
    }

    if (sessionStorage.getItem('userType') === 'FSE') {
      if (fseName && fseName !== '') {
        if (fseName === 'All') {
          const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
          searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["fseWebApplicationLoginId"] = [];
        } else {
          searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
        }
      } else {
        searchData["fseWebApplicationLoginId"] = [];
      }
      if (selectedCustomerName && selectedCustomerName !== '') {
        searchData["customerId"] = [selectedCustomerName];
      } else if (fseName === '' || fseName === 'Cust Only') {
        searchData["customerId"] = getAllCustomerIds();
      } else {
        searchData["customerId"] = []
      }
    }
    if (isQueuedEnquirieChecked === true) {
      searchData["isQueued"] = isQueuedEnquirieChecked
    }
    if (sessionStorage.getItem('userType') !== 'FSE') {
      dispatch(getEnquiriesList(customerTierType, searchData, isMyEnquiryChecked, pageNumber));
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      dispatch(getFSEEnquiriesList(customerTypeAccess, searchData, pageNumber))
    }
  }, [dispatch, searchText, startDate, endDate, status, isQueuedEnquirieChecked, fseName, getFSECustomerListData, selectedCustomerId, selectedCustomerName]);

  const loadEnquiryListOnClickOfSearch = useCallback((pageNumber?: any, e?: any) => {
    setPage(pageNumber ? pageNumber : 0);
    let searchData: any = {}

    if (e.target.value && e.target.value.trim()) {
      searchData["enquiryNumber"] = e.target.value.trim();
      searchData["referenceNumber"] = e.target.value.trim();
    }
    if (startDate && endDate) {
      searchData["fromDate"] = startDate;
      searchData["toDate"] = endDate;
    }
    if (status && status !== ALL_STATUS) {
      searchData["status"] = status;
    }

    if (sessionStorage.getItem('userType') === 'FSE') {
      if (fseName && fseName !== '') {
        if (fseName === 'All') {
          const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
          searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["fseWebApplicationLoginId"] = [];
        } else {
          searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
        }
      } else {
        searchData["fseWebApplicationLoginId"] = [];
      }
      if (selectedCustomerName && selectedCustomerName !== '') {
        searchData["customerId"] = [selectedCustomerName];
      } else if (fseName === '' || fseName === 'Cust Only') {
        searchData["customerId"] = getAllCustomerIds();
      } else {
        searchData["customerId"] = []
      }
    }
    if (isQueuedEnquirieChecked === true) {
      searchData["isQueued"] = isQueuedEnquirieChecked
    }
    if (sessionStorage.getItem('userType') !== 'FSE') {
      dispatch(getEnquiriesList(customerTierType, searchData, isMyEnquiryChecked, pageNumber));
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      dispatch(getFSEEnquiriesList(customerTypeAccess, searchData, pageNumber))
    }
  }, [dispatch, searchText, startDate, endDate, status, isQueuedEnquirieChecked, fseName, getFSECustomerListData, selectedCustomerId, selectedCustomerName]);

  const resetFilters = () => {
    setsearchText('');
    setstartDate(formattedDate);
    setendDate(today);
    setFseName(sessionStorage.getItem('webApplicationLoginId'));
    setstatus('');
    setSelectedCustomerId('');
    setSelectedCustomerName('');
    setIsQueuedEnquirieChecked(false);
    dispatch(getFSEEnquiriesList(customerTypeAccess, {
      "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')],
      fromDate: formattedDate,
      toDate: today
    }))
    // dispatch(getFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')] }))
  }

  const resetFiltersForCustomer = async () => {
    setsearchText('');
    setstartDate(null);
    setendDate(null);
    setstatus('');
    setIsMyEnquiryChecked(true);
    await loadEnquiryList();
    // dispatch(getEnquiriesList(customerTierType, {}, isMyEnquiryChecked));
  }

  const menuItemStyle = {
    width: "550px"
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };


  // React.useEffect(() => {
  //   if (searchText === '' && startDate === null && endDate === null) {
  //     loadEnquiryList()
  //   }
  // }, [searchText, startDate, endDate, isQueuedEnquirieChecked]);

  const showCancel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CANCEL_ENQUIRY) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTextByID("");
  };

  const onSearchCustomerId = (searchText: any) => {
    setSearchTextByID(searchText);
    // getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => (
    //   console.log("__________________", item)
    // ))
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    if (searchText.length > 0) {
      const filteredIds = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()) || sapId.name.toLowerCase().includes(searchText.toLowerCase()));
      setCustomerId(filteredIds);
    } else {
      setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    }
  };

  const handleClickSapId = (event: any, sapId: any, name: any) => {
    setSelectedCustomerId(sapId);
    setSelectedCustomerName(name);
  };

  const SubEnquiries = (props: { subEnq: any, enquiry: any }) => {
    const { subEnq } = props;

    let estimatedTotal;

    if (!!subEnq.estimatedTotal) {
      if (typeof subEnq.estimatedTotal === "string" && subEnq.estimatedTotal.toLowerCase() !== "nan") {
        estimatedTotal = subEnq.estimatedTotal;
      }
      else {
        estimatedTotal = 0;
      }

      if (typeof subEnq.estimatedTotal === "number") {
        estimatedTotal = subEnq.estimatedTotal;
      }
    }

    return (
      <TableRow>
        <TableCell className={classes.subTableBodyContent}>
          <span className="mx-4 pl-4"></span>
          {/* {getNewBrand(subEnq.make) === 'Tradepoint' && (customerTierType && customerTierType.toLowerCase() !== 'workshop' || !customerTypeAccess.includes("workshop")) ? 'ALAC' : getNewBrand(subEnq.make)} */}
          {(customerTierType && customerTierType.toLowerCase() === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : (customerTierType && customerTierType.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop")) ? getNewBrand(subEnq.make.toString()) : getNewlyAddedBrand(subEnq.make.toString())}
        </TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.enquiryReferenceNumber}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.status}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{parseFloat(getSellingPrice(estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
      </TableRow>
    )
  }

  function EnquiryList(props: { enquiry: any, setSelectedMasterId: any, setEnquiryCusId: any, index: any }) {
    const { enquiry, setSelectedMasterId, setEnquiryCusId, index } = props;
    const showCancelButton = enquiry.status && enquiry.status.toLowerCase() !== FULLY_CANCELLEED && enquiry.status.toLowerCase() !== "cancel" && showCancel
    const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? enquiry && enquiry.customerTierType : get(profileDetails, 'data.data.customerInfo.customerTierType', '');
    setEnquiryCusId(enquiry && enquiry.customerId);

    const requestPriceAccess = async () => {
      if (customerTierType.toLowerCase() === "dealer") {
        let data: any = await requestSpecialPriceService.requestSpecialPriceAccessAPI(
          {
            "sapCustomerId": sapCustomerId,
            "enquiryId": enquiry && enquiry.masterId ? enquiry.masterId : masterID
          }
        )
      }
    }

    return (
      <React.Fragment>
        {enquiry && enquiry.status !== 'Order Created' && (
          <>
            <TableRow hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer">
              <TableCell className={classes.bodyContentSmall}>
                <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
                  {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
                </IconButton>
              </TableCell>
              {enquiry.isSpecialPriceRequested === true && customerTierType.toLowerCase() === "dealer" && sessionStorage.getItem('userType') !== 'FSE' ?
                <TableCell className="bodyContent">
                  <div className="legendItemCreateEnquiry mt-1 pr-0 pl-4">
                    <span className="specialprice"></span>
                  </div>
                </TableCell>
                : ""}
              {sessionStorage.getItem('userType') === 'FSE' ?
                <TableCell className="bodyContent">
                  {
                    enquiry.status === "Verification Completed" && enquiry.isQueued === true ?
                      <div className="legendItemCreateEnquiry">
                        <span className="specialprice"></span>
                      </div> : null
                  }
                  {sessionStorage.getItem('userType') === 'FSE' && enquiry.isQueued === true && enquiry.userRole === 'FSE' && (enquiry.status === "Verification Queued" || enquiry.status === "Verification in Progress" || enquiry.status && enquiry.status.toLowerCase() === "order placement queued") && enquiry.status !== "Verification Completed" && (
                    <div className="mt-2 ml-0">
                      <span className="specialpriceFSE ml-0 mr-1"></span>
                      {showViewEnquiry &&
                        <Tooltip title="Pull real-time information from SAP." placement="top">
                          <IconButton className={classes.actionButton} size="small"
                            onClick={async () => {
                              setLoading(true);
                              let data: any = await dispatch(verifyEnquiryQueueCheck(enquiry.customerType, enquiry.masterId, enquiry.customerId));
                              if (data && data.error && (Object.keys(data.error).length > 1)) {
                                sessionStorage.setItem("isProblemParts", "true");
                                setIsProblemParts(true);
                                // await dispatch(getFSEEnquiriesList(customerTypeAccess, {
                                //   "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')], fromDate: startDate ? startDate : formattedDate,
                                //   toDate: endDate ? endDate : today
                                // }))
                              } else if (data && data.message && ((data?.message?.message && data?.message?.message.toLowerCase() === 'verification completed') || (data?.message?.message && data?.message?.message.toLowerCase() === 'enquiry changed') || (data?.message?.message && data?.message?.message.toLowerCase() === 'verification in progress') || (data?.message?.message && data?.message?.message.toLowerCase() === 'order created'))) {
                                await dispatch(getFSEEnquiriesList(customerTypeAccess, {
                                  "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')], fromDate: startDate ? startDate : formattedDate,
                                  toDate: endDate ? endDate : today
                                }))
                              }
                              setLoading(false);
                            }}>
                            <RotateLeftIcon fontSize="inherit" className="mb-1 ml-0" />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                  )}
                </TableCell>
                : ""}
              {sessionStorage.getItem('userType') === 'FSE' ? "" :
                enquiry.isSpecialPriceRequested === true && customerTierType.toLowerCase() === "dealer" && sessionStorage.getItem('userType') !== 'FSE' ? "" :
                  <TableCell></TableCell>}
              <TableCell className={classes.bodyContent}>{enquiry.masterId}</TableCell>
              <TableCell className={classes.bodyContent}>{enquiry.masterReferenceNumber}</TableCell>
              <TableCell align="left" className={classes.bodyContent}>
                {/* {enquiry.make && Array.isArray(enquiry.make) && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? enquiry.make.map((brand: any) => brand === "Tradepoint" ? "ALAC" : brand).join(', ') : enquiry.make.toString()} */}
                {(customerTierType && customerTierType.toLowerCase() === 'tpcdealer' || customerTypeAccess.includes("tpcdealer")) ? 'Tradepoint' : ((customerTierType && customerTierType.toLowerCase() === 'workshop') || customerTypeAccess.includes("workshop")) ?
                  (enquiry.make && Array.isArray(enquiry.make) && enquiry.make.map((brand: any) => brand === "Tradepoint" ? "Tradepoint" : brand).join(', '))
                  :
                  ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop") || (customerTierType && customerTierType.toLowerCase() !== 'tpcdealer' || !customerTypeAccess.includes("tpcdealer"))) ?
                    (enquiry.make && Array.isArray(enquiry.make) && enquiry.make.map((brand: any) => brand === "Tradepoint" ? "ALAC" : brand).join(', '))
                    :
                    enquiry.make && Array.isArray(enquiry.make) && enquiry.make.toString()
                }
              </TableCell>
              <TableCell className={classes.bodyContent}>{!!enquiry.estimatedTotal &&
                (typeof enquiry.estimatedTotal === 'string' && enquiry.estimatedTotal.toLowerCase() !== "nan") ?
                parseFloat(getSellingPrice(enquiry.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
              <TableCell className={classes.bodyContent}>{moment(enquiry.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
              <TableCell className="mw-150p">
                <div className="dealer-table-content">{enquiry.userId ? enquiry.userId : "-"}</div>
                <div className="dealer-table-subcontent">{enquiry.createdBy ? enquiry.createdBy : "-"}</div>
              </TableCell>
              {/* <TableCell className={classes.bodyContent}>{enquiry.status}</TableCell>
               */}
              <TableCell className="mw-150p">
                <div className="dealer-table-content">{enquiry.status ? enquiry.status : "-"}</div>
                <div className="problem-parts">{sessionStorage.getItem('userType') === 'FSE' && enquiry.isQueued === true && enquiry.userRole === 'FSE' && enquiry.queuedStatusNote && enquiry.queuedStatusNote.toLowerCase() === 'problem parts' ? "( Problem Parts )" : sessionStorage.getItem('userType') === 'FSE' && enquiry.isQueued === true && enquiry.userRole === 'FSE' && (enquiry.status === "Verification Queued" || enquiry.status === "Verification in Progress" || enquiry.status && enquiry.status.toLowerCase() === "order placement queued") && isProblemParts ? "( Problem Parts )" : ""}</div>
              </TableCell>
              <TableCell className={classes.bodyContent}>
                {showViewEnquiry &&
                  <Tooltip title="View Order Enquiry" placement="top">
                    <IconButton className={classes.actionButton} size="small" onClick={async () => {
                      // requestPriceAccess();
                      {
                        sessionStorage.setItem("partsData", "")
                        sessionStorage.setItem('isCopyandCreate', "");
                        enquiry.subEnquiries && enquiry.subEnquiries.map((subEnq: any) => {
                          return (
                            <>
                              {sessionStorage.getItem('userType') === 'FSE' && enquiry.userRole === 'FSE' && enquiry.status === "Verification Completed" && subEnq && subEnq.estimatedTotal === 0 ?
                                sessionStorage.setItem("isEstTotalZero", "true") : sessionStorage.setItem("isEstTotalZero", "false")
                              }
                            </>
                          );
                        }
                        )
                      }
                      if (sessionStorage.getItem("isEstTotalZero") === "true") {
                        setLoading(true);
                        let data: any = await dispatch(verifyEnquiryQueueCheck(enquiry.customerType, enquiry.masterId, enquiry.customerId));
                        setLoading(false);
                        setIsEstTotalZero(true);
                        sessionStorage.setItem("updateAPICall", "true");
                      } else {
                        setIsEstTotalZero(false);
                        sessionStorage.setItem("updateAPICall", "false");
                      }
                      sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                      setEnquiryMaterdata(enquiry)
                      let data: any;
                      if (customerTierType && customerTierType.toLowerCase() === "dealer") {
                        data = await dispatch(getViewRequestStatusAPI(enquiry.masterId))
                      }
                      let userData: any;
                      if (sessionStorage.getItem('userType') === 'FSE') {
                        userData = await dispatch(getCustomerDetailsByCustomerID(enquiry.customerId));
                      }
                      sessionStorage.setItem("companyCurrencyType", userData && userData.data && userData.data.companyCurrencyType)
                      if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].expiredAfterApproval === true && data && data.data && data.data[0] && data.data[0].status === "Approved") {
                        setshowApproveDialog(true);
                      } else if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].expiredAfterApproval === false) {
                        if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                          sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                          // requestPriceAccess();
                          // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                            mode: 'view',
                            masterId: enquiry.masterId,
                            orderType: "Cancelled"
                          }))
                          sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                          history.push('/viewenquiry');
                        }
                        else {
                          // requestPriceAccess();
                          sessionStorage.setItem('isCopyandCreate', "");
                          sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                          // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                            mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                            masterId: enquiry.masterId
                          }))
                          sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                          history.push('/updateenquiry');
                        }
                      } else {
                        sessionStorage.setItem("partsData", "")
                        if (sessionStorage.getItem('userType') === 'FSE') {
                          sessionStorage.setItem('isCopyandCreate', "");
                          if (enquiry.userRole === 'FSE' && enquiry.userId === sessionStorage.getItem('webApplicationLoginId')) {
                            if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                              sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                              // requestPriceAccess();
                              // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                              sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                mode: 'view',
                                masterId: enquiry.masterId,
                                orderType: "Cancelled"
                              }))
                              sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                              history.push('/viewenquiry');
                            }
                            else {
                              // requestPriceAccess();
                              sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                              // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                              sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                                masterId: enquiry.masterId
                              }))
                              sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                              history.push('/updateenquiry');
                            }
                          } else {
                            sessionStorage.setItem('isCopyandCreate', "");
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: 'view',
                              masterId: enquiry.masterId,
                            }))
                            sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                            history.push('/viewenquiry');
                          }
                        } else {
                          if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry && enquiry.userRole === 'FSE') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                            sessionStorage.setItem('isCopyandCreate', "");
                            sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                            // requestPriceAccess();
                            // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: 'view',
                              masterId: enquiry.masterId,
                              orderType: "Cancelled"
                            }))
                            sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                            history.push('/viewenquiry');
                          }
                          else {
                            // requestPriceAccess();
                            sessionStorage.setItem('isCopyandCreate', "");
                            sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                            // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                              masterId: enquiry.masterId
                            }))
                            sessionStorage.setItem('isEstTotalZero', isEstTotalZero);
                            history.push('/updateenquiry');
                          }
                        }
                      }
                    }}>
                      <OpenInNewOutlinedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                }
                {/* {console.log("enquiryDetailsQueueCheck", enquiryDetailsQueueCheck && enquiryDetailsQueueCheck.data && enquiryDetailsQueueCheck.data[0] && enquiryDetailsQueueCheck.data[0].header && enquiryDetailsQueueCheck.data[0].header.enquiryProcessingStatusDescription)} */}
                {/*  enquiry.isQueued === true   this conditions we dont need to check for refresh as per dicussion with sandeep we only need to handle based on status */}
                {sessionStorage.getItem('userType') === 'FSE' && enquiry.userRole === 'FSE' && (
                  <>
                    {showCancelButton && showViewEnquiry &&
                      <Tooltip title="Cancel Order Enquiry" placement="top">
                        <IconButton className={classes.actionButton} size="small"
                          onClick={() => {
                            setSelectedMasterId(enquiry.masterId);
                            setEnquiryCusId(enquiry.customerId);
                            handleopenCloseCancelDialog(true)
                          }}>
                          <CancelIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    }
                  </>
                )}
                {sessionStorage.getItem('userType') !== 'FSE' && enquiry.userRole !== 'FSE' && (
                  <>
                    {showCancelButton && showViewEnquiry &&
                      <Tooltip title="Cancel Order Enquiry" placement="top">
                        <IconButton className={classes.actionButton} size="small"
                          onClick={() => {
                            setSelectedMasterId(enquiry.masterId)
                            handleopenCloseCancelDialog(true)
                          }}>
                          <CancelIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    }
                  </>
                )}

              </TableCell>
            </TableRow>
            <TableRow className={classes.collapseBg} hover role="checkbox" tabIndex={-1}>
              <TableCell className="p-0 w-100" colSpan={12}>
                <Collapse in={open > 0 && open === index + 1 ? true : false}>
                  <Box>
                    <Table aria-label="table">
                      <TableHead>
                        <TableRow>
                          <TableCell className={`${classes.subTableHead} pr-0`}>
                            <span className="mx-4 pl-4"></span>
                            Brand</TableCell>
                          <TableCell className={`${classes.subTableHead}`}>Reference No.</TableCell>
                          <TableCell className={classes.subTableHead}>Status</TableCell>
                          <TableCell className={classes.subTableHead}>Estimated Total (incl. of Vat)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {enquiry.subEnquiries && enquiry.subEnquiries.map((subEnq: any) => {
                          return (
                            <SubEnquiries key={subEnq.enquiryNumber} subEnq={subEnq} enquiry={enquiry} />
                          );
                        }
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                </Collapse>
              </TableCell>
            </TableRow>
          </>
        )}
      </React.Fragment>
    );
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseCancelDialog = (open?: any) => {
    setOpenCancelDialog(open ? true : false);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    loadEnquiryList(newPage)
    setPage(newPage);
  };

  const showCreateChangeEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (
    <Fragment>
      {loading && (<FullscreenLoader />)}
      {dataOrderRedux && dataOrderRedux.enquiryList && dataOrderRedux.enquiryList.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Order Enquiry
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="cardCommon p-3">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mb-2">Search Enquiry</h5>
            </div>
            <TextField
              value={searchText}
              onChange={(e) => {
                setsearchText(e.target.value);
                if (e.target.value.trim() === "") {
                  setLoading(true);
                  // loadEnquiryList();
                  setLoading(false);
                  // From: sprint42 below 3 lines commented due to onchange we removed on click on search only we are calling API
                  // loadEnquiryListOnClickOfSearch(page, e)
                  // if (e.target.value === '') {
                  //   loadEnquiryListOnClickOfSearch(page, e)
                  // }
                }
              }}
              size="small"
              className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Reference No" variant="outlined" />
            <Tooltip title="Maximum 31 days data can be tracked." placement="top">
              <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50 w-100">
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setstartDate={setstartDate}
                  setendDate={setendDate}
                  isEnableOnlyThirtyDays={true}
                  isDisableBeforeOneYear={true}
                  onChange={(start: any, end: any) => {
                    setstartDate(start);
                    setendDate(end);
                  }}
                />
              </div>
            </Tooltip>
            <div className={sessionStorage.getItem('userType') !== 'FSE' ? 'col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0' : "col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-0 "}>
              <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                {sessionStorage.getItem('userType') === 'FSE' ?
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={status}
                    onChange={(e) => {
                      setstatus(e.target.value)
                    }}
                    label="Status"
                  >
                    <MenuItem key={ALL_STATUS}
                      value={ALL_STATUS}>All</MenuItem>
                    {getFSEEnquiryStatusData && getFSEEnquiryStatusData.data && getFSEEnquiryStatusData.data.length > 0 && getFSEEnquiryStatusData.data.map((status: any, i: any) => (
                      <MenuItem key={status}
                        value={status}>{status}</MenuItem>
                    ))}
                  </Select>
                  :
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={status}
                    onChange={(e) => {
                      setstatus(e.target.value)
                    }}
                    label="Status"
                  >
                    <MenuItem key={ALL_STATUS}
                      value={ALL_STATUS}>{sessionStorage.getItem('userType') === 'FSE' ? "All FSE" : "All"}</MenuItem>
                    {enquiryStatus && enquiryStatus.data && enquiryStatus.data.data && enquiryStatus.data.data.length > 0 && enquiryStatus.data.data.map((status: any, i: any) => (
                      <MenuItem key={status}
                        value={status}>{status}</MenuItem>
                    ))}
                  </Select>}
              </FormControl>
            </div>
            {sessionStorage.getItem('userType') !== 'FSE' && (
              <Button
                className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  loadEnquiryList()
                }}
              >Search
              </Button>
            )}
            {sessionStorage.getItem('userType') !== 'FSE' && (
              <div className="mt-4 d-flex flex-row">
                <div className="col-2 col-sm-6 col-md-2 col-lg-2 ml-2 d-flex flex-row">
                  <Checkbox checked={isMyEnquiryChecked} onChange={() => {
                    setIsMyEnquiryChecked(!isMyEnquiryChecked)
                  }} />
                  <span className="myOrders">My Enquiry</span>
                </div>
                <div className="col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0 pr-0">
                </div>
                <div className="col-2 col-sm-5 col-md-3 col-lg-3 d-inline-flex pl-2 pr-0">
                </div>
                <div className='offset-3 col-3'>
                  <Button variant="contained" className="cancel-button font-size-11 mr-3 mt-1" onClick={() => resetFiltersForCustomer()}>Reset Filters</Button>
                </div>
              </div>
            )}
            {sessionStorage.getItem('userType') === 'FSE' && (
              <div className="mt-4 d-flex flex-row">
                <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0">
                  <TextField
                    id="customerId"
                    variant="outlined"
                    size="small"
                    onClick={handleClick}
                    value={selectedCustomerId}
                    label="Filter By Customer"
                    aria-controls="customized-menu"
                    className="commonRoundedInputs w-100"
                    aria-haspopup="true"
                    placeholder="Filter By Customer"
                  >
                  </TextField>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    className="dealer-custom-dropdown"
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <div>
                      <div className="mt-2">
                        <div className="searchDealer">
                          <IconButton
                            edge="start"
                            className="ml-2"
                            disabled
                          >
                            <SearchIcon fontSize="inherit" />
                          </IconButton>
                          <TextField value={searchTextByID} onChange={(e) => { onSearchCustomerId(e.target.value) }}
                            size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                        </div>
                      </div>
                      {customerId && customerId.length > 0 && customerId.map((sapId: any, i: any) => (
                        <Grid key={i} container className="border-bottom">
                          <MenuItem key={i} value={sapId.sapCustomerId}>
                            <ListItemText primary={sapId.sapCustomerId} onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }} /><p className="px-5" onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }}> {" "} {" "} {sapId.name}</p>
                          </MenuItem>
                        </Grid>
                      ))}
                      {customerId && customerId.length === 0 && (
                        <Grid container >
                          <div className="d-flex justify-content-center text-center mx-auto">No Customers found.</div>
                        </Grid>
                      )}
                    </div>
                  </StyledMenu>
                </div>
                <div className="col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0 pr-0">
                  <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                    <InputLabel id="demo-simple-select-outlined-label">Filter By FSE</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={fseName}
                      onChange={(e) => {
                        setFseName(e.target.value)
                      }}
                      label="Filter By FSE"
                    >
                      <MenuItem value={"All"}>{sessionStorage.getItem('userType') === 'FSE' ? "All FSE" : "All"} </MenuItem>
                      {fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((fse: any, i: any) => (
                        <MenuItem key={fse.name}
                          value={fse.webApplicationLoginId}>{fse.name} - {fse.webApplicationLoginId}</MenuItem>
                      ))}
                      <MenuItem value={sessionStorage.getItem('userType') === 'FSE' ? "Cust Only" : ""}> {sessionStorage.getItem('userType') === 'FSE' ? "Cust Only" : "None"} </MenuItem>
                    </Select>
                  </FormControl>
                </div>
                {sessionStorage.getItem('userType') === 'FSE' && (
                  <div className="col-2 col-sm-3 col-md-2 col-lg-2 d-inline-flex pl-2 pr-0">
                    <Checkbox checked={isQueuedEnquirieChecked} onChange={() => {
                      setIsQueuedEnquirieChecked(!isQueuedEnquirieChecked);
                      setLoading(true);
                      loadEnquiryList();
                      setLoading(false);
                    }} />
                    <span className="myOrders">Queued Enquiries</span>
                  </div>
                )}
                <div className='offset-3 col-3'>
                  <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => resetFilters()}>Reset Filters</Button>
                  <Button
                    className="font-size-11 addOrderEnqButton"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      loadEnquiryList()
                    }}
                  >Search
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        text="Order Enquiry has been cancelled successfully"
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }}
      />

      <CancelDialog
        open={openCancelDialog}
        text="Are you sure to cancel this Order Enquiry ?"
        maxWidth="sm"
        handleopenClose={async (data: any) => {
          if (data) {
            await dispatch(cancelEnquiry(customerTierType, selectedMasterId, enqCusId));
            if (sessionStorage.getItem('userType') !== 'FSE') {
              dispatch(getEnquiriesList(customerTierType, {
                fromDate: startDate ? startDate : formattedDate,
                toDate: endDate ? endDate : today
              }, isMyEnquiryChecked));
            }
            if (sessionStorage.getItem('userType') === 'FSE') {
              dispatch(getFSEEnquiriesList(customerTypeAccess, {
                "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')], fromDate: startDate ? startDate : formattedDate,
                toDate: endDate ? endDate : today
              }))
            }
            setSelectedMasterId(null)
            handleopenCloseSnackbar(true)
          }
          handleopenCloseCancelDialog(false)
        }}
      />

      <div className="row mt-4">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title mt-2">List of Order Enquiries</h5>
            <div className="ml-auto legendItemCreateEnquiry m-2 mt-2">
              {sessionStorage.getItem('userType') === 'FSE' && (
                <>
                  <span className="specialprice"></span>
                  <span className="legendItemLabel mr-4 ml-2 mt-1">Verified Queued Enquiries</span>
                  <span className="specialpriceFSE"></span>
                  <span className="legendItemLabel mr-4 ml-2">Queued Enquiries in process</span>
                </>
              )}
              {customerTierType && customerTierType !== "Workshop" && customerTierType !== "tpcdealer" && customerTierType && customerTierType !== "Int. Business" && customerTierType && customerTierType !== "Fleet" && customerTierType && customerTierType !== "Government" && isShowSPR() && currentTier !== "" && (
                <>
                  <span className="specialprice"></span>
                  <span className="legendItemLabel mr-4 ml-2 mt-1">Special Price</span>
                </>
              )}
            </div>
            {showCreateChangeEnquiry && sessionStorage.getItem('userType') !== 'FSE' &&
              <Button
                variant="contained"
                color="primary"
                className="rounded-pill"
                startIcon={<AddIcon />}
                onClick={() => {
                  dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  sessionStorage.setItem('isImportFromOES', 'false');
                  history.push('/createenquiry');
                }}>
                Create Order Enquiry
              </Button>
            }
            {sessionStorage.getItem('userType') === 'FSE' && (
              <Button
                variant="contained"
                color="primary"
                className="rounded-pill"
                startIcon={<AddIcon />}
                onClick={() => {
                  if (sessionStorage.getItem('userType') === 'FSE') {
                    setIsOrderEnquiryCreated(true);
                    handleChooseCustomer();
                    dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  } else {
                    dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                    sessionStorage.setItem('isImportFromOES', 'false');
                    history.push('/createenquiry');
                  }
                }}>
                Create Order Enquiry
              </Button>
            )}
          </div>
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table aria-label="table">
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHeadSmall}></TableCell>
                    <TableCell></TableCell>
                    <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                    <TableCell className={classes.tableHead}>Reference No.</TableCell>
                    <TableCell className={classes.subTableHead}>Brand</TableCell>
                    <TableCell>
                      <h6 className="tableHead">Estimated Total</h6>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Created On</TableCell>
                    <TableCell>
                      <h6 className="tableHead">Created By</h6>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
                    <>
                      {enquiryList.data.data.map((enquiry: any, index: any) => {
                        return (
                          <EnquiryList key={index} index={index} enquiry={enquiry} setSelectedMasterId={setSelectedMasterId} setEnquiryCusId={setEnquiryCusId} />
                        );
                      })}
                    </>
                  )}
                </TableBody>
              </Table>
              {((enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length === 0) || (enquiryList && enquiryList.data && enquiryList && !enquiryList.data.data)) && (
                <div className="mt-5 justify-content-center mx-auto text-center">No Enquiries Found.</div>
              )}
            </TableContainer>
            <ExpiredDialog24Hrs
              open={showApproveDialog}
              setOpenSnackbar={setOpenSnackbar}
              setSuccessMessage={setSuccessMessage}
              enquiryMaterdata={enquiryMaterdata}
              handleopenCloseSnackbar={handleopenCloseSnackbar}
              handleopenClose={(data: any, iscopy?: any, isCancel?: any) => {
                if (isCancel) {
                  setshowApproveDialog(false)
                } else {
                  if (iscopy) {
                    setshowApproveDialog(data ? true : false)

                  } else {
                    setshowApproveDialog(data ? true : false)
                    if ((enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === 'failed') || (enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === "enquiry cancelled") || (enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === "fully cancelled") || enquiryMaterdata.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {

                      dispatch(getViewRequestStatusAPI(enquiryMaterdata.masterId))
                      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                        mode: 'view',
                        masterId: enquiryMaterdata.masterId,
                        orderType: "Cancelled"
                      }))
                      history.push('/viewenquiry');
                    }
                    else {
                      dispatch(getViewRequestStatusAPI(enquiryMaterdata.masterId))
                      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                        mode: enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() !== 'failed' && enquiryMaterdata.status.toLowerCase() !== "enquiry cancelled" && enquiryMaterdata.status.toLowerCase() !== "cancel" && enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                        masterId: enquiryMaterdata.masterId
                      }))
                      history.push('/updateenquiry');
                    }
                  }
                }
              }}
            />
            {openChooseCustomerDialog && <ChooseCustomerDialog openChooseCustomerDialog={openChooseCustomerDialog} openCloseChooseCustomer={openCloseChooseCustomer} isOrderEnquiryCreated={isOrderEnquiryCreated} />}
            <div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={enquiryList && enquiryList && enquiryList.totalCount ? enquiryList.totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderEnquiry;
