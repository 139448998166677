import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  cancelText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit',
  }
}));

const CancelDialog = (props: any) => {
  const { open, text, handleopenClose, handleCancelConfirmation, maxWidth } = props;
  const classes = useStyles();

  const handleYesClick = () => {
    handleopenClose(true);
    handleCancelConfirmation(true);
  };

  const handleCancelClick = () => {
    handleopenClose(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => handleopenClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth || false}
    >
      <DialogContent>
        <div className={classes.icon}>
          <WarningIcon className="delete-warning" fontSize="small" />
        </div>
        <DialogContentText id="alert-dialog-description" component="div">
          <div className={`mt-3 ${classes.cancelText}`}>
            {text}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={`mx-4 ${classes.yes}`} onClick={handleYesClick} autoFocus>
          Yes
        </Button>
        <Button className={`mr-4 ${classes.cancel}`} onClick={handleCancelClick}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;
