/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { postConfrimOrder } from "../../store/actions/createUpdateEnquiry.actions";
import _, { get } from "lodash";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24,
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function ConfirmDialogEnquiry(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const { handleopenClose } = props;
    const [verify, setVerify] = React.useState<any>(false);
    const classes = useStyles();
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    // eslint-disable-next-line no-useless-escape
    const { getCustomerDetailsBySapId }: any = useSelector((state: any) => state.dealer);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const [customerTierType, setCustomerTierType] = React.useState(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType
        : get(profileDetails, 'data.data.customerInfo.customerTierType', ''));
    const { enquirySessionId }: any = useSelector(
        (state: any) => state.createUpdateEnquiry
    );
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const onClickViewHistory = async () => {
        // props.ConfirmButtonUpdateAPI();
        const data: any = await dispatch(postConfrimOrder(customerTierType, props.enquiryId, enquirySessionId));
        if (data && data.message && data.message.status === "E") {
            handleopenCloseErrorSnackbar(true);
            showErrorMessage(data && data.error);
            handleopenCloseSnackbar(false);
        } else {
            setVerify(true);
        }
    }

    return (
        <Fragment>
            <Dialog
                open={props.open ? true : false}
                // onClose={() => { props.handleopenClose(false); props.handleopenCloseSnackbar(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={successMessage} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={errorMessage} />
                <div className={classes.root}>
                    <div className="col-12 mt-3 p-0">
                        {!verify && (
                            <div className='px-2 mt-3 mb-2'>
                                <div className='mt-4 mb-2'>
                                    <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                        <h4 className="shift-to-party-page-title px-4">
                                            Confirm Order
                                        </h4>
                                    </Typography>

                                </div>
                                <div className='mt-3 mb-3 px-1'>
                                    <h5 className="info-sub-header-document font-size-15 px-4">Are you sure you want to confirm order ?</h5>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                {verify && (
                    <>
                        <div className="d-flex justify-content-center mt-4">
                            <CheckCircleIcon className="quotation-created" fontSize="small" />
                        </div>
                        <div className='mt-2 mb-2'>
                            <DialogContentText id="alert-dialog-description">
                                <div className="mt-2">
                                    <h5 className="text-center submit-quotation-dialog-content ">Order Confirmed</h5>
                                </div>
                                <div className="pr-3 pl-3 mb-2">
                                    <h3 className="text-center document-content-fontcolor pt-3 pb-2 pr-3 pl-3">Your order has been confirmed, and the FSE will notify you after placing the order.</h3>
                                </div>
                            </DialogContentText>
                            <div className='pl-4 mb-4 pt-2'>
                                <div className='d-flex justify-content-center'>
                                    <Button variant="contained" className={classes.closeButton}
                                        onClick={() => { handleopenClose(); props.handleopenCloseSnackbar(false); setVerify(false); history.push('/orderenquiry') }}
                                    >Close
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="row-space-start ml-auto">
                    <DialogActions className="mb-1 justify-content-end">
                        {!verify && (
                            <div className="row-space-start ml-auto">
                                <DialogActions className="mt-3 mb-1 justify-content-end">
                                    <Button variant="contained"
                                        color="primary" className="submit-button-chooseCustomer"
                                        onClick={() => { onClickViewHistory(); }}
                                    >Confirm
                                    </Button>
                                    <Button variant="contained" className={classes.closeButton}
                                        onClick={() => { handleopenClose(); props.handleopenCloseSnackbar(false); setVerify(false); }}
                                    >Cancel
                                    </Button>
                                </DialogActions>
                            </div>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </Fragment>
    );
}