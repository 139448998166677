/* eslint-disable */
import { API_ENDPOINTS, CREATE_UPDATE_ENQUIRY, REGION_EMAIL } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const createUpdateEnquiryService = {
    createEnquiry,
    updateEnquiry,
    verifyEnquiry,
    cancelEnquiry,
    getEnquiryDetails,
    placeOrder,
    getEnquiryDetailsQueueCheck,
    deleteLostSaleReport,
    getTecdocData,
    getb2cAccessToken,
    userPaymentData,
    offlinePaymentData,
    getOrderSummaryData,
    sendEmail,
    getDLEmailByRegion,
    getQuotationDetailsQueueCheck,
    sendEmailProcurePart,
    getRefreshOrderDetailsAPI,
    postConfrimOrder,
    getOrderLogs,
    sendEmailOrderLogs,
    getOrderLogsLatestCount,
    deleteInvalidParts,
    // getTechDocPartList
};

// customerTierType, masterId, sendData

async function placeOrder(masterId: any, data: any) {
    if (sessionStorage.getItem('userType') === 'FSE') {
        data["customerId"] = sessionStorage.getItem("selectedSapCustomerId");
        data["userRole"] = "FSE"
    }
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId + CREATE_UPDATE_ENQUIRY.ORDERS;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrderSummaryData(customerTierType: any, masterId: any, orderNumber: any) {
    const type = customerTierType && customerTierType.toLowerCase();
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/order/${masterId}/${orderNumber}/payment-summary`;
    const id = sessionStorage.getItem('webApplicationLoginId')

    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN",
            "webApplicationLoginId": id
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function createEnquiry(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE;
    if (sessionStorage.getItem('userType') === 'FSE') {
        data['userRole'] = 'FSE'
        data["customerId"] = sessionStorage.getItem("selectedSapCustomerId");
        data["customerName"] = sessionStorage.getItem("CompanyName");
        if (data.customerType === 'int. business') {
            if (sessionStorage.getItem("orderCreatedBy") === 'AA') {
                data["salesData"] = {
                    salesOffice: sessionStorage.getItem("teSalesOffice"),
                    orderCreatedFor: sessionStorage.getItem("orderCreatedBy")
                }
            } else {
                data["salesData"] = {
                    orderCreatedFor: sessionStorage.getItem("orderCreatedBy")
                }
            }
        } else if (data.customerType && (data.customerType.toLowerCase() === 'workshop' || data.customerType.toLowerCase() === 'tpcdealer')) {
            data['customerAccountType'] = sessionStorage.getItem('orderType');
            data["salesData"] = {
                salesOffice: sessionStorage.getItem('selectOffice'),
                orderType: sessionStorage.getItem('orderType')
            }
        }
    }
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function sendEmail(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/sendEmailZeroPricePartNumberList`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteInvalidParts(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/deleteInvlidPart`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function sendEmailProcurePart(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/sendEmailProcmentPartNumberList`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function updateEnquiry(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.UPDATE + '/' + masterId;
    if (sessionStorage.getItem('userType') === 'FSE') {
        data['userRole'] = 'FSE'
        data["customerId"] = sessionStorage.getItem("selectedSapCustomerId");
        data["customerName"] = sessionStorage.getItem("CompanyName");
        if (data.customerType === 'int. business') {
            if (sessionStorage.getItem("orderCreatedBy") === 'AA') {
                data["salesData"] = {
                    salesOffice: sessionStorage.getItem("teSalesOffice"),
                    orderCreatedFor: sessionStorage.getItem("orderCreatedBy")
                }
            } else {
                data["salesData"] = {
                    orderCreatedFor: sessionStorage.getItem("orderCreatedBy")
                }
            }
        } else if (data.customerType && (data.customerType.toLowerCase() === 'workshop' || data.customerType.toLowerCase() === 'tpcdealer')) {
            data["salesData"] = {
                salesOffice: sessionStorage.getItem('finalSelectOffice') ? sessionStorage.getItem('finalSelectOffice') : sessionStorage.getItem('selectOffice'),
                orderType: sessionStorage.getItem('finalOrderType') ? sessionStorage.getItem('finalOrderType') : sessionStorage.getItem('orderType')
            }
        }
    }
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {

            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function cancelEnquiry(masterId: any, data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId + CREATE_UPDATE_ENQUIRY.CANCEL;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {

            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function verifyEnquiry(masterId: any, data: any) {
    if (sessionStorage.getItem('userType') === 'FSE') {
        data["customerId"] = sessionStorage.getItem("selectedSapCustomerId");
        data["userRole"] = "FSE"
    }

    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + '/' + masterId + CREATE_UPDATE_ENQUIRY.VERIFY;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getDLEmailByRegion(region: string) {
    const apiPath = `${API_ENDPOINTS.CREATE_UPDATE_ENQUIRY}${CREATE_UPDATE_ENQUIRY.CREATE}${REGION_EMAIL.GET_DL_EMAIL_REGION}/${region}`
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
    }
    return await makeRequest(options)
}

async function getRefreshOrderDetailsAPI(customerType?: any, loginId?: any, sapCustomerId?: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + `/users/formField/masterData?dataType=${customerType}&loginId=${loginId}&sapCustomerId=${sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getEnquiryDetails(customerTierType: any, masterId: any, isOrder: any = false, quotation: any, orderLogRead?: any) {
    let apiPath;
    if (sessionStorage.getItem('userType') !== 'FSE') {
        const type = customerTierType && customerTierType.toLowerCase();;
        apiPath = quotation ? API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.GETSINGLE + '/' + masterId + `?customerType=${type}&isOrder=${isOrder}&enquiryType=quotation` : API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.GETSINGLE + '/' + masterId + `?customerType=${type}&isOrder=${isOrder}`;
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
        apiPath = quotation ? API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.GETSINGLE + '/FSE/' + masterId + `?&isOrder=${isOrder}&enquiryType=quotation` : API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.GETSINGLE + '/FSE/' + masterId + `?isOrder=${isOrder}`;
    }

    if (orderLogRead) {
        apiPath += `&read=${orderLogRead}`
    }

    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {

            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getEnquiryDetailsQueueCheck(customerTierType: any, b2bEnquiryId: any, loginId: any, customerId?: any) {
    const type = customerTierType && customerTierType.toLowerCase();
    let apiPath: any;
    if (sessionStorage.getItem('userType') === 'FSE') {
        apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + CREATE_UPDATE_ENQUIRY.DETAILS + `?b2bEnquiryId=${b2bEnquiryId}&loginId=${loginId}&customerType=${type}&customerId=${customerId ? customerId : sessionStorage.getItem("selectedSapCustomerId")}&userRole=FSE`;
    } else {
        apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + CREATE_UPDATE_ENQUIRY.DETAILS + `?b2bEnquiryId=${b2bEnquiryId}&loginId=${loginId}&customerType=${type}`;
    }
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrderLogs(b2bEnquiryId: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + CREATE_UPDATE_ENQUIRY.LOGS + `${b2bEnquiryId}`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrderLogsLatestCount(b2bEnquiryId: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + CREATE_UPDATE_ENQUIRY.LOGS + `${b2bEnquiryId}?read=true`;
    const options = {
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function sendEmailOrderLogs(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/sendOrderLogsEmails`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

// "getArticles": {
//         "articleCountry": "AE",
//         "provider": 23767,
//         "searchQuery": "J1344010",
//         "searchType": 3,
//         "lang": "en",
//         "perPage": 100,
//         "page": 1,
//         "includeAll": true
//     }

// async function getTechDocPartList(data: any) {
//     // const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/sendOrderLogsEmails`;
//     const apiPath = 'https://autob2bdev.corp.al-futtaim.com/automotive/b2b/v1/cms/enquiries/techAllianceSearch';
//     const options = {
//         path: apiPath,
//         method: "POST",
//         bodyObj: data,
//         customHeaders: {},
//         formdata: true
//     };
//     console.log(" Tech-Doc==> " , apiPath)
//     return await makeRequest(options)
// }

async function getQuotationDetailsQueueCheck(enquiryNumber: any, b2bEnquiryId: any, loginId: any, sapCustomerId?: any) {
    let apiPath: any;
    if (sessionStorage.getItem('userType') === 'FSE') {
        apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/quotation/enquiry-details?b2bEnquiryId=${b2bEnquiryId}&enquiryNumber=${enquiryNumber}&webApplicationLoginId=${loginId}&sapCustomerId=${sapCustomerId}&customerId=${sapCustomerId}&userRole=FSE`;
    } else {
        apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/quotation/enquiry-details?b2bEnquiryId=${b2bEnquiryId}&enquiryNumber=${enquiryNumber}&webApplicationLoginId=${loginId}&sapCustomerId=${sapCustomerId}`;
    }

    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function deleteLostSaleReport(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/deleteLostSalesReport`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function userPaymentData(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/payment-request`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function offlinePaymentData(data: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/payment-request/paymentMode`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function postConfrimOrder(masterId: any, data: any) {
    if (sessionStorage.getItem('userType') === 'FSE') {
        data["customerId"] = sessionStorage.getItem("selectedSapCustomerId");
        data["userRole"] = "FSE"
    }
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/${masterId}/confirmorders`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": 2001,
            "Accept-Language": "EN"
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getTecdocData(id: any) {
    const apiPath = API_ENDPOINTS.CREATE_UPDATE_ENQUIRY + CREATE_UPDATE_ENQUIRY.CREATE + `/tecAllience/requestedData/${id}`
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function getb2cAccessToken() {
    const apiPath = API_ENDPOINTS.LOGON_ENDPOINT + '/users/oauth/b2c/accessToken'
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}




