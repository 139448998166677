/* eslint-disable */
import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import EnquiryInfo from './EnquiryInfo';
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import FullscreenLoader from './../../common/components/FullscreenLoader';
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import { useEffect, Dispatch, useState } from 'react';
import { getOrdersList, setPageNumber } from "../../store/actions/orderHistory.actions";
import { getLimitedFSEEnquiriesList, getlimitedEnquiriesList } from '../../store/actions/orderEnquiry.action';
import "./Catalogue.css";
import { setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';

const useStyles = makeStyles((theme) => ({
  addOrderEnqButton: {
    backgroundColor: '#1A9FE0',
    width: 190,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
}));


const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: '0.125rem 175',
    display: 'flex',
    alignItems: 'center',
    width: 560,
    borderRadius: "1.25rem",
    height: "2.25rem",
    minWidth: 200,
    margin: 0,
    backgroundColor: "#f8f8f8",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '0.75rem',
    padding: '1rem',
    color: '#999999',
    fontWeight: 800,
    fontFamily: 'Nunito'
  },
  iconButton: {
    color: '#444444',
    fontWeight: 700
  },
}));


const SelectEnquiry = (props: any) => {
  const [myEnquiry, setMyEnquiry] = React.useState(true);
  const [newEnquiry, setNewEnquiry] = React.useState(false);
  const [searchText, setSearchText] = React.useState("")
  const [count, setCount] = React.useState(0);
  const dataOrderRedux: any = useSelector((state: any) => state.orderEnquiry);
  const dispatch: Dispatch<any> = useDispatch();

  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);

  const b2cUserId = get(profileDetails, "data.data.basicInfo.b2cUserId", "");
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const [page, setPage] = useState(0);

  const classes = useStyles();
  const classes2 = useStyles2();
  const history = useHistory();
  const showCreateChangeEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  useEffect(() => {
    if (myEnquiry) {
      let searchData: any = {}
      searchData["userId"] = b2cUserId && b2cUserId
      if (sessionStorage.getItem('userType') === 'FSE') {
        dispatch(getLimitedFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')] }))
      } else {
        dispatch(getlimitedEnquiriesList(customerTierType, searchData, page));
      }
    } else {
      if (sessionStorage.getItem('userType') === 'FSE') {
        dispatch(getLimitedFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')] }))
      } else {
        dispatch(getlimitedEnquiriesList(customerTierType, {}, page));
      }
    }
    setCount(1)
  }, [myEnquiry])

  const handleClick = (e: any) => {
    setMyEnquiry(e.target.checked)
  }

  const newOrder = (arr: any) => {
    setNewEnquiry(true);
    if (arr.length === 0) {
      setNewEnquiry(false)
    } else setNewEnquiry(true)
  }


  return (
    <div>
      {dataOrderRedux && dataOrderRedux.enquiryList && dataOrderRedux.enquiryList.loading && <FullscreenLoader />}
      <Dialog
        open={props.open}
        disableEscapeKeyDown
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            props.handleopenClose(false)
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <div className="d-flex flex-row justify-content-between">
          <h4 className="page-title pl-4 pt-4">Select Enquiry</h4>
          <div className="d-flex flex-row pt-3">
            <div className="pt-1">
              <Checkbox
                checked={myEnquiry}
                onClick={handleClick}
                className=""
              />
            </div>
            <span className="pt-3 pr-2 fonts-catalogue">My Enquiries</span>
          </div>
        </div>
        {/* <div className="col-12 pr-4 mr-1 p-4">
          <Paper component="div" className={`${classes2.root} `}>
            <InputBase
              value={searchText}
              onChange={handleChange}
              className={classes2.input}
              placeholder="Search by Enquiry No Referance No"
              inputProps={{ 'aria-label': 'Search' }}
            />
            <IconButton onClick={() => props.callAPI()}>
              <SearchIcon className={classes2.iconButton} fontSize="small" />
            </IconButton>
          </Paper>
        </div> */}
        <div >
          <EnquiryInfo newOrder={newOrder} searchText={searchText} myEnquiry={myEnquiry} />
        </div>
        <div className="d-flex flex-row">
          <DialogActions className="mt-3 mb-2 justify-content-end">
            <Button variant="contained"
              disabled={showCreateChangeEnquiry === false ? true : newEnquiry === true ? true : false}
              color="secondary"
              className={`fonts-catalogue-button createFavourite mr-2 ml-4 ${(showCreateChangeEnquiry === false || newEnquiry === true) && "bgchanges-oes"}`}

              onClick={() => { sessionStorage.setItem('isImportFromOES', 'true'); history.push("/createenquiry"); sessionStorage.setItem('isUpdate', 'false') }}
            >
              Create New Enquiry
            </Button>
            <div className="ml-5 float-end pl-3">
              <Button variant="contained"
                disabled={showCreateChangeEnquiry === false ? true : newEnquiry === false ? true : false}
                color="secondary"
                className={`fonts-catalogue-button createFavourite mr-2 ml-5 ${(showCreateChangeEnquiry === false || newEnquiry === false) && "bgchanges-oes"}`} onClick={() => {
                  sessionStorage.setItem('isImportFromOES', 'true');
                  sessionStorage.setItem('isUpdate', 'true');
                  history.push('/updateenquiry');
                  dispatch(setOrderEnquiryDetails('create'));
                }}>
                Continue
              </Button>
              <Button variant="contained" className={`${classes.closeButton} fonts-catalogue-button`} onClick={() => { props.handleopenClose(false); sessionStorage.setItem('enquiryModeDetails', ""); sessionStorage.setItem('partsData', ''); }}>Cancel</Button>
            </div>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  )
}
export default SelectEnquiry;
