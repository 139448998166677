import { CREATE_UPDATE, CREATE_UPDATE_QUEUE_CHECK, VERIFIED_ORDER_LOGS, SELECTED_PARTS, TECH_DOC_PARTS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  enquiryInfo: null,
  enquiryDetailsQueueCheck: null,
  enquirySessionId: null,
  enquiryId: null,
  getRefreshOrderQueuedData: null,
  getOrderLogsDetails: null,
  getTechDocPartList: null
};

function createEnquiryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case CREATE_UPDATE.RESET_ENQUIRY_INFO:
      return {
        ...state,
        enquiryInfo: null,
        enquiryDetailsQueueCheck: null,
        getRefreshOrderQueuedData: null,
        getOrderLogsDetails: null,
        enquiryId: null,
        getTechDocPartList: null,
      }

    case TECH_DOC_PARTS.GET_TECH_DOC_PART_FETCHING:
      return {
        ...state,
        getTechDocPartList: { loading: true, data: null, err: null }
      };
    case TECH_DOC_PARTS.GET_TECH_DOC_PART_SUCCESS:
      return {
        ...state,
        getTechDocPartList: { loading: false, data: action.payload.data.data, err: null }
      };
    case TECH_DOC_PARTS.GET_TECH_DOC_PART_ERROR:
      return {
        ...state,
        getTechDocPartList: { loading: false, data: null, err: action.payload }
      };

    case CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING:
      return {
        ...state,
        enquiryInfo: { loading: true, data: null, err: null }
      };
    case CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS:
      return {
        ...state,
        enquiryInfo: { loading: false, data: action.payload.data.data, err: null }
      };
    case CREATE_UPDATE.POST_CREATE_UPDATE_ERROR:
      return {
        ...state,
        enquiryInfo: { loading: false, data: null, err: action.payload }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_FETCHING:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: true, data: null, err: null }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_SUCCESS:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: false, data: action.payload.data.data, err: null }
      };
    case CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_ERROR:
      return {
        ...state,
        enquiryDetailsQueueCheck: { loading: false, data: null, err: action.payload }
      };

    case VERIFIED_ORDER_LOGS.GET_VERIFIED_ORDER_LOGS_FETCHING:
      return {
        ...state,
        getOrderLogsDetails: { loading: true, data: null, err: null }
      };
    case VERIFIED_ORDER_LOGS.GET_VERIFIED_ORDER_LOGS_SUCCESS:
      return {
        ...state,
        getOrderLogsDetails: { loading: false, data: action.payload.data.data, err: null }
      };
    case VERIFIED_ORDER_LOGS.GET_VERIFIED_ORDER_LOGS_ERROR:
      return {
        ...state,
        getOrderLogsDetails: { loading: false, data: null, err: action.payload }
      };

    case CREATE_UPDATE.REFRESH_DETAILS_API_FETCHING:
      return {
        ...state,
        getRefreshOrderQueuedData: { loading: true, data: null, err: null }
      };
    case CREATE_UPDATE.REFRESH_DETAILS_API_SUCCESS:
      return {
        ...state,
        getRefreshOrderQueuedData: { loading: false, data: action.payload.data.data, err: null }
      };
    case CREATE_UPDATE.REFRESH_DETAILS_API_ERROR:
      return {
        ...state,
        getRefreshOrderQueuedData: { loading: false, data: null, err: action.payload }
      };

    case CREATE_UPDATE.GET_UNIQUE_ENQUIRY_ID:
      return {
        ...state,
        enquirySessionId: action.payload.data
      }
    case CREATE_UPDATE.SET_ENQUIRY_ID:
      return {
        ...state,
        enquiryId: action.payload
      }
    case CREATE_UPDATE.SET_ORDER_LOGS:
      return {
        ...state,
        getOrderLogsDetails: action.payload
      }
    case SELECTED_PARTS.GET_SELECTED_PARTS_SUCCESS:
      return {
        ...state,
        selectedParts: action.payload
      }
    default:
      return state;
  }
};

export default createEnquiryReducer;
