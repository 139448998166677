/* eslint-disable  */
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import "./OrderHistory.css";
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ReturnRequestSnackbar from "../../common/components/returnRequestSnackbar";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import DatePicker from '../../common/components/dateRangePicker';
import Checkbox from "@material-ui/core/Checkbox";
import { getOrdersList, getOrderId, getInvoice, resetInvoice, setPageNumber, getOrderStatus } from "../../store/actions/orderHistory.actions";
import moment from "moment-timezone";
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { isEmpty, isString } from 'lodash';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import { getDeliveryList } from "../../store/actions/delivery.actions";
import { getNewBrand } from "../../common/components/util";
import { useCallback } from "react";
import { ALL_STATUS, viewDeliveryOrderStatuses } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";
import Dialog from '@material-ui/core/Dialog';
import HistoryMakePaymentDialog from "../CreateEnquiry/OrderHistoryMakePaymentDialog";
import OrderHistoryPayment from "./OrderHistoryPayment";
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import _, { get } from "lodash";
import { getOrderSummary } from '../../store/actions/orderSummary.actions';
import { ViewDelivery } from "../../store/actions/delivery.actions";
import { selectedViewDelivery } from "../../store/actions/delivery.actions";
import { getOrderSummaryDetails } from '../../store/actions/orderSummary.actions';
import { getViewRequestStatusAPI } from "../../store/actions/requestSpecialPrice.actions";
import { isVewingFromOrderHistory } from "../../store/actions/orderHistory.actions"
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help"
import { quotationDetailsViewOrder } from "../../store/actions/quotation.action";
import UploadLpoViewOrder from "./UploadLpoViewOrder";
import OrderHistoryViewQuotation from "../Quotation/OrderHistoryViewQuotation";
import { downloadQuotation, viewLPO } from "../../store/actions/quotation.action";
import OrderHistoryViewLPO from "../Quotation/OrderHistoryViewLPODialog";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import { getFSECustomerList, getCustomerDetailsByCustomerID } from "../../store/actions/dealer.action";
import { getFSEList } from '../../store/actions/orderEnquiry.action';

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  rowHighlight: {
    background: 'rgba(67, 95, 107, 0.15)',
    color: '#666666',
    fontWeight: 700
  },
  orangeRowHighlight: {
    background: 'rgba(240, 223, 0, 0.12)',
    color: '#666666',
    fontWeight: 700
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,

  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 140,
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  actionRequest: {
    color: "white",
    background: "#1A9FE0",
    border: "2px solid #1A9FE0",
    borderRadius: "8px",
    width: "1rem",
    height: "1rem",
    "&:hover": {
      background: "#1A9FE0",
      color: "white !important",
      borderRadius: "14px",
      width: "1rem",
      height: "1rem",

    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const OrderHistory: React.FC = () => {
  const history = useHistory();
  const [isReturned, setIsReturned] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [openReturnRequest, setOpenReturnRequest] = React.useState(false);
  const [openReturnRequestSnackbar, setOpenReturnRequestSnackbar] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const dataOrderRedux: any = useSelector((state: any) => state.orderEnquiry);
  const { orderList, invoice, prevPage, orderStatus }: any = useSelector((state: any) => state.orderHistory);
  const [selectedOrder, setselectedOrder] = React.useState<any>(null);
  const [actualOrder, setActualOrder] = React.useState<any>(null);
  const [searchText, setsearchText] = useState<any>('');
  const [isMyOrders, setisMyOrders] = useState<boolean>(true);
  const today = moment(new Date());
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

  // Convert Date object to moment object
  const momentDate = moment(thirtyDaysAgo);

  // Format the date with day, month, year, hours, minutes, seconds, and timezone
  let formattedDate: any = momentDate.format('ddd MMM DD YYYY HH:mm:ss');
  formattedDate = moment(new Date(formattedDate))


  const [startDate, setstartDate] = useState<any>(formattedDate);
  const [endDate, setendDate] = useState<any>(today);

  const [searchByInvoice, setSearchByInvoice] = useState("")
  const [status, setstatus] = useState<any>("");
  const [open, setOpen] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [customerAnchorEl, setCustomerAnchorEl] = React.useState<null | HTMLElement>(null);
  const [goodsStatus, setGoodsStatus] = React.useState('');
  const [status24, set24hrsStatus] = React.useState('');
  const [fileExtention, setFileExtension] = React.useState('pdf');
  const [viewQuotationUrl, setViewQuotationUrl] = React.useState("")
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  let customerAccountType = get(profileDetails, 'data.data.customerInfo.customerAccountType', '');
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const { deliveryList }: any = useSelector((state: any) => state.delivery);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [Order, setOrder] = React.useState<any>("");
  const b2cUserId = get(profileDetails, "data.data.basicInfo.b2cUserId", "");
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [payment, setPayment] = React.useState(false)
  const showViewInvoice = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOwNLOAD_INVOICE_EXCEL) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_BILLING_DOCS);
  const showViewDelivery = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_DELIVERY);
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerTierType') ? sessionStorage.getItem('customerTierType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [openViewQuotation, setOpenViewQuotation] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [isLPOUpdate, isUpdateLPO] = React.useState(false);
  const [openViewLPO, setOpenViewLPO] = React.useState(false);
  const [quotationNumber, setQuotationNumber] = React.useState("")
  const [enquiryNumber, setEnquiryNumber] = React.useState("");
  const [brandId, setBrandId] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const [lpoAttachments, setLpoAttachments] = React.useState<any>([])
  const { getCustomerDetailsBySapId, getFSECustomerListData, getFSEEnquiryStatusData }: any = useSelector((state: any) => state.dealer);
  const [searchTextByID, setSearchTextByID] = React.useState<any>('');
  const [customerId, setCustomerId] = React.useState<any>([]);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>('');
  const [selectedCustomerName, setSelectedCustomerName] = React.useState<any>('');
  const [count, setCount] = React.useState<any>();
  const [anchorElForCustomer, setAnchorElForCustomer] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [isQueuedEnquirieChecked, setIsQueuedEnquirieChecked] = useState(false)
  const [fseName, setFseName] = useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const { enquiryList, enquiryStatus, fseList } = useSelector((state: any) => state.orderEnquiry);
  const [fseWebApplicationLoginId, setFseWebApplicationLoginId] = React.useState<any>("");
  const [filterByStatus, setFilterByStatus] = React.useState<any>('All');

  useEffect(() => {
    if (orderStatus && orderStatus.data && orderStatus.data.data && orderStatus.data.data.length > 0) {
    } else {
      dispatch(getOrderStatus());
    }
    // if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
    //     dispatch(getCreditLimit(sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId, webApplicationLoginId));
    // }
  }, [profileDetails]) // eslint-disable-line

  React.useEffect(() => {
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
  }, [getFSECustomerListData]);

  const getAllCustomerIds = () => {
    let customer_ids: string[] = [];
    if (getFSECustomerListData && getFSECustomerListData.data) {
      customer_ids = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => item.sapCustomerId);
    }
    return customer_ids;
  }

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (searchText === '' && sessionStorage.getItem('userType') === 'FSE' && getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
        let searchData: any = {};
        if (fseName && fseName !== '') {
          if (fseName === 'All') {
            const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
            searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
            setFseWebApplicationLoginId(webApplicationLoginIds);
          } else if (fseName === '' || fseName === 'Cust Only') {
            searchData["fseWebApplicationLoginId"] = [];
            setFseWebApplicationLoginId("");
          } else {
            searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
            setFseWebApplicationLoginId(fseName === 'Cust Only' ? "" : fseName);
          }
        } else {
          searchData["fseWebApplicationLoginId"] = [];
          setFseWebApplicationLoginId("");
        }
        if (selectedCustomerName && selectedCustomerName !== '') {
          searchData["customerId"] = [selectedCustomerName];
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["customerId"] = getAllCustomerIds();
        } else {
          searchData["customerId"] = []
        }
      }
    }
    setstatus('');
  }, [getFSECustomerListData]); // eslint-disable-line
  // }, [searchText, startDate, endDate, getFSECustomerListData]); // eslint-disable-line

  // useEffect(() => {
  //   dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
  // });

  const handleClick = (event: any, subEnquiry: any, order: any) => {
    setLpoAttachments((subEnquiry && subEnquiry.nowOrderDetails && subEnquiry.nowOrderDetails.lpoAttachments && subEnquiry.nowOrderDetails.lpoAttachments) || (subEnquiry && subEnquiry.backOrderDetails && subEnquiry.backOrderDetails.lpoAttachments && subEnquiry.backOrderDetails.lpoAttachments))
    setQuotationNumber(subEnquiry && subEnquiry.nowOrderDetails && subEnquiry.nowOrderDetails.quotationNumber && subEnquiry.nowOrderDetails.quotationNumber ? subEnquiry && subEnquiry.nowOrderDetails && subEnquiry.nowOrderDetails.quotationNumber && subEnquiry.nowOrderDetails.quotationNumber : subEnquiry && subEnquiry.backOrderDetails && subEnquiry.backOrderDetails.quotationNumber && subEnquiry.backOrderDetails.quotationNumber ? subEnquiry && subEnquiry.backOrderDetails.quotationNumber : "")
    setAnchorEl(event.currentTarget);
    set24hrsStatus(subEnquiry.status);
    setGoodsStatus(subEnquiry.goodsMovementStatus);
    setselectedOrder(subEnquiry);
    setActualOrder(order);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOpen = (str: any) => {
    if (str === "") {
      setOpenDialog(true);
      setPayment(true)
    }
    else if (str !== "") {
      setPayment(false)
      setAnchorEl(null)
    }
    return "called"
  };

  const handleopenCloseSnackbar = (open?: any) => {
    if (open) {
      setAnchorEl(null);
      loadOrderList();
    }
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTextByID("");
  };

  const onSearchCustomerId = (searchText: any) => {
    setSearchTextByID(searchText);
    // getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => (
    //   console.log("__________________", item)
    // ))
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    if (searchText.length > 0) {
      const filteredIds = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()) || sapId.name.toLowerCase().includes(searchText.toLowerCase()));
      setCustomerId(filteredIds);
    } else {
      setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    }
  };

  const handleClickSapId = (event: any, sapId: any, name: any) => {
    setSelectedCustomerName(name);
    setSelectedCustomerId(sapId);
  };

  const handleClickForCustomer = (event: React.MouseEvent<HTMLElement>) => {
    setCustomerAnchorEl(event.currentTarget);
  };

  const handleCloseForCustomer = () => {
    setCustomerAnchorEl(null);
    setSearchTextByID("");
  };


  const resetFilters = () => {
    setsearchText('');
    setPage(0);
    setstartDate(formattedDate);
    setendDate(today);
    setFseName(sessionStorage.getItem('webApplicationLoginId'));
    setstatus("");
    setFilterByStatus('');
    setSelectedCustomerId('');
    setSelectedCustomerName('');
    let searchData: any = { fromDate: formattedDate, toDate: today };
    // dispatch(getOrdersList(searchData, page));
    dispatch(setPageNumber(null));
  }

  const resetFiltersForCustomer = () => {
    setsearchText('');
    setPage(0);
    setstartDate(formattedDate);
    setendDate(today);
    setFseName(sessionStorage.getItem('webApplicationLoginId'));
    setstatus("");
    setFilterByStatus('');
    setSelectedCustomerId('');
    setSelectedCustomerName('');
    let searchData: any = { fromDate: formattedDate, toDate: today };
    dispatch(getOrdersList(searchData, page));
    dispatch(setPageNumber(null));
  }

  function getParameterByName(name: any, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  React.useEffect(() => {
    loadOrderList();
  }, [b2cUserId, isMyOrders, prevPage])
  // }, [b2cUserId, searchText, isMyOrders, startDate, endDate, status, prevPage, fseName, getFSECustomerListData, selectedCustomerId, selectedCustomerName])

  const loadOrderList = useCallback((pageNumber?: any) => {
    setPage((prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0);
    let newPage = (prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0;
    let searchData: any = {};

    if (startDate && endDate) { } else if (!searchText) {
      setstartDate(formattedDate);
      setendDate(today)
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (sessionStorage.getItem('userType') === 'FSE' && getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
        if (searchText && searchText.trim()) {
          searchData["enquiryNumber"] = searchText.trim();
          searchData["referenceNumber"] = searchText.trim();
          searchData["orderNumber"] = searchText.trim();
        }
        if (searchText && searchText !== '') {
          if ((startDate && endDate)) {
            searchData["fromDate"] = startDate ? startDate : formattedDate;
            searchData["toDate"] = endDate ? endDate : today;
          }
        } else {
          if ((startDate && endDate) || (formattedDate && today)) {
            searchData["fromDate"] = startDate ? startDate : formattedDate;
            searchData["toDate"] = endDate ? endDate : today;
          }
        }
        if (status && status !== ALL_STATUS) {
          searchData["status"] = status;
        }
        if (fseName && fseName !== '') {
          if (fseName === 'All') {
            const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
            searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
            setFseWebApplicationLoginId(webApplicationLoginIds);
          } else if (fseName === '' || fseName === 'Cust Only') {
            searchData["fseWebApplicationLoginId"] = [];
            setFseWebApplicationLoginId("");
          } else {
            searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
            setFseWebApplicationLoginId(fseName === 'Cust Only' ? "" : fseName);
          }
        } else {
          searchData["fseWebApplicationLoginId"] = [];
          setFseWebApplicationLoginId("");
        }
        if (selectedCustomerName && selectedCustomerName !== '') {
          searchData["customerId"] = [selectedCustomerName];
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["customerId"] = getAllCustomerIds();
        } else {
          searchData["customerId"] = []
        }
      }
    } else {
      if (searchText && searchText.trim()) {
        searchData["enquiryNumber"] = searchText.trim();
        searchData["referenceNumber"] = searchText.trim();
        searchData["orderNumber"] = searchText.trim();
      }
      searchData["userId"] = isMyOrders ? b2cUserId : "";
      if (searchText && searchText !== '') {
        if ((startDate && endDate)) {
          searchData["fromDate"] = startDate ? startDate : formattedDate;
          searchData["toDate"] = endDate ? endDate : today;
        }
      } else {
        if ((startDate && endDate) || (formattedDate && today)) {
          searchData["fromDate"] = startDate ? startDate : formattedDate;
          searchData["toDate"] = endDate ? endDate : today;
        }
      }
      if (status && status !== ALL_STATUS) {
        searchData["status"] = status;
      }
    }
    // dispatch(getOrdersList(searchData, deliveryType, newPage));
    dispatch(getOrdersList(searchData, newPage));
    dispatch(setPageNumber(null));
    setLoading(false);
  }, [b2cUserId, dispatch, searchText, isMyOrders, startDate, endDate, status, prevPage, fseName, getFSECustomerListData, selectedCustomerId, selectedCustomerName]);

  const loadOrderListOnClickOfSearch = useCallback((pageNumber?: any, e?: any) => {
    setPage((prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0);
    let newPage = (prevPage && prevPage !== null) ? prevPage : pageNumber ? pageNumber : 0;
    let searchData: any = {};
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (sessionStorage.getItem('userType') === 'FSE' && getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
        if (e.target.value && e.target.value.trim()) {
          searchData["enquiryNumber"] = e.target.value.trim();
          searchData["referenceNumber"] = e.target.value.trim();
          searchData["orderNumber"] = e.target.value.trim();
        }
        if (startDate && endDate) {
          searchData["fromDate"] = startDate;
          searchData["toDate"] = endDate;
        }
        if (status && status !== ALL_STATUS) {
          searchData["status"] = status;
        }
        if (fseName && fseName !== '') {
          if (fseName === 'All') {
            const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
            searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
            setFseWebApplicationLoginId(webApplicationLoginIds);
          } else if (fseName === '' || fseName === 'Cust Only') {
            searchData["fseWebApplicationLoginId"] = [];
            setFseWebApplicationLoginId("");
          } else {
            searchData["fseWebApplicationLoginId"] = [fseName === 'Cust Only' ? "" : fseName];
            setFseWebApplicationLoginId(fseName === 'Cust Only' ? "" : fseName);
          }
        } else {
          searchData["fseWebApplicationLoginId"] = [];
          setFseWebApplicationLoginId("");
        }
        if (selectedCustomerName && selectedCustomerName !== '') {
          searchData["customerId"] = [selectedCustomerName];
        } else if (fseName === '' || fseName === 'Cust Only') {
          searchData["customerId"] = getAllCustomerIds();
        } else {
          searchData["customerId"] = []
        }
      }
    } else {
      if (e.target.value && e.target.value.trim()) {
        searchData["enquiryNumber"] = e.target.value.trim();
        searchData["referenceNumber"] = e.target.value.trim();
        searchData["orderNumber"] = e.target.value.trim();
      }
      searchData["userId"] = isMyOrders ? b2cUserId : "";
      if (startDate && endDate) {
        searchData["fromDate"] = startDate;
        searchData["toDate"] = endDate;
      }
      if (status && status !== ALL_STATUS) {
        searchData["status"] = status;
      }
    }
    // dispatch(getOrdersList(searchData, deliveryType, newPage));
    setLoading(true);
    // dispatch(getOrdersList(searchData, newPage));
    dispatch(setPageNumber(null));
    setLoading(false);
  }, [b2cUserId, dispatch, searchText, isMyOrders, startDate, endDate, status, prevPage, fseName, getFSECustomerListData, selectedCustomerId, selectedCustomerName]);


  const getInvoiceDetails = (orderDetails: any) => {
    const data = {
      orderNumber: orderDetails.orderNumber,
      enquiryNumber: orderDetails.enquiryNumber,
      webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
      make: orderDetails.make
    }
    if (data.orderNumber && data.enquiryNumber && data.webApplicationLoginId && data.make) {
      dispatch(getInvoice(data));
    }
  }

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      dispatch(getFSECustomerList());
      if (fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0) {
      } else {
        dispatch(getFSEList());
      }
    }
  }, [])

  // React.useEffect(() => {
  //   if (searchText === '') {
  //     loadOrderList()
  //   }
  // }, [isMyOrders]); //eslint-disable-line

  // }, [searchText, startDate, endDate, isMyOrders]);

  const resetInvoiceValues = useCallback(() => {
    dispatch(resetInvoice());
  }, [dispatch]);

  useEffect(() => {
    if (!openSnackbar) resetInvoiceValues();
  }, [openSnackbar, resetInvoiceValues]);

  useEffect(() => {
    if (invoice && invoice.data && invoice.data.data && invoice.data.data.invoiceUrl) {
      downloadUrl(invoice.data.data.invoiceUrl);
      handleopenCloseSnackbar(true);
    }
    if (invoice && invoice.err && invoice.err.statusText && invoice.err.statusText.data && invoice.err.statusText.data.error) {
      handleopenCloseSnackbar(true);
    }
  }, [invoice]);

  const downloadUrl = (url: any) => {
    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPageNumber(null));
    loadOrderList(newPage)
    setPage(newPage);
  };

  const handleopenCloseReturnRequest = (open?: any) => {
    setOpenReturnRequest(open ? true : false);
  };

  const handleopenCloseReturnRequestSnackbar = (open?: any) => {
    setOpenReturnRequestSnackbar(open ? true : false);
  };

  const handleCloseViewQuotation = () => {
    setOpenViewQuotation(false);
  }

  const updateLPO = () => {
    setOpenUploadLpo(true);
    isUpdateLPO(true);
  }

  const handleCloseViewLPO = () => {
    setOpenViewLPO(false);
  }

  const handleViewQuotation = async () => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
      setOpenViewQuotation(false)
    } else {
      // window.open(data && data.data)
      if (data) {
        setOpenViewQuotation(true)
        setViewQuotationUrl(data)
      }
    }
  }

  const handleViewLPO = async (orderNo: any, quotationNo: any) => {
    if (quotationNo) {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sapCustomerId, quotationNo, ""))
      if (data && data.statusText && data.statusText.data.success === false) {
        showErrorMessage(data && data.statusText && data.statusText.data.message && data.statusText.data.message.message)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
      } else {
        if (lpoAttachments && lpoAttachments.length > 0) {
          const fileName = lpoAttachments[0] && lpoAttachments[0].url.substring(lpoAttachments[0].url.lastIndexOf('/') + 1);
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
          setFileExtension(fileExtension.toLowerCase())
          if (fileExtension === 'pdf') {
            setViewQuotationUrl(data && typeof data === 'string' ? data : lpoAttachments[0].url);
            setOpenViewLPO(true);
            // fetch(lpoAttachments[0].url ? lpoAttachments[0].url : data).then(resp => resp.arrayBuffer()).then(resp => {
            //   const file = new Blob([resp], { type: 'application/pdf' });
            //   const fileURL = URL.createObjectURL(file);
            //   setViewQuotationUrl(fileURL)
            //   if (fileURL) {
            //     setOpenViewLPO(true)
            //   }
            // });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setViewQuotationUrl(lpoAttachments[0].url);
            if (lpoAttachments[0].url) {
              setOpenViewLPO(true)
            }
          }
        }
      }
    } else {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId, "", orderNo))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
      } else {
        // window.open(data && data.data)
        // if (data) {
        //   setOpenViewLPO(true)
        //   setViewQuotationUrl(data)
        // }
        if (lpoAttachments && lpoAttachments.length > 0) {
          const fileName = lpoAttachments[0] && lpoAttachments[0].url.substring(lpoAttachments[0].url.lastIndexOf('/') + 1);
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
          setFileExtension(fileExtension.toLowerCase())
          if (fileExtension === 'pdf') {
            setViewQuotationUrl(data && typeof data === 'string' ? data : lpoAttachments[0].url);
            setOpenViewLPO(true);
            // fetch(lpoAttachments[0].url ? lpoAttachments[0].url : data).then(resp => resp.arrayBuffer()).then(resp => {
            //   const file = new Blob([resp], { type: 'application/pdf' });
            //   const fileURL = URL.createObjectURL(file);
            //   setViewQuotationUrl(fileURL)
            //   if (fileURL) {
            //     setOpenViewLPO(true)
            //   }
            // });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setViewQuotationUrl(lpoAttachments[0].url || data);
            if (lpoAttachments[0].url) {
              setOpenViewLPO(true)
            }
          }
        }
      }
    }
  }

  const handleUploadLpo = async () => {
    loadOrderList();
    handleClose();
    if (isLPOUpdate) {
      await handleViewLPO(orderNumber, quotationNumber);
      setOpenViewLPO(false);
      loadOrderList();
    }
    setOpenUploadLpo(false);
    isUpdateLPO(false);
  }

  const { data: orders } = orderList || {};

  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      {loading && (<FullscreenLoader />)}
      {dataOrderRedux && dataOrderRedux.orderList && dataOrderRedux.orderList.loading && <FullscreenLoader />}
      {((orderList && orderList.loading) || (deliveryList && deliveryList.loading)) && <FullscreenLoader />}
      {payment === false ?
        <>
          <div className="d-flex align-items-center">
            <h2 className="page-title">
              Order History
            </h2>
            <div className="ml-auto">
              <AnnouncementIcon />
              <NotificationIcon />
            </div>
          </div>
          {/* <div className="row mt-4"> */}
          <ReturnRequestSnackbar
            open={openReturnRequestSnackbar}
            handleopenClose={() => {
              handleopenCloseReturnRequestSnackbar(false)
            }}
          />
          {invoice && invoice.data && invoice.data.data && invoice.data.data.invoiceUrl && (
            <Snackbar
              open={openSnackbar}
              message='Invoice Downloaded Successfully'
              handleopenClose={() => {
                handleopenCloseSnackbar(false)
              }} />
          )}
          {invoice && invoice.err && invoice.err.statusText && invoice.err.statusText.data && invoice.err.statusText.data.error && (
            <Snackbar
              open={openSnackbar}
              type="error"
              message={invoice.err.statusText.data.error ? invoice.err.statusText.data.message.message : 'Invoice Request Failed'}
              handleopenClose={() => {
                handleopenCloseSnackbar(false)
              }} />
          )}
          {deliveryList && deliveryList.err && deliveryList.err.statusText && deliveryList.err.statusText.data && deliveryList.err.statusText.data.message && deliveryList.err.statusText.data.message.status && deliveryList.err.statusText.data.message.status === "E" && (
            <Snackbar
              open={openSnackbar}
              type="error"
              message={deliveryList.err.statusText.data.message.message ? deliveryList.err.statusText.data.message.message : 'There was a problem fetching the delivery list'}
              handleopenClose={() => {
                handleopenCloseSnackbar(false)
              }} />
          )}
          {/* <ReturnRequestDialog
            selectedOrder={selectedOrder}
            open={openReturnRequest}
            markReturned={setIsReturned}
            handleopenClose={(data: any) => {
              if (data) {
                //return api call here
                handleopenCloseReturnRequestSnackbar(true)
              }
              handleopenCloseReturnRequest(false)
            }}
          /> */}
          {/* </div> */}
          <div className="row mt-4">
            <div className="col-12">
              <div className="cardCommon p-3">
                <div className="d-flex align-items-center">
                  <h5 className="page-sub-title mb-2">Search Orders</h5>
                </div>
                <TextField
                  value={searchText}
                  onChange={(e) => {
                    setsearchText(e.target.value);
                    loadOrderListOnClickOfSearch(page, e)
                    if (e.target.value === "") {
                      loadOrderListOnClickOfSearch(page, e)
                    }
                  }}
                  size="small" className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Order No/Reference No" variant="outlined" />
                <Tooltip title="Maximum 31 days data can be tracked." placement="top">
                  <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                    <DatePicker
                      startDate={startDate}
                      endDate={endDate}
                      setstartDate={setstartDate}
                      setendDate={setendDate}
                      isEnableOnlyThirtyDays={true}
                      onChange={(start: any, end: any) => {
                        setstartDate(start);
                        setendDate(end);
                      }}
                    />
                  </div>
                </Tooltip>
                <div className={sessionStorage.getItem('userType') === 'FSE' ? "col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-0" : "col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0 "}>
                  <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select

                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={status}
                      onChange={(e) => {
                        setstatus(e.target.value)
                      }}
                      label="Status"
                    >
                      <MenuItem key={ALL_STATUS}
                        value={ALL_STATUS}>All</MenuItem>
                      {orderStatus && orderStatus.data && orderStatus.data.data && orderStatus.data.data.length > 0 && orderStatus.data.data.map((status: any, i: any) => (
                        <MenuItem key={status} value={status}>{status}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {sessionStorage.getItem('userType') === 'FSE' ? "" :
                  <Button
                    className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      loadOrderList()
                    }}
                  >Search</Button>}
                {/* <div className="ml-2 mt-2 d-flex flex-row">
                  <Checkbox checked={isMyOrders} onChange={() => { setisMyOrders(!isMyOrders); }} />
                  <span className="myOrders">My Orders</span>
                </div> */}
                {sessionStorage.getItem('userType') !== 'FSE' && (
                  <div className="mt-4 d-flex flex-row">
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2 ml-2 d-flex flex-row">
                      <Checkbox checked={isMyOrders} onChange={() => { setisMyOrders(!isMyOrders); }} />
                      <span className="myOrders">My Orders</span>
                    </div>
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0 pr-0">
                    </div>
                    <div className="col-2 col-sm-5 col-md-3 col-lg-3 d-inline-flex pl-2 pr-0">
                    </div>
                    <div className='offset-3 col-3'>
                      <Button variant="contained" className="cancel-button font-size-11 mr-3"
                        onClick={() => {
                          setLoading(true);
                          resetFiltersForCustomer();
                          setLoading(false);
                        }
                        }>Reset Filters</Button>
                    </div>
                  </div>
                )}
                {sessionStorage.getItem('userType') === 'FSE' && (
                  <div className="mt-4 d-flex flex-row">
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0">
                      <TextField
                        id="customerId"
                        variant="outlined"
                        size="small"
                        onClick={handleClickForCustomer}
                        value={selectedCustomerId}
                        label="Filter By Customer"
                        aria-controls="customized-menu"
                        className="commonRoundedInputs w-100"
                        aria-haspopup="true"
                        placeholder="Filter By Customer"
                      >
                      </TextField>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={customerAnchorEl}
                        keepMounted
                        className="dealer-custom-dropdown"
                        open={Boolean(customerAnchorEl)}
                        onClose={handleCloseForCustomer}
                      >
                        <div>
                          <div className="mt-2">
                            <div className="searchDealer">
                              <IconButton
                                edge="start"
                                className="ml-2"
                                disabled
                              >
                                <SearchIcon fontSize="inherit" />
                              </IconButton>
                              <TextField value={searchTextByID} onChange={(e) => {
                                onSearchCustomerId(e.target.value)
                              }}
                                size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                            </div>
                          </div>
                          {customerId && customerId.length > 0 && customerId.map((sapId: any, i: any) => (
                            <Grid key={i} container className="border-bottom">
                              <MenuItem key={i} value={sapId.sapCustomerId}>
                                <ListItemText primary={sapId.sapCustomerId} onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleCloseForCustomer() }} /><p className="px-5" onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleCloseForCustomer() }}> {" "} {" "} {sapId.name}</p>
                              </MenuItem>
                            </Grid>
                          ))}
                          {customerId && customerId.length === 0 && (
                            <Grid container >
                              <div className="d-flex justify-content-center text-center mx-auto">No Customers found.</div>
                            </Grid>
                          )}
                        </div>
                      </StyledMenu>
                    </div>
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0 pr-0">
                      <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                        <InputLabel id="demo-simple-select-outlined-label">Filter By FSE</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={fseName}
                          onChange={(e) => {
                            setFseName(e.target.value)
                          }}
                          label="Filter By FSE"
                        >
                          <MenuItem value={"All"}> {sessionStorage.getItem('userType') === 'FSE' ? "All FSE" : "All"} </MenuItem>
                          {fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((fse: any, i: any) => (
                            <MenuItem key={fse.name}
                              value={fse.webApplicationLoginId}>{fse.name} - {fse.webApplicationLoginId}</MenuItem>
                          ))}
                          <MenuItem value={sessionStorage.getItem('userType') === 'FSE' ? "Cust Only" : ""}> {sessionStorage.getItem('userType') === 'FSE' ? "Cust Only" : "None"} </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                    <div className='offset-5 col-3'>
                      <Button variant="contained" className="cancel-button font-size-11 mr-3"
                        onClick={() => {
                          // resetFilters();
                          resetFiltersForCustomer();
                        }
                        }>Reset Filters</Button>
                      <Button
                        className="font-size-11 addOrderEnqButton"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          loadOrderList()
                        }}
                      >Search
                      </Button>
                    </div>
                  </div>
                )}
              </div></div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title">List of Orders</h5>
                <div className="legendItem ml-auto">
                  {/* <span className="orangedot"></span>
                  <span className="legendItemLabel mr-4">Return Created</span> */}
                  <span className="dot"></span>
                  <span className="legendItemLabel">Back Order</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12">
              <div className="cardCommon mh-300p">
                <TableContainer>
                  <Table aria-label="table" >
                    <TableHead className="w-100">
                      <TableRow>
                        <TableCell className={classes.tableHeadSmall}></TableCell>
                        <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                        <TableCell className={classes.tableHead}>Reference No.</TableCell>
                        <TableCell className={classes.tableHead}>Brand</TableCell>
                        <TableCell className={classes.tableHead}>
                          <h6 className="totalHead">Total Amount</h6>
                          <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div>
                        </TableCell>
                        <TableCell className={classes.tableHead}>Created By</TableCell>
                        <TableCell className={classes.tableHead}>Created On</TableCell>
                        <TableCell className={classes.tableHead}>Status</TableCell>
                        <TableCell className={classes.tableHead}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {!isEmpty(orders) && orders.map((order: any, index: any) => {
                        // const { order } = props;
                        const { subEnquiries } = order || {};
                        const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
                        return (
                          <React.Fragment>
                            <TableRow key={order.masterId} hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer" >
                              <TableCell className={classes.bodyContentSmall}>
                                <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
                                  {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
                                </IconButton>
                              </TableCell>
                              <TableCell className={classes.bodyContent}>{order.masterId}</TableCell>
                              <TableCell className={classes.bodyContent}>{order.masterReferenceNumber}</TableCell>
                              <TableCell align="left" className={classes.bodyContent}>
                                {order.brands && Array.isArray(order.brands) && customerTierType && (customerTierType.toLowerCase() !== 'workshop' || subEnquiries[0]?.customerType?.toLowerCase() !== 'workshop') && subEnquiries && subEnquiries[0]?.customerType !== 'tpcdealer' ? order.brands.map((brand: any) => brand === "Tradepoint" ? "ALAC" : brand).join(', ') : subEnquiries[0]?.customerType === 'tpcdealer' || customerTierType.toLowerCase() === 'workshop' || subEnquiries[0]?.customerType?.toLowerCase() === 'workshop' ? "Tradepoint" : order.brands.toString()}
                                {/* {order.brands && Array.isArray(order.brands) && order.brands.toString()} */}
                              </TableCell>
                              <TableCell className={classes.bodyContent}>{parseFloat(getSellingPrice(!!order.estimatedTotal && order.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
                              {sessionStorage.getItem('userType') === 'FSE' ?
                                <TableCell className="mw-150p">
                                  <div className="dealer-table-content">{order.createdBy ? order.createdBy : "-"}</div>
                                  <div className="dealer-table-subcontent">{order.userRole === "FSE" ? "(Al-Futtaim)" : order.userRole ? (order.userRole) : "-"}</div>
                                </TableCell>
                                : <TableCell className="mw-150p">
                                  <div className="dealer-table-content">{order.createdBy ? order.createdBy : "-"}</div>
                                  <div className="dealer-table-subcontent">{order.userRole === "FSE" ? "(Al-Futtaim)" : order.userRole ? (order.userRole) : "-"}</div>
                                </TableCell>
                              }
                              <TableCell className={classes.bodyContent}>{moment(order.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                              <TableCell className={classes.bodyContent}>{order.status}</TableCell>
                              <TableCell>
                                {showViewEnquiry &&
                                  <Tooltip title="View Order Enquiry" placement="top" onClick={async () => { await dispatch(isVewingFromOrderHistory({ isViewingFromOrderHistory: true, id: order.masterId })) }}>
                                    <IconButton className={classes.actionButton} size="small" onClick={async () => {
                                      sessionStorage.setItem("partsData", "")
                                      sessionStorage.setItem('isCopyandCreate', "");
                                      if (sessionStorage.getItem('userType') === 'FSE') {
                                        await dispatch(getCustomerDetailsByCustomerID(order?.customerId))
                                      }
                                      if (customerTierType.toLowerCase() === "dealer" || customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === 'tpcdealer') {
                                        dispatch(getViewRequestStatusAPI(order.masterId))
                                      }
                                      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                        mode: 'view',
                                        masterId: order.masterId,
                                        orderType: "order",
                                        quotation: false
                                      }));
                                      await dispatch(isVewingFromOrderHistory({ isViewingFromOrderHistory: true, id: order.masterId }))
                                      dispatch(setPageNumber(page));
                                      history.push('/viewenquiry')
                                    }}>
                                      <AssignmentIcon fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                }
                              </TableCell>
                            </TableRow>
                            <TableRow className={classes.collapseBg}>
                              <TableCell className="p-0 w-100" colSpan={12}>
                                <Collapse in={open > 0 && open === index + 1 ? true : false}>
                                  <Box>
                                    <Table aria-label="table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell className={classes.subTableHeadSmall}>
                                            {selectedOrder && selectedOrder.status && typeof selectedOrder.status === "string" && selectedOrder.status.toLowerCase() === 'order completed' && (
                                              <span className="orangedot"></span>
                                            )}
                                          </TableCell>
                                          <TableCell className={classes.subTableHead}>
                                            <span className="mx-2 ml-3"></span>
                                            Order No.</TableCell>
                                          <TableCell className={classes.subTableHead}>Reference No.</TableCell>
                                          <TableCell className={classes.subTableHead}>Brand</TableCell>
                                          <TableCell className={classes.subTableHead}>
                                            Total Amount
                                            <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div>
                                          </TableCell>
                                          <TableCell className={classes.subTableHead}>Created By</TableCell>
                                          <TableCell className={classes.subTableHead}>Created On</TableCell>
                                          <TableCell className={classes.subTableHead}>Status</TableCell>
                                          <TableCell className={classes.subTableHead}>Payment Status</TableCell>
                                          <TableCell className={classes.subTableHead}>Action</TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {!isEmpty(subEnquiries) && subEnquiries.map((subEnquiry: any) => {
                                          const canShowStatus = isString(subEnquiry.status);
                                          const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ORDERS);
                                          return <>
                                            <TableRow key={subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber} className={isReturned ? classes.orangeRowHighlight : classes.subTableBodyContent}>
                                              <TableCell className={classes.subTableBodyContentSmall}>
                                                {subEnquiry.isAirOrder && <span className="ml-2 dot"></span>}
                                                {subEnquiry && subEnquiry.status && typeof subEnquiry.status === 'string' && subEnquiry.status.toLowerCase() === "return created" && <span className="ml-2 orangedot"></span>}
                                                {subEnquiry && subEnquiry.backOrderDetails && subEnquiry.backOrderDetails.length && subEnquiry.backOrderDetails.length > 0 && <span className="ml-2 orangedot"></span>}
                                              </TableCell>
                                              <TableCell className={classes.subTableBodyContent}>
                                                {subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber}
                                              </TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{subEnquiry.enquiryReferenceNumber}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>
                                                {customerTierType.toLowerCase() === 'tpcdealer' || subEnquiry.customerType === 'tpcdealer' || subEnquiry.customerType?.toLowerCase() === 'workshop' || customerTierType.toLowerCase() === 'workshop' ? 'Tradepoint' : getNewBrand(subEnquiry.make.toString()) === 'Tradepoint' && (customerTierType.toLowerCase() !== 'workshop' || subEnquiry.customerType !== 'workshop') && subEnquiry.customerType !== 'tpcdealer' ? 'ALAC' : getNewBrand(subEnquiry.make.toString())}
                                              </TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{subEnquiry.orderNumber || subEnquiry.backOrderNumber ?
                                                (parseFloat(getSellingPrice(subEnquiry.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2)) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{subEnquiry.userName}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{moment(order.orderCreatedon).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{canShowStatus && subEnquiry.status}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>{subEnquiry.status === "Fully Cancelled" ? "-" : subEnquiry.paymentStatus}</TableCell>
                                              <TableCell className={classes.subTableBodyContent}>
                                                {((subEnquiry.isAirOrder === false && subEnquiry.orderNumber && subEnquiry.orderNumber !== "-") || (subEnquiry.isAirOrder && subEnquiry.backOrderNumber)) && <IconButton onClick={(event: any) => {
                                                  handleClick(event, subEnquiry, order); let brands: any = {
                                                    "Toyota": "2001",
                                                    "Honda": "2002",
                                                    "Famco": "2003",
                                                    "TE": "2380",
                                                    "OES": "2195",
                                                  }
                                                  sessionStorage.setItem('customerTierType', subEnquiry.customerType);
                                                  setQuotationNumber(subEnquiry && subEnquiry.nowOrderDetails && subEnquiry.nowOrderDetails.quotationNumber ? subEnquiry && subEnquiry.nowOrderDetails.quotationNumber : subEnquiry && subEnquiry.backOrderDetails && subEnquiry.backOrderDetails.quotationNumber && subEnquiry.backOrderDetails.quotationNumber ? subEnquiry && subEnquiry.backOrderDetails.quotationNumber : "")
                                                  setBrandId(brands[subEnquiry && subEnquiry.make])
                                                  setEnquiryNumber(order.masterId);
                                                }} className={classes.actionButton} size="small">
                                                  <MoreVertIcon fontSize="inherit" className={classes.actionButton} />
                                                </IconButton>}
                                              </TableCell>
                                            </TableRow>
                                            {selectedOrder && ((subEnquiry.isAirOrder === false && selectedOrder.orderNumber && selectedOrder.orderNumber === subEnquiry.orderNumber) || (subEnquiry.isAirOrder && selectedOrder.backOrderNumber === subEnquiry.backOrderNumber)) &&
                                              <Menu
                                                id="simple-menu"
                                                anchorEl={anchorEl}
                                                keepMounted
                                                open={Boolean(anchorEl)}
                                                onClose={handleClose}
                                              >
                                                {showViewEnquiry && <MenuItem onClick={() => {
                                                  dispatch(getOrderId(selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber));
                                                  sessionStorage.setItem('isBackOrder', selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? 'true' : '');
                                                  sessionStorage.setItem('orderStoredBrand', selectedOrder.make.toString())
                                                  sessionStorage.setItem('orderBackNumber', selectedOrder.backOrderNumber)
                                                  if (selectedOrder.backOrderNumber || selectedOrder.orderNumber) {
                                                    history.push(`/orderdetails`);
                                                    sessionStorage.setItem('selectedCustomerID', selectedOrder && selectedOrder.customerId === undefined ? actualOrder && actualOrder.customerId : selectedOrder && selectedOrder.customerId)
                                                    dispatch(setPageNumber(page));
                                                    let brands: any = {
                                                      "Toyota": "2001",
                                                      "Honda": "2002",
                                                      "Famco": "2003",
                                                      "TE": "2380",
                                                      "OES": "2195",
                                                    }
                                                    lpoAttachments && lpoAttachments.length > 0 && lpoAttachments.map((ele: any) => {
                                                      sessionStorage.setItem('lpoAttachments', JSON.stringify({
                                                        documentId: ele.documentId,
                                                        lpoNumber: ele.lpoNumber,
                                                        uploadedBy: ele.uploadedBy,
                                                        uploadedOn: ele.uploadedOn,
                                                        url: ele.url
                                                      }));
                                                    });
                                                    sessionStorage.setItem("upload-lpoAttachments-url-view-order", "");
                                                    sessionStorage.setItem("upload-lpoAttachments-view-order", "");
                                                    dispatch(quotationDetailsViewOrder(
                                                      {
                                                        brandId: brands[selectedOrder && selectedOrder.make],
                                                        b2bEnquiryId: order.masterId,
                                                        orderNumber: subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber
                                                      }
                                                    ))
                                                  } handleClose()
                                                }}> <div className="pr-2">
                                                    <IconButton className={`${classes.actionButton} `} size="small">
                                                      <VisibilityIcon fontSize="inherit" />
                                                    </IconButton>
                                                  </div>View Order</MenuItem>}
                                                {quotationNumber && (sessionStorage.getItem('userType') === 'FSE' || customerTierType !== '') && <MenuItem className="pl-3 pr-3" onClick={() => {
                                                  handleViewQuotation()
                                                }}>
                                                  <div className="pr-2">
                                                    <IconButton className={`${classes.actionButton} `} size="small">
                                                      <VisibilityIcon fontSize="inherit" />
                                                    </IconButton>
                                                  </div>
                                                  View Quotation</MenuItem>}
                                                {/* {((lpoAttachments && lpoAttachments.length > 0 || quotationNumber) && (sessionStorage.getItem('userType') === 'FSE' || customerTierType !== '')) && */}
                                                {((lpoAttachments && lpoAttachments.length > 0) && (sessionStorage.getItem('userType') === 'FSE' || customerTierType !== '')) &&
                                                  <MenuItem className="pl-3 pr-3" onClick={() => {
                                                    handleViewLPO(selectedOrder && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber, subEnquiry && subEnquiry.isAirOrder ? subEnquiry.backOrderDetails.quotationNumber : subEnquiry.nowOrderDetails.quotationNumber);
                                                    let brands: any = {
                                                      "Toyota": "2001",
                                                      "Honda": "2002",
                                                      "Famco": "2003",
                                                      "TE": "2380",
                                                      "OES": "2195",
                                                    }
                                                    lpoAttachments && lpoAttachments.map((ele: any) => {
                                                      sessionStorage.setItem('lpoAttachments', JSON.stringify({
                                                        documentId: ele.documentId,
                                                        lpoNumber: ele.lpoNumber,
                                                        uploadedBy: ele.uploadedBy,
                                                        uploadedOn: ele.uploadedOn,
                                                        url: ele.url
                                                      }));
                                                    });
                                                    dispatch(quotationDetailsViewOrder(
                                                      {
                                                        brandId: brands[selectedOrder && selectedOrder.make],
                                                        b2bEnquiryId: order.masterId,
                                                        orderNumber: selectedOrder && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber,
                                                        quotationNumber: quotationNumber ? quotationNumber : selectedOrder && selectedOrder.nowOrderDetails.quotationNumber
                                                      }
                                                    ))
                                                    setOrderNumber(selectedOrder && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber);
                                                    sessionStorage.setItem('SubEnquiry-Status', subEnquiry.status);
                                                  }}>
                                                    <div className="pr-2">
                                                      <IconButton className={`${classes.actionButton} `} size="small">
                                                        <VisibilityIcon fontSize="inherit" />
                                                      </IconButton>
                                                    </div>
                                                    View LPO</MenuItem>}
                                                {(((lpoAttachments && lpoAttachments.length === 0 && quotationNumber === '') && (sessionStorage.getItem('userType') === 'FSE' || customerTierType !== ''))) && (
                                                  <>
                                                    {(subEnquiry.status !== "Partially Invoiced" && subEnquiry.status !== "Fully Invoiced" &&
                                                      subEnquiry.status !== "Fully delivered" &&
                                                      subEnquiry.status !== "Partially delivered" &&
                                                      subEnquiry.status !== "Partially delivered" && subEnquiry.status !== "Delivery Processing" &&
                                                      !(subEnquiry && subEnquiry.nowOrderDetails && subEnquiry.nowOrderDetails.quotationNumber)) && ((sessionStorage.getItem('userType') === 'FSE' || customerTierType !== '')) && <MenuItem onClick={() => {
                                                        let brands: any = {
                                                          "Toyota": "2001",
                                                          "Honda": "2002",
                                                          "Famco": "2003",
                                                          "TE": "2380",
                                                          "OES": "2195",
                                                        }
                                                        setOpenUploadLpo(true);
                                                        dispatch(quotationDetailsViewOrder(
                                                          {
                                                            brandId: brands[selectedOrder && selectedOrder.make],
                                                            b2bEnquiryId: order.masterId,
                                                            orderNumber: subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber,
                                                            quotationNumber: quotationNumber ? quotationNumber : subEnquiry && subEnquiry.nowOrderDetails.quotationNumber
                                                          }
                                                        ))
                                                      }} className="quotation-text">
                                                        <div className="pr-2">
                                                          <IconButton className="exportExcel" size="small" >
                                                            <ArrowUpwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => { }} />
                                                          </IconButton>
                                                        </div>
                                                        Upload LPO
                                                        <div>
                                                          <div className="pr-2">
                                                            <IconButton className={`${classes.actionButton} `} size="small">
                                                              <div className="">
                                                                <Tooltip title="Order will not be processed by Al-Futtaim unless LPO is uploaded." placement="top">
                                                                  <HelpIcon className="float-right" fontSize="inherit" />
                                                                </Tooltip>
                                                              </div>
                                                            </IconButton>
                                                          </div>
                                                        </div>
                                                      </MenuItem>}
                                                  </>
                                                )}

                                                {selectedOrder && selectedOrder.status && typeof selectedOrder.status == "string" &&
                                                  <>
                                                    {showViewDelivery && viewDeliveryOrderStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                      <MenuItem onClick={async () => {
                                                        let data: any = await dispatch(getDeliveryList(selectedOrder.orderNumber, selectedOrder.enquiryNumber, selectedOrder.make, selectedOrder.backOrderNumber, actualOrder && actualOrder.customerId));
                                                        sessionStorage.setItem('selectedOrderMake', selectedOrder.make);
                                                        sessionStorage.setItem('selectedOrderEnquiryNumber', selectedOrder.enquiryNumber);
                                                        sessionStorage.setItem('selectedOrderCreatedOn', moment(order.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss'));
                                                        sessionStorage.setItem('userRoleCreatedBy', order.userRole);
                                                        dispatch(ViewDelivery({ ...selectedOrder, parentOrder: order }))
                                                        if (data && data.error) {
                                                          handleopenCloseSnackbar(true);
                                                          setOpenErrorSnackbar(true)
                                                          setSuccessMessage(data && data.message && data.message.message)
                                                        }
                                                        else {
                                                          setOpenErrorSnackbar(false)
                                                          dispatch(selectedViewDelivery({ enquiryNumber: subEnquiry.enquiryNumber, make: subEnquiry.make }));
                                                          history.push(`/deliverylist`);
                                                        }
                                                        handleClose()
                                                      }}> <div className="pr-2">
                                                          <IconButton className={`${classes.actionButton} `} size="small">
                                                            <VisibilityIcon fontSize="inherit" />
                                                          </IconButton>
                                                        </div>View Delivery</MenuItem>
                                                    }
                                                    {/* {viewReturnRequestStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                      <MenuItem onClick={() => {
                                                        handleopenCloseReturnRequest(true);
                                                        handleClose();
                                                        dispatch(getOrderId(subEnquiry.backOrderNumber && !subEnquiry.orderNumber ? subEnquiry.backOrderNumber : subEnquiry.orderNumber));
                                                      }}>Return Order</MenuItem>
                                                    } */}
                                                    {(sessionStorage.getItem('userType') === 'FSE' && (order?.salesData?.orderType === 'CRD' || order.customerAccountType === 'Credit')) || ((customerTierType.toLowerCase() === "tpcdealer" || customerTierType.toLowerCase() === "workshop") && customerAccountType === 'Credit') ? '' :
                                                      <>
                                                        {(customerTierType && customerTierType.toLowerCase() === "fleet" || customerTierType === "Fleet" || customerTierType && customerTierType.toLowerCase() === "government" || customerTierType === "Government" || (sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('orderStoredBrand') !== "OES")) ? ""
                                                          :
                                                          <>
                                                            {(status24 == "Fully Cancelled") ? '' :
                                                              <>
                                                                {showViewEnquiry && selectedOrder && selectedOrder.paymentStatus == "Payment Received" || selectedOrder && selectedOrder.paymentStatus == "Credit Utilized" || selectedOrder.paymentStatus == "Offline Payment Pending" || goodsStatus == 'Completed' || selectedOrder.paymentStatus == "Offline Payment Pending (Payment at Counter)" || selectedOrder && selectedOrder.paymentStatus == "Offline Payment Pending (Credit card at delivery )" || !isSellingPriceData ?
                                                                  showViewEnquiry && selectedOrder && selectedOrder.paymentStatus && status24 != "Fully Cancelled" && (<MenuItem onClick={async () => {
                                                                    // sessionStorage.setItem('selectedOrderMake', selectedOrder.make);
                                                                    dispatch(getOrderId(selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber));
                                                                    setOrder({ ...selectedOrder, parentOrder: order })
                                                                    setPayment(true);
                                                                    dispatch(getCreditLimit(sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId, webApplicationLoginId));
                                                                    // let orderNumber = getParameterByName('orderNo')
                                                                    let orderNumber1 = selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber
                                                                    if (order.masterId) {
                                                                      dispatch(getOrderSummaryDetails({ customerType: customerTierType, masterId: order.masterId, orderNumber1: orderNumber1 }))
                                                                      dispatch(getOrderSummary(customerTierType, order.masterId, orderNumber1))
                                                                    } else {
                                                                      dispatch(getOrderSummaryDetails({ customerType: customerTierType, masterId: order.masterId, orderNumber1: orderNumber1 }))
                                                                      dispatch(getOrderSummary(customerTierType, order.masterId, orderNumber1))
                                                                    }
                                                                    window.history.replaceState(null, "title", "/orderhistorypayment")
                                                                    window.history.replaceState(null, "title", "/orderhistorypayment")
                                                                    sessionStorage.setItem('isBackOrder', selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? 'true' : '');
                                                                    sessionStorage.setItem('orderStoredBrand', selectedOrder.make.toString())
                                                                    sessionStorage.setItem('orderBackNumber', selectedOrder.backOrderNumber)
                                                                    if (selectedOrder.backOrderNumber || selectedOrder.orderNumber) {
                                                                      //  history.push(`/orderdetails`);
                                                                      dispatch(setPageNumber(page));
                                                                      let userData: any;
                                                                      if (sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('customerTypeofCustomer') === "workshop") {
                                                                        userData = await dispatch(getCustomerDetailsByCustomerID(actualOrder && actualOrder.customerId));
                                                                        sessionStorage.setItem("companyCurrencyType", userData && userData.data && userData.data.companyCurrencyType)
                                                                      }
                                                                    } handleClose()
                                                                  }}> <div className="pr-2">
                                                                      <IconButton className={`${classes.actionButton} `} size="small">
                                                                        <VisibilityIcon fontSize="inherit" />
                                                                      </IconButton>
                                                                    </div>View Payment</MenuItem>)
                                                                  :
                                                                  showViewEnquiry && selectedOrder && selectedOrder.paymentStatus == "Payment Due" || goodsStatus != 'Completed' || showViewEnquiry && selectedOrder && selectedOrder.paymentStatus == "Declined" && status24 != "Fully Cancelled" ?
                                                                    showViewEnquiry && selectedOrder && selectedOrder.paymentStatus && (<MenuItem onClick={async () => {
                                                                      // sessionStorage.setItem('selectedOrderMake', selectedOrder.make);
                                                                      dispatch(getOrderId(selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber));
                                                                      setOrder({ ...selectedOrder, parentOrder: order })
                                                                      setPayment(true);
                                                                      dispatch(getCreditLimit(sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId, webApplicationLoginId));
                                                                      // let orderNumber = getParameterByName('orderNo')
                                                                      let orderNumber1 = selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber
                                                                      if (order.masterId) {
                                                                        dispatch(getOrderSummaryDetails({ customerType: customerTierType, masterId: order.masterId, orderNumber1: orderNumber1 }))
                                                                        dispatch(getOrderSummary(customerTierType, order.masterId, orderNumber1))
                                                                      } else {
                                                                        dispatch(getOrderSummaryDetails({ customerType: customerTierType, masterId: order.masterId, orderNumber1: orderNumber1 }))
                                                                        dispatch(getOrderSummary(customerTierType, order.masterId, orderNumber1))
                                                                      }
                                                                      window.history.replaceState(null, "title", "/orderhistorypayment")
                                                                      window.history.replaceState(null, "title", "/orderhistorypayment")
                                                                      sessionStorage.setItem('isBackOrder', selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? 'true' : '');
                                                                      sessionStorage.setItem('orderStoredBrand', selectedOrder.make.toString())
                                                                      sessionStorage.setItem('orderBackNumber', selectedOrder.backOrderNumber)
                                                                      if (selectedOrder.backOrderNumber || selectedOrder.orderNumber) {
                                                                        //  history.push(`/orderdetails`);
                                                                        dispatch(setPageNumber(page));
                                                                        let userData: any;
                                                                        if (sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('customerTypeofCustomer') === "workshop") {
                                                                          userData = await dispatch(getCustomerDetailsByCustomerID(actualOrder && actualOrder.customerId));
                                                                          sessionStorage.setItem("companyCurrencyType", userData && userData.data && userData.data.companyCurrencyType)
                                                                        }
                                                                      } handleClose()
                                                                    }}><div className="pr-2">
                                                                        <IconButton className="exportExcel " size="small" >
                                                                          <ArrowForwardIcon className={` ${classes.actionRequest}`} style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => { }} />
                                                                        </IconButton>
                                                                      </div>Make Payment</MenuItem>)
                                                                    : ""}
                                                              </>}
                                                          </>}
                                                      </>
                                                    }
                                                    {/* {showViewInvoice && viewViewInvoiceStatuses.includes(selectedOrder.status.toLowerCase()) && selectedOrder && selectedOrder.status === "Fully Invoiced" ?
                                                      ""
                                                      :
                                                      showViewInvoice && viewViewInvoiceStatuses.includes(selectedOrder.status.toLowerCase()) &&
                                                      <MenuItem
                                                        disabled={!isSellingPriceData}
                                                        onClick={() => { getInvoiceDetails(selectedOrder); handleClose() }}>View Invoice
                                                      </MenuItem>
                                                    } */}
                                                  </>
                                                }
                                              </Menu>
                                            }
                                          </>
                                        })}
                                      </TableBody >
                                    </Table>
                                  </Box>
                                </Collapse>
                              </TableCell >
                            </TableRow >
                          </React.Fragment >
                        );
                      })}
                    </TableBody>
                  </Table>
                  {isEmpty(orders) &&
                    <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Orders Found.</div>
                  }
                </TableContainer>
                <div className="col-12 d-flex justify-content-end">
                  <TablePagination
                    className="pagination"
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={orderList && orderList.data && orderList.totalCount ? orderList.totalCount : 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  />
                </div>
              </div>
            </div>
          </div>

        </> : <>
          {payment &&
            <OrderHistoryPayment
              open={Order}
              orderData={Order}
              handleClickOpen={handleClickOpen}
              orderTotal={Order && Order.totalCost}
              getEstimatedTotal={2727}
            // handleopenClose={(data: any) => {
            //   history.push("/orderhistory");
            //    handleopenCloseOrderSummary(false);
            // }} 

            />
          }  </>

      }
      {/* <OrderSummaryDialog
       
     /> */}
      <Dialog className="pb-0"
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm"
      >
        <HistoryMakePaymentDialog orderData={Order} setSuccessMessage={setSuccessMessage}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          openErrorSnackbar={openErrorSnackbar}
          handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog}
        />
      </Dialog>
      <OrderHistoryViewQuotation openViewQuotation={openViewQuotation} handleCloseViewQuotation={handleCloseViewQuotation} viewQuotationUrl={viewQuotationUrl} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapCustomerId={sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId} />
      <UploadLpoViewOrder orderNumber={selectedOrder && selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder && selectedOrder.orderNumber ? selectedOrder.orderNumber : orderNumber} quotationNumber={quotationNumber} openUploadLpo={openUploadLpo} handleUploadLpo={handleUploadLpo} sapCustomerId={sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId} />
      <OrderHistoryViewLPO openViewLPO={openViewLPO} handleCloseViewLPO={handleCloseViewLPO} quotationNumber={quotationNumber} orderNumber={orderNumber} enquiryNumber={enquiryNumber} viewQuotationUrl={viewQuotationUrl} updateLPO={updateLPO} sapCustomerId={sessionStorage.getItem('userType') === 'FSE' ? actualOrder && actualOrder.customerId : sapCustomerId} fileExtension={fileExtention} />
    </Fragment >
  );
};

export default OrderHistory;