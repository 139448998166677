/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TextField, MenuItem } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RequestCorrectionDialog from './RequestCorrectionDialog';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import ActionButtons from './ActionButtons';
import './Access.css';
import { useEffect } from 'react';
import { onboardingService } from '../../services/onboard.service';
import { get } from 'lodash';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { getDealerDetails, getShipToPartyAddress } from '../../store/actions/onboard.actions';
import { checkFeatureAccess, getNewBrand, getNewlyAddedBrand, filterFeatureData } from '../../common/components/util';
import { OnboardingAuthorisedSignatoriesValidation, ValidationErrorMessage } from '../../constants/messages';
import { useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SubmitDialogKAM from '../Dealers/AddDealer/submitYourRequestDialogKam';
import ConfirmSapIdDialog from './confirmSapIdDialog';
import DeleteDialog from '../../common/components/deleteDialog';
import { Checkbox, FormControl } from "@material-ui/core";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import ListItemText from '@material-ui/core/ListItemText';
import TradePointAccessDialog from './TradePointAccessDialog';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
  footer: {
    paddingLeft: 220
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AccessKam(props: any) {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [selectedTab, setselectedTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const { dealerDetails, shipToPartyAddressData }: any = useSelector((state: any) => state.onboard);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const [paramsdata, setparamsdata] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [isApprove, setIsApprove] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [isOpenSapIdDialog, setIsOpenSapIdDialog] = React.useState(false);
  const [notfetchedInitialData, setnotfetchedInitialData] = React.useState(true);
  const [formSubmitted, setformSubmitted] = React.useState(false);
  // const [formSubmitted, setformSubmitted] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [sapAvailabilityChecked, setsapAvailabilityChecked] = React.useState<any>(false);
  const [accessTabData, setAccessTabData] = React.useState<any>();
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setfirstNameError] = React.useState<boolean>(false);
  const [firstNameErrorMessage, setfirstNameErrorMessage] = React.useState<string>("");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [customerTierType, setCustomerTierType] = React.useState<string>("");
  const [userType, setUserType] = React.useState<any>("");
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [lastNameErrorMessage, setLastNameErrorMessage] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [isTradePointAccess, setIsTradepointAccess] = React.useState(false);
  const onboardingType = sessionStorage.getItem('customerOnboardingType');
  const [openTradePointAccessDialog, setopenTradePointAccessDialog] = React.useState<boolean>(false);

  const [formData, setformData] = React.useState<any>({
    "featureAccessibility": [

    ],
    "addParameter": {
      "sapCustomerId": "",
      "payerId": "",
      "billToParty": "",
      "shipToParty": "",
      "webApplicationLoginId": "",
      "brands": [

      ]
    },
    "userInfo": {
      "name": "",
      "companyName": "",
      "email": "",
      "username": "",
      "status": "",
      "organizationIds": [
      ],
      "assignKam": "",
      "assignKamName": "",
      "sapCustomerId": "",
      "b2bUserId": "",
      "customerType": sessionStorage.getItem('customerOnboardingType')
    }
  });
  const [brands, setBrands] = React.useState<any>([[], []])
  const [customer, setCustomer] = React.useState<any>("");

  useEffect(() => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType) setCustomer(dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType)
  }, [dealerDetails])

  const selectBrands = [
    {
      brand: "Toyota",
      brandId: "2001",
      cateogory: "OEM",
      materialGroup: ["AJDR", "TJDR", "AJDR1"]
    },
    {
      brand: "Honda",
      brandId: "2002",
      category: "OEM",
      materialGroup: ["BJDR", "HJDR", "HJDR1"]
    }
  ]

  const handleBrandChange = (e: any, i: any) => {
    let brand = [...brands]
    brand[i] = e.target.value
    setBrands(brand)
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  useEffect(() => {
    loadInitialData();
    props.setDataUpdated(false);
    const userType = sessionStorage.getItem('userType');
    setUserType(userType);
  }, [])

  const loadInitialData = async () => {
    let paramsData: any = await onboardingService.getAllAcessParams();
    let data = await onboardingService.getAccessTabData(b2bUserId);
    await dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
    await dispatch(getShipToPartyAddress(b2bUserId))
    if (data && data.data) {
      setIsTradepointAccess(data.data.tradepointAccessToCustomer);
      setAccessTabData(data.data.userInfo);
      if (!data.data.addParameter || (Object.keys(data.data.addParameter) && Object.keys(data.data.addParameter).length < 1)) {
        data.data.addParameter = {
          "sapCustomerId": data.data.userInfo.sapCustomerId,
          "payerId": "",
          "billToParty": "",
          "shipToParty": "",
          "webApplicationLoginId": "",
          "brands": [
          ]
        }
      } else {
        data.data.addParameter["sapCustomerId"] = data.data.userInfo.sapCustomerId;
      }

      if (!data.data.addParameter || !data.data.addParameter.brands || !data.data.addParameter.brands.length) {
        if (data.data.userInfo.organizationIds[0] !== null) {
          data.data.userInfo.organizationIds = data.data.userInfo.organizationIds.map((item: any) => {
            if (item.organizationId !== '2003') {
              item['salesOrganization'] = item.organizationId
              item['assignKam'] = data.data.userInfo.assignKam
              return item
            }
          })
          data.data.addParameter['brands'] = data.data.userInfo.organizationIds.filter((b: any) => b.organizationId !== '2003');
        }

      } else {
        let availableBrands: any = []
        data.data.addParameter['brands'] = data.data.addParameter['brands'].map((item: any) => {
          if (!item['salesOrganization']) {
            item['salesOrganization'] = item.organizationId
          }
          availableBrands.push(parseInt(item.organizationId))
          item['assignKam'] = data.data.userInfo.assignKam
          return item
        })
        data.data.userInfo.organizationIds.map((item: any) => {
          if (item.organizationId !== '2003') {
            item['salesOrganization'] = item.organizationId
            item['assignKam'] = data.data.userInfo.assignKam
            if (!availableBrands.includes(parseInt(item.organizationId))) {
              data.data.addParameter['brands'].push(item)
            }
            return item
          }
        })
      }
      setformData(data.data)
      if (data && data.data && data.data.addParameter && data.data.addParameter['webApplicationLoginId']) {
        setsapAvailabilityChecked(true)
      }
      if (data.data.userInfo && data.data.userInfo.firstName) {
        setFirstName(data.data.userInfo.firstName);

      }
      if (data.data.userInfo && data.data.userInfo.lastName) {
        setLastName(data.data.userInfo.lastName)
      }
      if (data.data.userInfo && data.data.userInfo.email) {
        setEmail(data.data.userInfo.email)
      }
      if(data.data.customerTierType){
        setCustomerTierType(data.data.customerTierType);
      }
      if (data.data.featureAccessibility && Array.isArray(data.data.featureAccessibility)) {
        let allowedIds: any = [];
        data.data.featureAccessibility.map((feature: any) => {
          if (feature.value) {
            // if (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType === 'Workshop' && feature.featureCode === "VIEW_CR_STATUS") {
            // } else {
            allowedIds.push(feature.featureCode)
            // }
          }
        })
        if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
          let tempData = paramsData.data[0].featureAccessibility
          tempData.map((param: any) => {
            if (allowedIds.includes(param.featureCode)) {
              param.value = true
            } else {
              param.value = false
            }
            return param
          })
          if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
            let filterData = filterFeatureData(tempData)
            setparamsdata(filterData)
          } else {
            setparamsdata(tempData)
          }
        } else {
          setparamsdata(paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility)
        }
      } else {
        let featureData = paramsData?.data[0]?.featureAccessibility;
        if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
          let filterData = filterFeatureData(featureData)
          setparamsdata(filterData)
        } else {
          setparamsdata(paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility)
        }
      }
    }
    setnotfetchedInitialData(false)
  }

  function onChangeFirstName(data: any) {
    setFirstName(data.target.value);
    if (data.target.value != "") {
      setfirstNameError(false)
    }
    else {
      // setfirstNameError(true)

    }
    props.setDataUpdated(true);
  }

  function onChangeLastName(data: any) {
    setLastName(data.target.value);
    if (data.target.value != "") {
      setLastNameError(false)
    }
    else {
      // setLastNameError(true)

    }
    props.setDataUpdated(true);
  }

  function onChangeEmail(data: any) {
    setEmail(data.target.value);
    props.setDataUpdated(true);
    if (data.target.value) {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.target.value.trim()))) {
        setEmailError(true);
        setEmailErrorMessage("Invalid email");
      }
      else {
        setEmailError(false);
        setEmailErrorMessage("");
      }
    }
    else {
      setEmailError(false);
      setEmailErrorMessage("");
    }
  }

  function validateFormFields(action: string, value: any) {
    switch (action) {
      case "lastName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;
      }
      case "firstName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      case "email": {
        const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        return re.test(value.trim()) ? false : true;
      }
      default:
        return true;
    }
  }

  function validationForm() {
    let isValid = true;
    if (firstName === "") {
      isValid = false;
      setfirstNameError(true);
    }
    else {
      if (!validateFormFields('firstName', firstName)) {
        setfirstNameError(true);
        setfirstNameErrorMessage(ValidationErrorMessage.LENGTH)
        isValid = false;
      }
      else {
        setfirstNameError(false);
        setfirstNameErrorMessage("")
        // isValid = true;
      }
    }
    if (lastName === "") {
      isValid = false;
      setLastNameError(true);
    }
    else {
      if (!validateFormFields('lastName', lastName)) {
        setLastNameError(true);
        setLastNameErrorMessage(ValidationErrorMessage.LENGTH)
        isValid = false;

      }
      else {
        setLastNameError(false);
        setLastNameErrorMessage("")

        // isValid = true;
      }
    }
    if (email === "") {
      isValid = false;
      setEmailError(true);
    }
    else {
      // if (!validateFormFields('email', email)) {
      //   setEmailError(true);
      //   setEmailErrorMessage(OnboardingAuthorisedSignatoriesValidation.EMAIL_TEXT)
      //   isValid = false;

      // }
      // else {
      setEmailError(false);
      setEmailErrorMessage("")

      // isValid = true;
      // }
    }
    return isValid;
  }

  const approveCallback = async () => {
    let dataValid = true;
    let dealerDetails: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
    let shipToPartyAddressData: any = await dispatch(getShipToPartyAddress(b2bUserId));
    if (dealerDetails && dealerDetails.data && shipToPartyAddressData && shipToPartyAddressData.data) {
      if (dealerDetails.data.partnerDetails && dealerDetails.data.partnerDetails.length > 0) {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add at leaset one Owner/Partner");
        setIsApprove(false);
        dataValid = false;
      }

      if (dealerDetails.data.authorizedSignatories && dealerDetails.data.authorizedSignatories.length > 0 || customer.toLowerCase() === "workshop") {
        setIsApprove(true);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please add at leaset one Signatory");
        setIsApprove(false);
        dataValid = false;
      }
      if (dealerDetails.data.treadLicense && dealerDetails.data.treadLicense.length > 0) {
        let allTl: any = [];
        dealerDetails.data.treadLicense.map((tl: any) => {
          if (tl.regionCode && tl.tradeLicenseNo && tl.expiryDate && tl.documentId) {
            allTl.push(true);
          } else {
            allTl.push(false);
          }
        });
        if (allTl && allTl && allTl.every((p: any) => p === true)) {
          setIsApprove(true);
        } else {
          if (customer !== "Government") {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage("All fields are mandatory in Trande licence.");
            setIsApprove(false);
            dataValid = false;
          }
        }
      }
      if (dealerDetails.data.companySeal && dealerDetails.data.companySeal.documentId) {
        setIsApprove(true);
      } else {
        if (customer !== "Government" && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer") {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Please add companySeal document.");
          setIsApprove(false);
          dataValid = false;
        }
      }
      // if (dealerDetails.data.memorandumOfAssociation && dealerDetails.data.memorandumOfAssociation.documentId && dealerDetails.data.memorandumOfAssociation.expiryDate) {
      //   setIsApprove(true);
      // } else {
      //   setOpenErrorSnackbar(true);
      //   setOpenSnackbar(true);
      //   setSuccessMessage("Please add Memorandum Of Association document.");
      //   setIsApprove(false);
      //   dataValid = false;
      // }
      if (dealerDetails.data.vatCertificateCopy && dealerDetails.data.vatCertificateCopy.documentId && dealerDetails.data.vatCertificateCopy.expiryDate) {
        setIsApprove(true);
      } else {
        if (customer !== "Government" && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "tpcdealer") {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Please add Vat Certificate Copy document.");
          setIsApprove(false);
          dataValid = false;
        }
      }
      if (sessionStorage.getItem('customerOnboardingType') === 'Government' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer' || sessionStorage.getItem('customerOnboardingType') === 'Fleet' || sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
        if (dealerDetails.data.kamReview) {
          let kamData = dealerDetails.data.kamReview;
          if (kamData.companyIndustrySector) {
            setIsApprove(true);
          } else {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage("Please fill all mandatory fields and documents in KAM review.");
            setIsApprove(false);
            dataValid = false;
          }
        }
      } else {
        if (dealerDetails.data.kamReview) {
          let kamData = dealerDetails.data.kamReview;
          if (kamData.size && kamData.stock && kamData.salesSince && kamData.growthPlan && kamData.numberOfStaff && kamData.paymentHistory && kamData.salesToCutomerCurrencyType && kamData.pictureOfHo && kamData.pictureOfHo.length > 0) {
            setIsApprove(true);
          } else {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage("Please fill all mandatory fields and documents in KAM review.");
            setIsApprove(false);
            dataValid = false;
          }
        }
      }

      if (shipToPartyAddressData && shipToPartyAddressData.data && shipToPartyAddressData.data.addresses && shipToPartyAddressData.data.addresses.length > 0) {
        let allAdd: any = [];
        shipToPartyAddressData.data.addresses.map((add: any) => {
          if (add.shiftToPartyCustomerNumber && add.transportationZone) {
            allAdd.push(true);
          } else {
            allAdd.push(false);
          }
        });
        if (allAdd && allAdd && allAdd.every((p: any) => p === true)) {
          setIsApprove(true);
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("TransportationZone and ShiftToPartyCustomerNumber is mandatory in ship to party.");
          setIsApprove(false);
          dataValid = false;
        }
      }
      let mainvalid = await validateAction();
      if (dataValid && mainvalid) {
        setIsApprove(true);
      } else {
        setIsApprove(false);
        dataValid = false;
      }
    }
    return dataValid;
  }

  const generateCustomerId = async () => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.kamReview) {
      let kamData = dealerDetails.data.kamReview;
      if (kamData.companyIndustrySector && kamData.companyIndustrySector !== "") {
        let featureData = paramsdata.filter((item: any) => item.value)
        let data = {
          "sapCustomerId": "",
          "sessionDetails": {
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          },
          "b2bUserId": b2bUserId,
          "isOnboardDealer": true,
          "featureAccessibility": featureData,
          "tradepointAccessToCustomer": isTradePointAccess
        }
        setLoading(true);
        const responseData = await onboardingService.generateCustomerId(data)
        if (responseData && responseData.message && responseData.message.status == "S") {
          setLoading(false);
          props.setDataUpdated(false);
          await loadInitialData();
          if (shipToPartyAddressData && shipToPartyAddressData.data && shipToPartyAddressData.data.addresses && shipToPartyAddressData.data.addresses.length > 1) {
            setIsOpenSapIdDialog(true)
          }
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.message.message);
        } else {
          setLoading(false);
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
        }
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please fill all mandatory fields and documents in KAM review and Save.");
      }
    }

  }

  const validationOnClickSubmit = () => {
    let dataValid = true;
    if (dealerDetails && dealerDetails.data && shipToPartyAddressData && shipToPartyAddressData.data) {
      if (dealerDetails.data.kamReview) {
        let kamData = dealerDetails.data.kamReview;
        if (kamData.size && kamData.stock && kamData.salesSince && kamData.growthPlan && kamData.numberOfStaff && kamData.paymentHistory && kamData.salesToCutomerCurrencyType && kamData.pictureOfHo && kamData.pictureOfHo.length > 0) {
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Please fill all mandatory fields and documents in KAM review.");
          dataValid = false;
        }
      }
      if (shipToPartyAddressData && shipToPartyAddressData.data && shipToPartyAddressData.data.addresses && shipToPartyAddressData.data.addresses.length > 0) {
        let allAdd: any = [];
        shipToPartyAddressData.data.addresses.map((add: any) => {
          if (add.shiftToPartyCustomerNumber && add.transportationZone) {
            allAdd.push(true);
          } else {
            allAdd.push(false);
          }
        });
        if (allAdd && allAdd && allAdd.every((p: any) => p === true)) {
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("TransportationZone and ShiftToPartyCustomerNumber is mandatory in ship to party.");
          dataValid = false;
        }
      }
      if (dataValid) {
      } else {
        dataValid = false;
      }
    }
    return dataValid;
  }

  const validateTabs = () => {
    if (validationOnClickSubmit()) {
      setDeleteMessage("Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost.");
      handleopenCloseSubmit(true);
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage("Please fill all mandatory fields and documents in the KAM Review Tab and Ship to Party Tab and Save.");
    }
  }

  const saveAccessTab = async (sendInvitation: any) => {
    setformSubmitted(true);
    if (accessTabData && accessTabData.sapCustomerId === '') {
      let responseData: any;
      let featureData = paramsdata.filter((item: any) => item.value);
      if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
        let accessRequired = checkFeatureAccess(featureData);
        if (accessRequired.length === 0) {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Choose at least one parameter Tyre Access or Part Access");
          return;
        }
      }
      if (dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending") {
        let data = {
          "sapCustomerId": "",
          "sessionDetails": {
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          },
          "b2bUserId": b2bUserId,
          "sendInvitation": dealerDetails.data.status === "Dealer confirmation pending" && accessTabData.b2cUserId ? false : sendInvitation,
          "featureAccessibility": featureData,
          "userInfo": {
            "firstName": firstName,
            "lastName": lastName,
            "emailId": email
          }
        }
        if (validationForm()) {
          setLoading(true);
          const responseData = await onboardingService.sendInvitation(data);
          setnotfetchedInitialData(false)
          if (responseData && responseData.message && responseData.message.status == "S") {
            setOpenErrorSnackbar(false);
            setOpenSnackbar(true);
            setSuccessMessage(responseData.message.message);
            props.setDataUpdated(false);
            loadInitialData();
            setOpenSubmitDialog(false);
            setLoading(false);
            if (sendInvitation && accessTabData.b2cUserId === '') {
              setTimeout(() => {
                history.push('/dealers');
              }, 1000);
            }
          } else {
            setOpenErrorSnackbar(true);
            setLoading(false);
            setOpenSnackbar(true);
            setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
          }
        }
        else {
          setOpenErrorSnackbar(true);
          setLoading(false);
          setOpenSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields');
        }
      }
      else {
        let data = {
          "sapCustomerId": "",
          "sessionDetails": {
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          },
          "b2bUserId": b2bUserId,
          "isOnboardDealer": false,
          "featureAccessibility": featureData,
          "tradepointAccessToCustomer": isTradePointAccess,
        }
        setLoading(true);
        responseData = await onboardingService.generateCustomerId(data);
        if (responseData && responseData.message && responseData.message.status == "S") {
          props.setDataUpdated(false);
          loadInitialData();
          setLoading(false);
          setOpenErrorSnackbar(false);
          setTimeout(() => {
            setOpenSnackbar(true);
            setSuccessMessage(responseData.message.message);
          }, 3000)
        } else {
          setOpenErrorSnackbar(true);
          setLoading(false);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
        }
      }
    } else {
      if (!isTradePointAccess && accessTabData && accessTabData.isAddedByFse) {
        let data = {
          "b2bUserId": b2bUserId,
          "tradepointAccessToCustomer": false,
          "approveCustomer": sendInvitation ? true : false,
          "approvedBy": sessionStorage.getItem('webApplicationLoginId')
        }
        setLoading(true);
        const responseData = await onboardingService.saveUpdateIBAccessTab(data)
        setnotfetchedInitialData(false)
        if (responseData && responseData.message && responseData.message.status == "S") {
          props.setDataUpdated(false);
          loadInitialData();
          setOpenErrorSnackbar(false);
          setOpenSnackbar(true);
          setOpenSubmitDialog(false);
          setLoading(false);
          setSuccessMessage(responseData.message.message);
          if (sendInvitation) {
            setTimeout(() => {
              history.push('/dealers');
            }, 1000);
          }
        } else {
          setOpenErrorSnackbar(true);
          setLoading(false);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.message ? responseData.message.message : "Something went Wrong");
        }
      } else {
        if (sapAvailabilityChecked) {
          let featureData = paramsdata.filter((item: any) => item.value);
          if (sessionStorage.getItem('customerOnboardingType') === "TBL Dealer") {
            let accessRequired = checkFeatureAccess(featureData);
            if (accessRequired.length === 0) {
              setOpenErrorSnackbar(true);
              setOpenSnackbar(true);
              setOpenSubmitDialog(false);
              setSuccessMessage("Choose at least one parameter Tyre Access or Part Access");
              return;
            }
          }
          let data = {
            "sapCustomerId": formData.userInfo.sapCustomerId,
            "sessionDetails": {
              "clientIPAddress": sessionStorage.getItem('clientIp'),
              "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
            },
            "b2bUserId": b2bUserId,
            "isApprove": sendInvitation ? sendInvitation : false,
            "customerType": sessionStorage.getItem('customerOnboardingType'),
            "featureAccessibility": featureData,
            "addParameter": formData.addParameter,
            "userInfo": {
              "firstName": firstName ? firstName : formData.userInfo.firstName,
              "lastName": lastName ? lastName : formData.userInfo.lastName,
              "emailId": email ? email : formData.userInfo.email,
              "webApplicationLoginId": formData.addParameter.webApplicationLoginId ? formData.addParameter.webApplicationLoginId : ''
            },
            "tradepointAccessToCustomer": true
          }

          let hasValidationIssue = false;
          const requiredBandField = ['shipToParty', 'payerId', 'billToParty', 'webApplicationLoginId']
          requiredBandField.map((item: any) => {
            if (!formData['addParameter'] || !formData['addParameter'][item]) {
              hasValidationIssue = true
            }
          })
          let counter = 0;
          let brandName = ""
          let tempdata: any = JSON.parse(JSON.stringify(data.addParameter['brands']));
          if (tempdata) {
            tempdata = tempdata.filter((item: any) => {
              if (item.assignKam && item.salesEmployee && item.salesGroup && item.salesOffice && item.division && item.distributionChannel && item.salesOrganization && item.creditControlArea) {
                counter += 1
                return true
              } else {
                let fields = ["creditControlArea", "distributionChannel", "division", "salesOffice", "salesGroup", "salesEmployee"]
                fields.map((subItem) => {
                  if (item[subItem]) {
                    hasValidationIssue = true
                    brandName = item.make
                  }
                })
                return false
              }
            })
          }
          if (counter == 0) {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage("Please fill the details for atleast one brand below.");
            return
          } else if (hasValidationIssue) {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage(`Please complete the details for the following brand(s): ${brandName}`);
            return
          }
          if (hasValidationIssue) {
            setOpenErrorSnackbar(true);
            setOpenSnackbar(true);
            setSuccessMessage("Please fill all mandatory fields");
            return
          }
          setnotfetchedInitialData(true)
          data.addParameter['brands'] = tempdata;
          setLoading(true);
          const responseData = await onboardingService.saveUpdateAcessTab(data)
          setnotfetchedInitialData(false)
          if (responseData && responseData.message && responseData.message.status == "S") {
            props.setDataUpdated(false);
            loadInitialData();
            setOpenErrorSnackbar(false);
            setOpenSnackbar(true);
            setSuccessMessage(responseData.message.message);
            setOpenSubmitDialog(false);
            setLoading(false);
            if (sendInvitation && accessTabData.b2cUserId === '') {
              setTimeout(() => {
                history.push('/dealers');
              }, 1000);
            }
          } else {
            setOpenSubmitDialog(false);
            setOpenErrorSnackbar(true);
            setLoading(false);
            setOpenSnackbar(true);
            setSuccessMessage(responseData.message ? responseData.message : "Something went Wrong");
          }
        } else {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Check User Id Availability before Saving");
        }
      }
    }
  }

  const validateAction = async () => {
    setformSubmitted(true);
    if (sapAvailabilityChecked) {
      let featureData = paramsdata.filter((item: any) => item.value)
      let data = {
        "sapCustomerId": formData.userInfo.sapCustomerId,
        "sessionDetails": {
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        "b2bUserId": b2bUserId,
        "isApprove": false,
        "customerType": sessionStorage.getItem('customerOnboardingType'),
        "featureAccessibility": featureData,
        "addParameter": formData.addParameter,
        "userInfo": {
          "firstName": formData.userInfo.firstName,
          "lastName": formData.userInfo.lastName,
          "emailId": formData.userInfo.email,
          "webApplicationLoginId": ""
        },
        "tradepointAccessToCustomer": true
      }

      let hasValidationIssue = false;
      const requiredBandField = ['shipToParty', 'payerId', 'billToParty', 'webApplicationLoginId']
      requiredBandField.map((item: any) => {
        if (!formData['addParameter'] || !formData['addParameter'][item]) {
          hasValidationIssue = true
        }
      })
      let counter = 0;
      let brandName = ""
      let tempdata: any = JSON.parse(JSON.stringify(data.addParameter['brands']));
      if (tempdata) {
        tempdata = tempdata.filter((item: any) => {
          if (item.assignKam && item.salesEmployee && item.salesGroup && item.salesOffice && item.division && item.distributionChannel && item.salesOrganization && item.creditControlArea) {
            counter += 1
            return true
          } else {
            let fields = ["creditControlArea", "distributionChannel", "division", "salesOffice", "salesGroup", "salesEmployee"]
            fields.map((subItem) => {
              if (item[subItem]) {
                hasValidationIssue = true
                brandName = item.make
              }
            })
            return false
          }
        })
      }
      if (counter == 0) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please fill the details for atleast one brand below.");
        return
      } else if (hasValidationIssue) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage(`Please complete the details for the following brand(s): ${brandName}`);
        return
      }
      if (hasValidationIssue) {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Please fill all mandatory fields");
        return false
      }
      data.addParameter['brands'] = tempdata;
      return data
    } else {
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setSuccessMessage("Check User Id Availability before Saving");
      return false
    }
  }
  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  const handleBackClick = () => {
    // props.setTabValue(2)
    // return
    if (props.dataUpdated) {
      setDeleteMessage('Do you wish to proceed without saving the data?');
      handleopenClose(true);
    } else {
      props.setTabValue(3)
    }
  }

  const isDisabled = (access: any) => {
    if (access.featureCode === 'SEARCH_EPC'
      || access.featureCode === 'LIST_BILL_DOC' || access.featureCode === 'ACCOUNT_STATEMENT'
      || access.featureCode === 'CURT_PREVI_MONTH_SALES' || access.featureCode === 'DOC_EXP') {
      return true
    }
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    if (accessTabData && accessTabData.isAddedByFse && !isTradePointAccess) {
      setOpenSubmitDialog(open ? true : false);
    } else {
      if (validationForm()) {
        setOpenSubmitDialog(open ? true : false);
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage('Please fill all mandatory fields');
      }
    }
  };

  return (
    <Fragment>
      {(loading || notfetchedInitialData) && <FullscreenLoader />}

      {accessTabData && accessTabData.isAddedByFse && (
        <div className="col-12 mt-1">
          <div className="d-flex flex-row pt-3">
            <div className="pt-1">
              <Checkbox
                checked={isTradePointAccess}
                disabled={(sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerOnboardingType') === "TBL Dealer")? true : false}
                onChange={(e) => {
                  // setIsTradepointAccess(e.target.checked);
                  setopenTradePointAccessDialog(true);
                }}
                className=""
              />
            </div>
            <span className="pt-3 pr-2 fonts-catalogue">Enable Tradepoint access to Customer</span>
          </div>
          {!isTradePointAccess && accessTabData && accessTabData.isAddedByFse && (
            <div className='mb-2'><span className="file-close-color ml-2 mr-1">Note:</span> Kindly check this box if you want to provide Tradepoint access to Customer.</div>
          )}
          {accessTabData && accessTabData.sapCustomerId && !isTradePointAccess && accessTabData && accessTabData.isAddedByFse && (
            <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between ">
              <TextField id="sapCustomerId"
                disabled
                required
                value={formData && formData.addParameter && formData.addParameter.sapCustomerId}
                error={(!formData || !formData.addParameter || !formData.addParameter.sapCustomerId) && formSubmitted}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Customer No" variant="outlined" margin="dense"
                onChange={(e) => {
                  props.setDataUpdated(true);
                  let formDataTemp = JSON.parse(JSON.stringify(formData));
                  formDataTemp.addParameter['sapCustomerId'] = e.target.value
                  setformData(formDataTemp)
                }}
              >
              </TextField>
            </div>
          )}
        </div>
      )}
      <div className="card col-12 m-0 p-0">
        <div className="row mb-0 px-2">
          {accessTabData && accessTabData.isAddedByFse && !isTradePointAccess ? '' : (
            <div className="col-12 mt-1">
              <div className="d-flex align-items-center">
                <h5 className="page-sub-title px-2 mt-1">Customer Access</h5>
                <div className="row-space-start ml-auto">
                  <Button
                    className="mr-2 text-info"
                    startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                    onClick={async () => {
                      const paramsData = await onboardingService.getAllAcessParams();
                      if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
                        if (sessionStorage.getItem('customerOnboardingType') !== "TBL Dealer") {
                          let filterData = filterFeatureData(paramsData.data[0].featureAccessibility)
                          setparamsdata(filterData)
                        } else {
                          setparamsdata(paramsData.data[0].featureAccessibility)
                        }
                        props.setDataUpdated(true);
                      }
                    }}
                  ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Reset to Default
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {accessTabData && accessTabData.isAddedByFse && !isTradePointAccess ? '' : (
        <div className="card col-12 m-0 p-0">
          <div className="row mb-1 mt-1">
            {paramsdata && paramsdata.map((itemParam: any, index: any) => {
              return <div className="col-sm-12 col-md-4 col-lg-4 px-3 pr-2 mb-2 mt-2 row-space-between border-right">
                <div className="lock-indicator font-size-12 px-2">
                  {itemParam.label}
                </div>
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      {/* <AntSwitch disabled={(itemParam.featureCode === 'MANAGE_EXPORT_TO_EXL') || (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType === 'Workshop' && itemParam.featureCode === "VIEW_CR_STATUS") || isDisabled(itemParam)} */}
                      {/* checked={(dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType === 'Workshop' && itemParam.featureCode === "VIEW_CR_STATUS") ? false : itemParam.value} onChange={(e) => { */}
                      <AntSwitch disabled={
                        ((itemParam.featureCode === 'IAM_MAP' &&
                          (!(customerTierType.toLowerCase() === 'tpcdealer' || customerTierType.toLowerCase() === "workshop")) ||
                          ((userType && userType.toLowerCase() === 'fse') || (userType && userType.toLowerCase() === 'superadmin')))
                        ) ||
                        (itemParam.featureCode === 'MANAGE_EXPORT_TO_EXL') ||
                        isDisabled(itemParam)
                      }
                        checked={itemParam.value} onChange={(e) => {
                          props.setDataUpdated(true);
                          let paramDataTemp = JSON.parse(JSON.stringify(paramsdata));
                          paramDataTemp[index].value = !itemParam.value
                          setparamsdata(paramDataTemp)
                        }} />
                    </Grid>
                  </Grid>
                </Typography>
              </div>
            })}
          </div>
        </div>
      )}
      {accessTabData && accessTabData.isAddedByFse ? '' : (
        <>
          {dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" ||
            dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending" ?
            <div className="row m-0 col-12 mt-3">
              <div className="col-2 col-md-2 col-sm-2 mb-4">
                <div className="info-sub-title-value font-size-12">Name</div>
                <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.name}</div>
              </div>
              <div className="col-3 col-md-3 col-sm-2 mb-4">
                <div className="info-sub-title-value font-size-12">Company Name</div>
                <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.companyName}</div>
              </div>
              <div className="col-3 col-md-3 col-sm-3 mb-4">
                <div className="info-sub-title-value font-size-12">Email</div>
                <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.email}</div>
              </div>
              <div className="col-3 col-md-2 col-sm-2 mb-4">
                <div className="info-sub-title-value font-size-12">UserName</div>
                <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.username}</div>
              </div>
              <div className="col-2 col-md-2 col-sm-2 mb-4">
                <div className="info-sub-title-value font-size-12">Status</div>
                <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.status}</div>
              </div>
            </div>
            : (
              <div className="row m-0 col-12 mt-3">
                <div className="col-2 col-md-2 col-sm-2 mb-4">
                  <div className="info-sub-title-value font-size-12">Name</div>
                  <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.name}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-2 mb-4">
                  <div className="info-sub-title-value font-size-12">Company Name</div>
                  <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.companyName}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Email</div>
                  <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.email}</div>
                </div>
                <div className="col-3 col-md-2 col-sm-2 mb-4">
                  <div className="info-sub-title-value font-size-12">UserName</div>
                  <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.username}</div>
                </div>
                <div className="col-2 col-md-2 col-sm-2 mb-4">
                  <div className="info-sub-title-value font-size-12">Status</div>
                  <div className="subTitle mt-2">{formData && formData.userInfo && formData.userInfo.status}</div>
                </div>
              </div>
            )}
        </>
      )}

      {accessTabData && accessTabData.sapCustomerId ? (
        <>
          {accessTabData && accessTabData.isAddedByFse && !isTradePointAccess ? '' : (
            <>
              <div className="card col-12 m-0 p-0">
                <div className="row mt-2 mb-1">
                  <h6 className="page-sub-title mb-1 px-4 mt-2 mb-0">Add Parameter For Admin</h6>
                </div>
                <div className="row mb-1 mt-0 px-4">
                  {accessTabData && accessTabData.isAddedByFse && (
                    <>
                      <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                        <TextField id=""
                          required
                          type="text"
                          value={firstName}
                          error={firstNameError}
                          // helperText={setSizeError && setSize && <span style={{ color: 'red' }}>{setSizeValidationMessage}</span>}
                          onChange={(e: any) => onChangeFirstName(e)}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="First Name" variant="outlined">
                        </TextField>
                      </div>
                      <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-3 mb-3 mt-1 row-space-between">
                        <TextField id=""
                          required
                          type="text"
                          value={lastName}
                          error={lastNameError}
                          onChange={(e: any) => onChangeLastName(e)}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Last Name" variant="outlined">
                        </TextField>
                      </div>
                      <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-3 mb-3 mt-1 row-space-between">
                        <TextField id=""
                          required
                          type="text"
                          value={email}
                          error={emailError}
                          onChange={(e: any) => onChangeEmail(e)}
                          helperText={emailError && email == "" && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Email" variant="outlined">
                        </TextField>
                      </div>
                    </>
                  )}
                  <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between ">
                    <TextField id="sapCustomerId"
                      disabled
                      required
                      value={formData && formData.addParameter && formData.addParameter.sapCustomerId}
                      error={(!formData || !formData.addParameter || !formData.addParameter.sapCustomerId) && formSubmitted}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Customer No" variant="outlined" margin="dense"
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(formData));
                        formDataTemp.addParameter['sapCustomerId'] = e.target.value
                        setformData(formDataTemp)
                      }}
                    >
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-2 row-space-between">
                    <TextField id="shipToParty"
                      required
                      value={formData && formData.addParameter && formData.addParameter.shipToParty}
                      error={(!formData || !formData.addParameter || !formData.addParameter.shipToParty) && formSubmitted}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Ship To Party" variant="outlined" margin="dense"
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(formData));
                        formDataTemp.addParameter['shipToParty'] = e.target.value
                        setformData(formDataTemp)
                      }}
                    >
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-2 row-space-between">
                    <TextField id="payer"
                      required
                      value={formData && formData.addParameter && formData.addParameter.payerId}
                      error={(!formData || !formData.addParameter || !formData.addParameter.payerId) && formSubmitted}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Payer" variant="outlined" margin="dense"
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(formData));
                        formDataTemp.addParameter['payerId'] = e.target.value
                        setformData(formDataTemp)
                      }}
                    >
                    </TextField>
                  </div>
                </div>
                <div className="row mb-1 mt-0 px-4">
                  <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                    <TextField id="billToParty"
                      required
                      value={formData && formData.addParameter && formData.addParameter.billToParty}
                      error={(!formData || !formData.addParameter || !formData.addParameter.billToParty) && formSubmitted}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Bill to Party" variant="outlined" margin="dense"
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(formData));
                        formDataTemp.addParameter['billToParty'] = e.target.value
                        setformData(formDataTemp)
                      }}
                    >
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-0 row-space-between">
                    <TextField id="userSapId"
                      required
                      value={formData && formData.addParameter && formData.addParameter.webApplicationLoginId}
                      error={(!formData || !formData.addParameter || !formData.addParameter.webApplicationLoginId) && formSubmitted}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        if (e.target.value) {
                          let value: any = e.target.value.split(' ')
                          if ((value && value.length > 1) || !/^[a-z0-9]+$/i.test(e.target.value) || e.target.value.length > 10) {
                            return
                          }
                        }
                        let formDataTemp = JSON.parse(JSON.stringify(formData));
                        formDataTemp.addParameter['webApplicationLoginId'] = e.target.value
                        setformData(formDataTemp)
                        setsapAvailabilityChecked(false)
                      }}
                      InputProps={sapAvailabilityChecked ? {
                        endAdornment: <InputAdornment position="end">{
                          <CheckCircleIcon className="valid-user-id" />
                        }</InputAdornment>
                      } : {}}
                      label="User ID" variant="outlined" margin="dense">
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-0 row-space-between">
                    <Button
                      onClick={async () => {
                        if (formData.addParameter.webApplicationLoginId && formData.addParameter.webApplicationLoginId.trim()) {
                          const responseData = await onboardingService.checkSapAvailability({
                            "webApplicationLoginId": formData && formData.addParameter && formData.addParameter.webApplicationLoginId
                          })
                          if (responseData && responseData.message && responseData.message.status == "S") {
                            setOpenErrorSnackbar(false);
                            setOpenSnackbar(true);
                            setSuccessMessage(responseData.message.message);
                            setsapAvailabilityChecked(true)
                          } else {
                            setOpenErrorSnackbar(true);
                            setOpenSnackbar(true);
                            setSuccessMessage(responseData.message ? "User already exists with this ID" : "Something went Wrong");
                          }
                        } else {
                          setOpenErrorSnackbar(true);
                          setOpenSnackbar(true);
                          setSuccessMessage("Please enter User ID");
                        }
                      }}
                      variant="contained"
                      className="check-availability-button-access-tab font-size-11 mt-2">
                      Check Availability
                    </Button>
                  </div>
                </div>
                <div className="mt-3">
                  <ThemeProvider theme={theme}>
                    <Paper square>
                      <Tabs
                        className="tabSelect"
                        value={selectedTab}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={(event, newValue) => {
                          setselectedTab(newValue);
                        }}
                        aria-label="disabled tabs example">
                        {formData && formData.addParameter && formData.addParameter.brands.map((brand: any, index: any) => {
                          return <Tab
                            label={dealerDetails && dealerDetails.data && (dealerDetails.data.customerTierType === 'Workshop' || dealerDetails.data.customerTierType === 'tpcdealer') ? getNewBrand(brand.make) : getNewlyAddedBrand(brand.make)} value={index}
                          />
                        })}
                      </Tabs>
                    </Paper>
                  </ThemeProvider>
                </div>
              </div>
              {formData && formData.addParameter && formData.addParameter.brands.map((brand: any, index: any) => {
                if (selectedTab == index) {
                  return <div className="card col-12 m-0 p-0">
                    <TabPanel value={index} index={index}>
                      <div className="row mb-1 mt-2 px-4">
                        <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                          <TextField id="creditControlArea"
                            required
                            inputProps={{ maxLength: 4 }}
                            value={brand.creditControlArea}
                            error={!brand.creditControlArea && formSubmitted}
                            helperText={(brand.creditControlArea && brand.creditControlArea.length < 4 && !(/^[a-zA-Z0-9]+$/i.test(brand.creditControlArea))) && <span style={{ color: 'red' }}>Please enter 4 character in Credit Control Area and Special characters are not allowed.</span>}
                            label="Credit Control Area" variant="outlined" margin="dense" size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            onChange={(e) => {
                              // if (!e.target.value || (e.target.value && e.target.value.length < 5 && !(/^[a-zA-Z0-9]+$/i.test(brand.creditControlArea)))) {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['creditControlArea'] = e.target.value.toUpperCase();
                              setformData(formDataTemp)
                              // }
                            }}
                          >
                          </TextField>
                        </div>
                        <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-2 row-space-between">
                          <TextField id="salesOrg"
                            disabled
                            required
                            inputProps={{ maxLength: 4 }}
                            value={brand.salesOrganization}
                            error={!brand.salesOrganization && formSubmitted}
                            helperText={(brand.salesOrganization && brand.salesOrganization.length < 4 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesOrganization))) && <span style={{ color: 'red' }}>Please enter 4 character in Sales Organization and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['salesOrganization'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 5))
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Sales Org" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                        <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-2 row-space-between">
                          <TextField id="distributionChannel"
                            required
                            inputProps={{ maxLength: 2 }}
                            value={brand.distributionChannel}
                            error={!brand.distributionChannel && formSubmitted}
                            helperText={(brand.distributionChannel && brand.distributionChannel.length < 2 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.distributionChannel))) && <span style={{ color: 'red' }}>Please enter 2 digit in Distribution Channel and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['distributionChannel'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 3))
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Distribution Channel" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                      </div>
                      <div className="row mb-1 mt-0 px-4">
                        <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                          <TextField id="division"
                            required
                            inputProps={{ maxLength: 2 }}
                            value={brand.division}
                            error={!brand.division && formSubmitted}
                            helperText={(brand.division && brand.division.length < 2 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.division))) && <span style={{ color: 'red' }}>Please enter 2 Digit in Division and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['division'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 3))
                              //   onChange(e, 'division')
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Division" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                        <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-0 row-space-between">
                          <TextField id="salesOffice"
                            required
                            inputProps={{ maxLength: 4 }}
                            value={brand.salesOffice}
                            error={!brand.salesOffice && formSubmitted}
                            helperText={(brand.salesOffice && brand.salesOffice.length < 4 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesOffice))) && <span style={{ color: 'red' }}>Please enter 4 Character in Sales Office and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['salesOffice'] = e.target.value.toUpperCase();
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 5))
                              //   onChange(e, 'salesOffice')
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Sales Office" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                        <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-0 row-space-between">
                          <TextField id="salesGroup"
                            required
                            inputProps={{ maxLength: 3 }}
                            value={brand.salesGroup}
                            error={!brand.salesGroup && formSubmitted}
                            helperText={(brand.salesGroup && brand.salesGroup.length < 3 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesGroup))) && <span style={{ color: 'red' }}>Please enter 3 character in Sales Group and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['salesGroup'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 4))
                              //   onChange(e, 'salesGroup')
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Sales Group" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                      </div>
                      <div className="row mb-1 mt-0 px-4">
                        <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                          <TextField id="salesEmployee"
                            required
                            inputProps={{ maxLength: 6 }}
                            value={brand.salesEmployee}
                            error={!brand.salesEmployee && formSubmitted}
                            helperText={(brand.salesEmployee && brand.salesEmployee.length < 6 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesEmployee))) && <span style={{ color: 'red' }}>Please enter 6 character in Sales Employee and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['salesEmployee'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 7))
                              //   onChange(e, 'salesEmployee')
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Sales Employee" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                        <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-3 mt-0 row-space-between">
                          <TextField id="assignedKam"
                            disabled
                            required
                            inputProps={{ maxLength: 6 }}
                            value={brand.assignKam}
                            // error={!brand.assignKam && formSubmitted}
                            // helperText={(brand.assignKam && brand.assignKam.length < 6) && <span style={{ color: 'red' }}>Please enter 6 character in Assign Kam and Special characters are not allowed.</span>}
                            onChange={(e) => {
                              props.setDataUpdated(true);
                              let formDataTemp = JSON.parse(JSON.stringify(formData));
                              formDataTemp.addParameter['brands'][index]['assignKam'] = e.target.value
                              setformData(formDataTemp)
                              // if (!e.target.value || (e.target.value && e.target.value.length < 7))
                              //   onChange(e, 'assignKam')
                            }}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            label="Assigned KAM" variant="outlined" margin="dense">
                          </TextField>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                } else {
                  return null
                }
              })}
            </>
          )}
        </>
      ) : (
        <>
          <div className="row-space-start col-12 p-3">
            {dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending" ? (
              <div className="col-12 m-0 p-0">
                <div className="row mt-0 mb-1">
                  <h5 className="page-sub-title px-2 mt-1">Add Admin</h5>
                </div>
                <div className="row mb-1 mt-0 px-3">
                  <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                    <TextField id=""
                      required
                      type="text"
                      value={firstName}
                      error={firstNameError}
                      // helperText={setSizeError && setSize && <span style={{ color: 'red' }}>{setSizeValidationMessage}</span>}
                      onChange={(e: any) => onChangeFirstName(e)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="First Name" variant="outlined">
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                    <TextField id=""
                      required
                      type="text"
                      value={lastName}
                      error={lastNameError}
                      onChange={(e: any) => onChangeLastName(e)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Last Name" variant="outlined">
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-2 pr-2 mb-1 mt-2 row-space-between ">
                    <TextField id=""
                      required
                      type="text"
                      value={email}
                      error={emailError}
                      onChange={(e: any) => onChangeEmail(e)}
                      helperText={emailError && email == "" && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Email" variant="outlined">
                    </TextField>
                  </div>
                </div>
              </div>
            )
              : (dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from KAM" || dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from FSE" || dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Dealer confirmation pending") &&
              (<div className="border-top">
                <Button disabled={loading} variant="contained" onClick={() => {
                  generateCustomerId();
                }} color="primary" className='blueActionButton mb-2 ml-3 mt-3 rounded-pill'>Generate Customer Id</Button>
              </div>)
            }
          </div>
        </>
      )}

      <DeleteDialog
        open={openDeleteDialog}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'back') {
            props.setTabValue(3);
          }

          if (!data) handleopenClose(false);
        }} />
      <ConfirmSapIdDialog
        open={isOpenSapIdDialog}
        sapId={accessTabData && accessTabData.sapCustomerId}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            props.setTabValue(2);
            setIsOpenSapIdDialog(false);
          }
        }} />
      <SubmitDialogKAM
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveAccessTab(true);
          }
          if (!data) handleopenCloseSubmit(false);
        }} />
      <TradePointAccessDialog
        open={openTradePointAccessDialog}
        isTradePointAccess={isTradePointAccess}
        handleopenClose={(data: any,) => {
          if (data == 'Submit') {
            setIsTradepointAccess(isTradePointAccess ? false : true);
            setopenTradePointAccessDialog(false);
          } else {
            setopenTradePointAccessDialog(false)
          }
        }}
      />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="sm">
        <RequestCorrectionDialog setOpenDialog={handleClose}></RequestCorrectionDialog>
      </Dialog>
      {accessTabData && accessTabData.b2cUserId && (
        <ActionButtons
          isSaveDisabled={!props.dataUpdated}
          iscorrectionDisabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" ? true : false}
          isapproveDisabled={!(accessTabData && accessTabData.sapCustomerId) && dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending" ? true : false}
          // isapproveDisabled= {!(accessTabData && accessTabData.sapCustomerId)}
          isrejectDisabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from KAM" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending" ? true : false}
          saveCallback={() => {
            saveAccessTab(false)
          }}
          correctionCallback={() => {
            handleClickOpen();
          }}
          approveCallback={approveCallback}
          setOpenErrorSnackbar={setOpenErrorSnackbar}
          openErrorSnackbar={openErrorSnackbar}
          handleopenCloseSnackbar={handleopenCloseSnackbar}
          setSuccessMessage={setSuccessMessage}
          rejectCallback={() => { }}
          validateAction={validateAction}
          setDataUpdated={props.setDataUpdated}
        />
      )
        // :
        //     <ActionButtons
        //       isSaveDisabled={!props.dataUpdated}
        //       iscorrectionDisabled={false}
        //       setOpenErrorSnackbar={setOpenErrorSnackbar}
        //       openErrorSnackbar={openErrorSnackbar}
        //       isapproveDisabled={!(accessTabData && accessTabData.sapCustomerId)}
        //       isrejectDisabled={true}
        //       saveCallback={() => {
        //         saveAccessTab(true)
        //       }}
        //       correctionCallback={() => {
        //         handleClickOpen();
        //       }}
        //       approveCallback={approveCallback}
        //       handleopenCloseSnackbar={handleopenCloseSnackbar}
        //       setSuccessMessage={setSuccessMessage}
        //       rejectCallback={() => { }}
        //       validateAction={validateAction}
        //       setDataUpdated={props.setDataUpdated}
        //     />
      }
      < ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          setOpenSnackbar(false)
        }} message={successMessage} />
      {accessTabData && accessTabData.b2cUserId == "" && (
        <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
          <div className="col-12 display-flex p-0">
            <div className="col-8 p-0">
              <div className={`row m-0`}>
                {/* <div className="col-5 p-0">
                      <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                      {dealerDetails.data.status === 'Submitted' ? (
                        <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                      ) : (
                        <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                          <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                            Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                        </p>
                      )}
                    </div> */}
                <div className="col-4 mt-3 p-0">
                  <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                    onClick={() => saveAccessTab(false)}
                  >Save Form</Button>
                </div>
              </div>
            </div>
            <div className="col-4 onboard-footer float-right">
              <div className="p-3 mr-3">
                <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                  onClick={() =>
                    handleBackClick()
                  }
                >Back</Button>
              </div>
              <div className="p-3 mr-3">
                <Button variant="contained" color="primary"
                  disabled={accessTabData && accessTabData.sapCustomerId === '' && accessTabData.isAddedByFse ? true : false}
                  // disabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === "Dealer confirmation pending"}
                  onClick={() => {
                    // setDeleteMessage("Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost.");
                    // handleopenCloseSubmit(true);
                    validateTabs();
                  }}
                  className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Submit' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

