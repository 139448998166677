import React, { useEffect, useCallback } from "react";
import { TextField } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import ListAltIcon from '@material-ui/icons/ListAlt';
import IconButton from '@material-ui/core/IconButton';
import { readFile } from '../../helpers/commonmethod'
import GetAppIcon from '@material-ui/icons/GetApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone'
import { useDispatch } from "react-redux";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CancelIcon from "@material-ui/icons/CancelRounded";
import { importPartsExcelSheet } from "../../store/actions/oemOesProductTeam.action";
function ImportDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [uploadedFileName, setuploadedFileName] = React.useState('');
  const [uploadedFileNameError, setuploadedFileNameError] = React.useState(false);
  const [error, setError] = React.useState<any>('');
  const [file, setFile] = React.useState<any>("");
  const [size, setSize] = React.useState<any>(0);
  const [openSnackbar, setOpenSnackBar] = React.useState<any>(false)
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState("")
  const dispatch: any = useDispatch();
  const [isImportClicked, setIsImportClicked] = React.useState(false)
  const [isImportEnabled, setIsImportEnabled] = React.useState(false);

  const fileUploadChange = useCallback((data: any) => {
    if (data && data.length && data.length > 0 && data[0]) {
      if (data[0] && data[0].name.toLowerCase() && (data[0].name.toLowerCase().includes('.xlsx') || data[0].name.toLowerCase().includes('.xls'))) {
        readFile(data[0]);
        setError('');
        setuploadedFileNameError(false);
        setuploadedFileName(data[0] && data[0].name)
      } else {
        setError('Please upload excel file');
        setuploadedFileNameError(true);
        setuploadedFileName(data[0] && data[0].name)
      }
    }
    let uploader: any = document.getElementById('storesfileupload');
    if (uploader) uploader.value = "";
  }, [])

  const onDrop = useCallback((acceptedFiles: any) => {
    setSize(acceptedFiles[0].size)
    fileUploadChange(acceptedFiles)
    setFile(acceptedFiles && acceptedFiles[0])
    setuploadedFileName(acceptedFiles[0] && acceptedFiles[0].name)
  }, [fileUploadChange])

  // useEffect(() => {
  //   if (uploadedFileName && !uploadedFileNameError && error === '') {
  //     setOpen(false);
  //   }
  // }, [uploadedFileName, uploadedFileNameError, error])

  useEffect(() => {
    if (!open) {
      setuploadedFileName('');
      setuploadedFileNameError(false);
      setError('');
    }
  }, [open])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const convertToSize = (value: any) => {
    let Value = Number(value) / 1024
    return Math.round(Value) + "KB"
  }

  useEffect(() => {
    setIsImportEnabled(!!uploadedFileName && !uploadedFileNameError && !error);
  }, [uploadedFileName, uploadedFileNameError, error]);
  
  const handleClose = () => {
    setOpen(false);
    setIsImportEnabled(false);
  };

  const handleSubmit = async () => {
    setIsImportClicked(true);
    const formData: any = new FormData();
    const fileName = file && file.name.substring(file.name.lastIndexOf('/') + 1);
    const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
    let keyAttribute = `fieldname=${"importPartsExcelSheet"}&docExtension=${fileExtension}`
    const customHeaders = {
      "Accept": "application/json",
      "Lob-Id": "AUTO",
      "Channel-Id": "B2B",
      "Org-Id": "",
      "Accept-Language": "EN",
    };
    formData.append("entityName", "CUSTOMER");
    formData.append("keyAttribute", keyAttribute);
    formData.append("loginId", sessionStorage.getItem('webApplicationLoginId'));
    formData.append("file", file);
    formData.append("label", 'xlsfile');
    formData.append("selectedFileName", file.name);
    let data = await dispatch(importPartsExcelSheet(formData, customHeaders))
    if (data && data.message === "") {
      setOpenErrorSnackbar(true);
      setSuccessMessage("Please re-upload the excel file again to fetch updated values.");
      setOpenSnackBar(true);
    } else if (data && data.error) {
      setOpenErrorSnackbar(true);
      setSuccessMessage(data.error);
      setOpenSnackBar(true);
    } else {
      setSuccessMessage(data && data.message && data.message.message);
      setOpenSnackBar(true);
      setOpenErrorSnackbar(false);
      setError(null);
      setuploadedFileNameError(false);
      setOpen(false);
    }
  }

  const handleDownload = () => {
    const url = window.URL.createObjectURL(new Blob([file]));
    const link = document.createElement("a")
    link.href = url
    link.download = uploadedFileName
    document.body.appendChild(link)
    link.click()
  }

  return (
    <React.Fragment>
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          setOpenSnackBar(false)
        }} message={successMessage} />
      <span className="cursor-pointer mr-2" onClick={(e) => {
        setuploadedFileName('');
        setuploadedFileNameError(false);
        e.preventDefault();
        setOpen(true);
        setIsImportEnabled(false); // Disable Import when dialog is opened
        if (props.isOpened) props.isOpened()
      }}>
        <IconButton className="searchbarOptions" size="small">
          <ListAltIcon fontSize="inherit" />
          <span className="font-size-13 load-from-fav ml-1">Import Excel</span>
        </IconButton>
      </span>
  
      <Dialog disableBackdropClick={true}
        fullWidth={true}
        maxWidth="sm"
        open={open ? true : false}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <div className="d-flex justifyContentHorizontalSpacebetween ">
            <h4 className="page-title">Import Excel</h4>
            <a href="./sampleExcelForProductTeam.xlsx" download="sampleExcelForProductTeam.xlsx">
              <span className="cursor-pointer searchbarOptions">
                <IconButton className="mr-1" size="small">
                  <GetAppIcon fontSize="inherit" />
                  <span className="font-size-13 load-from-fav ml-1">Download Sample Excel</span>
                </IconButton>
              </span>
            </a>
          </div>
          <div className="d-flex mt-3 py-4 dropzoneImport" {...getRootProps()}>
            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center dashed-right-border">
              <div className="dragHereIcon justify-content-center">
                <CloudUploadIcon fontSize="large" />
              </div>
              <div className="dragHereText ml-2">Drag your excel sheet here</div>
              <input className="d-none" type="file" hidden id="productsfileupload" {...getInputProps()} />
            </div>
            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center">
              <Button
                className="font-size-11 addToEnquiryButton"
                size="small"
                onClick={(e) => {
                  fileUploadChange(e)
                }}
                variant="contained" color="primary">Browse Excel</Button>
            </div>
          </div>
          {uploadedFileName &&
            <div className="d-flex flex row pl-4">
              <div className="mt-2 row">
                <div className="file uploadFile" onClick={handleDownload}>{uploadedFileName} ({convertToSize(size)}/5MB)</div>
                <div className="pl-2" onClick={() => {
                  setuploadedFileName('');
                  setuploadedFileNameError(false);
                  setError('');
                  setSize("")
                  setFile("")
                  setIsImportEnabled(false); // Disable the button when file is removed
                }}>
                  <IconButton size="small">
                    <CancelIcon fontSize="inherit" />
                  </IconButton>
                </div>
              </div>
            </div>}
          {error && uploadedFileNameError && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - {error}</div>}
          <p className="ml-3 mt-2 note-text" style={{ color: 'red' }}>Note : You can only upload file that is up to 5 MB in size.</p>
        </DialogContent>
        <DialogActions className="mt-2 mb-4 justify-content-end">
          <Button
            autoFocus
            variant="contained"
            className={`submit-button font-size-11 mr-3 ${isImportEnabled ? "" : "lpo-button-disabled"}`}
            onClick={handleSubmit}
            disabled={!isImportEnabled} // Disable Import button based on the state
          >
            Import
          </Button>
          <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ImportDialog;
