import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import "./AdminDashboard.css";
import XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { withStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Divider from "@material-ui/core/Divider";
import MenuItem from "@material-ui/core/MenuItem";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { AntSwitch } from "../../common/components/Switch";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { getAllBrands, getAllLostSaleReasons, getAllSapCustomerIds, getLostSaleReport, resetReportData } from "../../store/actions/dealer.action";
import { Checkbox, FormControl } from "@material-ui/core";
import ListItemText from '@material-ui/core/ListItemText';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import IconButton from '@material-ui/core/IconButton';
import moment from "moment-timezone";
import { getNewBrand } from "../../common/components/util";
import { get } from 'lodash';

const CUSTOMTAB_ACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#1A9FE0",
    fontWeight: 800,
    fontFamily: "Nunito",

    paddingBottom: 4,
    paddingLeft: 0,
    height: "55px",
    alignItems: "left",
  },
})(Tab);

const CUSTOMTAB_INACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#999999",
    fontWeight: 800,
    paddingLeft: 0,
    fontFamily: "Nunito",

    paddingBottom: 4,
    height: "55px",
  },
})(Tab);

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const ReportComponent: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [report, setNewReport] = React.useState("Lost Sales Report");
  const [value, setValue] = React.useState(0);
  const [selectedReason, setReason] = React.useState<any>([]);
  const [selectedBrands, setSelectedBrands] = React.useState<any>([]);
  const [selectedDelaerIds, setselectedDelaerIds] = React.useState<any>([]);
  const [fromDate, setFromDate] = React.useState<Date | null>(null);
  const [toDate, setToDate] = React.useState<Date | null>(null);
  const { customerIds, lostSaleReason, brandsList, lostSaleReportData }: any = useSelector((state: any) => state.dealer);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [searchText, setsearchText] = React.useState<any>('');
  const [count, setCount] = React.useState<any>();
  const [allsapIds, setAllsapIds] = React.useState<any>([]);
  let webApplicationLoginId: any = sessionStorage.getItem('webApplicationLoginId');
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  let customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const handleClose = () => {
    setAnchorEl(null);
    setsearchText("");
  };
  let userTypeByProfileAPI = sessionStorage.getItem('userType-Profile-API');

  const selectAllCustomerIds = () => {
    if (customerIds && customerIds.data && customerIds.data.length > 0) {
      setAllsapIds(customerIds.data);
      setCount(customerIds.data.length)
      let data: any = [];
      customerIds.data.map((id: any) => data.push(id.sapCustomerId));
      setselectedDelaerIds(data);
    }
  }

  const onSearchCustomerId = (searchText: any) => {
    setsearchText(searchText);
    if (searchText.length >= 2) {
      const filteredIds = customerIds && customerIds.data && customerIds.data.length > 0 && customerIds.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()));
      setAllsapIds(filteredIds);
    } else {
      setAllsapIds(customerIds && customerIds.data);
    }
  };

  const handleSelectAllCustomerClick = (event: any) => {
    if (event.target.checked) {
      selectAllCustomerIds();
    } else if (event.target.checked === false) {
      setselectedDelaerIds([]);
    }
  };

  const handleClickSapId = (event: any, sapId: any) => {
    const selectedIndex: any = selectedDelaerIds.indexOf(sapId);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedDelaerIds, sapId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedDelaerIds.slice(1));
    } else if (selectedIndex === selectedDelaerIds.length - 1) {
      newSelected = newSelected.concat(selectedDelaerIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedDelaerIds.slice(0, selectedIndex),
        selectedDelaerIds.slice(selectedIndex + 1),
      );
    }
    setselectedDelaerIds(newSelected);
  };

  React.useEffect(() => {
    setAllsapIds(customerIds && customerIds.data)
    selectAllCustomerIds();
  }, [customerIds]); // eslint-disable-line

  const menuItemStyle = {
    width: "550px"
  };

  useEffect(() => {
    dispatch(getAllBrands('all'));
    if (lostSaleReason && lostSaleReason.data && lostSaleReason.data.length > 0) {
    } else {
      dispatch(getAllLostSaleReasons());
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    if (webApplicationLoginId) {
      if (customerIds && customerIds.data && customerIds.data.length > 0) {
      } else {
        dispatch(getAllSapCustomerIds());
      }
    }
  }, [webApplicationLoginId]); // eslint-disable-line

  const resetData = () => {
    dispatch(resetReportData());
    selectAllCustomerIds();
    selectAllLossSaleReason();
    selectAllBrands();
    setToDate(null);
    setFromDate(null);
  }

  const selectAllLossSaleReason = () => {
    if (lostSaleReason && lostSaleReason.data && lostSaleReason.data.length > 0) {
      let data: any = [];
      lostSaleReason.data.map((reason: any) => data.push(reason.status));
      // if (reason.statusId !== 'LS009' && reason.statusId !== 'LS010')

      //   return;
      // });
      setReason(data);
    }
  }

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop' && customerTierType.toLowerCase() !== 'tpcdealer' ) || (!customerTypeAccess.includes("workshop") && !customerTypeAccess.includes("tpcdealer"))) ? data.push('ALAC') : data.push(brand.displayName));
      setSelectedBrands(data);
    }
  }

  const exportData = () => {
    if (lostSaleReportData && lostSaleReportData.data && Array.isArray(lostSaleReportData.data) && lostSaleReportData.data.length > 0) {
      let filename = "lostSaleReport.xlsx";
      let data: any = [];

      lostSaleReportData.data.map((item: any) => {
        data.push({
          'Enquiry Date': moment(item.enquiryDate).format('DD/MM/YYYY'),
          Time: moment(item.enquiryDate).format('HH:mm:ss'),
          User: item.b2bUserName,
          Brand: getNewBrand(item.brand) === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop' && customerTierType.toLowerCase() !== 'tpcdealer' ) || (!customerTypeAccess.includes("workshop") && !customerTypeAccess.includes("tpcdealer"))) ? 'ALAC' : getNewBrand(item.brand),
          Region: item.region,
          'Customer ID': item.sapCustomerId,
          'Customer Name': item.customerName,
          'LPO Number / Ref Number': item.LPONumber && item.LPONumber.trim() ? item.LPONumber : item.childRefNumber,
          'Enquiry Number': item.b2bEnquiryId,
          'Part Number': item.partNumber,
          'Availability': Number(item.availability),
          'NSP': Number(parseFloat(item.nsp).toFixed(2)),
          'BOP': Number(parseFloat(item.aop).toFixed(2)),
          'Enquired Qty': Number(item.enquiredQty),
          'Now (RDC)': Number(item.nowAvailability),
          '24 hrs (PDC)': Number(item.hrs24Availability),
          'BO': Number(item.airAvailability),
          'Placed Qty': Number(item.placedQty),
          'Air Order Enquired Qty': Number(item.airOrderPlacedQty),
          'Loss Qty': Number(item.lossQty),
          'Reason Of Loss': item.reasonToLoss,
        });
        return undefined;
      });
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
      resetData();
    }
  };

  useEffect(() => {
    if (lostSaleReportData && lostSaleReportData.data) {
      exportData();
    }
  }, [lostSaleReportData]); // eslint-disable-line

  useEffect(() => {
    selectAllLossSaleReason();
  }, [lostSaleReason]); // eslint-disable-line

  useEffect(() => {
    selectAllBrands();
  }, [brandsList]); // eslint-disable-line

  const handleReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewReport(event.target.value);
  };
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  const handleChangeReason = (event: any) => {
    setReason(event.target.value);
  };

  const handleBrandChange = (event: any) => {
    setSelectedBrands(event.target.value);
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  const downloadReport = () => {
    let reportData: any = {};
    let newReason: any = [];
    let newBrands: any = [];
    if (selectedReason && selectedReason.includes('All')) {
      newReason = [];
    }
    else if (lostSaleReason && lostSaleReason.data && lostSaleReason.data.length > 0) {
      lostSaleReason.data.map((reason: any) => {
        selectedReason.map((selected: any) => {
          if (reason.status === selected) {
            newReason.push(reason.statusId)
          }
          return undefined;
        })
        return undefined;
      })
    }

    if (selectedBrands && selectedBrands.includes('All')) {
      newBrands = [];
    }
    else if (brandsList && brandsList.data && brandsList.data.length > 0) {
      brandsList.data.map((brand: any) => {
        selectedBrands.map((selected: any) => {
          if (brand.displayName === selected) {
            newBrands.push(brand.name)
          }
          return undefined;
        })
        return undefined;
      })
    }

    reportData.sapCustomerIds = selectedDelaerIds;
    if (newBrands && newBrands.length > 0) reportData.brands = newBrands;
    reportData.fromDate = moment(fromDate).utc().format();
    reportData.toDate = moment(toDate).utc().format();;
    if (newReason && newReason.length > 0) reportData.lossReason = newReason;
    reportData.reportType = "lossSaleReport";
    dispatch(getLostSaleReport(reportData));
  }

  const handleSelectAllClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setSelectedBrands([]);
    }
  };

  const handleSelectAllReasonClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllLossSaleReason();
    } else if (event.target.checked === false && value === 'All') {
      setReason([]);
    } else {
      let data: any = [...selectedReason];
      if (event.target.checked) {
        data.push(value);
        setReason(data);
      } else {
        data = data.filter((v: any) => v !== value);
        setReason(data);
      }
    }
  };

  const disableDownloadButton = !fromDate || !toDate || selectedDelaerIds.length === 0 || selectedReason.length === 0 || fromDate > toDate;
  const showInvalidDateValidation = (fromDate && toDate) && (fromDate > toDate);

  return (
    <Fragment>
      {userTypeByProfileAPI === "FSE" ?
      <h5 className="page-sub-title mb-2">Reports</h5> : ""}
      <div className="cardCommon specialOffers height-450 lostSaleReport">
        <div className="col-12">
          <div>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              variant="fullWidth"
              TabIndicatorProps={{
                style: {
                  height: 3,
                  padding: 0,
                  margin: 0,
                  backgroundColor: "#1A9FE0",
                  borderRadius: "2px",
                },
              }}
            >
              <CUSTOMTAB_ACTIVE label="All Reports" {...a11yProps(0)} />
              <CUSTOMTAB_INACTIVE label="My Reports" {...a11yProps(0)} />
            </Tabs>
          </div>
          <Divider light className="w-100" />
          <div className="col-12 smallText mt-3 mb-2 commonRoundedInputs">
            <TextField
              disabled
              id="reportType"
              select
              label="Select Report Type"
              variant="outlined"
              className="dropdown"
              size="small"
              InputLabelProps={{
                style: { color: '#000000' },
              }}
              value="Lost Sales Report"
              onChange={handleReportChange}
            >
              <MenuItem key={1} value="Lost Sales Report">
                Lost Sales Report
              </MenuItem>
              <MenuItem key={2} value="VAT Report">
                VAT Report
              </MenuItem>
              <MenuItem key={3} value="Other Sales Report">
                Other Sales Report
              </MenuItem>
            </TextField>
          </div>
          {report === "Lost Sales Report" ? (
            <div className=" my-1 w-100 h-75">
              <div className="mx-0 row-space-between">
                {" "}
                <div className="col-12 smallText mt-2 commonRoundedInputs w-100">
                  {/* <FormControl required className="commonRoundedInputs w-100" variant="outlined">
                    <InputLabel>Dealer ID</InputLabel>
                    <Select
                      multiple
                      variant="outlined"
                      value={selectedDelaerIds}
                      className="dropdown"
                      onChange={handleChangeDealerId}
                      input={<OutlinedInput label="Dealer ID" />}
                      renderValue={(selected: any) => selected.join(', ')}
                    >
                      {customerIds && customerIds.data && customerIds.data.length > 0 && customerIds.data.map((sapId: any, i: any) => (
                        <MenuItem key={i} value={sapId}>
                          <Checkbox checked={selectedDelaerIds.indexOf(sapId) > -1} />
                          <ListItemText primary={sapId} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl> */}
                  <TextField
                    id="dealerId"
                    variant="outlined"
                    required
                    size="small"
                    onClick={handleClick}
                    value={selectedDelaerIds}
                    label="Customer ID"
                    InputLabelProps={{
                      style: { color: '#000000' },
                    }}
                    aria-controls="customized-menu"
                    className="w-100"
                    aria-haspopup="true"
                    placeholder="Customer Id (Multiselect)"
                  >
                  </TextField>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    className="dealer-custom-dropdown "
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <div>
                      <span className="ml-2 mt-2 font-weight-bold">Select Customer(s)</span>
                      <div className="float-right mt-n2">
                        <Checkbox
                          checked={selectedDelaerIds.length === count}
                          onChange={(e: any) => handleSelectAllCustomerClick(e)}
                        />
                        <span className="text-info mr-3">Select All</span>
                      </div>
                      <div className="mt-2">
                        <div className="searchDealer">
                          <IconButton
                            edge="start"
                            className="ml-2"
                            disabled
                          >
                            <SearchIcon fontSize="inherit" />
                          </IconButton>
                          <TextField value={searchText} onChange={(e) => { onSearchCustomerId(e.target.value) }}
                            size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                        </div>
                      </div>
                      {allsapIds && allsapIds.length > 0 && allsapIds.map((sapId: any, i: any) => (
                        <Grid key={i} container style={menuItemStyle} className="border-bottom">
                          <MenuItem key={i} value={sapId.sapCustomerId}>
                            <Checkbox
                              checked={selectedDelaerIds && selectedDelaerIds.includes(sapId.sapCustomerId) ? true : false}
                              inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${i}` }}
                              onClick={(event) => handleClickSapId(event, sapId.sapCustomerId)}
                            />
                            <ListItemText primary={sapId.sapCustomerId} /><p className="px-5"> {" "} {" "} {sapId.customerName}</p>
                          </MenuItem>
                        </Grid>
                      ))}
                      {allsapIds && allsapIds.length === 0 && (
                        <Grid container style={menuItemStyle}>
                          <div className="d-flex justify-content-center text-center mx-auto">No Customers found</div>
                        </Grid>
                      )}
                    </div>
                  </StyledMenu>
                </div>
              </div>
              <div className="col-12 smallText mt-3 commonRoundedInputs">
                <FormControl className="commonRoundedInputs w-100" variant="outlined">
                  <InputLabel id="companyBrandsLabel" style={{ color: '#000000' }}>Brand</InputLabel>
                  <Select
                    multiple
                    variant="outlined"
                    value={selectedBrands}
                    onChange={handleBrandChange}
                    input={<OutlinedInput label="Brand" />}
                    renderValue={(selected: any) => selected.join(', ')}
                  >
                    {/* brandsList.data.map((brand: any) => brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? data.push('ALAC') : data.push(brand.displayName)); */}
                    {brandsList && brandsList.data && brandsList.data.length > 0 && brandsList.data.map((brand: any, i: any) => (
                      <MenuItem key={i} value={brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? 'ALAC' : brand.displayName}>
                        <Checkbox onChange={(e: any) => handleSelectAllClick(e, brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? 'ALAC' : brand.displayName)}
                          checked={selectedBrands && selectedBrands.includes(brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? 'ALAC' : brand.displayName) ? true : false}
                        />
                        <ListItemText primary={brand.displayName === 'Tradepoint' && ((customerTierType && customerTierType.toLowerCase() !== 'workshop') || !customerTypeAccess.includes("workshop")) ? 'ALAC' : brand.displayName} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="py-1 row-space-between mb-1 mt-2">
                {" "}
                <div className="col-6">
                  <KeyboardDatePicker
                    required autoOk size="small" variant="inline" inputVariant="outlined"
                    disableFuture
                    format="dd/MM/yyyy" margin="normal" label="From" value={fromDate}
                    onChange={(date: Date | null) => {
                      setFromDate(date);
                    }}
                    views={["year", "month", "date"]}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                    minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())} // Disable dates before one year ago
                  />
                </div>
                <div className="col-6">
                  <KeyboardDatePicker
                    required autoOk size="small" variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="To" value={toDate}
                    onChange={(date: Date | null) => {
                      setToDate(date);
                    }}
                    views={["year", "month", "date"]}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                    minDate={new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate())} // Disable dates before one year ago
                  />
                </div>
              </div>
              <div className="col-12 smallText mb-3 commonRoundedInputs">
                <FormControl required className="commonRoundedInputs w-100" variant="outlined">
                  <InputLabel id="companyBrandsLabel" style={{ color: '#000000' }}>Loss Reason</InputLabel>
                  <Select
                    multiple
                    variant="outlined"
                    value={selectedReason}
                    className="dropdown"
                    onChange={handleChangeReason}
                    input={<OutlinedInput label="Loss Reason" />}
                    renderValue={(selected: any) => selected.join(', ')}
                  >
                    {lostSaleReason && lostSaleReason.data && lostSaleReason.data.length > 0 && lostSaleReason.data.map((reason: any, i: any) => (
                      <>
                        {/* {(reason.statusId !== 'LS009' && reason.statusId !== 'LS010') && ( */}
                        <MenuItem key={i} value={reason.status}>
                          <Checkbox onChange={(e: any) => handleSelectAllReasonClick(e, reason.status)}
                            checked={selectedReason && selectedReason.includes(reason.status) ? true : false}
                          />
                          <ListItemText primary={reason.status} />
                        </MenuItem>
                        {/* )} */}
                      </>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="mx-2 my-2 py-1 row-space-between">
                <div className="col-6 ml-0 mt-2 font-11 font-weight-bold row-space-between">
                  Add to My Reports
                  <AntSwitch />
                </div>
                <div className="col-6 mr-0 pr-0 mt-4 mb-3">
                  <Button
                    fullWidth
                    size="small"
                    color="primary"
                    disabled={sessionStorage.getItem('userType') === 'FSE' ? true : disableDownloadButton}
                    variant="contained"
                    className="rounded-pill font-size-11 py-2 px-0"
                    onClick={() => downloadReport()}
                  >
                    Download Report
                  </Button>
                </div>
              </div>
              {showInvalidDateValidation &&
                <div className="validation-error">From Date cannot exceed To Date</div>
              }
            </div>
          ) : (
            <div
              style={{
                height: "302px",
              }}
            ></div>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ReportComponent;
