/* eslint-disable */
import React, { Dispatch, useEffect, useState } from 'react';
import { getArchivedData, getArchivedDataDetails } from '../../store/actions/orderEnquiry.action';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ReturnRequestSnackbar from "../../common/components/returnRequestSnackbar";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@material-ui/core/Box';
import { useHistory } from "react-router-dom";
import DatePicker from '../../common/components/dateRangePicker';
import Checkbox from "@material-ui/core/Checkbox";
import { getOrdersList, getOrderId, getInvoice, resetInvoice, setPageNumber, getOrderStatus, getOrderHistoryDetails } from "../../store/actions/orderHistory.actions";
import moment from "moment-timezone";
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { isEmpty, isString } from 'lodash';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import { getDeliveryList } from "../../store/actions/delivery.actions";
import { getNewBrand } from "../../common/components/util";
import { useCallback } from "react";
import { ALL_STATUS, viewDeliveryOrderStatuses, viewReturnRequestStatuses, viewViewInvoiceStatuses } from "../../constants/orderHistoryConstants";
import { getSellingPrice } from "../../helpers/commonmethod";
import MakePaymentDialog from '../CreateEnquiry/MakePaymentDialog';
import Dialog from '@material-ui/core/Dialog';
import HistoryMakePaymentDialog from "../CreateEnquiry/OrderHistoryMakePaymentDialog";
import OrderSummaryDialog from "../CreateEnquiry/orderSummaryDialog";
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import _, { get } from "lodash";
// import { createUpdateEnquiryService } from '../../services/createUpdateEnquiry.service ';
import { getOrderSummary } from '../../store/actions/orderSummary.actions';
import { ViewDelivery } from "../../store/actions/delivery.actions";
import { selectedViewDelivery } from "../../store/actions/delivery.actions";
import { getOrderSummaryDetails } from '../../store/actions/orderSummary.actions';
import { getViewRequestStatusAPI } from "../../store/actions/requestSpecialPrice.actions";
import ExpiredDialog24Hrs from "../CreateEnquiry/ExpiredDialog24Hrs";
import { isVewingFromOrderHistory } from "../../store/actions/orderHistory.actions"
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help"
import UploadLpo from "../Quotation/UploadLpo";
import ViewLPO from "../Quotation/ViewLpo";
import { quotationDetailsViewOrder } from "../../store/actions/quotation.action";
import OrderHistoryViewQuotation from "../Quotation/OrderHistoryViewQuotation";
import { downloadQuotation, viewLPO } from "../../store/actions/quotation.action";
import OrderHistoryViewLPO from "../Quotation/OrderHistoryViewLPODialog";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import { withStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import { getFSECustomerList, getCustomerDetailsByCustomerID } from "../../store/actions/dealer.action";
import { getFSEList } from '../../store/actions/orderEnquiry.action';

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  rowHighlight: {
    background: 'rgba(67, 95, 107, 0.15)',
    color: '#666666',
    fontWeight: 700
  },
  orangeRowHighlight: {
    background: 'rgba(240, 223, 0, 0.12)',
    color: '#666666',
    fontWeight: 700
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,

  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 140,
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  actionRequest: {
    color: "white",
    background: "#1A9FE0",
    border: "2px solid #1A9FE0",
    borderRadius: "8px",
    width: "1rem",
    height: "1rem",
    "&:hover": {
      background: "#1A9FE0",
      color: "white !important",
      borderRadius: "14px",
      width: "1rem",
      height: "1rem",

    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const DataArchival = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [data, setData] = useState([
    { orderNo: '123', price: '$100' },
    { orderNo: '456', price: '$150' },
    // Add more data as needed
  ]);

  const orgIdBrandsMapping: any = {
    "2001": "Toyota",
    "2002": "Honda",
    "2003": "Famco",
    "2380": "TE",
    "2195": "OES"
  }
  const history = useHistory();
  const [isReturned, setIsReturned] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const rowsPerPage = 10;
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [openReturnRequest, setOpenReturnRequest] = React.useState(false);
  const [openReturnRequestSnackbar, setOpenReturnRequestSnackbar] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const { orderList, invoice, prevPage, orderStatus }: any = useSelector((state: any) => state.orderHistory);
  const [selectedOrder, setselectedOrder] = React.useState<any>(null);
  const [actualOrder, setActualOrder] = React.useState<any>(null);
  const [searchText, setsearchText] = useState<any>('');
  const [isMyOrders, setisMyOrders] = useState<boolean>(true);
  const today = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate((thirtyDaysAgo.getDate() - 365) - 30);
  today.setDate(today.getDate() - 365);

  // Convert Date object to moment object
  const momentDate = moment(thirtyDaysAgo);
  // Format the date with day, month, year, hours, minutes, seconds, and timezone
  let formattedDate: any = momentDate.format('ddd MMM DD YYYY HH:mm:ss');
  formattedDate = moment(new Date(formattedDate))

  // Convert Date object to moment object
  const momentTodayDate = moment(today);
  // Format the date with day, month, year, hours, minutes, seconds, and timezone
  let formattedTodayDate: any = momentTodayDate.format('ddd MMM DD YYYY HH:mm:ss');
  formattedTodayDate = moment(new Date(formattedTodayDate))

  const [startDate, setstartDate] = useState<any>(formattedDate);
  const [endDate, setendDate] = useState<any>(formattedTodayDate);

  const [searchByInvoice, setSearchByInvoice] = useState("")
  const [status, setstatus] = useState<any>("");
  const [open, setOpen] = React.useState(-1);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [customerAnchorEl, setCustomerAnchorEl] = React.useState<null | HTMLElement>(null);
  const [goodsStatus, setGoodsStatus] = React.useState('');
  const [status24, set24hrsStatus] = React.useState('');
  const [fileExtention, setFileExtension] = React.useState('pdf');
  const [viewQuotationUrl, setViewQuotationUrl] = React.useState("")
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const { deliveryList }: any = useSelector((state: any) => state.delivery);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [Order, setOrder] = React.useState<any>("");
  const b2cUserId = get(profileDetails, "data.data.basicInfo.b2cUserId", "");
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [payment, setPayment] = React.useState(false)
  const showViewInvoice = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOwNLOAD_INVOICE_EXCEL) && !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_BILLING_DOCS);
  const showViewDelivery = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_DELIVERY);
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerTierType') ? sessionStorage.getItem('customerTierType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [openViewQuotation, setOpenViewQuotation] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [isLPOUpdate, isUpdateLPO] = React.useState(false);
  const [openViewLPO, setOpenViewLPO] = React.useState(false);
  const [quotationNumber, setQuotationNumber] = React.useState("")
  const [enquiryNumber, setEnquiryNumber] = React.useState("");
  const [brandId, setBrandId] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const [lpoAttachments, setLpoAttachments] = React.useState<any>([])
  const { getCustomerDetailsBySapId, getFSECustomerListData, getFSEEnquiryStatusData }: any = useSelector((state: any) => state.dealer);
  const [searchTextByID, setSearchTextByID] = React.useState<any>('');
  const [customerId, setCustomerId] = React.useState<any>([]);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>('');
  const [selectedCustomerName, setSelectedCustomerName] = React.useState<any>('');
  const [count, setCount] = React.useState<any>();
  const [anchorElForCustomer, setAnchorElForCustomer] = React.useState<null | HTMLElement>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [isQueuedEnquirieChecked, setIsQueuedEnquirieChecked] = useState(false)
  const [fseName, setFseName] = useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const { archivedData } = useSelector((state: any) => state.orderEnquiry);
  const [fseWebApplicationLoginId, setFseWebApplicationLoginId] = React.useState<any>("");
  const [filterByStatus, setFilterByStatus] = React.useState<any>('All');

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
    let data: any = {
      "fromDate": "2024-11-28",
      "toDate": "2024-11-28",
      "orderNumber": "121",
      "invoiceNumber": "123"
    }
    dispatch(getArchivedData(data, page));
  };

  const filteredData = data.filter((item) =>
    item.orderNo.includes(searchTerm)
  );

  const loadArchievedDataList = async () => {
    setLoading(true);
    let data: any =
    {
      "customerId": selectedCustomerId ? selectedCustomerId : '0205286833',
      "fromDate": startDate,
      "toDate": endDate,
      "orderNumber": searchText,
      "invoiceNumber": searchText,
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      },
      "userType": sessionStorage.getItem('userType')
    }
    let response: any = await dispatch(getArchivedData(data, page));
    if (response && response.message && response.message.status === 'S') {
      setLoading(false);
    } else {
      setSuccessMessage(response?.data?.message?.messageText);
      setOpenErrorSnackbar(true);
      setOpenSnackbar(true);
      setLoading(false);
    }
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPageNumber(null));
    loadArchievedDataList();
    setPage(newPage);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTextByID("");
  };

  useEffect(() => {
    if (getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
    } else {
      dispatch(getFSECustomerList());
    }
  }, []);

  React.useEffect(() => {
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
  }, [getFSECustomerListData]);

  const handleopenCloseSnackbar = (open?: any) => {
    if (open) {
      setAnchorEl(null);
      // loadOrderList();
    }
    setOpenSnackbar(open ? true : false);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onSearchCustomerId = (searchText: any) => {
    setSearchTextByID(searchText);
    // getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => (
    //   console.log("__________________", item)
    // ))
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    if (searchText.length > 0) {
      const filteredIds = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()) || sapId.name.toLowerCase().includes(searchText.toLowerCase()));
      setCustomerId(filteredIds);
    } else {
      setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    }
  };

  const handleClickSapId = (event: any, name: any, sapId: any) => {
    setSelectedCustomerId(sapId);
    setSelectedCustomerName(name);
  };

  return (
    <div>
      {loading && <FullscreenLoader />}
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Data Archival
        </h2>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="cardCommon p-3">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mb-2">Search Archived Data</h5>
            </div>
            <div>
              {sessionStorage.getItem('userType') === 'FSE' && (
                <div className="col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-0">
                  <TextField
                    id="customerId"
                    variant="outlined"
                    size="small"
                    onClick={handleClick}
                    value={selectedCustomerName}
                    label="Filter By Customer"
                    aria-controls="customized-menu"
                    className="commonRoundedInputs w-100"
                    aria-haspopup="true"
                    placeholder="Filter By Customer"
                  >
                  </TextField>
                  <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    className="dealer-custom-dropdown"
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <div>
                      <div className="mt-2">
                        <div className="searchDealer">
                          <IconButton
                            edge="start"
                            className="ml-2"
                            disabled
                          >
                            <SearchIcon fontSize="inherit" />
                          </IconButton>
                          <TextField value={searchTextByID} onChange={(e) => { onSearchCustomerId(e.target.value) }}
                            size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                        </div>
                      </div>
                      {customerId && customerId.length > 0 && customerId.map((sapId: any, i: any) => (
                        <Grid key={i} container className="border-bottom">
                          <MenuItem key={i} value={sapId.sapCustomerId}>
                            <ListItemText primary={sapId.sapCustomerId} onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }} /><p className="px-5" onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }}> {" "} {" "} {sapId.name}</p>
                          </MenuItem>
                        </Grid>
                      ))}
                      {customerId && customerId.length === 0 && (
                        <Grid container >
                          <div className="d-flex justify-content-center text-center mx-auto">No Customers found.</div>
                        </Grid>
                      )}
                    </div>
                  </StyledMenu>
                </div>
              )}
              <TextField
                required
                value={searchText}
                onChange={(e) => {
                  setsearchText(e.target.value)
                }}
                size="small" className={`${sessionStorage.getItem('userType') === 'FSE' ? "col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" : "col-3 col-sm-5 col-md-3 col-lg-3 common-input-font-size commonRoundedInputs searchField"}`} label="Search by Order No" variant="outlined" />
              <div className="col-4 col-sm-6 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                <DatePicker
                  startDate={startDate}
                  endDate={endDate}
                  setstartDate={setstartDate}
                  setendDate={setendDate}
                  validationMode="365days"
                  isEnableOnlyThirtyDays={true}
                  onChange={(start: any, end: any) => {
                    setstartDate(start);
                    setendDate(end);
                  }}
                  required
                />
              </div>
              <TextField
                // value={}
                onChange={(e) => {
                  setsearchText(e.target.value)
                }}
                size="small" className={`${sessionStorage.getItem('userType') === 'FSE' ? "mt-3 col-4 col-sm-6 col-md-4 col-lg-4 pr-3 common-input-font-size commonRoundedInputs searchField" : "col-3 col-sm-5 col-md-3 col-lg-3 pr-3 common-input-font-size commonRoundedInputs searchField"}`} label="Search by Invoice No" variant="outlined" />
              <Button
                className={`${sessionStorage.getItem('userType') === 'FSE' ? "mt-3 col-1 col-sm-2 col-md-1 col-lg-1 font-size-11 pl-0 addOrderEnqButton ml-2 text-right float-right mr-2" : "col-1 col-sm-2 col-md-1 col-lg-1 font-size-11 pl-0 addOrderEnqButton ml-2"}`}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => {
                  if ((sessionStorage.getItem('userType') === 'FSE' && selectedCustomerId !== '' && startDate !== null && endDate !== null && searchText !== '') || (startDate !== null && endDate !== null && searchText !== '')) {
                    showErrorMessage(null);
                    loadArchievedDataList()
                  } else {
                    if (sessionStorage.getItem('userType') === 'FSE' && selectedCustomerId === '') {
                      showErrorMessage('Please select atleast one customer')
                    }
                    else if (startDate === null && endDate === null && searchText === '') {
                      showErrorMessage('Please enter date range and order number or invoice number')
                    } else if
                      (searchText == '') {
                      showErrorMessage('Please enter order number or invoice number')
                    } else {
                      showErrorMessage('Please enter date range')
                    }
                  }
                }}
              >Search</Button>
            </div>
            {errorMessage && <div className='text-red text-right float-right'>{errorMessage} </div>}
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center">
            <h5 className="page-sub-title">List of Orders</h5>
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table aria-label="table" >
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHead}>Order No.</TableCell>
                    <TableCell className={classes.tableHead}>Reference No.</TableCell>
                    <TableCell className={classes.tableHead}>Brand</TableCell>
                    <TableCell className={classes.tableHead}>
                      <h6 className="totalHead">Total Amount</h6>
                      <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Created By</TableCell>
                    <TableCell className={classes.tableHead}>Created On</TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Payment Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {archivedData && archivedData.data && archivedData.data.data && archivedData.data.data.length > 0 && archivedData.data.data.map((order: any, index: any) => {
                    const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
                    return (
                      <React.Fragment>
                        <TableRow key={order.masterId} hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer" >
                          <TableCell className={classes.bodyContent}>{order.orderNumber}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.enquiryReferenceNumber}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>
                            {order.orgId}
                            {/* {order.brands && Array.isArray(order.brands) && customerTierType && customerTierType.toLowerCase() !== 'workshop' && customerTierType.toLowerCase() !== 'tpcdealer' ? order.brands.map((brand: any) => brand === "Tradepoint" ? "ALAC" : brand).join(', ') : customerTierType.toLowerCase() === 'tpcdealer' ? "Tradepoint" : order.brands.toString()} */}

                            {/* {order.brands && Array.isArray(order.brands) && order.brands.toString()} */}
                          </TableCell>
                          <TableCell className={classes.bodyContent}>{parseFloat(getSellingPrice(!!order.totalCost && order.totalCost, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
                          {sessionStorage.getItem('userType') === 'FSE' ?
                            <TableCell className="mw-150p">
                              <div className="dealer-table-content">{order.userName ? order.userName : "-"}</div>
                              <div className="dealer-table-subcontent">{order.userRole ? order.userRole : "-"}</div>
                            </TableCell>
                            : <TableCell className={classes.bodyContent}>{order.userName}</TableCell>
                          }
                          <TableCell className={classes.bodyContent}>{moment(order.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.sapStatus}</TableCell>
                          <TableCell className={classes.bodyContent}>{order.paymentStatus}</TableCell>
                          <TableCell>
                            {showViewEnquiry &&
                              <Tooltip title="View Order" placement="top" onClick={async () => {
                                // dispatch(getOrderId(selectedOrder.isAirOrder ? selectedOrder.backOrderNumber : selectedOrder.orderNumber));
                                // sessionStorage.setItem('isBackOrder', selectedOrder.backOrderNumber && selectedOrder.isAirOrder ? 'true' : '');
                                // sessionStorage.setItem('orderStoredBrand', selectedOrder.make.toString())
                                // sessionStorage.setItem('orderBackNumber', selectedOrder.backOrderNumber)
                                // history.push(`/orderdetails`);
                                // sessionStorage.setItem('selectedCustomerID', selectedOrder && selectedOrder.customerId === undefined ? actualOrder && actualOrder.customerId : selectedOrder && selectedOrder.customerId)
                              }}>
                                <IconButton className={classes.actionButton} size="small" onClick={async () => {
                                  dispatch(getOrderId(order.isAirOrder ? order.backOrderNumber : order.orderNumber));
                                  sessionStorage.setItem('isBackOrder', order.backOrderNumber && order.isAirOrder ? 'true' : '');
                                  sessionStorage.setItem('orderStoredBrand', order.orgId.toString())
                                  sessionStorage.setItem('orderBackNumber', order.backOrderNumber);
                                  if (order.isAirOrder) {
                                    sessionStorage.setItem('backorder', JSON.stringify(order.backorder));
                                  } else {
                                    sessionStorage.setItem('products', JSON.stringify(order.products));
                                  }
                                  let payload: any = {
                                    "orderNumber": order.orderNumber,
                                    "enquiryNumber": order.enquiryNumber,
                                    "orgId": order.orgId,
                                    "sessionDetails": {
                                      "clientIPAddress": sessionStorage.getItem('clientIp'),
                                      "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId')
                                    },
                                    "customerId": order.customerId,
                                    "userType": sessionStorage.getItem('userType')
                                  }
                                  // await dispatch(getOrderHistoryDetails(order.orderNumber, order.backorder));

                                  // await dispatch(getArchivedDataDetails(payload));
                                  // history.push(`/archivedorderdetails`);
                                  history.push(`/orderdetails`);
                                  sessionStorage.setItem('selectedCustomerID', order && order.customerId ? order.customerId : '')
                                }}>
                                  <AssignmentIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                      </React.Fragment >
                    );
                  })}
                </TableBody>
              </Table>
              {archivedData && archivedData.data && archivedData.data.data && archivedData.data.data.length === 0 &&
                <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Data Found.</div>
              }
            </TableContainer>
            {/* <div className="col-12 d-flex justify-content-end">
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[10]}
                component="div"
                count={orderList && orderList.data && orderList.totalCount ? orderList.totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataArchival;
