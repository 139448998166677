import {
    Button, Table, TableBody, TableCell, TableContainer, TableHead,
    TablePagination, TableRow, TextField, InputAdornment, OutlinedInput, InputLabel, IconButton,
    Tooltip
} from "@material-ui/core";
import { Dispatch, useEffect, useState } from "react";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from '@material-ui/core/styles';
import "./groupId.css"
import { useDispatch, useSelector } from "react-redux";
import { productTeamService } from "../../services/oemOesproductTeamSearch.service";
import SearchIcon from '@material-ui/icons/Search';
import { OES_OEM_MAP_PRODUCT_SEARCH } from "../../store/actions/actionsTypes";
import DeleteDialogProductTeamPortal from "../../common/components/DeleteDialogProductTeamPortal";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Autocomplete } from "@material-ui/lab";
import { oesOemMapActions, resetGroupIDState } from "../../store/actions/oemOesProductTeam.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import Snackbar from "../../common/components/Snackbar";
import CancelDialog from "../../common/components/cancelDialog";
import { API_STATES } from "../../helpers/enums";
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
interface GroupIdProps {
    goBack: () => void; // goBack is a function with no parameters and no return value
}
const useStyles = makeStyles((theme) => ({
    collapseBg: {
        background: 'rgba(66, 94, 108, 0.07)'
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        minWidth: 84
    },
    bodyContentSmall: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 40,
    },
    tableHeadSmall: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 40,
    },
    collapseIcon: {
        color: '#000000',
        fontWeight: 500,
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        minWidth: 70
    },
    actiontTbleHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        paddingLeft: "7rem",
        minWidth: 70
    },
    subTableHead: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    actionButton: {
        color: '#1A9FE0',
    },
    subTableBodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10
    },
    createOrderEnqButton: {
        backgroundColor: '#1A9FE0',
        width: 190,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        color: '#FFFFFF',
        borderRadius: 1000,
        height: 35,
        textAlign: 'center',
    },
    icon: {
        float: "left",
        margin: "0 10px 7px 0",
        height: "100%",
        fill: "white",
    },
    yes: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#1A9FE0',
        textTransform: 'capitalize'
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    deleteText: {
        color: '#133F8A',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    },
    dialogPaper: {
        maxWidth:'600px !important',
        width:'600px !important'
    },
}));
const GroupId: React.FC<GroupIdProps> = ({ goBack }): any => {
    const dispatch: Dispatch<any> = useDispatch();


    interface Part {
        Secondnary_Referece_Id: string;
        index: number
    }

    interface GroupIdItem {
        index: number;
        Secondnary_Referece_Id: string;
    }

    const classes = useStyles();
    // const [filteredParts, setFilteredParts] = useState<Part[]>([]);
    const [groupIdValue, setGroupIdValue] = useState<any[]>([]);
    const [totalGroupIdRecords, setTotalGroupIdRecords] = useState(null as any);
    const [filteredGroupIds, setFilteredGroupIds] = useState<any[]>([]);
    const { getGroupIdListData, getGroupIdListPaginationData, isGrpIDUpdating } = useSelector((state: any) => state.oesOemMapProduct);
    const [searchText, setSearchText] = useState<string>(""); // State to hold the search text
    const [page, setPage] = useState(0);
    const [isSearchShown, setIsSearchShown] = useState<boolean>(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false);
    const [errorMessage, showErrorMessage] = useState<any>('');
    const rowsPerPage = 10;
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openDeleteReplaceGroupDialog, setOpenDeleteReplaceGroupDialog] = useState(false);
    const [updateGroupDialog, setUpdateGroupDialog] = useState(false);
    const [groupIdReplaceObj, setGroupIdReplaceObj] = useState([] as any);
    const GroupId: GroupIdItem[] = [
        { index: 1, Secondnary_Referece_Id: "AB123" },
        { index: 2, Secondnary_Referece_Id: "CD456" },
        { index: 3, Secondnary_Referece_Id: "EF789" },
        // Add more items here
    ];

    const goToFirstPage = () => {
        setPage(0);
    }

    const goToLastPage = () => {
        let totalPage = 0;
        totalPage = groupIdValue && totalGroupIdRecords ? Math.ceil((totalGroupIdRecords / 10)) : 0;
        setPage(totalPage - 1);
    }

    const [loading, setLoading] = useState<boolean>(false);
    // Function to handle the search input changes
    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        if(e.target.value === ''){
            setIsSearchShown(false);
            setPage(0);
            handlePaginationWithAllRecords();
        }
    };

    useEffect(() => {
        // productTeamService.getGroupIdList('createdByME', '', 1);
        // dispatch(OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_FETCHING);
        if (getGroupIdListPaginationData && getGroupIdListPaginationData?.data?.data?.data) {
            setGroupIdValue(getGroupIdListPaginationData.data?.data?.data?.allRecords);
            setFilteredGroupIds(getGroupIdListPaginationData.data?.data?.data?.allRecords);
            setTotalGroupIdRecords(getGroupIdListPaginationData?.data?.data?.data?.totalRecords);
        }
        if (!totalGroupIdRecords) {
            setIsSearchShown(false);
            setTotalGroupIdRecords(getGroupIdListPaginationData?.data?.data?.data?.totalRecords);
        }
    }, [getGroupIdListPaginationData?.data?.data?.data]);

    const handlePagination = (groupdId?: any) => {
        if(!isSearchShown){
            dispatch(oesOemMapActions.getGroupIdPaginationList('createdByME', '', page, 10, groupdId));
        }else{
            dispatch(oesOemMapActions.getGroupIdPaginationList('createdByME', '', page, 10, searchText));
        }
    }

    const handlePaginationWithAllRecords = () => {
        dispatch(oesOemMapActions.getGroupIdPaginationList('createdByME', '', page, 10, null));
    }

    useEffect(() => {
        console.log('Page=>' + page);
        if (page >= 0) {
            handlePagination();
        }
    }, [page]);

    useEffect(() => {
        setLoading(false);
        dispatch(resetGroupIDState(OES_OEM_MAP_PRODUCT_SEARCH.RESET_GROUPID_API_STATE));
        setIsSearchShown(false);
        setTotalGroupIdRecords(null);
        setPage(0);
        setGroupIdValue([]);
        handlePaginationWithAllRecords();
        handleopenCloseErrorSnackbar(false);
        // setOpenCancelDialog(false);
    }, []);


    useEffect(() => {
        if (getGroupIdListPaginationData?.loading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [getGroupIdListPaginationData?.loading]);

    useEffect(() => {
        switch (isGrpIDUpdating?.state) {
            case API_STATES.Initial:
                setLoading(false);
                break;
            case API_STATES.Loading:
                setLoading(true);
                break;
            case API_STATES.Success:
                dispatch(resetGroupIDState(OES_OEM_MAP_PRODUCT_SEARCH.RESET_GROUPID_API_STATE));
                setTimeout(() => {
                    setLoading(true);
                    handlePaginationWithAllRecords();
                }, 100);
                break;
            case API_STATES.Failed:
                dispatch(resetGroupIDState(OES_OEM_MAP_PRODUCT_SEARCH.RESET_GROUPID_API_STATE));
                setTimeout(() => {
                    handleopenCloseErrorSnackbar(true, 'There was an error, please try again later');
                    setLoading(true);
                    handlePaginationWithAllRecords();
                }, 100);
                break;
            default:
                dispatch(resetGroupIDState(OES_OEM_MAP_PRODUCT_SEARCH.RESET_GROUPID_API_STATE));
                setLoading(false);
        }
    }, [isGrpIDUpdating?.state]);



    const updateGroupIdSearchResult = (value: any) => {
        console.log('====> Target ', value);
        if (value) {            
            if (value && value.length > 3) {
                setIsSearchShown(true);
                setPage(0);
                dispatch(oesOemMapActions.getGroupIdPaginationList('createdByME', '', 0, 10, value));
            } else {
                handleopenCloseErrorSnackbar(true, 'Please search by minimum 4 characters');
            }
        }
    }



    const EditGrpDlg = (props: any) => {
        const height = 20;
        const classes = useStyles();
        const [openCancelDialog, setOpenCancelDialog] = useState(false);
        const [newGrpIdToReplace, setNewGrpIdToReplace] = useState<any>(groupIdReplaceObj[0]);
        const confirmDeleteGrpID = (oldGrpID: any, newGrpId: any) => {
            console.log('GroupId to edit ==> ', oldGrpID + ' with ' + newGrpId);
            if (oldGrpID === newGrpId || `${newGrpId}` === '') {
                setUpdateGroupDialog(false)
            } else {
                dispatch(oesOemMapActions.updateGroupID(oldGrpID, newGrpId));
                setUpdateGroupDialog(false);
                resetAfterEditDelete();
            }
        }

        const showConfermation = () => {
            setOpenCancelDialog(true);
        }

        const handleopenCloseCancelDialog = (open?: boolean) => {
            setOpenCancelDialog(false);
        };

        const handleCancelConfirmation = (confirmed: boolean) => {
            if (confirmed) {
                setUpdateGroupDialog(false)
            }
            setOpenCancelDialog(false);  // Close cancel dialog regardless
        };

        useEffect(() => {
            setNewGrpIdToReplace(groupIdReplaceObj[0]);
        }, []);

        return (
            <div className="float-left">
                <Dialog
                    open={updateGroupDialog}
                    onClose={() => { setUpdateGroupDialog(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth={'xs'}
                >
                    <DialogContent style={{ overflow: "hidden", height: "100%", width: "100%" }}>
                        <h4 className="page-title"> Edit Group ID </h4>
                        <div>
                            <DialogContentText id="alert-dialog-description">
                                <div className="mt-2 ml-3 popup-input">
                                    <TextField
                                        required
                                        className="full-width rounded-input"
                                        size="small"
                                        id={`update-groupId`}
                                        label="Group ID"
                                        variant="outlined"
                                        margin="dense"
                                        error={newGrpIdToReplace === ''}
                                        value={`${newGrpIdToReplace}`}
                                        onChange={(e) => {
                                            setNewGrpIdToReplace(e.target.value);
                                        }}
                                        onKeyDown={(e: any) => {
                                            if (!/^[a-zA-Z0-9-\s]$/.test(e.key) && e.key !== 'Backspace') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </div>
                            </DialogContentText>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                            size="small"
                            variant="contained"
                            disabled={newGrpIdToReplace === '' || `${newGrpIdToReplace}`.length < 4}
                            color="primary"
                            onClick={() => {
                                confirmDeleteGrpID(props.oldGrpId, newGrpIdToReplace);
                            }}
                        >
                            Update
                        </Button>
                        <Button
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn"
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                showConfermation()
                            }}
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
                <CancelDialog
                    open={openCancelDialog}
                    text="Are you sure you want to close this window? All unsaved data will be lost."
                    handleopenClose={handleopenCloseCancelDialog}
                    handleCancelConfirmation={handleCancelConfirmation}
                    maxWidth="xs"
                />
            </div >
        );
    }

    const DeleteGrpDlg = (props: any) => {
        const height = 20;
        const classes = useStyles();
        const [newGrpIdToReplace, setNewGrpIdToReplace] = useState<any>(null);
        const [searchText, setSearchText] = useState<string>(""); // State to hold the search text
        const handleMapGrpIDDialog = (data: any) => {
            setGroupIdReplaceObj([]);
            if (data === 'delete') {
                setOpenDeleteDialog(false);
                setOpenDeleteReplaceGroupDialog(true);
                console.log('Data to edit', groupIdReplaceObj);
                // deleteAPICall();
            } else if (data === false) {
                setOpenDeleteDialog(false);
                setGroupIdReplaceObj([]);
                // handleopenCloseDeleteDialog(false);
                setOpenDeleteReplaceGroupDialog(false);
            }
        }
        const [searchGrpValue, setSearchGrpValue] = useState<any>('');
        const [suggestions, setSuggestions] = useState<any>([]);
        const [replaceGrpIdSelected, setReplaceGrpIdSelected] = useState(false);
        const getGroupIdPartialList = async (groupdId?: any) => {
            setReplaceGrpIdSelected(false);
            const results = await productTeamService.getGroupIdPaginationList('createdByME', '', 0, 1000, groupdId);
            if (results?.data?.allRecords) {
                let suggestions = [];
                if (results?.data?.allRecords?.length > 0) {//Secondnary_Referece_Id
                    suggestions = results?.data?.allRecords.map((e: any) => {
                        return e.Secondnary_Referece_Id;
                    })
                }
                setSuggestions(suggestions);
            }
            // console.log('===>',results);
        };
        useEffect(() => {
            const suggestions: any = [];
            setSuggestions(suggestions);
        }, []);

        return (
            <div className="float-left">
                <Dialog
                    open={openDeleteReplaceGroupDialog}
                    onClose={() => { handleMapGrpIDDialog(false) }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    // fullWidth={true}
                    // maxWidth={'sm'}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogContent>
                        {/* <div className={classes.icon}>
                            <WarningIcon className="delete-warning" fontSize="small" />
                        </div> */}
                        
                        <h4 className="page-title"> Confirm Delete </h4>
                        <div>
                            <DialogContentText id="alert-dialog-description">
                                <div className={`mt-3 ${classes.deleteText}`}>
                                    Map to another Group ID
                                </div>
                                <div className='delete-search' onClick={(ev): any => {
                                    if (ev.target && ev.target instanceof HTMLElement && ev.target.nodeName === 'LI') {
                                        setReplaceGrpIdSelected(true);
                                    }
                                }}>
                                    <Autocomplete
                                        popupIcon={<SearchIcon />}
                                        disableClearable
                                        noOptionsText={''}
                                        className="group-id-input delete-input"
                                        inputValue={searchGrpValue}
                                        onInputChange={(event, value) => {
                                            setSearchGrpValue(value);
                                            if (value && value.length > 3) {
                                                getGroupIdPartialList(value);
                                            }
                                        }}
                                        autoFocus
                                        onChange={(event: any, newValue: string | null) => {
                                            setSearchGrpValue(newValue);
                                            console.log('======XXXXX', newValue);
                                            setReplaceGrpIdSelected(true);
                                            setNewGrpIdToReplace(newValue)
                                            console.log('==> groupIdReplaceObj', [props.oldGrpId, newGrpIdToReplace]);
                                            // setSuggestions([]);
                                        }}
                                        id="group-id-search"
                                        options={replaceGrpIdSelected ? [] : suggestions}
                                        getOptionLabel={(option: string) => option}
                                        // style={{ width: 300 }}
                                        renderInput={(params) => (
                                            <TextField
                                                // className="full-width rounded-input search-helpertext mt-2" 
                                                className="smallText mt-2 search-helpertext commonRoundedInputs w-100"
                                                {...params} label="Group ID" variant="outlined" value={searchGrpValue}
                                                helperText={'' + (replaceGrpIdSelected && newGrpIdToReplace ? `You have selected Group ID:${newGrpIdToReplace}` : '')}
                                            />
                                        )}
                                        open={!replaceGrpIdSelected && searchGrpValue.length > 3}
                                    />
                                </div>
                            </DialogContentText>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            // className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton action-btn"
                            size="small"
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"

                            variant="contained"
                            color="primary"
                            onClick={() => {
                                confirmDeleteGrpID(props.oldGrpId, searchGrpValue);
                            }}
                        >
                            Confirm
                        </Button>
                        <Button
                            // className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn action-btn"
                            size="small"
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 dialog-cancel-btn"

                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                handleMapGrpIDDialog(false)
                            }}
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>

            </div >
        );
    }

    const confirmDeleteGrpID = (oldGrpID: any, newGrpId: any) => {
        console.log('GroupId to edit ==> ', oldGrpID + ' with ' + newGrpId);
        if (oldGrpID === newGrpId) {
            //ToDo: Show error message
            setOpenDeleteDialog(false);
            setOpenDeleteReplaceGroupDialog(false);
        } else {
            //ToDO: Verify API Integration
            dispatch(oesOemMapActions.updateGroupID(oldGrpID, newGrpId));
            setOpenDeleteDialog(false);
            setOpenDeleteReplaceGroupDialog(false);
            resetAfterEditDelete();
        }
    }

    const getRowCount = (): number => {
        return groupIdValue && totalGroupIdRecords ? totalGroupIdRecords : 0;
    }

    const resetAfterEditDelete = () => {
        setPage(0);
        setTotalGroupIdRecords(null);
        setGroupIdValue([]);
    }

    const handleopenCloseErrorSnackbar = (open: boolean, msg?: string) => {
        if (open) {
            showErrorMessage(msg);
            setOpenErrorSnackbar(true);
        } else {
            showErrorMessage('');
            setOpenErrorSnackbar(false);
        }

    };

    return (
        <>
            {loading && <FullscreenLoader />}
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    OES OEM Mapping
                </h2>
            </div>
            <div className="back-btn">
                <Button
                    variant="contained"
                    color="primary"
                    className="rounded-pill"
                    onClick={goBack}
                >
                    Back
                </Button>
            </div>
            <div className="search-div">
                <h6 className="groupId-title">List of Group IDs</h6>
                <div className="search-field">
                    <TextField
                        id="grpIdSearchTxt"
                        value={searchText}
                        onChange={handleSearchChange}
                        size="small"
                        className="commonRoundedInputs"
                        variant="outlined"
                        label="Search Group ID"
                        onKeyDown={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                            if (ev.key === 'Enter') {
                                ev.preventDefault();
                                updateGroupIdSearchResult(((ev.target as HTMLTextAreaElement)).value);
                            }
                        }}
                    />
                    <SearchIcon className="search-icon" onClick={() => { updateGroupIdSearchResult(searchText) }} />
                </div>
            </div>
            {/* <div className="cardCommon mh-300p"> */}
            <TableContainer>
                <Table aria-label="table">
                    <TableHead className="w-100">
                        <TableRow>
                            <TableCell className={classes.tableHead}>Serial No.</TableCell>
                            <TableCell className={classes.tableHead}>Group ID</TableCell>
                            <span className="groupId-action-title">
                                <TableCell className={classes.actiontTbleHead}>Action</TableCell>
                            </span>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {groupIdValue?.length > 0 ? (
                            groupIdValue.map((item: Part, index: number) => (
                                <TableRow key={index}
                                    style={{
                                        padding: '10px',
                                        margin: '5px 0',
                                        border: '1px solid #ddd',
                                    }}>
                                    {/* Displaying Serial No. dynamically starting from 1 */}
                                    <TableCell>{(page * 10) + index + 1}</TableCell>  {/* Serial No. starts from 1 */}
                                    <TableCell className="pl-3">{item.Secondnary_Referece_Id}</TableCell>

                                    <TableCell>
                                        <div className="act-btn-space">
                                            <div className="act-container" onClick={() => {
                                                setGroupIdReplaceObj([item.Secondnary_Referece_Id]);
                                                // EditGrpDlg
                                                setUpdateGroupDialog(true);
                                            }}>
                                                <EditOutlinedIcon fontSize="inherit" className="mr-1 act-btn" />
                                                <a className="mr-3 act-item">Edit</a>
                                            </div>
                                            <div className="act-container mr-5" onClick={() => {
                                                // handleDeleteClick(true, item);
                                                setOpenDeleteDialog(true);
                                                setGroupIdReplaceObj([item.Secondnary_Referece_Id]);
                                            }}>
                                                <DeleteIcon fontSize="inherit" className="mr-1 act-btn"
                                                />
                                                <a className="mr-3 act-item">Delete</a>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={8} className="no-data">
                                    {isSearchShown && <b className="no-data">No results found</b>}
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className="col-12 d-flex justify-content-center">
                {/* <Tooltip title={"Go To First Page"} placement="bottom"></Tooltip> */}
                    {/* <Button onClick={goToFirstPage}><FirstPageIcon></FirstPageIcon></Button> */}
                <IconButton
                    aria-label="firstPageIcon"
                    style={{fill:'black'}}
                    disabled={!groupIdValue || !totalGroupIdRecords
                        || totalGroupIdRecords <= 10 ||
                        (totalGroupIdRecords > 10 && page === 0)}
                    onClick={goToFirstPage}>
                    <FirstPageIcon />
                </IconButton>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={
                        getRowCount()
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                />
                {/* <Button onClick={goToLastPage} disabled="getRowCount() < = 10"><LastPageIcon></LastPageIcon></Button> */}
                <IconButton
                    aria-label="lastPageIcon"
                    disabled={!groupIdValue || !totalGroupIdRecords
                         || totalGroupIdRecords <= 10 || 
                         (totalGroupIdRecords > 10 && page === (Math.ceil((totalGroupIdRecords / 10)) - 1))
                        }
                    onClick={goToLastPage}>
                    <LastPageIcon />
                </IconButton>
            </div>
            <DeleteDialogProductTeamPortal
                className="test-delete-dio"
                open={openDeleteDialog}
                text={<div>
                    <br />
                    <p>Are you sure you want to delete the Group ID ?</p>
                    <p>You will have to map the parts to other Group ID</p>
                </div>}
                handleopenCloseDeleteDialog={(data: any) => {
                    //   setGroupIdReplaceObj([]);
                    if (data === 'delete') {
                        // setGroupIdReplaceObj([]);
                        console.log('Data to edit', groupIdReplaceObj);
                        setOpenDeleteDialog(false);
                        setOpenDeleteReplaceGroupDialog(true);
                        // deleteAPICall();
                    } else if (data === false) {
                        setOpenDeleteDialog(false);
                        setGroupIdReplaceObj([]);
                        // handleopenCloseDeleteDialog(false);
                        setOpenDeleteReplaceGroupDialog(false);
                    }
                }} />

            <DeleteGrpDlg classes={{ paper: classes.dialogPaper }} oldGrpId={groupIdReplaceObj[0]} />
            <EditGrpDlg oldGrpId={groupIdReplaceObj[0]} />
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false);
                }}
                text={errorMessage} />
        </>
    )
}


export default GroupId