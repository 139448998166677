/* eslint-disable */
import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import "./CreateEnquiry.css";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import IconButton from "@material-ui/core/IconButton";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
// import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ImportDialog from "./ImportExcelDialog";
import ViewFavorite from "./viewFavorite";
import OrderSummaryDialog from "./orderSummaryDialog";
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import XLSX from "xlsx";
import { searchProducts } from "../../store/actions/products.actions";
import { sendAnnouncmentToCustomer } from '../../store/actions/announcements.actions'
import FullscreenLoader from "./../../common/components/FullscreenLoader";
import NavigationPrompt from "react-router-navigation-prompt";
import DialogContent from '@material-ui/core/DialogContent';
import {
  createEnquiry,
  updateEnquiry,
  verifyEnquiry,
  getEnquiryDetails,
  placeOrder,
  verifyEnquiryQueueCheck,
  deleteLostSaleReport,
  getUniqueEnquirySessionId,
  setEnquiryId,
  selectedParts,
  sendZeroProductsEmail,
  getDLEmailByRegion,
  verifyQuotationQueueCheck,
  sendEmailProcurePart,
  getOrderLogs,
  sendEmailOrderLogs,
  getOrderLogsLatestCount,
  deleteInvalidParts,
  setOrderLogs
} from "../../store/actions/createUpdateEnquiry.actions";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import _, { get } from "lodash";
import Countdown from "react-countdown";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Dialog, FormControl, Select } from "@material-ui/core";
import { getNewBrand, getUniqueTimestamp } from "../../common/components/util";
import { getSellingPrice } from "../../helpers/commonmethod";
import DeleteDialog from "../../common/components/deleteDialog";
import MakePaymentDialog from "./MakePaymentDialog";
import OrderSummary from "./OrderSummary";
import { getCreditLimit } from "../../store/actions/creditLimit.actions";
import HelpIcon from "@material-ui/icons/Help";
import { requestSpecialPriceService } from "../../services/requestSpecialPrice.service";
import { getViewRequestStatusAPI, requestModifyData } from "../../store/actions/requestSpecialPrice.actions";
import ExpiredDialog from "./ExpiredDialog";
import ExpiredDialog24Hrs from "./ExpiredDialog24Hrs";
import EstimationPromtDialog from "./estimationPromtDialog";
import RequestQuotationDialog from "../Quotation/RequestQuotationDialog";
import ChooseCustomerDialog from "./ChooseCustomerDialog";
import { getCustomerDetailsByCustomerID, getDeliveryBlockReason, getFSECustomerList } from "../../store/actions/dealer.action";
import ShippingAddSelectionDialog from "./ShppingAddSelectionDialog";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ConfirmDialogEnquiry from "./ConfirmCreateEnquiryDialog";
import OrderLogDialog from "./OrderLogDialog";
import Badge from '@material-ui/core/Badge';


const CHECK_AVAILABILITY_BUTTON_TEXT = {
  NORMAL: 'Check Availability and Price',
  QUEUED: 'Queued for Verification',
  PROGRESS: 'Verification In Progress'
}

const orgIdBrandsMapping: any = {
  "2001": "Toyota",
  "2002": "Honda",
  "2003": "Famco",
  "2380": "TE",
  "2195": "OES"
}

const orgIdBrandsMappingReverse: any = {
  "Toyota": "2001",
  "Honda": "2002",
  "Famco": "2003",
  "TE": "2380",
  "OES": "2195",
}

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: '2px 4px',
    // display: "flex",
    // alignItems: "center",
    width: '100%',
  },
  container: {
    maxHeight: 500,
    background: "#FFFFF",
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  iconMaterial: {
    margin: "5px",
    height: "100%",
    fill: "white",
  },
  iconForCustomer: {
    float: "left",
    margin: "25px 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  icon1: {
    float: "left",
    margin: "0 20px 220px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  logoutText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  input: {
    flex: 1,
  },
  iconButton: {
    // padding: 10,
  },
  actionButton: {
    color: "#1a9fe0",
  },
  collapseBg: {
    background: "#FFFFF",
  },
  tableWidth: {
    minWidth: 170,
    // fontWeight: 600
  },
  collapseHeaderBg: {
    background: "rgba(17, 17, 17, 0.05)",
  },
  enquiryPop: {
    display: 'flex',
    margin: '10px 0px'
  },
  textRight: {
    textAlign: 'right'
  }
}));

const formatMaterialGroup = (data: any) => {
  if (data) {
    return data.substring(0, 5).toUpperCase()
  } else {
    return "XXXXX"
  }
}

function Row(props: any) {
  const {
    availability,
    setcheckedAllBO,
    setcheckedAllNow,
    setChecked24hrs,
    isChangeCheckBox,
    enquiryAction,
    setisSelectedAll,
    brandCode,
    setbrandRefNumbers,
    brandRefNumbers,
    setDeliveryBlockReason,
    deliveryBlockReason,
    salesOffice,
    setSalesOffice,
    setproducts,
    setreferenceNumber,
    isAvailabiityChecked,
    brandName,
    brandData,
    products,
    setIsQuantityUpdated,
    isSellingPriceData,
    markupPercentage,
    enquiryMapping,
    enquiryMappingTPC,
    enquirySubBrand,
    isSpecialPrice,
    verificationData,
    spParts,
    isAvailabiityCheckedAtTime,
    isSPRModifyData,
    accessData,
    requestStatus,
    setDisableQuotation
  } = props;
  const [open, setOpen] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { getCustomerDetailsBySapId, ddlValues }: any = useSelector((state: any) => state.dealer);
  let customerTierType: any = '';
  if (sessionStorage.getItem('userType') === 'FSE') {
    customerTierType = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType;
  } else {
    customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  }
  const deliveryTypeData = profileDetails?.data?.data?.customerInfo?.deliveryType;
  const collectionPoints = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.collectionPointsOfOrder && profileDetails.data.data.collectionPointsOfOrder.collectionPoints;
  const [estmateTotal, setEstmateTotal] = React.useState<any>();
  const [requestedTotalType, setRequestedTotalType] = React.useState<any>();
  const [requestedCalculatedTotalType, setRequestedCalculatedTotalType] = React.useState<any>();
  const [requestedCaluclatedTotalPrice, setRequestedCaluclatedTotalPrice] = React.useState<any>();
  const { enquiryInfo, enquirySessionId, getOrderLogsDetails }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );
  const { enquiryId }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );


  useEffect(() => {
    let checkedTotal: any
    let reqType: any
    let checkedCalculatedTotal: any
    let checkedReqType: any

    if (verificationData && verificationData.data && verificationData.data.length > 0) {
      verificationData.data.map((data: any) => {
        reqType = data && data?.specialPriceRequestType
        checkedTotal = data && data?.specialRequestOfferedTotalPricePercentage
        checkedCalculatedTotal = data && data?.specialRequestOfferedTotalPriceCalculated
        checkedReqType = data && data?.specialRequestOfferedTotalPriceCurrency
      })
    }

    setRequestedTotalType(reqType);
    setEstmateTotal(checkedTotal);
    setRequestedCaluclatedTotalPrice(checkedCalculatedTotal);
    setRequestedCalculatedTotalType(checkedReqType);
  }, [verificationData])


  const getParts = (item: any) => {
    let allBrand: any = []
    allBrand = [...allBrand, item]
  }

  const getAmtValue = (item: any) => {
    let total = 0.00;
    let isAvail = false;
    if (isAvailabiityCheckedAtTime) {
      var now = moment(new Date());
      var start = moment(new Date(isAvailabiityCheckedAtTime));
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (
        !isAvailabiityCheckedAtTime ||
        (minutes && minutes > 5)
      ) {
        isAvail = false;
      } else {
        isAvail = true
      }
    }

    if (item.nowAvailabilityChecked) {
      if (item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN' && enquiryAction == 'view' && isAvailabiityChecked && requestStatus === 'Approved') {
        total += parseFloat(item.nowAvailability) * parseFloat(item.nowApprovedPrice);
      } else {
        if (requestedTotalType == 'part' && item.isSpecialPriceRequestPart && requestStatus === 'Approved' && item.isRejected === false && isAvailabiityChecked && isAvail) {
          if (item.nowOfferedPriceType == 'byPercentage' && item["nowOfferedCalculatedPrice"] && item["nowOfferedCalculatedPrice"] !== '' && item["nowOfferedCalculatedPrice"] !== 'NaN') {
            total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedCalculatedPrice);
          } else if (item.nowOfferedPriceType == 'byCurrency' && item["nowOfferedPrice"] && item["nowOfferedPrice"] !== '' && item["nowOfferedPrice"] !== 'NaN') {
            total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedPrice);
          } else {
            total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
          }
        } else if (requestedTotalType == 'total' && isAvailabiityChecked && item.nsp && item.nowAvailability && item.isSpecialPriced === false &&
          requestStatus === 'Approved' && isAvail) {
          if (requestedCalculatedTotalType === "By AED") {

            let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
            let newNsp: any = (parseFloat(value) * item["nsp"]) / 100;
            let nsp: any = item["nsp"] - parseFloat(newNsp);


            // let nsp: any = (parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["nsp"]) / 100
            total += parseFloat(item.nowAvailability) * parseFloat(nsp)
          } else if (requestedCalculatedTotalType === "By %") {
            let nsp1: any = (parseFloat(estmateTotal) * item["nsp"]) / 100;
            let newNsp: any = parseFloat(item["nsp"]) - parseFloat(nsp1);
            total += parseFloat(item.nowAvailability) * parseFloat(newNsp)
          }
        }
        else {
          total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
        }
      }
    }
    // <<<<<<<<<<< 24 hrs >>>>>>>>>>>>>>>>>>>>> NEW CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item && item.hrs24AvailabilityChecked && item.hrs24Availability && item.nsp) {
      total += parseFloat(item.hrs24Availability) * parseFloat(item.nsp);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    if (item.airAvailabilityChecked && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer") {
      if (item.airApprovedPrice && item.airApprovedPrice !== '' && item.airApprovedPrice !== 'NaN' && isAvailabiityChecked && enquiryAction == 'view' && requestStatus === 'Approved') {
        total += parseFloat(item.airAvailability) * parseFloat(item.airApprovedPrice);
      } else {
        if (requestedTotalType == 'part' && item.isSpecialPriceRequestPart && requestStatus === 'Approved' && item.isRejected === false && isAvailabiityChecked && isAvail) {
          if (item.airOfferedPriceType == 'byPercentage' && item["airOfferedCalculatedPrice"] && item["airOfferedCalculatedPrice"] !== '' && item["airOfferedCalculatedPrice"] !== 'NaN') {
            total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedCalculatedPrice);
          } else if (item.airOfferedPriceType == 'byCurrency' && item["airOfferedPrice"] && item["airOfferedPrice"] !== '' && item["airOfferedPrice"] !== 'NaN') {
            total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedPrice);
          } else {
            total += parseFloat(item.airAvailability) * parseFloat(item.aop);
          }
        } else if (requestedTotalType == 'total' && isAvailabiityChecked && isAvail && item.aop && item.airAvailability && item.isSpecialPriced === false &&
          requestStatus === 'Approved') {
          if (requestedCalculatedTotalType === "By AED") {

            let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
            let bop1: any = (parseFloat(value) * item["aop"]) / 100;
            let newaop: any = item["aop"] - parseFloat(bop1);
            total += parseFloat(item.airAvailability) * parseFloat(newaop);

            // let bop: any = (parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["aop"]) / 100
            // total += parseFloat(item.airAvailability) * parseFloat(bop)
          } else if (requestedCalculatedTotalType === "By %") {
            let bop1: any = (parseFloat(estmateTotal) * item["aop"]) / 100;
            let newaop: any = parseFloat(item["aop"]) - parseFloat(bop1);
            total += parseFloat(item.airAvailability) * parseFloat(newaop)
          }
        }
        else {
          total += parseFloat(item.airAvailability) * parseFloat(item.aop);
        }
      }
    }
    return total
  }

  const isBluelegend = (ele: any) => {
    let airPrice: any = ele.airOfferedPriceType == "byCurrency" ? parseFloat(ele.airOfferedPrice) : parseFloat(ele.airOfferedCalculatedPrice)
    let nowPrice: any = ele.nowOfferedPriceType == "byCurrency" ? parseFloat(ele.nowOfferedPrice) : parseFloat(ele.nowOfferedCalculatedPrice)
    if (ele.nowAvailabilityChecked && ele.airAvailabilityChecked) {
      if (((parseFloat(ele.nspev)) > parseFloat(nowPrice)) || (parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.nowAvailabilityChecked) {
      if ((parseFloat(ele.nspev) > nowPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.airAvailabilityChecked) {
      if ((parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  const getNSPEVValue = (item: any) => {
    // let data = spParts.filter((sp: any) => sp.nowEnquiryItemNumber == item.nowEnquiryItemNumber)
    let nspev: any = 0.00;
    if (enquiryAction === 'view' && item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN') {
      nspev = (parseFloat(getSellingPrice(item.nowApprovedPrice, isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestedTotalType === "part" && item.isSpecialPriceRequestPart && item["nowAvailability"] && requestStatus === 'Approved') {
        if (item.nowOfferedPriceType == 'byPercentage' && item["nowOfferedCalculatedPrice"] && item["nowOfferedCalculatedPrice"] !== '' && item["nowOfferedCalculatedPrice"] !== 'NaN') {
          nspev = parseFloat(getSellingPrice(item["nowOfferedCalculatedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else if (item.nowOfferedPriceType == 'byCurrency' && item["nowOfferedPrice"] && item["nowOfferedPrice"] !== '' && item["nowOfferedPrice"] !== 'NaN') {
          nspev = parseFloat(getSellingPrice(item["nowOfferedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else {
          nspev = (parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (requestedTotalType === "total" && isAvailabiityChecked && item["nspev"] && item["nowAvailability"] && item.isSpecialPriced === false &&
        requestStatus === 'Approved') {
        if (requestedCalculatedTotalType === "By AED") {

          let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
          let addedValue: any = (parseFloat(value) * item["nspev"]) / 100;
          nspev = (parseFloat(getSellingPrice((parseFloat(item["nspev"]) - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
          // nspev = (parseFloat(getSellingPrice((parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["nspev"]) / 100, isSellingPriceData, markupPercentage)).toFixed(2))
        }
        if (requestedCalculatedTotalType === "By %") {
          let addedValue: any = (parseFloat(estmateTotal) * item["nspev"]) / 100;
          nspev = (parseFloat(getSellingPrice((parseFloat(item["nspev"]) - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (isAvailabiityChecked && item["nspev"]) {
        nspev = (parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return nspev;
  }

  const getNSPValue = (item: any) => {
    let nsp: any = 0.00;
    /* Code for adding 5% margin to nsp(exclusive vat) and returing nap value is special price approved ->  Bhargavi */
    if (enquiryAction === 'view' && item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN') {
      let nspev = getNSPEVValue(item);
      let addedValue: any = (nspev * 5) / 100;
      nsp = (parseFloat(getSellingPrice((parseFloat(nspev) + parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestStatus === 'Approved' && item["nowAvailability"] && item.isSpecialPriced === false) {
        let nspev = getNSPEVValue(item);
        let addedValue: any = (nspev * 5) / 100;
        nsp = (parseFloat(getSellingPrice((parseFloat(nspev) + parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
      } else if (isAvailabiityChecked && item["nsp"]) {
        nsp = (parseFloat(getSellingPrice(item["nsp"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return nsp;
  }

  const getBOPValue = (item: any) => {
    let bop: any = 0.00;
    if (enquiryAction === 'view' && item.airApprovedPrice && item.airApprovedPrice !== '' && item.airApprovedPrice !== 'NaN') {
      bop = (parseFloat(getSellingPrice(item.airApprovedPrice, isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestedTotalType === "part" && item.isSpecialPriceRequestPart && item["airAvailability"] && requestStatus === 'Approved' && item.isRejected == false) {
        if (item.airOfferedPriceType == 'byPercentage' && item["airOfferedCalculatedPrice"] && item["airOfferedCalculatedPrice"] !== '' && item["airOfferedCalculatedPrice"] !== 'NaN') {
          bop = parseFloat(getSellingPrice(item["airOfferedCalculatedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else if (item.airOfferedPriceType == 'byCurrency' && item["airOfferedPrice"] && item["airOfferedPrice"] !== '' && item["airOfferedPrice"] !== 'NaN') {
          bop = parseFloat(getSellingPrice(item["airOfferedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else {
          bop = (parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (requestedTotalType === "total" && isAvailabiityChecked && item["aop"] && item["airAvailability"] && item.isSpecialPriced === false &&
        requestStatus === 'Approved') {
        if (requestedCalculatedTotalType === "By AED") {

          let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
          let addedValue: any = (parseFloat(value) * item["aop"]) / 100;
          bop = (parseFloat(getSellingPrice((item["aop"] - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2));

          // bop = (parseFloat(getSellingPrice((parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["aop"]) / 100, isSellingPriceData, markupPercentage)).toFixed(2));
        }
        if (requestedCalculatedTotalType === "By %") {
          let addedValue: any = (item["aop"] * parseFloat(estmateTotal)) / 100;
          bop = (parseFloat(getSellingPrice((item["aop"] - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2));
        }
      } else if (isAvailabiityChecked && item["aop"]) {
        bop = (parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return bop;
  }

  function isDisabledBo(item: any) {
    if (item.nowAvailabilityChecked && isAvailabiityChecked && item.isSpecialPriced == false) {
      if (item.nowAvailability !== item.spNowAvailability) {
        return false
      }
    }
    return true;
  }

  function getDDLCode(title: any, ddl: any) {
    let array: any;
    if (ddl && ddl.length > 0) {
      array = ddl.filter((d: any) => d?.title === title)
    }
    if (array && array.length > 0) return array[0].value
  }

  function is24hrExist() {
    if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && products && products.length > 0) {
      return products.some((obj: any) => obj.hrs24AvailabilityChecked === true);
    }
  }

  const brandNameData: any = {
    "Toyota": "Al Futtaim Motors",
    "TE": "Trading Enterprises",
    "OES": (customerTierType && customerTierType.toLowerCase() === "workshop") || (customerTierType && customerTierType.toLowerCase() === "tpcdealer") ? "Tradepoint" : 'ALAC',
    "Honda": "Honda",
    "Te": "Trading Enterprises",
  }

  const FinalVerifiedinternalNumber: any = verificationData && verificationData.data && verificationData.data.length > 0 &&
    verificationData.data.map((ele: any, i: any) => {
      return {
        "make": ele && ele.make,
        "subEnquiryId": ele && ele.enquiryNumber,
      }
    });

  const ViewInternalNumber: any = enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.map((ele: any, i: any) => {
    return {
      "make": ele && ele?.make,
      "subEnquiryId": ele && ele?.enquiryNumber,
    }
  });

  let status: any = sessionStorage.getItem('enquiry-current-status');
  let id: any
  let newVerifiedId: any

  if (verificationData && verificationData.data && verificationData.data.length > 0) {
    const subEnquiryId: any = FinalVerifiedinternalNumber ? FinalVerifiedinternalNumber : ""
    id = subEnquiryId.filter((ele: any, i: any) => {
      return brandNameData[ele.make] === brandName
    });
  } else {
  }

  if (enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.length > 0) {
    const viewInternalID: any = ViewInternalNumber ? ViewInternalNumber : ""
    let brandNameforOES: any = customerTierType === 'tpcdealer' ? 'Tradepoint' : brandName
    id = viewInternalID.filter((ele: any, i: any) => {
      return brandNameData[ele.make] === brandNameforOES
    });
  } else {
  }

  const internalNumber: any = enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.filter((ele: any, i: any) => {
    return brandNameData[ele.make] === brandName
  });


  return (
    <React.Fragment>
      <TableRow
        className="tableRowPanelsCreateEnquiry"
      >
        <TableCell className="">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell colSpan={11} className="blueLabelHeaderTable">
          {customerTierType === 'tpcdealer' ? 'Tradepoint' : brandName}{" "}
          <input
            disabled={customerTierType && customerTierType === "Dealer" &&
              (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
            maxLength={20}
            value={brandRefNumbers[brandCode]}
            onChange={(e) => {
              if (enquiryAction !== "view") {
                setreferenceNumber(e.target.value);
                setbrandRefNumbers({
                  ...brandRefNumbers,
                  [brandCode]: e.target.value ? e.target.value : "",
                });
              }
            }}
            type="text"
            className="ml-3 referenceNumberText"
            placeholder="Your reference number"
          />
          <span className="ml-3 totalCountTextSmallest">{brandData.length} Parts</span>
          {sessionStorage.getItem('userType') === 'FSE' && (
            <>
              <TextField
                id="deliveryblockreason"
                select
                disabled={enquiryAction === "view" ? true : false}
                required={sessionStorage.getItem('userType') === 'FSE' && customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && is24hrExist() ? true : false}
                label="Delivery block reason"
                variant="outlined"
                className="rounded-input-dealer ml-3 w-25"
                size="small"
                InputLabelProps={{
                  style: { color: '#000000' },
                }}
                value={deliveryBlockReason[brandCode].deliveryBlockReason}
                onChange={(e: any) => {
                  if (enquiryAction !== "view") {
                    setDeliveryBlockReason({
                      ...deliveryBlockReason,
                      [brandCode]: {
                        deliveryBlockReason: e.target.value,
                        deliveryBlockReasonCode: getDDLCode(e.target.value, ddlValues && ddlValues.data && ddlValues.data.data && ddlValues.data.data.deliveryBlockReason)
                      },
                    });
                  }
                }}
              >
                {ddlValues && ddlValues.data && ddlValues.data.data && ddlValues.data.data.deliveryBlockReason && ddlValues.data.data.deliveryBlockReason.length > 0 && ddlValues.data.data.deliveryBlockReason.map((ddl: any) => (
                  <MenuItem key={ddl.title} value={ddl.title}>
                    {ddl.title}
                  </MenuItem>
                ))}
              </TextField>
              {brandCode === '2380' && sessionStorage.getItem("orderCreatedBy") === 'AA' && (
                <TextField
                  id="salesOffice"
                  select
                  required
                  label="Sales Office"
                  variant="outlined"
                  className="rounded-input-dealer ml-2 w-25"
                  size="small"
                  InputLabelProps={{
                    style: { color: '#000000' },
                  }}
                  value={salesOffice ? salesOffice : sessionStorage.getItem('teSalesOffice')}
                  onChange={(e: any) => {
                    if (enquiryAction !== "view") {
                      setSalesOffice(e.target.value);
                      sessionStorage.setItem('teSalesOffice', e.target.value)
                    }
                  }}
                >
                  <MenuItem key='E45A' value='E45A'>
                    E45A
                  </MenuItem>
                  <MenuItem key='E45B' value='E45B'>
                    E45B
                  </MenuItem>
                </TextField>
              )}
            </>
          )}
          {status === "Update" || status === "View" ?
            <>
              {/* {!id  ? "" :
                <span key={id && id.subEnquiryId} className="ml-5">Internal number - {id && id.length > 0 && id[0].subEnquiryId} </span>
              } */}
              {!id || Object.keys(id).length === 0 ? (
                ""
              ) : (
                <span key={id?.subEnquiryId || "default-key"} className="ml-5">
                  Internal number - {id?.length > 0 ? id[0]?.subEnquiryId : ""}
                </span>
              )}
            </>
            : ""}
        </TableCell>
      </TableRow>
      {/* <TableRow className={"tableRowPanelsCreateEnquiry"}>
        <TableCell className="p-0" colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
        </TableCell>
      </TableRow> */}
      {
        open &&
        brandData &&
        brandData.map((item: any, index: any) => {
          getParts(item);
          if (!isAvailabiityChecked || (isAvailabiityChecked && (availability === "All" ||
            (availability === "Now" && item.nowAvailability && !item.airAvailability) ||
            (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && availability === "24" && item.hrs24Availability && !item.nowAvailability && !item.airAvailability) ||
            (availability === "BO" && item.airAvailability)))
          ) {
            return (
              <TableRow className={`${item.isSuperseeded ? "superseededRow" : ""} ${!item.isValid ? "tableRowCreateEnquiry invalidRowClass" : "tableRowCreateEnquiry"}`}>
                <TableCell className="pr-0">
                  {!item.isSuperseeded ?
                    (
                      <>
                        <input
                          disabled={customerTierType && customerTierType === "Dealer" &&
                            (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)} type="checkbox"
                          checked={item.selected ? true : false}
                          onChange={() => {
                            if (enquiryAction !== "view") {
                              let productsTemp: any = JSON.parse(
                                JSON.stringify(products)
                              );
                              productsTemp[item.actualIndex]["selected"] =
                                !item.selected;
                              setproducts([...productsTemp]);
                              setisSelectedAll(false);
                            }
                          }}
                        />
                        <strong className="ml-1 font-9">{index + 1}</strong>
                      </>
                    ) : <span className=""><ArrowRightAltIcon /><strong className="px-1 font-9">{index + 1}</strong></span>}
                </TableCell>
                <TableCell className="">
                  <div className="innergreyLabelTable position-relative">
                    {" "}
                    {item.isProcurementPart && (
                      <Tooltip title="Material cannot be procured from external supplier" placement="top">
                        <span className="dotlegendItemProcCreateEnquiry"></span>
                      </Tooltip>
                    )}
                    {item.isSpecialPriced && customerTierType && customerTierType.toLowerCase() !== "fleet" && customerTierType.toLowerCase() !== "government" && (
                      <span className="dotlegendItemCreateEnquiry"></span>
                    )}
                    {item.isRejected && requestStatus === 'Approved' && customerTierType.toLowerCase() !== "fleet" && customerTierType.toLowerCase() !== "government" && (
                      <span className=" dotlegendItemRedCreateEnquiry mt-n1"></span>
                    )}
                    {!item.isSpecialPriced && (item.nowRequestPrice && item.nowRequestPrice !== '' && item.nowRequestPrice !== 'NaN' || item.airRequestPrice && item.airRequestPrice !== '' && item.airRequestPrice !== 'NaN') && requestStatus === 'Approved' && customerTierType.toLowerCase() !== "fleet" && customerTierType.toLowerCase() !== "government" && (
                      <>
                        {isBluelegend(item) && !item.isRejected && item.isSpecialPriceRequestPart ? (
                          <span className="dotlegendItemBlueCreateEnquiry mt-n1"></span>
                        ) :
                          ((isBluelegend(item) == false)) && item.isSpecialPriceRequestPart && !item.isRejected && customerTierType.toLowerCase() !== "fleet" && customerTierType.toLowerCase() !== "government" ? (
                            <span className=" dotlegendItemRedCreateEnquiry mt-n1"></span>
                          ) : ''}
                      </>
                    )}
                    {/* {item.isRejected == false && item.isSpecialPriceRequestPart && requestStatus === 'Approved' && (
                      <span className="dotlegendItemBlueCreateEnquiry"></span>
                    )} */}
                    {" "}
                    {/* {item.superceededPartIndicator && (
                      <span className="dotlegendItemCreateEnquirySupersede"></span>
                    )}{" "} */}
                    {item.productId}
                  </div>
                  <div className="greyLabelTable partNumberYextTable">
                    {item.productIdDescription}
                  </div>
                </TableCell>
                <TableCell className="font-10 font-weight-700 text-444">
                  {/* {console.log(item, enquiryMappingTPC, enquiryMapping, getNewBrand(enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`]))} */}
                  {/* {enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`]}{item.oesSubBrandName} */}
                  {enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`] === 'OES' && item && item.oesSubBrandName !== '' ? item.oesSubBrandName :
                    enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] ? enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] :
                      customerTierType && customerTierType.toLowerCase() !== "tpcdealer" ? (
                        <>
                          {enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] === 'OES' && (customerTierType && (customerTierType.toLowerCase() !== "workshop")) ? 'ALAC' : getNewBrand(enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`])}
                        </>
                      ) : (
                        <>
                          {getNewBrand(enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`])}
                        </>
                      )}
                  {/* {getNewBrand(enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`])} */}
                </TableCell>
                <TableCell className="pl-0 pr-0">
                  {!item.isSuperseeded && <div className="innergreyLabelTable">
                    <input
                      type="text"
                      disabled={customerTierType && customerTierType === "Dealer" &&
                        (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                      onChange={(e) => {
                        if (enquiryAction !== "view") {
                          let productsTemp: any = JSON.parse(
                            JSON.stringify(products)
                          );
                          let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                            parseInt(e.target.value) >= 1
                            ? parseInt(e.target.value)
                            : ""
                          setIsQuantityUpdated(true);
                          setDisableQuotation(true);
                          productsTemp[item.actualIndex]["quantity"] = value;
                          setproducts([...productsTemp]);
                        }
                      }}
                      value={item.quantity}
                      maxLength={5}
                      className={`cardCommon mr-1 smallcircularinputFiled ${!item.quantity || !parseInt(item.quantity)
                        ? "error-border-input"
                        : ""
                        }`}
                      placeholder=""
                    />{" "}
                    {isAvailabiityChecked && item["nowAvailability"] && item["hrs24Availability"] && item["nowAvailability"] ?
                      parseInt(isAvailabiityChecked && item["nowAvailability"] && item["nowAvailability"]) + parseInt(isAvailabiityChecked && item["hrs24Availability"] && item["hrs24Availability"])
                      : isAvailabiityChecked && item["nowAvailability"] ? item["nowAvailability"] : isAvailabiityChecked && item["hrs24Availability"] ? item["hrs24Availability"]
                        : "0"}{"   "}&nbsp;
                    {sessionStorage.getItem('userType') === 'FSE' && (
                      item.soh ? item.soh : '0'
                    )}
                  </div>}
                </TableCell>
                {sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" ?
                  <TableCell className="font-10 font-weight-700 text-444">{isAvailabiityChecked && item["rsp"] ? item["rsp"] : "XX.XX"}</TableCell>
                  : ""}
                <TableCell className="font-10 font-weight-700 text-444">
                  {getNSPValue(item)}
                </TableCell>
                {/* {isAvailabiityChecked && item["nsp"] ? parseFloat(getSellingPrice(item["nsp"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td> */}
                <TableCell className="font-10 font-weight-700 text-444">{isAvailabiityChecked ? parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</TableCell>
                <TableCell className="font-10 font-weight-700 text-444">{getNSPEVValue(item)}</TableCell>
                {/* {isAvailabiityChecked && item["nspev"] ? parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</td> */}
                {
                  customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") ?
                    <TableCell className="partNumberYextTable font-10 font-weight-700 text-444">{isAvailabiityChecked && item["aop"] ? parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2) : "0.00"}</TableCell> :
                    <TableCell className="font-10 font-weight-700 text-444">
                      {getBOPValue(item)}
                    </TableCell>
                }

                <TableCell className="">
                  <div className="row no-gutters pr-1">
                    <div className="innergreyLabelTable col-4 pr-1">
                      <input type="checkbox"
                        disabled={customerTierType && customerTierType === "Dealer" &&
                          (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}                        // disabled={item.isSuperseeded}
                        checked={item.nowAvailabilityChecked ? true : false}
                        onChange={() => {
                          if (enquiryAction !== "view" && item["nowAvailability"]) {
                            let productsTemp: any = JSON.parse(
                              JSON.stringify(products)
                            );
                            productsTemp[item.actualIndex][
                              "nowAvailabilityChecked"
                            ] = !item.nowAvailabilityChecked;
                            setproducts([...productsTemp]);
                            isChangeCheckBox(true);
                            setcheckedAllNow(false);
                          }
                        }}
                      />{" "}
                      {isAvailabiityChecked && item["nowAvailability"]
                        ? item["nowAvailability"]
                        : "0"}
                    </div>
                    {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") ?
                      <div className="innergreyLabelTable col-4">
                        <input type="checkbox"
                          checked={item.hrs24AvailabilityChecked ? true : false}
                          onChange={() => {
                            if (enquiryAction !== "view" && item["hrs24Availability"]) {
                              let productsTemp: any = JSON.parse(
                                JSON.stringify(products)
                              );
                              productsTemp[item.actualIndex][
                                "hrs24AvailabilityChecked"
                              ] = !item.hrs24AvailabilityChecked;
                              setproducts([...productsTemp]);
                              isChangeCheckBox(true);
                              setChecked24hrs(false);
                            }
                          }}
                        />
                        {" "}
                        {isAvailabiityChecked && item["hrs24Availability"]
                          ? item["hrs24Availability"]
                          : "0"}
                      </div>
                      : ""}
                    <div className={customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") ? "disableInnergreyLabelTable col-4" : "innergreyLabelTable col-4"}>
                      <input type="checkbox"
                        disabled={sessionStorage.getItem('userType') === 'FSE' ? true : (isDisabledBo(item) && ((accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) == false ? false : item.isSuperseeded) || customerTierType && customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer" || customerTierType && customerTierType === "Dealer" &&
                          (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                        checked={sessionStorage.getItem('userType') === 'FSE' ? false : (item.airAvailabilityChecked && customerTierType && (customerTierType.toLowerCase() !== "workshop" || customerTierType.toLowerCase() === "tpcdealer")) ? true : false}
                        onChange={() => {
                          if (enquiryAction !== "view" && item["airAvailability"]) {
                            let productsTemp: any = JSON.parse(
                              JSON.stringify(products)
                            );
                            productsTemp[item.actualIndex][
                              "airAvailabilityChecked"
                            ] = !item.airAvailabilityChecked;
                            setproducts([...productsTemp]);
                            isChangeCheckBox(true);
                            setcheckedAllBO(false);
                          }
                        }}
                      />{" "}
                      {isAvailabiityChecked && item["airAvailability"]
                        ? item["airAvailability"]
                        : "0"}
                    </div>
                  </div>
                </TableCell>
                <TableCell className="">
                  <div className="innergreyLabelTable">
                    {item.isValid
                      ? `${parseInt(item.dimensions.length)}x${parseInt(
                        item.dimensions.width
                      )}x${parseInt(item.dimensions.height)} | ${item.dimensions.grossWeight ? parseFloat(item.dimensions.grossWeight).toFixed(2) : '-'}`
                      : "-"}
                  </div>
                </TableCell>
                <TableCell className="">
                  <div className="innergreyLabelTable">
                    {item.isValid
                      ? `${parseFloat(item.dimensions.volume).toFixed(3)} cm`
                      : "-"}
                    <sup>3</sup>
                  </div>
                </TableCell>
              </TableRow>
            );
          } else {
            return null;
          }
        })
      }
    </React.Fragment >
  );
}


const Dashboard: React.FC = (props: any) => {
  const MAX_PARTS = 750;
  const classes = useStyles();
  const history: any = useHistory();
  const location: any = useLocation();
  let inputRefs: any = [];
  const [products, setproducts] = React.useState<any>([]);
  const [productsDataForSpecialRequest, setProductsDataForSpecialRequest] = React.useState<any>([]);
  const [zeroProducts, setZeroProducts] = React.useState<any>([]);
  const [isZeroPricePopup, openZeroPricePopup] = React.useState(false);
  const [hideZeroMessage, setHideZeroMessage] = React.useState(false);
  const [isBackOrder, setIsBackorder] = React.useState(false);
  const [isQuantityUpdated, setIsQuantityUpdated] = React.useState(false);
  const [isCopy, setCopy] = React.useState(false);
  const [orderTotal, setorderTotal] = React.useState<any>(0);
  const dispatch: Dispatch<any> = useDispatch();
  const [productId, setproductId] = React.useState("");
  const [isQueue, setIsQueue] = React.useState(false);
  const [quantity, setquantity] = React.useState<any>('');
  const [isAvailabiityChecked, setisAvailabiityChecked] = React.useState(false);
  const [isDisableQuotation, setDisableQuotation] = React.useState(false);
  const [isEstimatedTotalIsLess, setIsEstimatedTotalIsLess] = React.useState(false);
  const [placeorderEnabled, setplaceorderEnabled] = React.useState(false);
  const [isAvailabiityCheckedAtTime, setisAvailabiityCheckedAtTime] =
    React.useState<any>(null);
  const [isEnquirySaved, setisEnquirySaved] = React.useState(false);
  const [estTotal, setEstimatedTotal] = React.useState<any>(0);
  const [enquiryData, setenquiryData] = React.useState<any>(null);
  const [getVerifyData, setGetVerifyData] = React.useState<any>(null);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const [openOrderSummary, setOpenOrderSummary] = React.useState(false);
  const [isAdminsetDefaultAddress, setIsAdminSetDefaultAddress] = React.useState(false);
  const [isUpdateMode, setisUpdateMode] = React.useState(false);
  const [isSelectedAll, setisSelectedAll] = React.useState(false);
  const [orderQueued, setorderQueued] = React.useState(false);
  const [isSpecialPrice, setIsSpecialPrice] = React.useState('');
  const [enquiryAction, setenquiryAction] = React.useState("");
  const [orderType, setOrderType] = React.useState("");
  const [individualPrice, setIndividialPrice] = React.useState([]);
  const [spParts, setSpParts] = React.useState([]);
  const [requestSPData, setRequestSPData] = React.useState<any>();
  const [isSpEnquiry, setIsSpEnquiry] = React.useState<boolean>(false);
  const [referenceNumber, setreferenceNumber] = React.useState("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [changeData, setchangeData] = React.useState<any>(null);
  const [deliveryType, setDeliveryType] = React.useState<any>('DLV');
  const [changingDeliveryTypeColor, setChangingDeliveryTypeColor] = React.useState<any>("white");
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [selectedDeliveryTypeError, setSelectedDeliveryTypeError] = React.useState(false);
  const [verificationData, setVerificationData] = React.useState<any>();
  const [invalidSelectedDeliveryType, setInvalidSelectedDeliveryType] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [brandRefNumbers, setbrandRefNumbers] = React.useState<any>({
    "2001": "",
    "2002": "",
    "2003": "",
    "2380": "",
    "2195": "",
  });
  const [deliveryBlockReason, setDeliveryBlockReason] = React.useState<any>({
    "2001": {
      deliveryBlockReason: '',
      deliveryBlockReasonCode: ''
    },
    "2002": {
      deliveryBlockReason: '',
      deliveryBlockReasonCode: ''
    },
    "2003": {
      deliveryBlockReason: '',
      deliveryBlockReasonCode: ''
    },
    "2380": {
      deliveryBlockReason: '',
      deliveryBlockReasonCode: ''
    },
    "2195": {
      deliveryBlockReason: '',
      deliveryBlockReasonCode: ''
    },
  });
  const [salesOffice, setSalesOffice] = React.useState("");
  const [availability, setAvailability] = React.useState("All");
  const [procuredPart, setprocuredPart] = React.useState<any>([]);
  const [materialNotExtendedPart, setMaterialNotExtendedPart] = React.useState<any>([]);
  const [invalidParts, setInvalidParts] = React.useState<any>([]);
  const [removedProductsLength, setRemovedProductsLength] = React.useState<any>(0)
  const [callAPI, setIscallAPI] = React.useState<any>(true);
  const [isInvalidPartsExist, setIsInvalidPartsExist] = React.useState<any>(false);
  const [procuredPartExist, setIsProcuredPartExist] = React.useState<boolean>(false);
  const [materialNotExtendedExist, setIsMaterialNotExtendedExist] = React.useState<boolean>(false);
  const [showBackOrderConfirmation, setshowBackOrderConfirmation] = React.useState<any>(false);
  const [showPlaceOrderConfirmationPopup, setShowPlaceOrderConfirmationPopup] = React.useState<any>(false);
  const [actualProductLength, setActualProductLength] = React.useState<any>();
  const { enquiryInfo, enquirySessionId
    , getOrderLogsDetails
  }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );
  const enquiryNumber = get(enquiryInfo, 'data[0].enquiryNumber', '');
  const [openDialog, setOpenDialog] = React.useState(false);

  const { enquiryId }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );

  let getOrderLogsDetails1: any = {}

  const getOrderLogsData = (enquiryDetails: any) => {
    const allOrderLogs: any = enquiryDetails?.data.flatMap((e: any) => e.orderLogs || []);
    getOrderLogsDetails1 = { data: allOrderLogs }
    dispatch(setOrderLogs(getOrderLogsDetails1));
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const [orderData, setorderData] = React.useState<any>(null);
  const [checkedAllNow, setcheckedAllNow] = React.useState<any>(false);
  const [checked24hrs, setChecked24hrs] = React.useState<any>(false);
  const [checkedAllBO, setcheckedAllBO] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [changeCheckBox, isChangeCheckBox] = React.useState<any>(false);
  const [dlEmails, setDlEmails] = React.useState<string>("");
  const [importOes, setOes] = React.useState(false);
  const [openSpecialPrice, setOpenSpecialPrice] = React.useState<any>(false)
  const [checkAvailabilityButtonText, setCheckAvailabilityButtonText] = React.useState<any>('Check Availability and Price');
  const { favoritesList }: any = useSelector((state: any) => state.favorites);
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups);
  let materialGroupsData = get(materialGroups, 'data.data', null);
  let materialGroupsDataOriginal = get(materialGroups, 'data.data', null);
  let materialGroupsDataTPC = get(materialGroups, 'data.data', null);

  const { dealerDetails, ddlValues, getCustomerDetailsBySapId }: any = useSelector((state: any) => state.dealer);
  const { requestAccessData, viewSpecialPriceDetails, isSPRModifyData }: any = useSelector((state: any) => state.requestSpecialPrice);
  const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { viewingFromOrderHistory }: any = useSelector((state: any) => state.orderHistory)
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const customerAccountType = get(profileDetails, 'data.data.customerInfo.customerAccountType', '');
  const companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data) ? dealerDetails.data.data.organizationIds : null;
  const brandIds = companyBrands && companyBrands.map((id: any) => id.organizationId);
  let deliveryTypeData: any;
  if (sessionStorage.getItem('userType') !== 'FSE') {
    deliveryTypeData = profileDetails?.data?.data?.customerInfo?.deliveryType;
  }
  const companyCurrencyType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType ? profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType : 'AED'
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const currentTier = sessionStorage.getItem('currentTier');
  const [accessData, setAccessData] = React.useState<any>();
  const { enquiryList, enquiryStatus } = useSelector((state: any) => state.orderEnquiry);
  const [userMasterId, setUserMasterId] = React.useState<any>();

  const [requestStatus, setRequestStatus] = React.useState<any>();
  const [estmateTotal, setEstmateTotal] = React.useState<any>();
  const [requestedTotalType, setRequestedTotalType] = React.useState<any>();
  const [requestedCalculatedTotalType, setRequestedCalculatedTotalType] = React.useState<any>();
  const [requestedCaluclatedTotalPrice, setRequestedCaluclatedTotalPrice] = React.useState<any>();
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [showExpired24Hrs, setShowExpired24Hrs] = React.useState<boolean>(false);
  const [showReorderEnquiry, setShowReorderEnquiry] = React.useState<boolean>(false);
  const [copyCreateEnquiry, setCopyCreateEnquiry] = React.useState(false);
  const [isViewingFromOrderHistory, setViewigFromOrderHistory] = React.useState<boolean>(false);
  const [showRequestQuotationDialog, setShowRequestQuotationDialog] = React.useState<boolean>(false);
  const [statusSuccessChecked, setStatusSuccessChecked] = React.useState<boolean>(false);
  const [requestQuotationStatusChecked, setRequestQuotationStatusChecked] = React.useState<boolean>(false);
  const [masterIDData, setMasterIDData] = React.useState<any>();
  const [quotation, setQuotation] = React.useState<any>(false)
  const [isDeleted, setIsDelted] = React.useState(false);
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo?.userType) || "";
  const [multipleAddressData, setMultipleAddressData] = React.useState<any>('');
  const [collectionAddressData, setCollectionAddressData] = React.useState<any>();
  const [changingAddressTypeColor, setChangingAddressTypeColor] = React.useState<any>("white");
  const [addressId, setAddressId] = React.useState<any>();
  const [shiftToPartyId, setShiftToPartyId] = React.useState<any>();
  const [openChooseCustomerDialog, setOpenChooseCustomerDialog] = React.useState(false);
  const [openShippingAddDialog, setOpenShippingAddDialog] = React.useState(false);
  const [showConfirmDialogPopup, setShowConfirmDialogPopup] = React.useState<boolean>(false);
  const [openOrderLogPopup, setOpenOrderLogPopup] = React.useState(false);

  let collectionPoints: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.collectionPointsOfOrder && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints;
  if (sessionStorage.getItem('userType') === 'FSE') {
    collectionPoints = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.collectionPointsOfOrder && getCustomerDetailsBySapId.data.collectionPointsOfOrder.collectionPoints;
    // setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
  } else {
    collectionPoints = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.collectionPointsOfOrder && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints;
  }

  let sapCustomerId: any = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  if (sessionStorage.getItem('userType') === 'FSE') {
    sapCustomerId = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId
  }

  const featureAccessibility = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.accessParameter && profileDetails.data.data.accessParameter.featureAccessibility);

  const shipToPartyFeatureAccess = sessionStorage.getItem('userType') === 'FSE' ? true : get(profileDetails, 'data.data.customerInfo.shipToPartyFeatureAccess', '');
  const [newAddressData, setAddressData] = React.useState(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
    : get(
      profileDetails,
      "data.data.customerInfo.addresses",
      ""
    ));

  const [customerTierType, setCustomerTierType] = React.useState(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType
    : get(profileDetails, 'data.data.customerInfo.customerTierType', ''));

  const [isOrderEnquiryCreated, setIsOrderEnquiryCreated] = React.useState<boolean>(false);
  const [onClickOfChangeButton, setOnClickOfChangeButton] = React.useState(false);
  const getChoosenCustomerName: any = sessionStorage.getItem("CompanyName");
  const getChoosenCustomerSapCustomerId = sessionStorage.getItem('selectedSapCustomerId');
  const getSelectedOrderType = sessionStorage.getItem('orderType');
  const getSelectedSO = sessionStorage.getItem('selectOffice');
  const getSelectedOfficeTitle = sessionStorage.getItem('selectedOfficeTitle');
  const getFinalOrderType: any = sessionStorage.getItem('finalOrderType');
  const getFinalSelectOffice: any = sessionStorage.getItem('finalSelectOffice');
  const getOrderCreatedBy: any = sessionStorage.getItem('orderCreatedBy');
  const getOrderCreatedByTitle = sessionStorage.getItem('orderCreatedByTitle');
  const selectedSAPIDCustomerType = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType;
  const [getCustomerNameonView, setGetCustomerNameonView] = React.useState<any>('');
  const [getCustomerSapIdonView, setGetCustomerSapIdonView] = React.useState<any>('');
  const companyCurrencyTypeByFSE: any = sessionStorage.getItem('companyCurrencyType');
  const isTpcDealer = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.isTpcDealer;
  const [confirmOrderChecked, setConfirmOrderChecked] = React.useState<boolean>(false);
  const TPCRegionDetails = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.TPCRegionDetails;
  const [getLatestOrderCount, setGetLatestOrderCount] = React.useState(false);
  const [subEnquiriesData, setSubEnquiriesData] = React.useState<any>([]);
  const [isDisableOrderLogButton, setIsDisableOrderLogButton] = React.useState<any>(false);
  const resetOrderLogCount: any = sessionStorage.getItem('reset-the-orderlog-count');
  const [orderLogBadgeCount, setOrderLogBadgeCount] = React.useState<any>();

  //<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< EXISTING CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // let enquiryMappingReverse: any = {};

  // materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
  //   enquiryMappingReverse[group.make] = group.materialGroup.toString();
  // })

  // let enquiryMapping: any = {};

  // materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
  //   group.materialGroup.forEach((item: any) => {
  //     enquiryMapping[item] = group.make;
  //   })
  // })

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NEW CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  useEffect(() => {
    dispatch(selectedParts(products, parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2), enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""));
    // let isSpecialPrice = false
    // products.forEach((ele: any) => {
    //   if (ele.hasOwnProperty("selected")) {
    //     if (ele.selected) {
    //       isSpecialPrice = true
    //     }
    //   }
    // })
    // if (isSpecialPrice) {
    //   setSpecialPrice(true)
    // } else if (isSpecialPrice === false) {
    //   setSpecialPrice(false)
    // }
  }, [products])

  const deleteCall = async (enquiryId: any) => {
    await deleteProblemParts(enquiryId ? enquiryId : enquiryData && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId, invalidParts, false);
  }

  useEffect(() => {
    if ((enquiryId && callAPI) || (invalidParts && invalidParts.length > 0 && callAPI)) {
      if (removedProductsLength !== invalidParts.length) {
        deleteCall(enquiryId ? enquiryId : enquiryData && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId)
        // deleteProblemParts(enquiryId ? enquiryId : enquiryData && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId, invalidParts, false);
      }
    }
  }, [enquiryId, invalidParts]);

  useEffect(() => {
    if (sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses && !openShippingAddDialog) {
      if (window.location.pathname.includes('viewenquiry') && !openChooseCustomerDialog && getCustomerDetailsBySapId && multipleAddressData && multipleAddressData !== '' && getCustomerDetailsBySapId.data) {
        let isAddressExist: any = getCustomerDetailsBySapId.data.addresses.some((obj: any) => obj.shiftToPartyCustomerNumber === multipleAddressData)
        if (isAddressExist) {
          setAddressData(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
            : get(
              profileDetails,
              "data.data.customerInfo.addresses",
              ""
            ));
        } else {
          let address = {
            shiftToPartyCustomerNumber: multipleAddressData
          }
          setAddressData([...getCustomerDetailsBySapId?.data?.addresses, address])
          let e = { target: { value: address?.shiftToPartyCustomerNumber } }
          handleChangeAddress(e)
        }
      } else {
        setAddressData(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
          : get(
            profileDetails,
            "data.data.customerInfo.addresses",
            ""
          ));
      }
    }
    setCustomerTierType(sessionStorage.getItem('userType') === 'FSE' ? getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType
      : get(profileDetails, 'data.data.customerInfo.customerTierType', ''));
    if (sessionStorage.getItem('userType') === 'FSE') {
      sessionStorage.setItem('customerId', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId)
      sessionStorage.setItem('customerName', getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName)
    }
  }, [getCustomerDetailsBySapId, multipleAddressData])

  const isShowSPR = () => {
    if ((currentTier && currentTier.toLowerCase() === 'gold') || (currentTier && currentTier.toLowerCase() === 'platinum') || (currentTier && currentTier.toLowerCase() === 'elite')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (products.length !== actualProductLength && !isAvailabiityChecked) {
      setDisableQuotation(true);
    } else {
      setDisableQuotation(false);
    }
  }, [products, actualProductLength])

  useEffect(() => {
    if (sessionStorage.getItem('userType') !== 'FSE') {
      setDeliveryType(deliveryTypeData);
    }
  }, [deliveryTypeData])

  useEffect(() => {
    if (!enquirySessionId) {
      getUniqueEnquirySessionId(getUniqueTimestamp());
    }
  }, [enquirySessionId])

  useEffect(() => {
    let id: any
    enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
      <>
        {enquiryList.data.data.map((enquiry: any, index: any) => {
          return (
            id = sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
          );
        })}
      </>
    )
    setUserMasterId(id)
  }, [enquiryList]);

  const getOrderLogCount = (orderLogs: any) => {
    let totalUnreadCount = 0;
    orderLogs && orderLogs.data.forEach((entry: any) => {
      // totalUnreadCount += entry.unReadCount ? entry.unReadCount : entry.count;
      totalUnreadCount += entry?.unReadCount;
    });
    return totalUnreadCount;
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      // if (history.location.pathname !== "/viewenquiry") {
      //   dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
      // }
      if ((customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) || (profileDetails.data.data.customerInfo && (profileDetails.data.data.customerInfo.customerTierType === 'Workshop' || profileDetails.data.data.customerInfo.customerTierType === 'tpcdealer'))) {
        getDLEmailByRegionResult()
      }
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (ddlValues && ddlValues.data && ddlValues.data.data && ddlValues.data.data.deliveryBlockReason && ddlValues.data.data.deliveryBlockReason.length > 0) {
      } else {
        dispatch(getDeliveryBlockReason());
      }
    }
  }, [profileDetails]) // eslint-disable-line

  function is24hrExist() {
    if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && products && products.length > 0) {
      return products.some((obj: any) => obj.hrs24AvailabilityChecked === true);
    }
  }

  useEffect(() => {
    let viewingFromid: any = viewingFromOrderHistory && viewingFromOrderHistory.data && viewingFromOrderHistory.data && viewingFromOrderHistory.data.id
    let b2bId: any = enquiryData && enquiryData[0] && enquiryData[0].B2bEnquiryId && enquiryData[0].B2bEnquiryId;
    // viewingFromid === b2bId &&
    if (enquiryAction === "view" && viewingFromOrderHistory && viewingFromOrderHistory.data && viewingFromOrderHistory.data && viewingFromOrderHistory.data.isViewingFromOrderHistory) {
      setViewigFromOrderHistory(true)
    } else {
      setViewigFromOrderHistory(false)
    }
  }, [viewingFromOrderHistory, enquiryData && enquiryData[0] && enquiryData[0].B2bEnquiryId && enquiryData[0].B2bEnquiryId])

  const getDLEmailByRegionResult = async () => {
    let DLEmailByRegionResult: any = await dispatch(getDLEmailByRegion(region))
    if (!_.isEmpty(DLEmailByRegionResult.data)) {
      let emailList: Array<any> = DLEmailByRegionResult.data
      let emails: Array<any> = emailList.map(el => el.partsAdvisoreEmail);
      setDlEmails(emails.toString())
    }
  }

  useEffect(() => {
    if (verificationData &&
      verificationData.data &&
      Array.isArray(verificationData.data) &&
      verificationData.data.length > 0) {
      getEstimatedTotalSap(true, verificationData);
      setGetVerifyData(verificationData);
    }
  }, [verificationData])

  let enquiryMappingReverse: any = {};
  let temp: any = []
  let tempTPC: any = []

  if (customerTierType && customerTierType.toLowerCase() === 'tpcdealer') {
    if (materialGroupsDataTPC !== null) {
      if (materialGroupsData !== null) {
        for (let item of materialGroupsData) {
          // if (item.make != "OES") {
          tempTPC.push(item)
          // }
        }
      }
    }
  }
  if (customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer") {
    if (materialGroupsData !== null) {
      for (let item of materialGroupsData) {
        // if (item.make != "OES") {
        temp.push(item)
        // }
      }
    }
  } else if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
    let selectedBrand: any;
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.organizationIds)
        selectedBrand = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.brandAccess
    } else {
      if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.selectedBrand)
        selectedBrand = profileDetails?.data?.data?.customerInfo?.selectedBrand
    }

    if (sessionStorage.getItem('userType') === 'FSE') {
      // if (selectedBrand && selectedBrand.length == 1 && (selectedBrand[0].organizationId === "2195" || selectedBrand[0].salesOrganization === '2195')) {
      let brand = _.filter(materialGroupsData, (i) => {
        return i.orgId !== '2003'
      });
      if (!_.isEmpty(brand)) {
        temp = brand;
      }
      let mergeMaterialGroup: any = [];
      for (let i of temp) {
        mergeMaterialGroup = mergeMaterialGroup.concat(i.materialGroup)
      }
      let finalMaterialGroup = [{
        displayName: "Tradepoint",
        make: "OES",
        materialGroup: mergeMaterialGroup,
        orgId: "2195"
      }]
      temp = finalMaterialGroup
    } else {
      if (selectedBrand && selectedBrand.length == 1 && selectedBrand[0].organizationId === "2195") {
        let brand = _.filter(materialGroupsData, (i) => {
          return i.orgId == "2195"
        });
        if (!_.isEmpty(brand)) {
          temp.push(brand[0])
        }
      } else if (true) {
        // selectedBrand ?? [];
        if (Array.isArray(selectedBrand)) {
          for (let item of selectedBrand) {
            let brand = _.filter(materialGroupsData, (i) => {
              return i.orgId == item.organizationId
            });
            if (!_.isEmpty(brand)) {
              temp.push(brand[0])
            }
          }
        }
        let mergeMaterialGroup: any = [];
        for (let i of temp) {
          mergeMaterialGroup = mergeMaterialGroup.concat(i.materialGroup)
        }
        let finalMaterialGroup = [{
          displayName: "Tradepoint",
          make: "OES",
          materialGroup: mergeMaterialGroup,
          orgId: "2195"
        }]
        temp = finalMaterialGroup
      }
    }
  }
  if (temp && temp.length > 0) {
    materialGroupsData = temp;
  }

  if (tempTPC && tempTPC.length > 0) {
    materialGroupsDataTPC = tempTPC;
  }


  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    enquiryMappingReverse[group?.make] = group?.materialGroup?.toString();
  })

  let enquiryMapping: any = {};
  let enquirySubBrand: any = {};
  let enquiryMappingTPC: any = {};
  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group?.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group?.make;
    })
  })

  // enquirySubBrand = materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.reduce((acc, item) => {
  //   return { ...acc, ...item.materialGroupforSubbrand };
  // }, {});

  if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
    enquirySubBrand = materialGroupsDataOriginal?.reduce((acc: any, item: any) => {
      if (item.materialGroupforSubbrand) {
        Object.assign(acc, item.materialGroupforSubbrand);
      }
      return acc;
    }, {});
  } else {
    enquirySubBrand = materialGroupsData?.reduce((acc: any, item: any) => {
      if (item.materialGroupforSubbrand) {
        Object.assign(acc, item.materialGroupforSubbrand);
      }
      return acc;
    }, {});
  }


  materialGroupsDataTPC && Array.isArray(materialGroupsDataTPC) && materialGroupsDataTPC.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMappingTPC[item] = group.make;
    })
  })

  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");

  let assignedBrandsData: any = [];
  let address: any = [];
  let addressData: any = [];
  if (sessionStorage.getItem('userType') === 'FSE') {
    assignedBrandsData = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.brandAccess
    address = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
    addressData = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
  } else {
    assignedBrandsData = get(
      profileDetails,
      "data.data.basicInfo.salesOrganization",
      []
    );
    address = get(
      profileDetails,
      "data.data.customerInfo.addresses",
      ""
    );
    addressData = get(
      profileDetails,
      "data.data.customerInfo.addresses",
      ""
    );
  }

  const firstName = get(
    profileDetails,
    "data.data.basicInfo.firstName",
    ""
  );

  const lastName = get(
    profileDetails,
    "data.data.basicInfo.lastName",
    ""
  );

  const region = get(
    profileDetails,
    "data.data.basicInfo.region",
    ""
  );

  const b2cUserId = get(
    profileDetails,
    "data.data.basicInfo.b2cUserId",
    ""
  );

  const firstAddress = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.firstAddress === true && ele.dafault === true)

  if (address && typeof address != 'string') {
    if (typeof address == 'object') {
      let data = enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.length > 0 && enquiryInfo.data.data[0].shiftToPartyCustomerNumber ? address.find((item: any) => item.shiftToPartyCustomerNumber === enquiryInfo.data.data[0].shiftToPartyCustomerNumber) : address.find((item: any) => item.firstAddress)
      if (data) {
        address = `${data.shiftToPartyCustomerNumber}, ${data.transportationZone ? `${data.transportationZone},` : ''} ${data.location ? `${data.location},` : data.companyName ? `${data.companyName},` : ''} ${data.city ? `${data.city},` : ''} ${data.streetName}`
        // `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
      } else {
        address = ``
      }
    } else {
      address = ""
    }
  }

  const defaultAddressId = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.defaultAddressId && profileDetails.data.data.customerInfo.defaultAddressId;
  const handleAvailabilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAvailability(event.target.value);
  };

  useEffect(() => {
    if (enquiryAction === 'view' || enquiryAction === 'update') {
      if (enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.length > 0) {

        // dispatch(getEnquiryDetails(customerTierType,
        //   enquiryInfo.data.data[0].B2bEnquiryId,
        //   (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
        // ));

        // dispatch(getOrderLogs(enquiryInfo.data.data[0].B2bEnquiryId));

        setMultipleAddressData(enquiryInfo.data.data[0].hasOwnProperty('shiftToPartyCustomerNumber') ? enquiryInfo.data.data[0].shiftToPartyCustomerNumber : enquiryInfo.data.data[0].customerId);
        setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
        if (!customerTierType) {
          if (enquiryInfo.data.data[0].customerType === 'int. business') {
            setCustomerTierType("Int. Business");
          } else {
            if (enquiryInfo.data.data[0].customerType) {
              setCustomerTierType(enquiryInfo.data.data[0].customerType.charAt(0).toUpperCase() + enquiryInfo.data.data[0].customerType.slice(1));
            }
          }
        }
      }
    } else {
      let defaultAddress = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.id === defaultAddressId)
      let firstAddresses = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.firstAddress === true && ele.dafault === true)
      if (customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer") {
        if (defaultAddress && defaultAddress.shiftToPartyCustomerNumber)
          setMultipleAddressData(defaultAddress && defaultAddress.shiftToPartyCustomerNumber);
      }
      if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "DLV") {
        if (defaultAddress && defaultAddress.shiftToPartyCustomerNumber)
          setMultipleAddressData(defaultAddress && defaultAddress.shiftToPartyCustomerNumber);
      } else if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC") {
        setMultipleAddressData(firstAddresses && firstAddresses.shiftToPartyCustomerNumber);
        setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
      }
    }
  }, [enquiryAction, profileDetails, getCustomerDetailsBySapId, enquiryInfo])

  useEffect(() => {
    if (enquiryAction === 'view' || enquiryAction === 'update') {
      if (enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.length > 0) {
        enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.map((ele: any) => {
          setGetCustomerNameonView(ele && ele.customerName);
          setGetCustomerSapIdonView(ele && ele.customerId);
          sessionStorage.setItem("CompanyName", ele && ele.customerName);
          sessionStorage.setItem("selectedSapCustomerId", ele && ele.customerId);
          sessionStorage.setItem("orderCreatedBy", ele && ele.salesData && ele.salesData.orderCreatedFor);
          sessionStorage.setItem("selectedOfficeTitle", ele && ele.salesData && ele.salesData.salesOffice);
          sessionStorage.setItem("selectOffice", ele && ele.salesData && ele.salesData.salesOffice);
          sessionStorage.setItem("orderType", ele && ele.salesData && ele.salesData.orderType);
          sessionStorage.setItem("customerTypeofCustomer", ele && ele.customerType && ele.customerType.toLowerCase());
          sessionStorage.setItem('teSalesOffice', ele && ele.salesData && ele.salesData.salesOffice)
        })
      }
    }
  }, [enquiryAction, enquiryInfo, profileDetails])

  const handleopenCloseOrderSummary = (open?: any) => {
    setOpenOrderSummary(open ? true : false);
    open && window.history.replaceState(null, "title", "/ordersummarypayment");
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleCloseSpecialPrice = () => {
    // setOpenSpecialPrice(false);
  }

  const handleChangeDeliveryType = (e: any) => {
    setDeliveryType(e.target.value);
    if (e.target.value === "SELF-PIC") {
      setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
    }
    setChangingDeliveryTypeColor("blanchedalmond")
    setTimeout(() => {
      setChangingDeliveryTypeColor("white")
    }, 3000);
  }

  React.useEffect(() => {
    dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
    if (sessionStorage.getItem('isCopyandCreate') === 'true') {
      checkSessionStorage(true);
    } else {
      checkSessionStorage();
      setMasterIDData(null);
      setStatusSuccessChecked(false);
      return () => {
        dispatch(requestModifyData(false))
      }
    }
  }, [location.pathname]);

  // function getRemovedProducts(enquiryDetailsData: any) {
  //   if (!enquiryDetailsData || !Array.isArray(enquiryDetailsData)) {
  //     return null; // Return null if the structure is invalid
  //   }

  //   for (const item of enquiryDetailsData) {
  //     if (item.removedProducts && Array.isArray(item.removedProducts) && item.removedProducts.length > 0) {
  //       return item.removedProducts; // Return the first matching removedProducts
  //     }
  //   }
  // }

  function getRemovedProducts(enquiryDetailsData: any) {
    if (!enquiryDetailsData || !Array.isArray(enquiryDetailsData)) {
      return null; // Return null if the structure is invalid
    }

    let largestObject: any = null; // To store the object with the largest removedProducts array
    let maxLength = 0; // To keep track of the largest length

    for (const item of enquiryDetailsData) {
      if (item.removedProducts && Array.isArray(item.removedProducts)) {
        if (item.removedProducts.length > maxLength) {
          maxLength = item.removedProducts.length;
          largestObject = item.removedProducts; // Update the largest object
        }
      }
    }

    return largestObject; // Return the object with the largest removedProducts
  }

  const deleteProblemParts = async (b2bEnquiryId: any, invalidp?: any, isManualDelete: any = false) => {
    let parts: any = invalidp;
    if (!isManualDelete) {
      parts = invalidp && invalidp.length > 0 ? invalidp : [...invalidParts];
    }
    let removedProduct: any = parts.map((data: any) => (
      {
        quantity: data.quantity,
        invalidMaterialGroup: data.invalidMaterialGroup,
        productId: data.productId,
        isZeroPrice: isAvailabiityChecked ? (data.nsp && data.nsp === "0.00") || (data.nsp && parseFloat(data.nsp) === 0) ? true : false : false,
        noAccessToBrand: data.isMaterialNotExtended ? false : !data.invalidMaterialGroup ? false : `${enquiryMapping[`${formatMaterialGroup(data.invalidMaterialGroup)}`]}` !== 'undefined' ? true : true,
        enquiryReferenceNumber: data.enquiryReferenceNumber !== '' ? data.enquiryReferenceNumber : referenceNumber,
        action: "D",
        isValid: false,
        status: data.isMaterialNotExtended ? 'Material Not Extended to Plant' : !data.invalidMaterialGroup ? 'Invalid Part' : `${enquiryMapping[`${formatMaterialGroup(data.invalidMaterialGroup)}`]}` != "undefined" ? `No access to ${enquiryMapping[`${formatMaterialGroup(data.invalidMaterialGroup)}`]} Brand` : 'No access to Brand',
        productIdDescription: data.productIdDescription,
        orgId: data.orgId && data.orgId !== '' ? data.orgId : `${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(data.invalidMaterialGroup)}`]}`]}` === 'undefined' ? '' : `${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(data.invalidMaterialGroup)}`]}`]}`,
        seq: data.seq ? data.seq : data.actualIndex,
        isManualDelete: isManualDelete
      }));
    let uniqueProducts: any;
    if (removedProduct && removedProduct.length > 0) {
      // Remove duplicates based on seq and productId
      uniqueProducts = removedProduct.filter((product: any, index: any, self: any) =>
        index === self.findIndex((p: any) =>
          p.seq === product.seq && p.productId === product.productId
        )
      );
    }
    let payload: any = {
      "b2bEnquiryId": enquiryId ? enquiryId : b2bEnquiryId,
      "removedProduct": uniqueProducts,
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      }
    }
    if ((b2bEnquiryId || enquiryId) && removedProduct && removedProduct.length > 0) {
      await dispatch(deleteInvalidParts(payload));
      // let savedData: any = await dispatch(getEnquiryDetails(customerTierType,
      //   enquiryId ? enquiryId : b2bEnquiryId ? b2bEnquiryId : enquiryData &&
      //     Array.isArray(enquiryData) &&
      //     enquiryData.length > 0 &&
      //     enquiryData[0].B2bEnquiryId
      //     ? enquiryData[0].B2bEnquiryId
      //     : enquiryId,
      //   (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
      // ));
      setIscallAPI(false);
      dispatch(setEnquiryId(null));
      // if (savedData && savedData.data && savedData.data.data && savedData.data.data.length > 0 && savedData.data.data[0].removedProducts) {
      //   setRemovedProductsLength(savedData.data.data[0].removedProducts.length)
      //   setInvalidParts([...savedData.data.data[0].removedProducts]);
      //   // setIscallAPI(false);
      // }
    }
  }

  const sendOrderLogsEmail = async (selectedItems: any) => {
    let data: any = {
      "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      selectedOrderLogs: selectedItems,
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      },
      "userName": firstName,
      "userId": b2cUserId,
      "enquirySessionId": `b2bLSR${enquirySessionId}`,
      "customerType": customerTierType,
      "b2bEnquiryId": enquiryData &&
        Array.isArray(enquiryData) &&
        enquiryData.length > 0 &&
        enquiryData[0].B2bEnquiryId,
      "masterReferenceNumber": enquiryData &&
        Array.isArray(enquiryData) &&
        enquiryData.length > 0 &&
        enquiryData[0].masterReferenceNumber || referenceNumber,
      "customerId": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("selectedSapCustomerId") : sessionStorage.getItem('customerId'),
      "customerName": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("CompanyName") : sessionStorage.getItem('customerName')
    }
    let res: any = await dispatch(sendEmailOrderLogs(data));
    if (res && res.error) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(res.error && typeof res.error === 'string' ? res.error : res.message && typeof res.message === 'string' ? res.message : 'Error to send email');
      handleopenCloseSnackbar(true);
    } else {
      setSuccessMessage(res && res.message && typeof res.message.message == 'string' ? res.message.message : 'Email sent successfully');
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
    }
  }

  const handleChangeAddress = (e: any) => {
    setMultipleAddressData(e.target.value);
    setCollectionAddressData(e.target.value);
    setChangingAddressTypeColor("blanchedalmond")
    setTimeout(() => {
      setChangingAddressTypeColor("white");
    }, 3000);
  }

  const getSelectedAddress = () => {
    if (multipleAddressData && multipleAddressData !== '' && newAddressData && newAddressData.length > 0) {
      let data: any = newAddressData.find((add: any) => add?.shiftToPartyCustomerNumber === multipleAddressData);
      if (data && Object.keys(data).length > 1)
        return `${data?.shiftToPartyCustomerNumber}, ${data?.transportationZone ? `${data?.transportationZone},` : ''} ${data?.location ? `${data?.location},` : data.companyName ? `${data.companyName},` : ''} ${data.city ? `${data?.city},` : ''} ${data?.streetName}`
    }
  }

  // React.useEffect(() => {
  //   checkSessionStorage();
  //   return () => {
  //     dispatch(requestModifyData(false))
  //   }
  // }, [location.pathname, getCustomerDetailsBySapId]);

  const showImportExcel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);

  const checkSessionStorage = async (iscopyandCreate?: any) => {
    if (iscopyandCreate || copyCreateEnquiry) {
      if (sessionStorage.getItem("partsData")) {
        let productsTemp: any = [];
        let partsData = sessionStorage.getItem("partsData");
        // sessionStorage.setItem("partsData", "")
        if (partsData) {
          partsData = JSON.parse(partsData);
          if (customerTierType && customerTierType !== '') {
            if (Array.isArray(partsData)) {
              partsData.map((item) => {
                if (item.productId && item.productId.trim()) {
                  let validParts: any = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === true) : [];
                  if (validParts && validParts.length < MAX_PARTS) {
                    productsTemp.push({
                      productId: item.productId ? item.productId.trim() : "",
                      quantity:
                        item.quantity && parseInt(item.quantity)
                          ? parseInt(item.quantity)
                          : 1,
                    });
                  } else {
                    setOpenSnackbarType("error");
                    setOpenErrorSnackbar(true);
                    setSuccessMessage('You can upload a maximum of only 750 valid lines');
                    handleopenCloseSnackbar(true);
                    // alert('error')
                  }
                }
              });
              commonInsertionLogic(productsTemp, false, true);
            }
          } else {
            if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
              let cusData: any = await dispatch(getCustomerDetailsByCustomerID(enquiryData && enquiryData.length > 0 && enquiryData[0].customerId))
              sessionStorage.setItem("companyCurrencyType", cusData && cusData.data && cusData.data.companyCurrencyType)
              if (cusData && cusData.data && cusData.data.customerTierType) {
                if (Array.isArray(partsData)) {
                  partsData.map((item) => {
                    if (item.productId && item.productId.trim()) {
                      productsTemp.push({
                        productId: item.productId ? item.productId.trim() : "",
                        quantity:
                          item.quantity && parseInt(item.quantity)
                            ? parseInt(item.quantity)
                            : 1,
                      });
                    }
                  });
                  commonInsertionLogic(productsTemp, false, true, cusData.data.customerTierType);
                }
              }
            } else {
              if (Array.isArray(partsData)) {
                partsData.map((item) => {
                  if (item.productId && item.productId.trim()) {
                    let validParts: any = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === true) : [];
                    if (validParts && validParts.length < MAX_PARTS) {
                      productsTemp.push({
                        productId: item.productId ? item.productId.trim() : "",
                        quantity:
                          item.quantity && parseInt(item.quantity)
                            ? parseInt(item.quantity)
                            : 1,
                      });
                    } else {
                      setOpenSnackbarType("error");
                      setOpenErrorSnackbar(true);
                      setSuccessMessage('You can upload a maximum of only 750 valid lines');
                      handleopenCloseSnackbar(true);
                    }
                  }
                });
                commonInsertionLogic(productsTemp, false, true);
              }
            }
          }
        }
        // sessionStorage.setItem("partsData", "");
      }
    } else {
      if (profileDetails && profileDetails?.data?.data?.customerInfo?.deliveryType === 'SELF-PIC') {
        setDeliveryType(profileDetails?.data?.data?.customerInfo?.deliveryType)
        setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
      } else if (profileDetails && profileDetails?.data?.data?.customerInfo?.deliveryType === 'DLV') {
        setDeliveryType('DLV')
      }
      let enquiryDataExisting: any = sessionStorage.getItem("enquiryModeDetails");
      if (sessionStorage.getItem('isImportFromOES') === 'true' && sessionStorage.getItem('userType') === 'FSE' && !enquiryDataExisting) {
        if (sessionStorage.getItem('isUpdate') !== 'true' && sessionStorage.getItem('isUpdate') !== '') {
          if (!openChooseCustomerDialog) {
            setOpenChooseCustomerDialog(true);
          }
        }
        if (sessionStorage.getItem("partsData")) {
          let productsTemp: any = JSON.parse(JSON.stringify(products));
          let partsData = sessionStorage.getItem("partsData");
          sessionStorage.setItem("partsData", "")
          if (partsData) {
            partsData = JSON.parse(partsData);
            if (Array.isArray(partsData)) {
              partsData.map((item) => {
                if (item.productId && item.productId.trim()) {
                  productsTemp.push({
                    productId: item.productId ? item.productId.trim() : "",
                    quantity:
                      item.quantity && parseInt(item.quantity)
                        ? parseInt(item.quantity)
                        : 1,
                  });
                }
              });
              commonInsertionLogic(productsTemp);
            }
          }
          sessionStorage.setItem("partsData", "");
        }
      } else {
        let enquiryDataExisting: any = sessionStorage.getItem("enquiryModeDetails");
        sessionStorage.setItem("enquiryModeDetails", "");
        if (enquiryDataExisting && JSON.parse(enquiryDataExisting)) {
          enquiryDataExisting = JSON.parse(enquiryDataExisting);
          setenquiryAction(enquiryDataExisting.mode);
          setOrderType(enquiryDataExisting.orderType);
          setQuotation(enquiryDataExisting && enquiryDataExisting.quotation && JSON.parse(enquiryDataExisting.quotation) ? true : false)
          setproducts([]);
          let savedData: any = await dispatch(
            getEnquiryDetails(customerTierType, enquiryDataExisting.masterId,
              (enquiryDataExisting && enquiryDataExisting.mode && enquiryDataExisting.mode === "view" && enquiryDataExisting && enquiryDataExisting.orderType && enquiryDataExisting.orderType !== "Cancelled") ? true : false, enquiryDataExisting && enquiryDataExisting.quotation ? true : false)
          );
          await getOrderLogsData(savedData?.data);
          let rmpro: any = await getRemovedProducts(savedData?.data?.data);
          setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : savedData?.data?.data[0].removedProducts ? savedData?.data?.data[0].removedProducts : []);
          setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : savedData?.data?.data[0].removedProducts?.length)
          // enquiry changed in case of problem parts start
          let hasEnquiryChanged;
          if (savedData.data && savedData.data.data && savedData.data.data.length > 0 && sessionStorage.getItem('userType') === 'FSE' && enquiryAction !== "view") {
            hasEnquiryChanged = savedData.data.data.some((item: any) => item.status && (item.status.toLowerCase() === "enquiry changed"));
          }
          if ((hasEnquiryChanged || sessionStorage.getItem("isProblemParts") === "true") && sessionStorage.getItem('userType') === 'FSE' && enquiryAction !== "view") {
            const productIds = savedData.data.data.flatMap((item: any) =>
              item.products.filter((product: any) => product.isMaterialNotExtended && product.productId)
                .map((product: any) => product.productId));
            if (productIds && productIds.length > 0) {
              setMaterialNotExtendedPart(productIds);
              openZeroPricePopup(true);
              setisAvailabiityChecked(true);
              setIsQueue(false);
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
            }
          }
          // enquiry changed in case of problem parts end

          if (savedData.data && savedData.data.data && savedData.data.data.length > 0 && savedData.data.data[0].status && savedData.data.data[0].status === "Verification Completed" && sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('updateAPICall') === "true" && enquiryAction !== "view") {
            setLoading(true);
            setIsQueue(false);
            if (savedData.data.data[0].deliveryType === 'Onsite Delivery') {
              setDeliveryType('DLV')
            }
            if (savedData.data.data[0].deliveryType === 'Self Pickup') {
              setDeliveryType('SELF-PIC')
              setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
            }
            let enquiryDetailsQueueCheckData: any = await dispatch(verifyEnquiryQueueCheck(savedData.data.data[0].customerType || customerTierType, savedData.data.data[0].B2bEnquiryId, savedData.data.data[0].customerId));
            let verificationData: any = []
            let hasConfirmationData = true;
            if (enquiryDetailsQueueCheckData && Array.isArray(enquiryDetailsQueueCheckData.data)) {
              setLoading(false);
              enquiryDetailsQueueCheckData.data.map((item: any) => {
                if (item && item.confirmations && Array.isArray(item.confirmations) && item.confirmations.length > 0) {
                  verificationData.push({
                    verifiedData: item
                  })
                } else {
                  hasConfirmationData = false;
                }
              })
            }
            if (hasConfirmationData || (verificationData && verificationData.length > 0)) {
              await enquiryDataToEditMode(savedData && savedData.data && savedData.data.data, true, false, enquiryDataExisting.mode);
              setisAvailabiityChecked(true);
              setDisableQuotation(false);
              setorderQueued(false);
              setisAvailabiityCheckedAtTime(new Date());
              await verifyEnquiryDataToEditMode(verificationData, savedData.data.data, false, true);
              setLoading(false);
              // await updateOnly(savedData.data.data[0].products, undefined, undefined, savedData.data.data);
            }

            // Additional required if not remove
            if (savedData.data.data.length > 0 && (savedData.data.data[0].shiftToPartyCustomerNumber || savedData.data.data[0].customerId)) {
              setMultipleAddressData(savedData.data.data[0].hasOwnProperty('shiftToPartyCustomerNumber') ? savedData.data.data[0].shiftToPartyCustomerNumber : savedData.data.data[0].customerId)
            } else {
              let firstAddresses = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.firstAddress === true && ele.dafault === true)
              setMultipleAddressData(firstAddresses && firstAddresses.shiftToPartyCustomerNumber);
            }
            dispatch(getUniqueEnquirySessionId(savedData && savedData.data && savedData.data.data && savedData.data.data.length > 0 && savedData.data.data[0].b2bLostSalesReportId ? savedData.data.data[0].b2bLostSalesReportId : getUniqueTimestamp()));
            setenquiryData(savedData?.data?.data);
            await getOrderLogsData(savedData.data);
            // let orderLogCount: any = 0;

            // savedData.data.data.forEach((ele: any) => {
            //   if (ele && ele.orderLogs) {
            //     const { orderLogs } = ele
            //     orderLogCount += orderLogs?.unReadCount
            //   }
            // })
            // setOrderLogBadgeCount(orderLogCount);
            setGetVerifyData(savedData)

            // Additional required if not remove

          } else {
            let sum: any = 0;
            let alength: any = 0;
            let individualPrice: any = [];
            if (savedData.data && savedData.data.data && savedData.data.data.length > 0) {
              if (enquiryDataExisting.mode === "view" && (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE")) {
                let savedUserData: any = await dispatch(getCustomerDetailsByCustomerID(savedData.data.data[0].customerId));
                sessionStorage.setItem("companyCurrencyType", savedUserData && savedUserData.data && savedUserData.data.companyCurrencyType)
              }
              if (savedData.data.data[0].deliveryType === 'Onsite Delivery') {
                setDeliveryType('DLV')
              }
              if (savedData.data.data[0].deliveryType === 'Self Pickup') {
                setDeliveryType('SELF-PIC')
                setCollectionAddressData(collectionPoints && collectionPoints.length > 0 && collectionPoints[0].defineTPCounter)
              }
              savedData.data.data.map((data: any) => {
                if (data && data.estimatedTotal) {
                  individualPrice.push({ make: data.make, total: data.estimatedTotal })
                  sum = parseFloat(sum) + parseFloat(data.estimatedTotal)
                }
                if (data && data.products) {
                  alength = alength + data.products.length;
                }
              });
              if (savedData.data.data.length > 0 && (savedData.data.data[0].shiftToPartyCustomerNumber || savedData.data.data[0].customerId)) {
                setMultipleAddressData(savedData.data.data[0].hasOwnProperty('shiftToPartyCustomerNumber') ? savedData.data.data[0].shiftToPartyCustomerNumber : savedData.data.data[0].customerId)
              } else {
                let firstAddresses = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.firstAddress === true && ele.dafault === true)
                setMultipleAddressData(firstAddresses && firstAddresses.shiftToPartyCustomerNumber);
              }
              setEstimatedTotal(sum);
              setIndividialPrice(individualPrice)
              setActualProductLength(alength);
            }
            await enquiryDataToEditMode(savedData && savedData.data && savedData.data.data, true, false, enquiryDataExisting.mode);
            dispatch(getUniqueEnquirySessionId(savedData && savedData.data && savedData.data.data && savedData.data.data.length > 0 && savedData.data.data[0].b2bLostSalesReportId ? savedData.data.data[0].b2bLostSalesReportId : getUniqueTimestamp()));
            setenquiryData(savedData?.data?.data);
            setGetVerifyData(savedData)
          }
        } else if (sessionStorage.getItem("partsData")) {
          let productsTemp: any = JSON.parse(JSON.stringify(products));
          let partsData = sessionStorage.getItem("partsData");
          sessionStorage.setItem("partsData", "")
          if (partsData) {
            partsData = JSON.parse(partsData);
            if (Array.isArray(partsData)) {
              partsData.map((item) => {
                if (item.productId && item.productId.trim()) {
                  let validParts: any = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === true) : [];
                  if (validParts && validParts.length < MAX_PARTS) {
                    productsTemp.push({
                      productId: item.productId ? item.productId.trim() : "",
                      quantity:
                        item.quantity && parseInt(item.quantity)
                          ? parseInt(item.quantity)
                          : 1,
                    });
                  } else {
                    setOpenSnackbarType("error");
                    setOpenErrorSnackbar(true);
                    setSuccessMessage('You can upload a maximum of only 750 valid lines');
                    handleopenCloseSnackbar(true);
                  }
                }
              });
              commonInsertionLogic(productsTemp);
            }
          }
          sessionStorage.setItem("partsData", "");
        }
      }
    }
  };
  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      return null;
    } else {
      if (procuredPart && procuredPart.length > 0) {
        // ADD condition if all now bucket both procured parts always true
        setisAvailabiityChecked(true);
        setplaceorderEnabled(true);
      } else {
        setisAvailabiityChecked(true);
        setplaceorderEnabled(true);
      }
      return <span>{minutes}:{seconds} mins</span>;
    }
  };

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  // const isProcurementPartExist = () => {
  //   let data: any = [];
  //   if (products && products.length > 0 && procuredPart && procuredPart.length > 0) {
  //     products.map((p: any) => {
  //       if (procuredPart.includes(p.id)) data.push(true)
  //     })
  //   }
  //   if (data && data.length > 0) {
  //   }
  // }

  useEffect(() => {
    history.listen((location: any) => {
      if (history.action === 'PUSH') {
        setorderData(null);
        setVerificationData(null);
        setcheckedAllNow(false);
        setIsBackorder(false);
        setIsQuantityUpdated(false);
        setRemovedProductsLength(0);
        setIsInvalidPartsExist(false);
        setChecked24hrs(false);
        setIscallAPI(false);
        setcheckedAllBO(false);
        setproducts([]);
        setInvalidParts([]);
        setenquiryData(null);
        setMaterialNotExtendedPart([]);
        setproductId("");
        setorderTotal(0);
        setquantity('');
        setorderQueued(false)
        setisAvailabiityChecked(false);
        setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
        setisAvailabiityCheckedAtTime(null);
        setisEnquirySaved(false);
        setGetVerifyData(null);
        setSuccessMessage(null);
        setOpenSnackbar(false);
        openZeroPricePopup(false);
        setZeroProducts([]);
        setOpenSnackbarType("");
        setOpenOrderSummary(false);
        setisUpdateMode(false);
        setisSelectedAll(false);
        setenquiryAction("");
        setreferenceNumber("");
        setDeliveryType(deliveryTypeData);
        setchangeData(null);
        setbrandRefNumbers({
          "2001": "",
          "2002": "",
          "2003": "",
          "2380": "",
          "2195": "",
        });
        setDeliveryBlockReason({
          "2001": {
            deliveryBlockReason: '',
            deliveryBlockReasonCode: ''
          },

          "2002": {
            deliveryBlockReason: '',
            deliveryBlockReasonCode: ''
          },
          "2003": {
            deliveryBlockReason: '',
            deliveryBlockReasonCode: ''
          },
          "2380": {
            deliveryBlockReason: '',
            deliveryBlockReasonCode: ''
          },
          "2195": {
            deliveryBlockReason: '',
            deliveryBlockReasonCode: ''
          },
        });
        setSalesOffice("");
        sessionStorage.setItem('teSalesOffice', "");
        setAvailability("All");
        setprocuredPart([]);
        setEstimatedTotal(0);
        setIndividialPrice([]);
        setMultipleAddressData('');
        setCollectionAddressData('');
      }
    })
    return () => {
    }
  }, [])


  const resetData = () => {
    setorderData(null);
    setcheckedAllNow(false);
    setVerificationData(null);
    setChecked24hrs(false);
    setcheckedAllBO(false);
    setIscallAPI(false);
    setIsBackorder(false);
    setIsQuantityUpdated(false);
    setRemovedProductsLength(0);
    setIsInvalidPartsExist(false);
    isChangeCheckBox(false);
    setMasterIDData(null);
    setStatusSuccessChecked(false);
    setproducts([]);
    setInvalidParts([]);
    setproductId("");
    setorderTotal(0);
    setquantity('');
    setorderQueued(false)
    setisAvailabiityChecked(false);
    setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
    setisAvailabiityCheckedAtTime(null);
    setisEnquirySaved(false);
    setenquiryData(null);
    setGetVerifyData(null);
    setSuccessMessage(null);
    setOpenSnackbar(false);
    openZeroPricePopup(false);
    setZeroProducts([]);
    setOpenSnackbarType("");
    setOpenOrderSummary(false);
    setisUpdateMode(false);
    setisSelectedAll(false);
    setenquiryAction("");
    setreferenceNumber("");
    setDeliveryType(deliveryTypeData);
    setchangeData(null);
    setbrandRefNumbers({
      "2001": "",
      "2002": "",
      "2003": "",
      "2380": "",
      "2195": "",
    });
    setDeliveryBlockReason({
      "2001": {
        deliveryBlockReason: '',
        deliveryBlockReasonCode: ''
      },
      "2002": {
        deliveryBlockReason: '',
        deliveryBlockReasonCode: ''
      },
      "2003": {
        deliveryBlockReason: '',
        deliveryBlockReasonCode: ''
      },
      "2380": {
        deliveryBlockReason: '',
        deliveryBlockReasonCode: ''
      },
      "2195": {
        deliveryBlockReason: '',
        deliveryBlockReasonCode: ''
      },
    });
    setSalesOffice("");
    sessionStorage.setItem('teSalesOffice', "");
    setAvailability("All");
    setprocuredPart([]);
    setEstimatedTotal(0);
    setIndividialPrice([]);
    setMaterialNotExtendedPart([]);

  }

  const commonInsertionLogic = async (jsonData: any, fetchFromRedux?: any, isCopyandcreate?: any, tierType?: any, isCreateNew?: any) => {
    let negativeValuesPresent = false;
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
      let orgsAssignedToUser = "";
      if (assignedBrandsData && Array.isArray(assignedBrandsData)) {
        assignedBrandsData.map((item: any) => {
          if (item.salesOrganization) {
            orgsAssignedToUser += enquiryMappingReverse[`${orgIdBrandsMapping[`${item.salesOrganization}`]}`] + ",";
          }
        });
      }
      let tempData = jsonData.filter(
        (item: any) => item.productId && item.productId.trim()
      );
      let partsList: any = tempData.map((item) => {
        return item.productId.trim();
      });
      let searchProduct: any = [];
      tempData.map(item => {
        searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
      })
      let apiData: any = await dispatch(searchProducts(customerTierType ? customerTierType : tierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct, region));
      let reduxData: any = sessionStorage.getItem("tempProducts");
      let productsTemp: any = isCreateNew ? [] : isCopyandcreate ? [] : JSON.parse(
        JSON.stringify(
          fetchFromRedux && reduxData ? JSON.parse(reduxData) : products
        )
      );
      jsonData.map((item: any) => {

        let isInvalidBrand = "";
        let isPresent: any =
          apiData && apiData.data && apiData.data.length > 0
            ? apiData.data.find((responsePart: any) => {
              if (responsePart && item.productId && responsePart.productId && responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase()) {
                isInvalidBrand = responsePart?.attributes?.materialGroup?.value;
              }
              return (
                responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase() &&
                formatMaterialGroup(responsePart?.attributes?.materialGroup?.value) &&
                orgsAssignedToUser.includes(formatMaterialGroup(responsePart?.attributes?.materialGroup?.value))
              );
            })
            : null;
        if (
          !(
            item.quantity &&
            parseInt(item.quantity) &&
            parseInt(item.quantity) >= 1
          )
        ) {
          negativeValuesPresent = true;
        }
        if (
          item.quantity &&
          parseInt(item.quantity) &&
          parseInt(item.quantity) >= 1 &&
          item.productId &&
          item.productId.trim() &&
          productsTemp &&
          Array.isArray(productsTemp)
        ) {
          let containsItem = false;
          // productsTemp.map((product: any) => {
          //   if (
          //     product.productId &&
          //     item.productId.trim() &&
          //     product.productId === item.productId.trim()
          //   ) {
          //     product.quantity +=
          //       item.quantity && parseInt(item.quantity)
          //         ? parseInt(item.quantity) >= 1
          //           ? parseInt(item.quantity)
          //           : 0
          //         : 0;
          //     product.quantity = product.quantity && product.quantity.toString() && parseInt(product.quantity.toString().slice(0, 5))
          //     containsItem = false;
          //   }
          //   return product;
          // });
          if (!containsItem) {
            let validParts: any = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === true) : [];
            const mergedArray = [...productsTemp, ...invalidParts];
            const seqNumbers = mergedArray.map(obj => obj.seq);
            const filteredSeqNumbers = seqNumbers.filter(num => typeof num === 'number' && !isNaN(num));
            const maxSeq = filteredSeqNumbers.length === 0 ? seqNumbers.length : Math.max(...filteredSeqNumbers);

            const startingSerialNumber = isAvailabiityChecked || invalidParts && invalidParts.length > 0 ? maxSeq + 1 : productsTemp.length + 1;
            if (validParts && validParts.length < MAX_PARTS) {
              productsTemp.push({
                quantity:
                  item.quantity &&
                    parseInt(item.quantity) &&
                    parseInt(item.quantity) >= 1
                    ? parseInt(item.quantity)
                    : 1,
                seq: startingSerialNumber,
                invalidMaterialGroup: isInvalidBrand ? isInvalidBrand : "",
                productId:
                  isPresent && isPresent.productId
                    ? isPresent.productId
                    : item.productId.trim(),
                materialGroup:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.materialGroup
                    ? isPresent.attributes.materialGroup.value.toUpperCase()
                    : "",
                productIdDescription:
                  isPresent && isPresent.description
                    ? isPresent.description["EN"]
                    : "",
                productCost: 0,
                oesSubBrandName: isPresent &&
                  isPresent.attributes &&
                  isPresent.attributes.productHierarchy
                  ? isPresent.attributes.productHierarchy.value
                  : "",
                currency: sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED',
                unitOfMeasure:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.unitOfMeasure
                    ? isPresent.attributes.unitOfMeasure.value
                    : "",
                VAT: "",
                orgId: isPresent && isPresent.orgId ? isPresent.orgId : "",
                enquiryReferenceNumber: referenceNumber ? referenceNumber : "",
                isSpecialPriced: false,
                isSpecialPriceRequestPart: false,
                isRejected: false,
                nowAvailability: 0,
                nowAvailabilityChecked: false,
                hrs24Availability: 0,
                hrs24AvailabilityChecked: false,
                airAvailability: 0,
                airAvailabilityChecked: false,
                action: "I",
                dimensions: {
                  height:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.height
                      ? isPresent.attributes.height.value
                      : "",
                  width:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.width
                      ? isPresent.attributes.width.value
                      : "",
                  length:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.length
                      ? isPresent.attributes.length.value
                      : "",
                  volume:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.volume
                      ? isPresent.attributes.volume.value
                      : "",
                  grossWeight: isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.grossWeight
                    ? isPresent.attributes.grossWeight.value
                    : ""
                },
                attributes: [
                  {
                    name: "height",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.height
                        ? isPresent.attributes.height.value
                        : "",
                  },
                  {
                    name: "width",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.width
                        ? isPresent.attributes.width.value
                        : "",
                  },
                  {
                    name: "length",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.length
                        ? isPresent.attributes.length.value
                        : "",
                  },
                  {
                    name: "grossWeight",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.grossWeight
                        ? isPresent.attributes.grossWeight.value
                        : "",
                  },
                  {
                    name: "volume",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.volume
                        ? isPresent.attributes.volume.value
                        : "",
                  },
                ],
                plant: "",
                nowEnquiryItemNumber: isPresent && isPresent.nowEnquiryItemNumber ? isPresent.nowEnquiryItemNumber : '',
                nowProposedItemNumber: "",
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<
                hrs24EnquiryItemNumber: isPresent && isPresent.hrs24EnquiryItemNumber ? isPresent.hrs24EnquiryItemNumber : '',
                hrs24ProposedItemNumber: "",
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                airEnquiryItemNumber: isPresent && isPresent.airEnquiryItemNumber ? isPresent.airEnquiryItemNumber : '',
                airProposedItemNumber: "",
                isValid:
                  isPresent && isPresent.attributes.materialGroup &&
                    isPresent.attributes.materialGroup.value &&
                    enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`]
                    ? true
                    : false,
              });
            } else {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage('You can upload a maximum of only 750 valid lines');
              handleopenCloseSnackbar(true);
            }
          }
        } else {
          negativeValuesPresent = true;
        }
      });
      let inValidPartsData = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === false) : [];
      inValidPartsData.forEach((part: any, index: any) => {
        const actualIndex = productsTemp.findIndex((item: any) => item === part);
        part.actualIndex = actualIndex;
      });
      if (apiData && apiData.data && apiData.data.length > 0) {
        setIscallAPI(false);
      } else {
        setIscallAPI(true);
      }

      let combinedArray: any = isCopyandcreate ? inValidPartsData : [...inValidPartsData, ...invalidParts];
      let uniqueMap = new Map();
      combinedArray.forEach((item: any) => {
        // Generate a key based on the 'seq' value or the whole object if 'seq' is undefined
        let key = item.seq !== undefined ? item.seq.toString() : JSON.stringify(item);

        // If the key is not already in the map, add the item
        if (!uniqueMap.has(key)) {
          uniqueMap.set(key, item);
        }
      });

      // Create an array of unique objects
      combinedArray = Array.from(uniqueMap.values());
      // Sort the array based on the 'seq' property
      combinedArray.sort((a: any, b: any) => {
        if (a.seq !== undefined && b.seq !== undefined) {
          return a.seq - b.seq; // Sort based on 'seq' if both are defined
        } else if (a.seq !== undefined) {
          return -1; // 'a' has 'seq', so it should come before 'b'
        } else if (b.seq !== undefined) {
          return 1; // 'b' has 'seq', so it should come before 'a'
        } else {
          return 0; // Both 'a' and 'b' have no 'seq', maintain their relative order
        }
      });


      // let seqMap = new Map();
      // combinedArray.forEach(item => {
      //   if (!seqMap.has(item.seq)) {
      //     seqMap.set(item.seq, item);
      //   }
      // });

      // // Get values from the map to retrieve unique items based on 'seq'
      // combinedArray = Array.from(seqMap.values());
      // combinedArray.sort((a: any, b: any) => a.seq - b.seq);
      setInvalidParts(combinedArray);

      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      // setisAvailabiityChecked(false);
    }

    if (negativeValuesPresent) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Negative quantity values were ignored during the import`
      );
      handleopenCloseSnackbar(true);
    }
    // setTimeout(() => {
    setproductId("");
    setquantity('');
    // }, 1000);
  };

  const commonInsertionLogicforOES = async (jsonData: any, oesproducts: any) => {
    let negativeValuesPresent = false;
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
      let orgsAssignedToUser = "";
      if (assignedBrandsData && Array.isArray(assignedBrandsData)) {
        assignedBrandsData.map((item: any) => {
          if (item.salesOrganization) {
            orgsAssignedToUser += enquiryMappingReverse[`${orgIdBrandsMapping[`${item.salesOrganization}`]}`] + ",";
          }
        });
      }
      let tempData = jsonData.filter(
        (item: any) => item.productId && item.productId.trim()
      );
      let partsList: any = tempData.map((item) => {
        return item.productId.trim();
      });
      let searchProduct: any = [];
      tempData.map(item => {
        searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
      })
      let apiData: any = await dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct, region));
      let reduxData: any = sessionStorage.getItem("tempProducts");
      let productsTemp: any = JSON.parse(
        JSON.stringify(oesproducts)
      );
      jsonData.map((item: any) => {
        let isInvalidBrand = "";
        let isPresent: any =
          apiData && apiData.data && apiData.data.length > 0
            ? apiData.data.find((responsePart: any) => {
              if (responsePart && item.productId && responsePart.productId && responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase()) {
                isInvalidBrand = responsePart?.attributes?.materialGroup?.value;
              }
              return (
                responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase() &&
                formatMaterialGroup(responsePart?.attributes?.materialGroup?.value) &&
                orgsAssignedToUser.includes(formatMaterialGroup(responsePart?.attributes?.materialGroup?.value))
              );
            })
            : null;
        if (
          !(
            item.quantity &&
            parseInt(item.quantity) &&
            parseInt(item.quantity) >= 1
          )
        ) {
          negativeValuesPresent = true;
        }
        if (
          item.quantity &&
          parseInt(item.quantity) &&
          parseInt(item.quantity) >= 1 &&
          item.productId &&
          item.productId.trim() &&
          productsTemp &&
          Array.isArray(productsTemp)
        ) {
          let containsItem = false;
          // productsTemp.map((product: any) => {
          //   if (
          //     product.productId &&
          //     item.productId.trim() &&
          //     product.productId === item.productId.trim()
          //   ) {
          //     product.quantity +=
          //       item.quantity && parseInt(item.quantity)
          //         ? parseInt(item.quantity) >= 1
          //           ? parseInt(item.quantity)
          //           : 0
          //         : 0;
          //     product.quantity = product.quantity && product.quantity.toString() && parseInt(product.quantity.toString().slice(0, 5))
          //     containsItem = false;
          //   }
          //   return product;
          // });
          if (!containsItem) {
            let validParts: any = productsTemp && productsTemp.length > 0 ? productsTemp.filter((p: any) => p.isValid === true) : [];
            if (validParts && validParts.length < MAX_PARTS) {
              productsTemp.push({
                quantity:
                  item.quantity &&
                    parseInt(item.quantity) &&
                    parseInt(item.quantity) >= 1
                    ? parseInt(item.quantity)
                    : 1,
                invalidMaterialGroup: isInvalidBrand ? isInvalidBrand : "",
                productId:
                  isPresent && isPresent.productId
                    ? isPresent.productId
                    : item.productId.trim(),
                materialGroup:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.materialGroup
                    ? isPresent.attributes.materialGroup.value.toUpperCase()
                    : "",
                productIdDescription:
                  isPresent && isPresent.description
                    ? isPresent.description["EN"]
                    : "",
                productCost: 0,
                currency: sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED',
                oesSubBrandName: isPresent &&
                  isPresent.attributes &&
                  isPresent.attributes.productHierarchy
                  ? isPresent.attributes.productHierarchy.value
                  : "",
                unitOfMeasure:
                  isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.unitOfMeasure
                    ? isPresent.attributes.unitOfMeasure.value
                    : "",
                VAT: "",
                orgId: isPresent && isPresent.orgId ? isPresent.orgId : "",
                enquiryReferenceNumber: referenceNumber ? referenceNumber : "",
                isSpecialPriced: false,
                isSpecialPriceRequestPart: false,
                isRejected: false,
                nowAvailability: 0,
                nowAvailabilityChecked: false,
                airAvailability: 0,
                airAvailabilityChecked: false,
                action: "I",
                dimensions: {
                  height:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.height
                      ? isPresent.attributes.height.value
                      : "",
                  width:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.width
                      ? isPresent.attributes.width.value
                      : "",
                  length:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.length
                      ? isPresent.attributes.length.value
                      : "",
                  volume:
                    isPresent &&
                      isPresent.attributes &&
                      isPresent.attributes.volume
                      ? isPresent.attributes.volume.value
                      : "",
                  grossWeight: isPresent &&
                    isPresent.attributes &&
                    isPresent.attributes.grossWeight
                    ? isPresent.attributes.grossWeight.value
                    : ""
                },
                attributes: [
                  {
                    name: "height",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.height
                        ? isPresent.attributes.height.value
                        : "",
                  },
                  {
                    name: "width",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.width
                        ? isPresent.attributes.width.value
                        : "",
                  },
                  {
                    name: "length",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.length
                        ? isPresent.attributes.length.value
                        : "",
                  },
                  {
                    name: "grossWeight",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.grossWeight
                        ? isPresent.attributes.grossWeight.value
                        : "",
                  },
                  {
                    name: "volume",
                    value:
                      isPresent &&
                        isPresent.attributes &&
                        isPresent.attributes.volume
                        ? isPresent.attributes.volume.value
                        : "",
                  },
                ],
                plant: "",
                nowEnquiryItemNumber: isPresent && isPresent.nowEnquiryItemNumber ? isPresent.nowEnquiryItemNumber : '',
                nowProposedItemNumber: "",
                airEnquiryItemNumber: isPresent && isPresent.airEnquiryItemNumber ? isPresent.airEnquiryItemNumber : '',
                airProposedItemNumber: "",
                isValid:
                  isPresent && isPresent.attributes.materialGroup &&
                    isPresent.attributes.materialGroup.value &&
                    enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`]
                    ? true
                    : false,
              });
            } else {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage('You can upload a maximum of only 750 valid lines');
              handleopenCloseSnackbar(true);
            }
          }
        } else {
          negativeValuesPresent = true;
        }
      });
      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      // setisAvailabiityChecked(false);
    }
    if (negativeValuesPresent) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(
        `Negative quantity values were ignored during the import`
      );
      handleopenCloseSnackbar(true);
    }
    // setTimeout(() => {
    setproductId("");
    setquantity('');
    // }, 1000);
  };

  const getExportNSPEVValue = (item: any) => {
    // let data = spParts.filter((sp: any) => sp.nowEnquiryItemNumber == item.nowEnquiryItemNumber)
    let nspev: any = 0.00;
    if (enquiryAction === 'view' && item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN') {
      nspev = (parseFloat(getSellingPrice(item.nowApprovedPrice, isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestedTotalType === "part" && item.isSpecialPriceRequestPart && item["nowAvailability"] && requestStatus === 'Approved') {
        if (item.nowOfferedPriceType == 'byPercentage' && item["nowOfferedCalculatedPrice"] && item["nowOfferedCalculatedPrice"] !== '' && item["nowOfferedCalculatedPrice"] !== 'NaN') {
          nspev = parseFloat(getSellingPrice(item["nowOfferedCalculatedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else if (item.nowOfferedPriceType == 'byCurrency' && item["nowOfferedPrice"] && item["nowOfferedPrice"] !== '' && item["nowOfferedPrice"] !== 'NaN') {
          nspev = parseFloat(getSellingPrice(item["nowOfferedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else {
          nspev = (parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (requestedTotalType === "total" && isAvailabiityChecked && item["nspev"] && item["nowAvailability"] && item.isSpecialPriced === false &&
        requestStatus === 'Approved') {
        if (requestedCalculatedTotalType === "By AED") {

          let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
          let addedValue: any = (parseFloat(value) * item["nspev"]) / 100;
          nspev = (parseFloat(getSellingPrice((parseFloat(item["nspev"]) - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
          // nspev = (parseFloat(getSellingPrice((parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["nspev"]) / 100, isSellingPriceData, markupPercentage)).toFixed(2))
        }
        if (requestedCalculatedTotalType === "By %") {
          let addedValue: any = (parseFloat(estmateTotal) * item["nspev"]) / 100;
          nspev = (parseFloat(getSellingPrice((parseFloat(item["nspev"]) - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (isAvailabiityChecked && item["nspev"]) {
        nspev = (parseFloat(getSellingPrice(item["nspev"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return Number(nspev);
  }

  const getExportNSPValue = (item: any) => {
    let nsp: any = 0.00;
    /* Code for adding 5% margin to nsp(exclusive vat) and returing nap value is special price approved ->  Bhargavi */
    if (enquiryAction === 'view' && item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN') {
      let nspev: any = getExportNSPEVValue(item);
      let addedValue: any = (nspev * 5) / 100;
      nsp = (parseFloat(getSellingPrice((parseFloat(nspev) + parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestStatus === 'Approved' && item["nowAvailability"] && item.isSpecialPriced === false) {
        let nspev: any = getExportNSPEVValue(item);
        let addedValue: any = (nspev * 5) / 100;
        nsp = (parseFloat(getSellingPrice((parseFloat(nspev) + parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2))
      } else if (isAvailabiityChecked && item["nsp"]) {
        nsp = (parseFloat(getSellingPrice(item["nsp"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return Number(nsp);
  }

  const getExportBOPValue = (item: any) => {
    let bop: any = 0.00;
    if (enquiryAction === 'view' && item.airApprovedPrice && item.airApprovedPrice !== '' && item.airApprovedPrice !== 'NaN') {
      bop = (parseFloat(getSellingPrice(item.airApprovedPrice, isSellingPriceData, markupPercentage)).toFixed(2))
    } else {
      if (isAvailabiityChecked && requestedTotalType === "part" && item.isSpecialPriceRequestPart && item["airAvailability"] && requestStatus === 'Approved' && item.isRejected == false) {
        if (item.airOfferedPriceType == 'byPercentage' && item["airOfferedCalculatedPrice"] && item["airOfferedCalculatedPrice"] !== '' && item["airOfferedCalculatedPrice"] !== 'NaN') {
          bop = parseFloat(getSellingPrice(item["airOfferedCalculatedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else if (item.airOfferedPriceType == 'byCurrency' && item["airOfferedPrice"] && item["airOfferedPrice"] !== '' && item["airOfferedPrice"] !== 'NaN') {
          bop = parseFloat(getSellingPrice(item["airOfferedPrice"], isSellingPriceData, markupPercentage)).toFixed(2);
        } else {
          bop = (parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2))
        }
      } else if (requestedTotalType === "total" && isAvailabiityChecked && item["aop"] && item["airAvailability"] && item.isSpecialPriced === false &&
        requestStatus === 'Approved') {
        if (requestedCalculatedTotalType === "By AED") {

          let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
          let addedValue: any = (parseFloat(value) * item["aop"]) / 100;
          bop = (parseFloat(getSellingPrice((item["aop"] - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2));

          // bop = (parseFloat(getSellingPrice((parseFloat(requestedCaluclatedTotalPrice.replace("%", "").replace("-", "")) * item["aop"]) / 100, isSellingPriceData, markupPercentage)).toFixed(2));
        }
        if (requestedCalculatedTotalType === "By %") {
          let addedValue: any = (item["aop"] * parseFloat(estmateTotal)) / 100;
          bop = (parseFloat(getSellingPrice((item["aop"] - parseFloat(addedValue)), isSellingPriceData, markupPercentage)).toFixed(2));
        }
      } else if (isAvailabiityChecked && item["aop"]) {
        bop = (parseFloat(getSellingPrice(item["aop"], isSellingPriceData, markupPercentage)).toFixed(2))
      }
    }
    return Number(bop);
  }

  const getAmtValue = (item: any) => {
    let total = 0.00;
    if (item && item.nowAvailabilityChecked && item.nowAvailability && item.nsp) {
      if (requestStatus === 'Approved') {
        let nsp1: any = getExportNSPValue(item);
        total += parseFloat(item.nowAvailability) * parseFloat(nsp1);
      } else {
        total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
      }
    }
    // <<<<<<<<<<< 24 hrs >>>>>>>>>>>>>>>>>>>>> NEW CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item && item.hrs24AvailabilityChecked && item.hrs24Availability && item.nsp) {
      total += parseFloat(item.hrs24Availability) * parseFloat(item.nsp);
    }
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    if (customerTierType && (customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer") && item && item.airAvailabilityChecked && item.airAvailability && item.aop) {
      if (requestStatus === 'Approved') {
        let aop1: any = getExportBOPValue(item);
        total += parseFloat(item.airAvailability) * parseFloat(aop1);
      } else {
        total += parseFloat(item.airAvailability) * parseFloat(item.aop);
      }
    }
    return total
  }

  const exportData = () => {

    let combinedArray = [...products, ...invalidParts];

    // Create a map to store unique objects based on their 'seq' property or the whole object if 'seq' is undefined
    let uniqueMap = new Map();
    combinedArray.forEach((item: any) => {
      // Generate a key based on the 'seq' value or the whole object if 'seq' is undefined
      let key = item.seq !== undefined ? item.seq.toString() : JSON.stringify(item);

      // If the key is not already in the map, add the item
      if (!uniqueMap.has(key)) {
        uniqueMap.set(key, item);
      }
    });

    // Create an array of unique objects
    combinedArray = Array.from(uniqueMap.values());
    // Sort the array based on the 'seq' property
    combinedArray.sort((a, b) => {
      if (a.seq !== undefined && b.seq !== undefined) {
        return a.seq - b.seq; // Sort based on 'seq' if both are defined
      } else if (a.seq !== undefined) {
        return -1; // 'a' has 'seq', so it should come before 'b'
      } else if (b.seq !== undefined) {
        return 1; // 'b' has 'seq', so it should come before 'a'
      } else {
        return 0; // Both 'a' and 'b' have no 'seq', maintain their relative order
      }
    });


    if (combinedArray && Array.isArray(combinedArray) && combinedArray.length > 0) {
      let filename = "enquiryexport.xlsx";
      let data: any = [];
      if (sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet") {
        combinedArray.map((item: any) => {
          data.push({
            'Part No': item.productId,
            'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
            Brand: enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' && customerTierType.toLowerCase() !== "tpcdealer" ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] :
              '',
            "Sub-Brand": enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`] === 'OES' && item && item.oesSubBrandName !== '' ? item.oesSubBrandName : enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] ? enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' && customerTierType.toLowerCase() !== "tpcdealer" ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] :
              '',
            'Part Description': item.productIdDescription,
            Quantity: !item.isSuperseeded ? item.quantity : '',
            'NSP (Incl. VAT)': item.nsp ? getExportNSPValue(item) ? getExportNSPValue(item) : Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'RSP': sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" && item.rsp ? Number(parseFloat(item.rsp).toFixed(2)) : '0.00',
            'SOH': sessionStorage.getItem('userType') === 'FSE' && item.soh ? Number(parseInt(item.soh)) : '0.00',
            Amt: Number(parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2)),
            // 'NSP (Excl. VAT)': item.nspev ? Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'NSP (Excl. VAT)': item.nspev ? getExportNSPEVValue(item) ? getExportNSPEVValue(item) : Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            // BOP: item.aop ? Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            BOP: item.aop ? getExportBOPValue(item) ? getExportBOPValue(item) : Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'Now Availability': item.nowAvailability,
            '24 Availability': item.hrs24Availability,
            'BO Availability': item.airAvailability,
            Dimension: item.isValid
              ? `${parseInt(item.dimensions.length)} x ${parseInt(
                item.dimensions.width
              )} x ${parseInt(item.dimensions.height)}`
              : "-",
            Weight: item.isValid ? (item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : '-') : "-",
            Volume: item.isValid ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "-",
            'Orignal Part': item.supercessionMaterial,
            'Error Message': item.isSuperseeded ? '' : item.isProcurementPart ? 'Material Blocked for Procurement' : item.isMaterialNotExtended ? 'Material Not extended to Plant' :
              !item.invalidMaterialGroup ? 'Invalid Part' : item.isValid ? '' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]}` != "undefined" ? `No access to ${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand` : item.status ? item.status : 'No access to Brand',
          });
        });
      } else if (sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType !== "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType !== "Fleet") {
        combinedArray.map((item: any) => {
          data.push({
            'Part No': item.productId,
            'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
            Brand: enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' && customerTierType.toLowerCase() !== "tpcdealer" ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]
              : '',
            "Sub-Brand": enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`] === 'OES' && item && item.oesSubBrandName !== '' ? item.oesSubBrandName : enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] ? enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' && customerTierType.toLowerCase() !== "tpcdealer" ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]
              : '',
            'Part Description': item.productIdDescription,
            Quantity: !item.isSuperseeded ? item.quantity : '',
            // 'NSP (Incl. VAT)': item.nsp ? Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'NSP (Incl. VAT)': item.nsp ? getExportNSPValue(item) ? getExportNSPValue(item) : Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'SOH': sessionStorage.getItem('userType') === 'FSE' && item.soh ? Number(parseInt(item.soh)) : '0.00',
            Amt: Number(parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2)),
            // 'NSP (Excl. VAT)': item.nspev ? Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'NSP (Excl. VAT)': item.nspev ? getExportNSPEVValue(item) ? getExportNSPEVValue(item) : Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            // BOP: item.aop ? Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            BOP: item.aop ? getExportBOPValue(item) ? getExportBOPValue(item) : Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'Now Availability': item.nowAvailability,
            '24 Availability': item.hrs24Availability,
            'BO Availability': item.airAvailability,
            Dimension: item.isValid
              ? `${parseInt(item.dimensions.length)} x ${parseInt(
                item.dimensions.width
              )} x ${parseInt(item.dimensions.height)}`
              : "-",
            Weight: item.isValid ? (item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : '-') : "-",
            Volume: item.isValid ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "-",
            'Orignal Part': item.supercessionMaterial,
            'Error Message': item.isSuperseeded ? '' : item.isProcurementPart ? 'Material Blocked for Procurement' : item.isMaterialNotExtended ? 'Material Not extended to Plant' :
              !item.invalidMaterialGroup ? 'Invalid Part' : item.isValid ? '' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]}` != "undefined" ? `No access to ${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand` : item.status ? item.status : 'No access to Brand',
          });
          // if (sessionStorage.getItem('userType') === 'FSE') {
          //   data.push({
          //     'SOH': item.soh ? Number(parseInt(item.soh)) : '0.00',
          //     // 'RSP': item.rsp ? Number(parseFloat(item.rsp).toFixed(2)) : '0.00'
          //   });
          // }
        });
      } else {
        combinedArray.map((item: any) => {
          data.push({
            'Part No': item.productId,
            'Alternate Part': item.isSuperseeded ? 'YES' : 'NO',
            Brand: customerTierType === 'tpcdealer' ? 'Tradepoint' : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]
              : '',
            "Sub-Brand": enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`] === 'OES' && item && item.oesSubBrandName !== '' ? item.oesSubBrandName : enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] ? enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] : customerTierType === 'tpcdealer' ? 'Tradepoint' : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] ? enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "OES" && customerTierType !== 'Workshop' ? "ALAC" :
              enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" :
                enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`].toUpperCase() === "TE" ? "Trading Enterprises" : enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]
              : '',
            'Part Description': item.productIdDescription,
            Quantity: !item.isSuperseeded ? item.quantity : '',
            // 'NSP (Incl. VAT)': item.nsp ? Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'NSP (Incl. VAT)': item.nsp ? getExportNSPValue(item) ? getExportNSPValue(item) : Number(parseFloat(getSellingPrice(item.nsp, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            Amt: Number(parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2)),
            // 'NSP (Excl. VAT)': item.nspev ? Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'NSP (Excl. VAT)': item.nspev ? getExportNSPEVValue(item) ? getExportNSPEVValue(item) : Number(parseFloat(getSellingPrice(item.nspev, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            // BOP: item.aop ? Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            BOP: item.aop ? getExportBOPValue(item) ? getExportBOPValue(item) : Number(parseFloat(getSellingPrice(item.aop, isSellingPriceData, markupPercentage)).toFixed(2)) : '0.00',
            'Now Availability': item.nowAvailability,
            '24 Availability': item.hrs24Availability,
            'BO Availability': item.airAvailability,
            Dimension: item.isValid
              ? `${parseInt(item.dimensions.length)} x ${parseInt(
                item.dimensions.width
              )} x ${parseInt(item.dimensions.height)}`
              : "-",
            Weight: item.isValid ? (item.dimensions.grossWeight ? Number(parseFloat(item.dimensions.grossWeight).toFixed(2)) : '-') : "-",
            Volume: item.isValid ? Number(parseFloat(item.dimensions.volume).toFixed(2)) : "-",
            'Orignal Part': item.supercessionMaterial,
            'Error Message': item.isSuperseeded ? '' : item.isProcurementPart ? 'Material Blocked for Procurement' : item.isMaterialNotExtended ? 'Material Not extended to Plant' :
              !item.invalidMaterialGroup ? 'Invalid Part' : item.isValid ? '' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]}` != "undefined" ? `No access to ${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand` : item.status ? item.status : 'No access to Brand',
          });
          // if (sessionStorage.getItem('userType') === 'FSE') {
          //   data.push({
          //     'SOH': item.soh ? Number(parseInt(item.soh)) : '0.00',
          //     // 'RSP': item.rsp ? Number(parseFloat(item.rsp).toFixed(2)) : '0.00'
          //   });
          // }
        });
      }
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  };

  const exportToExcelData = () => {
    if (procuredPart && procuredPart.length > 0) {
      let filename = "ProcurementPartsExport.xlsx";
      let data: any = [];
      procuredPart && Array.isArray(procuredPart) && procuredPart.length > 0 && procuredPart.map((p: any, i: any) => (
        data.push({
          'Part No': p,
          'Error Message': 'Material Blocked for Procurement'
        })
      ))
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  };

  const exportToExcelMaterialData = () => {
    if (materialNotExtendedPart && materialNotExtendedPart.length > 0) {
      let filename = "materialNotExtendedParts.xlsx";
      let data: any = [];
      materialNotExtendedPart && Array.isArray(materialNotExtendedPart) && materialNotExtendedPart.length > 0 && materialNotExtendedPart.map((p: any, i: any) => (
        data.push({
          'Part No': p,
          'Error Message': 'Material Not Extended to Plant'
        })
      ))
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  };

  const isNumeric = (n: any) => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };

  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  const renderRows = () => {
    let mappedData: any = {
      Toyota: [],
      Honda: [],
      Famco: [],
      TE: [],
      Others: [],
      OES: [],
    };
    let invalidProducts: any = [];
    // >>>>>>>>>>>>>>>>>>>>>>>>>>>
    // if (isInvalidPartsExist === false) {
    if (products && Array.isArray(products) && products.length > 0) {
      let productunref: any = JSON.parse(JSON.stringify(products));
      productunref.map((item: any, index: any) => {
        let storeItem: any = JSON.parse(JSON.stringify(item));
        storeItem["actualIndex"] = index;
        if (item.materialGroup && enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] &&
          !item.isMaterialNotExtended) {
          mappedData[enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]].push(storeItem);
        } else {
          invalidProducts.push(storeItem);
        }
      });
      if (invalidParts && invalidParts.length > 0) {
        const combinedArray = [...invalidProducts];
        for (const item of invalidParts) {
          if (!combinedArray.some(element => element.seq === item.seq)) {
            combinedArray.push(item);
          }
        }
        invalidProducts = combinedArray
      }
    }

    // } else {
    //   if (products && Array.isArray(products) && products.length > 0) {
    //     let productunref: any = JSON.parse(JSON.stringify(products));
    //     productunref.map((item: any, index: any) => {
    //       let storeItem: any = JSON.parse(JSON.stringify(item));
    //       storeItem["actualIndex"] = index;
    //       if (item.materialGroup && enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`] &&
    //         !item.isProcurementPart) {
    //         mappedData[enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]].push(storeItem);
    //       } else {
    //         invalidProducts.push(storeItem);
    //       }
    //     });
    //   }
    // }
    Object.keys(mappedData).map((key) => {
      mappedData[key] = mappedData[key].sort((a: any, b: any) => {
        let deciderCondition = b.isSuperseeded ? -1 : 1;
        let part1ItemNumber = a.nowEnquiryItemNumber || a.hrs24EnquiryItemNumber || a.airEnquiryItemNumber;
        let part2ItemNumber = b.nowEnquiryItemNumber || b.hrs24EnquiryItemNumber || b.airEnquiryItemNumber;
        return parseInt(part1ItemNumber) > parseInt(part2ItemNumber) ? 1 : parseInt(part2ItemNumber) > parseInt(part1ItemNumber) ? -1 : deciderCondition;
      })
    })
    let finalRows: any = [];
    Object.keys(mappedData).map((key) => {
      if (mappedData[key].length > 0) {
        finalRows.push(
          <Row
            availability={availability}
            setcheckedAllBO={setcheckedAllBO}
            setcheckedAllNow={setcheckedAllNow}
            setChecked24hrs={setChecked24hrs}
            isChangeCheckBox={isChangeCheckBox}
            enquiryAction={enquiryAction}
            setisSelectedAll={setisSelectedAll}
            brandCode={orgIdBrandsMappingReverse[key]}
            setreferenceNumber={setreferenceNumber}
            setbrandRefNumbers={setbrandRefNumbers}
            brandRefNumbers={brandRefNumbers}
            setDeliveryBlockReason={setDeliveryBlockReason}
            deliveryBlockReason={deliveryBlockReason}
            salesOffice={salesOffice}
            setSalesOffice={setSalesOffice}
            products={products}
            brandName={key?.toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : key?.toUpperCase() === "TE" ? "Trading Enterprises" : key?.toUpperCase() === "OES" && (customerTierType === 'Workshop' || customerTierType === "tpcdealer") ? "Tradepoint" : key?.toUpperCase() === "OES" && (customerTierType !== 'Workshop' && customerTierType !== "tpcdealer") ? 'ALAC' : key}
            // brandName={key.toUpperCase() === "TOYOTA" ? "Al Futtaim Motors" : key.toUpperCase() === "TE" ? "Trading Enterprises" : key.toUpperCase() === "OES" ? "Tradepoint" : key}
            brandData={mappedData[key]}
            verificationData={verificationData}
            isAvailabiityChecked={isAvailabiityChecked}
            setproducts={setproducts}
            setIsQuantityUpdated={setIsQuantityUpdated}
            isSellingPriceData={isSellingPriceData}
            markupPercentage={markupPercentage}
            enquiryMapping={enquiryMapping}
            enquiryMappingTPC={enquiryMappingTPC}
            enquirySubBrand={enquirySubBrand}
            isSpecialPrice={isSpecialPrice}
            spParts={spParts}
            isAvailabiityCheckedAtTime={isAvailabiityCheckedAtTime}
            isSPRModifyData={isSPRModifyData}
            accessData={accessData}
            requestStatus={requestStatus}
            setDisableQuotation={setDisableQuotation}
          />
        );
      }
    });
    invalidProducts.map((item: any, index: any) => {
      //problem parts conflcits fix
      item.isProcurementPart ? '' :
        finalRows.push(
          <TableRow className={!item.isValid || item.isMaterialNotExtended || item.isProcurementPart || item.isZeroPrice ? "invalidRowClass invalidRowClassInvalid" : ""}>
            <TableCell className="">
              <input type="checkbox"
                // disabled={customerTierType && customerTierType === "Dealer" &&
                //   (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                disabled={true}
                checked={item.selected ? true : false}
                onChange={() => {
                  if (enquiryAction !== "view") {
                    let productsTemp: any = JSON.parse(JSON.stringify(products));

                    let dataCheck = productsTemp.filter((ele: any) => {
                      return ele.productId === item.productId
                    })
                    if (dataCheck.length > 0) {
                      productsTemp[item.actualIndex]["selected"] = !item.selected;
                      setproducts([...productsTemp]);
                      setisSelectedAll(false);
                    } else {
                      let seqArr = [...products, ...invalidParts];
                      let selectEle = seqArr.filter((ele) => ele.productId == item.productId);
                      selectEle[0].selected = !item.selected;
                      setproducts([...seqArr])
                    }

                  }
                }}
              />
            </TableCell>
            <TableCell className="invalidRowClassInvalidPart">
              <div className="greyLabelTable fontsizetenew">{item.productId}</div>
              <div className="greyLabelTable invalidPartText fontsizetenew">
                {/* {item.productId == procuredPart ? "Material blocked for Procurement" : !item.invalidMaterialGroup ? 'Invalid part' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]}` != "undefined" ? `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand not allowed` : "Brand not allowed"} */}
                {item.isMaterialNotExtended ? 'Material Not Extended to plant' : item.isProcurementPart ? "Material blocked for Procurement" : item.isZeroPrice ? 'Zero Price Part' : !item.invalidMaterialGroup ? 'Invalid part' : `${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]}` != "undefined" ? `No access to ${enquiryMapping[`${formatMaterialGroup(item.invalidMaterialGroup)}`]} Brand` : "No access to Brand"}
              </div>
            </TableCell>
            <TableCell className="font-10 font-weight-700">
              {item && (!item.materialGroup || item.materialGroup === '') ? '' : enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] ? enquirySubBrand[`${formatMaterialGroup(item.materialGroup)}`] :
                customerTierType && customerTierType.toLowerCase() !== "tpcdealer" ? (
                  <>
                    {item && item.materialGroup ? getNewBrand(enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]) : ''}
                  </>
                ) : (
                  <>
                    {item && item.materialGroup ? getNewBrand(enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`]) : ''}
                  </>
                )}

              {/* {customerTierType && customerTierType.toLowerCase() !== "tpcdealer" ? (
                <>
                  {item && item.materialGroup ? getNewBrand(enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]) : ''}
                </>
              ) : (
                <>
                  {item && item.materialGroup ? getNewBrand(enquiryMappingTPC[`${formatMaterialGroup(item.materialGroup)}`]) : ''}
                </>
              )} */}

              {/* {item && item.materialGroup ? getNewBrand(enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]) : ''} */}
            </TableCell>
            <TableCell className="pl-0 invalidRowClassInvalidPart">
              <div className="greyLabelTable fontsizetenew">
                <input
                  onChange={(e) => {
                    if (enquiryAction !== "view") {
                      let productsTemp: any = JSON.parse(
                        JSON.stringify(products)
                      );
                      let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                        parseInt(e.target.value) >= 1
                        ? parseInt(e.target.value)
                        : ""
                      setquantity(value);
                      productsTemp[item.actualIndex]["quantity"] = value;
                      setproducts([...productsTemp]);
                    }
                  }}
                  value={item.quantity}
                  maxLength={5}
                  type="text"
                  className="cardCommon mr-2 smallcircularinputFiled"
                  placeholder=""
                />{" "}
                {/* {isAvailabiityChecked && item.quantity ? item.quantity : "0"} */}
                {isAvailabiityChecked && item["nowAvailability"] && item["hrs24Availability"] && item["nowAvailability"] ?
                  parseInt(isAvailabiityChecked && item["nowAvailability"] && item["nowAvailability"]) + parseInt(isAvailabiityChecked && item["hrs24Availability"] && item["hrs24Availability"])
                  : isAvailabiityChecked && item["nowAvailability"] ? item["nowAvailability"] : isAvailabiityChecked && item["hrs24Availability"] ? item["hrs24Availability"]
                    : "0"}{"   "}&nbsp;
                {sessionStorage.getItem('userType') === 'FSE' && (
                  item.soh ? item.soh : '0'
                )}
              </div>
            </TableCell>
            {sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" ?
              <TableCell className="font-10 font-weight-700 text-444">{isAvailabiityChecked && item["rsp"] ? item["rsp"] : "XX.XX"}</TableCell>
              : ""}
            {/* <TableCell className=" font-10 font-weight-700 text-444">{isAvailabiityChecked && item["rsp"] ? item["rsp"] : "XX.XX"}</TableCell> */}
            <TableCell className=" font-10 font-weight-700 text-444">{isAvailabiityChecked && item["nsp"] ? item["nsp"] : 0.00}</TableCell>
            <TableCell className=" font-10 font-weight-700 text-444">{isAvailabiityChecked ? parseFloat(getSellingPrice(getAmtValue(item), isSellingPriceData, markupPercentage)).toFixed(2) : 0.00}</TableCell>
            <TableCell className=" font-10 font-weight-700 text-444">{isAvailabiityChecked && item["nspev"] ? item["nspev"] : 0.00}</TableCell>
            <TableCell className=" font-10 font-weight-700 text-444">{isAvailabiityChecked && item["bop"] ? item["bop"] : 0.00}</TableCell>
            <TableCell className="invalidRowClassInvalidPart">
              <div className="row no-gutters">
                <div className="col-4 greyLabelTable">
                  <input type="checkbox" disabled />{" "}
                  {isAvailabiityChecked && item["nowAvailability"]
                    ? item["nowAvailability"]
                    : "0"}
                </div>
                <div className="col-4 greyLabelTable">
                  <input type="checkbox" disabled /> {isAvailabiityChecked && item["airAvailability"]
                    ? item["airAvailability"]
                    : "0"}
                </div>
              </div>
            </TableCell>
            <TableCell className="invalidRowClassInvalidPart">
              <div className="greyLabelTable fontsizetenew">
                {item.isValid
                  ? `${parseInt(item.dimensions.length)} x ${parseInt(
                    item.dimensions.width
                  )} x ${parseInt(item.dimensions.height)} | ${item.dimensions.grossWeight ? parseFloat(item.dimensions.grossWeight).toFixed(2) : '-'}`
                  : "-"}
              </div>
            </TableCell>
            <TableCell className="invalidRowClassInvalidPart">
              <div className="greyLabelTable fontsizetenew">
                {item.isValid ? `${parseInt(item.dimensions.volume)}m` : "-"}
              </div>
            </TableCell>
          </TableRow>
        );
    });
    return finalRows;
  };


  const enquiryDataToEditMode = async (enquiryDataFetched: any, reset?: any, withDeletion?: any, availabilityMode?: any) => {
    let productsTemp: any = [];
    let sp: any = false;
    let orgsAssignedToUser = "";
    productsTemp = productsTemp.filter((product: any) => product.isValid);
    // let pushedProducts: any = [];
    let brandRefNumbersTemp = JSON.parse(JSON.stringify(brandRefNumbers));
    // if (sessionStorage.getItem('userType') === 'FSE') {
    let deliveryBlockReasonTemp = JSON.parse(JSON.stringify(deliveryBlockReason));
    // }
    if (assignedBrandsData && Array.isArray(assignedBrandsData)) {
      assignedBrandsData.map((item: any) => {
        if (item.salesOrganization) {
          orgsAssignedToUser += enquiryMappingReverse[`${orgIdBrandsMapping[`${item.salesOrganization}`]}`] + ",";
        }
      });
    }

    // if (!productsTemp || productsTemp.length === 0) {
    enquiryDataFetched && enquiryDataFetched.length > 0 && enquiryDataFetched.map((subenquiry: any, index: any) => {
      setreferenceNumber(subenquiry.masterReferenceNumber);
      if (enquiryDataFetched && enquiryDataFetched.length === 1) {
        if (enquiryDataFetched[0].status) {
          // setEnquiryStatus(enquiryDataFetched[0].status);
          if (enquiryDataFetched[0].status && enquiryDataFetched[0].status.toLowerCase() === 'verification queued') {
            setIsQueue(true);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.QUEUED)
          } else if (enquiryDataFetched[0].status && enquiryDataFetched[0].status.toLowerCase() === 'verification in progress' && enquiryDataFetched[0].isQueued) {
            setIsQueue(true);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS)
          } else {
            setIsQueue(false);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
          }
          if (enquiryDataFetched[0].status && enquiryDataFetched[0].status.toLowerCase() === 'order placement queued') {
            setorderQueued(true)
          }
        }
      } else {
        if (enquiryDataFetched[0].status) {
          // setEnquiryStatus(enquiryDataFetched[0].status);
          if (enquiryDataFetched.some((obj: any) => obj.status.toLowerCase() === 'verification queued')) {
            setIsQueue(true);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.QUEUED)
          } else if (enquiryDataFetched.some((obj: any) => obj.status.toLowerCase() === 'verification in progress') && enquiryDataFetched.some((obj: any) => obj.isQueued)) {
            setIsQueue(true);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS)
          } else {
            setIsQueue(false);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
          }
          if (enquiryDataFetched.some((obj: any) => obj.status.toLowerCase() === 'order placement queued')) {
            setorderQueued(true)
          }
        }
      }
      if (enquiryDataFetched[0].specailPriceRequestDetails && enquiryDataFetched[0].specailPriceRequestDetails) {
        setRequestSPData(enquiryDataFetched[0].specailPriceRequestDetails && enquiryDataFetched[0].specailPriceRequestDetails)
        setSpParts(enquiryDataFetched[0].specailPriceRequestDetails && enquiryDataFetched[0].specailPriceRequestDetails.products)
        setIsSpEnquiry(true);
        sp = true;
      }
      // if (subenquiry && subenquiry.removedProducts && Array.isArray(subenquiry.removedProducts)) {
      //   setRemovedProductsLength(subenquiry.removedProducts.length)
      //   let combinedArray = [...subenquiry.removedProducts];
      //   let seqMap = new Map();
      //   combinedArray.forEach(item => {
      //     if (!seqMap.has(item.seq)) {
      //       seqMap.set(item.seq, item);
      //     }
      //   });

      //   // Get values from the map to retrieve unique items based on 'seq'
      //   combinedArray = Array.from(seqMap.values());
      //   combinedArray.sort((a: any, b: any) => a.seq - b.seq);

      //   setInvalidParts(combinedArray)

      // }


      // if (invalidParts && invalidParts.length > 0 && !isAvailabiityChecked) {
      //   setInvalidParts(invalidParts)
      // }

      if (subenquiry && subenquiry.products && Array.isArray(subenquiry.products)) {
        subenquiry.products.map((productSub: any) => {
          // if (!pushedProducts.includes(productSub.productId)) {
          //   pushedProducts.push(productSub.productId);
          // productsTemp.push(JSON.parse(JSON.stringify(productSub)));
          // }
          // setreferenceNumber(subenquiry.masterReferenceNumber);
          if (subenquiry.verifiedOn && index == 0) {
            var now = moment(new Date());
            var start = moment(new Date(subenquiry.verifiedOn));
            var duration: any = moment.duration(start.diff(now));
            var minutes: any = Math.abs(duration.asMinutes());
            if (sessionStorage.getItem('userType') === 'FSE') {
              if (!(minutes && minutes > 60) || availabilityMode) {
                if (!sp && !isQueue) {
                  setisAvailabiityCheckedAtTime(new Date(subenquiry.verifiedOn)) // need to change as per new key
                }
                setisAvailabiityChecked(true);
                setDisableQuotation(false);
              }
            }
            else {
              if (!(minutes && minutes > 5) || availabilityMode) {
                if (!sp && !isQueue) {
                  setisAvailabiityCheckedAtTime(new Date(subenquiry.verifiedOn))
                }
                setisAvailabiityChecked(true);
                setDisableQuotation(false);
              }
            }
          }
          brandRefNumbersTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`] = productSub.enquiryReferenceNumber;
          if (productSub && productSub.deliveryBlockReason && productSub.deliveryBlockReason !== '') {
            if (deliveryBlockReasonTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`]) {
              deliveryBlockReasonTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`].deliveryBlockReason = productSub.deliveryBlockReason;
              deliveryBlockReasonTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`].deliveryBlockReasonCode = productSub.deliveryBlockReasonCode;
            }
          }
          if (enquiryDataFetched[0].specailPriceRequestDetails && enquiryDataFetched[0].specailPriceRequestDetails.expiredAfterApproval == false && enquiryDataFetched[0].specailPriceRequestDetails.status == 'Approved') {
            enquiryDataFetched[0].specailPriceRequestDetails.products && Array.isArray(enquiryDataFetched[0].specailPriceRequestDetails.products) &&
              enquiryDataFetched[0].specailPriceRequestDetails.products.map((spProducts: any) => {
                let approvedPrice: any = 0.00;
                let boapprovedPrice: any = 0.00;

                if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceType === "part" && spProducts.isSpecialPriced == false && spProducts["nowAvailability"]) {
                  if (spProducts.nowOfferedPriceType == 'byPercentage' && spProducts["nowOfferedCalculatedPrice"] && spProducts["nowOfferedCalculatedPrice"] !== '' && spProducts["nowOfferedCalculatedPrice"] !== 'NaN') {
                    approvedPrice = parseFloat(spProducts["nowOfferedCalculatedPrice"]).toFixed(2)
                  } else if (spProducts.nowOfferedPriceType == 'byCurrency' && spProducts["nowOfferedPrice"] && spProducts["nowOfferedPrice"] !== '' && spProducts["nowOfferedPrice"] !== 'NaN') {
                    approvedPrice = parseFloat(spProducts["nowOfferedPrice"]).toFixed(2);
                  } else {
                    approvedPrice = parseFloat(spProducts["nspev"]).toFixed(2);
                  }
                  if (spProducts.productId === productSub.productId && spProducts.nowEnquiryItemNumber === productSub.nowEnquiryItemNumber) {
                    productSub['spNowAvailability'] = spProducts.nowAvailability;
                    productSub["nowApprovedPrice"] = approvedPrice;
                  }
                }
                else if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceType === "total" && spProducts["nspev"] && spProducts["nowAvailability"] && spProducts.isSpecialPriced === false &&
                  enquiryDataFetched[0].specailPriceRequestDetails.status === 'Approved') {
                  if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCurrency === "By AED") {
                    let value: any = enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCalculated.replace("%", "").replace("-", "");
                    let newNsp: any = (parseFloat(value) * spProducts["nspev"]) / 100;
                    let ns: any = spProducts["nspev"] - parseFloat(newNsp);
                    approvedPrice = parseFloat(ns).toFixed(2);
                  }
                  if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCurrency === "By %") {
                    let newNsp: any = (parseFloat(enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPrice) * spProducts["nspev"]) / 100;
                    let ns: any = spProducts["nspev"] - parseFloat(newNsp);
                    approvedPrice = parseFloat(ns).toFixed(2);
                  }
                  if (spProducts.productId == productSub.productId && spProducts.nowEnquiryItemNumber === productSub.nowEnquiryItemNumber) {
                    productSub['spNowAvailability'] = spProducts.nowAvailability;
                    productSub["nowApprovedPrice"] = approvedPrice;
                  }
                }

                if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceType === "part" && spProducts.isSpecialPriced == false && spProducts["airAvailability"]) {
                  if (spProducts.airOfferedPriceType == 'byPercentage' && spProducts["airOfferedCalculatedPrice"] && spProducts["airOfferedCalculatedPrice"] !== '' && spProducts["airOfferedCalculatedPrice"] !== 'NaN') {
                    boapprovedPrice = parseFloat(spProducts["airOfferedCalculatedPrice"]).toFixed(2)
                  } else if (spProducts.airOfferedPriceType == 'byCurrency' && spProducts["airOfferedPrice"] && spProducts["airOfferedPrice"] !== '' && spProducts["airOfferedPrice"] !== 'NaN') {
                    boapprovedPrice = parseFloat(spProducts["airOfferedPrice"]).toFixed(2);
                  } else {
                    boapprovedPrice = parseFloat(spProducts["aop"]).toFixed(2);
                  }
                  if (spProducts.productId == productSub.productId && spProducts.airEnquiryItemNumber === productSub.airEnquiryItemNumber) {
                    productSub['spAirAvailability'] = spProducts.airAvailability;
                    productSub["airApprovedPrice"] = boapprovedPrice;
                  }
                  // if(spProducts.airAvailability > productSub.airAvailability) {

                  // }
                }
                else if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceType === "total" && spProducts["aop"] && spProducts["airAvailability"] && spProducts.isSpecialPriced === false &&
                  enquiryDataFetched[0].specailPriceRequestDetails.status === 'Approved') {
                  if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCurrency === "By AED") {


                    let value: any = enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCalculated.replace("%", "").replace("-", "");
                    let newBop: any = (parseFloat(value) * spProducts["bop"]) / 100;
                    let bo: any = spProducts["aop"] - parseFloat(newBop);
                    approvedPrice = parseFloat(bo).toFixed(2);

                    // let value: any = (parseFloat(enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCalculated.replace("%", "").replace("-", "")) * spProducts["bop"]) / 100
                    // boapprovedPrice = parseFloat(value).toFixed(2);
                  }
                  if (enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPriceCurrency === "By %") {
                    let newBop: any = (parseFloat(enquiryDataFetched[0].specailPriceRequestDetails.offeredTotalPrice) * spProducts["aop"]) / 100;
                    let bo: any = spProducts["aop"] - parseFloat(newBop);
                    boapprovedPrice = parseFloat(bo).toFixed(2);
                  }
                  if (spProducts.productId == productSub.productId && spProducts.airEnquiryItemNumber === productSub.airEnquiryItemNumber) {
                    productSub['spAirAvailability'] = spProducts.airAvailability;
                    productSub["airApprovedPrice"] = boapprovedPrice;
                  }
                }
              });

          }
          // responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase() &&
          // formatMaterialGroup(responsePart?.attributes?.materialGroup?.value) &&
          // orgsAssignedToUser.includes(formatMaterialGroup(responsePart?.attributes?.materialGroup?.value))
          productSub["materialGroup"] = formatMaterialGroup(productSub.materialGroup) &&
            orgsAssignedToUser.includes(formatMaterialGroup(productSub.materialGroup)) ? productSub.materialGroup : '';
          productSub["isValid"] = formatMaterialGroup(productSub.materialGroup) &&
            orgsAssignedToUser.includes(formatMaterialGroup(productSub.materialGroup)) ? true : false;
          productSub["enquiryNumber"] = subenquiry.enquiryNumber;
          productSub["id"] = subenquiry.id;
          productsTemp.push(JSON.parse(JSON.stringify(productSub)));
        });
      }
    });
    // }
    // if (withDeletion) {
    //   productsTemp = productsTemp.filter((item: any) => !item.selected);
    // }
    // let tempProductsObject: any = null;
    // tempProductsObject = _.keyBy(productsTemp, 'productId');
    // enquiryDataFetched.map((subenquiry: any, index: any) => {
    //   setreferenceNumber(subenquiry.masterReferenceNumber);
    //   if (subenquiry.verifiedOn && index === 0) {
    //     var now = moment(new Date());
    //     var start = moment(new Date(subenquiry.verifiedOn));
    //     var duration: any = moment.duration(start.diff(now));
    //     var minutes: any = Math.abs(duration.asMinutes());
    //     if (!(minutes && minutes > 5) || availabilityMode) {
    //       setisAvailabiityCheckedAtTime(new Date(subenquiry.verifiedOn))
    //       setisAvailabiityChecked(true)
    //     }
    //   }
    //   if (
    //     subenquiry &&
    //     subenquiry.products &&
    //     Array.isArray(subenquiry.products)
    //   ) {
    //     subenquiry.products.map((productSub: any) => {
    //       if (productSub.productId && tempProductsObject[productSub.productId]) {
    //         tempProductsObject[productSub.productId]["nowEnquiryItemNumber"] = productSub.nowEnquiryItemNumber ? productSub.nowEnquiryItemNumber : "";
    //         tempProductsObject[productSub.productId]["nowProposedItemNumber"] = productSub.nowProposedItemNumber ? productSub.nowProposedItemNumber : "";
    //         tempProductsObject[productSub.productId]["airEnquiryItemNumber"] = productSub.airEnquiryItemNumber ? productSub.airEnquiryItemNumber : "";
    //         tempProductsObject[productSub.productId]["airProposedItemNumber"] = productSub.airProposedItemNumber ? productSub.airProposedItemNumber : "";
    //         tempProductsObject[productSub.productId]['enquiryNumber'] = subenquiry.enquiryNumber;
    //         brandRefNumbersTemp[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(productSub.materialGroup)}`]}`]}`] = productSub.enquiryReferenceNumber;
    //       }
    //     });
    //   }
    // });
    // let finalArray = _.values(tempProductsObject);
    // finalArray = finalArray.map((item: any) => {
    //   item.action = "C";
    //   return item;
    // });

    productsTemp = productsTemp.map((item: any) => {
      if (item.isMaterialNotExtended) {
        item.action = "D";
        // item["isValid"] = false;
      } else {
        item.action = "C";
      }
      return item;
    });
    setbrandRefNumbers({ ...brandRefNumbersTemp });
    setDeliveryBlockReason({ ...deliveryBlockReasonTemp })
    let rmparts: any = await getRemovedProducts(enquiryDataFetched) || invalidParts;

    if ((invalidParts && invalidParts.length > 0) || (rmparts && rmparts.length > 0) || (enquiryDataFetched && enquiryDataFetched.length > 0 && enquiryDataFetched[0].removedProducts && enquiryDataFetched[0].removedProducts.length > 0)) {
      // Iterate over invalidParts to filter out objects with isProcurementPart = true
      // let rmparts: any = enquiryDataFetched && enquiryDataFetched.length > 0 && enquiryDataFetched[0].removedProducts || invalidParts;

      rmparts.forEach((part: any) => {
        if (part.isProcurementPart === true || part.isZeroPrice) {
          // Check if an object with the same sequence number already exists in the products array
          const existingIndex = productsTemp.findIndex((item1: any) => item1.seq === part.seq);
          if (existingIndex === -1) {
            // Add the filtered object into the products array
            productsTemp.splice(part.seq - 1, 0, part);
          } else {
            console.log(`An object with sequence ${part.seq} already exists in the products array.`);
          }
        }
      });
    }

    setproducts([...productsTemp]);
    setActualProductLength(productsTemp.length);
    setisUpdateMode(true);
    setisEnquirySaved(true);
    // requestPriceAccess();
    if (sessionStorage.getItem('partsData')) {
      let partsDataExisting: any = sessionStorage.getItem("partsData");
      let newData: any = JSON.parse(partsDataExisting);
      sessionStorage.setItem("partsData", '');
      commonInsertionLogicforOES(newData, productsTemp);
    }
    return
  };
  const verifyEnquiryDataToEditMode = async (enquiryDataFetched: any, enquiryDataFetchedGetCall?: any, deletion?: any, queue?: any, isCopyfromItems?: any) => {
    let productsTemp: any = [];
    let procurement: any = [];
    let materialNotExtended: any = [];
    let isPro: any = false;
    let isMat: any = false;
    if (enquiryDataFetchedGetCall && Array.isArray(enquiryDataFetchedGetCall)) {
      enquiryDataFetchedGetCall.map((subenquiry: any, index: any) => {
        if (subenquiry && subenquiry.products && Array.isArray(subenquiry.products)) {
          subenquiry.products.map((productSub: any) => {
            productSub["enquiryNumber"] = subenquiry.enquiryNumber;
            productSub["id"] = subenquiry.id;
            productsTemp.push(JSON.parse(JSON.stringify(productSub)));
          });
        }
      });
      productsTemp.map((item: any) => {
        if (item.isMaterialNotExtended) {
          item.action = "D";
        } else {
          item.action = "C";
        }
        return item;
      });
    } else {
      productsTemp = JSON.parse(JSON.stringify(products));
    }
    productsTemp = productsTemp.filter((product: any) => product.isValid && !product.isSuperseeded);
    productsTemp = productsTemp.map((item: any) => {
      item["nowAvailability"] = 0;
      // item["nowAvailabilityChecked"] = false;
      // >>>>>>>>>>>>>>>>>>>>>>>>> NEW 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<
      if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
        item["hrs24Availability"] = 0;
        // item["hrs24AvailabilityChecked"] = false;
      }
      // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
      item["airAvailability"] = 0;
      // item["airAvailabilityChecked"] = false;
      item["action"] = item.isMaterialNotExtended ? "D" : "C";
      return item;
    });

    if (enquiryDataFetched && Array.isArray(enquiryDataFetched)) {
      setIsSpecialPrice(enquiryDataFetched[0].specialPriceRequestType);
      enquiryDataFetched.map((subEnquiries: any) => {
        if (subEnquiries && subEnquiries["verifiedData"] &&
          subEnquiries["verifiedData"]["items"] &&
          Array.isArray(subEnquiries["verifiedData"]["items"])
        ) {
          if (queue && isCopyfromItems) {
            subEnquiries["verifiedData"]["confirmations"] = subEnquiries["verifiedData"]["items"];
          }
          subEnquiries["verifiedData"]["items"].map((item: any) => {
            if (item && item.procurementBlockIndicator && item.procurementBlockIndicator === 'X') {
              procurement.push(item.materialNumber)
            }
          });
          subEnquiries["verifiedData"]["items"].map((item: any) => {
            if (item && item.isMaterialNotExtended) {
              materialNotExtended.push(item.materialNumber)
            }
          });
        }
        if (procurement && procurement.length > 0) {
          setprocuredPart(procurement);
          isPro = true;
          setIsProcuredPartExist(true);
        } else {
          isPro = false;
          setIsProcuredPartExist(false);
        }
        if (materialNotExtended && materialNotExtended.length > 0) {
          setMaterialNotExtendedPart(materialNotExtended);
          setIsMaterialNotExtendedExist(true);
          isMat = true;
        } else {
          isMat = false;
          setIsMaterialNotExtendedExist(false);
        }
        if (
          subEnquiries &&
          subEnquiries["verifiedData"] &&
          subEnquiries["verifiedData"]["confirmations"] &&
          Array.isArray(subEnquiries["verifiedData"]["confirmations"])
        ) {
          subEnquiries["verifiedData"]["confirmations"].map((confirmationsingle: any) => {
            let isItemFound = false;
            productsTemp && productsTemp.length > 0 && productsTemp.map((item: any) => {
              if (procurement && procurement.length > 0 && procurement.includes(item.productId)) {
                item["isProcurementPart"] = true;
                item['selected'] = false; // selected made false for procurement items -Bhargavi 25/03 for sending action C
              }
              if (materialNotExtended && materialNotExtended.length > 0 && materialNotExtended.includes(item.productId)) {
                item["isMaterialNotExtended"] = true;
                item['selected'] = false;
              }
              if (item.productId == confirmationsingle.proposedArticle && item.nowEnquiryItemNumber == confirmationsingle.enquiryItemNumber) {
                isItemFound = true;
                if (
                  confirmationsingle &&
                  confirmationsingle["deliveryBucketId"] &&
                  confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
                ) {
                  item["nowAvailability"] = parseInt(
                    confirmationsingle.availableQuantityforProposedArticle
                  );
                  item["nowAvailabilityChecked"] = item["nowAvailability"] ? true : false;

                  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< 24 hrs Code >>>>>>>>>>>>>>>>>>>>>>
                } else if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && confirmationsingle && confirmationsingle["deliveryBucketId"] && confirmationsingle["deliveryBucketId"] === "24") {
                  item["hrs24Availability"] = parseInt(
                    confirmationsingle.availableQuantityforProposedArticle
                  );
                  item["hrs24AvailabilityChecked"] = item["hrs24Availability"] ? true : false;
                  // .....................................................................
                } else {
                  item["airAvailability"] = parseInt(
                    confirmationsingle.availableQuantityforProposedArticle
                  );
                  item["airAvailabilityChecked"] = item["isProcurementPart"] && item["airAvailability"] !== 0 ? false : sessionStorage.getItem('userType') === 'FSE' ? false : item["airAvailability"] ? true : false;
                }
                item["isSpecialPriced"] =
                  confirmationsingle.priceActivIndicator &&
                    confirmationsingle.priceActivIndicator === "X"
                    ? true
                    : false;
                item["isSpecialPriceRequestPart"] = confirmationsingle.isSpecialPriceRequestPart ? true : false;
                item["isRejected"] = confirmationsingle.isRejected ? true : false;
                item["rsp"] = parseFloat(
                  confirmationsingle.maximumRetailSalesPrice
                ).toFixed(2);
                item["soh"] = parseInt(
                  confirmationsingle.totalAtpQuantityAtSiteLevel
                );
                item["nsp"] = parseFloat(
                  confirmationsingle.netSalesPrice
                ).toFixed(2);
                item["amt"] = parseFloat(
                  confirmationsingle.forwardOrderPrice
                ).toFixed(2);
                item["nspev"] = parseFloat(
                  confirmationsingle.netSellingPriceexcludingVAT ? confirmationsingle.netSellingPriceexcludingVAT : confirmationsingle.netSellingPriceExcludingVat
                ).toFixed(2);

                if (item["nowAvailabilityChecked"]) {
                  item["nowOfferedPrice"] = parseFloat(
                    confirmationsingle.nowOfferedPrice ? confirmationsingle.nowOfferedPrice : confirmationsingle.nowOfferedPrice
                  ).toFixed(2);
                  item["nowRequestPrice"] = parseFloat(
                    confirmationsingle.nowRequestPrice ? confirmationsingle.nowRequestPrice : confirmationsingle.nowRequestPrice
                  ).toFixed(2);
                  item["nowOfferedCalculatedPrice"] = parseFloat(
                    confirmationsingle.nowOfferedCalculatedPrice ? confirmationsingle.nowOfferedCalculatedPrice : confirmationsingle.nowOfferedCalculatedPrice
                  ).toFixed(2);
                  item["nowRequestPriceType"] =
                    confirmationsingle.nowRequestPriceType ? confirmationsingle.nowRequestPriceType : confirmationsingle.nowRequestPriceType
                  item["nowOfferedPriceType"] =
                    confirmationsingle.nowOfferedPriceType ? confirmationsingle.nowOfferedPriceType : confirmationsingle.nowOfferedPriceType
                }
                if (item["airAvailabilityChecked"]) {
                  item["airRequestPrice"] = parseFloat(
                    confirmationsingle.airRequestPrice ? confirmationsingle.airRequestPrice : confirmationsingle.airRequestPrice
                  ).toFixed(2);
                  item["airOfferedCalculatedPrice"] = parseFloat(
                    confirmationsingle.airOfferedCalculatedPrice ? confirmationsingle.airOfferedCalculatedPrice : confirmationsingle.airOfferedCalculatedPrice
                  ).toFixed(2);
                  item["airOfferedPrice"] = parseFloat(
                    confirmationsingle.airOfferedPrice ? confirmationsingle.airOfferedPrice : confirmationsingle.airOfferedPrice
                  ).toFixed(2);
                  item["airRequestPriceType"] =
                    confirmationsingle.airRequestPriceType ? confirmationsingle.airRequestPriceType : confirmationsingle.airRequestPriceType
                  item["airOfferedPriceType"] =
                    confirmationsingle.airOfferedPriceType ? confirmationsingle.airOfferedPriceType : confirmationsingle.airOfferedPriceType
                }
                item["aop"] = parseFloat(
                  confirmationsingle.forwardOrderPrice
                ).toFixed(2);
                if (
                  confirmationsingle &&
                  confirmationsingle["deliveryBucketId"] &&
                  confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
                ) {
                  item["nowEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                  item["nowProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                } else if (confirmationsingle && confirmationsingle["deliveryBucketId"] && confirmationsingle["deliveryBucketId"] === "24") {
                  item["hrs24EnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                  item["hrs24ProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                  //  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                } else {
                  item["airEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                  item["airProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                }
              }
            })
            if (!isItemFound && materialNotExtended && materialNotExtended.length === 0 && confirmationsingle && confirmationsingle['supercessionMaterial'] && confirmationsingle['availableQuantityforProposedArticle'] && parseInt(confirmationsingle['availableQuantityforProposedArticle']) &&
              confirmationsingle["superceededPartIndicator"] &&
              confirmationsingle["superceededPartIndicator"].toUpperCase() === "X") {
              let materialGroup = ""
              let enquiryIdDatabase = ""
              let enquiryNumberDataBase = ""
              let orgId = ""
              productsTemp.map((itemProd: any) => {
                if (itemProd.productId == confirmationsingle.supercessionMaterial) {
                  materialGroup = itemProd.materialGroup,
                    orgId = itemProd.orgId,
                    enquiryIdDatabase = itemProd.id
                  enquiryNumberDataBase = itemProd.enquiryNumber
                }
              })
              let newSupperSeededItem: any = {
                materialGroup: materialGroup,
                id: enquiryIdDatabase,
                enquiryNumber: enquiryNumberDataBase,
                isSuperseeded: true,
                quantity: 0,
                productId: confirmationsingle.proposedArticle,
                productIdDescription: confirmationsingle.materialDescription,
                productCost: 0,
                currency: sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED',
                unitOfMeasure: confirmationsingle.baseUnitofMeasure,
                VAT: "",
                orgId: orgId ? orgId : confirmationsingle.orgId ? confirmationsingle.orgId : "",
                enquiryReferenceNumber: "",
                isSpecialPriced: false,
                isSpecialPriceRequestPart: false,
                isRejected: false,
                nowAvailability: 0,
                nowAvailabilityChecked: false,
                hrs24Availability: 0,
                hrs24AvailabilityChecked: false,
                airAvailability: 0,
                airAvailabilityChecked: false,
                action: "I",
                dimensions: {
                  height: confirmationsingle.dimensionHeight,
                  width: confirmationsingle.dimensionWidth,
                  length: confirmationsingle.dimensionLength,
                  volume: confirmationsingle.dimensionVolume ? confirmationsingle.dimensionVolume : '',
                  grossWeight: confirmationsingle.unitWeightInKgs
                },
                attributes: [
                  {
                    name: "height",
                    value: confirmationsingle.dimensionHeight,
                  },
                  {
                    name: "width",
                    value: confirmationsingle.dimensionWidth,
                  },
                  {
                    name: "length",
                    value: confirmationsingle.dimensionLength,
                  },
                  {
                    name: "grossWeight",
                    value: confirmationsingle.unitWeightInKgs,
                  },
                  {
                    name: "volume",
                    value: 0,
                  },
                ],
                plant: "",
                nowEnquiryItemNumber: "",
                nowProposedItemNumber: "",
                hrs24EnquiryItemNumber: "",
                hrs24ProposedItemNumber: "",
                airEnquiryItemNumber: "",
                airProposedItemNumber: "",
                isValid: true,
                supercessionMaterial: confirmationsingle.supercessionMaterial
              }
              if (
                confirmationsingle &&
                confirmationsingle["deliveryBucketId"] &&
                confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
              ) {
                newSupperSeededItem["nowAvailability"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["quantity"] += parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["nowAvailabilityChecked"] = newSupperSeededItem["nowAvailability"] ? true : false;
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HOURS CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
              } else if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && newSupperSeededItem) {
                newSupperSeededItem["hrs24Availability"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["quantity"] += parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["hrs24AvailabilityChecked"] = newSupperSeededItem["hrs24Availability"] ? true : false;
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
              } else {
                newSupperSeededItem["airAvailability"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["quantity"] = parseInt(
                  confirmationsingle.availableQuantityforProposedArticle
                );
                newSupperSeededItem["airAvailabilityChecked"] = sessionStorage.getItem('userType') === 'FSE' ? false : newSupperSeededItem["airAvailability"] ? true : false;
              }
              newSupperSeededItem["isSpecialPriced"] =
                confirmationsingle.priceActivIndicator &&
                  confirmationsingle.priceActivIndicator === "X"
                  ? true
                  : false;
              newSupperSeededItem["isSpecialPriceRequestPart"] =
                confirmationsingle.isSpecialPriceRequestPart
                  ? true
                  : false;
              newSupperSeededItem["isRejected"] = confirmationsingle.isRejected ? true : false;
              newSupperSeededItem["rsp"] = parseFloat(
                confirmationsingle.maximumRetailSalesPrice
              ).toFixed(2);
              newSupperSeededItem["soh"] = parseInt(
                confirmationsingle.totalAtpQuantityAtSiteLevel
              );
              newSupperSeededItem["nsp"] = parseFloat(
                confirmationsingle.netSalesPrice
              ).toFixed(2);
              newSupperSeededItem["amt"] = parseFloat(
                confirmationsingle.forwardOrderPrice
              ).toFixed(2);
              newSupperSeededItem["nspev"] = parseFloat(
                confirmationsingle.netSellingPriceexcludingVAT ? confirmationsingle.netSellingPriceexcludingVAT : confirmationsingle.netSellingPriceExcludingVat
              ).toFixed(2);
              if (newSupperSeededItem["nowAvailabilityChecked"]) {
                newSupperSeededItem["nowRequestPrice"] = parseFloat(
                  confirmationsingle.nowRequestPrice ? confirmationsingle.nowRequestPrice : confirmationsingle.nowRequestPrice
                ).toFixed(2);
                newSupperSeededItem["nowOfferedPrice"] = parseFloat(
                  confirmationsingle.nowOfferedPrice ? confirmationsingle.nowOfferedPrice : confirmationsingle.nowOfferedPrice
                ).toFixed(2);
                newSupperSeededItem["nowOfferedCalculatedPrice"] = parseFloat(
                  confirmationsingle.nowOfferedCalculatedPrice ? confirmationsingle.nowOfferedCalculatedPrice : confirmationsingle.nowOfferedCalculatedPrice
                ).toFixed(2);
                newSupperSeededItem["nowRequestPriceType"] =
                  confirmationsingle.nowRequestPriceType ? confirmationsingle.nowRequestPriceType : confirmationsingle.nowRequestPriceType
                newSupperSeededItem["nowOfferedPriceType"] =
                  confirmationsingle.nowOfferedPriceType ? confirmationsingle.nowOfferedPriceType : confirmationsingle.nowOfferedPriceType
              }
              if (newSupperSeededItem["airAvailabilityChecked"]) {
                newSupperSeededItem["airRequestPrice"] = parseFloat(
                  confirmationsingle.airRequestPrice ? confirmationsingle.airRequestPrice : confirmationsingle.airRequestPrice
                ).toFixed(2);
                newSupperSeededItem["airOfferedCalculatedPrice"] = parseFloat(
                  confirmationsingle.airOfferedCalculatedPrice ? confirmationsingle.airOfferedCalculatedPrice : confirmationsingle.airOfferedCalculatedPrice
                ).toFixed(2);
                newSupperSeededItem["airOfferedPrice"] = parseFloat(
                  confirmationsingle.airOfferedPrice ? confirmationsingle.airOfferedPrice : confirmationsingle.airOfferedPrice
                ).toFixed(2);
                newSupperSeededItem["airRequestPriceType"] =
                  confirmationsingle.airRequestPriceType ? confirmationsingle.airRequestPriceType : confirmationsingle.airRequestPriceType
                newSupperSeededItem["airOfferedPriceType"] =
                  confirmationsingle.airOfferedPriceType ? confirmationsingle.airOfferedPriceType : confirmationsingle.airOfferedPriceType

              }
              newSupperSeededItem["aop"] = parseFloat(
                confirmationsingle.forwardOrderPrice
              ).toFixed(2);
              if (
                confirmationsingle &&
                confirmationsingle["deliveryBucketId"] &&
                confirmationsingle["deliveryBucketId"].toUpperCase() === "NOW"
              ) {
                newSupperSeededItem["nowEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                newSupperSeededItem["nowProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<<<<<<<<<
              } else if (confirmationsingle && confirmationsingle["deliveryBucketId"] && confirmationsingle["deliveryBucketId"] === "24") {
                newSupperSeededItem["hrs24EnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                newSupperSeededItem["hrs24ProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
                //  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
              } else {
                newSupperSeededItem["airEnquiryItemNumber"] = confirmationsingle.enquiryItemNumber ? confirmationsingle.enquiryItemNumber : "";
                newSupperSeededItem["airProposedItemNumber"] = confirmationsingle.proposedItemNumber ? confirmationsingle.proposedItemNumber : "";
              }
              if (materialGroup) {
                productsTemp.push(newSupperSeededItem)
              }
            }
          });
        }
      });
    }

    if (queue) {
      setIsQueue(true);
    }
    let rmp: any = await getRemovedProducts(enquiryDataFetchedGetCall) || invalidParts;

    if ((invalidParts && invalidParts.length > 0) || (rmp && rmp.length > 0) || (enquiryDataFetchedGetCall && Array.isArray(enquiryDataFetchedGetCall) && enquiryDataFetchedGetCall[0].removedProducts && enquiryDataFetchedGetCall[0].removedProducts.length > 0)) {
      // Iterate over invalidParts to filter out objects with isProcurementPart = true

      rmp.forEach((part: any) => {
        if (part.isProcurementPart === true || part.isZeroPrice) {
          // Check if an object with the same sequence number already exists in the products array
          const existingIndex = productsTemp.findIndex((item1: any) => item1.seq === part.seq);
          if (existingIndex === -1) {
            // Add the filtered object into the products array
            productsTemp.splice(part.seq - 1, 0, part);
          } else {
            console.log(`An object with sequence ${part.seq} already exists in the products array.`);
          }
        }
      });

      // Logging the modified products array for verification
    }
    // let finalArray = _.values(tempProductsObject);
    setproducts([...productsTemp]);
    setActualProductLength(productsTemp.length);
    setchangeData([...productsTemp]);
    if (isPro || (procurement && procurement.length > 0)) {
      setIsProcuredPartExist(true);
      setplaceorderEnabled(true); // updated to true pproblem parts
    } else {
      setIsProcuredPartExist(false);
      setplaceorderEnabled(true);
    }
    if (isMat || (materialNotExtended && materialNotExtended.length > 0)) {
      // let invalidP: any = invalidParts;
      // let materialE: any = productsTemp.filter((product: any) => product.action !== 'D' && product.isMaterialNotExtended && product.seq);
      // invalidP = [...invalidParts, ...materialE]
      // setInvalidParts(invalidP)
      setIsMaterialNotExtendedExist(true);
      setplaceorderEnabled(false);
    } else {
      setIsMaterialNotExtendedExist(false);
      setplaceorderEnabled(true);
    }
    setisAvailabiityChecked(true);
    setorderQueued(false);
    setisAvailabiityCheckedAtTime(new Date());
    // if (deletion) {
    //   setplaceorderEnabled(false);
    // } else {
    //   setplaceorderEnabled(true);
    // }
  };
  const getZeroParts = (parts: any) => {
    let zeroParts: any = []
    if (parts && parts.length > 0) {
      parts.map((item: any) => {
        if (item) {
          if ((item.nsp && item.nsp === "0.00") || (item.nsp && parseFloat(item.nsp) === 0)) {
            zeroParts.push(item);
          }
        }
      })
      setZeroProducts([...zeroParts])
      return zeroParts;
      // let invalid: any = [...invalidParts, ...zeroParts];
      // setInvalidParts(invalid);
    }
  }

  useEffect(() => {
    if (zeroProducts && zeroProducts.length > 0 && isAvailabiityChecked && !hideZeroMessage) {
      openZeroPricePopup(true);
    }
  }, [zeroProducts, isAvailabiityChecked]);

  // commented in problem parts

  useEffect(() => {
    if (procuredPart && procuredPart.length > 0 && isAvailabiityChecked) {
      openZeroPricePopup(true);
    } else {
      setIsProcuredPartExist(false);
    }
  }, [procuredPart, isAvailabiityChecked]);

  useEffect(() => {
    if (materialNotExtendedPart && materialNotExtendedPart.length > 0 && isAvailabiityChecked) {
      openZeroPricePopup(true);
    } else {
      setIsMaterialNotExtendedExist(false);
    }
  }, [materialNotExtendedPart, isAvailabiityChecked]);

  const callChangeData = async () => {
    let zeroParts: any = []
    if (materialNotExtendedPart && materialNotExtendedPart.length === 0) {
      zeroParts = await getZeroParts(changeData);
    }
    if (zeroParts && zeroParts.length > 0) {
      updateOnly(changeData, undefined, undefined, undefined, zeroParts);
    } else {
      updateOnly(changeData);
    }
    setchangeData(null);
  }

  useEffect(() => {
    if (changeData) {
      callChangeData();
    }
  }, [changeData]);

  useEffect(() => {
    if (enquiryData != null && !accessData && sessionStorage.getItem('userType') !== 'FSE') {
      requestPriceAccess()
    }
  }, [enquiryData, accessData])

  useEffect(() => {
    let checkedTotal: any
    let reqType: any
    let checkedCalculatedTotal: any
    let checkedReqType: any

    if (verificationData && verificationData.data && verificationData.data.length > 0) {
      verificationData.data.map((data: any) => {
        reqType = data && data.specialPriceRequestType
        checkedTotal = data && data.specialRequestOfferedTotalPricePercentage
        checkedCalculatedTotal = data && data.specialRequestOfferedTotalPriceCalculated
        checkedReqType = data && data.specialRequestOfferedTotalPriceCurrency
      })
    }
    setRequestedTotalType(reqType);
    setEstmateTotal(checkedTotal);
    setRequestedCaluclatedTotalPrice(checkedCalculatedTotal);
    setRequestedCalculatedTotalType(checkedReqType);
  }, [verificationData])

  useEffect(() => {
  }, [requestStatus, enquiryAction, accessData, isSPRModifyData])

  useEffect(() => {
    let requestStatusHideEnquiry: any;
    let reorderEnquiry: any;
    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
      requestStatusHideEnquiry = ele && ele.status
      reorderEnquiry = ele && ele.expiredAfterApproval
    })

    setRequestStatus(requestStatusHideEnquiry);
    setShowReorderEnquiry(reorderEnquiry)
  }, [viewSpecialPriceDetails])

  const getEstimatedTotal = (isAvailabiityChecked?: any, withFilter?: any, withDeletion?: any) => {
    let productsTemp = JSON.parse(JSON.stringify(products))
    if (withFilter) {
      productsTemp = productsTemp.filter((item: any) => !isAvailabiityChecked ||
        (isAvailabiityChecked && (availability === "All" || (availability === "Now" && item.nowAvailability && !item.airAvailability) ||
          (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && availability === "24" && item.hrs24Availability && !item.nowAvailability && !item.airAvailability) ||
          (availability === "BO" && item.airAvailability))))
    }
    if (isAvailabiityChecked && productsTemp && productsTemp.length > 0) {
      let total: any = 0;
      let individualPrice: any = [];
      productsTemp.map((item: any) => {
        let brand: any = enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`];
        if (!withDeletion || (withDeletion && !item['selected'])) {
          if (item.nsp || item.aop) {
            if (item.nowAvailabilityChecked) {
              if (enquiryAction == 'view' && item.nowApprovedPrice && item.nowApprovedPrice !== '' && item.nowApprovedPrice !== 'NaN') {
                total += parseFloat(item.nowAvailability) * parseFloat(item.nowApprovedPrice);
              } else {
                if (item.isSpecialPriceRequestPart && item.isRejected == false && requestStatus == 'Approved') {
                  if (item.nowOfferedPriceType == 'byPercentage' && item["nowOfferedCalculatedPrice"] && item["nowOfferedCalculatedPrice"] !== '' && item["nowOfferedCalculatedPrice"] !== 'NaN') {
                    individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedCalculatedPrice) })
                    total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedCalculatedPrice);
                  } else if (item.nowOfferedPriceType == 'byCurrency' && item["nowOfferedPrice"] && item["nowOfferedPrice"] !== '' && item["nowOfferedPrice"] !== 'NaN') {
                    individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedPrice) })
                    total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedPrice);
                  } else {
                    individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
                    total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
                  }
                } else {
                  if (requestedTotalType === "total" && requestedCalculatedTotalType === "By %" && requestStatus === 'Approved') {
                    if (item.isSpecialPriced) {
                      total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
                      individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
                    } else {
                      let nsp1: any = (parseFloat(estmateTotal) * item["nsp"]) / 100;
                      let newNsp: any = parseFloat(item["nsp"]) - parseFloat(nsp1);
                      total += parseFloat(item.nowAvailability) * parseFloat(newNsp)
                      individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(newNsp) })
                    }
                  } else if (requestedTotalType === "total" && requestedCalculatedTotalType === "By AED" && requestStatus === 'Approved') {
                    if (item.isSpecialPriced) {
                      total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
                      individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
                    } else {
                      let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
                      let newNsp: any = (parseFloat(value) * item["nsp"]) / 100;
                      let nsp: any = item["nsp"] - parseFloat(newNsp);
                      total += parseFloat(item.nowAvailability) * parseFloat(nsp)
                      individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(nsp) })
                    }
                  } else {
                    individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
                    total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
                  }
                }
              }
            }
            // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<
            if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked) {
              individualPrice.push({ make: brand, total: parseFloat(item.hrs24Availability) * parseFloat(item.nsp) })
              total += parseFloat(item.hrs24Availability) * parseFloat(item.nsp);
            }
            // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
            if (item.airAvailabilityChecked && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer" && !item.isProcurementPart) {
              if (enquiryAction === 'view' && item.airApprovedPrice && item.airApprovedPrice !== '' && item.airApprovedPrice !== 'NaN') {
                total += parseFloat(item.airAvailability) * parseFloat(item.airApprovedPrice);
              } else {
                if (item.isSpecialPriceRequestPart && item.isRejected == false && requestStatus == 'Approved') {
                  if (item.airOfferedPriceType == 'byPercentage' && item["airOfferedCalculatedPrice"] && item["airOfferedCalculatedPrice"] !== '' && item["airOfferedCalculatedPrice"] !== 'NaN') {
                    individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.airOfferedCalculatedPrice) })
                    total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedCalculatedPrice);
                  } else if (item.airOfferedPriceType == 'byCurrency' && item["airOfferedPrice"] && item["airOfferedPrice"] !== '' && item["airOfferedPrice"] !== 'NaN') {
                    individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.airOfferedPrice) })
                    total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedPrice);
                  } else {
                    individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.nsp) })
                    total += parseFloat(item.airAvailability) * parseFloat(item.aop);
                  }
                } else {
                  if (requestedTotalType === "total" && requestedCalculatedTotalType === "By %" && requestStatus === 'Approved') {
                    if (item.isSpecialPriced) {
                      total += parseFloat(item.airAvailability) * parseFloat(item.aop);
                      individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.aop) })
                    } else {
                      let nsp1: any = (parseFloat(estmateTotal) * item.aop) / 100;
                      let newNsp: any = parseFloat(item.aop) - parseFloat(nsp1);
                      total += parseFloat(item.airAvailability) * parseFloat(newNsp)
                      individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(newNsp) })
                    }
                  } else if (requestedTotalType === "total" && requestedCalculatedTotalType === "By AED" && requestStatus === 'Approved') {
                    if (item.isSpecialPriced) {
                      total += parseFloat(item.airAvailability) * parseFloat(item.aop);
                      individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.aop) })
                    } else {
                      let value: any = requestedCaluclatedTotalPrice.replace("%", "").replace("-", "");
                      let newNsp: any = (parseFloat(value) * item.aop) / 100;
                      let nsp: any = item.aop - parseFloat(newNsp);
                      total += parseFloat(item.airAvailability) * parseFloat(nsp)
                      individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(nsp) })
                    }
                  } else {
                    individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.aop) })
                    total += parseFloat(item.airAvailability) * parseFloat(item.aop);
                  }
                }
              }
            }
          } else {
            total = total;
          }
        }
      });
      if (individualPrice && individualPrice.length > 0) {
        var holder: any = {};
        individualPrice.forEach(function (d: any) {
          if (holder.hasOwnProperty(d.make)) {
            holder[d.make] = holder[d.make] + d.total;
          } else {
            holder[d.make] = d.total;
          }
        });

        var obj2: any = [];

        for (var prop in holder) {
          obj2.push({ make: prop, total: parseFloat(holder[prop]).toFixed(2) });
        }
        sessionStorage.setItem('iprice', JSON.stringify(obj2));
      }
      return parseFloat(total).toFixed(2);
      // }
    }
    return 0;
  };

  const getViewEstimatedTotal = () => {
    let total: any = 0;
    if (enquiryData && enquiryData.length > 0) {
      enquiryData.map((data: any) => {
        if (enquiryAction === 'view' && data.specailPriceRequestDetails) {
          total = getEstimatedTotal(true, true)
        } else {
          /* commented because estimated total should be consistent before and after order placement -- Bhargavi 09/03/2023 */
          // if (data && data.netOrderValue) {
          //   total = parseFloat(total) + parseFloat(data.netOrderValue);
          // } else {
          total = getEstimatedTotal(isAvailabiityChecked, true)
          // }
        }
      })
    }
    if (requestedTotalType === "total" && requestedCalculatedTotalType === "By %" && requestStatus === 'Approved') {
      return parseFloat(requestedCaluclatedTotalPrice).toFixed(2);
    } else if (requestedTotalType === "total" && requestedCalculatedTotalType === "By AED" && requestStatus === 'Approved') {
      return parseFloat(estmateTotal).toFixed(2);
    } else {
      return parseFloat(total).toFixed(2);
    }
    // return parseFloat(total).toFixed(2)
  }

  const getEstimatedTotalSap = (withFilter?: any, newData?: any) => {
    let productsTemp = JSON.parse(JSON.stringify(products))
    if (withFilter) {
      productsTemp = productsTemp.filter((item: any) => !isAvailabiityChecked ||
        (isAvailabiityChecked && (availability === "All" || (availability === "Now" && item.nowAvailability && !item.airAvailability) ||
          (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && availability === "24" && item.hrs24Availability && !item.nowAvailability && !item.airAvailability) ||
          (availability === "BO" && item.airAvailability))))
    }
    let sum: any = 0;
    let individualPrice: any = [];
    if (newData.data && newData.data.length > 0) {
      newData.data.map((data: any) => {
        if (data && data.verifiedData && data.verifiedData.header && data.verifiedData.header.netOrderValue) {
          individualPrice.push({ make: data.make, total: data.verifiedData.header.netOrderValue })
          sum = parseFloat(sum) + parseFloat(data.verifiedData.header.netOrderValue)
        }
      });
      setEstimatedTotal(sum);
      setIndividialPrice(individualPrice)
    }
    return sum;
  };

  const getIsAirAvalibityExistCount = () => {
    let airAvailabilityProducts = products.filter((product: any) => product.airAvailability > 0);
    return airAvailabilityProducts.length;
  }

  const getnetOrderValue = (products: any, changeCheckBox: any, withDeletion?: any) => {
    if (changeCheckBox) {
      const data: any = sessionStorage.getItem('iprice');
      let individualCost = JSON.parse(data);
      if (products && products.length > 0 && individualCost && individualCost.length > 0) {
        let brand: any = enquiryMapping[`${formatMaterialGroup(products[0].materialGroup)}`];
        if (enquiryMapping[`${formatMaterialGroup(products[0].materialGroup)}`]) {
          if (individualCost && individualCost.length > 0) {
            let value: any = individualCost.filter((a: any) => a.make == brand);
            if (value && value.length > 0) {
              return value[0].total;
            }
          }
        }
      } else {
        return 0;
      }
    } else {
      if (products && products.length > 0 && individualPrice && individualPrice.length > 0) {
        let brand: any = enquiryMapping[`${formatMaterialGroup(products[0].materialGroup)}`];
        if (enquiryMapping[`${formatMaterialGroup(products[0].materialGroup)}`]) {
          if (individualPrice && individualPrice.length > 0) {
            let value: any = individualPrice.filter((a: any) => a.make == brand);
            if (value && value.length > 0) {
              return value[0].total;
            }
          }
        }
      } else {
        return 0;
      }
    }
  }

  const requestPriceAccess = async () => {
    let enquiryId = enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""
    if (!_.isEmpty(enquiryId) && customerTierType && customerTierType.toLowerCase() === "dealer") {
      let data: any = await requestSpecialPriceService.requestSpecialPriceAccessAPI(
        {
          "sapCustomerId": sapCustomerId,
          "enquiryId": enquiryId
        }
      )
      setAccessData(data);
    }
  }

  const saveUpdateEnquiryOnDeleteUnavailbility = async (shouldCheckAvailability: any, withDeletion?: any, p?: any) => {
    let data: any = null;
    setIsQuantityUpdated(false);
    setActualProductLength(p && p.length > 0 ? p.length : products.length);
    let productsTemp = JSON.parse(JSON.stringify(p && p.length > 0 ? p : products));
    let productsExtract = [...productsTemp]
    let inValidParts = productsExtract.filter((product: any) => !product.isValid).map(item => item['productId']);
    productsTemp = productsTemp.filter((product: any) => product.isValid);
    // productsTemp = productsTemp.filter((product: any) => !product.isMaterialNotExtended);
    if (
      productsTemp &&
      Array.isArray(productsTemp) &&
      productsTemp.length > 0
    ) {
      let emptyRefNo = false;
      let emptyQuantity = false;
      productsTemp = productsTemp.map((item: any) => {
        if (item && item.materialGroup) {
          if (
            item && item.materialGroup && !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
          ) {
            emptyRefNo = true;
          }
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }
        if (item && item.materialGroup) {
          item["enquiryReferenceNumber"] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`];
          item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
          item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';
        } else {
          item["enquiryReferenceNumber"] = '';
          item["deliveryBlockReason"] = '';
          item["deliveryBlockReasonCode"] = '';
        }
        item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(`All reference numbers are mandatory`);
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater than zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      let preventGetCall = false;
      if (!isEnquirySaved) {
        data = await dispatch(createEnquiry(customerTierType, customerAccountType, referenceNumber, productsTemp, sessionStorage.getItem('userType') === 'FSE' ? firstName + ' ' + lastName : firstName, sessionStorage.getItem('userType') === 'FSE' ? webApplicationLoginId : b2cUserId, enquirySessionId));
        sessionStorage.setItem('subEnquiryId', JSON.stringify(data && data.data && data.data.subEnquiries && data.data.subEnquiries));
        setSubEnquiriesData(data && data.data && data.data.subEnquiries && data.data.subEnquiries);
      } else {
        let filteredItemNos: any = []
        let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

        const isSuperseededExist = (item: any) => {
          let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
          if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
            return true
          } else {
            return false
          }
        }
        productsTemp.map((item: any) => {
          if (withDeletion) {
            let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
            // setIsQuantityUpdated(true) // Aded this condition to make place order disabled again if product got deleted
            if (item['nowAvailability'] == 0) {
              let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
              if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
              } else {
                filteredItemNos.push({
                  productId: item.productId,
                  itemNumber: item.nowEnquiryItemNumber || item.hrs24EnquiryItemNumber || item.airEnquiryItemNumber
                })
                item["action"] = "D";
                item["isManualDelete"] = true;
              }
            }
          }

          // newly added problem parts code start

          if (zeroProducts && zeroProducts.length > 0 && isAvailabiityChecked) {
            if ((parseFloat(item.nsp) === 0 || parseFloat(item.nsp) === 0.00) && !item.isSuperseeded && !item.isMaterialNotExtended && !isSuperseededExist(item)) {
              item.action = "D";
              item.isZeroPrice = true;
            }
          }
          if (item.isMaterialNotExtended) {
            item["action"] = "D";
          }
          if (item.isProcurementPart && item.nowAvailability && parseInt(item.nowAvailability) !== 0 && !withDeletion) {
            item["action"] = "C";
          }

          if (item.isProcurementPart && item.airAvailability && parseInt(item.airAvailability) !== 0 && parseInt(item.nowAvailability) === 0) {
            if (isSuperseededExist(item)) {
              item["action"] = "C";
            } else {
              item["action"] = "D";
            }
            item["airAvailabilityChecked"] = false;
          }
          // newly added problem parts code end

          // item["nowAvailabilityChecked"] = false;
          // item["hrs24AvailabilityChecked"] = false;
          // item["airAvailabilityChecked"] = false;
          if (item.nowAvailabilityChecked) {
            item["productCost"] = 0;
          }
          // >>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<
          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked) {
            item["productCost"] = 0;
          }
          //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (item.airAvailabilityChecked) {
            item["productCost"] = 0;
          }
          return item
        })
        productsTemp = productsTemp.filter((item: any) => {
          let isNotDeleted = true
          filteredItemNos.map((part: any) => {
            if (item.isSuperseeded && item.supercessionMaterial == part.productId &&
              (part.itemNumber == item.nowEnquiryItemNumber ||
                part.itemNumber == item.nowEnquiryItemNumber)) {
              isNotDeleted = false
            }
          })
          return isNotDeleted
        })
        let updationData: any = {};
        let insertData: any = [];

        let productsTempNew: any = productsTemp;
        let rmpro: any = await getRemovedProducts(enquiryData);

        // Filter the productsTempNew array
        productsTempNew = productsTempNew.filter((product: any) => {
          if ((invalidParts && invalidParts.length > 0) || (rmpro && rmpro.length > 0) || (enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].removedProducts && enquiryData[0].removedProducts.length > 0)) {
            // Check if the product's sequence number exists in invalidParts

            let inp: any = rmpro || enquiryData[0].removedProducts || invalidParts;
            const seqExistsInInvalidParts = inp.some((part: any) => part.seq === product.seq);
            // If the sequence number exists in invalidParts, apply filter
            if (seqExistsInInvalidParts) {
              return (product.action !== 'D' && !product.isProcurementPart) || (product.action !== 'D' && !product.isZeroPrice);
            } else {
              // If the sequence number doesn't exist in invalidParts, retain the item
              return true;
            }
          } else {
            return product;
          }
        });

        productsTempNew.map((singleProduct: any) => {
          if (singleProduct["id"]) {
            if (updationData[singleProduct["id"]]) {
              updationData[singleProduct["id"]].push(singleProduct);
            } else {
              updationData[singleProduct["id"]] = [singleProduct];
            }
          } else {
            insertData.push(singleProduct)
          }
        });
        let finalData: any = [];
        Object.keys(updationData).map((singlekey: any) => {
          let tc: any = getEstimatedTotal(isAvailabiityChecked, true, withDeletion);
          finalData.push({
            id: singlekey,
            masterReferenceNumber: referenceNumber,
            products: updationData[singlekey],
            // totalCost: changeCheckBox ? getEstimatedTotal(true, withDeletion) : estTotal,
            totalCost: getEstimatedTotal(isAvailabiityChecked, true, withDeletion),
            netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true, withDeletion)
          });
        });
        let enquiryDetailsQueueCheckData: any;
        if (checkAvailabilityButtonText.toLowerCase() !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL.toLowerCase()) {
          //Trigger verification queued check before attempting update
          enquiryDetailsQueueCheckData = await dispatch(verifyEnquiryQueueCheck(customerTierType, enquiryData[0].B2bEnquiryId, enquiryData[0].customerId));
          //Error enquiryDetailsQueueCheckData.message.status == "E"
          if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message?.status && enquiryDetailsQueueCheckData.message?.status == "E") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage(enquiryDetailsQueueCheckData?.message?.message);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //Queued enquiryDetailsQueueCheckData.message.message === "verification queued"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification queued") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setIsQueue(true);
            setSuccessMessage(sessionStorage.getItem('userType') === 'FSE' ?
              'Enquiry has been submitted for verification and will take few minutes to get completed. You can check its status by selecting “Queued Enquiries” in the Order-Enquiry List.'
              : 'Enquiry queued for verification, press the verification button after 1 min');
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "verification in progress"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification in progress") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setIsQueue(true);
            setSuccessMessage('Enquiry verification in progress, press the verification button after 1 min.');
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "enquiry changed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "enquiry changed") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage('Enquiry changed, press the verification button after 10 seconds');
            setIsQueue(false);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //Success enquiryDetailsQueueCheckData.message.message === "verification completed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification completed") {
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL);
            //should be removed and format should be updated on api side
            setIsQueue(false);
            let verificationData: any = []
            let hasConfirmationData = true;
            if (enquiryDetailsQueueCheckData && Array.isArray(enquiryDetailsQueueCheckData.data)) {
              enquiryDetailsQueueCheckData.data.map((item: any) => {
                if (item && item.confirmations && Array.isArray(item.confirmations) && item.confirmations.length > 0) {
                  verificationData.push({
                    verifiedData: item
                  })
                } else {
                  hasConfirmationData = false;
                }
              })
            }
            if (hasConfirmationData) {
              //should be removed and format should be updated on api side
              preventGetCall = true;
              let logData: any = await dispatch(getEnquiryDetails(customerTierType,
                enquiryData &&
                Array.isArray(enquiryData) &&
                enquiryData.length > 0 &&
                enquiryData[0].B2bEnquiryId,
                (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
              ));
              // let logData: any = await dispatch(getOrderLogs(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : data.data.masterId))
              if (logData && logData.message && logData.message.status === "E") {
                setTimeout(() => {
                  setOpenSnackbarType("error");
                  setOpenErrorSnackbar(true);
                  setSuccessMessage(logData && logData.message && logData.message.message && logData.message.message);
                  handleopenCloseSnackbar(true);
                }, 2000)
                setIsDisableOrderLogButton(true);
              } else {
                await getOrderLogsData(logData?.data);
                setIsDisableOrderLogButton(false);

                let rmpro: any = await getRemovedProducts(logData?.data?.data);
                setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : logData?.data?.data[0].removedProducts ? logData?.data?.data[0].removedProducts : []);
                setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : logData?.data?.data[0].removedProducts?.length)

                // setInvalidParts(logData?.data?.data[0].removedProducts ? logData?.data?.data[0].removedProducts : []);
                // setRemovedProductsLength(logData?.data?.data[0].removedProducts?.length)
              }
              setGetLatestOrderCount(false);
              // let savedData: any = await dispatch(getEnquiryDetails(customerTierType,
              //   enquiryData &&
              //     Array.isArray(enquiryData) &&
              //     enquiryData.length > 0 &&
              //     enquiryData[0].B2bEnquiryId
              //     ? enquiryData[0].B2bEnquiryId
              //     : data.data.masterId,
              //   (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
              // ));
              setisAvailabiityChecked(true);
              setDisableQuotation(false);
              setorderQueued(false);
              setisAvailabiityCheckedAtTime(new Date());
              verifyEnquiryDataToEditMode(verificationData, logData.data.data, withDeletion, true)
              setSuccessMessage(enquiryDetailsQueueCheckData.message.message);
              setOpenErrorSnackbar(false);
              handleopenCloseSnackbar(true);
              if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                setSelectedDeliveryTypeError(false);
              }
              return;
            } else {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage('Enquiry verification in progress, press the verification button after 1 min.');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
              return;
            }
          }
          else {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage(enquiryDetailsQueueCheckData?.message?.message);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
        }
        let data1: any;
        data = await dispatch(
          updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, true, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
          )
        );
        if (data && data.data) {
          data1 = await dispatch(
            updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, false, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
            )
          );
        }
        if (!(data1 && data1.data && Array.isArray(data1.data))) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setSuccessMessage(data1 && data1.error && typeof data1.error === 'string' ? data1.error : data1?.message && data1?.message?.message && typeof data1?.message?.message === 'string' ? data1?.message?.message : `Order Enquiry Update Failed`);
          // setSuccessMessage(data1 && data1.error && typeof data1.error === 'string' ? data1.error : `Order Enquiry Update Failed`);
          handleopenCloseSnackbar(true);
          return
        }
      }
      if (
        ((data && data.data && data.data.masterId) ||
          (isEnquirySaved && enquiryData[0].B2bEnquiryId)) && !preventGetCall
      ) {
        await dispatch(setEnquiryId(enquiryData &&
          Array.isArray(enquiryData) &&
          enquiryData.length > 0 &&
          enquiryData[0].B2bEnquiryId
          ? enquiryData[0].B2bEnquiryId
          : data.data.masterId));
        let savedData: any = await dispatch(getEnquiryDetails(customerTierType,
          enquiryData &&
            Array.isArray(enquiryData) &&
            enquiryData.length > 0 &&
            enquiryData[0].B2bEnquiryId
            ? enquiryData[0].B2bEnquiryId
            : data.data.masterId,
          (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
        ));
        if (
          savedData &&
          savedData.data &&
          savedData.data.data &&
          savedData.data.data[0]
        ) {

          let rmpro: any = await getRemovedProducts(savedData?.data?.data);
          setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : savedData.data.data[0].removedProducts ? savedData.data.data[0].removedProducts : []);
          setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : savedData?.data?.data[0].removedProducts?.length)

          // setInvalidParts(savedData.data.data[0].removedProducts ? savedData.data.data[0].removedProducts : []);
          // setRemovedProductsLength(savedData?.data?.data[0].removedProducts?.length);
          await getOrderLogsData(savedData?.data);
          setenquiryData(savedData?.data?.data);
          setGetVerifyData(savedData);
          if (inValidParts.length > 0 && sessionStorage.getItem('userType') === 'FSE') {
            sendEmailToMMForInvalidParts(inValidParts, savedData.data.data)
          }
          if (!isEnquirySaved) {
            setisUpdateMode(true);
            setisEnquirySaved(true);
            // requestPriceAccess();
            setActualProductLength(savedData.data.data[0].products.length)
          }
          if (sessionStorage.getItem('userType') === 'FSE') {
            await enquiryDataToEditMode(savedData.data.data, false, withDeletion);
          }
          if (!shouldCheckAvailability) {
            enquiryDataToEditMode(savedData.data.data, false, withDeletion);
            if (withDeletion) {
              setIsDelted(true)
            }
            setSuccessMessage(
              isEnquirySaved
                ? `Order Enquiry Updated successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
                : `Order Enquiry saved successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
            );
            if (importOes) {
              await dispatch(setEnquiryId(savedData.data.data[0].B2bEnquiryId));
              return savedData.data.data[0].B2bEnquiryId;
            }
            setOpenErrorSnackbar(false);
            handleopenCloseSnackbar(true);
            if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
              setSelectedDeliveryTypeError(false);
            }
          }
        } else {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setSuccessMessage(
            `Order Enquiry ${!isEnquirySaved ? "Creation" : "Update"} Failed`
          );
          setOpenErrorSnackbar(false);
          handleopenCloseSnackbar(true);
          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
            setSelectedDeliveryTypeError(false);
          }
        }
        if (
          shouldCheckAvailability &&
          ((savedData &&
            savedData.data &&
            savedData.data.data[0] &&
            savedData.data.data[0].B2bEnquiryId) ||
            (enquiryData && enquiryData[0].B2bEnquiryId))
        ) {
          isChangeCheckBox(false);
          sessionStorage.setItem('iprice', '');
          setprocuredPart([]);
          let availData: any = await dispatch(
            verifyEnquiry(customerTierType,
              (enquiryData &&
                Array.isArray(enquiryData) &&
                enquiryData[0].B2bEnquiryId
                ? enquiryData[0].B2bEnquiryId
                : savedData &&
                savedData.data &&
                savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId), enquirySessionId
            )
          );
          setVerificationData(availData);
          sessionStorage.setItem('reset-the-orderlog-count', "false")

          let data: any = await dispatch(getEnquiryDetails(customerTierType,
            enquiryData &&
            Array.isArray(enquiryData) &&
            enquiryData.length > 0 &&
            enquiryData[0].B2bEnquiryId,
            (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
          ));
          await getOrderLogsData(data?.data);
          // let data: any = await dispatch(getOrderLogs(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : savedData && savedData.data && savedData.data.data[0] && savedData.data.data[0].B2bEnquiryId))
          if (data && data.message && data.message.status === "E") {
            setTimeout(() => {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage(data && data.message && data.message.message && data.message.message);
              handleopenCloseSnackbar(true);
            }, 2000)
            setIsDisableOrderLogButton(true);
          } else {

            let rmpro: any = await getRemovedProducts(data?.data?.data);
            setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
            setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : data?.data?.data[0].removedProducts?.length)

            // setInvalidParts(data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
            // setRemovedProductsLength(data?.data?.data[0].removedProducts?.length)
            setIsDisableOrderLogButton(false);
          }
          setGetLatestOrderCount(false);

          if (
            availData &&
            availData.data &&
            Array.isArray(availData.data) &&
            availData.data.length > 0
          ) {
            setMaterialNotExtendedPart([]);
            setIsMaterialNotExtendedExist(false);
            if (availData.message.status !== 'I') {
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
              setisAvailabiityCheckedAtTime(new Date());
              setIsQueue(false);
              verifyEnquiryDataToEditMode(availData.data, savedData.data.data);
              setSuccessMessage(`Availability and Price successfully fetched`);
              setOpenErrorSnackbar(false);
              handleopenCloseSnackbar(true);
              setisAvailabiityChecked(true);
              setDisableQuotation(false);
              // requestPriceAccess();
              if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                setSelectedDeliveryTypeError(false);
              }
            } else {
              //Set Check Availability Button to show verification queued if check data is not verified.
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.QUEUED)
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setIsQueue(true);
              setSuccessMessage(sessionStorage.getItem('userType') === 'FSE' ?
                'Enquiry has been submitted for verification and will take few minutes to get completed. You can check its status by selecting “Queued Enquiries” in the Order-Enquiry List.'
                : 'Enquiry queued for verification, press the verification button after 1 min');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
            }
          } else {
            enquiryDataToEditMode(savedData.data.data, true, false, false);
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            if (availData && availData.error && Array.isArray(availData.error) && availData.error.length > 0) {
              setSuccessMessage(availData.statusText && typeof availData.statusText === 'object' && typeof availData.statusText.data === 'object' ? availData.statusText.data.message && availData.statusText.data.message.message && typeof availData.statusText.data.message.message === 'object' && availData.statusText.data.message.message.messageText : 'Verify enquiry failed');
            } else {
              setSuccessMessage(typeof availData.error === 'string' ? availData.error : typeof availData.error === 'object' ? (availData.error && availData.error?.messageId ? availData.error?.messageId : 'Verify enquiry failed') : 'Verify enquiry failed');
            }
            handleopenCloseSnackbar(true);
            if (availData && availData.error && Array.isArray(availData.error) && availData.error.length > 0) {
              verifyEnquiryDataToEditMode(availData.error, savedData.data.data);
            }
          }
        }
      } else if (data && data.error) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(typeof data.message === 'string' ? data.message : data.error);
        handleopenCloseSnackbar(true);
        return;
      }
    } else {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(`No valid are parts present`);
      handleopenCloseSnackbar(true);
      return;
    }
    if (!shouldCheckAvailability) setplaceorderEnabled(false)
  };

  const onClickDelete = async () => {
    if (invalidParts && invalidParts.length > 0) {
      setIsInvalidPartsExist(true);
    } else {
      setIsInvalidPartsExist(false);
    }
    let hasDeletions = false;
    let selected: any = [];
    let productsTemp: any = JSON.parse(
      JSON.stringify(products)
    );
    // isChangeCheckBox(true);
    productsTemp = productsTemp.filter(
      (item: any) => {
        if (item.selected) {
          selected.push(item.productId)
          hasDeletions = true;
        }
        return !item.selected
      }
    );
    if (isEnquirySaved) {
      if (hasDeletions) {
        setIsInvalidPartsExist(false);
        saveUpdateEnquiry(false, true);
        if (invalidParts.length > 0) {
          let b2bEnqId = enquiryData && enquiryData[0]?.B2bEnquiryId;
          let selectInvaildParts = invalidParts.filter((item: any) => {
            if (selected.includes(item.productId)) {
              item.isManualDelete = true;
              return item;
            }
          })
          await deleteProblemParts(b2bEnqId, selectInvaildParts, true);
        }
      }
    } else {
      setproducts([...productsTemp]);
      let deletedParts = invalidParts.filter((item: any) => {
        if (!selected.includes(item.productId)) {
          return item;
        }
      })
      setInvalidParts([...deletedParts]);
      //dispatch(deleteLostSaleReport(customerTierType, selected, enquirySessionId))
    }
  }

  const sendEmailToMMForInvalidParts = (invalidParts: Array<[]>, savedEnquiryData: any) => {

    let emailPayload = {
      "userType": "FSE",
      "emailType": "invalidParts",
      "webApplicationLoginId": webApplicationLoginId,
      "firstNaem": firstName,
      "lastName": lastName,
      "email": profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.email,
      "enquiryId": savedEnquiryData && savedEnquiryData.length > 0 && savedEnquiryData[0].B2bEnquiryId ? savedEnquiryData[0].B2bEnquiryId : "",
      "invalidParts": invalidParts
    }
    dispatch(sendAnnouncmentToCustomer(emailPayload))
  }

  const saveUpdateEnquiry = async (shouldCheckAvailability: any, withDeletion?: any, p?: any) => {
    sessionStorage.setItem("isProblemParts", "");
    sessionStorage.setItem('isCopyandCreate', "");
    sessionStorage.setItem("partsData", "")
    let data: any = null;
    setIsQuantityUpdated(false);
    if (!isQueue) {
      setIscallAPI(true);
    }
    setActualProductLength(p && p.length > 0 ? p.length : products.length);
    let productsTemp = JSON.parse(JSON.stringify(p && p.length > 0 ? p : products));
    let invalidParts: any = [];
    let productsExtract = [...productsTemp]
    invalidParts = productsExtract.filter((product: any) => !product.isValid).map(item => item['productId']);
    let allProducts: any = [];
    if (isEnquirySaved) {

      if (enquiryData && enquiryData.length > 0) {
        allProducts = enquiryData.reduce((acc: any, enquiry: any) => {
          return [...acc, ...enquiry.products];
        }, []);
      } else {
        productsTemp = productsTemp.filter((product: any) => product.isValid);
      }
      if (invalidParts && invalidParts.length > 0) {
        for (const product of allProducts) {
          if (invalidParts.includes(product.productId) && product.materialGroup == '') {
            // productsTemp = productsTemp;
          } else if (product.isValid === false) {
            productsTemp = productsTemp.filter((p: any) => p.productId !== product.productId);
          }
        }
      } else {
        productsTemp = productsTemp.filter((product: any) => product.isValid);
      }
    } else {
      productsTemp = productsTemp.filter((product: any) => product.isValid);
    }
    productsTemp = productsTemp.filter((product: any) => product.invalidMaterialGroup !== '');
    if (
      productsTemp &&
      Array.isArray(productsTemp) &&
      productsTemp.length > 0
    ) {
      let emptyRefNo = false;
      let emptyQuantity = false;
      productsTemp = productsTemp.map((item: any) => {
        // Changed on merging problem parts added  && item.isValid
        if (item && item.materialGroup) {
          if (
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim() && item.isValid
          ) {
            emptyRefNo = true;
          }
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }
        // Changed on merging problem parts added  && item.isValid
        if (item && item.materialGroup) {
          item["enquiryReferenceNumber"] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`];
          item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
          item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';
          item["masterReferenceNumber"] = referenceNumber;
        } else {
          item["enquiryReferenceNumber"] = '';
          item["deliveryBlockReason"] = '';
          item["deliveryBlockReasonCode"] = '';
        } item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(`All reference numbers are mandatory`);
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater than zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      setproducts(JSON.parse(JSON.stringify(productsTemp)));
      let preventGetCall = false;
      if (!isEnquirySaved) {
        data = await dispatch(createEnquiry(customerTierType, customerAccountType, referenceNumber, productsTemp, sessionStorage.getItem('userType') === 'FSE' ? firstName + ' ' + lastName : firstName, sessionStorage.getItem('userType') === 'FSE' ? webApplicationLoginId : b2cUserId, enquirySessionId));
        sessionStorage.setItem('subEnquiryId', JSON.stringify(data && data.data && data.data.subEnquiries && data.data.subEnquiries));
        setSubEnquiriesData(data && data.data && data.data.subEnquiries && data.data.subEnquiries);
      } else {
        let filteredItemNos: any = []
        let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

        const isSuperseededExist = (item: any) => {
          let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
          if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
            return true
          } else {
            return false
          }
        }
        productsTemp.map((item: any) => {
          if (withDeletion) {
            // setIsQuantityUpdated(true) // Aded this condition to make place order disabled again if product got deleted
            if (item['selected']) {
              filteredItemNos.push({
                productId: item.productId,
                itemNumber: item.nowEnquiryItemNumber || item.hrs24EnquiryItemNumber || item.airEnquiryItemNumber
              })
              item["action"] = "D"
              item["isManualDelete"] = true;
            }
          }

          // Changed on merging problem parts added next 3 lines
          if (item.isValid === false && item.materialGroup === '') {
            item["action"] = "D"
          }

          if (zeroProducts && zeroProducts.length > 0 && isAvailabiityChecked) {
            if ((parseFloat(item.nsp) === 0 || parseFloat(item.nsp) === 0.00) && !item.isSuperseeded && !item.isMaterialNotExtended && !isSuperseededExist(item)) {
              item.action = "D";
              item.isZeroPrice = true;
            }
          }
          if (item.isMaterialNotExtended) {
            item["action"] = "D";
            // item["isValid"] = false;
          }
          if (item.isProcurementPart && item.nowAvailability && parseInt(item.nowAvailability) !== 0 && !withDeletion) {
            item["action"] = "C";
          }

          if (item.isProcurementPart && item.airAvailability && parseInt(item.airAvailability) !== 0 && parseInt(item.nowAvailability) === 0) {
            if (isSuperseededExist(item)) {
              item["action"] = "C";
            } else {
              item["action"] = "D";
            }
            item["airAvailabilityChecked"] = false;
          }
          // item["nowAvailabilityChecked"] = false;
          // item["hrs24AvailabilityChecked"] = false;
          // item["airAvailabilityChecked"] = false;
          if (item.nowAvailabilityChecked) {
            item["productCost"] = 0;
          }
          // >>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<
          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked) {
            item["productCost"] = 0;
          }
          //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (item.airAvailabilityChecked) {
            item["productCost"] = 0;
          }
          return item
        })
        productsTemp = productsTemp.filter((item: any) => {
          let isNotDeleted = true
          filteredItemNos.map((part: any) => {
            if (item.isSuperseeded && item.supercessionMaterial == part.productId &&
              (part.itemNumber == item.nowEnquiryItemNumber ||
                part.itemNumber == item.nowEnquiryItemNumber)) {
              isNotDeleted = false
            }
          })
          return isNotDeleted
        })
        let updationData: any = {};
        let insertData: any = [];

        let productsTempNew: any = productsTemp;

        // Filter the productsTempNew array
        productsTempNew = productsTempNew.filter((product: any) => {
          if (invalidParts && invalidParts.length > 0) {
            // Check if the product's sequence number exists in invalidParts
            const seqExistsInInvalidParts = invalidParts.some((part: any) => part.seq === product.seq);
            // If the sequence number exists in invalidParts, apply filter
            if (seqExistsInInvalidParts) {
              return (product.action !== 'D' && !product.isProcurementPart) || (product.action !== 'D' && !product.isZeroPrice);
            } else {
              // If the sequence number doesn't exist in invalidParts, retain the item
              return true;
            }
          } else {
            return product;
          }
        });

        // let productsTempNew: any = productsTemp;
        // productsTempNew = productsTempNew.filter((product: any) => product.action !== 'D' && !product.isProcurementPart);
        productsTempNew.map((singleProduct: any) => {
          if (singleProduct["id"]) {
            if (updationData[singleProduct["id"]]) {
              updationData[singleProduct["id"]].push(singleProduct);
            } else {
              updationData[singleProduct["id"]] = [singleProduct];
            }
          } else {
            if (singleProduct.materialGroup && singleProduct.isValid) {
              insertData.push(singleProduct)
            }
          }
        });
        let finalData: any = [];
        Object.keys(updationData).map((singlekey: any) => {
          let tc: any = getEstimatedTotal(isAvailabiityChecked, true, withDeletion);
          finalData.push({
            id: singlekey,
            masterReferenceNumber: referenceNumber,
            products: updationData[singlekey],
            // totalCost: changeCheckBox ? getEstimatedTotal(true, withDeletion) : estTotal,
            totalCost: getEstimatedTotal(isAvailabiityChecked, true, withDeletion),
            netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true, withDeletion)
          });
        });
        let enquiryDetailsQueueCheckData: any;
        if (checkAvailabilityButtonText.toLowerCase() !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL.toLowerCase()) {
          //Trigger verification queued check before attempting update
          enquiryDetailsQueueCheckData = await dispatch(verifyEnquiryQueueCheck(customerTierType, enquiryData[0].B2bEnquiryId, enquiryData[0].customerId));
          //Error enquiryDetailsQueueCheckData.message.status == "E"
          if ((enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && typeof enquiryDetailsQueueCheckData.error === 'object' && enquiryDetailsQueueCheckData.error.items && enquiryDetailsQueueCheckData.error.items.length > 0) || (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && Array.isArray(enquiryDetailsQueueCheckData.error) && enquiryDetailsQueueCheckData.error?.length > 0 && enquiryDetailsQueueCheckData.error[0].items && enquiryDetailsQueueCheckData.error[0].items.length > 0) || (enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.status && enquiryDetailsQueueCheckData.message.status == "E")) {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage(enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && typeof enquiryDetailsQueueCheckData.error === 'object' && enquiryDetailsQueueCheckData.error.items && enquiryDetailsQueueCheckData.error.items.length > 0 ? 'Verify Enquiry Failed'
              : enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && Array.isArray(enquiryDetailsQueueCheckData.error) && enquiryDetailsQueueCheckData.error?.length > 0 && enquiryDetailsQueueCheckData.error[0].items && enquiryDetailsQueueCheckData.error[0].items.length > 0 ? 'Verify Enquiry Failed' : enquiryDetailsQueueCheckData?.message?.message ? enquiryDetailsQueueCheckData?.message?.message : 'Verify Enquiry Failed');
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            if ((enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && typeof enquiryDetailsQueueCheckData.error === 'object' && enquiryDetailsQueueCheckData.error.items && enquiryDetailsQueueCheckData.error.items.length > 0) || (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && Array.isArray(enquiryDetailsQueueCheckData.error) && enquiryDetailsQueueCheckData.error?.length > 0 && enquiryDetailsQueueCheckData.error[0].items && enquiryDetailsQueueCheckData.error[0].items.length > 0)) {
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL);
              setisAvailabiityChecked(false);
              setorderQueued(false);
              let verificationData: any = [];
              let hasConfirmationData = true;
              if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.error && typeof enquiryDetailsQueueCheckData.error === 'object' || enquiryDetailsQueueCheckData.error?.length > 0) {
                if (Array.isArray(enquiryDetailsQueueCheckData.error) && enquiryDetailsQueueCheckData?.error?.length > 0) {
                  enquiryDetailsQueueCheckData.error.map((data: any) => {
                    verificationData.push({ verifiedData: data });
                  })
                } else if (typeof enquiryDetailsQueueCheckData.error === 'object') {
                  verificationData.push({ verifiedData: enquiryDetailsQueueCheckData.error });
                }
              }
              verifyEnquiryDataToEditMode(verificationData, enquiryData, withDeletion, true, true)
            }
            return;
          }


          //Queued enquiryDetailsQueueCheckData.message.message === "verification queued"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification queued") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setIsQueue(true);
            setSuccessMessage(sessionStorage.getItem('userType') === 'FSE' ?
              'Enquiry has been submitted for verification and will take few minutes to get completed. You can check its status by selecting “Queued Enquiries” in the Order-Enquiry List.'
              : 'Enquiry queued for verification, press the verification button after 1 min');
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "verification in progress"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification in progress") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage('Enquiry verification in progress, press the verification button after 1 min.');
            setIsQueue(true);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //In Progress enquiryDetailsQueueCheckData.message.message === "enquiry changed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "enquiry changed") {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage('Enquiry changed, press the verification button after 10 seconds');
            setIsQueue(false);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
          //Success enquiryDetailsQueueCheckData.message.message === "verification completed"
          else if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.message && typeof enquiryDetailsQueueCheckData.message.message === 'string' && enquiryDetailsQueueCheckData.message.message.toLowerCase() == "verification completed") {
            setIsQueue(false);
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL);
            //should be removed and format should be updated on api side
            let verificationData: any = []
            let hasConfirmationData = true;
            if (enquiryDetailsQueueCheckData && Array.isArray(enquiryDetailsQueueCheckData.data)) {
              enquiryDetailsQueueCheckData.data.map((item: any) => {
                if (item && item.confirmations && Array.isArray(item.confirmations) && item.confirmations.length > 0) {
                  verificationData.push({
                    verifiedData: item
                  })
                } else {
                  hasConfirmationData = false;
                }
              })
            }
            if (hasConfirmationData) {
              //should be removed and format should be updated on api side
              preventGetCall = true;
              let logData: any = await dispatch(getEnquiryDetails(customerTierType,
                enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : data.data.masterId,
                (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
              ));

              // await dispatch(getOrderLogs(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : data.data.masterId))
              if (logData && logData.message && logData.message.status === "E") {
                setTimeout(() => {
                  setOpenSnackbarType("error");
                  setOpenErrorSnackbar(true);
                  setSuccessMessage(logData && logData.message && logData.message.message && logData.message.message);
                  handleopenCloseSnackbar(true);
                }, 2000)
                setIsDisableOrderLogButton(true);
              } else {

                let rmpro: any = await getRemovedProducts(logData?.data?.data);
                setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : logData?.data?.data[0].removedProducts ? logData?.data?.data[0].removedProducts : []);
                setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : logData?.data?.data[0].removedProducts?.length)

                // setRemovedProductsLength(logData?.data?.data[0].removedProducts?.length)
                // setInvalidParts(logData?.data?.data[0].removedProducts ? logData?.data?.data[0].removedProducts : []);
                await getOrderLogsData(logData?.data);
                setIsDisableOrderLogButton(false);
              }
              setGetLatestOrderCount(false);
              setisAvailabiityChecked(true);
              setDisableQuotation(false);
              setorderQueued(false);
              setisAvailabiityCheckedAtTime(new Date());
              verifyEnquiryDataToEditMode(verificationData, logData.data.data, withDeletion, true)
              setSuccessMessage(enquiryDetailsQueueCheckData.message.message);
              setOpenErrorSnackbar(false);
              handleopenCloseSnackbar(true);
              if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                setSelectedDeliveryTypeError(false);
              }
              return;
            } else {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage('Enquiry verification in progress, press the verification button after 1 min.');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
              return;
            }
          }
          else {
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            setSuccessMessage(typeof enquiryDetailsQueueCheckData.message.message === 'string' ? enquiryDetailsQueueCheckData.message.message : typeof enquiryDetailsQueueCheckData.message.message === 'object' ? enquiryDetailsQueueCheckData.message.message && enquiryDetailsQueueCheckData.message.message.messageText : 'Verify Enquiry failed');
            setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.PROGRESS);
            handleopenCloseSnackbar(true);
            setplaceorderEnabled(false);
            return;
          }
        }
        let data1: any;
        let upData: any;
        if (invalidParts && invalidParts.length === 0) {
          upData = await dispatch(
            updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, true, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
            )
          );
        }
        // if (data && data.data) {
        data1 = await dispatch(
          updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, false, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
          )
        );
        // }
        // if (data && data.data) {
        // data1 = await dispatch(
        //   updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && customerTierType.toLowerCase() === "workshop" && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, false, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
        //   )
        // );
        // }
        if (!(data1 && data1.data && Array.isArray(data1.data))) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setSuccessMessage(data1 && data1.error && typeof data1.error === 'string' ? data1.error : data1?.message && data1?.message?.message && typeof data1?.message?.message === 'string' ? data1?.message?.message : `Order Enquiry Update Failed`);
          handleopenCloseSnackbar(true);
          return
        }
      }
      if (
        ((data && data.data && data.data.masterId) ||
          (isEnquirySaved && enquiryData[0].B2bEnquiryId)) && !preventGetCall
      ) {
        await dispatch(setEnquiryId(enquiryData &&
          Array.isArray(enquiryData) &&
          enquiryData.length > 0 &&
          enquiryData[0].B2bEnquiryId
          ? enquiryData[0].B2bEnquiryId
          : data.data.masterId));
        let savedData: any;
        savedData = await dispatch(getEnquiryDetails(customerTierType,
          enquiryData &&
            Array.isArray(enquiryData) &&
            enquiryData.length > 0 &&
            enquiryData[0].B2bEnquiryId
            ? enquiryData[0].B2bEnquiryId
            : data.data.masterId,
          (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
        ));
        if (
          savedData &&
          savedData.data &&
          savedData.data.data &&
          savedData.data.data[0]
        ) {
          let rmpro: any = await getRemovedProducts(savedData?.data?.data);
          setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : savedData.data.data[0].removedProducts ? savedData.data.data[0].removedProducts : []);
          setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : savedData?.data?.data[0].removedProducts?.length)
          // setRemovedProductsLength(savedData.data.data[0]?.removedProducts?.length)
          // setInvalidParts(savedData.data.data[0].removedProducts ? savedData.data.data[0].removedProducts : []);
          await getOrderLogsData(savedData?.data);
          setenquiryData(savedData?.data?.data);
          setGetVerifyData(savedData);
          if (invalidParts.length > 0 && sessionStorage.getItem('userType') === 'FSE') {
            sendEmailToMMForInvalidParts(invalidParts, savedData.data.data)
          }
          if (!isEnquirySaved) {
            setisUpdateMode(true);
            setisEnquirySaved(true);
            // requestPriceAccess();
            setActualProductLength(savedData.data.data[0].products.length)
          }
          if (sessionStorage.getItem('userType') === 'FSE') {
            await enquiryDataToEditMode(savedData.data.data, false, withDeletion);
          }
          if (!shouldCheckAvailability) {
            enquiryDataToEditMode(savedData.data.data, false, withDeletion);
            if (withDeletion) {
              setIsDelted(true)
            }
            setSuccessMessage(
              isEnquirySaved
                ? `Order Enquiry Updated successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
                : `Order Enquiry saved successfully ${savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId
                }`
            );
            if (importOes) {
              await dispatch(setEnquiryId(savedData.data.data[0].B2bEnquiryId));
              return savedData.data.data[0].B2bEnquiryId;
            }
            setOpenErrorSnackbar(false);
            handleopenCloseSnackbar(true);
            if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
              setSelectedDeliveryTypeError(false);
            }
          }
        } else {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setSuccessMessage(
            `Order Enquiry ${!isEnquirySaved ? "Creation" : "Update"} Failed`
          );
          setOpenErrorSnackbar(false);
          handleopenCloseSnackbar(true);
          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
            setSelectedDeliveryTypeError(false);
          }
        }
        if (
          shouldCheckAvailability &&
          ((savedData &&
            savedData.data &&
            savedData.data.data[0] &&
            savedData.data.data[0].B2bEnquiryId) ||
            (enquiryData && enquiryData[0].B2bEnquiryId))
        ) {
          isChangeCheckBox(false);
          sessionStorage.setItem('iprice', '');
          setprocuredPart([]);
          let updatedData: any;
          if (sessionStorage.getItem('userType') === 'FSE' && !enquiryData) {
            updatedData = await updateOnly(products, undefined, undefined, savedData.data.data);
          }
          let availData: any = await dispatch(
            verifyEnquiry(customerTierType,
              (enquiryData &&
                Array.isArray(enquiryData) &&
                enquiryData[0].B2bEnquiryId
                ? enquiryData[0].B2bEnquiryId
                : savedData &&
                savedData.data &&
                savedData.data.data[0] &&
                savedData.data.data[0].B2bEnquiryId), enquirySessionId
            )
          );
          setVerificationData(availData);
          sessionStorage.setItem('reset-the-orderlog-count', "false")
          let data: any = await dispatch(getEnquiryDetails(customerTierType,
            enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : savedData && savedData.data && savedData.data.data[0] && savedData.data.data[0].B2bEnquiryId,
            (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false
          ));
          // await dispatch(getOrderLogs(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : savedData && savedData.data && savedData.data.data[0] && savedData.data.data[0].B2bEnquiryId))
          if (data && data.message && data.message.status === "E") {
            setTimeout(() => {
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage(data && data.message && data.message.message && data.message.message);
              handleopenCloseSnackbar(true);
            }, 2000)
            setIsDisableOrderLogButton(true);
          } else {
            let rmpro: any = await getRemovedProducts(data?.data?.data);
            setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
            setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : data?.data?.data[0].removedProducts?.length)
            // setInvalidParts(data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
            // setRemovedProductsLength(data?.data?.data[0].removedProducts?.length)
            await getOrderLogsData(data?.data);
            setIsDisableOrderLogButton(false);
          }
          setGetLatestOrderCount(false);
          if (
            availData &&
            availData.data &&
            Array.isArray(availData.data) &&
            availData.data.length > 0
          ) {
            setMaterialNotExtendedPart([]);
            setIsMaterialNotExtendedExist(false);
            if (availData.message.status !== 'I') {
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL)
              setisAvailabiityCheckedAtTime(new Date());
              verifyEnquiryDataToEditMode(availData.data, savedData.data.data);
              setSuccessMessage(`Availability and Price successfully fetched`);
              setOpenErrorSnackbar(false);
              handleopenCloseSnackbar(true);
              setisAvailabiityChecked(true);
              setDisableQuotation(false);
              // requestPriceAccess();
              if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                setSelectedDeliveryTypeError(false);
              }
            } else {
              //Set Check Availability Button to show verification queued if check data is not verified.
              setCheckAvailabilityButtonText(CHECK_AVAILABILITY_BUTTON_TEXT.QUEUED)
              setOpenSnackbarType("error");
              setOpenErrorSnackbar(true);
              setSuccessMessage(sessionStorage.getItem('userType') === 'FSE' ?
                'Enquiry has been submitted for verification and will take few minutes to get completed. You can check its status by selecting “Queued Enquiries” in the Order-Enquiry List.'
                : 'Enquiry queued for verification, press the verification button after 1 min');
              handleopenCloseSnackbar(true);
              setplaceorderEnabled(false);
            }
          } else {
            enquiryDataToEditMode(savedData.data.data, true, false, false);
            setOpenSnackbarType("error");
            setOpenErrorSnackbar(true);
            if (availData && availData.error && Array.isArray(availData.error) && availData.error.length > 0) {
              setSuccessMessage(availData.statusText && typeof availData.statusText === 'object' && typeof availData.statusText.data === 'object' ? availData.statusText.data.message && availData.statusText.data.message.message && typeof availData.statusText.data.message.message === 'object' && availData.statusText.data.message.message.messageText : 'Verify enquiry failed');
            } else {
              setSuccessMessage(typeof availData.error === 'string' ? availData.error : typeof availData.error === 'object' ? (availData.error && availData.error.messageId ? availData.error.messageId : 'Verify enquiry failed') : 'Verify enquiry failed');
            }
            handleopenCloseSnackbar(true);
            if (availData && availData.error && Array.isArray(availData.error) && availData.error.length > 0) {
              verifyEnquiryDataToEditMode(availData.error, savedData.data.data);
            }
          }
        }
      } else if (data && data.error) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(typeof data.message === 'string' ? data.message : data.error);
        handleopenCloseSnackbar(true);
        return;
      }
    } else {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(`No valid are parts present`);
      handleopenCloseSnackbar(true);
      return;
    }
    if (!shouldCheckAvailability) setplaceorderEnabled(false)
  };

  const requestQuotation = async (comments: any, validUntilDate: any) => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      let date: any = moment(validUntilDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
      // moment(isAvailabiityCheckedAtTime).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss');
      sessionStorage.setItem("validUntilDate", date);
      updateOnly(products, comments, true);
    } else {
      updateOnly(products, comments, true);
    }
  }


  const loadQueueQuotation = () => {
    dispatch(verifyQuotationQueueCheck(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData[0].B2bEnquiryId, sapCustomerId));
  }

  const updateOnly = async (productsData?: any, comment?: any, quotation?: any, enquiryInfo?: any, zeroParts?: any) => {
    let productsTemp: any;
    if (sessionStorage.getItem('userType') === 'FSE' && enquiryInfo && enquiryInfo.length > 0) {
      let enquiryInfoProducts: Array<any> = []
      let i = 0
      while (i < enquiryInfo.length) {
        enquiryInfoProducts = [...enquiryInfoProducts, ...enquiryInfo[i].products]
        i++;
      }
      productsTemp = JSON.parse(JSON.stringify(enquiryInfoProducts));
    } else {
      productsTemp = JSON.parse(JSON.stringify(productsData));
    }

    let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

    const isSuperseededExist = (item: any) => {
      let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
      if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
        return true
      } else {
        return false
      }
    }

    productsTemp = productsTemp.filter((product: any) => product.isValid);
    let emptyRefNo = false;
    let emptyQuantity = false;
    productsTemp = productsTemp.map((item: any) => {
      // Problem pats conflcit resolved
      if (item && item.materialGroup) {
        if (
          (!brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()) && item.isValid
        ) {
          emptyRefNo = true;
        }
      }
      if (referenceNumber === "") {
        emptyRefNo = true;
      }
      if (!parseInt(item.quantity)) {
        emptyQuantity = item.productId;
      }
      if (item.isMaterialNotExtended) {
        item.action = "D";
        // item["isValid"] = false;
      } else {
        item.action = "C";
      }
      if (item.isProcurementPart && item.nowAvailability && parseInt(item.nowAvailability) !== 0) {
        item["action"] = "C";
      }
      if (item.isProcurementPart && item.airAvailability && parseInt(item.airAvailability) !== 0 && parseInt(item.nowAvailability) === 0) {
        if (isSuperseededExist(item)) {
          item["action"] = "C";
        } else {
          item["action"] = "D";
        }
        item["airAvailabilityChecked"] = false;
      }
      // if (zeroParts && zeroParts.length > 0 && isAvailabiityChecked) {
      if ((parseFloat(item.nsp) === 0 || parseFloat(item.nsp) === 0.00) && !item.isSuperseeded && !item.isMaterialNotExtended && !isSuperseededExist(item)) {
        item.action = "D";
        item.isZeroPrice = true;
      }
      // }
      item['enquiryReferenceNumber'] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`];
      item['masterReferenceNumber'] = referenceNumber;
      item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
      item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';

      return item
    })
    if (sessionStorage.getItem('userType') === 'FSE') {
      productsTemp = productsTemp.map((item: any) => {
        if (item.isMaterialNotExtended) {
          item.action = "D";
          // item["isValid"] = false;
        } else {
          item.action = "C";
        }
        if (item.isProcurementPart && item.nowAvailability && parseInt(item.nowAvailability) !== 0) {
          item["action"] = "C";
        }
        if (item.isProcurementPart && item.airAvailability && parseInt(item.airAvailability) !== 0 && parseInt(item.nowAvailability) === 0) {
          if (!isSuperseededExist(item)) {
            item["action"] = "D";
          } else {
            item["action"] = "C";
          }
          item["airAvailabilityChecked"] = false;
        }
        return item;
      });
    }

    if (emptyRefNo) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(`All reference numbers are mandatory`);
      handleopenCloseSnackbar(true);
      return;
    }
    if (emptyQuantity) {
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      setSuccessMessage(
        `Quantity of product ${emptyQuantity} should be greater that zero`
      );
      handleopenCloseSnackbar(true);
      return;
    }
    let updationData: any = {};
    let insertData: any = [];


    let productsTempNew: any = productsTemp;
    // Filter the productsTempNew array
    productsTempNew = productsTempNew.filter((product: any) => {
      let invalid: any = [...invalidParts]
      if (invalid && invalid.length > 0) {
        // Check if the product's sequence number exists in invalidParts
        const seqExistsInInvalidParts = invalidParts.some((part: any) => part.seq === product.seq);
        // If the sequence number exists in invalidParts, apply filter
        if (seqExistsInInvalidParts) {
          return (product.action !== 'D' && !product.isProcurementPart) || (product.action !== 'D' && !product.isZeroPrice);
        } else {
          // If the sequence number doesn't exist in invalidParts, retain the item
          return true;
        }
      } else {
        return product;
      }
    });
    productsTempNew.map((singleProduct: any) => {
      if (singleProduct["id"]) {
        if (updationData[singleProduct["id"]]) {
          updationData[singleProduct["id"]].push(singleProduct);
        } else {
          updationData[singleProduct["id"]] = [singleProduct];
        }
      } else {
        insertData.push(singleProduct)
      }
    });
    let finalData: any = [];
    Object.keys(updationData).map((singlekey: any) => {
      // let tc = changeCheckBox ? getEstimatedTotal(true) : estTotal;
      let tc: any = getEstimatedTotal(true, true);
      finalData.push({
        id: singlekey,
        masterReferenceNumber: referenceNumber,
        products: updationData[singlekey],
        // totalCost: changeCheckBox ? getEstimatedTotal(true) : estTotal,
        totalCost: getEstimatedTotal(true, true),
        verifiedOn: isAvailabiityCheckedAtTime,
        netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true)
      })
    })
    if (quotation) {
      let statusSuccess: any
      let masterID: any
      let quotationData: any = await dispatch(updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, false, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(), true, true, comment, b2bUserId));
      if (quotationData && quotationData.data && quotationData.data[0] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"]["success"]) {
        statusSuccess = quotationData.data[0]["quotationsRequestDetails"]["success"],
          masterID = quotationData.data[0]["quotationsRequestDetails"]["B2bEnquiryId"]
        setMasterIDData(masterID);
        setStatusSuccessChecked(statusSuccess);
        // dispatch(verifyQuotationQueueCheck(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData[0].B2bEnquiryId, sapCustomerId));
      } else if (!(quotationData && quotationData.data && quotationData.data[0] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"] && quotationData.data[0]["quotationsRequestDetails"]["success"])) {
        setOpenSnackbar(true)
        setOpenErrorSnackbar(true)
        setSuccessMessage("Error to request for quotation")
        setShowRequestQuotationDialog(false);
        setTimeout(() => {
          setOpenSnackbar(false)
          setOpenErrorSnackbar(false)
        }, 2000)
      }
    } else {
      if (enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0) {
        await dispatch(updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryData[0].B2bEnquiryId, finalData, true, insertData, enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber, enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()));
      } else if (enquiryInfo && Array.isArray(enquiryInfo) && enquiryInfo.length > 0) {
        await dispatch(updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData, enquiryInfo[0].B2bEnquiryId, finalData, false, insertData, enquiryInfo[0].enquiryNumber, enquiryInfo[0].b2bLostSalesReportId ? enquiryInfo[0].b2bLostSalesReportId : getUniqueTimestamp()));
      }
      return
    }
    // if(statusSuccessChecked === true){
    //   // setSuccessMessage("Quotation Created Successfully");
    //   // handleopenCloseSnackbar(true);
    // }
    let savedData: any = await dispatch(getEnquiryDetails(customerTierType, enquiryData[0].B2bEnquiryId, (enquiryAction === "view" && orderType !== "Cancelled") ? true : false, false));
    enquiryDataToEditMode(savedData.data.data, true, false, false);

    let rmpro: any = await getRemovedProducts(savedData?.data?.data);
    setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : savedData?.data?.data[0].removedProducts ? savedData?.data?.data[0].removedProducts : []);
    setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : savedData?.data?.data[0].removedProducts?.length)
    // setInvalidParts(savedData?.data?.data[0].removedProducts ? savedData?.data?.data[0].removedProducts : []);
    await getOrderLogsData(savedData?.data);
    // setRemovedProductsLength(savedData?.data?.data[0].removedProducts?.length)
    setenquiryData(savedData?.data?.data);
    setGetVerifyData(savedData)
    // requestPriceAccess();
  };

  // // ONCHANGE OF SELECT DELIVERY TYPE
  // const onChangeDeliveryType = (e: any) => {
  //     setDeliveryType(e.target.value);
  //     if (e.target.value === "" || e.target.value.trim() === "") {
  //         setSelectedDeliveryTypeError(true);
  //     }
  //     else {
  //         setSelectedDeliveryTypeError(false);
  //     }
  // }

  // VALIDATIONS FOR DELIVERY TYPE 
  const validateDeliveryType = () => {
    let isValidationSucess: boolean = true;
    setIsValid(true);
    if (deliveryType == undefined) {
      setSelectedDeliveryTypeError(true);
      setOpenSnackbarType("error");
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage(`Please Select Delivery Type`);
      setIsValid(false);
      isValidationSucess = false;
    }
    else {
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(false);
      setSelectedDeliveryTypeError(false);
    }
    if (isValidationSucess) {
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(false);
      setSelectedDeliveryTypeError(false);
      placeOrderHandler();
    }
  }

  const getCounter = () => {
    if (isAvailabiityCheckedAtTime) {
      var now = moment(new Date());
      var start = moment(new Date(isAvailabiityCheckedAtTime));
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (
        !isAvailabiityCheckedAtTime ||
        (minutes && minutes > 5)
      ) {
        return <>
          <br />
          <span className="text-danger">
            (Availability Expired)
          </span>
        </>
      } else {
        return <>
          <br />
          <span>
            (Please submit your order in <Countdown date={isAvailabiityCheckedAtTime.getTime() + 300000} renderer={renderer} />)
          </span>
        </>
      }
    } else {
      return null
    }
  }

  const getCounterFSE = () => {
    if (isAvailabiityCheckedAtTime) {
      var now = moment(new Date());
      var start = moment(new Date(isAvailabiityCheckedAtTime));
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (
        !isAvailabiityCheckedAtTime ||
        (minutes && minutes > 60)
      ) {
        return <>
          <br />
          <span className="text-danger">
            (Availability Expired)
          </span>
        </>
      } else {
        return <>
          <br />
          <span>
            (Please submit your order in <Countdown date={isAvailabiityCheckedAtTime.getTime() + 3.6e+6} renderer={renderer} />)
          </span>
        </>
      }
    } else {
      return null
    }
  }

  const sendEmailObj = (zeroparts: any) => {
    let list: any = [];
    zeroparts && zeroparts.map((part: any) => {
      list.push({
        "productId": part.productId,
        "supercessionMaterial": part.supercessionMaterial ? part.supercessionMaterial : '',
        "brand": getNewBrand(enquiryMapping[`${formatMaterialGroup(part.materialGroup)}`]),
        "productIdDescription": part.productIdDescription,
        "orgId": orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(part.materialGroup)}`]}`],
        "price": "0",
        "enquiredQty": part.quantity
      })
    });

    let data: any = {
      "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      "partNumberList": list,
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      },
      "dealerCode": "test01",
      "userName": firstName,
      "userId": b2cUserId,
      "enquirySessionId": `b2bLSR${enquirySessionId}`,
      "customerType": customerTierType,
      "enquiryId": enquiryData &&
        Array.isArray(enquiryData) &&
        enquiryData.length > 0 &&
        enquiryData[0].B2bEnquiryId,
      "customerId": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("selectedSapCustomerId") : sessionStorage.getItem('customerId'),
      "customerName": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("CompanyName") : sessionStorage.getItem('customerName')
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      data.userRole = 'FSE'
    }
    return data;
  }

  const sendEmailProcurement = () => {
    let list: any = [];
    let procurementPart: any = products.filter((obj1: any) => procuredPart.some((obj2: any) => obj1.productId === obj2));
    procurementPart && procurementPart.map((part: any) => {
      list.push({
        "productId": part.productId,
        "supercessionMaterial": part.supercessionMaterial ? part.supercessionMaterial : '',
        "brand": getNewBrand(enquiryMapping[`${formatMaterialGroup(part.materialGroup)}`]),
        "productIdDescription": part.productIdDescription,
        "orgId": orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(part.materialGroup)}`]}`],
        "price": part.nowAvailability ? part.nowCost && part.nowCost.toString() : part.airCost && part.airCost.toString(),
        "enquiredQty": part.quantity
      })
    });

    let data: any = {
      "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      "partNumberList": list,
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
      },
      "userName": firstName,
      "userId": b2cUserId,
      "enquirySessionId": `b2bLSR${enquirySessionId}`,
      "customerType": customerTierType,
      "enquiryId": enquiryData &&
        Array.isArray(enquiryData) &&
        enquiryData.length > 0 &&
        enquiryData[0].B2bEnquiryId,
      "customerId": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("selectedSapCustomerId") : sessionStorage.getItem('customerId'),
      "isProcurement": true,
      "customerName": sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem("CompanyName") : sessionStorage.getItem('customerName')
    }

    if (sessionStorage.getItem('userType') === 'FSE') {
      data.userRole = 'FSE'
    }
    return data;
  }

  const placeOrderHandler = async () => {
    if (invalidParts && invalidParts.length > 0) {
      await setIscallAPI(true)
    }
    setLoading(true);
    if (!orderQueued) {
      var now = moment(new Date());
      var start = moment(
        isAvailabiityCheckedAtTime
          ? new Date(isAvailabiityCheckedAtTime)
          : new Date()
      );
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (sessionStorage.getItem('userType') === 'FSE') {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 60)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setLoading(false);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      } else {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 5)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setLoading(false);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      }

      let productsTemp = JSON.parse(
        JSON.stringify(products)
      );
      // if (procuredPart && procuredPart.length > 0) {
      //   productsTemp = productsTemp.filter(
      //     (product: any) => !product.isProcurementPart
      //   );
      //   setproducts([...productsTemp]);
      // }
      if (materialNotExtendedPart && materialNotExtendedPart.length > 0) {
        productsTemp = productsTemp.filter(
          (product: any) => !product.isMaterialNotExtended
        );
        setproducts([...productsTemp]);
      }
      // if (zeroProducts && zeroProducts.length > 0) {
      //   productsTemp = productsTemp.filter(
      //     (product: any) => parseInt(product.nsp) !== 0
      //   );
      //   setproducts([...productsTemp]);
      // }
      let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

      const isSuperseededExist = (item: any) => {
        let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
        if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
          return true
        } else {
          return false
        }
      }

      productsTemp = productsTemp.filter(
        (product: any) => product.isValid
      );
      let emptyRefNo = false;
      let emptyQuantity = false;
      let itemsSelected = false;
      productsTemp = productsTemp.map((item: any) => {
        if (item && item.materialGroup) {
          if (
            item && item.materialGroup && !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
          ) {
            emptyRefNo = true;
          }
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }

        let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

        const isSuperseededExist = (item: any) => {
          let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
          if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
            return true
          } else {
            return false
          }
        }
        if ((parseFloat(item.nsp) === 0 || parseFloat(item.nsp) === 0.00) && !item.isSuperseeded && !item.isMaterialNotExtended && !isSuperseededExist(item)) {
          item.action = "D";
          item.isZeroPrice = true;
        }

        if ((item.nowAvailabilityChecked && item.nowAvailability > 0) || (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked && item.hrs24Availability > 0) || (item.airAvailabilityChecked && item.airAvailability > 0)) {
          itemsSelected = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }

        item["enquiryReferenceNumber"] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`];
        item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
        item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';
        item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `All reference numbers are mandatory`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater that zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (!itemsSelected) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `Please select atleast one item with quantity greater than 0 to proceed.`
        );
        handleopenCloseSnackbar(true);
        return;
      }

      // let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)

      productsTemp = productsTemp.map((item: any) => {
        enquiryData.map((subenquiry: any) => {
          if (
            subenquiry &&
            subenquiry.products &&
            Array.isArray(subenquiry.products)
          ) {
            subenquiry.products.map((productSub: any) => {
              if (
                (productSub.productId && productSub.productId.toLowerCase() === item.productId.toLowerCase()) ||
                subenquiry["make"] && subenquiry["make"].toLowerCase() ===
                enquiryMapping[
                  `${formatMaterialGroup(item.materialGroup)}`
                ].toLowerCase()
              ) {
                item["enquiryNumber"] =
                  subenquiry.enquiryNumber;
                item["id"] = subenquiry.id;
                item["action"] = item.isProcurementPart && item.airAvailability && parseInt(item.airAvailability) !== 0 && parseInt(item.nowAvailability) === 0 && !isSuperseededExist(item) ? "D" : "C";
                item["productCost"] = 0;
                if (item.nowAvailabilityChecked) {
                  item["productCost"] +=
                    (item.nowAvailability
                      ? parseFloat(item.nowAvailability)
                      : 0) *
                    (item.nsp ? parseFloat(item.nsp) : 0);
                }
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<<<<<
                if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked) {
                  item["productCost"] +=
                    (item.hrs24Availability
                      ? parseFloat(item.hrs24Availability)
                      : 0) *
                    (item.nsp ? parseFloat(item.nsp) : 0);
                }
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                if (item.airAvailabilityChecked) {
                  if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                    item["airAvailability"] = 0;
                    item["aop"] = 0;
                    item["airAvailabilityChecked"] = false
                  }
                  if (item.isProcurementPart && item.airAvailability !== 0) {
                    item["airAvailabilityChecked"] = false
                  }
                  item["productCost"] +=
                    (item.airAvailability && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer"
                      ? parseFloat(item.airAvailability)
                      : 0) *
                    (item.aop && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer" ? parseFloat(item.aop) : 0);
                }
              }
            });
          }
        });
        return item;
      });
      if (zeroProducts && zeroProducts.length > 0) {
        let data: any = sendEmailObj(zeroProducts);
        await dispatch(sendZeroProductsEmail(data));
      }

      let updationData: any = {};


      let productsTempNew: any = productsTemp;

      // Filter the productsTempNew array
      productsTempNew = productsTempNew.filter((product: any) => {
        if (invalidParts && invalidParts.length > 0) {
          // Check if the product's sequence number exists in invalidParts
          const seqExistsInInvalidParts = invalidParts.some((part: any) => part.seq === product.seq);
          // If the sequence number exists in invalidParts, apply filter
          if (seqExistsInInvalidParts) {
            return product.action !== 'D' && !product.isProcurementPart;
          } else {
            // If the sequence number doesn't exist in invalidParts, retain the item
            return true;
          }
        } else {
          return product;
        }
      });


      productsTempNew.map((singleProduct: any) => {
        if (singleProduct["id"]) {
          if (updationData[singleProduct["id"]]) {
            updationData[singleProduct["id"]].push(
              singleProduct
            );
          } else {
            updationData[singleProduct["id"]] = [
              singleProduct,
            ];
          }
        }
      });

      let finalData: any = [];
      Object.keys(updationData).map((singlekey: any) => {
        // let tc = changeCheckBox ? getEstimatedTotal(true) : estTotal;
        let tc: any = getEstimatedTotal(isAvailabiityChecked, true);
        finalData.push({
          id: singlekey,
          masterReferenceNumber: referenceNumber,
          products: updationData[singlekey],
          // totalCost: changeCheckBox ? getEstimatedTotal(true) : estTotal,
          totalCost: getEstimatedTotal(isAvailabiityChecked, true),
          netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true)
        });
      });
      await dispatch(
        updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData,
          enquiryData[0].B2bEnquiryId,
          finalData,
          true,
          null,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
          true
        )
      );
      await dispatch(
        updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData,
          enquiryData[0].B2bEnquiryId,
          finalData,
          false,
          null,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
          true
        )
      );
      // if (zeroProducts && zeroProducts.length > 0) {
      //   await dispatch(
      //     verifyEnquiry(customerTierType,
      //       enquiryData &&
      //       Array.isArray(enquiryData) &&
      //       enquiryData[0].B2bEnquiryId
      //       , enquirySessionId
      //     )
      //   );
      //   await dispatch(
      //     updateEnquiry(customerTierType,
      //       enquiryData[0].B2bEnquiryId,
      //       finalData,
      //       true,
      //       null,
      //       enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
      //       enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
      //       true
      //     )
      //   );
      //   await dispatch(
      //     updateEnquiry(customerTierType,
      //       enquiryData[0].B2bEnquiryId,
      //       finalData,
      //       false,
      //       null,
      //       enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
      //       enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
      //       true
      //     )
      //   );
      // }
      setLoading(true);
      let placeoRederData: any = await dispatch(
        placeOrder(customerTierType, deliveryType, enquiryData[0].B2bEnquiryId, sessionStorage.getItem('userType') === 'FSE' ? firstName + ' ' + lastName : firstName, b2cUserId, enquirySessionId, products)
      );
      if (placeoRederData && placeoRederData.message.status && (placeoRederData.message.status == "I" || placeoRederData.message.status == "E")) {
        setorderQueued(placeoRederData.message.status == 'I' ? true : false);
        setOpenSnackbarType("error");
        setTimeout(() => {
          setOpenErrorSnackbar(true);
          setSuccessMessage(sessionStorage.getItem('userType') !== 'FSE'
            ? placeoRederData && placeoRederData.message && placeoRederData?.message?.message === "Your Order has been queued for verification" ? "Please do not leave this page while the order is getting processed. If the order is not confirmed in 15 min then please contact our sales team for support."
              : placeoRederData.message.message : placeoRederData?.message?.message ? placeoRederData?.message?.message : "Unable to place order");
          handleopenCloseSnackbar(true);
        }, 3000);
        setLoading(false);
        if (placeoRederData.message && placeoRederData.message.message && placeoRederData.message.message.includes("does not allow external procurement")) {
          let wordsArray = placeoRederData.message.message.split(" ")
          let indexPart = wordsArray.indexOf("material");
          let procuredPartTemp = indexPart >= 0 ? wordsArray[indexPart + 1] : [];
          setprocuredPart(procuredPartTemp)
        }
      }
      else if (
        placeoRederData &&
        placeoRederData.data &&
        Array.isArray(placeoRederData.data)
      ) {
        setSuccessMessage(`Order successfully placed`);
        setOpenErrorSnackbar(false);
        handleopenCloseSnackbar(true);
        setorderData(placeoRederData.data);
        setActualProductLength(products.length);
        setIsQuantityUpdated(false);
        setorderTotal(placeoRederData.data[0].orderTotal);
        handleopenCloseOrderSummary(true);
        if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
          if (customerAccountType === 'Credit') {
            dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
          } else {
          }
        } else {
          dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
        }
        let defaultAddress = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.id === defaultAddressId)
        if (defaultAddress && defaultAddress.shiftToPartyCustomerNumber)
          setMultipleAddressData(defaultAddress && defaultAddress.shiftToPartyCustomerNumber);
        setLoading(false);
        // history.push("/ordersummarydetails");
      }
    } else {
      let enquiryDetailsQueueCheckData: any = await dispatch(verifyEnquiryQueueCheck(customerTierType, enquiryData[0].B2bEnquiryId, enquiryData[0].customerId));
      if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.status && enquiryDetailsQueueCheckData.message.status == "S" && enquiryDetailsQueueCheckData.message.message == "Order Created") {
        setSuccessMessage(`Order successfully placed`);
        setOpenErrorSnackbar(false);
        handleopenCloseSnackbar(true);
        setLoading(false);
        if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.length > 0) {
          // setorderData(enquiryDetailsQueueCheckData.data[0].orderSummary);
          let summaryData: any = [];
          enquiryDetailsQueueCheckData.data.map((item: any) => {
            summaryData = [...summaryData, ...item.orderSummary]
          })

          let total = 0;
          enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.map((item: any) => {
            if (item.brandTotal) {
              total += parseFloat(item.brandTotal)
            }
          })

          setorderTotal(total)
          setorderData(summaryData);
        }
        handleopenCloseOrderSummary(true);
        let defaultAddress = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.id === defaultAddressId)
        if (defaultAddress && defaultAddress.shiftToPartyCustomerNumber)
          setMultipleAddressData(defaultAddress && defaultAddress.shiftToPartyCustomerNumber);
        if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
          if (customerAccountType === 'Credit') {
            dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
          } else {
          }
        } else {
          dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
        }
        setLoading(false);
        // history.push("/ordersummarydetails");
      } else {
        if (enquiryDetailsQueueCheckData.message.message.toLowerCase() == 'order placement failed') {
          setorderQueued(false)
        }
        setOpenSnackbarType("error");
        setSuccessMessage(enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData?.message?.message);
        handleopenCloseSnackbar(true);
        setLoading(false);
      }
    }
  }


  const ConfirmButtonUpdateAPI = async () => {
    setLoading(true);
    if (!orderQueued) {
      var now = moment(new Date());
      var start = moment(
        isAvailabiityCheckedAtTime
          ? new Date(isAvailabiityCheckedAtTime)
          : new Date()
      );
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (sessionStorage.getItem('userType') === 'FSE') {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 60)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setLoading(false);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      } else {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 5)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setLoading(false);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      }

      let productsTemp = JSON.parse(
        JSON.stringify(products)
      );
      if (zeroProducts && zeroProducts.length > 0) {
        productsTemp = productsTemp.filter(
          (product: any) => parseInt(product.nsp) !== 0
        );
        setproducts([...productsTemp]);
      }
      productsTemp = productsTemp.filter(
        (product: any) => product.isValid
      );
      let emptyRefNo = false;
      let emptyQuantity = false;
      let itemsSelected = false;
      productsTemp = productsTemp.map((item: any) => {
        if (item && item.materialGroup) {
          if (
            item && item.materialGroup && !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`] ||
            !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].trim()
          ) {
            emptyRefNo = true;
          }
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if ((item.nowAvailabilityChecked && item.nowAvailability > 0) || (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked && item.hrs24Availability > 0) || (item.airAvailabilityChecked && item.airAvailability > 0)) {
          itemsSelected = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }

        item["enquiryReferenceNumber"] = brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`];
        item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
        item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';
        item["masterReferenceNumber"] = referenceNumber;
        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `All reference numbers are mandatory`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater that zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (!itemsSelected) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setLoading(false);
        setSuccessMessage(
          `Please select atleast one item with quantity greater than 0 to proceed.`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      productsTemp = productsTemp.map((item: any) => {
        enquiryData.map((subenquiry: any) => {
          if (
            subenquiry &&
            subenquiry.products &&
            Array.isArray(subenquiry.products)
          ) {
            subenquiry.products.map((productSub: any) => {
              if (
                (productSub.productId && productSub.productId.toLowerCase() === item.productId.toLowerCase()) ||
                subenquiry["make"] && subenquiry["make"].toLowerCase() ===
                enquiryMapping[
                  `${formatMaterialGroup(item.materialGroup)}`
                ].toLowerCase()
              ) {
                item["enquiryNumber"] =
                  subenquiry.enquiryNumber;
                item["id"] = subenquiry.id;
                item["action"] = "C";
                item["productCost"] = 0;
                if (item.nowAvailabilityChecked) {
                  item["productCost"] +=
                    (item.nowAvailability
                      ? parseFloat(item.nowAvailability)
                      : 0) *
                    (item.nsp ? parseFloat(item.nsp) : 0);
                }
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<<<<<
                if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked) {
                  item["productCost"] +=
                    (item.hrs24Availability
                      ? parseFloat(item.hrs24Availability)
                      : 0) *
                    (item.nsp ? parseFloat(item.nsp) : 0);
                }
                // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
                if (item.airAvailabilityChecked) {
                  if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                    item["airAvailability"] = 0;
                    item["aop"] = 0;
                    item["airAvailabilityChecked"] = false
                  }
                  item["productCost"] +=
                    (item.airAvailability && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer"
                      ? parseFloat(item.airAvailability)
                      : 0) *
                    (item.aop && customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer" ? parseFloat(item.aop) : 0);
                }
              }
            });
          }
        });
        return item;
      });

      if (zeroProducts && zeroProducts.length > 0) {
        let data: any = sendEmailObj(zeroProducts);
        await dispatch(sendZeroProductsEmail(data));
      }

      let updationData: any = {};
      productsTemp.map((singleProduct: any) => {
        if (singleProduct["id"]) {
          if (updationData[singleProduct["id"]]) {
            updationData[singleProduct["id"]].push(
              singleProduct
            );
          } else {
            updationData[singleProduct["id"]] = [
              singleProduct,
            ];
          }
        }
      });

      let finalData: any = [];
      Object.keys(updationData).map((singlekey: any) => {
        // let tc = changeCheckBox ? getEstimatedTotal(true) : estTotal;
        let tc: any = getEstimatedTotal(isAvailabiityChecked, true);
        finalData.push({
          id: singlekey,
          masterReferenceNumber: referenceNumber,
          products: updationData[singlekey],
          // totalCost: changeCheckBox ? getEstimatedTotal(true) : estTotal,
          totalCost: getEstimatedTotal(isAvailabiityChecked, true),
          netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true)
        });
      });
      await dispatch(
        updateEnquiry(customerTierType, !shipToPartyFeatureAccess ? '' : customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "SELF-PIC" ? firstAddress.shiftToPartyCustomerNumber : multipleAddressData,
          enquiryData[0].B2bEnquiryId,
          finalData,
          true,
          null,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
          true,
          null,
          null,
          null,
          true
        )
      );
      setLoading(false);
      setShowConfirmDialogPopup(true);
    } else {
      let enquiryDetailsQueueCheckData: any = await dispatch(verifyEnquiryQueueCheck(customerTierType, enquiryData[0].B2bEnquiryId, enquiryData[0].customerId));
      if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData.message.status && enquiryDetailsQueueCheckData.message.status == "S" && enquiryDetailsQueueCheckData.message.message == "Order Created") {
        setSuccessMessage(`Order successfully placed`);
        setOpenErrorSnackbar(false);
        handleopenCloseSnackbar(true);
        setLoading(false);
        if (enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.length > 0) {
          // setorderData(enquiryDetailsQueueCheckData.data[0].orderSummary);
          let summaryData: any = [];
          enquiryDetailsQueueCheckData.data.map((item: any) => {
            summaryData = [...summaryData, ...item.orderSummary]
          })

          let total = 0;
          enquiryDetailsQueueCheckData.data && Array.isArray(enquiryDetailsQueueCheckData.data) && enquiryDetailsQueueCheckData.data.map((item: any) => {
            if (item.brandTotal) {
              total += parseFloat(item.brandTotal)
            }
          })

          setorderTotal(total)
          setorderData(summaryData);
        }
        setShowConfirmDialogPopup(true);
        let defaultAddress = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.id === defaultAddressId)
        if (defaultAddress && defaultAddress.shiftToPartyCustomerNumber)
          setMultipleAddressData(defaultAddress && defaultAddress.shiftToPartyCustomerNumber);
        if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
          if (customerAccountType === 'Credit') {
            dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
          } else {
          }
        } else {
          dispatch(getCreditLimit(sapCustomerId, webApplicationLoginId));
        }
        setLoading(false);
        // history.push("/ordersummarydetails");
      } else {
        if (enquiryDetailsQueueCheckData.message.message.toLowerCase() == 'order placement failed') {
          setorderQueued(false)
        }
        setOpenSnackbarType("error");
        setSuccessMessage(enquiryDetailsQueueCheckData && enquiryDetailsQueueCheckData.message && enquiryDetailsQueueCheckData?.message?.message);
        handleopenCloseSnackbar(true);
        setLoading(false);
      }
      setLoading(false);
    }
  }

  const handleChooseCustomer = () => {
    setOpenChooseCustomerDialog(true);
  }

  const openCloseChooseCustomer = async () => {
    let jsonData: any = [];
    let newProducts: any = products;
    await resetData();
    await setproducts([]);
    newProducts.map((data: any) => {
      jsonData.push({
        productId: data.productId,
        quantity: data.quantity,
      });
    });
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0)
      commonInsertionLogic(jsonData, false, false, customerTierType, true);
    setOpenChooseCustomerDialog(false);
  }


  const handleOrderLogPopup = () => {
    setOpenOrderLogPopup(true);
  }

  const openCloseOrderLogPopup = () => {
    setOpenOrderLogPopup(false);
  }

  const handleShippingAddDialog = () => {
    setOpenShippingAddDialog(true);
  }

  const openCloseShippingAddSelectionDialog = (address?: any) => {
    if (address) {
      setAddressData([...newAddressData, address])
      let e = { target: { value: address.shiftToPartyCustomerNumber } }
      handleChangeAddress(e)
    }

    setOpenShippingAddDialog(false);
  }

  const requestQuotationHandler = async () => {
    if (!orderQueued) {
      var now = moment(new Date());
      var start = moment(
        isAvailabiityCheckedAtTime
          ? new Date(isAvailabiityCheckedAtTime)
          : new Date()
      );
      var duration: any = moment.duration(start.diff(now));
      var minutes: any = Math.abs(duration.asMinutes());
      if (sessionStorage.getItem('userType') === 'FSE') {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 60)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setLoading(false);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      } else {
        if (
          !isAvailabiityCheckedAtTime ||
          (minutes && minutes > 5)
        ) {
          setOpenSnackbarType("error");
          setOpenErrorSnackbar(true);
          setSuccessMessage(
            "Availability Expired: Please check availability again"
          );
          handleopenCloseSnackbar(true);
          return;
        }
      }
      let productsTemp = JSON.parse(
        JSON.stringify(products)
      );
      // if (procuredPart && procuredPart.length > 0) {
      //   productsTemp = productsTemp.filter(
      //     (product: any) => !product.isProcurementPart
      //   );
      //   setproducts([...productsTemp]);
      // }
      if (materialNotExtendedPart && materialNotExtendedPart.length > 0) {
        productsTemp = productsTemp.filter(
          (product: any) => !product.isMaterialNotExtended
        );
        setproducts([...productsTemp]);
        setActualProductLength(productsTemp.length);
      }
      if (zeroProducts && zeroProducts.length > 0) {
        productsTemp = productsTemp.filter(
          (product: any) => parseInt(product.nsp) !== 0
        );
        setproducts([...productsTemp]);
        setActualProductLength(productsTemp.length);
      }

      productsTemp = productsTemp.filter(
        (product: any) => product.isValid
      );
      let airOrder: any = false;
      productsTemp.map((item: any) => {
        if (item.airAvailabilityChecked && item.airAvailability > 0) {
          airOrder = true;
        }
      });

      setIsBackorder(airOrder);
      let emptyRefNo = false;
      let emptyQuantity = false;
      let itemsSelected = false;
      productsTemp = productsTemp.map((item: any) => {
        // Problem pats conflcit resolved
        if (item && item.materialGroup) {
          if (
            (!brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`] ||
              !brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].trim()) && item.isValid
          ) {
            emptyRefNo = true;
          }
        }
        if (referenceNumber === "") {
          emptyRefNo = true;
        }
        if ((item.nowAvailabilityChecked && item.nowAvailability > 0) || (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && item.hrs24AvailabilityChecked && item.hrs24Availability > 0) || (item.airAvailabilityChecked && item.airAvailability > 0)) {
          itemsSelected = true;
        }
        if (!parseInt(item.quantity)) {
          emptyQuantity = item.productId;
        }
        item["enquiryReferenceNumber"] =
          brandRefNumbers[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`];
        item["masterReferenceNumber"] = referenceNumber;
        item["deliveryBlockReason"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item?.materialGroup)}`]}`]}`].deliveryBlockReason : '';
        item["deliveryBlockReasonCode"] = item?.materialGroup ? deliveryBlockReason[`${orgIdBrandsMappingReverse[`${enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`]}`]}`].deliveryBlockReasonCode : '';

        return item;
      });
      if (emptyRefNo) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(
          `All reference numbers are mandatory`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (emptyQuantity) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(
          `Quantity of product ${emptyQuantity} should be greater that zero`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (!itemsSelected) {
        setOpenSnackbarType("error");
        setOpenErrorSnackbar(true);
        setSuccessMessage(
          `Please select atleast one item with quantity greater than 0 to proceed.`
        );
        handleopenCloseSnackbar(true);
        return;
      }
      if (zeroProducts && zeroProducts.length > 0) {
        let data: any = sendEmailObj(zeroProducts);
        await dispatch(sendZeroProductsEmail(data));
      }

      // let updationData: any = {};
      // productsTemp.map((singleProduct: any) => {
      //   if (singleProduct["id"]) {
      //     if (updationData[singleProduct["id"]]) {
      //       updationData[singleProduct["id"]].push(
      //         singleProduct
      //       );
      //     } else {
      //       updationData[singleProduct["id"]] = [
      //         singleProduct,
      //       ];
      //     }
      //   }
      // });

      // let finalData: any = [];
      // Object.keys(updationData).map((singlekey: any) => {
      //   // let tc = changeCheckBox ? getEstimatedTotal(true) : estTotal;
      //   let tc: any = getEstimatedTotal(isAvailabiityChecked, true);
      //   finalData.push({
      //     id: singlekey,
      //     masterReferenceNumber: referenceNumber,
      //     products: updationData[singlekey],
      //     // totalCost: changeCheckBox ? getEstimatedTotal(true) : estTotal,
      //     totalCost: getEstimatedTotal(isAvailabiityChecked, true),
      //     netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true)
      //   });
      // });
      // await dispatch(
      //   updateEnquiry(customerTierType,
      //     enquiryData[0].B2bEnquiryId,
      //     finalData,
      //     true,
      //     null,
      //     enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
      //     enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
      //     true
      //   )
      // );
      // await dispatch(
      //   updateEnquiry(customerTierType,
      //     enquiryData[0].B2bEnquiryId,
      //     finalData,
      //     false,
      //     null,
      //     enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].enquiryNumber,
      //     enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].b2bLostSalesReportId ? enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp(),
      //     true
      //   )
      // );
    }
    setShowRequestQuotationDialog(true);
    setStatusSuccessChecked(false)
  }

  const handleTecDoc = async (enquiryId: any) => {
    if (isUpdateMode && enquiryId) {
      if (isEnquirySaved && enquiryId && sessionStorage.getItem('userType') === 'FSE') {
        sessionStorage.setItem('isUpdate', 'true')
      }
      await dispatch(setEnquiryId(enquiryId));
      history.push("oescatalogue")
    } else {
      await dispatch(setEnquiryId('create'));
      history.push("oescatalogue")
    }
    // if ((products.length > 0)) {
    //   // setproducts([...products,{productId:"1222233"}])
    //   setOes(true)
    // } else {
    //   <a href="https://web.stg.tecalliance.net/alfuttaim-catalog/en/home"></a>
    //   //window.open("https://web.stg.tecalliance.net/alfuttaim-catalog/en/home");
    //   history.push("oescatalogue")
    //   setOes(false)
    // }
  }
  const showDownloadEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ENQUIRY_EXCEL);
  const showPlaceOrder = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.PLACE_ORDER);
  const showStockEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.STOCK_ENQUIRY);
  const showUpdateEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPDATE_ENQUORY);

  // ON CHANGE OF DELIVERY TYPE 
  const changeDeliveryType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const checkOrderLogs = async () => {
    handleOrderLogPopup();
  }

  useEffect(() => {
    if (featureAccessibility && featureAccessibility.length > 0 && userType === 'User') {
      let featureAccessibilityAddress = featureAccessibility.filter((ele: any) => {
        return ele && ele.featureCode == "SELECT_DEFAULT_ADDRESS"
      });
      if (featureAccessibilityAddress && featureAccessibilityAddress.length > 0) {
        if (featureAccessibilityAddress[0].value == false) {
          let arr: any = [];
          let defaultAddress: any = addressData && addressData.length > 0 && addressData.find((ele: any) => ele.id === defaultAddressId);
          setIsAdminSetDefaultAddress(true);
          arr.push(defaultAddress);
          setAddressData(arr);
        }
      }
    }
  }, [featureAccessibility, userType]);

  const isAccess = () => {
    if ((customerTierType && customerTierType.toLowerCase() === "dealer") || (customerTierType && customerTierType === "TBL Dealer") || (customerTierType && customerTierType.toLowerCase() === "government") || (customerTierType && customerTierType.toLowerCase() === "fleet") || (customerTierType && customerTierType.toLowerCase() === "int. business")) {
      return true
    } else {
      return false
    }
  }

  // const getTotalCount = () => {
  //   let count: any = 0;
  //   if (products && products.length > 0 && invalidParts && invalidParts.length > 0 && isAvailabiityChecked) {
  //     count = products.length + invalidParts.length;
  //   } else if (products && products.length > 0) {
  //     count = products.length;
  //   } else {
  //     count = 0
  //   }
  //   return count;
  // }


  const getTotalCount = () => {
    let count = 0;
    const filteredProducts = products.filter((product: any) => {
      return !invalidParts.some((invalid: any) => invalid.seq === product.seq && invalid.productId === product.productId);
    });

    count = filteredProducts.length + invalidParts.length;
    return count;
  }

  return (
    <Fragment>
      {((enquiryInfo && enquiryInfo.loading) || (favoritesList && favoritesList.loading)) && <FullscreenLoader />}
      {loading && <FullscreenLoader />}
      {(getCustomerDetailsBySapId && getCustomerDetailsBySapId.loading) && <FullscreenLoader />}

      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
          setOpenSnackbarType("");
        }}
      />
      {sessionStorage.setItem('enquiry-current-status', isUpdateMode
        ? enquiryAction === "view"
          ? "View"
          : "Update"
        : "New")}
      {openOrderSummary === false &&
        <div className="d-flex align-items-center">
          <h2 className="page-title">
            <span>
              {isUpdateMode
                ? enquiryAction === "view"
                  ? "View"
                  : "Update"
                : "New"}{" "}
              Order Enquiry{" "}
              {isEnquirySaved && (
                <span className="font-size-13">
                  {isUpdateMode &&
                    enquiryData &&
                    enquiryData[0] &&
                    `(${enquiryData[0].B2bEnquiryId})`}
                </span>
              )}
            </span>
          </h2>
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        </div>}
      {sessionStorage.getItem('userType') === 'FSE' ?
        <>
          {openOrderSummary === false ?
            <div className="m-2 row">
              <div className="row mt-2">
                <div className="tandcText m-2 row">
                  {/* <div className="tandcHeading row px-2">Selected Customer - <span className="tandcHeading-font ml-1">{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName ? getCustomerDetailsBySapId.data.companyName : "-"}</span> {" "} */}
                  <div className="tandcHeading row px-2">Customer : <span className="tandcHeading-font-type ml-1">{getChoosenCustomerName ? getChoosenCustomerName : "-"}</span> {" "}
                    <span className="tandcHeading-font-type ml-1">
                      {getChoosenCustomerSapCustomerId ?
                        // `(${getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId ? getCustomerDetailsBySapId.data.sapCustomerId : "-"})`}
                        `(${getChoosenCustomerSapCustomerId ? getChoosenCustomerSapCustomerId : "-"})` : ""}
                    </span></div>
                </div>
                {/* need to update here TPC DEALER  */}
                {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType && (getCustomerDetailsBySapId.data.customerTierType.toLowerCase() === "workshop" || getCustomerDetailsBySapId.data.customerTierType.toLowerCase() === "tpcdealer") || (sessionStorage.getItem('customerTypeofCustomer') === "workshop" || sessionStorage.getItem('customerTypeofCustomer') === "tpcdealer") ?
                  <>
                    {getSelectedOrderType === "CASH" || getSelectedOrderType === "CRD" ?
                      <>
                        <div className="tandcHeading row px-4 mt-2">Order Type : <span className="tandcHeading-font-type ml-1">{getSelectedOrderType === "CRD" ? "Credit" : getSelectedOrderType === "CASH" ? "Cash" : "-"}</span> {" "}
                        </div>
                        <div className="tandcHeading row px-4 mt-2">Sales Office : <span className="tandcHeading-font-type ml-1">{" "}{getSelectedOfficeTitle ? getSelectedOfficeTitle : "-"}</span> {" "}
                        </div>
                      </> : ""}
                  </>
                  : ""}

                {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType && getCustomerDetailsBySapId.data.customerTierType.toLowerCase() === "int. business" || sessionStorage.getItem('customerTypeofCustomer') === 'int. business' ?
                  <>
                    {getOrderCreatedBy === "AA" || getOrderCreatedBy === "MA" ?
                      <>
                        <div className="tandcHeading row px-4 mt-2">Order Created By : <span className="tandcHeading-font-type ml-1">{getOrderCreatedBy === "MA" ? "Multi Auto" : getOrderCreatedBy === "AA" ? "Arch Auto" : "-"}</span> {" "}
                        </div>
                      </>
                      : ""}
                  </>
                  : ""}
                {sessionStorage.getItem('userType') === 'FSE' && !isUpdateMode && !enquiryData ?
                  <Button
                    className="mt-0"
                    disabled={(((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}>
                    <span className="font-size-13 button-font-changes" onClick={(e) => {
                      if (sessionStorage.getItem('userType') === 'FSE' || userType === "FSE") {
                        setOnClickOfChangeButton(true);
                        handleChooseCustomer();
                        // handleChooseCustomerIWSFSEDialog();
                        setLoading(true);
                        let data: any = dispatch(getFSECustomerList());
                        dispatch(getCustomerDetailsByCustomerID(getChoosenCustomerSapCustomerId));
                        if (data && data.error) {
                          setOpenSnackbar(true)
                          setOpenErrorSnackbar(true)
                          setSuccessMessage(data && data.error);
                          setLoading(false);
                        } else {
                          setOpenSnackbar(false)
                          setOpenErrorSnackbar(false)
                          setLoading(false);
                        }
                        setLoading(false);
                        e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                      } else {
                        e.stopPropagation(); dispatch(getUniqueEnquirySessionId(getUniqueTimestamp())); history.push('/createenquiry');
                      }
                    }}>Change </span>
                  </Button>
                  : ''}
              </div>
            </div>
            : ""}
        </>
        : ""}
      {openOrderSummary === false ?
        <>
          {enquiryAction !== "view" && (
            <div className="row mt-0">
              <div className="col-12">
                <div className="cardCommon p-3">
                  <div className="d-flex align-items-center">
                    <h5 className="page-sub-title mb-2">Search Parts</h5>
                    <div className="ml-auto">
                      <span className="cursor-pointer mr-2">
                        {/* {(customerTierType === 'Workshop' || sessionStorage.getItem('userType') === 'FSE') && */}
                        {(customerTierType && (customerTierType.toLowerCase() === 'workshop' || customerTierType.toLowerCase() === "tpcdealer")) &&
                          <IconButton className="mr-1 searchbarOptions" size="small"
                            onClick={() => {
                              setOes(true);
                              handleTecDoc(enquiryData &&
                                enquiryData[0] &&
                                enquiryData[0].B2bEnquiryId);
                            }}
                          >
                            <span className="material-icons-outlined" style={{ fontSize: "20px" }}>fact_check</span>
                            <span className="font-size-13 load-from-fav ml-1">Import From Catalogue </span>
                          </IconButton>
                        }
                      </span>
                      {sessionStorage.getItem('userType') !== 'FSE' && (
                        <IconButton
                          disabled={customerTierType && customerTierType === "Dealer" &&
                            (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                        >
                          <ViewFavorite commonInsertionLogic={commonInsertionLogic} isQueue={isQueue} orderQueued={orderQueued} />
                        </IconButton>
                      )}
                      {/* {sessionStorage.getItem('userType') !== 'FSE' && (
                        <> */}
                      {showImportExcel &&
                        <IconButton
                          disabled={isQueue || orderQueued ? true : customerTierType && customerTierType === "Dealer" &&
                            (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}>
                          <ImportDialog
                            isOpened={() => {
                              sessionStorage.setItem(
                                "tempProducts",
                                JSON.stringify(products ? products : [])
                              );
                            }}
                            commonInsertionLogic={(jsonData: any) => {
                              commonInsertionLogic(jsonData, true);
                            }}
                          />
                        </IconButton>
                      }
                      {/* </>
                      )} */}
                    </div>
                  </div>
                  <TextField
                    disabled={customerTierType && customerTierType === "Dealer" &&
                      (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                    onChange={(e) => setproductId(e.target.value)}
                    autoFocus
                    inputRef={ref => inputRefs.push(ref)}
                    onPaste={(e) => {
                      let clipData: any = e.clipboardData.getData("Text");
                      let crlf: any =
                        String.fromCharCode(13) + String.fromCharCode(10);
                      let clipRows: any = clipData.split(String.fromCharCode(13));
                      let jsonData: any = [];
                      for (let iRow: any = 0; iRow < clipRows.length - 1; iRow++) {
                        let productId = clipRows[iRow].split(
                          String.fromCharCode(9)
                        )[0];
                        var quantity = isNumeric(
                          clipRows[iRow].split(String.fromCharCode(9))[1]
                        )
                          ? clipRows[iRow].split(String.fromCharCode(9))[1]
                          : 1;
                        jsonData.push({
                          productId: productId.replace(/(\r\n|\n|\r)/gm, ""),
                          quantity: quantity && quantity.toString() && quantity.toString().slice(0, 5),
                        });
                      }
                      if (
                        jsonData &&
                        Array.isArray(jsonData) &&
                        jsonData.length > 0
                      )
                        commonInsertionLogic(jsonData);
                    }}
                    value={productId}
                    onKeyDown={(e: any) => {
                      const event = e;
                      if (e.keyCode == 13) {
                        if (productId && productId.trim()) {
                          inputRefs[1].focus();
                          event.preventDefault();
                        }
                        // if (productId && productId.trim()) {
                        //   commonInsertionLogic([
                        //     {
                        //       productId: productId.trim(),
                        //       quantity:
                        //         quantity &&
                        //           parseInt(quantity) &&
                        //           parseInt(quantity) >= 1
                        //           ? parseInt(quantity)
                        //           : 1,
                        //     },
                        //   ]);
                        // }
                      }
                    }}
                    size="small"
                    className="col-sm-12 col-md-6 col-ml-7 col-xl-8 pr-3 commonRoundedInputs common-input-font-size"
                    label="Search By Part Number"
                    variant="outlined"
                  />
                  <TextField
                    disabled={customerTierType && customerTierType === "Dealer" &&
                      (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                    type="text"
                    id="quantity"
                    inputRef={ref => inputRefs.push(ref)}
                    onChange={(e) => {
                      let value = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                        parseInt(e.target.value) >= 1
                        ? parseInt(e.target.value)
                        : "";
                      setquantity(value);
                    }}
                    onKeyDown={(e: any) => {
                      if (e.keyCode == 13) {
                        if (productId && productId.trim()) {
                          commonInsertionLogic([
                            {
                              productId: productId.trim(),
                              quantity:
                                quantity &&
                                  parseInt(quantity) &&
                                  parseInt(quantity) >= 1
                                  ? parseInt(quantity)
                                  : 1,
                            },
                          ]);
                          inputRefs[0].focus();
                        }
                      }
                    }}
                    value={quantity}
                    size="small"
                    className="col-sm-6 col-md-3 col-ml-2 col-xl-2 pr-3 commonRoundedInputs common-input-font-size"
                    label="Quantity"
                    inputProps={{ maxLength: 5 }}
                    variant="outlined"
                  />
                  <Button
                    disabled={customerTierType && customerTierType === "Dealer" &&
                      (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                    className="col-sm-6 col-md-3 col-ml-3 col-xl-2 font-12 addOrderEnqButton"
                    size="small"
                    onClick={async () => {
                      if (productId && productId.trim()) {
                        commonInsertionLogic([
                          {
                            productId: productId.trim(),
                            quantity:
                              quantity &&
                                parseInt(quantity) &&
                                parseInt(quantity) >= 1
                                ? parseInt(quantity)
                                : 1,
                          },
                        ]);
                      }
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Add to Order Enquiry
                  </Button>
                </div>
              </div>
            </div>
          )}

          <div className="row mt-4">
            <div className="col-12">
              <div className="d-flex align-items-center mb-2">
                <h5 className="page-sub-title">
                  Order Enquiry Item List
                  <input
                    value={referenceNumber}
                    disabled={customerTierType && customerTierType === "Dealer" &&
                      (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                    maxLength={20}
                    onChange={(e) => {
                      if (enquiryAction !== "view") {
                        setreferenceNumber(e.target.value ? e.target.value : "");
                        setbrandRefNumbers({
                          "2001": e.target.value ? e.target.value : "",
                          "2002": e.target.value ? e.target.value : "",
                          "2003": e.target.value ? e.target.value : "",
                          "2380": e.target.value ? e.target.value : "",
                          "2195": e.target.value ? e.target.value : "",
                        });
                      }
                    }}
                    type="text"
                    className=" ml-3 referenceNumberText"
                    placeholder="Your reference number"
                  />
                  <span className="ml-3 totalCountTextSmallest">Total parts {getTotalCount()}</span>
                  {enquiryAction === "view" &&
                    enquiryData &&
                    Array.isArray(enquiryData) &&
                    enquiryData.length > 0 &&
                    ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                      (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) && (
                      <span className="text-danger ml-3">
                        Order Enquiry Cancelled
                      </span>
                    )}
                </h5>
                <div className="ml-auto searchbarOptions">
                  {enquiryAction !== "view" && (
                    <>
                      <span className="mr-2 commonRoundedInputs">
                        {isAvailabiityChecked && isAvailabiityCheckedAtTime && (
                          <span className="offer px-2 font-11 float-left">
                            <span>
                              Verified At -{" "}
                              {moment(isAvailabiityCheckedAtTime).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                            </span>
                            {sessionStorage.getItem('userType') === 'FSE' ?
                              (<>
                                {checkAvailabilityButtonText === CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL && !orderQueued && getCounterFSE()}
                              </>)
                              :
                              (<>
                                {checkAvailabilityButtonText === CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL && !orderQueued && getCounter()}
                              </>)
                            }
                          </span>
                        )}
                        <TextField
                          disabled={customerTierType && customerTierType === "Dealer" &&
                            (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                          id="availabilityType"
                          select
                          label="Availability Type"
                          variant="outlined"
                          className="availabilityDropdown"
                          size="small"
                          value={availability}
                          onChange={handleAvailabilityChange}
                        >
                          <MenuItem key={1} value="All">
                            All
                          </MenuItem>
                          <MenuItem key={2} value="Now">
                            Now
                          </MenuItem>
                          {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") ?
                            <MenuItem key={3} value="24">
                              24hrs
                            </MenuItem>
                            : ""}
                          <MenuItem key={4} value="BO">
                            BO
                          </MenuItem>
                        </TextField>
                      </span>
                      {/* <span className="mr-2 cursor-pointer"> */}
                      <IconButton
                        disabled={orderQueued || (checkAvailabilityButtonText.toLowerCase() !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL.toLowerCase()) ? true : customerTierType && customerTierType === "Dealer" &&
                          (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                        className="mr-1 mt-2" size="small"
                        onClick={() => {

                          onClickDelete();
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                        <span className="font-size-13 load-from-fav ml-1">
                          Delete
                        </span>
                      </IconButton>
                      {sessionStorage.getItem('userType') === 'FSE' && <IconButton
                        disabled={orderQueued || (checkAvailabilityButtonText.toLowerCase() !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL.toLowerCase()) ? true : enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled || (isAvailabiityChecked && products.length) ? false : true}
                        className="mr-1 mt-2" size="small"
                        onClick={() => {
                          let hasDeletions = false;
                          let selected: any = [];
                          let productsTemp: any = JSON.parse(
                            JSON.stringify(products)
                          );
                          // isChangeCheckBox(true);
                          let superseeded: any = productsTemp.filter((p: any) => p.isSuperseeded)
                          productsTemp = productsTemp.filter(
                            (item: any) => {
                              let issuperseededexist: any = superseeded.find((s: any) => s.supercessionMaterial === item.productId && parseInt(s.nsp) > 0);
                              if (issuperseededexist && parseInt(issuperseededexist?.nsp) > 0) {
                                return true
                              } else {
                                if (parseInt(item.nowAvailability) == 0 && !issuperseededexist) {
                                  selected.push(item.productId)
                                  hasDeletions = true;
                                }
                                return parseInt(item.nowAvailability) > 0
                              }
                            }
                          );
                          if (isEnquirySaved) {
                            if (hasDeletions) {
                              setIsInvalidPartsExist(false);
                              saveUpdateEnquiryOnDeleteUnavailbility(false, true,)
                            }
                          } else {
                            setproducts([...productsTemp]);
                            dispatch(deleteLostSaleReport(customerTierType, selected, enquirySessionId))
                          }
                        }}
                      >
                        <DeleteIcon fontSize="inherit" />
                        <span className="font-size-13 load-from-fav ml-1">
                          Delete Unavailable
                        </span>
                      </IconButton>}
                      {/* </span> */}
                    </>
                  )}
                  {/* {customerTierType && customerTierType === "Dealer" && enquiryAction === "update" && requestSPData && requestSPData.status === "Approved" && requestSPData && requestSPData.expiredAfterApproval === true ? */}
                  {customerTierType && customerTierType === "Dealer" && enquiryAction === "update" ?
                    <span className="cursor-pointer pr-2 pl-2">
                      <IconButton
                        onClick={() => {
                          setshowApproveDialog(true);
                        }}
                        className="mr-1 mt-2" size="small">
                        <span className="font-size-13 re-order-button">
                          Copy & Create New Enquiry
                        </span>
                      </IconButton>
                    </span>
                    : ""}
                  {/* {customerTierType && customerTierType === "Dealer" && enquiryAction === "view" && requestSPData && requestSPData.status === "Approved" && requestSPData && requestSPData.expiredAfterApproval === true ? */}
                  {customerTierType && customerTierType === "Dealer" && enquiryAction === "view" && (sessionStorage.getItem('userType') !== 'FSE') ?
                    <span className="cursor-pointer pr-3">
                      <IconButton
                        onClick={() => {
                          setshowApproveDialog(true);
                        }}
                        className="mr-1 mt-2" size="small">
                        <span className="font-size-13 re-order-button">
                          Re-Order Enquiry
                        </span>
                      </IconButton>
                    </span>
                    : ""
                  }
                  {
                    showDownloadEnquiry && products.length > 0 &&
                    // <span className="cursor-pointer">
                    <IconButton
                      // disabled={customerTierType && customerTierType === "Dealer" &&
                      //   (((requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out") &&
                      //     (enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "")) ? true : false)}
                      className="mr-3 mt-2" size="small" onClick={() => exportData()}>
                      <GetAppIcon fontSize="inherit" />
                      <span className="font-size-13 load-from-fav ml-1">
                        Export Excel
                      </span>
                    </IconButton>
                    // </span>
                  }
                  {
                    enquiryAction === "view" &&
                      enquiryData &&
                      Array.isArray(enquiryData) &&
                      enquiryData.length > 0 &&
                      ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                        (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) ?
                      <>
                        {enquiryAction === "view" && (
                          <span className="cursor-pointer">
                            <IconButton
                              // disabled={requestStatus === "New Request"}
                              onClick={() => {
                                // history.goBack();
                                history.push("/orderenquiry");
                              }}
                              className="mr-1 mt-2" size="small">
                              <ArrowBackIosIcon fontSize="inherit" />
                              <span className="font-size-13 load-from-fav">
                                Back to List
                              </span>
                            </IconButton>
                          </span>
                        )}
                      </>
                      :
                      <>
                        {enquiryAction === "view" && (
                          <>
                            {(customerTierType === "Government" || customerTierType === "Fleet") && (sessionStorage.getItem('userType') !== 'FSE') ?
                              <span className="cursor-pointer pr-2 pl-2">
                                <IconButton
                                  onClick={() => {
                                    setshowApproveDialog(true);
                                  }}
                                  className="mr-1 mt-2" size="small">
                                  <span className="font-size-13 re-order-button">
                                    Copy & Create New Enquiry
                                  </span>
                                </IconButton>
                              </span>
                              : ""}
                            {sessionStorage.getItem('userType') === 'FSE' ?
                              <span className="cursor-pointer pr-2 pl-2">
                                <IconButton
                                  onClick={() => {
                                    setshowApproveDialog(true);
                                  }}
                                  className="mr-1 mt-2" size="small">
                                  <span className="font-size-13 re-order-button">
                                    Copy & Create New Enquiry
                                  </span>
                                </IconButton>
                              </span>
                              : ""}
                            <span className="cursor-pointer">
                              <IconButton
                                // disabled={requestStatus === "New Request"}
                                onClick={() => {
                                  // history.goBack();
                                  if (quotation) {
                                    history.push("/quotation");
                                  } else {
                                    // history.push("/orderenquiry");
                                    history.goBack();
                                  }
                                }}
                                className="mr-1 mt-2" size="small">
                                <ArrowBackIosIcon fontSize="inherit" />
                                <span className="font-size-13 load-from-fav">
                                  Back to List
                                </span>
                              </IconButton>
                            </span>
                          </>
                        )}
                      </>
                  }
                </div >
              </div >
              <div className="cardCommon">
                <div className={sessionStorage.getItem('userType') === 'FSE' ? "enquiryDetails-FSE" : "enquiryDetails"} unselectable="on">
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead className="">
                        <TableRow className="tableRowPanelsCreateEnquiry">
                          <TableCell className="w-60p pr-0">
                            <input type="checkbox"
                              disabled={customerTierType && customerTierType === "Dealer" &&
                                (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                              checked={isSelectedAll}
                              onChange={() => {
                                if (enquiryAction !== "view") {
                                  let productsTemp: any = JSON.parse(
                                    JSON.stringify(products)
                                  );
                                  productsTemp = productsTemp.map((item: any) => {
                                    item.selected = !isSelectedAll;
                                    return item;
                                  });
                                  setproducts([...productsTemp]);
                                  setisSelectedAll(!isSelectedAll);
                                }
                              }}
                            />
                          </TableCell>
                          <TableCell className="">
                            <h6 className="blueLabelTable">Part No.</h6>
                            <div className="greyLabelTable">
                              Part Name &amp; Desc
                            </div>
                          </TableCell>
                          <TableCell className="">
                            <h6 className="blueLabelTable">Brand</h6>
                          </TableCell>
                          <TableCell className="">
                            <h6 className="blueLabelTable">Quantity</h6>
                            <div className="row no-gutters">
                              <div className={sessionStorage.getItem('userType') === 'FSE' ? 'col-4 greyLabelTable' : 'col-6 greyLabelTable'}>Qty</div>
                              <div className={sessionStorage.getItem('userType') === 'FSE' ? 'col-4 greyLabelTable' : 'col-6 greyLabelTable'}>Avl</div>
                              {sessionStorage.getItem('userType') === 'FSE' && (
                                <Tooltip title="Stock on hand" placement="top">
                                  <div className="col-4 greyLabelTable">SOH</div>
                                </Tooltip>
                              )}
                            </div>
                          </TableCell>
                          {sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" ?
                            <TableCell className="font-10 font-weight-700 text-444"><h6>&nbsp;</h6>RSP </TableCell>
                            : ''}
                          <TableCell className="font-10 font-weight-700 text-444"><h6>&nbsp;</h6>NSP (Incl. VAT)</TableCell>
                          <TableCell className="font-10 font-weight-700 text-444"><h6>&nbsp;</h6>Amt</TableCell>
                          <TableCell className="font-10 font-weight-700 text-444"><h6>&nbsp;</h6>NSP (Excl. VAT)</TableCell>
                          <TableCell className="font-10 font-weight-700 text-444"><h6>&nbsp;</h6>BOP</TableCell>
                          <TableCell className="mw-150p">
                            <h6 className="blueLabelTable">Availability</h6>
                            <div className="row no-gutters">
                              <div className="col-4 greyLabelTable">
                                <input type="checkbox"
                                  disabled={customerTierType && customerTierType === "Dealer" &&
                                    (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                                  value={checkedAllNow}
                                  onChange={() => {
                                    if (enquiryAction !== "view") {
                                      let productsTemp: any = JSON.parse(
                                        JSON.stringify(products)
                                      );
                                      productsTemp = productsTemp.map(
                                        (item: any) => {
                                          item.nowAvailabilityChecked =
                                            !!!checkedAllNow;
                                          return item;
                                        }
                                      );
                                      setproducts([...productsTemp]);
                                      setcheckedAllNow(!checkedAllNow);
                                    }
                                  }}
                                  className="checkbox small-check mr-1"
                                // size="small"
                                />
                                Now
                              </div>
                              {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") ?
                                <div className="col-4 greyLabelTable">
                                  <input type="checkbox"
                                    value={checked24hrs}
                                    onChange={() => {
                                      if (enquiryAction !== "view") {
                                        let productsTemp: any = JSON.parse(
                                          JSON.stringify(products)
                                        );
                                        productsTemp = productsTemp.map(
                                          (item: any) => {
                                            item.hrs24AvailabilityChecked =
                                              !!!checked24hrs;
                                            return item;
                                          }
                                          // (item: any) => {
                                          //   item.hrs24AvailabilityChecked =
                                          //     !!!checked24hrs;
                                          //   return item;
                                          // }
                                        );
                                        setproducts([...productsTemp]);
                                        setChecked24hrs(!checked24hrs);
                                      }
                                    }}
                                    className="checkbox small-check mr-1"
                                  // size="small"
                                  />
                                  24hrs
                                </div>
                                : ""}
                              <div className="col-4 greyLabelTable">
                                <input type="checkbox"
                                  disabled={customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") || customerTierType === "Dealer" &&
                                    (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)}
                                  value={checkedAllBO}
                                  onChange={() => {
                                    if (enquiryAction !== "view") {
                                      let productsTemp: any = JSON.parse(
                                        JSON.stringify(products)
                                      );
                                      productsTemp = productsTemp.map(
                                        (item: any) => {
                                          item.airAvailabilityChecked =
                                            !!!checkedAllBO;
                                          return item;
                                        }
                                      );
                                      setproducts([...productsTemp]);
                                      setcheckedAllBO(!checkedAllBO);
                                    }
                                  }}
                                  className="checkbox small-check mr-1"
                                // size="small"
                                />
                                BO
                              </div>
                            </div>
                          </TableCell>
                          <TableCell className="">
                            <h6 className="blueLabelTable">Dimension</h6>
                            <div className="greyLabelTable">
                              L x W x H(mm) | W(gm)
                            </div>
                          </TableCell>
                          <TableCell className="">
                            <h6 className="blueLabelTable">Volume</h6>
                            <div className="greyLabelTable">(Per Unit)</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{renderRows()}</TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer" || customerTierType.toLowerCase() === "TBL Dealer") && (
                  <div className="container-fluid">
                    <div className="row tableBottom">
                      <div className="col-lg-2 col-md-2 col-sm-2 p-3 borderRightBottom">
                        <p className="bottomFieldNameTable">
                          Estimated Total (incl. of VAT)
                          <Tooltip title="The Estimated Total here is an approximate and may get rounded off when ordered & billed." placement="top">
                            <HelpIcon className="float-right" fontSize="small" />
                          </Tooltip>
                        </p>
                        {enquiryAction === "view" ? (
                          <p className="bottomFieldValueTable">
                            {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2)}
                          </p>
                        ) : (
                          <p className="bottomFieldValueTable ">
                            {/* AED {parseFloat(getSellingPrice(changeCheckBox ? getEstimatedTotal(true) : estTotal, isSellingPriceData, markupPercentage)).toFixed(2)} */}
                            {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(getEstimatedTotal(isAvailabiityChecked, true), isSellingPriceData, markupPercentage)).toFixed(2)}
                          </p>
                        )}
                      </div>

                      {enquiryAction !== "view" && !importOes && (
                        <NavigationPrompt when={!orderQueued && ((products.length > 0 && !isEnquirySaved) || (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated))}>
                          {({ onConfirm, onCancel }) => (
                            <React.Fragment>
                              <Dialog
                                disableBackdropClick={true}
                                open={true}
                                fullWidth={true}
                                maxWidth={'sm'}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogContent>
                                  <div className={classes.icon}>
                                    <WarningIcon className="delete-warning" fontSize="small" />
                                  </div>
                                  <div>
                                    <DialogContentText id="alert-dialog-description">
                                      <div className={`mt-3 ${classes.logoutText}`}>
                                        {importOes ? (<>
                                          {(isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                            ? `You haven't updated your Enquiry. Please update before importing parts from Catalogue`
                                            : `You haven't saved your Enquiry. Please save before importing parts from Catalogue`}
                                        </>) : (<>
                                          {(isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                            ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                            : 'Your enquiry is not saved, please press Yes to Save your enquiry.'}
                                        </>)}
                                      </div>
                                    </DialogContentText>
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    className={`mx-4 ${classes.yes}`}
                                    onClick={async () => {
                                      saveUpdateEnquiry(false);
                                      onCancel();
                                    }}>Yes</Button>
                                  <Button
                                    className={`mr-4 ${classes.cancel}`}
                                    onClick={() => {
                                      // problem parts conflict resolved uncommented the code in problem parts
                                      // if (isEnquirySaved && products.length !== actualProductLength) {
                                      // } else {
                                      //   let partsList: any = products.map((item: any) => {
                                      //     return item.productId.trim();
                                      //   });
                                      //   let searchProduct: any = [];
                                      //   products.map((item: any) => {
                                      //     searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
                                      //   })
                                      //   dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct));
                                      // }
                                      onConfirm();
                                    }}>No</Button>
                                </DialogActions>
                              </Dialog>
                            </React.Fragment>
                          )}
                        </NavigationPrompt>
                      )}
                      {enquiryAction !== "view" && importOes && (
                        <NavigationPrompt when={!orderQueued && ((products.length > 0 && !isEnquirySaved) || (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated))}>
                          {({ onConfirm, onCancel }) => (
                            <React.Fragment>
                              <Dialog
                                disableBackdropClick={true}
                                open={true}
                                fullWidth={true}
                                maxWidth={'sm'}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                              >
                                <DialogContent>
                                  <div className={classes.icon}>
                                    <WarningIcon className="delete-warning" fontSize="small" />
                                  </div>
                                  <div>
                                    <DialogContentText id="alert-dialog-description">
                                      <div className={`mt-3 ${classes.logoutText}`}>
                                        {importOes ? (<>
                                          {(isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                            ? `You haven't updated your Enquiry. Please update before importing parts from Catalogue`
                                            : `You haven't saved your Enquiry. Please save before importing parts from Catalogue`}
                                        </>) : (<>
                                          {(isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                            ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                            : 'Your enquiry is not saved, please press Yes to Save your enquiry.'}
                                        </>)}
                                      </div>
                                    </DialogContentText>
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                  <Button
                                    className={`mx-4 ${classes.yes}`}
                                    onClick={async () => {
                                      const data: any = await saveUpdateEnquiry(false);
                                      if (data) {
                                        onConfirm()
                                      } else {
                                        onCancel();
                                      }
                                    }}>
                                    {isEnquirySaved ? 'Update' : `Save`}
                                  </Button>
                                  <Button
                                    className={`mr-4 ${classes.cancel}`}
                                    onClick={() => {
                                      onCancel();
                                    }}>No</Button>
                                </DialogActions>
                              </Dialog>
                            </React.Fragment>
                          )}
                        </NavigationPrompt>
                      )}
                      <div className="col-lg-2 col-md-2 col-sm-2 p-3 borderRightBottom">
                        <div className="">
                          <div>
                            <p className="">
                              <span className="bottomFieldNameTable text-capitalize mr-4">
                                Delivery Type</span>
                            </p>
                            {enquiryAction === "view" && !importOes ?
                              <TextField select id="deliveryTypes"
                                value={deliveryType}
                                disabled
                                label="Select Delivery Type" required variant="outlined" margin="dense"
                                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                error={selectedDeliveryTypeError || invalidSelectedDeliveryType}
                                helperText={invalidSelectedDeliveryType && <span style={{ color: 'red' }}>Please select the customer type.</span>}
                                onChange={(e: any) => {
                                  handleChangeDeliveryType(e)
                                }}
                              >
                                <MenuItem value={"DLV"}>{"Delivery"}</MenuItem>
                                <MenuItem value={"SELF-PIC"}>{"Self Pickup"}</MenuItem>
                              </TextField>
                              :
                              <TextField select id="deliveryTypes"
                                value={deliveryType}
                                label="Select Delivery Type" required variant="outlined" margin="dense"
                                disabled={customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && profileDetails?.data?.data?.customerInfo?.deliveryType === "SELF-PIC" ? true : false}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                error={selectedDeliveryTypeError || invalidSelectedDeliveryType}
                                helperText={invalidSelectedDeliveryType && <span style={{ color: 'red' }}>Please select the customer type.</span>}
                                onChange={(e: any) => {
                                  handleChangeDeliveryType(e)
                                }}
                              >
                                <MenuItem value={"DLV"}>{"Delivery"}</MenuItem>
                                <MenuItem value={"SELF-PIC"}>{"Self Pickup"}</MenuItem>
                              </TextField>}
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-lg-2 col-md-2 col-sm-2 p-3 borderRightBottom" style={{ backgroundColor: changingDeliveryTypeColor }}>
                      </div> */}
                      <div className="col-lg-2 col-md-2 col-sm-2 p-3 borderRightBottom">
                        <div className="">
                          <div>
                            <p className="">
                              {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType !== "SELF-PIC" ?
                                <span className="bottomFieldNameTable text-capitalize mr-4">Shipping Address</span>
                                : <span className="bottomFieldNameTable text-capitalize mr-4">Collection Point</span>}
                            </p>
                            {customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "DLV" ?
                              (
                                <>
                                  {/* {isAdminsetDefaultAddress ? (
                                    <TextField select id="multipleAddressData"
                                      disabled={
                                        // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                        enquiryData &&
                                        Array.isArray(enquiryData) &&
                                        enquiryData.length > 0 &&
                                        ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                          (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                      value={multipleAddressData}
                                      label="Select Address" required variant="outlined" margin="dense"
                                      size="small" className="col-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                      onChange={handleChangeAddress}
                                    >
                                      {newAddressData && newAddressData.length > 0 && newAddressData.map((ele: any, index: any) => (
                                        <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}> {`${ele.streetName}, ${ele.region}, ${ele.city}, ${ele.country} ,${ele.postalCode}`} </MenuItem>
                                      ))}
                                    </TextField>
                                  ) : (
                                    <TextField select id="multipleAddressData"
                                      disabled={
                                        // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                        enquiryData &&
                                        Array.isArray(enquiryData) &&
                                        enquiryData.length > 0 &&
                                        ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                          (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                      value={multipleAddressData}
                                      label="Select Address" required variant="outlined" margin="dense"
                                      size="small" className="col-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                      onChange={handleChangeAddress}
                                    >
                                      {addressData && addressData.length > 0 && addressData.map((ele: any, index: any) => (
                                        <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}> {`${ele.streetName}, ${ele.region}, ${ele.city}, ${ele.country} ,${ele.postalCode}`} </MenuItem>
                                      ))}
                                    </TextField>
                                  )} */}
                                  {shipToPartyFeatureAccess ? (
                                    <TextField select id="multipleAddressData"
                                      disabled={
                                        // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                        enquiryData &&
                                        Array.isArray(enquiryData) &&
                                        enquiryData.length > 0 &&
                                        ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                          (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                      value={multipleAddressData}
                                      label="Select Address" required variant="outlined" margin="dense"
                                      size="small" className="col-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                      onChange={handleChangeAddress}
                                    >
                                      {newAddressData && newAddressData.length > 0 && newAddressData.map((ele: any, index: any) => (
                                        <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}>
                                          {`${ele.shiftToPartyCustomerNumber}, ${ele.transportationZone ? `${ele.transportationZone},` : ''} ${ele.location ? `${ele.location},` : ele.companyName ? `${ele.companyName},` : ''} ${ele.city ? `${ele.city},` : ''} ${ele.streetName ? ele.streetName : ''}`}
                                          {/* {`${ele.shiftToPartyCustomerNumber}, ${ele.transportationZone }, ${ele.location ? ele.location : ele.companyName}, ${ele.city} ,${ele.streetName}`} */}
                                        </MenuItem>
                                      ))}
                                      {sessionStorage.getItem('userType') === 'FSE' && (
                                        <MenuItem value={"OtherAddress"} onClick={(e) => {
                                          handleShippingAddDialog();
                                        }}> Other Address </MenuItem>
                                      )}
                                    </TextField>) : (
                                    <>
                                      <p className="bottomFieldValueTable">
                                        {address ? address : "-"}
                                      </p>
                                    </>
                                  )}
                                </>
                              )
                              :
                              <TextField select id="collectionAddressData"
                                value={collectionAddressData}
                                label="Select Address" required variant="outlined" margin="dense"
                                size="small" className="col-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={handleChangeAddress}
                              >
                                {collectionPoints && collectionPoints.length > 0 && collectionPoints.map((ele: any) => (
                                  <MenuItem className="border p-3" key={ele.defineTPCounter} value={ele.defineTPCounter}> {ele.defineTPCounter ? ele.defineTPCounter : ""}, {" "} {ele.area ? ele.area : ""}, {" "} {ele.landlinePhone ? "Landline No :" : ""} {" "} {ele.landlinePhone ? ele.landlinePhone : ""} </MenuItem>
                                ))}
                                {/* <MenuItem value={"OtherAddress"} onClick={(e) => {
                                  handleShippingAddDialog();
                                }}> Other Address </MenuItem> */}
                              </TextField>}
                          </div>
                        </div>
                      </div>
                      {customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer" ?
                        <>
                          <p className="bottomFieldNameTable">Shipping Address</p>
                          <>
                            {/* {isAdminsetDefaultAddress ? (
                              <TextField select id="multipleAddressData"
                                disabled={history.location.pathname == "/viewenquiry" ? true :
                                  // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                  enquiryData &&
                                  Array.isArray(enquiryData) &&
                                  enquiryData.length > 0 &&
                                  ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                    (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                value={multipleAddressData}
                                label="Select Address" required variant="outlined" margin="dense"
                                size="small" className="col-lg-12 col-md-12 col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={handleChangeAddress}
                              >
                                {newAddressData && newAddressData.length > 0 && newAddressData.map((ele: any, index: any) => (
                                  <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}> {`${ele.streetName}, ${ele.region}, ${ele.city}, ${ele.country} ,${ele.postalCode}`} </MenuItem>
                                ))}
                              </TextField>
                            ) : (
                              <TextField select id="multipleAddressData"
                                disabled={history.location.pathname == "/viewenquiry" ? true :
                                  // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                  enquiryData &&
                                  Array.isArray(enquiryData) &&
                                  enquiryData.length > 0 &&
                                  ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                    (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                value={multipleAddressData}
                                label="Select Address" required variant="outlined" margin="dense"
                                size="small" className="col-lg-12 col-md-12 col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={handleChangeAddress}
                              >
                                {addressData && addressData.length > 0 && addressData.map((ele: any, index: any) => (
                                  <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}> {`${ele.streetName}, ${ele.region}, ${ele.city}, ${ele.country} ,${ele.postalCode}`} </MenuItem>
                                ))}
                              </TextField>
                            )} */}
                            {shipToPartyFeatureAccess ? (
                              <TextField select id="multipleAddressData"
                                disabled={
                                  // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                  enquiryData &&
                                  Array.isArray(enquiryData) &&
                                  enquiryData.length > 0 &&
                                  ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                    (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                value={multipleAddressData}
                                label="Select Address" required variant="outlined" margin="dense"
                                size="small" className="col-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={handleChangeAddress}
                              >
                                {newAddressData && newAddressData.length > 0 && newAddressData.map((ele: any, index: any) => (
                                  <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}> {`${ele.shiftToPartyCustomerNumber}, ${ele.transportationZone ? ele.transportationZone : ''}, ${ele.location ? ele.location : ele.companyName ? ele.companyName : ''}, ${ele.city ? ele.city : ''} ,${ele.streetName ? ele.streetName : ''}`} </MenuItem>
                                ))}
                              </TextField>) : (
                              <>
                                <p className="bottomFieldValueTable">
                                  {address ? address : "-"}
                                </p>
                              </>
                            )}
                          </>
                        </>
                        : ""}
                      <div className="col-lg-6 col-md-6 col-sm-12 p-3 pb-0 borderBottom d-flex justify-content-end" >
                        {
                          customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && getIsAirAvalibityExistCount() > 0 ?
                            <p className="pt-2 bottomFieldMessage">
                              {`Note: For ordering item quantities in BO (Back Order), please contact your nearest Al Futtaim Trade Point Counter at ${dlEmails}`}
                            </p> : ""
                        }
                      </div>
                    </div>
                  </div>
                )}
                {isAccess() && (
                  <div className="container-fluid">
                    <div className="row tableBottom">
                      <div className="col-lg-3 col-md-3 col-sm-6 p-3 borderRightBottom">
                        <p className="bottomFieldNameTable">
                          Estimated Total (incl. of VAT)
                          <Tooltip title="The Estimated Total here is an approximate and may get rounded off when ordered & billed." placement="top">
                            <HelpIcon className="float-right" fontSize="small" />
                          </Tooltip>
                        </p>
                        {enquiryAction === "view" ? (
                          <p className="bottomFieldValueTable">
                            {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType && customerTierType.toLowerCase() === "int. business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2)}
                          </p>
                        ) : (
                          <p className="bottomFieldValueTable ">
                            {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType && customerTierType.toLowerCase() === "int. business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(getEstimatedTotal(isAvailabiityChecked, true), isSellingPriceData, markupPercentage)).toFixed(2)}
                            {/* AED {parseFloat(getSellingPrice(changeCheckBox ? getEstimatedTotal(true) : estTotal, isSellingPriceData, markupPercentage)).toFixed(2)} */}
                          </p>
                        )}
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 p-3 borderRightBottom">
                        <div className="d-flex">
                          <div>
                            {
                              customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && deliveryType === "DLV" ?
                                <>
                                  <p className="bottomFieldNameTable">Shipping Address</p>
                                  <p className="bottomFieldValueTable">
                                    {address ? address : "-"}
                                  </p>
                                </>
                                :
                                deliveryType === "SELF-PIC" ?
                                  <>
                                    <p className="bottomFieldNameTable">Collection Point</p>
                                    <p className="bottomFieldValueTable">
                                      {collectionPoints && collectionPoints.length > 0 && collectionPoints.map((ele: any) => {
                                        return <p> {ele.defineTPCounter ? ele.defineTPCounter : ""}, {" "} {ele.area ? ele.area : ""}, {" "} {ele.landlinePhone ? "Landline No :" : ""} {" "} {ele.landlinePhone ? ele.landlinePhone : ""} </p>
                                      })}
                                    </p>
                                  </>
                                  :
                                  <>
                                    {/* <p className="bottomFieldNameTable">Shipping Address</p>
                                      <p className="bottomFieldValueTable">
                                        {address ? address : "-"}
                                      </p> */}
                                  </>
                            }
                          </div>
                        </div>
                        {customerTierType && customerTierType.toLowerCase() !== "workshop" && customerTierType.toLowerCase() !== "tpcdealer" ?
                          <>
                            <p className="bottomFieldNameTable">Shipping Address</p>
                            {shipToPartyFeatureAccess ? (
                              <TextField select id="deliveryTypes"
                                disabled={history.location.pathname == "/viewenquiry" ? true :
                                  enquiryData &&
                                  Array.isArray(enquiryData) &&
                                  enquiryData.length > 0 &&
                                  ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                    (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                                value={multipleAddressData}
                                label="Select Address" required variant="outlined" margin="dense"
                                size="small" className="col-lg-12 col-md-12 col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={handleChangeAddress}
                              >
                                {newAddressData && newAddressData.length > 0 && newAddressData.map((ele: any, index: any) => (
                                  <MenuItem className="border p-3" key={ele.shiftToPartyCustomerNumber} value={ele.shiftToPartyCustomerNumber}>
                                    {`${ele.shiftToPartyCustomerNumber}, ${ele.transportationZone ? `${ele.transportationZone},` : ''} ${ele.location ? `${ele.location},` : ele.companyName ? `${ele.companyName},` : ''} ${ele.city ? `${ele.city},` : ''} ${ele.streetName ? ele.streetName : ''}`}
                                    {/* {`${ele.shiftToPartyCustomerNumber}, ${ele.transportationZone ? ele.transportationZone : ''}, ${ele.location ? ele.location : ele.companyName ? ele.companyName : ''}, ${ele.city ? ele.city : ''} ,${ele.streetName ? ele.streetName : ''}`} */}
                                  </MenuItem>
                                ))}
                                {sessionStorage.getItem('userType') === 'FSE' && (
                                  <MenuItem value={"OtherAddress"} onClick={(e) => {
                                    handleShippingAddDialog();
                                  }}> Other Address </MenuItem>
                                )}

                              </TextField>
                            ) : (
                              <>
                                <p className="bottomFieldValueTable">
                                  {address ? address : "-"}
                                </p>
                              </>
                            )}
                          </> : ""}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12 p-3 pb-0 borderBottom d-flex justify-content-end">
                        {isTpcDealer === true ?
                          <p className="pt-2 bottomFieldMessage-confirm-order">
                            {`To follow up on the status of your Confirmed Order, please contact your nearest Trade Point Counter at  (${TPCRegionDetails && TPCRegionDetails.email ? TPCRegionDetails.email : ""}) or (${TPCRegionDetails && TPCRegionDetails.Phone ? TPCRegionDetails.Phone : ""}).`}
                          </p>
                          : ''}
                      </div>
                    </div >
                  </div >
                )
                }
                {/* {sessionStorage.getItem('userType') !== 'FSE' ? */}
                <div className="col-12 container-fluid borderTop">
                  <div className="row tableBottom">
                    {!isShowSPR() || currentTier === "" ?
                      <div className={(sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase() === "government") || (sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase()) === "fleet" ? "col-lg-2 col-md-2 col-sm-12 p-3 borderTop" : sessionStorage.getItem('userType') === 'FSE' ? "col-lg-2 col-md-2 col-sm-6 p-3 borderTop" : sessionStorage.getItem('userType') === "FSE" && customerTierType && customerTierType === "Government" || customerTierType && customerTierType === "Fleet" ? "col-lg-4 col-md-4 col-sm-9 p-3 borderTop" : "col-lg-3 col-md-3 col-sm-8 p-3 borderTop"}>
                      </div> :
                      <div className={(sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase() === "government") || (sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase()) === "fleet" ? "col-lg-2 col-md-2 col-sm-12 p-3 borderTop" : sessionStorage.getItem('userType') === 'FSE' ? "col-lg-2 col-md-2 col-sm-6 p-3 borderTop" : sessionStorage.getItem('userType') === "FSE" && customerTierType && customerTierType === "Government" || customerTierType && customerTierType === "Fleet" ? "col-lg-4 col-md-4 col-sm-9 p-3 borderTop" : "col-lg-3 col-md-3 col-sm-8 p-3"}>
                        {customerTierType && customerTierType.toLowerCase() === "dealer" && userType === 'Admin' ?
                          <Button
                            className={`ml-2 font-size-11 ${(isAvailabiityChecked && products.length > 0) || (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true)
                              ? "actionButtomButtomsBlue"
                              : "actionButtomButtomsGrey text-black"
                              }`}
                            disabled={enquiryAction === "view" || orderQueued ? true : accessData && accessData.data && accessData.data.currentTier === "Silver" || ((accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === false) && (accessData && accessData.data && accessData.data.ableToPlaceSpecialRequest === false)) || ((requestStatus && requestStatus !== "inPrgress") && accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true)}
                            // old disabled
                            // disabled={((placeorderEnabled && isAvailabiityChecked && products.length > 0 && getEstimatedTotal(isAvailabiityChecked) >= 35000
                            //   && accessData && accessData.data && accessData.data.ableToPlaceSpecialRequest === false)
                            //   || (accessData && accessData.data && accessData.data.currentTier === "Silver") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === false))
                            // }
                            onClick={async () => {
                              let total: any = await getEstimatedTotal(isAvailabiityChecked);
                              if (total < 35000) {
                                setIsEstimatedTotalIsLess(true)
                                return true
                              }
                              // if (procuredPart && procuredPart.length > 0) {
                              //   setHideZeroMessage(true);
                              //   openZeroPricePopup(true);
                              //   return true;
                              // }
                              if (materialNotExtendedPart && materialNotExtendedPart.length > 0) {
                                setHideZeroMessage(true);
                                openZeroPricePopup(true);
                                return true;
                              }
                              if ((viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "Cancelled") && isSPRModifyData) {
                                let data: any = await dispatch(getViewRequestStatusAPI(enquiryData && Array.isArray(enquiryData) && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""))

                                if (data && data.data.length !== 0) {
                                  history.push("editrequestspecialprice")
                                }
                                return true
                              }
                              if (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true && enquiryAction !== "") {
                                dispatch(getViewRequestStatusAPI(enquiryData && Array.isArray(enquiryData) && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""))
                                history.push('/viewrequeststatus')
                                return true;
                              }
                              let hasDeletions = false;
                              let selected: any = [];
                              let newArr: any = [];
                              let productsTemp: any = JSON.parse(
                                JSON.stringify(products)
                              );
                              productsTemp.filter(
                                (p: any) => {
                                  if (p.nowAvailabilityChecked === false && p.airAvailabilityChecked === false) {
                                    selected.push(p.productId)
                                    hasDeletions = true;
                                  } else {
                                    newArr.push(p);
                                  }
                                }
                              );
                              await setproducts([...newArr]);
                              await dispatch(selectedParts(newArr, parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2), enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""));
                              if (isEnquirySaved) {
                                if (hasDeletions) {
                                  await saveUpdateEnquiry(false, true, newArr);
                                  parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2)
                                  history.push('/requestspecialprice');
                                } else {
                                  parseFloat(getSellingPrice(getViewEstimatedTotal(), isSellingPriceData, markupPercentage)).toFixed(2)
                                  history.push('/requestspecialprice');
                                }
                              }
                            }}
                            color="primary"
                            variant="contained"
                          >
                            Request Special Price
                          </Button> : null
                        }
                      </div>
                    }
                    {sessionStorage.getItem('userType') === 'FSE' ?
                      <div className={"p-2 borderBottom"}>
                        <Button
                          className={`ml-2 mt-3 font-size-11 ${(placeorderEnabled && isAvailabiityChecked && products.length > 0 && !orderQueued)
                            ? "actionButtomButtomsBlue"
                            : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                            }`}
                          disabled={orderQueued || isDisableQuotation || isQuantityUpdated || isDeleted || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            if (isAvailabiityChecked && sessionStorage.getItem('userType') === 'FSE' && customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && is24hrExist()) {
                              if (deliveryBlockReason["2195"].deliveryBlockReason === '' || deliveryBlockReason["2195"].deliveryBlockReasonCode === '') {
                                setOpenSnackbarType("error");
                                setOpenErrorSnackbar(true);
                                setSuccessMessage(`Please Select Delivery Block Reason`);
                                handleopenCloseSnackbar(true);
                                return
                              } else {
                                requestQuotationHandler();
                              }
                            } else {
                              requestQuotationHandler();
                            }
                            // setShowRequestQuotationDialog(true);
                          }}
                        >
                          Request Quotation
                        </Button>
                      </div> :
                      <></>
                    }

                    {!isShowSPR() || currentTier === "" ?
                      <div className={(sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase() === "government") || (sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase()) === "fleet" ? "p-3 " : sessionStorage.getItem('userType') === 'FSE' ? "" : sessionStorage.getItem('userType') === "FSE" && customerTierType && customerTierType === "Government" || customerTierType && customerTierType === "Fleet" ? "" : customerTierType && customerTierType === "Workshop" ? "" : customerTierType && customerTierType === "tpcdealer" ? "" : customerTierType && customerTierType === "Dealer" ? "" : "col-lg-3 col-md-3 col-sm-3 p-3 borderRightBottom"}>
                      </div> :
                      <div className={(sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase() === "government") || (sessionStorage.getItem('userType') !== "FSE" && customerTierType && customerTierType.toLowerCase()) === "fleet" ? "p-3 " : sessionStorage.getItem('userType') === 'FSE' ? "" : sessionStorage.getItem('userType') === "FSE" && customerTierType && customerTierType === "Government" || customerTierType && customerTierType === "Fleet" ? "" : customerTierType && customerTierType === "Workshop" ? "" : customerTierType && customerTierType === "Dealer" ? "" : customerTierType && customerTierType === "tpcdealer" ? "" : "col-lg-2 col-md-2 col-sm-2 p-3 borderRightBottom"}>
                        {
                          customerTierType && customerTierType === "Dealer" && accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true && (requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || requestStatus === "Rejected") ?
                            <div className="d-flex mt-2">
                              {accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true && enquiryAction !== "" ?
                                <Button
                                  // disabled={!_.get(accessData, 'data.isRequestPlacedForEnquiry', false)}
                                  disabled={accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === false}
                                  className="bottomFieldNameTable-view-price cursor-pointer"
                                  onClick={async () => {
                                    // saveUpdateEnquiry(true);
                                    dispatch(getViewRequestStatusAPI(enquiryData && Array.isArray(enquiryData) && enquiryData[0].B2bEnquiryId ? enquiryData[0].B2bEnquiryId : ""))
                                    history.push('/viewrequeststatus')
                                  }}> View Request Status</Button>
                                : ""}
                            </div> : null
                        }

                      </div>
                    }
                    {sessionStorage.getItem('userType') === "FSE" ? "" :
                      <>
                        {customerTierType && customerTierType === "Government" || customerTierType && customerTierType === "Fleet" ?
                          <div className="p-3">
                            <Button
                              className={`ml-2 mt-1 font-size-11 ${(placeorderEnabled && isAvailabiityChecked && products.length > 0)
                                ? "actionButtomButtomsBlue"
                                : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                                }`}
                              disabled={orderQueued || isDisableQuotation || isQuantityUpdated || isDeleted || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                requestQuotationHandler();
                                // setShowRequestQuotationDialog(true);
                              }}
                            >
                              Request Quotation
                            </Button>
                          </div> : null}
                      </>
                    }
                    <div className="borderBottom">
                      {(
                        <div className="d-flex justify-content-end p-3 pb-0">
                          <NavigationPrompt when={!orderQueued && ((products.length > 0 && !isEnquirySaved) || (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated))}>
                            {({ onConfirm, onCancel }) => (
                              <React.Fragment>
                                <Dialog
                                  disableBackdropClick={true}
                                  open={true}
                                  fullWidth={true}
                                  maxWidth={'sm'}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogContent>
                                    <div className={classes.icon}>
                                      <WarningIcon className="delete-warning" fontSize="small" />
                                    </div>
                                    <div>
                                      <DialogContentText id="alert-dialog-description">
                                        <div className={`mt-3 ${classes.logoutText}`}>
                                          {
                                            (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                              ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                              : 'Your enquiry is not saved, please press Yes to Save your enquiry.'
                                          }
                                        </div>
                                      </DialogContentText>
                                    </div>
                                  </DialogContent>
                                  <DialogActions>
                                    <><Button
                                      className={`mx-4 `}
                                      onClick={async () => {
                                        saveUpdateEnquiry(false);
                                        onCancel();
                                      }}>Yes</Button>
                                      <Button
                                        className={`mr-4 ${classes.cancel}`}
                                        onClick={() => {
                                          // problem parts conflcit resolved, un commented onclick function in current change the below code is commented
                                          // if (isEnquirySaved && products.length !== actualProductLength) {
                                          // } else {
                                          //   let partsList: any = products.map((item: any) => {
                                          //     return item.productId.trim();
                                          //   });
                                          //   let searchProduct: any = [];
                                          //   products.map((item: any) => {
                                          //     searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
                                          //   })
                                          //   dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct));
                                          // }
                                          onConfirm();
                                        }}>No</Button></>
                                  </DialogActions>
                                </Dialog>
                              </React.Fragment>
                            )}
                          </NavigationPrompt>
                          {sessionStorage.setItem('Order-log-UnReadCount', getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount && getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount)}
                          {sessionStorage.setItem('Order-log-ReadCount', getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].readCount && getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].readCount)}
                          <div className={"mr-3"}>
                            {resetOrderLogCount === "false" && getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount && getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount !== 0 && getLatestOrderCount === false ?
                              <>
                                {getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount && getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount !== 0 ?
                                  <Tooltip title="Please do Check Availability & Price to check the updated logs." placement="top">
                                    <Badge badgeContent={getOrderLogCount(getOrderLogsDetails)} color="error">
                                      <Button
                                        className={`ml-1 font-size-11 ${enquiryAction === "view" ? "actionButtonForOrderLog" : (placeorderEnabled && isAvailabiityChecked && products.length > 0 && !orderQueued)
                                          ? "actionButtonForOrderLog"
                                          : "actionButtonForOrderLog actionButtomButtomsBlueDisabled"
                                          }`}
                                        // disabled={enquiryAction === "view" ? false : orderQueued || isDisableQuotation || isQuantityUpdated || isDeleted || procuredPartExist || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                        disabled={enquiryAction === "view" ? false : getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 ? false : isAvailabiityChecked && products.length > 0 ? false : true}
                                        variant="contained"
                                        onClick={async () => {
                                          checkOrderLogs();
                                          // let data: any = await dispatch(getOrderLogsLatestCount(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId));
                                          let data: any = await dispatch(getEnquiryDetails(customerTierType,
                                            enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId,
                                            (enquiryAction === "view" && orderType !== "Cancelled" && !quotation) ? true : false, quotation ? true : false, true
                                          ));
                                          await getOrderLogsData(data?.data);
                                          let rmpro: any = await getRemovedProducts(data?.data?.data);
                                          setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                          setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : data?.data?.data[0].removedProducts?.length)

                                          // setInvalidParts(data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                          // setRemovedProductsLength(data?.data?.data[0].removedProducts?.length)

                                          // if (data && data.message && data.message.status === "E") {
                                          //   setTimeout(() => {
                                          //     setOpenSnackbarType("error");
                                          //     setOpenErrorSnackbar(true);
                                          //     setSuccessMessage(data && data.message && data.message.message && data.message.message);
                                          //     handleopenCloseSnackbar(true);
                                          //   }, 2000)
                                          // }
                                        }}
                                      >
                                        Order Log
                                      </Button>
                                    </Badge>
                                  </Tooltip>
                                  :
                                  <Badge badgeContent={getOrderLogCount(getOrderLogsDetails)} color="error">
                                    <Button
                                      className={`ml-1 font-size-11 ${enquiryAction === "view" ? "actionButtonForOrderLog" : (placeorderEnabled && isAvailabiityChecked && products.length > 0 && !orderQueued)
                                        ? "actionButtonForOrderLog"
                                        : "actionButtonForOrderLog actionButtomButtomsBlueDisabled"
                                        }`}
                                      // disabled={enquiryAction === "view" ? false : orderQueued || isDisableQuotation || isQuantityUpdated || isDeleted || procuredPartExist || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                      disabled={enquiryAction === "view" ? false : getOrderLogsDetails && getOrderLogsDetails.data && getOrderLogsDetails.data.length > 0 && getOrderLogsDetails.data[0].unReadCount && getOrderLogsDetails.data[0].unReadCount !== 0 ? false : isAvailabiityChecked && products.length > 0 ? false : true}
                                      variant="contained"
                                      onClick={async () => {
                                        checkOrderLogs();
                                        let data: any = await dispatch(getEnquiryDetails(customerTierType,
                                          enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId,
                                          (enquiryAction === "view" && orderType !== "Cancelled" && !quotation) ? true : false, quotation ? true : false, true
                                        ));
                                        await getOrderLogsData(data?.data);

                                        let rmpro: any = await getRemovedProducts(data?.data?.data);
                                        setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                        setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : data?.data?.data[0].removedProducts?.length)

                                        // setInvalidParts(data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                        // setRemovedProductsLength(data?.data?.data[0].removedProducts?.length) // let data: any = await dispatch(getOrderLogsLatestCount(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId));
                                      }}
                                    >
                                      Order Log
                                    </Button>
                                  </Badge>
                                }
                              </>
                              :
                              <>
                                <Badge badgeContent={orderLogBadgeCount} color="error">
                                  <Button
                                    className={`ml-2 font-size-11 ${enquiryAction === "view" ? "actionButtonForOrderLog" : (placeorderEnabled && isAvailabiityChecked && products.length > 0)
                                      ? "actionButtonForOrderLog"
                                      : "actionButtonForOrderLog actionButtomButtomsBlueDisabled"
                                      }`}
                                    // disabled={enquiryAction === "view" ? false : orderQueued || isDisableQuotation || isQuantityUpdated || isDeleted || procuredPartExist || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                    disabled={enquiryAction === "view" ? false : isAvailabiityChecked && products.length > 0 ? false : true}
                                    variant="contained"
                                    onClick={async () => {
                                      checkOrderLogs();
                                      let data: any = await dispatch(getEnquiryDetails(customerTierType,
                                        enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId,
                                        (enquiryAction === "view" && orderType !== "Cancelled" && !quotation) ? true : false, quotation ? true : false, true
                                      ));
                                      await getOrderLogsData(data?.data);
                                      // setOrderLogBadgeCount(0);

                                      let rmpro: any = await getRemovedProducts(data?.data?.data);
                                      setInvalidParts(rmpro && rmpro.length > 0 ? rmpro : data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                      setRemovedProductsLength(rmpro && rmpro.length > 0 ? rmpro.length : data?.data?.data[0].removedProducts?.length)

                                      // setInvalidParts(data?.data?.data[0].removedProducts ? data?.data?.data[0].removedProducts : []);
                                      // setRemovedProductsLength(data?.data?.data[0].removedProducts?.length)  // let data: any = await dispatch(getOrderLogsLatestCount(enquiryData && Array.isArray(enquiryData) && enquiryData.length > 0 && enquiryData[0].B2bEnquiryId));
                                    }}
                                  >
                                    Order Log
                                  </Button>
                                </Badge>
                              </>
                            }
                          </div>
                          {showUpdateEnquiry && <Button
                            onClick={async () => {
                              saveUpdateEnquiry(false);
                            }}
                            className={`mr-2 font-size-11 ${products.length > 0 && !orderQueued && !isQueue
                              ? "actionButtomButtomsBlue"
                              : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                              }`}
                            variant="contained"
                            color="primary"
                            disabled={isQueue ? true : isEnquirySaved && (isQuantityUpdated || products.length !== actualProductLength || isAvailabiityChecked) ? true :
                              customerTierType && customerTierType === "Dealer" &&
                              (((enquiryAction === "update" || enquiryAction === "view" || enquiryAction !== "") && (accessData && accessData.data && accessData.data.isRequestPlacedForEnquiry === true) && !isSPRModifyData) ? true : false)
                              || checkAvailabilityButtonText !== CHECK_AVAILABILITY_BUTTON_TEXT.NORMAL &&
                              enquiryData &&
                              Array.isArray(enquiryData) &&
                              enquiryData.length > 0 &&
                              ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (quotation) || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || orderType === "Cancelled" || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation === true}
                          >
                            {isEnquirySaved
                              ? "Update Order Enquiry"
                              : "Save Order Enquiry"}
                          </Button>}
                          {showStockEnquiry &&
                            <>
                              <Button
                                className={`mr-2 font-size-11 ${products.length > 0 && !orderQueued
                                  ? "actionButtomButtomsBlue"
                                  : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                                  }`}
                                onClick={async () => {
                                  saveUpdateEnquiry(true);
                                  setIsDelted(false)
                                }}
                                color="primary"
                                variant="contained"
                                disabled={history.location.pathname == "/viewenquiry" ? true :
                                  // customerTierType && customerTierType === "Dealer" && enquiryAction !== "" && enquiryAction !== "" && requestStatus === "New Request" || requestStatus === "KAM Review" || requestStatus === "Approved" || requestStatus === "Cancelled" || requestStatus === "Timed Out" || enquiryAction === "view" &&
                                  enquiryData &&
                                  Array.isArray(enquiryData) &&
                                  enquiryData.length > 0 &&
                                  ((enquiryData[0].status && enquiryData[0].status.toLowerCase() === "enquiry cancelled") || (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "fully cancelled") ||
                                    (enquiryData[0].status && enquiryData[0].status.toLowerCase() === "cancel")) || isViewingFromOrderHistory || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation}
                              >
                                {checkAvailabilityButtonText}
                              </Button>
                              <EstimationPromtDialog
                                open={isEstimatedTotalIsLess}
                                text={`Estimated Total is lower than the minimum value required to place a Special Price Request. To avail this, please add an additional AED ${(parseFloat("35000") - parseFloat(getViewEstimatedTotal())).toFixed(2)} value of parts to the order-enquiry.`}
                                handleopenClose={() => {
                                  setIsEstimatedTotalIsLess(false)
                                }} />
                              {!isTpcDealer && showPlaceOrder &&
                                <Tooltip title={isAvailabiityChecked && products.length ? '' : `Please do 'Check Availability and Price' to place the order`} placement="top">
                                  <span>
                                    <Button
                                      onClick={() => {
                                        if (shipToPartyFeatureAccess) {
                                          if ((multipleAddressData == undefined || multipleAddressData == "") && (collectionAddressData == undefined || collectionAddressData == '')) {
                                            setOpenSnackbarType("error");
                                            setOpenErrorSnackbar(true);
                                            setSuccessMessage(
                                              `Please select shipping address to proceed.`
                                            );
                                            handleopenCloseSnackbar(true);
                                            return true;
                                          }
                                        }
                                        if (isAvailabiityChecked && sessionStorage.getItem('userType') === 'FSE' && customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer") && is24hrExist()) {
                                          if (deliveryBlockReason["2195"].deliveryBlockReason === '' || deliveryBlockReason["2195"].deliveryBlockReasonCode === '') {
                                            setOpenSnackbarType("error");
                                            setOpenErrorSnackbar(true);
                                            setSuccessMessage(`Please Select Delivery Block Reason`);
                                            handleopenCloseSnackbar(true);
                                            return
                                          }
                                        }
                                        // if (procuredPart && procuredPart.length > 0) {
                                        //   setHideZeroMessage(true);
                                        //   openZeroPricePopup(true);
                                        //   return true;
                                        // }
                                        let isAirOrder = false
                                        let productsTemp = JSON.parse(
                                          JSON.stringify(products)
                                        );
                                        productsTemp = productsTemp.filter(
                                          (product: any) => product.isValid && !product.isSuperseeded
                                        );
                                        productsTemp = productsTemp.map((item: any) => {
                                          if (item.airAvailabilityChecked && item.airAvailability > 0) {
                                            isAirOrder = true;
                                          }

                                        });
                                        if (!isAirOrder || orderQueued) {
                                          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                                            setSelectedDeliveryTypeError(false);
                                            validateDeliveryType()
                                          } else {
                                            if (sessionStorage.getItem('userType') === 'FSE' && !orderQueued && orderQueued !== false) {
                                              setShowPlaceOrderConfirmationPopup(true);
                                              // placeOrderHandler();
                                            } else {
                                              placeOrderHandler();
                                            }
                                          }
                                        } else if (!orderQueued) {
                                          if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                                            setSelectedDeliveryTypeError(false);
                                            validateDeliveryType()
                                          } else {
                                            setshowBackOrderConfirmation(true)
                                          }
                                        }
                                        // } else if (!orderQueued) {
                                        //   if (customerTierType && customerTierType === "Workshop") {
                                        //     setSelectedDeliveryTypeError(false);
                                        //     validateDeliveryType()
                                        //   } else {
                                        //     setshowBackOrderConfirmation(true)
                                        //   }
                                        // }
                                      }}
                                      className={`mr-2 font-size-11 ${placeorderEnabled && isAvailabiityChecked && products.length > 0
                                        ? "actionButtomButtomsBlue"
                                        : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                                        }`}
                                      disabled={orderQueued || (enquiryData && enquiryData.length > 0 && enquiryData[0].status && enquiryData[0].status.toLowerCase() === 'order placement queued') ? false : isQuantityUpdated || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                      variant="contained"
                                      color="primary"
                                    >
                                      {orderQueued || (enquiryData && enquiryData.length > 0 && enquiryData[0].status && enquiryData[0].status.toLowerCase() === 'order placement queued') ? "Order in progress" : "Place Order"}
                                    </Button>
                                  </span>
                                </Tooltip>
                              }
                              {isTpcDealer === true &&
                                <Tooltip title={isAvailabiityChecked && products.length ? '' : `Please do 'Check Availability and Price' to place the order`} placement="top">
                                  <span>
                                    <Button
                                      onClick={() => {
                                        ConfirmButtonUpdateAPI();
                                      }}
                                      className={`mr-2 font-size-11 ${placeorderEnabled && isAvailabiityChecked && products.length > 0
                                        ? "actionButtomButtomsBlue"
                                        : "actionButtomButtomsBlue actionButtomButtomsBlueDisabled"
                                        }`}
                                      disabled={orderQueued || (enquiryData && enquiryData.length > 0 && enquiryData[0].status && enquiryData[0].status.toLowerCase() === 'order placement queued') ? false : isQuantityUpdated || procuredPartExist || enquiryData && enquiryData[0] && enquiryData[0].isQuotation && enquiryData[0].isQuotation ? true : placeorderEnabled && isAvailabiityChecked && products.length ? false : true}
                                      variant="contained"
                                      color="primary"
                                    >
                                      {orderQueued || (enquiryData && enquiryData.length > 0 && enquiryData[0].status && enquiryData[0].status.toLowerCase() === 'order placement queued') ? "Order in progress" : "Confirm Order"}
                                    </Button>
                                  </span>
                                </Tooltip>
                              }
                            </>
                          }
                          <ConfirmDialogEnquiry
                            open={showConfirmDialogPopup}
                            ConfirmButtonUpdateAPI={ConfirmButtonUpdateAPI}
                            openRequestSnackbar={openSnackbar}
                            enquiryId={enquiryData &&
                              Array.isArray(enquiryData) &&
                              enquiryData.length > 0 &&
                              enquiryData[0].B2bEnquiryId
                              ? enquiryData[0].B2bEnquiryId
                              : ""}
                            handleopenCloseSnackbar={handleopenCloseSnackbar}
                            handleopenClose={(data: any, open: any) => {
                              setShowConfirmDialogPopup(data ? true : false)
                            }}
                          />
                        </div>
                      )}
                    </div>
                    {/* Request special price popup */}
                    <div className="col-lg-6 col-md-6 col-sm-12 p-3 pb-0 borderBottom d-flex justify-content-end">
                      {(
                        <>
                          <div>
                            <React.Fragment>
                              <Dialog
                                disableBackdropClick={true}
                                open={!orderQueued && openSpecialPrice}
                                fullWidth={true}
                                maxWidth={'sm'}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                onClose={handleCloseSpecialPrice}
                              >
                                <DialogContent>
                                  <div className={classes.icon}>
                                    <WarningIcon className="delete-warning" fontSize="small" />
                                  </div>
                                  <div>
                                    <DialogContentText id="alert-dialog-description">
                                      <div className={`mt-3 ${classes.logoutText}`}>
                                        {
                                          (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                            ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                            : 'Your enquiry is not saved, please press Yes to Save your enquiry.'
                                        }
                                      </div>
                                    </DialogContentText>
                                  </div>
                                </DialogContent>
                                <DialogActions>
                                  <><Button
                                    className={`mx-4 `}
                                    onClick={async () => {
                                      saveUpdateEnquiry(false);
                                      handleCloseSpecialPrice();
                                    }}>Yes</Button>
                                    <Button
                                      className={`mr-4 ${classes.cancel}`}
                                      onClick={() => {
                                        if (isEnquirySaved && products.length !== actualProductLength) {
                                        } else {
                                          let partsList: any = products.map((item: any) => {
                                            return item.productId.trim();
                                          });
                                          let searchProduct: any = [];
                                          products.map((item: any) => {
                                            searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
                                          })
                                          dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct));
                                        }
                                        handleCloseSpecialPrice();
                                      }}>No</Button></>
                                </DialogActions>
                              </Dialog>
                            </React.Fragment>
                          </div>
                          {showStockEnquiry &&
                            <>


                            </>
                          }
                        </>
                      )}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 p-3 pb-0 borderBottom d-flex ">
                      {(
                        <>
                          <NavigationPrompt when={!orderQueued && ((products.length > 0 && !isEnquirySaved) || (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated))}>
                            {({ onConfirm, onCancel }) => (
                              <React.Fragment>
                                <Dialog
                                  disableBackdropClick={true}
                                  open={true}
                                  fullWidth={true}
                                  maxWidth={'sm'}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                >
                                  <DialogContent>
                                    <div className={classes.icon}>
                                      <WarningIcon className="delete-warning" fontSize="small" />
                                    </div>
                                    <div>
                                      <DialogContentText id="alert-dialog-description">
                                        <div className={`mt-3 ${classes.logoutText}`}>
                                          {
                                            (isEnquirySaved && products.length !== actualProductLength) || (isEnquirySaved && isQuantityUpdated)
                                              ? 'Your enquiry is not updated, please press Yes to Update your enquiry.'
                                              : 'Your enquiry is not saved, please Save your enquiry.'
                                          }
                                        </div>
                                      </DialogContentText>
                                    </div>
                                  </DialogContent>
                                  <DialogActions>
                                    <><Button
                                      className={`mx-4 `}
                                      onClick={async () => {
                                        saveUpdateEnquiry(false);
                                        onCancel();
                                      }}>Yes</Button>
                                      <Button
                                        className={`mr-4 ${classes.cancel}`}
                                        onClick={() => {
                                          // problem parts conflcit resolved, un commented onclick function in current change the below code is commented
                                          // if (isEnquirySaved && products.length !== actualProductLength) {
                                          // } else {
                                          //   let partsList: any = products.map((item: any) => {
                                          //     return item.productId.trim();
                                          //   });
                                          //   let searchProduct: any = [];
                                          //   products.map((item: any) => {
                                          //     searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
                                          //   })
                                          //   dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, sapCustomerId, b2bUserId, searchProduct));
                                          // }
                                          onConfirm();
                                        }}>No</Button></>
                                  </DialogActions>
                                </Dialog>
                              </React.Fragment>
                            )}
                          </NavigationPrompt>
                        </>
                      )}
                    </div>
                  </div>
                  <>
                  </>
                  {!isShowSPR() || currentTier === "" ?
                    "" :
                    <>
                      {
                        customerTierType && customerTierType.toLowerCase() === "dealer" && enquiryAction !== "" ?
                          <div className="row col-12 tableBottom">
                            {accessData && accessData.data && accessData.data.ableToPlaceSpecialRequest === false && (
                              <>
                                {/* {showReorderEnquiry === false ? */}
                                <div className="col-12 row">
                                  <span className="row col-12 mt-2 bottomFieldNameTable pr-4">Request Special Price Access Denied Reason : {" "} <p className="row px-4 denied-reason">
                                    {accessData && accessData.data && accessData.data.accessDeniedReason ? accessData.data.accessDeniedReason : ""}
                                  </p></span>
                                </div>
                                {/* : ""} */}
                              </>
                            )}
                          </div>
                          : ""}
                    </>
                  }
                </div>
                {/* : ''} */}
              </div>
              <div className="legendItemCreateEnquiry legendItemspr m-2 mt-3">
                <span className="dot"></span>
                <span className="legendItemLabel mr-4 ml-2">Discounted Price</span>
                {customerTierType && customerTierType.toLowerCase() === "dealer" && isShowSPR() && currentTier !== "" && (
                  <>
                    <span className="dot-blue"></span>
                    <span className="legendItemLabel mr-4 ml-2">Special Price</span>
                    <span className="dot-red"></span>
                    <span className="legendItemLabel mr-4 ml-2">Special price Not Approved/Not Offered</span>
                  </>
                )}
                <Tooltip title="Material cannot be procured from external supplier" placement="top">
                  <span>
                    <span className="dot-procurement"></span>
                    <span className="legendItemLabel mr-4 ml-2">Material blocked for Procurement</span>
                  </span>
                </Tooltip>
              </div>
              <div className="tandcText m-2">
                <p className="tandcHeading">Terms & Conditions</p>
                <p className="tandcBody">
                  The parties agree that that in the event of Value Added Tax (VAT) being levied or introduced by the government authorities in the jurisdiction where the [Vendor] is incorporated and/or tax resident the [Vendor] reserves the right to charge VAT at the prevailing VAT rate in respect of any supply made under this Agreement. The [Purchaser] shall pay the amount of any such VAT as an addition to the invoice value. The [Vendor] shall provide to the [Purchaser] the documentation required by the applicable VAT legislation to permit the [Purchaser] to claim an input VAT deduction on its VAT return.
                </p>
                <p className={`tandcBody mt-2 ${classes.textRight}`}>
                  يوافق الطرفان على أنه في حال فرض أو تطبيق ضريبة القيمة المضافة (VAT) من قِبَل السلطات الحكومية في الولاية القضائية التي يكون [البائع] مؤسّساً / مُقيماً دافعاً للضرائب بها، يحتفظ [البائع] بالحق في فرض ضريبة القيمة المضافة بمعدّلها السائد فيما يتعلق بأي عملية توريد بموجب هذه الاتفاقية. ويدفع [المشتري] مبلغ ضريبة القيمة المضافة تلك كإضافة إلى قيمة الفاتورة. ويقوم [البائع] بتزويد [المشتري] بالمستندات المطلوبة بموجب تشريع ضريبة القيمة المضافة المعمول به للسماح لـ [المشتري] بالمطالبة بخصم ضريبة القيمة المضافة المُدخلة في إقراره الضريبي
                </p>
              </div>
            </div >
          </div >
          <div className="col-12">
          </div>
          {openShippingAddDialog && <ShippingAddSelectionDialog addressData={newAddressData} b2bUserId={getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId} openShippingAddDialog={openShippingAddDialog} openCloseShippingAddSelectionDialog={openCloseShippingAddSelectionDialog} />}
          {openChooseCustomerDialog && <ChooseCustomerDialog openChooseCustomerDialog={openChooseCustomerDialog} openCloseChooseCustomer={openCloseChooseCustomer} isOrderEnquiryCreated={isOrderEnquiryCreated} onClickOfChangeButton={onClickOfChangeButton} isQuantityUpdated={isQuantityUpdated} isEnquirySaved={isEnquirySaved} />}
          {
            openOrderLogPopup &&
            <OrderLogDialog openOrderLogPopup={openOrderLogPopup}
              openCloseOrderLogPopup={openCloseOrderLogPopup} isOrderEnquiryCreated={isOrderEnquiryCreated}
              onClickOfChangeButton={onClickOfChangeButton} isQuantityUpdated={isQuantityUpdated}
              isEnquirySaved={isEnquirySaved} getLatestOrderCount={getLatestOrderCount} setGetLatestOrderCount={setGetLatestOrderCount} sendOrderLogsEmail={sendOrderLogsEmail}
            />
          }
          <OrderSummaryDialog
            open={openOrderSummary && orderData}
            orderData={orderData}
            orderTotal={orderTotal}
            getEstimatedTotal={getEstimatedTotal}
            handleopenClose={(data: any) => {
              history.push("/orderhistory");
              handleopenCloseOrderSummary(false);
            }}
          />
          <RequestQuotationDialog
            open={showRequestQuotationDialog}
            isBackOrder={isBackOrder}
            openRequestSnackbar={openSnackbar}
            requestQuotation={requestQuotation}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            setOpenSnackbarType={setOpenSnackbarType}
            setOpenErrorSnackbar={setOpenErrorSnackbar}
            openErrorSnackbar={openErrorSnackbar}
            masterIDData={masterIDData}
            setSuccessMessage={setSuccessMessage}
            statusSuccessChecked={statusSuccessChecked}
            requestQuotationStatusChecked={requestQuotationStatusChecked}
            handleopenClose={(data: any, open: any) => {
              setShowRequestQuotationDialog(data ? true : false)
            }}
            loadQueueQuotation={loadQueueQuotation}
          />
          <ExpiredDialog
            open={showApproveDialog}
            setOpenSnackbar={setOpenSnackbar}
            setSuccessMessage={setSuccessMessage}
            products={products}
            setCopyCreateEnquiry={setCopyCreateEnquiry}
            checkSessionStorage={checkSessionStorage}
            setViewigFromOrderHistory={setViewigFromOrderHistory}
            resetData={resetData}
            userMasterId={userMasterId}
            enquiryData={enquiryData}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenClose={(data: any) => {
              setshowApproveDialog(data ? true : false)
            }}
          />
          <ExpiredDialog24Hrs
            open={showExpired24Hrs}
            userMasterId={userMasterId}
            products={products}
            enquiryData={enquiryData}
            checkSessionStorage={checkSessionStorage}
            resetData={resetData}
            setOpenSnackbar={setOpenSnackbar}
            setSuccessMessage={setSuccessMessage}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenClose={(data: any) => {
              setShowExpired24Hrs(data ? true : false)
            }}
          />
          <DeleteDialog
            open={confirmDialog}
            text="Please select the Delivery Type."
            handleopenClose={(data: any) => {
              if (data === 'delete')
                if (!data) {
                  handleopenCloseConfirmPopup(false);
                }
            }} />

          <Dialog className="pb-0"
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
          >
            <MakePaymentDialog setSuccessMessage={setSuccessMessage}
              setOpenErrorSnackbar={setOpenErrorSnackbar}
              openErrorSnackbar={openErrorSnackbar}
              handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog}
            />
          </Dialog> -
          <React.Fragment>
            <Dialog
              open={showBackOrderConfirmation}
              fullWidth={true}
              onClose={() => {
                setshowBackOrderConfirmation(false)
              }}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >

              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className={`mt-3 ${classes.logoutText}`}>
                      Your order-enquiry basket has <span className="text-danger">BACK ORDER</span> items. Are you sure you want to proceed with order placement?”
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={`mx-4 ${classes.yes}`}
                  onClick={async () => {
                    setLoading(true);
                    if (customerTierType && (customerTierType.toLowerCase() === "workshop" || customerTierType.toLowerCase() === "tpcdealer")) {
                      setSelectedDeliveryTypeError(false);
                      validateDeliveryType()
                    } else {
                      if (sessionStorage.getItem('userType') === 'FSE' && !orderQueued) {
                        setshowBackOrderConfirmation(false);
                        setShowPlaceOrderConfirmationPopup(true);
                        setLoading(false);
                      } else {
                        placeOrderHandler();
                      }
                    }
                    setshowBackOrderConfirmation(false)
                  }}>Yes</Button>
                <Button
                  className={`mr-4 ${classes.cancel}`}
                  onClick={() => {
                    setshowBackOrderConfirmation(false)
                  }}>No</Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
          <React.Fragment>
            <Dialog
              open={showPlaceOrderConfirmationPopup}
              fullWidth={true}
              onClose={() => {
                setShowPlaceOrderConfirmationPopup(false)
              }}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <div className={classes.iconForCustomer}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className={`mt-3 mb-3 ${classes.logoutText}`}>
                      Order is getting placed for <span className="tandcHeading-font-type">({getChoosenCustomerName ? getChoosenCustomerName : "-"}) {" "}</span>
                      and for the shipping address <span className="tandcHeading-font-type">({getSelectedAddress()}) </span>
                      <span className={`${classes.logoutText}`}>.Would you like to place an order for the above customer?</span>
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  className={`mx-4 ${classes.yes}`}
                  onClick={async () => {
                    setLoading(true);
                    setShowPlaceOrderConfirmationPopup(false);
                    placeOrderHandler();
                    setLoading(false);
                  }}>Yes</Button>
                <Button
                  className={`mr-4 ${classes.cancel}`}
                  onClick={() => {
                    setShowPlaceOrderConfirmationPopup(false);
                    setshowBackOrderConfirmation(false);
                  }}>No</Button>
              </DialogActions>
            </Dialog>
          </React.Fragment>
          <React.Fragment>
            <Dialog
              open={isZeroPricePopup && ((zeroProducts && zeroProducts.length > 0 && !hideZeroMessage) || (materialNotExtendedPart && materialNotExtendedPart.length > 0) || (procuredPart && procuredPart.length > 0))}
              fullWidth={true}
              onClose={() => {
                openZeroPricePopup(false)
              }}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                {/* <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div> */}
                <div id="alert-dialog-description">
                  {/* <DialogContentText id="alert-dialog-description"> */}
                  {zeroProducts && zeroProducts.length > 0 && !hideZeroMessage && (
                    <div className={`mt-3 ${classes.enquiryPop}`}>
                      <div className={classes.iconMaterial}>
                        <WarningIcon className="delete-warning" fontSize="small" />
                      </div>
                      <div className={`${classes.logoutText}`}>
                        It seems there are Parts with Zero price that will be auto-deleted when placing the order.<br />
                        You will receive an email with the list of Part(s) with Zero price.
                      </div>
                    </div>
                  )}
                  {materialNotExtendedPart && materialNotExtendedPart.length > 0 && (
                    <div className={`mt-3 ${classes.enquiryPop}`}>
                      <div className={classes.iconMaterial}>
                        <WarningIcon className="delete-warning" fontSize="small" />
                      </div>
                      <div className={`${classes.logoutText}`}>
                        <span className="text-red">"Material Not Extended Parts" identified in the Order Enquiry are as below:</span><br />
                        <div className="pt-2">
                          {materialNotExtendedPart && Array.isArray(materialNotExtendedPart) && materialNotExtendedPart.length > 0 && materialNotExtendedPart.map((p: any, i: any) => (
                            <span className="file-close-color">
                              {p}{materialNotExtendedPart.length !== i + 1 ? ', ' : ''}{' '}
                            </span>
                          )
                          )}
                          <CopyToClipboard text={String(materialNotExtendedPart)}
                            onCopy={() => setCopy(true)}>
                            <Tooltip title={isCopy ? 'Copied' : 'Click to copy'} placement="top">
                              <img src="./contentCopy.svg" className="ml-3 w-19px" />
                            </Tooltip>
                          </CopyToClipboard>
                          <span className="mt-1 ml-1">(Copy Parts to clipboard).</span>
                          <IconButton
                            className="ml-2 file-close-color" size="small" onClick={() => exportToExcelMaterialData()}>
                            <GetAppIcon fontSize="inherit" />
                            <span className="font-size-14 file-close-color ml-1">
                              Export To Excel
                            </span>
                          </IconButton>
                        </div>
                        <div className="pt-2">
                          You will not be able to "Verify Enquiry" until these parts are removed. Please do 'Check Availability and Price' again.<br />
                        </div>
                      </div>
                    </div>
                  )}
                  {procuredPart && procuredPart.length > 0 && (
                    <div className={`mt-3 ${classes.enquiryPop}`} >
                      <div className={classes.iconMaterial}>
                        <WarningIcon className="delete-warning" fontSize="small" />
                      </div>
                      <div className={`${classes.logoutText}`}>
                        <span className="text-red">"Parts blocked for Procurement" identified in the Order Enquiry are as below:</span><br />
                        <div className="pt-2">
                          {procuredPart && Array.isArray(procuredPart) && procuredPart.length > 0 && procuredPart.map((p: any, i: any) => (
                            <span className="file-close-color">
                              {p}{procuredPart.length !== i + 1 ? ', ' : ''}{' '}
                            </span>
                          )
                          )}
                          <CopyToClipboard text={String(procuredPart)}
                            onCopy={() => setCopy(true)}>
                            <Tooltip title={isCopy ? 'Copied' : 'Click to copy'} placement="top">
                              <img src="./contentCopy.svg" className="ml-3 w-19px" />
                            </Tooltip>
                          </CopyToClipboard>
                          <span className="mt-1 ml-1">(Copy Parts to clipboard).</span>

                          <IconButton
                            className="mr-3 file-close-color" size="small" onClick={() => exportToExcelData()}>
                            <GetAppIcon fontSize="inherit" />
                            <span className="font-size-14 file-close-color ml-1">
                              Export To Excel
                            </span>
                          </IconButton>
                        </div>

                        {/* <div className="pt-2">
                            You will not be able to "Place Order" until those parts are removed. After removing "Procurement Blocked" parts you will need to Check Availability and Price again.<br />
                            Do you want to remove the Procurement Blocked parts?
                          </div> */}
                      </div>
                    </div>
                  )}
                  {/* </DialogContentText> */}
                </div>
              </DialogContent>
              <DialogActions>
                {(procuredPart && procuredPart.length > 0) || (materialNotExtendedPart && materialNotExtendedPart.length > 0) ? (
                  <>
                    <Button
                      className={`mx-4 ${classes.yes}`}
                      onClick={() => {
                        openZeroPricePopup(false)
                        setCopy(false);
                        setHideZeroMessage(false);
                        if (procuredPart && procuredPart.length > 0) {
                          openZeroPricePopup(false);
                          setplaceorderEnabled(false);
                          setisAvailabiityChecked(true);
                          setorderQueued(false);
                          setisAvailabiityCheckedAtTime(new Date());
                        }
                        if (materialNotExtendedPart && materialNotExtendedPart.length > 0) {
                          openZeroPricePopup(false);
                          setplaceorderEnabled(false);
                          setisAvailabiityChecked(false);
                          setorderQueued(false);
                          setMaterialNotExtendedPart([]);
                        }
                      }}>Close</Button>
                  </>
                ) :
                  <>
                    {zeroProducts && zeroProducts.length > 0 && !hideZeroMessage && (
                      <Button
                        className={`mx-4 ${classes.yes}`}
                        onClick={() => {
                          openZeroPricePopup(false)
                          setisAvailabiityChecked(true);
                          setorderQueued(false);
                          setisAvailabiityCheckedAtTime(new Date());
                        }}>Ok</Button>
                    )}
                  </>
                }
              </DialogActions>
            </Dialog>
          </React.Fragment>
        </> : <>
          {
            <OrderSummary
              open={openOrderSummary && orderData}
              orderData={orderData}
              orderTotal={orderTotal}
              getEstimatedTotal={getEstimatedTotal}
              handleopenClose={(data: any) => {
                history.push("/orderhistory");
                handleopenCloseOrderSummary(false);
              }}
            />}</>
      }
    </Fragment >
  );
};

export default Dashboard;
