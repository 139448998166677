/* eslint-disable */
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import "./Dashboard.css";
import moment from 'moment-timezone';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import CancelIcon from '@material-ui/icons/Cancel';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';
import { Link, useHistory } from "react-router-dom";
import SpecialOffers from "../SpecialOffer/SpecialOffers";
import Announcements from "../Announcements/Announcements";
// import { userLogon } from "../../store/actions/logon.actions";
import { getEnquiriesList, getFSEEnquiriesList, setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import ImportDialog from './../CreateEnquiry/ImportExcelDialog'
import FullscreenLoader from './../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar'
import { getFavoriteDetails, getFavoritesList } from "../../store/actions/favorites.actions";
import { Dialog } from "@material-ui/core";
import CreateFavorite from "../favorites/createFavorite";
import ViewFavorite from "../favorites/viewFavorite";
import { getIP, getNewBrand, getUniqueTimestamp, getNewlyAddedBrand } from "../../common/components/util";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import CancelDialog from '../../common/components/cancelDialog';
import { cancelEnquiry, getUniqueEnquirySessionId, setEnquiryId } from "../../store/actions/createUpdateEnquiry.actions";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import NotificationIcon from "../../common/components/NotificationIcon";
import Loading from '../../common/components/loading';
import _, { get } from "lodash";
import { FULLY_CANCELLEED } from '../../constants/orderEnquiryConstants';
import { getSellingPrice } from "../../helpers/commonmethod";
import { getViewRequestStatusAPI } from "../../store/actions/requestSpecialPrice.actions";
import ExpiredDialog24Hrs from "../CreateEnquiry/ExpiredDialog24Hrs";
import { requestSpecialPriceService } from "../../services/requestSpecialPrice.service";

const useStyles = makeStyles((theme) => ({

  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: 12,
    fontFamily: 'Nunito',
    padding: 10,
    minWidth: 70
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 70,
    padding: 10
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 111,
    padding: 10
  },
  actionButton: {
    color: '#1A9FE0',
  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    minWidth: 130,
    padding: 10
  },
}));

const Dashboard: React.FC = () => {
  const history = useHistory();
  let inputRefs: any = [];
  const [partNumber, setpartNumber] = React.useState('');
  const [quantity, setquantity] = React.useState<any>('');
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { enquiryList } = useSelector((state: any) => state.orderEnquiry);
  const [selectedMasterId, setSelectedMasterId] = useState<any>(null);
  const [enqCusId, setEnqCusId] = useState<any>(null);
  const { favoritesList, updateFavorite }: any = useSelector((state: any) => state.favorites);
  const [openEditFavorite, setOpenEditFavorite] = React.useState(false);
  const [openViewFavorite, setOpenViewFavorite] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const logonData: any = useSelector((state: any) => state.logon.logonData);
  const [searchText, setsearchText] = React.useState<any>('');
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  const { addNotification }: any = useSelector((state: any) => state.notifications);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [masterID, setMasterID] = React.useState<any>();
  const [enquiryMaterdata, setEnquiryMaterdata] = React.useState<any>();
  const currentTier = sessionStorage.getItem('currentTier');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const iPExist = sessionStorage.getItem('clientIp');
  const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;

  const [open, setOpen] = useState(-1);
  let favoritesPage = 2;
  let rowsPerPage = 5;
  let page = 0;

  const isShowSPR = () => {
    if ((currentTier && currentTier.toLowerCase() === 'gold') || (currentTier && currentTier.toLowerCase() === 'platinum') || (currentTier && currentTier.toLowerCase() === 'elite')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('customerId') !== null && sessionStorage.getItem('customerId') !== undefined && !enquiryList) {
      if (sessionStorage.getItem('userType') === 'FSE') {
        dispatch(getFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')] }))
      } else {
        dispatch(getEnquiriesList(customerTierType, {}));
      }
      dispatch(setEnquiryId(null));
      if (iPExist) {
      } else {
        getIP();
      }
      // dispatch(getTermsAndConditions());
    }
  }, [dispatch, profileDetails]);

  useEffect(() => {
    if (sessionStorage.getItem('webApplicationLoginId') !== null &&
      sessionStorage.getItem('webApplicationLoginId') !== undefined &&
      sessionStorage.getItem('customerId') !== null && sessionStorage.getItem('customerId') !== undefined && !favoritesList)
      dispatch(getFavoritesList(null, null));
  }, [dispatch]);

  const searchFavorites = () => {
    if ((searchText && searchText !== '') || (favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.length === 0)) {
      dispatch(getFavoritesList(null, searchText));
    }
  }

  useEffect(() => {
    if (updateFavorite && updateFavorite.data) {
      handleClose();
      setSuccessMessage('Favorite updated successfully.');
      handleopenCloseSnackbar(true);
      handleopenCloseViewFavorite(false);
      dispatch(getFavoritesList(null, null));
    }
  }, [updateFavorite, dispatch]);

  const SubEnquiries = (props: { subEnq: any, enquiry: any }) => {
    const { subEnq } = props;
    return (
      <TableRow>
        <TableCell className={classes.subTableBodyContent}>
          <span className="mx-4 pl-4"></span>
          {(customerTierType && customerTierType.toLowerCase() !== 'workshop' && customerTierType.toLowerCase() !== 'tpcdealer') ? getNewlyAddedBrand(subEnq.make) : getNewBrand(subEnq.make)}
        </TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.enquiryReferenceNumber}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.status}</TableCell>
        <TableCell className={classes.subTableBodyContent}>{subEnq.estimatedTotal && typeof subEnq.estimatedTotal === 'string' &&
          subEnq.estimatedTotal.toLowerCase() !== "nan" ? parseFloat(getSellingPrice(subEnq.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
      </TableRow>
    )
  }

  function EnquiryList(props: { enquiry: any, setSelectedMasterId: any, index: any, setEnqCusId?: any }) {
    const { enquiry, setSelectedMasterId, index, setEnqCusId } = props;

    const showCancel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CANCEL_ENQUIRY);
    const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
    const showCancelButton = sessionStorage.getItem('userType') === 'FSE' ? true : enquiry.status && enquiry.status.toLowerCase() !== FULLY_CANCELLEED && enquiry.status.toLowerCase() !== "cancel" && showCancel && showCreateChangeEnquiry
    const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
    const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");

    React.useEffect(() => {
      let id: any
      enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length > 0 && (
        <>
          {enquiryList.data.data.map((enquiry: any, index: any) => {
            return (
              id = enquiry.masterId
            );
          })}
        </>
      )
      setMasterID(id)
    }, [enquiryList]);

    const requestPriceAccess = async () => {
      if (customerTierType && customerTierType.toLowerCase() === "dealer") {
        let data: any = await requestSpecialPriceService.requestSpecialPriceAccessAPI(
          {
            "sapCustomerId": sapCustomerId,
            "enquiryId": enquiry && enquiry.masterId ? enquiry.masterId : masterID
          }
        )
      }
    }

    return (
      <Fragment>
        <Snackbar
          open={openSnackbar}
          message={successMessage}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} />
        <TableRow hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer">
          <TableCell className="bodyContent">
            <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
              {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
            </IconButton>
          </TableCell>
          <TableCell>
            {
              enquiry.isSpecialPriceRequested ? <div className="legendItemCreateEnquiry">
                <span className="specialprice"></span>
              </div> : null
            }

          </TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterId}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.masterReferenceNumber}</TableCell>
          <TableCell align="left" className={classes.bodyContent}>
            {enquiry.make && Array.isArray(enquiry.make) && customerTierType && customerTierType.toLowerCase() !== 'workshop' && customerTierType.toLowerCase() !== 'tpcdealer' ? enquiry.make.map((brand: any) => brand === "Tradepoint" ? "ALAC" : brand).join(', ') : enquiry.make.toString()}
          </TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.estimatedTotal && (typeof enquiry.estimatedTotal === 'string' &&
            enquiry.estimatedTotal.toLowerCase() !== "nan") ?
            parseFloat(getSellingPrice(enquiry.estimatedTotal, isSellingPriceData, markupPercentage)).toFixed(2) : (parseFloat(getSellingPrice(0, isSellingPriceData, markupPercentage)).toFixed(2))}</TableCell>
          <TableCell className={classes.bodyContent}>{moment(enquiry.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.createdBy}</TableCell>
          <TableCell className={classes.bodyContent}>{enquiry.status}</TableCell>
          <TableCell className={classes.bodyContent}>
            {showViewEnquiry &&
              <Tooltip title="View Order Enquiry" placement="top">
                <IconButton
                  disabled={blockIntent === true ? true : false}
                  className={classes.actionButton} size="small" onClick={async () => {
                    // requestPriceAccess();
                    sessionStorage.setItem("partsData", "")
                    setEnquiryMaterdata(enquiry)
                    sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                    let data: any;
                    if (customerTierType && customerTierType.toLowerCase() === "dealer") {
                      data = dispatch(getViewRequestStatusAPI(enquiry.masterId))
                    }
                    if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].expiredAfterApproval === true && data && data.data && data.data[0] && data.data[0].status === "Approved") {
                      setshowApproveDialog(true);
                    } else if (data && data.data && data.data.length > 0 && data.data[0] && data.data[0].expiredAfterApproval === false) {
                      if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                        sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                        // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                        sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                          mode: 'view',
                          masterId: enquiry.masterId,
                          orderType: "Cancelled"
                        }))
                        sessionStorage.setItem('isCopyandCreate', "");
                        history.push('/viewenquiry');
                      }
                      else {
                        sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                        // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                        sessionStorage.setItem('isCopyandCreate', "");
                        sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                          // mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                          mode: 'update',
                          masterId: enquiry.masterId
                        }))
                        history.push('/updateenquiry');
                      }
                    } else {
                      if (sessionStorage.getItem('userType') === 'FSE') {
                        if (enquiry.userRole === 'FSE' && enquiry.userId === sessionStorage.getItem('webApplicationLoginId')) {
                          if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                            sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: 'view',
                              masterId: enquiry.masterId,
                              orderType: "Cancelled"
                            }))
                            sessionStorage.setItem('isCopyandCreate', "");
                            history.push('/viewenquiry');
                          }
                          else {
                            // requestPriceAccess();
                            sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                            // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                            sessionStorage.setItem('isCopyandCreate', "");
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                              masterId: enquiry.masterId
                            }))
                            history.push('/updateenquiry');
                          }
                        } else {
                          sessionStorage.setItem('isCopyandCreate', "");
                          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                            mode: 'view',
                            masterId: enquiry.masterId,
                          }))
                          history.push('/viewenquiry');
                        }
                      } else {
                        if ((enquiry.status && enquiry.status.toLowerCase() === 'failed') || (enquiry && enquiry.userRole === 'FSE') || (enquiry.status && enquiry.status.toLowerCase() === "enquiry cancelled") || (enquiry.status && enquiry.status.toLowerCase() === "fully cancelled") || enquiry.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                          sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                          // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                          sessionStorage.setItem('isCopyandCreate', "");
                          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                            mode: 'view',
                            masterId: enquiry.masterId,
                            orderType: "Cancelled"
                          }))
                          history.push('/viewenquiry');
                        }
                        else {
                          sessionStorage.setItem("current-enquiry-no", enquiry.masterId)
                          // dispatch(getViewRequestStatusAPI(enquiry.masterId))
                          sessionStorage.setItem('isCopyandCreate', "");
                          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                            // mode: enquiry.status && enquiry.status.toLowerCase() !== 'failed' && enquiry.status.toLowerCase() !== "enquiry cancelled" && enquiry.status.toLowerCase() !== "cancel" && enquiry.status && enquiry.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                            mode: 'update',
                            masterId: enquiry.masterId
                          }))
                          history.push('/updateenquiry');
                        }
                      }
                    }
                  }}>
                  <OpenInNewOutlinedIcon fontSize="inherit"
                  />
                </IconButton>
              </Tooltip>
            }
            {sessionStorage.getItem('userType') === 'FSE' && enquiry.userRole === 'FSE' && (
              <>
                {showCancelButton && showViewEnquiry &&
                  <Tooltip title="Cancel Order Enquiry" placement="top">
                    <IconButton
                      disabled={blockIntent === true ? true : false}
                      className={classes.actionButton} size="small"
                      onClick={() => {
                        setSelectedMasterId(enquiry.masterId);
                        setEnqCusId(enquiry.customerId);
                        handleopenCloseCancelDialog(true)
                      }}>
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                }
              </>
            )}
            {sessionStorage.getItem('userType') !== 'FSE' && enquiry.userRole !== 'FSE' && (
              <>
                {showCancelButton && showViewEnquiry &&
                  <Tooltip title="Cancel Order Enquiry" placement="top">
                    <IconButton className={classes.actionButton} size="small"
                      disabled={blockIntent === true ? true : false}
                      onClick={() => {
                        setSelectedMasterId(enquiry.masterId)
                        setEnqCusId(enquiry.customerId);
                        handleopenCloseCancelDialog(true)
                      }}>
                      <CancelIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                }
              </>
            )}


          </TableCell>
        </TableRow>
        <TableRow className={classes.collapseBg} hover role="checkbox" tabIndex={-1}>
          <TableCell className="p-0 w-100" colSpan={12}>
            <Collapse in={open > 0 && open === index + 1 ? true : false}>
              <Box>
                <Table aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.subTableHead} pr-0`}>
                        <span className="mx-4 pl-4"></span>
                        Brand</TableCell>
                      <TableCell className={`${classes.subTableHead}`}>Reference No.</TableCell>
                      <TableCell className={classes.subTableHead}>Status</TableCell>
                      <TableCell className={classes.subTableHead}>Estimated Total (incl. of Vat)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {enquiry.subEnquiries && enquiry.subEnquiries.map((subEnq: any) => {
                      return (
                        <SubEnquiries key={subEnq.enquiryNumber} subEnq={subEnq} enquiry={enquiry} />
                      );
                    }
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClickOpen = () => {
    setOpenEditFavorite(true);
  };

  const handleClose = () => {
    setOpenEditFavorite(false);
  };

  const handleopenCloseCancelDialog = (open?: any) => {
    setOpenCancelDialog(open ? true : false);
  };

  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");

  useEffect(() => {
    sessionStorage.setItem('webApplicationLoginId', webApplicationLoginId);;
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.salesOrganization) {
      let brands = profileDetails.data.data.basicInfo.salesOrganization.map((salesOrg: any) => {
        return salesOrg.salesOrganization;
      })
      sessionStorage.setItem('brands', brands);
    }
    if (!logonData && webApplicationLoginId !== "" && webApplicationLoginId !== null) {
      // dispatch(userLogon('Dealer'));
    }
  }, [profileDetails, logonData, webApplicationLoginId, dispatch]);

  const handleopenCloseViewFavorite = (open?: any) => {
    setOpenViewFavorite(open ? true : false);
  };

  const showCreateChangeEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const showImportExcel = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);
  const showFavorites = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_FAVORITES);
  const ENQ_LIST = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.ENQ_LIST);

  return (
    <Fragment>
      {(enquiryList && enquiryList.loading && !logonData) || (addNotification && addNotification.loading) && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Dashboard
        </h2>
        {blockIntent === true ?
          <div className="ml-auto">
            <Button
              disabled={blockIntent === true ? true : false}
              className="ml-auto">
              <AnnouncementIcon />
            </Button>
            <NotificationIcon />
          </div>
          :
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        }
      </div>
      <div className="row mt-4">
        <div className="col-12">
          {showCreateChangeEnquiry &&
            <div className="cardCommon p-3">

              <div className="d-flex align-items-center">
                <h5 className="page-sub-title mb-2">Create Order Enquiry</h5>
                {showImportExcel &&
                  <Button
                    disabled={blockIntent === true ? true : false}
                    className="ml-auto">
                    <ImportDialog
                      commonInsertionLogic={(data: any) => {
                        if (data && Array.isArray(data) && data.length > 0) {
                          data = data.map((item: any) => {
                            item.productId = item.productId ? item.productId : item.partnumber
                            return item
                          });
                          sessionStorage.setItem('partsData', JSON.stringify(data));
                          dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                          sessionStorage.setItem('isImportFromOES', 'false');
                             history.push('/createenquiry');
                          dispatch(setOrderEnquiryDetails('create'));
                        }
                      }} />
                  </Button>
                }
              </div>

              <TextField value={partNumber}
                autoFocus
                disabled={blockIntent === true ? true : false}
                inputRef={ref => inputRefs.push(ref)}
                onKeyDown={(e: any) => {
                  const event = e;
                  if (e.keyCode == 13) {
                    if (partNumber && partNumber.trim()) {
                      inputRefs[1].focus();
                      event.preventDefault();
                    }
                  }
                }}
                onChange={(e) => { setpartNumber(e.target.value) }}
                size="small" className="col-sm-12 col-md-6 col-ml-7 col-xl-8 pr-3 commonRoundedInputs common-input-font-size" label="Search By Part Number" variant="outlined" />

              <TextField value={quantity}
                disabled={blockIntent === true ? true : false}
                inputRef={ref => inputRefs.push(ref)}
                onChange={(e) => {
                  let value: any = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
                    parseInt(e.target.value) >= 1
                    ? parseInt(e.target.value)
                    : ""
                  setquantity(value)
                }}
                inputProps={{ maxLength: 5 }}
                onKeyDown={(e: any) => {
                  // const event = e;
                  if (e.keyCode == 13) {
                    if (partNumber) {
                      sessionStorage.setItem('partsData', JSON.stringify([{
                        productId: partNumber ? partNumber.trim() : '',
                        quantity: quantity && parseInt(quantity) ? parseInt(quantity) : 1
                      }]))
                    }
                    dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                    sessionStorage.setItem('isImportFromOES', 'false');
                    history.push('/createenquiry'); dispatch(setOrderEnquiryDetails('create'));
                    // inputRefs[1].focus();
                    // event.preventDefault();
                    // if (productId && productId.trim()) {
                    //   commonInsertionLogic([
                    //     {
                    //       productId: productId.trim(),
                    //       quantity:
                    //         quantity &&
                    //           parseInt(quantity) &&
                    //           parseInt(quantity) >= 1
                    //           ? parseInt(quantity)
                    //           : 1,
                    //     },
                    //   ]);
                    // }
                  }
                }}
                size="small" className="col-sm-6 col-md-3 col-ml-2 col-xl-2 pr-3 commonRoundedInputs common-input-font-size" label="Quantity" variant="outlined" />
              <Button
                className="col-sm-6 col-md-3 col-ml-3 col-xl-2 font-12 addOrderEnqButton"
                disabled={blockIntent === true ? true : false}
                onClick={() => {
                  if (partNumber) {
                    sessionStorage.setItem('partsData', JSON.stringify([{
                      productId: partNumber ? partNumber.trim() : '',
                      quantity: quantity && parseInt(quantity) ? parseInt(quantity) : 1
                    }]))
                  }
                  dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                  sessionStorage.setItem('isImportFromOES', 'false');
                  history.push('/createenquiry'); dispatch(setOrderEnquiryDetails('create'));
                }}
                variant="contained"
                color="primary">Add to Order Enquiry
              </Button>

            </div>
          }
        </div>
      </div>

      {ENQ_LIST && <div className="row mt-4">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center mb-2">
            <h5 className="page-sub-title">Recent Order Enquiries</h5>
            {customerTierType && customerTierType !== "Workshop" && customerTierType !== "tpcdealer" && customerTierType !== "Government" && customerTierType !== "Fleet" && customerTierType !== "Int. Business" && isShowSPR() && currentTier !== "" && (
              <div className="ml-auto legendItemCreateEnquiry m-2 mt-3">
                <span className="specialprice"></span>
                <span className="legendItemLabel mr-4 ml-2 mt-1">Special Price</span>
              </div>
            )}
          </div>
        </div>
        <div className="col-12">
          <div className="cardCommon mh-300p w-100">
            <Table aria-label="table">
              <TableHead className="w-100">
                <TableRow>
                  <TableCell className={classes.tableHead}></TableCell>
                  <TableCell></TableCell>
                  <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                  <TableCell className={classes.tableHead}>Reference No.</TableCell>
                  <TableCell className={classes.tableHead}>Brand</TableCell>
                  <TableCell>
                    <h6 className="tableHead">Estimated Total</h6>
                    <h6 className="vat">(incl.of VAT)</h6>
                  </TableCell>
                  <TableCell className={classes.tableHead}>Created On</TableCell>
                  <TableCell className={classes.tableHead}>Created By</TableCell>
                  <TableCell className={classes.tableHead}>Status</TableCell>
                  <TableCell className={classes.tableHead}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enquiryList && enquiryList.data && enquiryList.data.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data.length > 0 && (
                  <>
                    {enquiryList.data.data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((enquiry: any, index: any) => {
                      return (
                        <EnquiryList
                          key={index} index={index} enquiry={enquiry} setSelectedMasterId={setSelectedMasterId} setEnqCusId={setEnqCusId} />
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
            {((enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length === 0) || (enquiryList && enquiryList.data && enquiryList && !enquiryList.data.data)) && (
              <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Enquiries Found.</div>
            )}
            {enquiryList && enquiryList.loading && (
              <div className="mt-5 justify-content-center mx-auto text-center d-flex"><Loading /></div>
            )}
            {enquiryList && enquiryList.data && enquiryList.data.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data.length > 0 &&
              <Button disabled={blockIntent === true ? true : false}><Link className={blockIntent === true ? "disabled-link p-3 d-block" : "bottom-link p-3 d-block"} to="/orderenquiry">View all</Link></Button>
            }
          </div>
        </div>
      </div>}
      <Button
        disabled={blockIntent === true ? true : false}
        className="row col-12 mt-4">
        <div className="col-12 col-lg-4 col-md-12 col-sm-12">
          <SpecialOffers />
        </div>
        <div className="col-12 col-lg-4 col-md-12 col-sm-12">
          <Announcements />
        </div>
        {showFavorites &&
          <div className="col-12 col-lg-4 col-md-12 col-sm-12">
            <h5 className="page-sub-title mb-2">Favorites</h5>
            <div className="cardCommon height-400">
              <div className="col-12 mt-3">
                <div className="justifyContentHorizontalSpacebetween align-items-center">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 ml-1 mb-3 fav">Part</div>
                  <div className="mr-5 mb-3 fav">Action</div>
                </div>
                <Divider light />
                <div className="searchFav">
                  <span>
                    <IconButton
                      disabled={blockIntent === true ? true : false}
                      edge="start"
                      className="ml-2"
                      onClick={() => searchFavorites()}
                    >
                      <SearchIcon fontSize="inherit" />
                    </IconButton>
                    <TextField
                      disabled={blockIntent === true ? true : false}
                      value={searchText} onChange={(e) => { setsearchText(e.target.value) }}
                      size="small" className="mt-2" placeholder="Search your favorites" variant="outlined" />
                  </span>
                </div>
                <Divider light />
                {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length > 0 && (
                  <>
                    {favoritesList.data.favouriteList.slice(page * favoritesPage, page * favoritesPage + favoritesPage).map((favorite: any, index: any) => (
                      <Fragment key={index}>
                        <div className="d-flex justify-content-between">
                        </div>
                        <div className="mt-3 ml-1 footFav">
                          <Tooltip title="Edit" placement="top">
                            <IconButton className={classes.actionButton} size="small" onClick={() => { handleClickOpen(); dispatch(getFavoriteDetails(favorite)); }}>
                              <EditOutlinedIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View" placement="top">
                            <IconButton className={classes.actionButton} size="small" onClick={() => { handleopenCloseViewFavorite(true); dispatch(getFavoriteDetails(favorite)); }}>
                              <OpenInNewOutlinedIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                          <div className="mt-3 ml-1 footFav">{favorite.name}</div>
                          <div className="ml-3 mt-2 footOffers">By{' '}{favorite.userId}</div>
                          <div className="ml-3 mt-2 mb-2 footDate">{moment(favorite.updatedAt).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</div>
                        </div>
                        <Divider light />
                      </Fragment>
                    ))}
                  </>
                )}
                {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length === 0 && (
                  <span className="ml-5 mt-3">No favorites found</span>
                )}
              </div>
              {favoritesList && favoritesList.data && favoritesList.data.favouriteList && Array.isArray(favoritesList.data.favouriteList) && favoritesList.data.favouriteList.length > 0 &&
                <Link className="bottom-link p-3 ml-3 d-block" to="/favorites">View all</Link>
              }
            </div>
          </div>
        }
      </Button>
      <Dialog
        open={openEditFavorite}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        className="createFavoriteDialog"
        maxWidth="md"
      >
        <CreateFavorite setOpenDialog={handleClose} />
      </Dialog>
      <ViewFavorite
        open={openViewFavorite}
        handleopenClose={(data: any) => {
          handleopenCloseViewFavorite(false)
        }}
      />
      <ExpiredDialog24Hrs
        open={showApproveDialog}
        setOpenSnackbar={setOpenSnackbar}
        setSuccessMessage={setSuccessMessage}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenClose={(data: any, iscopy?: any, isCancel?: any) => {
          if (isCancel) {
            setshowApproveDialog(false)
          } else {
            if (iscopy) {
              setshowApproveDialog(data ? true : false)
            } else {
              setshowApproveDialog(data ? true : false)
              if ((enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === 'failed') || (enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === "enquiry cancelled") || (enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() === "fully cancelled") || enquiryMaterdata.status.toLowerCase() === "cancel" || !showCreateChangeEnquiry) {
                sessionStorage.setItem("current-enquiry-no", enquiryMaterdata.masterId)

                if (customerTierType && customerTierType.toLowerCase() === "dealer") {
                  dispatch(getViewRequestStatusAPI(enquiryMaterdata.masterId))
                }
                sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                  mode: 'view',
                  masterId: enquiryMaterdata.masterId,
                  orderType: "Cancelled"
                }))
                history.push('/viewenquiry');
              }
              else {
                sessionStorage.setItem("current-enquiry-no", enquiryMaterdata.masterId)
                if (customerTierType && customerTierType.toLowerCase() === "dealer") {
                  dispatch(getViewRequestStatusAPI(enquiryMaterdata.masterId))
                }
                sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                  // mode: enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() !== 'failed' && enquiryMaterdata.status.toLowerCase() !== "enquiry cancelled" && enquiryMaterdata.status.toLowerCase() !== "cancel" && enquiryMaterdata.status && enquiryMaterdata.status.toLowerCase() !== "fully cancelled" && showCreateChangeEnquiry ? 'update' : 'view',
                  mode: 'update',
                  masterId: enquiryMaterdata.masterId
                }))
                history.push('/updateenquiry');
              }
            }
          }
        }}
      />
      <CancelDialog
        open={openCancelDialog}
        text="Are you sure to cancel this Order Enquiry ?"
        maxWidth="sm"
        handleopenClose={async (data: any) => {
          if (data) {
            await dispatch(cancelEnquiry(customerTierType, selectedMasterId, enqCusId));
            if (sessionStorage.getItem('userType') === 'FSE') {
              dispatch(getFSEEnquiriesList(customerTypeAccess, { "fseWebApplicationLoginId": [sessionStorage.getItem('webApplicationLoginId')] }))
            } else {
              dispatch(getEnquiriesList(customerTierType, {}));
            }
            setSelectedMasterId(null)
            handleopenCloseSnackbar(true)
            setSuccessMessage('Enquiry cancelled successfully.');
          }
          handleopenCloseCancelDialog(false)
        }}
      />
    </Fragment>
  );
};

export default Dashboard;
