import * as React from "react";
import "react-dates/initialize";
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from "moment";

function DatePicker(props: any): JSX.Element {
  const [focusedInput, setFocusedInput] = React.useState(null);
  const { startDate, endDate, validationMode } = props;

  const onChange = (start: any, end: any) => {
    props.setstartDate(start);
    props.setendDate(end);
    props.onChange(start, end)
  }

  const onFocusChange = (focus: any) => {
    setFocusedInput(focus)
  }

  const isOutsideRange = (day: any) => {
    if (validationMode === '365days') {
      if (validationMode === '365days') {
        const oneYearAgo = moment().subtract(365, "days");
        return day.isAfter(oneYearAgo, "day");
      }
      if (startDate && props.isEnableOnlyThirtyDays) {
        const thirtyDaysFromStartDate = moment(startDate).add(30, "days");
        return day.isBefore(startDate, "day") || day.isAfter(thirtyDaysFromStartDate, "day");
      }
    } else {
      if (startDate) {
        if (props.isEnableOnlyThirtyDays) {
          const thirtyDaysFromNow = new Date(startDate);
          thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
          return day.isBefore(new Date(startDate)) || day.isAfter(thirtyDaysFromNow);
        }
        if (props.isDisableBeforeOneYear) {
          const oneYearAgo = new Date();
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
          return day.isBefore(oneYearAgo);
        }
      } else {
        if (props.isDisableBeforeOneYear) {
          const oneYearAgo = new Date();
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
          return day.isBefore(oneYearAgo);
        }
      }
    }
    return false;
  };


  return (
    <DateRangePicker
      displayFormat="DD/MM/yyyy"
      startDate={startDate}
      isOutsideRange={isOutsideRange}
      startDateId="startDate"
      initialVisibleMonth={() => validationMode === '365days' ? moment().subtract(395, "days") : moment()} // Default to one year ago
      startDatePlaceholderText="From Date"
      endDatePlaceholderText="To Date"
      endDate={endDate}
      endDateId="endDate"
      showClearDates
      onDatesChange={({ startDate, endDate }) => onChange(startDate, endDate)}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => onFocusChange(focusedInput)}
      readOnly
    />
  );
}

export default DatePicker;
