import { API_STATES } from "../../helpers/enums";
import { OES_OEM_MAP_PRODUCT_SEARCH } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  partSearchList: null,
  addNewPart: null,
  getGroupIdListData: null,
  getGroupIdListPaginationData:null,
  updateSearchPart: null,
  isGrpIDUpdating: {state: API_STATES.Initial}
};

function oesOemPartSearchReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_FETCHING:
      return {
        ...state,
        partSearchList: { loading: true, data: null, err: null },
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        partSearchList: { loading: false, data: action.payload.data.data, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_SEARCH_LIST_ERROR:
      return {
        ...state,
        partSearchList: { loading: false, data: null, err: action.payload.error }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_FETCHING:
      return {
        ...state,
        addNewPart: { loading: true, data: null, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_SUCCESS:
      return {
        ...state,
        addNewPart: { loading: false, data: action.payload, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.CREATE_PART_LIST_ERROR:
      return {
        ...state,
        addNewPart: { loading: false, data: null, err: action.payload.error }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_FETCHING:
      return {
        ...state,
        getGroupIdListData: { loading: true, data: null, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_SUCCESS:
      return {
        ...state,
        getGroupIdListData: { loading: false, data: action.payload, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_LIST_ERROR:
      return {
        ...state,
        getGroupIdListData: { loading: false, data: null, err: action.payload.error }
      };
      case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_FETCHING:
        return {
          ...state,
          getGroupIdListPaginationData: { loading: true, data: null, err: null }
        };
      case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_SUCCESS:
        return {
          ...state,
          getGroupIdListPaginationData: { loading: false, data: action.payload, err: null }
        };
    case OES_OEM_MAP_PRODUCT_SEARCH.GET_GRUOPID_PAGINATION_LIST_ERROR:
      return {
        ...state,
        getGroupIdListPaginationData: { loading: false, data: null, err: action.payload.error }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.RESET_GROUPID_API_STATE:
      return {
        ...state,
        isGrpIDUpdating: { state: API_STATES.Initial },
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_FETCHING:
      return {
        ...state,
        isGrpIDUpdating: { state: API_STATES.Loading }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_SUCCESS:
      return {
        ...state,
        isGrpIDUpdating: { state: API_STATES.Success }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_GROUPID_ERROR:
      return {
        ...state,
        isGrpIDUpdating: { state: API_STATES.Failed }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_FETCHING:
      return {
        ...state,
        updateSearchPart: { loading: true, data: null, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_SUCCESS:
      return {
        ...state,
        updateSearchPart: { loading: false, data: action.payload, err: null }
      };
    case OES_OEM_MAP_PRODUCT_SEARCH.UPDATE_PART_LIST_ERROR:
      return {
        ...state,
        updateSearchPart: { loading: false, data: null, err: action.payload.error }
      };
      case OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_FETCHING:
        return {
          ...state,
          importPartsExcelSheet: { loading: true, data: null, err: null }
        };
      case OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_SUCCESS:
        return {
          ...state,
          importPartsExcelSheet: { loading: false, data: action.payload, err: null }
        };
      case OES_OEM_MAP_PRODUCT_SEARCH.IMPORT_EXCEL_PART_LIST_ERROR:
        return {
          ...state,
          importPartsExcelSheet: { loading: false, data: null, err: action.payload.error }
        };
    default:
      return state;
  }
};

export default oesOemPartSearchReducer;