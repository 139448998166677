/* eslint-disable */
import React, { useEffect, Dispatch } from 'react';
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { login, logout } from "../../store/actions/account.actions";
import { useHistory } from 'react-router-dom';
import { markAdminUser } from "../../store/actions/global.actions";
import { getProfile } from "../../store/actions/profile.action";
import { getKamToken } from './../../store/actions/logon.actions';
import { authService } from './../../services/auth.service';
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import FullscreenLoader from "./../../common/components/FullscreenLoader";
import { addNotifications } from '../../store/actions/notifications.action';
import jwt_decode from "jwt-decode";
import { get } from 'lodash';
import { DASHBOARD_REDIRECT_EXCLUSION } from '../../constants/dashboardRedirectExclusion';
import { logoutUser } from "../../store/actions/logon.actions";
import { getMaterialGroups } from '../../store/actions/materialGroups.actions';

const LoginAzure: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const { instance } = useMsal();
    const history = useHistory();
    const userDispatch = useUserDispatch();

    useEffect(() => {
        loginUser()
        const sessionExpiry = Date.now() + 3600000;
        sessionStorage.setItem("sessionExpiry", String(sessionExpiry))
    }, []); // eslint-disable-line

    const loginUser = async () => {
        if (window.location.protocol == "http:" && !window.location.href.includes('localhost')) {
            window.location.href = window.location.href.replace(/^http:/, 'http:');
        } else {
            await instance.handleRedirectPromise();
            const accounts = instance.getAllAccounts();
            if (accounts.length === 0) {
                document.getElementById('btnLogin')?.click();
            } else {
                if (accounts && Array.isArray(accounts) && accounts[0]) {
                    const silentRequest = {
                        account: accounts[0],
                        scopes: ['openid', 'profile', 'email', 'user.read'] // Adjust scopes as per your application's requirements
                    };
                    try {
                        const response = await instance.acquireTokenSilent(silentRequest);
                        sessionStorage.setItem('azureAdAccessToken', response.accessToken);
                    } catch (error) {
                        console.error("Error during token acquisition: ", error);
                    }

                    const aud = get(accounts[0], 'idTokenClaims.aud', "");
                    const tid = get(accounts[0], 'idTokenClaims.tid', "");
                    const oid = get(accounts[0], 'idTokenClaims.oid', "");
                    sessionStorage.setItem('userOid', oid);
                    const msalKey = accounts[0].homeAccountId + "-" + accounts[0].environment + "-idtoken-" + aud + "-" + tid + "-";
                    const msalData: any = sessionStorage.getItem(msalKey);
                    const msalDataParsed = JSON.parse(msalData)
                    sessionStorage.setItem('idToken', msalDataParsed && msalDataParsed.secret);
                    sessionStorage.setItem('adminIdToken', msalDataParsed && msalDataParsed.secret);
                    let data: any = accounts[0];
                    let userEmail: any = accounts[0].username || data && data.idTokenClaims && data.idTokenClaims.emails && Array.isArray(data.idTokenClaims.emails) && data.idTokenClaims.emails[0]
                  
                    
                    if (userEmail) {
                       
                        // let tokenData: any = await dispatch(getJwtToken("", userEmail, "admin"));
                        let tokenData: any = await dispatch(getKamToken(oid, msalDataParsed && msalDataParsed.secret));
                        let jwt_token: any;
                        if (tokenData && tokenData.data && tokenData.data.accessToken)
                            jwt_token = jwt_decode(tokenData && tokenData.data && tokenData.data.accessToken);
                        await authService.setToken(tokenData && tokenData.data && tokenData.data);
                      
                        
                        if (jwt_token && jwt_token.source === "creditTeam") {
                            let profileData: any = await dispatch(getProfile(userEmail, "creditTeam"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', 'creditTeam');
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                if (profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "CREDIT_CONTROL_MANAGER") {
                                    if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                        history.push('/creditteam');
                                    }
                                } else {
                                    // const data = {
                                    //     userType: "KAM",
                                    //     sapCustomerId: '',
                                    //     webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
                                    // }
                                    // //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                                    // setTimeout(async () => {
                                    //     if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                    //         history.push('/');
                                    //     }
                                    // }, 2000)
                                    // dispatch(addNotifications(data));
                                }
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        } else if (jwt_token && jwt_token.userType === "FSE") {
                            let profileData: any = await dispatch(getProfile(userEmail, "fse"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                await dispatch(getMaterialGroups());
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', 'FSE');
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                const data = {
                                    userType: "FSE",
                                    sapCustomerId: '',
                                    webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
                                }
                                await dispatch(addNotifications(data));
                                // DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes

                                // setTimeout(async () => {
                                //     if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                //         history.push('/');
                                //     }
                                // }, 2000)
                                // if (window.location.pathname.includes('createenquiry')) {
                                //     history.push('/');
                                // }
                                if (window.location.pathname.includes('orderdetails') || window.location.pathname.includes('deliverylist') || window.location.pathname.includes('deliverydetails')) {
                                    history.push('/orderhistory');
                                }
                                if (window.location.pathname.includes('vieweditdealerdetails') || window.location.pathname.includes('dealerusers') || window.location.pathname.includes('dealerrequestdetails')) {
                                    history.push('/dealers');
                                }
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        } else if (jwt_token && jwt_token.source === "afg_super_admin") {
                            let profileData: any = await dispatch(getProfile(userEmail, "afg_super_admin"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', 'afg_super_admin');
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                if (profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "AFG_SUPER_ADMIN") {
                                    if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                        history.push('/planfeatures');
                                    }
                                } else {
                                    // const data = {
                                    //     userType: "KAM",
                                    //     sapCustomerId: '',
                                    //     webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
                                    // }
                                    // //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                                    // setTimeout(async () => {
                                    //     if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                    //         history.push('/');
                                    //     }
                                    // }, 2000)
                                    // dispatch(addNotifications(data));
                                }
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        } else if (jwt_token && jwt_token.source === "product_team") {
                            let profileData: any = await dispatch(getProfile(userEmail, "product_team"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', 'product_team');
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                if (profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "PRODUCT_TEAM") {
                                    if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                        history.push('/oesoemmapping');
                                    }
                                } else {}
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        }
                        
                        else if (jwt_token && jwt_token.source === "pricing_team") {
                            let profileData: any = await dispatch(getProfile(userEmail, "pricing_team"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', "pricing_team");
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                if (profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.userType === "PRICING_TEAM") {
                                    if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                        history.push('/specialpricerequest');
                                    }
                                } else {
                                    // const data = {
                                    //     userType: "KAM",
                                    //     sapCustomerId: '',
                                    //     webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
                                    // }
                                    // //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                                    // setTimeout(async () => {
                                    //     if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                    //         history.push('/');
                                    //     }
                                    // }, 2000)
                                    // dispatch(addNotifications(data));
                                }
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        } 
                        else {
                            let profileData: any = await dispatch(getProfile(userEmail, "kam"));
                            let orgIds = profileData && profileData.data && profileData.data.basicInfo && profileData.data.basicInfo.organizationIds && profileData.data.basicInfo.organizationIds.map((item: any) => {
                                return item.organizationId
                            })
                            if (profileData && profileData.message && profileData.message.status == "S") {
                                await dispatch(markAdminUser(true))
                                await dispatch(login(accounts[0]));
                                if (profileData.data && profileData.data.basicInfo) {
                                    sessionStorage.setItem('userId', profileData.data.basicInfo.b2cUserId);
                                    sessionStorage.setItem('userType', 'kam');
                                    sessionStorage.setItem('email', profileData.data.basicInfo.email);
                                    sessionStorage.setItem('kamOrgs', orgIds && Array.isArray(orgIds) ? JSON.stringify(orgIds) : JSON.stringify([]));
                                    // sessionStorage.setItem('customerId', profileData.data.basicInfo.sapCustomerId)
                                    // sessionStorage.setItem('customerName', profileData.data.customerInfo.companyName)
                                    sessionStorage.setItem('webApplicationLoginId', profileData.data.basicInfo.webApplicationLoginId);
                                }
                                const data = {
                                    userType: "KAM",
                                    sapCustomerId: '',
                                    webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
                                }
                                await dispatch(addNotifications(data));
                                //     //DASHBOARD_REDIRECT_EXCLUSION to be modified for any path changes
                                //     setTimeout(async () => {
                                //         if (!(DASHBOARD_REDIRECT_EXCLUSION.includes(window.location.pathname))) {
                                //             history.push('/');
                                //         }
                                //     }, 2000)
                                if (window.location.pathname.includes('vieweditdealerdetails') || window.location.pathname.includes('dealerusers') || window.location.pathname.includes('dealerrequestdetails')) {
                                    history.push('/dealers');
                                }
                            } else {
                                // setHasFailed('you dont have access to the app, you will be redirected o Login Page in 3 Sec');
                                setTimeout(async () => {
                                    await dispatch(logoutUser());
                                    await dispatch(logout());
                                    signOut(userDispatch);
                                    instance.logoutRedirect({ postLogoutRedirectUri: "/admin" });
                                }, 3000)
                            }
                        }
                        
                    }
                }
            }
        }
    }

    return (
        <div>
            <FullscreenLoader />
            <button style={{ display: 'none' }} id="btnLogin" onClick={() => instance.loginRedirect(loginRequest)}>Login to Azure</button>
        </div>
    )
}

export default LoginAzure
