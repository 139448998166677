/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch, useCallback, forwardRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { TextField, Button, Select, MenuItem, IconButton } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment-timezone";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import CancelIcon from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import DeleteDialogOnboarding from '../../common/components/deleteDialogOnboarding';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddNewAuthorisedSignatoriesDailog from './addNewAuthorisedSignatories';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { getAddNewAuthorisedSignatories, saveAuthorisedSignatories, updateAuthorisedSignatories, deleteAuthorisedSignatories, updateDocument, deleteDocument, getDealerDetails, getAllSignatories, resetValues, changeCustomerType, saveDocument, getShipToPartyAddress, getTradeRegion } from '../../store/actions/onboard.actions';
import DeleteIcon from "@material-ui/icons/Delete";
import { get } from 'lodash';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import SubmitDialog from './submitYourRequestDialog';
import { Prompt, useHistory } from 'react-router-dom';
import DeleteDocumentDialog from '../../common/components/deleteDocumentDialog';
import { getUniqueTimestamp } from '../../common/components/util';
import Visibility from "@material-ui/icons/Visibility";
import "./document.css";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
  bodyContentActionHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    maxWidth: 60
  },
  oppacityLess: {
    opacity: 0.5,

  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADERS FOR ADD AUTHORIZED SIGNATORIES
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'authorisedFor', numeric: true, disablePadding: false, label: 'Authorized for' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

export const Documents = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [tradeLicense, setTradeLicense] = React.useState<any>(null);
  const [memoRandumExpiryDate, setMemoRandumExpiryDate] = React.useState<any>(null);
  const [isValidMemoRandumExpiryDate, setIsValidMemoRandumExpiryDate] = React.useState<any>(true);
  const [memoRandumRegFile, setMemoRandumRegFile] = React.useState<any>(null);
  const [vatRegExpiryDate, setVatRegExpiryDate] = React.useState<any>(null);
  const [isValidVatRegExpiryDate, setIsValidVatRegExpiryDate] = React.useState<any>(true);
  const [vatRegFile, setVatRegFile] = React.useState<any>(null);
  const [companySealFile, setCompanySealFile] = React.useState<any>(null);
  const [selectRegionError, setSelectRegionError] = React.useState<boolean>(false);
  const [invalidSelectRegion, setInvalidSelectRegion] = React.useState<string>("");
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [selectedAddNewData, setSelectedAddNewData] = React.useState<any>("");
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openDeleteDialogTrade, setOpenDeleteDialogTrade] = React.useState(false);
  const [openDeleteDialogDocument, setOpenDeleteDialogDocument] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [onClickOfSave, setOnClickOfSave] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [selected, setSelected] = React.useState<any>([]);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [addNewAuthorisedSignatories, setAddNewAuthorisedSignatories]: any = React.useState([])
  const [currentEmiratesIndex, setCurrentEmiratesIndex] = React.useState<any>();
  const history = useHistory();
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [selectedType, setSelectedType] = React.useState<any>();
  const [dealerDetails, setDealerDetails] = React.useState<any>();
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [selectedTradeReference, setSelectedTradeReference] = React.useState<any>([]);
  // const [dataUpdated, setDataUpdated] = React.useState(false);
  const [memorandamCopy, setMemorandamCopy] = React.useState({ documentId: '', expiryDate: '' });
  const [vatCertificateCopy, setVatCertificateCopy] = React.useState({ documentId: '', expiryDate: '' });
  const [companySealCopy, setCompanySealCopy] = React.useState({ documentId: '' });
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [isView, setIsView] = React.useState(false);

  const { customerDetails, regionData, saveSignatoriesData, editSignatoriesData,
    deleteSignatoriesData, getAllSignatoriesData, shipToPartyAddressData }: any = useSelector((state: any) => state.onboard);

  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [tradeReference, setTradeReference] = React.useState<any>([
    {
      tradeLicenseNo: "",
      selectRegion: "",
      tradeLicenseExpiryDate: null,
      tradeLicense: null,
    }
  ]);
  const customer: any = sessionStorage.getItem('customerOnboardingType')
  const customerType: any = sessionStorage.getItem('customerType')



  const handleClose = () => {
    setOpen(false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseSubmit = (open?: any) => {
    if (isValidDocumentTab()) {
      setOpenSubmitDialog(open ? true : false);
    }
  };

  const handleopenCloseTrade = (open?: any) => {
    setOpenDeleteDialogTrade(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseDocument = (open?: any) => {
    setOpenDeleteDialogDocument(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/onboarding');
      window.location.reload();
    } else {
      showErrorMessage(data.error);
      handleopenCloseErrorSnackbar(true);
    }
  }

  // ON CHANGE OF DEALER TYPE 
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }


  // DOCUMENTS TAB API INTEGRATION
  const populateDocumentDetails = (data: any) => {
    if (data && data.data && data.data.treadLicense && !dataFetched) {
      let tread: any = [];
      data.data.treadLicense.map((tl: any) => {
        tread.push({
          selectRegion: tl.regionCode,
          tradeLicenseNo: tl.tradeLicenseNo,
          tradeLicenseExpiryDate: tl.expiryDate,
          tradeLicense: tl.documentId && tl.documentId.url ? tl.documentId : '',
          id: tl.id
        })
      })
      if (data.data.treadLicense.length === 0) {
        tread.push({
          tradeLicenseNo: "",
          selectRegion: "",
          tradeLicenseExpiryDate: null,
          tradeLicense: null,
        })
      }
      setTradeReference(tread);
      setSelectedTradeReference(tread);
      handleopenClose(false);
    }
    if (data && data.data && data.data.memorandumOfAssociation && data.data.memorandumOfAssociation.documentId && !dataFetched) {
      const { expiryDate, id } = data.data.memorandumOfAssociation.documentId;
      setMemoRandumExpiryDate(expiryDate);
      setMemorandamCopy({ documentId: id, expiryDate: expiryDate })
      setMemoRandumRegFile(data.data.memorandumOfAssociation.documentId)
    }
    if (data && data.data && data.data.vatCertificateCopy && data.data.vatCertificateCopy.documentId && !dataFetched) {
      const { expiryDate, id } = data.data.vatCertificateCopy.documentId;
      setVatCertificateCopy({ documentId: id, expiryDate: expiryDate })
      setVatRegExpiryDate(expiryDate)
      setVatRegFile(data.data.vatCertificateCopy.documentId);
    }
    if (data && data.data && data.data.companySeal && data && data.data.companySeal.documentId && !dataFetched) {
      const { id } = data && data.data.companySeal.documentId;
      setCompanySealCopy({ documentId: id });
      setCompanySealFile(data && data.data.companySeal.documentId)
    }
    if (!dataFetched) setDataFetched(true);
    handleopenCloseSnackbar(false);
    handleopenCloseDocument(false);
  }

  useEffect(() => {
    loadSignatories();
  }, []);

  // TO DISPATCH THE USER DETAILS
  const loadSignatories = async () => {
    setLoading(true);
    let data: any = await dispatch(getDealerDetails(b2bUserId, 'documents'));
    if (data && data.data && !dataFetched) {
      props.setdealerStatus(data.data.status)

      setDealerDetails(data);
      populateDocumentDetails(data);
    }
    dispatch(getAllSignatories(b2bUserId));
    dispatch(getShipToPartyAddress(b2bUserId));
    dispatch(getTradeRegion(b2bUserId));
    setLoading(false);
  }

  // ON CLICK OF SAVE AUTHORIZED SIGNATORIES
  useEffect(() => {
    if (saveSignatoriesData && saveSignatoriesData.data && saveSignatoriesData.data.message) {
      setSuccessMessage(saveSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(b2bUserId));
    }
  }, [saveSignatoriesData, editSignatoriesData])

  // ON CLICK OF EDIT/UPDATE AUTHORIZED SIGNATORIES
  useEffect(() => {
    if (editSignatoriesData && editSignatoriesData.data && editSignatoriesData.data.message) {
      setSuccessMessage(editSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(b2bUserId));
    }
  }, [editSignatoriesData]);

  // ON CLICK OF DELETE AUTHORIZED SIGNATORIES
  useEffect(() => {
    if (deleteSignatoriesData && deleteSignatoriesData.data && deleteSignatoriesData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteSignatoriesData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllSignatories(b2bUserId));
    }
  }, [deleteSignatoriesData]);

  // ON CLICK BROWSE FILE  
  const onClickBrowseFile = async (type: any, item: any, setCallback: any, index?: any) => {
    setLoading(true);
    props.setDataUpdated(true);
    showErrorMessage(null);
    setSuccessMessage(null);
    handleopenCloseSnackbar(false);
    handleopenCloseErrorSnackbar(false);
    setCurrentEmiratesIndex(index);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
      return;
    }
    if (type === 'tradeLicenes' && item && item.size < 5000000) {
      setLoading(true);
      const canNotUploadWithoutDate = type === 'tradeLicenes';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';

      if (setCallback) {
        if (type == "tradeLicenes") {
          let tradeReferenceRow = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = item;
          setCallback(tradeReferenceRow);
          // return;
        }
      }
      if (type !== "tradeLicenes") setCallback(item);
      if (canNotUploadWithoutDate) {
        if (type === 'tradeLicenes') {
          let trade = [...tradeReference];
          expiryDate = moment(trade[index].tradeLicenseExpiryDate).utc().format()
          setCallback(trade);
        };
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'tradeLicenes') { docType = 'Trade License File' }

      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let uploadedData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedData && uploadedData.data && uploadedData.message && uploadedData.message.status === 'S') {
        setSuccessMessage(uploadedData.message.message);
        handleopenCloseSnackbar(true);
        setLoading(false);
        const { label, expiryDate, url, id, sapCustomerId } = uploadedData.data;
        if (label === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = uploadedData.data;
          tradeReferenceRow[index].tradeLicenseExpiryDate = expiryDate;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        setLoading(false);
        if (type === 'tradeLicenes') {
          let tradeReferenceRow: any = [...tradeReference];
          tradeReferenceRow[index].tradeLicense = null;
          tradeReferenceRow[index].tradeLicenseExpiryDate = null;
          setTradeReference(tradeReferenceRow);
          setSelectedTradeReference(tradeReferenceRow);
        }
      }
    } else if (type !== 'tradeLicenes' && item && item.size < 2000000) {
      setLoading(true);
      const canNotUploadWithoutDate = type === 'memorandumOfAssociation' || type === 'vatCertificateFile' || type === 'tradeLicenes';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';

      if (canNotUploadWithoutDate) {
        if (type === 'memorandumOfAssociation') expiryDate = moment(memoRandumExpiryDate).utc().format();
        else if (type === 'vatCertificateFile') expiryDate = moment(vatRegExpiryDate).utc().format();
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'memorandumOfAssociation') { docType = 'Memorandum File' }
      else if (type === 'vatCertificateFile') { docType = 'Vat Certificate File' }
      else if (type === 'tradeLicenes') { docType = 'Trade License File' }
      else if (type === 'companySealFile') { docType = 'Company Seal File' }

      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let uploadedData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedData && uploadedData.data && uploadedData.message && uploadedData.message.status === 'S') {
        setSuccessMessage(uploadedData.message.message);
        handleopenCloseSnackbar(true);
        setLoading(false);
        const { label, expiryDate, url, id, sapCustomerId } = uploadedData.data;
        if (label === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(expiryDate);
          setMemorandamCopy({ documentId: id, expiryDate: expiryDate });
          setMemoRandumRegFile(uploadedData.data);
        }
        else if (label === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: id, expiryDate: expiryDate });
          setVatRegExpiryDate(expiryDate);
          setVatRegFile(uploadedData.data);
        }
        else if (label === 'companySealFile') {
          setCompanySealCopy({ documentId: id });
          setCompanySealFile(uploadedData.data)
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        setLoading(false);
        if (type === 'memorandumOfAssociation') {
          setMemoRandumExpiryDate(null);
          setMemorandamCopy({ documentId: '', expiryDate: '' });
          setMemoRandumRegFile(null);
        }
        else if (type === 'vatCertificateFile') {
          setVatCertificateCopy({ documentId: '', expiryDate: '' });
          setVatRegExpiryDate(null);
          setVatRegFile(null);
        }
        else if (type === 'companySealFile') {
          setCompanySealCopy({ documentId: '' });
          setCompanySealFile(null)
        }
      }
    } else if (type === 'tradeLicenes') {
      showErrorMessage('Please upload less than 5MB file for Trade License Copy.');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
    } else if (type !== 'tradeLicenes') {
      showErrorMessage('Please upload less than 2MB file');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
    }
    setLoading(false);
  }

  //ON CHANGE OF DATE FUNCTIONALITY & VALIDATIONS
  function datePickerValid(e: any, state: any, index?: any) {

    if (state === 'trade') {
      let trade = [...tradeReference]
      if (e === "Invalid Date Format") {
        trade[index].dateError = "Invalid Date Format"
        trade[index].invalidDate = true
      } else if (e === "Date should not be before minimal date") {
        trade[index].dateError = "Date should not be before minimal date"
        trade[index].invalidDate = true
      } else if (e === "Date should not be after maximal date") {
        trade[index].dateError = "Date should not be after maximal date"
        trade[index].invalidDate = true
      }

      else {
        trade[index].dateError = '';
        trade[index].invalidDate = false
      }
    } else {
      if (e === "Invalid Date Format") {
        state(false);
        // setSubmitDisabled(true);
      } else if (e === "Date should not be before minimal date") {
        state(false);
      } else if (e === "Date should not be after maximal date") {
        state(false);
      } else {
        state(true);
      }
    }
  }

  // ON CLICK OF SUBMIT & CONFIRM BUTTON DISABLED CONDITION
  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }

  // ON CHANGE OF ADD MORE VALIDATION FOR TEXTFILEDS
  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    props.setDataUpdated(true);
    let trade = [...tradeReference]
    switch (type) {
      case "selectRegion":
        /* Uncomment for region validation occurs in future */
        // if (isRegionExists(e.target.value, index)) {
        //   trade[index].error = 'Region already exists'
        // } else {
        trade[index].selectRegion = e.target.value
        // trade[index].error = null
        // }
        break;
      case "tradeLicenseNo":
        trade[index].tradeLicenseNo = e.target.value;
        // validateTradeLicense(e.target.value, index); 
        break;
      case "tradeLicenseExpiryDate":
        let date = new Date(e)
        if (date) {
          trade[index].tradeLicenseExpiryDate = e;
          trade[index].dateError = '';
          trade[index].invalidDate = false;
        } else {
          trade[index].dateError = "Invalid Date Format"
          trade[index].invalidDate = true
        }
        break;
      case "tradeLicense":
        // deleteDealerDocument('treadLicense', trade[index].tradeLicense, index);
        // trade[index].tradeLicense = null;
        break;
      default: ;
    }
    setTradeReference(trade);
    setSelectedTradeReference(trade);
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        errorSetterName(true);
      }
      else {
        errorSetterName(false);
      }
    }
  }



  // ON CHANGE OF BROWSE FILE VALIDATIONS FOR DOCUMENTS 
  const isValidDocuments = () => {
    let isValid = true;
    if (vatCertificateCopy.documentId === '' && customer.toLowerCase() !== "workshop" && customer.toLowerCase() !== "government") {
      isValid = false;
    }
    if (vatRegExpiryDate !== null && vatCertificateCopy.documentId == "" && customer.toLowerCase() === "government") {
      isValid = false
    }
    if (companySealCopy.documentId === '' && customer !== "Government" && customer.toLowerCase() !== "workshop") {
      isValid = false;
    }
    let trade = [...selectedTradeReference];
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (customer !== "Government") {
        if (!tradeItemMap.tradeLicenseNo || tradeItemMap.isError || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense || tradeItemMap.invalidDate) {
          isValid = false;
        }
      } else if (tradeItemMap.tradeLicenseNo || tradeItemMap.selectRegion || tradeItemMap.tradeLicenseExpiryDate || tradeItemMap.tradeLicense && tradeItemMap.tradeLicense.fileName) {
        if (!tradeItemMap.tradeLicenseNo || tradeItemMap.isError || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense || tradeItemMap.invalidDate) {
          isValid = false;
        }
      }
    });
    return isValid;
  }

  // API INTEGARTION FOR DOCUMENTS TAB
  function isValidDocumentTab() {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    if (isValidDocuments()) {
      if (getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.length === 0 && customer.toLowerCase() !== "workshop") {
        // setOpenErrorSnackbar(true);
        setInCompleteFileds(true);
        handleopenCloseErrorSnackbar(true);
        showErrorMessage('Please add atleast one Authorized Signatories');
      } else {
        setInCompleteFileds(false);
        return true;
      }
    } else {
      setInCompleteFileds(true);
      showErrorMessage("Please fill all mandatory fields and upload all mandatory documents");
      handleopenCloseErrorSnackbar(true);
      return false;
    }
  }

  // ON CLICK OF SAVE FORM ( FOR NEWLY ADDED USER BY KAM FOR USER CONFIRMATION ) VALIDATIONS & API INTTEGRATIONS
  async function saveDocumentDetailsForUserConfirmation(isSubmitted?: any) {
    if (isValidDocumentTab()) {
      dispatch(resetValues());
      // setSuccessMessage(null);
      handleopenCloseSnackbar(false);
      let selectedTrade: any[] = [];
      let trade = [...selectedTradeReference];
      trade.map((t: any) => {
        selectedTrade.push({
          "regionCode": t.selectRegion,
          "tradeLicenseNo": t.tradeLicenseNo,
          "expiryDate": moment(t.tradeLicenseExpiryDate).utc().format() === "Invalid date" ? "" : moment(t.tradeLicenseExpiryDate).utc().format(),
          "documentId": t.tradeLicense && t.tradeLicense.id ? t.tradeLicense.id : '',
          id: t.id ? t.id : randomString()
        })
      })
      const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
      const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
      const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
        ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
      let data = {
        "b2bUserId": b2bUserId,
        "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
        "treadLicense": selectedTrade,
        "companySeal": companySealCopy && companySealCopy["documentId"] ? companySealCopy : {},
        "memorandumOfAssociation": memorandamCopy && memorandamCopy["documentId"] ? memorandamCopy : {},
        "vatCertificateCopy": vatCertificateCopy,
        "isSubmited": false,
      }
      setLoading(true);
      const isSaveDocument: any = await dispatch(saveDocument(data));
      if (isSaveDocument && isSaveDocument.message) {
        props.setDataUpdated(false);
        setSuccessMessage(isSaveDocument.message.message ? isSaveDocument.message.message : 'Customer details saved successfully');
        handleopenCloseSnackbar(true);
        setLoading(false);
        props.setTabValue(2);
        if (isSubmitted) {
          handleopenCloseSubmit(false);
          let data: any = await dispatch(getDealerDetails(b2bUserId, 'documents'));
          setDealerDetails(data);
          if (data && data.data && data.data.status)
            if (data && data.data && data.data.status === 'Submitted') {
              props.loadDealerDetails(true);
            }
        }
      }
    }
  }


  // ON CLICK OF SAVE FORM VALIDTIONS (FOR NEW & EXISITING FLOW) & API INTEGRATION
  function randomString() {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyz-';
    let length = 35;
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  function isFleet() {
    if (sessionStorage.getItem('customerOnboardingType') == 'Fleet') {
      return true
    } else {
      return false
    }
  }




  async function saveDocumentDetails(isSaveForm?: any) {
    if (isValidDocumentTab()) {
      dispatch(resetValues());
      // setSuccessMessage(null);
      handleopenCloseSnackbar(false);
      let selectedTrade: any[] = [];
      let trade = [...selectedTradeReference];
      trade.map((t: any) => {
        selectedTrade.push({
          "regionCode": t.selectRegion,
          "tradeLicenseNo": t.tradeLicenseNo,
          "expiryDate": moment(t.tradeLicenseExpiryDate).utc().format() === "Invalid date" ? "" : moment(t.tradeLicenseExpiryDate).utc().format(),
          "documentId": t.tradeLicense && t.tradeLicense.id ? t.tradeLicense.id : '',
          id: t.id ? t.id : randomString()
        })
      })
      const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
      const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
      const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
        ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
      let data = {
        "b2bUserId": b2bUserId,
        "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
        "treadLicense": selectedTrade,
        "companySeal": companySealCopy && companySealCopy["documentId"] ? companySealCopy : {},
        "memorandumOfAssociation": memorandamCopy && memorandamCopy["documentId"] ? memorandamCopy : {},
        "vatCertificateCopy": vatCertificateCopy,
        "isSubmited": false,
      }
      setLoading(true);
      const isSaveDocument: any = await dispatch(saveDocument(data));
      if (isSaveDocument && isSaveDocument.message) {
        props.setDataUpdated(false);
        setSuccessMessage(isSaveDocument.message.message ? isSaveDocument.message.message : 'Customer details saved successfully');
        handleopenCloseSnackbar(true);
        setLoading(false);
        if (!isSaveForm) props.setTabValue(2);
        handleopenCloseSubmit(false);
        let data: any = await dispatch(getDealerDetails(b2bUserId, 'documents'));
        setDealerDetails(data);
        if (data && data.data && data.data.status)
          if (data && data.data && data.data.status === 'Submitted') {
            props.loadDealerDetails(true);
          }
      }
    }
  }

  //TRADE LICENSE - ONCLICK OF ADD MORE FUNCTIONALITY
  const addTradeRow = () => {
    let trade = [...tradeReference];
    let allow = true;
    trade.map((tradeItemMap: any) => {
      tradeItemMap.error = null;
      if (!tradeItemMap.tradeLicenseNo || !tradeItemMap.selectRegion || !tradeItemMap.tradeLicenseExpiryDate || !tradeItemMap.tradeLicense) {
        allow = false;
      }
    });
    if (allow) {
      const data = {
        tradeLicenseNo: "",
        selectRegion: "",
        tradeLicenseExpiryDate: null,
        tradeLicense: ""
      }
      trade.push(data);
      setTradeReference(trade);
      setSelectedTradeReference(trade);
      setInCompleteFileds(false);
    } else {
      setInCompleteFileds(true);
    }
    return allow;
  }

  // ALLOW TO ADD NEW ROW ONCLICK OF ADD MORE
  const allowNewRow = () => {
    let allow = true;
    return allow;
  }

  // DELETE ROW ONCLICK OF ADD MORE - DELETE ICON
  const deleteTradeRow = () => {
    if (shipToPartyAddressData && shipToPartyAddressData.data && shipToPartyAddressData.data.addresses && shipToPartyAddressData.data.addresses.length > 0 && (shipToPartyAddressData.data.addresses[0].transportationZone !== '' || shipToPartyAddressData.data.addresses[0].shiftToPartyCustomerNumber !== '')) {
      let trade = [...tradeReference];
      trade = trade.filter((element, index1) => {
        return index1 === currentEmiratesIndex;
      });
      if (trade && trade.length > 0) {
        if (shipToPartyAddressData.data.addresses[0].tradeLicenseNo == trade[0].tradeLicenseNo) {
          showErrorMessage('You cannot delete this trade license as it is mapped to your Shipping address.');
          handleopenCloseErrorSnackbar(true);
        } else {
          props.setDataUpdated(true);
          let trade1 = [...tradeReference];
          trade1 = trade1.filter((element, index1) => {
            return index1 != currentEmiratesIndex;
          })
          setTradeReference(trade1);
          setSelectedTradeReference(trade1);
          handleopenCloseTrade(false);
        }
      }
    } else {
      props.setDataUpdated(true);
      let trade = [...tradeReference];
      trade = trade.filter((element, index1) => {
        return index1 != currentEmiratesIndex;
      })
      setTradeReference(trade);
      setSelectedTradeReference(trade);
      handleopenCloseTrade(false);
    }
  }

  // ON CLICK OF CHECKBOXES FOR ADD AUTHORIZED SIGNATORIES
  const getAuthorisedFor = (data: any) => {
    if (data.authorizedForCheque && data.authorizedForPurchaseOffice) {
      return 'Cheques , Purchase Orders';
    } else if (data.authorizedForCheque) {
      return 'Cheques'
    } else if (data.authorizedForPurchaseOffice) {
      return 'Purchase Orders'
    } else {
      return '-'
    }
  }

  // ON CLICK OF SAVE - ADD NEW USER AUTHORISED SIGANTORIES 
  const getNewAuthorisedSignatories = (data: any) => {
    addNewAuthorisedSignatories.push(data);
    dispatch(saveAuthorisedSignatories(data));
    setAddNewAuthorisedSignatories(addNewAuthorisedSignatories);
  }

  //ON CLICK - UPDATE USER AUTHORISED SIGANTORIES 
  const updateNewAuthorisedSignatories = (data: any) => {
    dispatch(updateAuthorisedSignatories(data));
  }

  // ON CLICK - DELETE USER AUTHORISED SIGANTORIES 
  const deleteSignatory = (addNewData: any) => {
    handleopenClose(true);
    setDeleteMessage('Are you sure you want to delete this Authorized Signatories ?');
    setSelectedAddNewData(addNewData);
  }

  const handleSubmit = () => {
    handleopenClose(true);
    setDeleteMessage('Are you sure you want to delete this Trade License Details ?');
  }

  // DELETE SIGNATORIES API INTEGRATION 
  const deleteAuthorised = () => {
    const data = {
      "sapCustomerId": selectedAddNewData && selectedAddNewData.sapCustomerId ? selectedAddNewData.sapCustomerId : '',
      "id": b2bUserId,
      "signatorieId": selectedAddNewData.id
    }
    dispatch(deleteAuthorisedSignatories(data))
  }

  // ON CLCIK OF BROWSE FILE VALIDATIONS 
  const deleteDealerDocument = async () => {
    let data: any;
    props.setDataUpdated(true);
    setSelectedType(documentType);
    if (documentType == 'companySeal') {
      data = companySealFile;
    } else if (documentType == 'memorandumOfAssociation') {
      data = memoRandumRegFile;
    } else if (documentType == 'vatCertificateCopy') {
      data = vatRegFile;
    } else if (documentType == 'treadLicense') {
      let trade = [...tradeReference]
      data = trade[currentEmiratesIndex].tradeLicense;
    }
    const obj = {
      "type": documentType
    }
    let response: any = await dispatch(deleteDocument(b2bUserId, data.id, obj));
    if (response && response.message && response.message.status === 'S') {
      if (documentType === 'companySeal') {
        setCompanySealCopy({ documentId: '' });
        setCompanySealFile(null);
      }
      if (documentType === 'memorandumOfAssociation') {
        setMemoRandumRegFile(null); setMemoRandumExpiryDate(null);
        setMemorandamCopy({ documentId: '', expiryDate: '' });
      }
      if (documentType === 'vatCertificateCopy') {
        setVatRegFile(null); setVatRegExpiryDate(null);
        setVatCertificateCopy({ documentId: '', expiryDate: '' });
      }
      if (documentType === 'treadLicense') {
        let trade = [...tradeReference]
        trade[currentEmiratesIndex].tradeLicense = null;
        trade[currentEmiratesIndex].tradeLicenseExpiryDate = null;
      }
      handleopenCloseDocument(false);
    } else {
      showErrorMessage(response.error);
      setOpenErrorSnackbar(true);
    }
  }

  // ON CLICK OF NEXT BUTTON - SAVE DATA AND VALIDATIONS CHECK
  const handleNextClick = async () => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.status === "Submitted" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Confirmed from Dealer") {
      props.setTabValue(2);
    } else {
      if (isKamOnboarded) {
        saveDocumentDetailsForUserConfirmation(false);
      } else {
        saveDocumentDetails();
      }
    }
  }

  // ON CLCIK OF BACK BUTTON FUNCTIONALITY
  const handleBackClick = () => {
    if (props.dataUpdated) {
      setDeleteMessage('Do you wish to proceed without saving the data?')
      handleopenClose(true);
    } else {
      props.setTabValue(0)
    }
  }

  //TABLE HEADER FUNCTIONALITY
  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              // align={headCell.numeric ? headCell.id === 'actions' || headCell.id === 'brands' ? 'center' : 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Fragment>
      {/* <Prompt
        when={dataUpdated}
        message='Do you wish to proceed without saving the data?'
      /> */}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      {loading && (<FullscreenLoader />)}
      <div className="row m-0 p-0 border-bottom">
        <div className="col-12 mt-3 mb-3">
          <h6 className="info-sub-header-document">Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.</h6>
        </div>
      </div>
      <div className="row  m-0 p-0 border-bottom">
        <div className="col-md-2 col-lg-2 mb-2 mt-3">
          <h6 className="document-content">Trade License{' '}{customer !== "Government" ? "*" : ""}</h6>
          <IconButton className="p-0 m-1 toast-header"
            disabled={isDisabled()}
            edge="end" aria-label="add">
            <AddCircleIcon fontSize="medium" onClick={() => {
              addTradeRow();
            }} />
          </IconButton>
          <span className="info-sub-header-document mt-2">Add More</span>
        </div>
        <div className="col-md-10 col-lg-10 mb-2 pl-0 mt-3 trade onboarding-field ">
          {tradeReference && tradeReference.length > 0 && tradeReference.map((tradeItem: any, index: any) => {
            return <div className="col-md-12 col-lg-12 mb-2 pl-0 mt-0">
              <TextField select id="selectRegion"
                value={tradeItem.selectRegion}
                error={customer !== "Government" ? !tradeItem.selectRegion && inCompleteFileds : false}
                helperText={invalidSelectRegion}
                disabled={isDisabled()}
                onChange={(e: any) => handleInputChange(e, "selectRegion", index, setSelectRegionError, setInvalidSelectRegion)}
                size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                label="Select Region" required={customer !== "Government" ? true : false} variant="outlined" margin="dense">
                {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
              </TextField>
              <TextField id="tradeLicenseNo"
                value={tradeItem.tradeLicenseNo}
                type="text"
                //onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                error={customer !== "Government" ? !tradeItem.tradeLicenseNo && inCompleteFileds : false}
                helperText={tradeItem.errormessage && <span style={{ color: 'red' }}>{tradeItem.errormessage}</span>}
                disabled={isDisabled()}
                inputProps={{ maxLenght: 30 }}
                onChange={(e: any) => handleInputChange(e, "tradeLicenseNo", index)}
                size="small" className="col-sm-3 my-2 pr-2 commonRoundedInputs mt-2"
                label="Trade License No" required={customer !== "Government" ? true : false} variant="outlined" margin="dense" />
              {/* <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onChange={(date: Date | null) => { handleInputChange(date, "tradeLicenseExpiryDate", index); }}
                // onError={(e, v) => datePickerValid(e, 'trade', index)}
                // disabled={tradeLicenseExpiryDate && tradeLicense ? true : false}
                disabled={isDisabled()}
                autoOk size="small"
                disableToolbar
                error={!tradeItem.tradeLicenseExpiryDate && inCompleteFileds || tradeItem.invalidDate}
                variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date *"
                value={tradeItem.tradeLicenseExpiryDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'col-sm-2 my-2 pr-3 rounded-input-dealer cal-icon colorHelper'}
              // helperText={tradeItem.invalidDate && <span style={{ color: 'red' }}>{tradeItem.dateError}</span>}
              /> */}
              {/* Below keyboard */}
              <KeyboardDatePicker
                required={isFleet() ? true : false}
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                disabled={isDisabled()}
                onError={(e, v) => datePickerValid(e, 'trade', index)}
                // disabled={tradeLicenseExpiryDate && tradeLicense ? true : false}
                autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                // error={customer !== "Government" ? tradeItem.dateError ? true : false : false}          
                format="dd/MM/yyyy" label="Expiry Date" value={tradeItem.tradeLicenseExpiryDate ? tradeItem.tradeLicenseExpiryDate : null}
                onChange={(date: Date | null) => { handleInputChange(date, "tradeLicenseExpiryDate", index); }}
                // onChange={(date: any) => {
                //   let newDate = new Date(date);
                //   if (newDate < new Date()) {
                //     setIsValidMemoRandumExpiryDate(false);
                //   } else {
                //     setMemoRandumExpiryDate(date);
                //     setIsValidMemoRandumExpiryDate(true);
                //   }
                // }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'col-sm-2 my-2 pr-3 rounded-input-dealer cal-icon colorHelper'}
              />
              {tradeItem.tradeLicense ?
                <div className="file-name ml-2">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(tradeItem.tradeLicense.url)
                  }}>{tradeItem.tradeLicense.name ? tradeItem.tradeLicense.name : tradeItem.tradeLicense.selectedFileName}</span>
                  <IconButton className={isDisabled() ? "p-1 mt-2 disabledCustomclass" : "p-1 mt-2"}
                    disabled={isDisabled()} aria-label="delete"
                    onClick={(e: any) => {
                      setDocumentType('treadLicense');
                      setCurrentEmiratesIndex(index);
                      handleopenCloseDocument(true);
                    }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component="label" color="secondary"
                  className={`col-md-3 col-lg-3 rounded-button-dealer mt-2 mr-4 px-4 ml-2 ${isDisabled() || !tradeItem.tradeLicenseExpiryDate ? classes.oppacityLess : ""}`}
                  disabled={isDisabled() ? isDisabled() : tradeItem.tradeLicenseExpiryDate ? false : true}
                >
                  Upload Trade license
                  <input type="file" hidden
                    disabled={isDisabled()}
                    onClick={(e: any) => {
                      const { target = {} } = e || {}
                      target.value = ""
                      setTradeLicense("")
                      showErrorMessage('');
                      handleopenCloseErrorSnackbar(false);
                    }}
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('tradeLicenes', e.target.files[0], setTradeReference, index) : setTradeLicense(null)
                    }
                  />
                </Button>
              }
              {tradeReference && tradeReference.length === 1 ? '' : (
                <IconButton className="col-md-0 mt-2 float-right" size="small"
                  disabled={isDisabled()}
                  onClick={() => {
                    setCurrentEmiratesIndex(index);
                    handleopenCloseTrade(true)
                  }}>
                  <DeleteIcon
                    fontSize="inherit" />
                  <span className="font-size-13 load-from-fav ml-1 mt-0">
                  </span>
                </IconButton>
              )}
              {tradeItem.error ? (<div className="text-red">{tradeItem.error}</div>) : ''}
            </div>
          })}
        </div>
      </div>
      {customer !== "Government" && <div className="row m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-2 mb-2">
          <h6 className="document-content pt-3 pb-2">Company Seal{' '}{customer.toLowerCase() !== "Government" && customer.toLowerCase() !== "workshop" ? "*" : ""}</h6>
          <small className="document-content">--</small>
        </div>
        <div className="col-md-2 col-lg-2 pr-5 mb-2 pl-0 mt-2">
          {companySealFile !== null ?
            <span className="file-name">
              <span className="cursor-pointer" onClick={() => {
                window.open(companySealFile.url)
              }}>{companySealFile.name ? companySealFile.name : companySealFile.selectedFileName}</span>
              <IconButton className="p-1" aria-label="delete"
                disabled={isDisabled()}
                onClick={(e) => {
                  setDocumentType('companySeal');
                  handleopenCloseDocument(true);
                }}>
                <CancelIcon className="file-close-color" fontSize="small" />
              </IconButton>
            </span>
            : <Button variant="contained"
              color="secondary"
              component="label"
              disabled={isDisabled()}
              className="rounded-button-dealer pl-4 pr-4 mt-2">
              Browse File
              <input type="file" hidden
                disabled={isDisabled()}
                onClick={(e: any) => {
                  const { target = {} } = e || {}
                  target.value = ""
                  setTradeLicense("")
                  showErrorMessage('');
                  handleopenCloseErrorSnackbar(false);
                }}
                onChange={(e) =>
                  (e.target && e.target.files) ?
                    onClickBrowseFile('companySealFile', e.target.files[0], setCompanySealFile, isDisabled()) : setCompanySealFile(null)
                } />
            </Button>}
        </div>
      </div>}
      {customer !== "Government" && customer.toLowerCase() !== "workshop" && <div className="row  m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
          <h6 className="document-content">Memorandum Of Association (MOA)</h6>
          <small className="document-sub-content pb-3">Power Of Attorney (POA)</small><br></br>
          <small className="pb-3">If you are not the Owner, it's mandatory to upload.</small>
        </div>
        <div className="col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2">
          {memoRandumRegFile !== null ?
            <div className="file-name">
              <span className="cursor-pointer" onClick={() => {
                window.open(memoRandumRegFile.url)
              }}>{memoRandumRegFile.name ? memoRandumRegFile.name : memoRandumRegFile.selectedFileName}</span>
              <IconButton className="p-1" aria-label="delete"
                disabled={isDisabled()}
                onClick={(e) => {
                  setDocumentType('memorandumOfAssociation');
                  handleopenCloseDocument(true);
                }}>
                <CancelIcon className="file-close-color" fontSize="small" />
              </IconButton>
            </div>
            : <Button
              variant="contained"
              component={memoRandumExpiryDate && isValidMemoRandumExpiryDate ? "label" : "button"}
              color="secondary"
              className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
              disabled={!memoRandumExpiryDate || isValidMemoRandumExpiryDate === false}
            >Browse File
              <input type="file" hidden
                disabled={isDisabled()}
                onClick={(e: any) => {
                  const { target = {} } = e || {}
                  target.value = ""
                  setTradeLicense("")
                  showErrorMessage('');
                  handleopenCloseErrorSnackbar(false);
                }}
                onChange={(e) =>
                  (e.target && e.target.files) ?
                    onClickBrowseFile('memorandumOfAssociation', e.target.files[0], setMemoRandumRegFile) : setMemoRandumRegFile(null)
                } />
            </Button>}
        </div>
        <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
          <KeyboardDatePicker
            views={["year", "month", "date"]}
            openTo="year"
            disablePast
            onError={(e, v) => datePickerValid(e, setIsValidMemoRandumExpiryDate)}
            disabled={isDisabled() ? isDisabled() : memoRandumExpiryDate && memoRandumRegFile ? true : false}
            autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
            format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={memoRandumExpiryDate}
            onChange={(date: Date | null) => {
              props.setDataUpdated(true);
              setMemoRandumExpiryDate(date); !date && setMemoRandumRegFile(null)
            }}
            // onChange={(date: any) => {
            //   let newDate = new Date(date);
            //   if (newDate < new Date()) {
            //     setIsValidMemoRandumExpiryDate(false);
            //   } else {
            //     setMemoRandumExpiryDate(date);
            //     setIsValidMemoRandumExpiryDate(true);
            //   }
            // }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
          />
        </div>
      </div>}
      <div className="row  m-0 p-0 border-bottom">
        <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-3">
          <h6 className="document-content">VAT Certificate Copy{' '}{customer !== "Government" && customer.toLowerCase() !== "workshop" ? "*" : ""}</h6>
          <small className="document-sub-content pb-3">One Time With Issue Date</small>
        </div>
        <div className="col-md-3 col-lg-2 pr-5 mb-2 pl-0 mt-2">
          {vatRegFile !== null ?
            <div className="file-name">
              <span className="cursor-pointer" onClick={() => {
                window.open(vatRegFile.url)
              }}>{vatRegFile.name ? vatRegFile.name : vatRegFile.selectedFileName}</span>
              <IconButton className="p-1" aria-label="delete"
                disabled={isDisabled()}
                onClick={(e) => {
                  // setVatRegFile(null); setVatRegExpiryDate(null);
                  // deleteDealerDocument('vatCertificateCopy', vatRegFile);
                  isDisabled();
                  setDocumentType('vatCertificateCopy');
                  handleopenCloseDocument(true);
                }}>
                <CancelIcon className="file-close-color" fontSize="small" />
              </IconButton>
            </div>
            : <Button
              variant="contained"
              component={vatRegExpiryDate && isValidVatRegExpiryDate ? "label" : "button"}
              color="secondary" className="col-md-12 rounded-button-dealer mt-2 mr-4 px-4"
              disabled={!vatRegExpiryDate || isValidVatRegExpiryDate === false}
            >Browse File
              <input type="file" hidden
                disabled={isDisabled()}
                onClick={(e: any) => {
                  const { target = {} } = e || {}
                  target.value = ""
                  setTradeLicense("")
                  showErrorMessage('');
                  handleopenCloseErrorSnackbar(false);
                }}
                onChange={(e) =>
                  (e.target && e.target.files) ?
                    onClickBrowseFile('vatCertificateFile', e.target.files[0], setVatRegFile) : setVatRegFile(null)
                } />
            </Button>
          }
        </div>
        <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-2">
          <KeyboardDatePicker views={["year", "month", "date"]} openTo="year"
            disableFuture
            onError={(e, v) => datePickerValid(e, setIsValidVatRegExpiryDate)}
            disabled={isDisabled() ? isDisabled() : vatRegExpiryDate && vatRegFile ? true : false}
            autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
            // disabled={isDisabled()}
            format="dd/MM/yyyy" margin="normal" label="Issue Date" value={vatRegExpiryDate}
            onChange={(date: Date | null) => {
              props.setDataUpdated(true);
              setVatRegExpiryDate(date); !date && setVatRegFile(null)
            }}
            // onChange={(date: any) => {
            //   let newDate = new Date(date);
            //   if (newDate > new Date()) {
            //     setIsValidVatRegExpiryDate(false);
            //   } else {
            //     setVatRegExpiryDate(date); !date && setVatRegFile(null)
            //     setIsValidVatRegExpiryDate(true);
            //   }
            // }}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            className={'col-sm-4 my-2 pr-3 rounded-input-dealer cal-icon'}
          />
        </div>
      </div>
      <div className="row m-0 p-0 mt-2">
        <div className="col-12">
          <h6 className="dealer-table-content float-left mt-3">Authorized Signatories</h6>
          <Button
            className="blueActionButton border-left rounded-pill col-sm-3 col-md-3 col-3 float-right font-16 addOrderEnqButton mt-2 mb-3"
            size="small"
            disabled={isDisabled()}
            onClick={() => {
              handleClickOpen(); setIsEdit(false);
              setIsView(false);
              setTypeOfDailog('add')
            }}
            variant="contained" color="primary">
            Add Authorized Signatories
          </Button>
        </div>
      </div>
      {getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.length > 0 && (
        <div className="row m-0 p-0 mt-2 mb-3">
          <div className="col-12">
            <TableContainer>
              <Table
                aria-labelledby="tableTitle"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                />
                <TableBody>
                  <>
                    {getAllSignatoriesData && getAllSignatoriesData.data && getAllSignatoriesData.data.map((newAuthorisedSignatories: any, index: any) => (
                      <TableRow hover tabIndex={-1} key={index}>
                        <TableCell component="th"
                          scope="row" padding="none" className="document-tablecell-data">
                          {newAuthorisedSignatories.firstName}{'  '}{newAuthorisedSignatories.lastName}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.email}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.mobile}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.designation}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {newAuthorisedSignatories.emiratesIdNumber}
                        </TableCell>
                        <TableCell align="left" className="document-tablecell-data">
                          {getAuthorisedFor(newAuthorisedSignatories)}
                        </TableCell>
                        <TableCell align="left">
                          <div className="row">
                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                              {isAdmin && (
                                <Button
                                  onClick={() => {
                                    dispatch(getAddNewAuthorisedSignatories(newAuthorisedSignatories.id, b2bUserId))
                                    handleClickOpen();
                                    setIsView(true);
                                    setIsEdit(true);
                                    setEditData(newAuthorisedSignatories);
                                    setTypeOfDailog('view')
                                  }}
                                  className='text-info'
                                  startIcon={<Visibility />}
                                >
                                  View
                                </Button>
                              )}
                              {isAdmin && (
                                <Button
                                  className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                  startIcon={<EditOutlinedIcon />}
                                  disabled={isDisabled()}
                                  onClick={() => {
                                    dispatch(getAddNewAuthorisedSignatories(newAuthorisedSignatories.id, b2bUserId))
                                    handleClickOpen();
                                    setIsView(false);
                                    setIsEdit(true);
                                    setEditData(newAuthorisedSignatories);
                                    setTypeOfDailog('edit')
                                  }}>
                                  Edit
                                </Button>
                              )}
                              {isAdmin && (
                                <Button
                                  onClick={() => deleteSignatory(newAuthorisedSignatories)}
                                  className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                  disabled={isDisabled()}
                                  startIcon={<DeleteOutlineOutlinedIcon />}
                                >
                                  Delete
                                </Button>
                              )}
                            </ButtonGroup>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                    }
                  </>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      )}
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-6 p-0">
            <div className="row m-0">
              <div className='px-3'></div>
              {isKamOnboarded ? '' : (
                <div className='row col-6 p-0 customer-type-popup'>
                  <div className="p-0">
                    <p className={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? "mt-2 px-5 customer-type-font" : "mt-3 px-5 customer-type-font"}>Customer Type</p>
                    {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? (
                      <p className="customer-header-font ml-5 mt-2 mb-2 text-capitalize">{sessionStorage.getItem('customerType')}{' '} {sessionStorage.getItem('customerOnboardingType')} User</p>
                    ) : (
                      <p className="cursor-pointer px-5 mt-2" onClick={() => { changeDealerType() }}>
                        <span className="customer-header-font text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                          {sessionStorage.getItem('customerOnboardingType') == 'Fleet' || sessionStorage.getItem('customerOnboardingType') == 'fleet' ? 'Corporate (Fleet)' : sessionStorage.getItem('customerOnboardingType')}</span><KeyboardArrowDownIcon className='customer-font' />{'  '}<span className="customer-sub-header-font">Change Type</span>
                      </p>
                    )}
                  </div>
                </div>
              )}
              {dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Submitted' && dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Active' && (
                <div className="col-4 mt-4 p-0 px-3">
                  <Button variant="contained" className="border-left rounded-pill ml-4 w-150p mb-2"
                    onClick={() => saveDocumentDetails(true)}
                  >Save Form</Button>
                </div>
              )}
            </div>
          </div>
          <div className="row col-6 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" className="rounded-pill pl-4 pr-4 w-150p"
                onClick={() => handleBackClick()}
              >Back</Button>
              <Button variant="contained" color="primary" className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2"
                onClick={() => { handleNextClick() }}>Next
              </Button>
              {/* <Button variant="contained" color="primary" className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2"
                  disabled={isDisabled()}
                   onClick={() => {
                     setDeleteMessage("Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost.");
                    handleopenCloseSubmit(true);
                  }}>Submit
                 </Button> */}
            </div>
          </div>
        </div>
      </div>
      <Dialog className="pb-0"
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
        disableEscapeKeyDown
      >
        <AddNewAuthorisedSignatoriesDailog setOpenDialog={handleClose} getNewAuthorisedSignatories={getNewAuthorisedSignatories}
          isEdit={isEdit}
          setOnClickOfSave={setOnClickOfSave}
          editData={editData}
          updateNewAuthorisedSignatories={updateNewAuthorisedSignatories}
          isView={isView} typeofDailog={typeofDailog}
        />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'back') {
            if (props.dataUpdated) {
              props.setTabValue(0);
              props.setDataUpdated(false);
            }
          }
          if (data === 'delete') {
            // if (props.dataUpdated) {
            //   props.setTabValue(0);
            //   props.setDataUpdated(false);
            // } else {
            deleteAuthorised();
            // }
            // deleteCustomerData();
          }
          if (!data) handleopenClose(false);
        }} />
      <DeleteDialogOnboarding
        open={openDeleteDialogTrade}
        text="Are you sure you want to delete this Trade License Details ?"
        handleopenCloseTrade={(data: any) => {
          if (data === 'delete')
            deleteTradeRow();
          if (!data) handleopenCloseTrade(false);
        }} />
      <DeleteDocumentDialog
        open={openDeleteDialogDocument}
        text="Are you sure you want to delete this document ?"
        handleopenCloseDocument={(data: any) => {
          if (data === 'delete')
            deleteDealerDocument();
          if (!data) handleopenCloseDocument(false);
        }} />
      <SubmitDialog
        open={openSubmitDialog}
        handleopenClose={(data: any) => {
          if (data === 'Submit') {
            saveDocumentDetails();
          }
          if (!data) handleopenCloseSubmit(false);
        }} />
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            loadSignatories();
            handleopenCloseConfirmPopup(false);
          }
        }} />
    </Fragment >
  )
}

export default Documents;
