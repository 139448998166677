/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import NotificationIcon from '../../common/components/NotificationIcon';
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import AddIcon from '@material-ui/icons/Add';
import { useRef } from 'react';
import { DataUsageTwoTone, Visibility } from '@material-ui/icons';
import { getShipToPartyAddress, getDealerDetails, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getTransportationZone, saveZone, setCustomerType, getDealingIn, postDeliveryType } from '../../store/actions/editDealer.actions';
import TextField from '@material-ui/core/TextField';
import { MenuItem, Button, Tooltip, FormGroup, createStyles, Theme, withStyles, Switch, makeStyles, createTheme } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './shiptoparty.css';
import { Help } from '@material-ui/icons';
import { updateDealerData, getShipToPartyDropDownValues } from '../../store/actions/dealer.action';
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getDeliveryType, sendToSAP, customerAcceptingDeliveryInUAE, getIBCountryAndSalesRegion } from '../../store/actions/onboard.actions';
import QuotationPropmtDialog from '../Quotation/DeliveryPromptDialog';
import DeactivateDialog from './DeactivateDailog';
import DeactivateAddress from './DeactivateAddress';
import SendToSAPDialog from '../NewDealerRequestsDetails/SendToSAPDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DeactivateAddressInfoDialog from './DeactivateAddressInfoDialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getRefreshDataFromSAP } from "../../store/actions/dealer.action";
import { getNewBrand } from '../../common/components/util';
import { getNewlyAddedBrand } from "../../common/components/util";


const useStyles = makeStyles((theme) => ({
  footer: {
    paddingLeft: 220
  },
  icon: {
    float: "left",
    // margin: "0 10px px 0",
    height: "100%",
    fill: "white",
  },
  close: {
    color: '#000000',
    fontWeight: 900
  },
  correctionText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const orgIdBrandsMapping: any = {
  "2001": "Toyota",
  "2002": "Honda",
  "2003": "Famco",
  "2380": "TE",
  "2195": 'OES'
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

export const ShipToParty = (props: any) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [selectedAddress, setSelectedAddress] = React.useState();
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, isEditDealer, getDataFromSAPOnClickRefresh, shippingDropdownValues }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [selected, setSelected] = React.useState<string>("");
  const [shippingLabelOne, setShippingLabelOne] = React.useState<string>("");
  const [shippingLabelTwo, setShippingLabelTwo] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [selectedZone, setSelectedZone] = React.useState<any>([]);
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [setCustomer, SetCustomer] = React.useState<any>("");
  const [dealerstatus, setStatus] = React.useState<any>("");
  const { dealerDetails, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData, getDeliveryTypeData, deliveryTypes }: any = useSelector((state: any) => state.onboard);
  // const [selectedRadioButton, setSelectedRadioButton] = React.useState<any>(dealerDetails && dealerDetails.data && dealerDetails.data.selectedDeliveryType);
  const [editedAddress, setEditAddress] = React.useState<any>({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const [selectedDeliveryType, setSelectedDeliveryType] = React.useState<string>('DLV');
  const [isCustomerAcceptingDelivery, setIsCustomerAcceptingDelivery] = React.useState(true);
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  const customer: any = sessionStorage.getItem("customerType")
  const [dataUpdatedQuotation, setDataUpdatedQuotation] = React.useState(false);
  const [showDeactivateDialog, setshowDeactivateDialog] = React.useState<boolean>(false)
  const [openDeactivateAddress, setOpenDeactivateAddress] = React.useState<boolean>(false)
  const [shippingAdd, setShippingAdd] = React.useState<any>();
  const [dealerStatus, setdealerStatus] = React.useState<any>("Active")
  const [addressStatus, setAddressStatus] = React.useState<any>("Active")
  const [addressId, setAddressId] = React.useState<any>("")
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [confirmationSentToSAP, setConfirmationSentToSAP] = React.useState<boolean>(false);
  const [addressIndex, setAddressIndex] = React.useState<any>(false);
  const customerOnboardingType: any = sessionStorage.getItem("customerOnboardingType");
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const displayName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.displayName;
  const [loading, setLoading] = useState(false);
  const [isDefaultAdd, setIsDefaultAdd] = React.useState<any>(false);
  const [sendToSAPSuccess, setSendToSAPSuccess] = React.useState<boolean>(false);
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerTierType') ? sessionStorage.getItem('customerTierType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = async () => {
    // setIsEdited(false);
    if ((sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') && selectedDeliveryType === "DLV") {
      // let data: any = await dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      // if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      // data.data.addresses.map((ele: any) => {
      // if (ele && ele.pictureOfDeliveryLocation && ele.pictureOfDeliveryLocation.documentId !== ''
      //   && ele.personToAcceptDelivery && ele.personToAcceptDelivery.length > 0
      //   && ele.tradeLicenseNo !== '' && ele.photoOfPremises && ele.photoOfPremises.documentId !== '') {
      // } else {
      //   setSelectedDeliveryType("SELF-PIC");
      //   dispatch(postDeliveryType({
      //     "id": sapCustomerId,
      //     "deliveryType": "SELF-PIC",
      //     "updatedBy": webApplicationLoginId,
      //     "updatedByRole": "kam"
      //   }))
      // dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      // }
      // })
      // }
    }
    setOpen(false);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(sapCustomerId, data, selected));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        if (selectedDeliveryType === "DLV" && (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer')) {
          await dispatch(postDeliveryType({
            "id": sapCustomerId,
            "deliveryType": 'DLV',
            "updatedBy": webApplicationLoginId,
            "updatedByRole": "kam"
          }))
        }
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(sapCustomerId, data));
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
      setSelected("");
    }
  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);
  }

  const checkShippingDetailsMandotoryFields = (companyBrands: Array<any>) => {
    try {
      let filteredEmptyArray = companyBrands.filter((shippingDetail: any) => shippingDetail.shippingDetails.priority === '' || shippingDetail.shippingDetails.shippingCondition === '' || shippingDetail.shippingDetails.deliveringPlant === '')

      return filteredEmptyArray.length > 0 ? true : false
    } catch (error: any) {
      console.log("Error Handling", error.message);
      return true
    }
  }

  const onClickSendToSAP = async (addressIndex: any) => {
    let address = [...addressList]
    setSelectedAddress(address[addressIndex]);
    let newSelectedAddress = address[addressIndex] || selectedAddress;
    let payloadData: any = {};
    if ((!newSelectedAddress.transportationZone && newSelectedAddress.transportationZone === "") || !newSelectedAddress.shippingLabels || ((newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels.length === 0) || (newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[0] && newSelectedAddress.shippingLabels[0].value === "")) || (checkShippingDetailsMandotoryFields(newSelectedAddress.companyBrands))) {
      setOpenErrorSnackbar(true);
      setApiResponseText('Please fill all the mandatory data')
      setOpenConfirmDialog(false)
      return true
    } else if ((newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[0] && newSelectedAddress.shippingLabels[0].value.length > 20) || (newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[1] && newSelectedAddress.shippingLabels[1].value.length > 20)) {
      setOpenErrorSnackbar(true);
      setApiResponseText('Shipping label value should not more than 20 digits.')
      setOpenConfirmDialog(false)
      return true
    }
    if (newSelectedAddress && newSelectedAddress.type == "New") {
      if (newSelectedAddress.sapCustomerId === '' && newSelectedAddress.dafault && newSelectedAddress.shiftToPartyCustomerNumber === '') {
        newSelectedAddress.showSapId = false;
        props.setSendToSAP(true);
        let payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": false,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": b2bUserId,
          "action": "save_in_db",                       //when ship to party number & sapCustomerId is blank 
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands
        }
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getShipToPartyAddress(b2bUserId));
        } else {
          setApiResponseText(response && response.error);
          handleopenCloseErrorSnackbar(true);
        }
      } else if (newSelectedAddress.sapCustomerId !== '' && !newSelectedAddress.dafault && newSelectedAddress.shiftToPartyCustomerNumber === '') {
        newSelectedAddress.showSapId = true;
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": false,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": sapCustomerId,
          "action": "add_in_sap",
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands
        };
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
          setSendToSAPSuccess(true);
        } else {
          setApiResponseText(response && response.error);
          handleopenCloseErrorSnackbar(true);
          setSendToSAPSuccess(false);
        }
      }
    }
    else {
      if (newSelectedAddress.shiftToPartyCustomerNumber !== '' && newSelectedAddress.sapCustomerId !== '') {
        newSelectedAddress.showSapId = true;
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": true,
          "shiftToPartyCustomerNumber": newSelectedAddress.shiftToPartyCustomerNumber,
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": sapCustomerId,
          "action": "update_in_sap",
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands
        };

        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
        } else {
          setApiResponseText(response && response.error);
          handleopenCloseErrorSnackbar(true);
        }
      } else if (!newSelectedAddress.dafault && newSelectedAddress.shiftToPartyCustomerNumber === '' && newSelectedAddress.sapCustomerId !== '') {
        newSelectedAddress.showSapId = true;
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": true,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": sapCustomerId,
          "action": "update_in_sap",
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands
        };
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
        } else {
          setApiResponseText(response && response.error);
          handleopenCloseErrorSnackbar(true);
        }
      }
    }
    setOpenConfirmDialog(false)
  }

  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }
    let result: any = await dispatch(deleteShipToPartyAddress(sapCustomerId, shippingAddress.id, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
    else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setSuccessMessage(result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.message && result.statusText.data.message.message);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    // if (props.sendToSAP && addressId.shiftToPartyCustomerNumber && addressId.shiftToPartyCustomerNumber !== '') 
    // // setOpenDeleteDialog(true);
    // // setOpenSnackbar(false);
    // if ((!props.sendToSAP) || (addressId && addressId.shiftToPartyCustomerNumber === '')) {
    await dispatch(getSelectedShipToPartyAddress(sapCustomerId, addressId.id));
    setSelected(addressId.id);
    addressId.showSapId = false;
    setOpen(true);
    // }
    // dispatch(getDeliveryType());
  }

  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }


  const onChangeZone = async (e: any, data: any, index: any, sapKeys: string, companyDetailsIndex?: any) => {
    props.setDataUpdated(true);
    /**** TO DO: JAYAPRAKASH COMMENT On the head office address we are updating api on each click.. Let user wait to update the shipping label also... Need to verify this scenerio to Bhargavi or with tester teams */
    let address = [...addressList]
    if (e.target.value && e.target.value && sapKeys === 'transportationZone') {
      setSelectedAddress(address[index]);
      /** CODE WAS COMMENTED TO AVOID CALLING THE SENDTOSAP API FOR HEAD OFFICE NEW ADDRES */

      // if (address[index].type == "New") {
      //   if (address[index].sapCustomerId === '' && address[index].dafault && address[index].shiftToPartyCustomerNumber === '') {
      //     address[index].showSapId = false;
      //     let payloadData = {
      //       "addressId": address[index].id,
      //       "isExisting": false,
      //       "shiftToPartyCustomerNumber": "",
      //       "transportationZoneCode": getZoneCode(e.target.value),
      //       "transportationZone": e.target.value,
      //       "id": sapCustomerId,
      //       "action": "save_in_db"                       //when ship to party number & sapCustomerId is blank 
      //     }
      //     let response: any = await dispatch(sendToSAP(payloadData));
      //     if (response && response.message && response.message.status === 'S') {
      //       props.setDataUpdated(false);
      //       props.setSendToSAP(false);
      //       setApiResponseText(response.message.message);
      //       handleopenCloseSnackbar(true);
      //       dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      //     } else {
      //       setApiResponseText(response && response.error);
      //       handleopenCloseErrorSnackbar(true);
      //     }
      //   } else {
      //     address[index].showSapId = true;
      //     props.setSendToSAP(true);
      //     address[index].transportationZone = e.target.value;
      //     address[index].transportationZoneCode = getZoneCode(e.target.value);
      //   }
      // }
      // else {
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].transportationZone = e.target.value;
      address[index].transportationZoneCode = getZoneCode(e.target.value);
      // }
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && e.target.value.length <= 20 && sapKeys === 'shippingLabelOne') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      if (address && address[index] && address[index].shippingLabels && address[index].shippingLabels.length > 0 && address[index].shippingLabels.find((ele: any) => ele.name === "SORT1")) {
        address[index].shippingLabels[0].value = e.target.value
      } else {
        address[index].shippingLabels = [
          {
            "name": "SORT1",
            "value": e.target.value
          },
          {
            "name": "SORT2",
            "value": ""
          }
        ]
      }
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && e.target.value.length <= 20 && sapKeys === 'shippingLabelTwo') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      if (address && address[index] && address[index].shippingLabels && address[index].shippingLabels.length > 0 && address[index].shippingLabels.find((ele: any) => ele.name === "SORT2")) {
        address[index].shippingLabels[1].value = e.target.value
      } else {
        address[index].shippingLabels = [
          {
            "name": "SORT1",
            "value": ''
          },
          {
            "name": "SORT2",
            "value": e.target.value
          }
        ]
      }
      setAddressList(address);

    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'priority') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.priority = e.target.value
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'shippingCondition') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.shippingCondition = e.target.value
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'maxPartialDeliveriesPerItem') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.maxPartialDeliveriesPerItem = e.target.value
      setAddressList(address);
    }
    else if (!e.target.value && sapKeys === 'transportationZone') {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  const onChangeShiftToPartyCustomerNumber = async (e: any, data: any, index: any) => {
    props.setDataUpdated(true);
    let address = [...addressList]
    if (e.target.value && e.target.value) {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].shiftToPartyCustomerNumber = e.target.value;
      setAddressList(address);
    }
    else if (!e.target.value) {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }


  const onChangeNew = (e: any, data: any, index: any) => {
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      setAddressList(address);
    }
  }

  const handleChange = async (event: any) => {
    setSelectedDeliveryType(event.target.value);
    // if (event.target.value === 'SELF-PIC') {
    await dispatch(postDeliveryType({
      "id": sapCustomerId,
      "deliveryType": event.target.value,
      "updatedBy": webApplicationLoginId,
      "updatedByRole": "kam"
    }))
    // }
  };

  const saveZoneAddress = async () => {
    if (props.sendToSAP && !sendToSAPSuccess) {
      setOpenDeleteDialog(true);
    } else {
      setIsValid(true);
      let isValidTemp = true
      let address = [...addressList];
      let data: any = {
        addresses: []
      };
      address.map((add: any) => {
        if (add.transportationZone && add.transportationZoneCode && add.shiftToPartyCustomerNumber) {
          data.addresses.push({
            id: add.id,
            transportationZone: add.transportationZone,
            transportationZoneCode: add.transportationZoneCode,
            shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber,
            isExisting: add.isExisting,
          })
        } else {
          isValidTemp = false;
          setIsValid(false);
        }
      });
      if ((sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') && selectedDeliveryType === "DLV") {
        let isAllDataExist: any = [true];
        dealerDetails && dealerDetails.data && dealerDetails.data.addresses && dealerDetails.data.addresses.map((ele: any) => {

          // if (!ele.region) {
          //   isValidTemp = false
          // }
          // if (!ele.tradeLicenseNo) {
          //   isValidTemp = false
          // } 
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (!ele.pictureOfDeliveryLocation.documentId) {
              isAllDataExist.push(false);
            } if (!ele.photoOfPremises.documentId) {
              isAllDataExist.push(false);

            }
          }
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (ele.personToAcceptDelivery.length > 0) {
              ele.personToAcceptDelivery.map((ele: any) => {
                if (!ele.contactNumber) {
                  isAllDataExist.push(false);
                }
                if (!ele.documentId) {
                  isAllDataExist.push(false);

                }
                if (!ele.expiryDate) {
                  isAllDataExist.push(false);

                }
                if (!ele.name) {
                  isAllDataExist.push(false);

                }
                // if (!ele.emailId) {
                //   isValidTemp = false
                // }
              })
            }
          }
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (ele.personToAcceptDelivery.length == 0) {
              isAllDataExist.push(false);
            }
          }
          // if (!ele.authorityLetter.documentId) {
          //   isValidTemp = false
          // }
        })
        let isValid = isAllDataExist && isAllDataExist && isAllDataExist.every((p: any) => p === true);
        if (!isValid) isValidTemp = false;
      } else if (sessionStorage.getItem('customerOnboardingType') === 'Government' || sessionStorage.getItem('customerOnboardingType') === 'government' || sessionStorage.getItem('customerOnboardingType') === 'Fleet' || sessionStorage.getItem('customerOnboardingType') === 'fleet' || sessionStorage.getItem('customerOnboardingType') === 'Dealer' || sessionStorage.getItem('customerOnboardingType') === 'dealer') {
        dealerDetails && dealerDetails.data && dealerDetails.data.addresses && dealerDetails.data.addresses.map((ele: any) => {
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (!ele.region) {
              isValidTemp = false
            }
          }
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (!ele.tradeLicenseNo) {
              isValidTemp = false
            } if (!ele.pictureOfDeliveryLocation.documentId
            ) {
              isValidTemp = false
            } if (!ele.photoOfPremises.documentId) {
              isValidTemp = false
            } if (ele.personToAcceptDelivery.length >= 1
            ) {
              ele.personToAcceptDelivery.map((ele: any) => {
                if (!ele.contactNumber) {
                  isValidTemp = false
                }
                if (!ele.documentId) {
                  isValidTemp = false
                }
                if (!ele.expiryDate) {
                  isValidTemp = false
                }
                if (!ele.name
                ) {
                  isValidTemp = false
                }
                if (!ele.emailId) {
                  isValidTemp = false
                }
              })
            }
          }
          if (firstAddressCheck === true && isDefaultAdd === true) {
            if (ele.personToAcceptDelivery.length == 0) {
              isValidTemp = false
            } if (!ele.authorityLetter.documentId
            ) {
              isValidTemp = false
            }
          }
        })
      }

      if (isValidTemp && address.length === data.addresses.length) {
        // let savedZone: any = await dispatch(saveZone(sapCustomerId, data));
        // if (savedZone && savedZone.message && savedZone.message.status === 'S') {
        if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') {
          await dispatch(postDeliveryType({
            "id": sapCustomerId,
            "deliveryType": selectedDeliveryType,
            "updatedBy": webApplicationLoginId,
            "updatedByRole": "kam"
          }))
        }
        props.setDataUpdated(false);
        props.setSendToSAP(false);
        props.setselectedTab(3);
        props.setSuccessMessage("Customer details updated successfully");
        props.handleopenCloseSnackbar(true);
        setDataUpdatedQuotation(false);
        // } else {
        //   setApiResponseText(savedZone.error);
        //   handleopenCloseErrorSnackbar(true);
        //   setDataUpdatedQuotation(false);
        // }
      } else {
        if (isValidTemp && address.length === data.addresses.length && (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') && selectedDeliveryType === "DLV") {
          setDataUpdatedQuotation(false);
        } else if (isValidTemp && (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') && selectedDeliveryType === "DLV") {
          setDataUpdatedQuotation(true);
        } else if (isValidTemp && (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') && selectedDeliveryType !== "DLV") {
          setDataUpdatedQuotation(false);
        } else {
          // props.setDataUpdated(false);
          // props.setSendToSAP(false);
          // props.setselectedTab(3);
          setApiResponseText('Please fill all mandatory fields');
          handleopenCloseErrorSnackbar(true);
        }
      }
    }
  }

  useEffect(() => {
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(false);
      if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') {
        dispatch(getDeliveryType());
      }
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      props.setDataUpdated(true);
    }
  }, [saveNewAdress]);

  useEffect(() => {
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message);
      handleopenCloseSnackbar(true);
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'))
      let address = [...addressList];
      if (address && address.length > 0) {
        const index = address.findIndex((obj) => obj.id === updateAddressData.data.data.id);
        setAddressIndex(index);
        if ((address[index] && address[index].shiftToPartyCustomerNumber) || (updateAddressData.data.data && updateAddressData.data.data.shiftToPartyCustomerNumber)) {
          address[index].showSapId = false;
        }
        setAddressList(address);
      }
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  useEffect(() => {
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') {
        dispatch(getDeliveryType());
      }
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadData()
  }, []);

  const loadData = async () => {
    const data: any = await dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      setMasterCompanyBrands(data.data.masterCompanyBrands);
    }
  }

  useEffect(() => {
    if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') {
      dispatch(getDeliveryType());
    }
    // dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    if (customerOnboardingType === "Int. Business") {
      dispatch(getIBCountryAndSalesRegion());
    }
    if (zoneData && zoneData.data && zoneData.data.length > 0) {
    } else {
      dispatch(getTransportationZone(b2bUserId));
    }
    console.log("-----", shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.deliveryPriority && shippingDropdownValues.data.data.deliveryPriority.length > 0)
    // if (shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.deliveryPriority && shippingDropdownValues.data.data.deliveryPriority.length > 0) {
    if (sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer') {
      dispatch(getShipToPartyDropDownValues('Workshop'));
    } else {
      dispatch(getShipToPartyDropDownValues());
    }
    // }

    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
  }, []);

  useEffect(() => {
    if (dealerDetails && dealerDetails.data && !isEdited) {
      if (dealerDetails.data.status) {
        setStatus(dealerDetails.data.status)
      }
      if (dealerDetails.data.addresses && dealerDetails.data.addresses.length > 0) {
        // setMasterCompanyBrands(dealerDetails.data.masterCompanyBrands);
        if (addressIndex && addressIndex > 0) dealerDetails.data.addresses[addressIndex].showSapId = false;
        setAddressList(setaddressData(dealerDetails.data.addresses));
        setSelectedDeliveryType(dealerDetails.data.deliveryType ? dealerDetails.data.deliveryType : "DLV");
        setSapId(dealerDetails.data.sapCustomerId);
        props.setSendToSAP(false);
      }
    }
    if (dealerDetails && dealerDetails.data && dealerDetails.data.addresses && dealerDetails.data.addresses.length > 0) {
      // setMasterCompanyBrands(dealerDetails.data.masterCompanyBrands);
      if (addressIndex && addressIndex > 0) dealerDetails.data.addresses[addressIndex].showSapId = false;
      setAddressList(setaddressData(dealerDetails.data.addresses));
      setSapId(dealerDetails.data.sapCustomerId);

      let defaultAddress = dealerDetails.data.addresses.find((address: any) => address.dafault)
      if (defaultAddress && (dealerDetails && dealerDetails.data && dealerDetails.data.hasOwnProperty('acceptDeliveryInUAE'))) {
        setIsDefaultUAE(defaultAddress.country)
        setSalesRegion(defaultAddress.salesRegion)
        if (isDefaultUAE === "" || isDefaultUAE !== defaultAddress.country) {
          customerAcceptDelivery(dealerDetails.data.acceptDeliveryInUAE, defaultAddress.salesRegion, defaultAddress.country, false)
        }
      }

      if (updateAddressData && updateAddressData.data && updateAddressData.data.data && updateAddressData.data.data.id) {
        let address = [...dealerDetails.data.addresses];
        const index = address.findIndex((obj) => obj.id === updateAddressData.data.data.id);
        if (updateAddressData.data.data && updateAddressData.data.data.shiftToPartyCustomerNumber === '') {
          if (index !== -1) address[index].showSapId = true;
          props.setSendToSAP(true);
        }
        setAddressList(address);
        props.setDataUpdated(true);
      }
    }

    // if (getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.data && !isEdited) {
    //   if (getDataFromSAPOnClickRefresh.data.status) {
    //     setStatus(getDataFromSAPOnClickRefresh.data.status)
    //   }
    //   if (getDataFromSAPOnClickRefresh.data.addresses && getDataFromSAPOnClickRefresh.data.addresses.length > 0) {
    //     if (addressIndex && addressIndex > 0) getDataFromSAPOnClickRefresh.data.addresses[addressIndex].showSapId = false;
    //     setAddressList(setaddressData(getDataFromSAPOnClickRefresh.data.addresses));
    //     setSelectedDeliveryType(getDataFromSAPOnClickRefresh.data.deliveryType ? getDataFromSAPOnClickRefresh.data.deliveryType : "DLV");
    //     setSapId(getDataFromSAPOnClickRefresh.data.sapCustomerId);
    //     props.setSendToSAP(false);
    //   }
    // }
    // if (getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.data && getDataFromSAPOnClickRefresh.data.addresses && getDataFromSAPOnClickRefresh.data.addresses.length > 0) {
    //   if (addressIndex && addressIndex > 0) getDataFromSAPOnClickRefresh.data.addresses[addressIndex].showSapId = false;
    //   setAddressList(setaddressData(getDataFromSAPOnClickRefresh.data.addresses));
    //   setSapId(getDataFromSAPOnClickRefresh.data.sapCustomerId);

    //   let defaultAddress = getDataFromSAPOnClickRefresh.data.addresses.find((address: any) => address.dafault)
    //   if (defaultAddress && (getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.data && getDataFromSAPOnClickRefresh.data.hasOwnProperty('acceptDeliveryInUAE'))) {
    //     setIsDefaultUAE(defaultAddress.country)
    //     setSalesRegion(defaultAddress.salesRegion)
    //     if (isDefaultUAE === "" || isDefaultUAE !== defaultAddress.country) {
    //       customerAcceptDelivery(getDataFromSAPOnClickRefresh.data.acceptDeliveryInUAE, defaultAddress.salesRegion, defaultAddress.country, false)
    //     }
    //   }

    // if (updateAddressData && updateAddressData.data && updateAddressData.data.data && updateAddressData.data.data.id) {
    //   let address = [...getDataFromSAPOnClickRefresh.data.addresses];
    //   const index = address.findIndex((obj) => obj.id === updateAddressData.data.data.id);
    //   if (updateAddressData.data.data && updateAddressData.data.data.shiftToPartyCustomerNumber === '') {
    //     if (index !== -1) address[index].showSapId = true;
    //     props.setSendToSAP(true);
    //   }
    //   setAddressList(address);
    //   props.setDataUpdated(true);
    // }
    // }
  }, [dealerDetails])

  useEffect(() => {
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }

  }, [getSelectedAddress])

  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (element.isExisting == false) {
        element.type = "New";
        setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status);
        element.isExisting = false;
      } else {
        element.type = "Existing";
        setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status);
        element.isExisting = true;
      }
    });
    return data;
  }

  const handleResponse = (isError: any, message: any) => {
    if (isError) {
      setOpenErrorSnackbar(true)
      setApiResponseText(message)
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));

    } else {
      setOpenSnackbar(true)
      setOpenErrorSnackbar(false)
      setApiResponseText(message)
      dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    }
  }

  const handleCustomerAcceptingDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    customerAcceptDelivery(event.target.checked, dealerDetails.data.salesRegion, isDefaultUAE, true)
  };

  const customerAcceptDelivery = async (isCustomerAcceptingDeliveryValue: boolean, salesRegion: string = "", isDefaultUAE: string, updateAPI: boolean) => {
    props.freeZoneProps({
      isDefaultUAE,
      salesRegion,
      isCustomerAcceptingDelivery: isCustomerAcceptingDeliveryValue,
      isIntBuisness: customerOnboardingType === "Int. Business" ? true : false
    })
    setIsCustomerAcceptingDelivery(isCustomerAcceptingDeliveryValue);
    if (updateAPI) {
      let data: any = await dispatch(customerAcceptingDeliveryInUAE({
        "id": b2bUserId, "typeOfData": "acceptDeliveryInUAE", "updatedBy": sessionStorage.getItem('webApplicationLoginId'),
        "updatedByRole": "FSE",
        "acceptDeliveryInUAE": isCustomerAcceptingDeliveryValue
      }));
      if (data && data.message && data.message.status === 'S') {
        handleopenClose(false);
        setSuccessMessage("Outsidefreezone is updated")
        handleopenCloseSnackbar(true);
      } else {
        setSuccessMessage(data.error);
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false)
        }, 2000);
        handleopenCloseSnackbar(true);
      }
    }

  }
  return (
    <Fragment>
      {dealerDetails && dealerDetails.loading && <FullscreenLoader />}
      {getDeliveryTypeData && getDeliveryTypeData.loading && <FullscreenLoader />}
      {getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {loading && <FullscreenLoader />}

      {/* {isEditDealer && (
        <div className="col-12">
          <div className="d-flex align-items-center mb-2">
            <div className="row-space-start ml-auto">
              <Button
                className="text-info disabledCustomclass"
                startIcon={<AddIcon className="font-size-18" />}
                // disabled={dealerDetails && dealerDetails.err && dealerDetails.err.error}
                disabled
                onClick={() => {
                  handleClickOpen();
                }}
              ><span className="onshiftoparty-font">Add New Address
                </span>
              </Button> 
            </div>
          </div>
        </div>
      )} */}
      <div className="row">
        {(dealerDetails && dealerDetails.err) && (
          <div className="col-12">
            <Alert severity="info">Please submit Complete Registration details prior to Shipping Address !</Alert>
          </div>
        )}
        {customerOnboardingType === "Int. Business" && (
          <FormGroup className="mt-4 pl-4" aria-label="position" row>
            <FormControlLabel
              control={<AntSwitch color="primary" checked={isCustomerAcceptingDelivery} onChange={handleCustomerAcceptingDelivery} />}
              label={<h6 className={`${classes.correctionText}`}>Is the customer accepting deliveries within UAE to a PDC authorized delivery address ?&nbsp;</h6>}
              labelPlacement="start"
              disabled
            />
          </FormGroup>
        )}
        {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer' ?
          <>
            <div className="row col-12 mb-2 pl-3">
              <div className="dealer-table-content float-right px-3">
                <FormControl component="fieldset" name="customer-type" className='document-content'>
                  <h6 className="page-title ml-4 mt-3 mb-2" style={{ fontSize: "15px" }}>Select Delivery Type{' '}*</h6>
                  <RadioGroup className="mt-1 mb-1 ml-4" row value={selectedDeliveryType} onChange={handleChange}>
                    {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                      if (ele.value === "SELF-PIC") {
                        return (<>
                          <FormControlLabel
                            value={ele.value}
                            control={<Radio color="primary" />}
                            label={ele.title}
                          />
                        </>)
                      } else if (ele.value === "DLV") {
                        return (<>
                          <FormControlLabel
                            value={ele.value}
                            control={<Radio color="primary" />}
                            label={ele.title}
                          />
                        </>)
                      }
                    })}
                  </RadioGroup>
                </FormControl>
                {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                  if (ele.title === "Self-pickup" && selectedDeliveryType === "SELF-PIC") {
                    return (<>
                      <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Self-pickup" && selectedDeliveryType === "SELF-PIC" ? ele.description : ""}</h6>
                    </>)
                  } else if (ele.value === "DLV") {
                    return (<>
                      <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Delivery" && selectedDeliveryType !== "SELF-PIC" ? ele.description : ""}</h6>
                    </>)
                  }
                })}
              </div>
            </div>
          </>
          : ""}
        {/* {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0 pl-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex flex-row">
                  <div className="card-body border-bottom pt-1 d-flex flex-row" key={index}>
                    <div className="w-75 d-inline-block pt-2 ">
                      <div className="shipAddTitle">{shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;

                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                      <div className="shipAdd mt-2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                      {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="shipAdd">Contact Telephone Number: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div> : <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>}
                      <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
                      {isEditDealer && (
                        <div className="px-0 col-md-3 col-lg-5" style={{ display: "flex" }}>
                          <div className="px-0 col-md-3 col-lg-4 typeOfCustomer">
                            <TextField select id="New"
                              required
                              disabled
                              // error={!shipppingAddress.transportationZone && !isValid}
                              value={shipppingAddress.type}
                              onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              variant="outlined" margin="dense" >
                              {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                <MenuItem value={zone}> {zone} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && (!shipppingAddress.transportationZone && !shipppingAddress.shiftToPartyCustomerNumber) ?
                            'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                            {!shipppingAddress.isExisting ?
                              <TextField select id="transportationZone"
                                required
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                error={!shipppingAddress.transportationZone && !isValid}
                                value={shipppingAddress.transportationZone}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Transportation Zone" variant="outlined" margin="dense" >
                                {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                  <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                ))}
                              </TextField>
                              : <TextField type="text" id="shipToParty"
                                required
                                error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                value={shipppingAddress.shiftToPartyCustomerNumber}
                                disabled={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index)}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                label="Ship To Party Customer number" variant="outlined" margin="dense" >
                              </TextField>
                            }
                          </Tooltip>
                          {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && (!shipppingAddress.transportationZone && !shipppingAddress.shiftToPartyCustomerNumber) && (
                            <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                              'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                              <Button
                                className="cursor-normal mb-1 text-info"
                                startIcon={<Help />}
                              >
                              </Button>
                            </Tooltip>
                          )}
                          {confirmationSentToSAP === true ?
                            <div className="px-0 col-md-3 col-lg-8 typeOfCustomer">
                              <Button variant="contained" onClick={() => { setOpenConfirmDialog(true) }} color="primary" className="blueActionButton border-left rounded-pill ml-4 mt-2">Sent to SAP</Button>
                            </div>
                            : ""}
                        </div>
                      )}
                    </div>
                    <div className='text-right col-6 col-lg-6 col-md-6 d-flex flex-row mt-5'>
                      {shipppingAddress.dafault && isEditDealer && (
                        <div className="markDefaultIcon  text-right">
                          <Button
                            className="text-info"
                            startIcon={<EditOutlinedIcon />}
                            disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                            onClick={() => {
                              onClickEditAddress(shipppingAddress.id)
                            }}><span className="onshiftoparty-font">Edit</span>
                          </Button>

                        </div>
                      )}
                      {shipppingAddress.dafault && !isEditDealer && (
                        <div className="markDefaultIcon w-25 d-inline-block text-right">
                          <div>
                            <Button
                              className="text-info"
                              startIcon={<Visibility />}
                              onClick={() => {
                                onClickEditAddress(shipppingAddress.id)
                              }}><span className="onshiftoparty-font">View</span>
                            </Button>


                          </div>

                        </div>
                      )}
                      {!shipppingAddress.dafault && isEditDealer && (
                        <div className="markDefaultIcon  d-inline-block text-right">
                          <Button
                            className="text-info"
                            startIcon={<EditOutlinedIcon />}
                            disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                            onClick={() => {
                              onClickEditAddress(shipppingAddress.id)
                            }}><span className="onshiftoparty-font">Edit</span>
                          </Button>
                          <Button
                            onClick={() => onClickDeleteAddress(shipppingAddress)}
                            className="text-info "
                            startIcon={<DeleteIcon />}

                          > <span className="onshiftoparty-font">Delete</span>
                          </Button>
                          <Button variant="contained"
                            onClick={() => {
                              setAddressId(shipppingAddress.id)
                            
                                setOpenDeactivateAddress(true);
                                setOpenSnackbar(false)
                                setAddressStatus(shipppingAddress.isDeactivated ? "Deactive" : "Active")
                              
                            }
                            }
                            className={`${shipppingAddress.isDeactivated?"greenActionButton":"redActionButton"} border-left rounded-pill ml-4 mb-2`} >{shipppingAddress.isDeactivated ? "Activate Address" : "Deactivate Address"}</Button>
                        </div>

                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ))} */}
      </div>
      <div className=''>
        <div className="">
          {/* {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
        )} */}
          {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'tpcdealer' ?
            <div className="border-bottom"></div>
            : ""}
          <div className="">
            {
              addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => {
                return <>
                  <div className="col-12 row m-0">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 border-bottom py-1">
                      <div className="w-50 d-inline-block pt-2">
                        <div className="shipAddTitle">
                          {shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;
                          {shipppingAddress.dafault && (
                            <Button
                              className="defaultAddressButton cursor-default"
                              size="small"
                              variant="contained"
                              color="secondary"
                            >Head Office</Button>
                          )}
                        </div>
                      </div>
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <div className="">
                          {/* {shipppingAddress.dafault && isEditDealer && (
                            <div className="markDefaultIcon  d-inline-block text-right">
                              <Button
                                className="text-info"
                                startIcon={<Visibility />}
                                disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                                onClick={() => {
                                  onClickEditAddress(shipppingAddress)
                                }}><span className="onshiftoparty-font">View</span>
                              </Button>
                            </div>
                          )} */}
                          {shipppingAddress.shiftToPartyCustomerNumber ?
                            <Tooltip title="Pull real-time information from SAP." placement="top">
                              <Button
                                className="mr-2 text-info"
                                startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                                onClick={async () => {
                                  setLoading(true);
                                  let data: any = await dispatch(getRefreshDataFromSAP("refreshAddress", sapCustomerId, shipppingAddress.shiftToPartyCustomerNumber, webApplicationLoginId, displayName.replace(/\s+/g, ' '), shipppingAddress.id));
                                  setLoading(false);
                                  if (data && data.status === 400) {
                                    setApiResponseText(data && data.error);
                                    handleopenCloseErrorSnackbar(true);
                                  } else {
                                    dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
                                    handleopenCloseErrorSnackbar(false);
                                    setAddressList(data && data.data && data.data.addresses);
                                    setApiResponseText(data && data.message && data.message.message);
                                    handleopenCloseSnackbar(true);
                                  }
                                }}
                              ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Refresh
                                </span>
                              </Button>
                            </Tooltip>
                            : ''}
                          {shipppingAddress.dafault && !isEditDealer && (
                            <div className="markDefaultIcon  d-inline-block text-right">
                              <div>
                                <Button
                                  className="text-info"
                                  startIcon={<Visibility />}
                                  onClick={() => {
                                    setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                                    onClickEditAddress(shipppingAddress)
                                  }}><span className="onshiftoparty-font">View</span>
                                </Button>
                              </div>
                            </div>
                          )}
                          {isEditDealer && (
                            <div className="markDefaultIcon  d-inline-block text-right">
                              <Button
                                className={(dealerstatus && dealerstatus === "Deactive") || (shipppingAddress.isDeactivated) ? "text-info disabledCustomclass" : 'text-info'}
                                startIcon={<EditOutlinedIcon />}
                                disabled={(dealerstatus && dealerstatus === "Deactive") || (shipppingAddress.isDeactivated) ? true : false}
                                onClick={() => {
                                  setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                                  onClickEditAddress(shipppingAddress)
                                }}><span className="onshiftoparty-font">Edit</span>
                              </Button>
                              {!shipppingAddress.dafault && shipppingAddress.shiftToPartyCustomerNumber
                                && <Button variant="contained"
                                  onClick={() => {
                                    setAddressId(shipppingAddress.id)
                                    sessionStorage.setItem("shipToPartCustomerNo", shipppingAddress.shiftToPartyCustomerNumber)
                                    setOpenDeactivateAddress(true);
                                    setOpenSnackbar(false)
                                    setAddressStatus(shipppingAddress.isDeactivated ? "Deactive" : "Active")
                                  }
                                  }
                                  className={`${shipppingAddress.isDeactivated ? "greenActionButton" : "redActionButton"} border-left rounded-pill ml-4 mb-2`} >{shipppingAddress.isDeactivated ? "Activate Address" : "Deactivate Address"}</Button>}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="shipAdd pt-n2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                        {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="shipAdd">Contact Telephone Number: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div> : <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>}
                        <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
                        <div className="shipAdd">Ship To Party Customer ID:{" "}{shipppingAddress.shiftToPartyCustomerNumber ? shipppingAddress.shiftToPartyCustomerNumber : '-'}</div>
                      </div>
                      <div className='card-body row p-0 m-0 mt-2'>
                        {isEditDealer && (
                          <div className="pl-0 col-12 width" style={{ display: "flex" }}>
                            <div className="px-0 col-md-1 col-lg-1 col-1 typeOfCustomer">
                              <TextField select id="New"
                                required
                                disabled
                                // disabled={shipppingAddress.shiftToPartyCustomerNumber ? true : false}
                                value={shipppingAddress.type}
                                onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                variant="outlined" margin="dense" >
                                {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                  <MenuItem value={zone}> {zone} </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer">
                              <Tooltip title={shipppingAddress && !shipppingAddress.transportationZone && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customerOnboardingType !== "Int. Business" && selectedDeliveryType !== "SELF-PIC" ?
                                'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                                <TextField select id="transportationZone"
                                  required
                                  // disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customerOnboardingType !== "Int. Business" || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                  disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                  error={!shipppingAddress.transportationZone && !isValid}
                                  value={shipppingAddress.transportationZone}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'transportationZone')}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Transportation Zone" variant="outlined" margin="dense" >
                                  {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                    <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                  ))}
                                </TextField>
                              </Tooltip>
                            </div>
                            {(shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber !== "") || (shipppingAddress && shipppingAddress.type === 'Existing') ?
                              <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer">
                                <TextField id="shiftToPartyCustomerNumber"
                                  required disabled={shipppingAddress.shiftToPartyCustomerNumber ? true : false}
                                  error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                  value={shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber}
                                  onChange={(e: any) => onChangeShiftToPartyCustomerNumber(e, shipppingAddress, index)}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Ship To Party Customer ID" variant="outlined" margin="dense" >
                                </TextField>
                                {/* {shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber !== "" && (
                                  <CheckCircleIcon className="order-placed-tick mt-3 ml-1" fontSize="small" />
                                )} */}
                              </div>
                              : ''}
                            <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer">
                              <Tooltip title={'This is the name printed on the shipping label of the handling unit'} placement="top">
                                <TextField type="text" id="ShippingLabelName1"
                                  required
                                  disabled={shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customerOnboardingType !== "Int. Business" || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                  value={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[0] && shipppingAddress.shippingLabels[0].value}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingLabelOne')}
                                  helperText={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[0] && shipppingAddress.shippingLabels[0].value.length > 20 ? 'should not more than 20 digits.' : ''}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Shipping Label Name 1" variant="outlined" margin="dense" >
                                </TextField>
                              </Tooltip>
                            </div>
                            <div className="pl-0 col-md-2 col-lg-2 col-2 typeOfCustomer">
                              <Tooltip title={'This is the name printed on the shipping label of the handling unit'} placement="top">
                                <TextField type="text" id="ShippingLabelName2"
                                  disabled={shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customerOnboardingType !== "Int. Business" || dealerstatus && dealerstatus === "Deactive" ? true : false}
                                  value={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[1] && shipppingAddress.shippingLabels[1].value}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingLabelTwo')}
                                  helperText={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[1] && shipppingAddress.shippingLabels[1].value.length > 20 ? 'should not more than 20 digits.' : ''}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Shipping Label Name 2" variant="outlined" margin="dense" >
                                </TextField>
                              </Tooltip>
                            </div>
                            {/* {shipppingAddress && !shipppingAddress.transportationZone && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customerOnboardingType !== "Int. Business" && (
                              <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                                'Please fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                                <Button
                                  className="cursor-normal mb-1 text-info"
                                  startIcon={<Help />}
                                >
                                </Button>
                              </Tooltip>
                            )} */}
                            {/* {shipppingAddress.showSapId === true && !shipppingAddress.shiftToPartyCustomerNumber ?
                                <div className="px-0 col-md-3 col-lg-8 typeOfCustomer">
                                  <Button variant="contained" onClick={() => {
                                    setOpenConfirmDialog(true);
                                    setAddressIndex(index);
                                    let address = [...addressList]
                                    setSelectedAddress(address[index]);
                                  }} color="primary" className="blueActionButton border-left rounded-pill ml-4 mt-2">Sent to SAP</Button>
                                </div>
                                : ""} */}

                            {/* {confirmationSentToSAP === true ?
                                  <div className="px-0 col-md-3 col-lg-8 typeOfCustomer">
                                    <Button variant="contained" onClick={() => { setOpenConfirmDialog(true) }} color="primary" className="blueActionButton border-left rounded-pill ml-4 mt-2">Sent to SAP</Button>
                                  </div>
                                  : ""} */}
                          </div>
                        )}
                        {/* {( */}
                        {/* <div className="px-0 col-md-3 col-lg-3 col-3"> */}
                        {/* Rchanged thie sap showing button condition from shipppingAddress.showSapId === true && !shipppingAddress.shiftToPartyCustomerNumber this for testing*/}

                        {/* </div> */}
                        {/* )} */}
                      </div>
                      {((shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE") || (shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE")) &&
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2 pl-0">
                          <div className='row bg-gray-200 p-2 m-0'>
                            <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700'><span className='ml-2'>Brand Name</span></div>
                            <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Delivery Priority</div>
                            <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Shipping conditions</div>
                            <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Delivering Plant</div>
                            <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Max. partial deliveries</div>
                          </div>
                          {shipppingAddress && shipppingAddress.companyBrands && shipppingAddress.companyBrands.length > 0 && shipppingAddress.companyBrands.map((companyBrand: any, companyBrandIndex: any) => (
                            <div className='row m-0 p-2 border-bottom'>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2 mt-3 pl-0'>{sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && sessionStorage.getItem('customerOnboardingType') !== 'tpcdealer' ?  getNewlyAddedBrand(orgIdBrandsMapping[companyBrand.organizationId]) : getNewBrand(orgIdBrandsMapping[companyBrand.organizationId])}</div>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                                <TextField select id="priority"
                                  required
                                  disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                  error={!companyBrand.shippingDetails.priority && !isValid}
                                  value={companyBrand.shippingDetails.priority}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'priority', companyBrandIndex)}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Delivery Priority" variant="outlined" margin="dense" >
                                  {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.deliveryPriority && shippingDropdownValues.data.data.deliveryPriority.length > 0 && shippingDropdownValues.data.data.deliveryPriority.map((dp: any, index: any) => (
                                    <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                                <TextField select id="shippingCondition"
                                  required
                                  disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                  error={!companyBrand.shippingDetails.shippingCondition && !isValid}
                                  value={companyBrand.shippingDetails.shippingCondition}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingCondition', companyBrandIndex)}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Shipping conditions" variant="outlined" margin="dense" >
                                  {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.shippingConditions && shippingDropdownValues.data.data.shippingConditions.length > 0 && shippingDropdownValues.data.data.shippingConditions.map((dp: any, index: any) => (
                                    <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                                <TextField id="deliveryPlant"
                                  required
                                  disabled
                                  value={companyBrand.shippingDetails.deliveringPlant}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Delivering Plant" variant="outlined" margin="dense" >
                                </TextField>
                              </div>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                                <TextField select id="maxPartialDeliveriesPerItem"
                                  disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDeliveryType === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                  // error={!companyBrand.shippingDetails.maxPartialDeliveriesPerItem && !isValid}
                                  value={companyBrand.shippingDetails.maxPartialDeliveriesPerItem}
                                  onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'maxPartialDeliveriesPerItem', companyBrandIndex)}
                                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                  label="Max. partial deliveries" variant="outlined" margin="dense" >
                                  {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.maxPartialDeliveries && shippingDropdownValues.data.data.maxPartialDeliveries.length > 0 && shippingDropdownValues.data.data.maxPartialDeliveries.map((dp: any, index: any) => (
                                    <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                              <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                                {shipppingAddress.showSapId === true && (shipppingAddress && shipppingAddress.companyBrands && shipppingAddress.companyBrands.length) - 1 === companyBrandIndex ?
                                  <Button variant="contained" onClick={() => {
                                    setAddressIndex(index);
                                    let address = [...addressList]
                                    setSelectedAddress(address[index]);
                                    if (shipppingAddress.sapCustomerId === '' && shipppingAddress.dafault && shipppingAddress.shiftToPartyCustomerNumber === '') {
                                      onClickSendToSAP(index)
                                    } else {
                                      setOpenConfirmDialog(true);
                                    }
                                  }} color="primary" className="blueActionButton border-left rounded-pill ml-4 mt-2">
                                    {shipppingAddress.sapCustomerId === '' && shipppingAddress.dafault && shipppingAddress.shiftToPartyCustomerNumber === '' ? "Save" : "Sent to SAP"}
                                  </Button>
                                  : ""}
                              </div>
                            </div>
                          ))}
                        </div>
                      }
                    </div>
                  </div>
                </>
              })
            }
          </div >
        </div>
      </div>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">

        <AddNewAddressDailog firstAddressCheck={firstAddressCheck} isDefaultAdd={isDefaultAdd} setOpenDialog={handleClose} setDataUpdatedQuotation={setDataUpdatedQuotation} selectedDeliveryType={selectedDeliveryType} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} isEditDealer={editedAddress.dafault ? false : isEditDealer} isOutsideFreezone={isCustomerAcceptingDelivery}
          isIntBuisness={customerOnboardingType === "Int. Business" ? true : false} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog && !props.sendToSAP && sendToSAPSuccess}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }}
        sendToSAP={false} />
      <DeleteDialog
        open={openDeleteDialog && props.sendToSAP && !sendToSAPSuccess}
        text={props.sendToSAP && !sendToSAPSuccess ? 'You will have to send the address(es) to SAP before updating the Customer details else the data will not get updated and will be lost.' : "Do you wish to proceed without saving the data?"}
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === 'back') { handleopenClose(false); }
          if (!data) { handleopenClose(false); }
        }}
        sendToSAP={true} />
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={apiResponseText} />
      <SendToSAPDialog
        open={openConfirmDialog}
        selectedAddress={selectedAddress}
        handleopenClose={(data: any) => {
          if (data === 'success') {
            onClickSendToSAP(addressIndex);
          } else {
            setOpenConfirmDialog(false)
          }
        }}
      />
      <QuotationPropmtDialog
        open={dataUpdatedQuotation}
        setIsEdited={setIsEdited}
        text="Please update the complete address to proceed further."
        handleopenClose={(data: any) => {
          if (data === 'Ok') {
            let shppingAddID: any
            addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => {
              if (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length == 0)
                shppingAddID = shipppingAddress
            })
            if (data && !isEditDealer || isEditDealer) {
              onClickEditAddress(shppingAddID);
              handleopenClose(false); setDataUpdatedQuotation(false);
            }
          } else if (data === 'Cancel') {
            handleopenClose(false); setDataUpdatedQuotation(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      {
        isEditDealer && (
          <ActionButtons
            openRequestSnackbar={true}
            deactiveShow={true}
            addressList={addressList}
            dealerDetails={dealerDetails}
            dealerStatus={dealerstatus}
            saveCallback={() => {
              saveZoneAddress();
            }}
          />
        )
      }
      <DeactivateDialog
        open={showDeactivateDialog}
        status={dealerStatus}
        openRequestSnackbar={openSnackbar}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenClose={(data: any) => {
          setshowDeactivateDialog(data ? true : false)
        }}
      />
      <DeactivateAddress
        open={openDeactivateAddress}
        status={addressStatus}
        handleResponse={handleResponse}
        sapId={sapCustomerId}
        id={addressId}
        openRequestSnackbar={openSnackbar}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenClose={(data: any) => {
          setOpenDeactivateAddress(data ? true : false)
        }}
      />
    </Fragment >
  )
}
export default ShipToParty;
