/* eslint-disable */
import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from "react-router-dom";
import { useEffect, useState } from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import { getlimitedEnquiriesList, getOrderEnquiryStatus } from '../../store/actions/orderEnquiry.action';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { get } from "lodash";
import { getOrdersList, } from "../../store/actions/orderHistory.actions";
import moment from "moment-timezone";
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';

const headCells = [
  { id: 'srNo', numeric: true, disablePadding: true, label: 'Enquiry No' },
  { id: 'partNo', numeric: true, disablePadding: false, label: 'Referance No.' },
  { id: 'desc', numeric: true, disablePadding: false, label: 'Brands' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'Created On' },
  { id: 'createdby', numeric: true, disablePadding: false, label: 'Created By' },
];

function EnhancedTableHead(props: any) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/* <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            className="ml-3 mr-2"
          /> */}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: "100px"
  },
  title: {
    flex: '1 1 100%',
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: '0.7rem   '
  },
  tableHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontFamily: 'Nunito',
    fontSize: 14
  },
  offer: {
    paddingRight: 30,
    borderRight: '2px solid #666666',
    fontWeight: 700,
    color: '#666666',
    fontSize: 14
  }
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    padding: '0.125rem 175',
    display: 'flex',
    alignItems: 'center',
    width: 560,
    borderRadius: "1.25rem",
    height: "2.25rem",
    minWidth: 200,
    margin: 0,
    backgroundColor: "#f8f8f8",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '0.75rem',
    padding: '1rem',
    color: '#000000',
    fontWeight: 600,
    fontFamily: 'Nunito'
  },
  iconButton: {
    color: '#444444',
    fontWeight: 700
  },
}));

export default function EnquiryInfo(props: any) {

  const { myEnquiry } = props
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);

  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const classes2 = useStyles2();
  const [selected, setSelected] = React.useState<any>([]);
  const [page, setPage] = useState(0);
  const [searchText, setSearchText] = React.useState("")
  const rowsPerPage = 5;
  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  const { enquiryList, enquiryStatus } = useSelector((state: any) => state.orderEnquiry);
  const { orderList }: any = useSelector((state: any) => state.orderHistory);
  const { data: orders } = orderList || {};
  const b2cUserId = get(profileDetails, "data.data.basicInfo.b2cUserId", "");
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  React.useEffect(() => {
    let searchData: any = {}
    searchData["userId"] = b2cUserId && b2cUserId
    if (searchText === '' && myEnquiry) {
      setPage(0)
      dispatch(getlimitedEnquiriesList(customerTierType, searchData, page));
    } else if (searchText === '') {
      setPage(0)
      dispatch(getlimitedEnquiriesList(customerTierType, {}, page));
    }
  }, [searchText]);

  React.useEffect(() => {
    setPage(0)
  }, [myEnquiry])

  useEffect(() => {
    props.newOrder(selected)
  }, [selected])

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = enquiryList && enquiryList.data && enquiryList.data.data && enquiryList.data.data.map((n: any) => n.masterId);
      setSelected(newSelecteds);
      return undefined;
    }
    setSelected([]);
  };


  const loadEnquiryList = (pageNumber?: any) => {
    setPage(pageNumber ? pageNumber : 0);
    let searchData: any = {}
    if (searchText && searchText.trim()) {
      searchData["enquiryNumber"] = searchText.trim();
      searchData["referenceNumber"] = searchText.trim();
      // searchData["orderNumber"] = searchText.trim();
    }
    if (myEnquiry && searchText && searchText.trim()) {
      searchData["userId"] = b2cUserId && b2cUserId;
      dispatch(getlimitedEnquiriesList(customerTierType, searchData, pageNumber));
    } else if (myEnquiry) {
      searchData["userId"] = b2cUserId && b2cUserId;
      dispatch(getlimitedEnquiriesList(customerTierType, searchData, pageNumber));
    }
    else if (myEnquiry === false && searchText && searchText.trim()) { dispatch(getlimitedEnquiriesList(customerTierType, searchData, pageNumber)); }
    else if (myEnquiry === false && searchText == '') { dispatch(getlimitedEnquiriesList(customerTierType, {}, pageNumber)); }
  }

  const handleChange = (e: any) => {
    setSearchText(e.target.value)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    loadEnquiryList(newPage)
    setPage(newPage);
  };

  const handleClick = (event: any, name: any, status: any, func: any) => {
    if (event.target.checked) {
      setSelected([name]);
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'update',
        masterId: name
      }))
    } else {
      sessionStorage.setItem('enquiryModeDetails', "")
      setSelected([])
    }
  };

  return (
    <div>
      <div className="col-12 pr-4 mr-1 p-4">
        <Paper component="div" className={`${classes2.root} `}>
          <InputBase
            value={searchText}
            onChange={handleChange}
            className={classes2.input}
            placeholder="Search by Enquiry No Referance No"
            inputProps={{ 'aria-label': 'Search' }}
          />
          <IconButton>
            <SearchIcon className={classes2.iconButton} fontSize="small" onClick={() => { loadEnquiryList() }} />
          </IconButton>
        </Paper>
      </div>
      <div>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            className="w-100"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={enquiryList && enquiryList.data && enquiryList.data.data && enquiryList.data.data.length}
            />
            <TableBody>
              {(
                <>
                  {enquiryList && enquiryList.data && enquiryList.data.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data.length > 0 && (
                    <>
                      {enquiryList.data.data.map((row: any, index: any) => {
                        const labelId: any = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.masterId}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={selected && selected.length && selected.includes(row.masterId) ? true : false}
                                inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
                                disabled={showCreateChangeEnquiry === false}
                                onClick={(event: any) => {
                                  handleClick(event, row.masterId, row.status, props.newOrder);
                                }}
                                className="ml-3"
                              />
                            </TableCell>
                            <TableCell component="th" id={labelId} scope="row" className="p-0 text-light-gray">
                              {row.masterId}
                            </TableCell>
                            <TableCell align="left" className="text-light-gray">{row.masterReferenceNumber}</TableCell>
                            <TableCell align="left" className="text-light-gray">{row.make && Array.isArray(row.make) && row.make.toString()}</TableCell>
                            <TableCell align="left" className="text-light-gray">{moment(new Date(row.createdOn)).format("DD/MM/YYYY h:mm:ss a")}</TableCell>
                            <TableCell align="left" className="text-light-gray">{row.createdBy}</TableCell>
                          </TableRow>
                        );
                      })}
                    </>
                  )}
                </>)
              }
            </TableBody>
          </Table>
          <>
            {(enquiryList && enquiryList.data && Array.isArray(enquiryList.data.data) && enquiryList.data.data && enquiryList.data.data.length === 0) || (enquiryList && enquiryList.data && enquiryList && !enquiryList.data.data) && (
              <div className="mt-5 justify-content-center mx-auto text-center">No Enquiries Found.</div>
            )}
          </>
        </TableContainer>
        <div className="col-12 d-flex justify-content-end">
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={enquiryList && enquiryList && enquiryList.totalCount ? enquiryList.totalCount : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />

        </div>
      </div >
    </div>
  );
}
